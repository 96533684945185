import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

@Component({
  selector: 'app-idn-table',
  templateUrl: './idn-table.component.html',
  styleUrls: ['./idn-table.component.scss'],
})
export class IdnTableComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  @Output() onPageChanged = new EventEmitter<any>();
  @Output() onSort = new EventEmitter<any>();
  @Input() data: any;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: any = [];
  noContent: any;
  currentPage: any = 0;
  pageLength: any = 0;
  pageSize: any = 5;
  PageNumber: any = 1;
  displayedColumns: any;
  paginationRequired: any;
  dataSourceValues: any;
  @Output() downloadEvent = new EventEmitter<any>();
  @Output() refreshButton = new EventEmitter<any>();
  @Output() viewButton = new EventEmitter<any>();
  ngOnInit() {
    this.displayedColumns = this.data?.displayedColumns;
    if (this.data?.dataSource?.records?.length) {
      this.dataSource = new MatTableDataSource(this.data?.dataSource?.records);
      this.noContent = false;
    } else {
      this.noContent = true;
    }
    this.dataSourceValues = this.data?.dataSourceValues;
    this.paginationRequired = this.data?.pagination ? true : false;
    if (this.paginationRequired) {
      this.currentPage = this.data.pagination?.currentPage;
      this.pageLength = this.data.pagination?.pageLength;
      this.pageSize = this.data.pagination?.pageSize;
      this.PageNumber = this.data.pagination?.pageNumber;
    }
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(): void {
    this.displayedColumns = this.data?.displayedColumns;
    if (this.data?.dataSource?.records?.length) {
      this.dataSource = new MatTableDataSource(this.data?.dataSource?.records);
      this.noContent = false;
    } else {
      this.noContent = true;
    }
    this.dataSourceValues = this.data?.dataSourceValues;
    this.paginationRequired = this.data?.pagination ? true : false;
    if (this.paginationRequired) {
      this.currentPage = this.data.pagination?.currentPage;
      this.pageLength = this.data.pagination?.pageLength;
      this.pageSize = this.data.pagination?.pageSize;
      this.PageNumber = this.data.pagination?.pageNumber;
    }
  }

  ngAfterViewChecked() {
    if (this.paginator) {
      this.paginator.length = this.data?.dataSource?.matchingCount;
      this.paginator.pageIndex = this.currentPage;
    }
  }

  pageChanged(event: any) {
    let pageChangeData = {
      event: event,
      reference: this.data?.reference,
    };
    this.onPageChanged.emit(pageChangeData);
  }

  isDisabled(e: any) {
    return e.scrollWidth <= e.clientWidth;
  }

  handleSort(event: any) {
    let sortData = {
      event: event,
      reference: this.data?.reference,
    };
    this.onSort.emit(sortData);
  }

  Download(event:any){
  this.downloadEvent.emit(event);
  }

  GetExportedFileHistory(){
  this.refreshButton.emit(true);
  }
  viewRequest(event:any){
    let param = {
      clicked:true,
      data:event
    }
    this.viewButton.emit(param);
  }
}
