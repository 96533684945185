import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ShareDataService {

  private subject = new BehaviorSubject<any>("");
  public isView = new BehaviorSubject<any>(false);

  constructor() { }

  sendData(data:any){
    this.isView.next(true);
    this.subject.next(data);
  }

  receiveData():Observable<any>{
    return this.subject.asObservable();
  }
}