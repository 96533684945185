import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import * as XLSX  from 'xlsx';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { REQUEST_TYPE } from 'src/app/shared/constants/idn-constants';
@Component({
  selector: 'app-instancetools-history',
  templateUrl: './instancetools-history.component.html',
  styleUrls: ['./instancetools-history.component.scss'],
})
export class InstancetoolsHistoryComponent implements OnInit {
  public instanceEnum = InstanceManagement;
  @ViewChild('search_input') inputName: any;
  $subs!: Subscription;
  breadCrumbItems: any;
  instanceId: any;
  ViewData: any = [];
  instanceIciVersion: any;
  dataSource: any;
  result: any;
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  pageIndex: any = 0;
  currentPage = 0;
  pageLength: any = 0;
  pageNumber: any = 1;
  pageSize: any = 10;
  compareVersion: any = '8.4.0.0';
  compareIciversion: number = 0;
  status: any = '';
  requestType: any = '';
  noContent: any = false;
  displayedColumns = [
    'action',
    'requesterdetails',
    'criteria',
    'status',
    'download',
  ];
  Actions: any ;

  Status: any = [
    { statusId: 1, name: 'In Progress' },
    { statusId: 2, name: 'Failed' },
    { statusId: 3, name: 'Success' },
  ];
  leftArrow = false;
  rightArrow = true;
  dataJson: any;
  configData: boolean = false;
  configDisplayedColumns: string[] = [
    'configname',
    'value',
    'valuetype',
    'overridetype',
    'isencrypted',
    'modifiedat'
  ];
  configDataSource:any;
  configHistory: any;
  filter: any ='';
  sortBy: any='';
  noConfigKeyContent: boolean = false;
  isViewICIToolLogActions: any;
  isViewICIToolLogActionsProd: any;
  isViewICIToolWriteActions: any;
  isViewICIToolWriteActionsProd: any;
  hasApprovalAccessToDebuggingUtilitiesProd: any;
  instanceType: any;
  instanceHistoryBackup: any;

  constructor(
    private instanceService: InstanceService,
    private router: Router,
    private blobService: AzureBlobStorageService,
    private dialogService: DialogService,
    private permissionService: PermissionsService,
    private notificationService: NotificationService,
    private loader: LoaderService
  ) {}

  ngOnDestroy(): void {
    this.$subs.unsubscribe();
  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
      { label: 'Instance Tools History', path: 'history' },
    ];
    this.isViewICIToolLogActions =
    this.permissionService.getPermission('LoggingUtilities');
  this.isViewICIToolLogActionsProd = this.permissionService.getPermission(
    'LoggingUtilities-Prod'
  );
  this.isViewICIToolWriteActions =
    this.permissionService.getPermission('WriteUtilities');
  this.isViewICIToolWriteActionsProd = this.permissionService.getPermission(
    'WriteUtilities-Prod'
  );
  this.hasApprovalAccessToDebuggingUtilitiesProd =
    this.permissionService.getPermission(
      'ApprovalAccessToDebuggingUtilities-Prod'
    );
    this.instanceId = localStorage.getItem('instanceID');
    this.configHistory = Number(localStorage.getItem('tools-history'));
    this.instanceHistoryBackup = Number(localStorage.getItem('instanceHistoryBackup'));
    this.$subs = this.instanceService.receiveData().subscribe((data) => {
      if (data) {
        let tool = JSON.parse(data);
        if(tool.name == this.instanceEnum.instance_view_gif_troubleshooter_header) {
          tool.id = REQUEST_TYPE.GIFTroubleShooter
        } 
        if (
          tool.id != undefined &&
          tool.id != null &&
          tool.id != 9 &&
          tool.path == 'landingPage'
        ) {
          this.getICIHistory(tool.id);
        } else {
          if(tool.id == 9 ){
            this.configData = true;
            this.getICIConfigKeyHistory();
          } else {
            this.getICIHistory();
          }
        }
      } else {
        if(this.configHistory == 9){
          this.configData = true;
          this.getICIConfigKeyHistory();
        } else{
          this.getICIHistory();
        }
        
      }
    });
    this.getInstanceByID();
  }

  getICIHistory(actionId?: any) {
    this.requestType = actionId ? actionId : this.requestType;
    this.instanceService
      .ICIToolList(
        this.instanceId,
        this.pageNumber,
        this.pageSize,
        this.requestType,
        this.status
      )
      .subscribe(
        (resp: any) => {
          this.result = resp;
          if (resp.body.matchingCount) {
            this.dataSource = new MatTableDataSource(resp.body.records);
            this.paginator.length = resp.body.matchingCount;
            this.paginator.pageIndex = this.currentPage;
            this.noContent = false;
          } else {
            this.noContent = true;
            this.dataSource = [];
          }
        },
        (error) => {
          this.noContent = true;
          this.dataSource = [];
          console.log(error);
        }
      );
  }

  getICIConfigKeyHistory() {
    try{
      this.instanceService
      .InstanceConfigHistoryById(
        this.instanceId,
        this.filter,
        this.sortBy,
        this.pageNumber,
        this.pageSize,
      )
      .subscribe(
        (resp: any) => {
          if (resp.body.matchingCount) {
            let result = resp.body?.records?.map((property:any)=>{
              if (property.valueType.includes('System.Boolean')) {
                property.valueType = 'System.Boolean';
              } else if (property.valueType.includes('System.String')) {
                property.valueType = 'System.String';
              } else if (property.valueType.includes('System.Int32')) {
                property.valueType = 'System.Int32';
              } else if (property.valueType.includes('System.Int64')) {
                property.valueType = 'System.Int64';
              } else if (property.valueType.includes('System.Json')) {
                property.valueType = 'System.Json';
              }
              return property;
            })      
            this.configDataSource = new MatTableDataSource(result);
            this.paginator.length = resp.body.matchingCount;
            this.paginator.pageIndex = this.currentPage;
            this.noConfigKeyContent = false;
          } else {
            this.noConfigKeyContent = true;
            this.configDataSource = [];
          }
        },
        (error) => {
          this.noConfigKeyContent = true;
          this.configDataSource = [];
          console.log(error);
        }
      );
    } catch(error){
      console.log(error)
    }
    
  }

  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.instanceType = this.ViewData?.instanceType;
            this.instanceIciVersion = this.ViewData.iciVersion?.localeCompare(
              this.compareVersion,
              undefined,
              { numeric: true, sensitivity: 'base' }
            );
          }
          this.actionPermission();
        });
    } catch (error) {
      console.log(error);
    }
  }

  actionPermission() {
    if(this.isViewICIToolLogActions || this.isViewICIToolLogActionsProd){
      this.Actions = [
        {
          actionId: 6,
          actionName: this.instanceEnum.instance_view_application_log_header,
        },
        {
          actionId: 5,
          actionName: this.instanceEnum.instance_view_database_log_header,
        },
        {
          actionId: 8,
          actionName: this.instanceEnum.instance_view_esign_troubleshooter_header,
        },
        {
          actionId: 11,
          actionName: this.instanceEnum.instance_tools_hangfireDashboard_header,
        },
        {
          actionId: REQUEST_TYPE.GIFTroubleShooter,
          actionName: this.instanceEnum.instance_view_gif_troubleshooter_header,
        }
      ]
    }

    if(this.isViewICIToolLogActions && this.isViewICIToolWriteActions && this.instanceType != 'Production'){
      this.Actions = [
        {
          actionId: 6,
          actionName: this.instanceEnum.instance_view_application_log_header,
        },
        {
          actionId: 5,
          actionName: this.instanceEnum.instance_view_database_log_header,
        },
        {
          actionId: 11,
          actionName: this.instanceEnum.instance_tools_hangfireDashboard_header,
        },
        {
          actionId: 1,
          actionName: this.instanceEnum.instance_view_cache_flush_header,
        },
        {
          actionId: 3,
          actionName: this.instanceEnum.instance_view_task_restart_header,
        },
        {
          actionId: 2,
          actionName: this.instanceEnum.instance_view_role_restart_header,
        },
        { 
          actionId: 7,
          actionName: this.instanceEnum.instance_view_instance_backup_header
        },
        {
          actionId: 8,
          actionName: this.instanceEnum.instance_view_esign_troubleshooter_header,
        },
        {
          actionId: REQUEST_TYPE.GIFTroubleShooter,
          actionName: this.instanceEnum.instance_view_gif_troubleshooter_header,
        },
      ];
    }
    if((this.hasApprovalAccessToDebuggingUtilitiesProd || this.isViewICIToolWriteActionsProd) && this.instanceType == 'Production'){
      this.Actions = [ {
        actionId: 6,
        actionName: this.instanceEnum.instance_view_application_log_header,
      },
      {
        actionId: 5,
        actionName: this.instanceEnum.instance_view_database_log_header,
      },
      {
        actionId: 11,
        actionName: this.instanceEnum.instance_tools_hangfireDashboard_header,
      },
      {
        actionId: 1,
        actionName: this.instanceEnum.instance_view_cache_flush_header,
      },
      {
        actionId: 3,
        actionName: this.instanceEnum.instance_view_task_restart_header,
      },
      {
        actionId: 2,
        actionName: this.instanceEnum.instance_view_role_restart_header,
      },
      { 
        actionId: 7,
        actionName: this.instanceEnum.instance_view_instance_backup_header
      },
      {
        actionId: 8,
        actionName: this.instanceEnum.instance_view_esign_troubleshooter_header,
      },
      {
        actionId: REQUEST_TYPE.GIFTroubleShooter,
        actionName: this.instanceEnum.instance_view_gif_troubleshooter_header,
      },]
    }
    this.Actions =
      this.instanceIciVersion < this.compareIciversion
        ? [...this.Actions,
            {
              actionId: 4,
              actionName: this.instanceEnum.instance_view_workertask_log_header,
            }
           
          ]
        : this.Actions
  }

  clearFilter() {
    this.status = '';
    this.requestType = '';
    if(this.inputName){
      this.inputName.nativeElement.value = '';
    }
    this.filter=''
    if(this.configData){
      this.getICIConfigKeyHistory();
    } else{
      this.getICIHistory();
    }
  }

  Oncancel() {
    this.status = '';
    this.filter='';
    this.requestType = '';
    this.router.navigate(['/home/instanceoperations/instance-requests/instance-tools-landing']);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
    if(this.configData){
      this.getICIConfigKeyHistory();
    } else{
      this.getICIHistory();
    }
  }

  requestTypeChange(event: any) {
    this.requestType = event.value;
    this.getICIHistory();
  }

  statusChange(event: any) {
    this.status = event.value;
    this.getICIHistory();
  }

  downloadLogs(element: any, view?: any) {
    try {
      let header = view ? this.instanceEnum.instanceTools_history_view_logs_header : this.instanceEnum.instanceTools_history_download_started_header;
      let body = view ? this.instanceEnum.instanceTools_history_view_logs_body : this.instanceEnum.instanceTools_history_download_started_body
      this.loader.show();
      var FileSaver = require('file-saver');
      this.instanceService
        .getBlobDownloadConnection(element.instanceId, element.fileName)
        .subscribe(async (resp: any) => {
          if (resp != null && resp != undefined) {
          
            let fileSize = resp.blobSize / (1024 * 1024); //convert byte to MB
            if (fileSize >= 5) {
              this.notificationService.showNotification(
                body,
                'success',
                header
              );
            }

            setTimeout(() => {
              this.loader.hide();
            }, 2000);

            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              async (response: any) => {
                if (response._response.status == 200) {
                  response.blobBody.then(async (onres: any) => {
                    if (view) {
                      if (element.requestType == 'Application Logs') {
                        const blobContents = await this.blobToString(onres);
                        let data = {
                          instanceType: this.ViewData.instanceType,
                          blobContents: blobContents,
                        };
                        this.dialogService
                          .confirmDialogComments({
                            title: 'Application Logs',
                            module: 'jsonView',
                            message: JSON.stringify(data),
                            confirmText: 'Confirm',
                            cancelText: 'Cancel',
                          })
                          .subscribe();
                      } else if(element.requestType == 'Database Logs') {
                        this.navigateDBlogsView(element);
                      }
                    } else {
                      if (element.requestType == this.instanceEnum.instance_view_gif_troubleshooter_header){
                        this.notificationService.showNotification(
                          'File has been downloaded.',
                          'success',
                          'Downloaded'
                        );
                      } else {
                      this.notificationService.showNotification(
                        element.requestType+' has been downloaded.',
                        'success',
                        element.requestType+' Downloaded'
                      );
                      FileSaver.saveAs(onres, element.fileName);
                      }
                    }
                  });
                }
              }
            );
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  blobToString(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(blob);
    });
  }

  redirectToBackupLink(url:any) {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.instanceEnum.redirect_dialog_body,
      confirmText: this.instanceEnum.redirect_dialog_primary_btn,
      cancelText: this.instanceEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open(url, '_blank');
      }
    });
 }

 isDisabled(e:any) {
  return  e.scrollWidth <= e.clientWidth;
}

viewMoreData(element:any){
  this.dialogService.confirmDialog({
    title: "Config Key Details",
    module: 'config-details',
    message: JSON.stringify(element),
    confirmText: '',
    cancelText: 'Close'
  }).subscribe((res: any) => {
  });
}

public doFilter = () => {
  this.filter = this.inputName?.nativeElement.value;
  this.getICIConfigKeyHistory();
};

ClearInput(event: any, filter: any) {
  this.filter = '';
  if(this.inputName){
    this.inputName.nativeElement.value = '';
  }
  this.doFilter();
}

 /** Announce the change in sort state for assistive technology. */
 announceSortChange(sortState: Sort) {
  if (sortState.direction == 'desc') {
    this.sortBy = sortState.active + '_' + sortState.direction;
  } else {
    this.sortBy = sortState.active;
  }
  this.getICIConfigKeyHistory();
}

navigateEsignView(rowData:any){
  let data={
   providerName: rowData.searchText,
   fileName: rowData.fileName
  }
  this.instanceService.sendEsignData(JSON.stringify(data))
  localStorage.setItem('esignProviderLocalData', JSON.stringify(data));
  this.router.navigate(['/home/instanceoperations/instance-requests/history/view-esign']);
}

navigateDBlogsView(rowData:any){
  let Data = {
    fileName: rowData.fileName,
    dbLogTableName: rowData.searchText
  }
  localStorage.setItem('dbLogFileDetails', JSON.stringify(Data))
  this.router.navigate(['/home/instanceoperations/instance-requests/history/view-dblogs']);
}

navigateGIFView(rowData:any){
  let Data = {
    instanceName: rowData.cName,
    version: this.ViewData.iciVersion,
    integrationType: rowData.searchText.split(' | '),
    fileName: rowData.fileName   
  }
  localStorage.setItem('gifTroubleShooterLocalData', JSON.stringify(Data))
  this.router.navigate(['/home/instanceoperations/instance-requests/history/view-gif']);
}

refresh(event:any){
  event.preventDefault();
  event.stopPropagation();
  this.getICIHistory();
}
}
