<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [auditLogData]="'instance'" [mapRole]="true" [applyFilter]="false"
    [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<app-config-drawer (closeD)="closeConfigdrawer($event)" *ngIf="isConfigDrawer" [EditData]="editData">
</app-config-drawer>

<div class="idn-acc-wrapper">
    <mat-card class="idn-acc-list-card">
        <mat-card-header class="card-header">
            <mat-card-title class="card-title">Instance Details</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="idn-view-body">
            <div class="card-content">

                <div class="idn-view-body-items" id="customer_block">
                    <div class="idn-view-body-items-label" >
                        {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_customerName}}
                    </div>
                    <div class="idn-view-body-items-data" matTooltip="{{ViewData.customerName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.customerName}}</div>
                </div>
                <div class="idn-view-body-items" id="cname_block">
                    <div class="idn-view-body-items-label" >
                        {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_cName}}</div>
                    <div class="idn-view-body-items-data" matTooltip="{{ViewData.cname}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.cname}}</div>
                </div>
                
                <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">
                        {{instanceEnum.instanceTools_iciversion_lable}}</div>
                    <div class="idn-view-body-items-data" matTooltip="{{ViewData.iciVersion}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.iciVersion}}</div>
                </div>

                <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">
                        {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_type}}</div>
                    <div class="idn-view-body-items-data" matTooltip="{{ViewData.instanceType}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.instanceType}}</div>
                </div>               

                <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">
                        {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_region}}</div>
                    <div class="idn-view-body-items-data" matTooltip="{{ViewData.deploymentRegion}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.deploymentRegion}}</div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="idn-acc-list-card-1">
        <mat-card-header class="card-header">
            <mat-card-title class="card-title">
                Deployment Packages
            </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="acc-count-chips card-content">
            <span class="count-label">Package Selected</span>
            <span class="count" [ngClass]="{ 'c-info': deploymentCount > 0 }">{{ deploymentCount }}</span>
        </mat-card-content>
    </mat-card>
</div>
<div class="idn-stepper">
    <mat-stepper linear #stepper aria-busy="true">
        <mat-step #firstStep [stepControl]="firstStepControl" completed=false>
            <form [formGroup]="frmRequestDeployment">
                <br>
                <ng-template matStepLabel>{{instanceEnum.instance_stepper_step_one_heading}} <br>
                    <h6>
                        <mat-hint class="info">{{instanceEnum.instance_stepper_step_one_message}}
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="select-package">
                    <div class="pss-left-panel">
                        <mat-form-field>
                            <mat-label>{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_heading}}</mat-label>
                            <mat-select matNativeControl disableOptionCentering formControlName="packageSourceId"
                                required (selectionChange)="getDeploymentType($event.value)"
                                (valueChange)="validateDeploymentType($event)">
                                <mat-option [value]="1">Customer Extension Package</mat-option>
                                <mat-option [value]="2">SKU Deployment</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="showSolutionPackage">
                            <mat-label>{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_sku}}</mat-label>
                            <mat-select matNativeControl disableOptionCentering formControlName="skuId" required
                                (valueChange)="getSkuData($event);validateDeployment($event)">
                                <mat-option *ngFor="let sku of skuList" [value]="sku.id">
                                    {{sku.productName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="!showSolutionPackage">
                            <mat-label>{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_extensionType}}</mat-label>
                            <mat-select matNativeControl disableOptionCentering required
                                formControlName="extensionTypeId" (selectionChange)="getExtensionName($event)">
                                <mat-option *ngFor="let extension of extensionType" [value]="extension.id">
                                    {{extension.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="pss-right-panel ">
                        <mat-form-field *ngIf="!showSolutionPackage">
                            <mat-label>{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_extensionName}}</mat-label>
                            <mat-select matNativeControl disableOptionCentering required
                                (selectionChange)="getSolutionPackageById($event.value)" formControlName="extensionId" name="ExtensionName">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel="Search Extension Name"
                                        noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlExtensionName">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                <mat-option *ngFor="let extension of selectExtensionName" [value]="extension.id">
                                    {{extension.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="!showSolutionPackage">
                            <mat-label>{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_solutionPackage}}
                            </mat-label>
                            <mat-select matNativeControl disableOptionCentering required 
                                formControlName="solutionPackageId" (selectionChange)="validateDeployment($event)" name="SolutionPackage">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel="Search Customer Extension Package Name"
                                        noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlSolutionPackageName">
                                    </ngx-mat-select-search>
                                  </mat-option>
                                <mat-option *ngFor="let Solution of selectSolutionPackageName" [value]="Solution.id">
                                    {{Solution.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <button mat-stroked-button color="primary" class="idn-add-outline" [disabled]="isAddtoDeployment"
                (click)="addDeploymentPackage(); validateDeploymentType($event)">
                <mat-icon>post_add</mat-icon>
                <span>Add for Deployment</span>
            </button>
            <div class="pss-form-button-wrapper">
                <button mat-flat-button color="primary" [disabled]="isDeploymentInvalid" (click)="onNext()">
                    {{instanceEnum.instance_initiate_deployment_next_btn}}
                </button>
                <button mat-flat-button
                    (click)="onCancel()">{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_cancel_btn}}</button>
            </div>
        </mat-step>
        <mat-step #secondStep [stepControl]="secondStepControl" completed=false>
            <div class="page-container">
                <br>
                <ng-template matStepLabel>{{instanceEnum.instance_stepper_step_two_heading}}<br>
                    <h6>
                        <mat-hint class="info">{{instanceEnum.instance_stepper_step_two_message}}
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="idn-table" *ngIf="isTenantConfig">
                    <mat-error *ngIf="invalidData">{{validateError}}</mat-error>
                    <table mat-table *ngIf="!noContent" [dataSource]="dataSource" class="mat-elevation-z8">

                        <!-- Name Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell id="mat-column-name" *matHeaderCellDef>
                                {{instanceEnum.instance_edit_config_name}}</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-chip class="edit-chip" *ngIf="element.isModified" aria-label="Edited"
                                    role="none">Edited</mat-chip>
                                {{element.Name}}
                            </td>
                        </ng-container>

                        <!-- Value Column -->
                        <ng-container matColumnDef="Value">
                            <th mat-header-cell id="mat-column-value" *matHeaderCellDef>
                                {{instanceEnum.instance_edit_config_value}}</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.Value}}
                            </td>
                        </ng-container>

                        <!-- ValueType Column -->
                        <ng-container matColumnDef="ValueType">
                            <th mat-header-cell id="mat-column-valueType" *matHeaderCellDef>
                                {{instanceEnum.instance_edit_config_valueType}}</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.ValueType}}
                            </td>
                        </ng-container>

                        <!-- OverrideType Column -->
                        <ng-container matColumnDef="OverrideType">
                            <th mat-header-cell id="mat-column-overrideType" *matHeaderCellDef>
                                {{instanceEnum.instance_edit_config_OverrideType}} <span><mat-icon
                                        matTooltip={{overrideTooltip}} matTooltipPosition="above">info</mat-icon></span>
                            </th>
                            <td mat-cell *matCellDef="let element">
                                {{element.OverrideType}}
                            </td>
                        </ng-container>

                        <!-- DefaultValue Column -->
                        <ng-container matColumnDef="DefaultValue">
                            <th mat-header-cell id="mat-column-defaultValue" *matHeaderCellDef>
                                {{instanceEnum.instance_edit_config_DefaultValue}}</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.DefaultValue}}
                            </td>
                        </ng-container>

                        <!-- IsEncrypted Column -->
                        <ng-container matColumnDef="IsEncrypted">
                            <th mat-header-cell id="mat-column-isEncrypted" *matHeaderCellDef>
                                {{instanceEnum.instance_edit_config_IsEncrypted}}</th>
                            <td mat-cell *matCellDef="let element">
                                {{element.IsEncrypted}}
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="Action">
                            <th mat-header-cell id="mat-column-action" *matHeaderCellDef>
                                Edit</th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button matTooltip="Click to Edit" color="primary"
                                    aria-label="Edit Button" (click)="edit(element)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>
                </div>
            </div>
            <div class="pss-form-button-wrapper">
                <button mat-flat-button color="primary" (click)="onPrevious()">
                    {{instanceEnum.instance_initiate_deployment_previous_btn}}
                </button>
                <button mat-flat-button color="primary" [disabled]="isDeploymentInvalid" (click)="onNextPreview()">
                    {{instanceEnum.instance_initiate_deployment_next_btn}}
                </button>
                <button mat-flat-button
                    (click)="onCancel()">{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_cancel_btn}}</button>
            </div>
        </mat-step>
        <mat-step #thirdStep [stepControl]="thirdStepControl" completed=false>
            <div>
                <br>
                <ng-template matStepLabel>{{instanceEnum.instance_stepper_step_three_heading}}<br>
                    <h6>
                        <mat-hint class="info">{{instanceEnum.instance_stepper_step_three_message}}
                        </mat-hint>
                    </h6>
                </ng-template>
                <div *ngIf="deployments">
                    <h3 class="preview-heading">Selected Deployment Packages</h3>
                    <div *ngFor="let deployment of deployments" class="idn-acc-list-card">
                        <div class="idn-view-body">
                            <button matTooltip="Remove this Extension & Package" class="card-action" mat-icon-button
                                aria-label="Remove Deployment"
                                (click)="removeDeploymentPackage(deployment.id);validateDeploymentType(false)">
                                <mat-icon>delete</mat-icon>
                            </button>
                            <mat-grid-list cols="4" rowHeight="48px">
                                <mat-grid-tile [colspan]="1">
                                    <div class="mat-grid-tile-content">
                                        <div class="idn-view-body-items">
                                            <div class="idn-view-body-items-label">{{
                                                deploymentEnum.deployment_View_Field_deployment_for }}</div>
                                            <div class="idn-view-body-items-data" matTooltip="{{deployment.deploymentFor}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{deployment.deploymentFor}}</div>
                                        </div>
                                    </div>
                                </mat-grid-tile>
                                <mat-grid-tile [colspan]="1">
                                    <div class="mat-grid-tile-content">
                                        <div class="idn-view-body-items">
                                            <div class="idn-view-body-items-label">{{
                                                deploymentEnum.deployment_View_Field_extensionType
                                                }}</div>
                                            <div class="idn-view-body-items-data" matTooltip="{{deployment.extensionType}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{deployment.extensionType}}</div>
                                        </div>
                                    </div>
                                </mat-grid-tile>
                                <div *ngIf="deployment.productName">
                                    <mat-grid-tile [colspan]="1">
                                        <div class="mat-grid-tile-content">
                                            <div class="idn-view-body-items">
                                                <div class="idn-view-body-items-label">{{
                                                    deploymentEnum.deployment_View_Field_productName}}
                                                </div>
                                                <div class="idn-view-body-items-data" matTooltip="{{deployment.productName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{deployment.productName}}</div>
                                            </div>
                                        </div>
                                    </mat-grid-tile>
                                </div>
                                <div *ngIf="!deployment.productName">
                                    <mat-grid-tile [colspan]="1">
                                        <div class="mat-grid-tile-content">
                                            <div class="idn-view-body-items">
                                                <div class="idn-view-body-items-label">{{
                                                    deploymentEnum.deployment_View_Field_extensionName
                                                    }}</div>
                                                <div class="idn-view-body-items-data" matTooltip="{{deployment.extensionName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{deployment.extensionName}}</div>
                                            </div>
                                        </div>
                                    </mat-grid-tile>
                                </div>
                                <mat-grid-tile [colspan]="1">
                                    <div class="mat-grid-tile-content">
                                        <div class="idn-view-body-items">
                                            <div class="idn-view-body-items-label">{{
                                                deploymentEnum.deployment_View_Field_solutionPackage }}</div>
                                            <div class="idn-view-body-items-data" matTooltip="{{deployment.solutionPackage}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{deployment.solutionPackage}}</div>
                                        </div>
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </div>
                </div>
                <div *ngIf="editedData.length > 0">
                    <h3 class="preview-heading">{{instanceEnum.instance_config_preview_table_heading}}</h3>
                    <div class="idn-table" tabindex="0">
                        <mat-error *ngIf="invalidData">{{validateError}}</mat-error>
                        <table mat-table *ngIf="!noContent" [dataSource]="dataSource1" class="mat-elevation-z8">

                            <!-- Name Column -->
                            <ng-container matColumnDef="Name">
                                <th mat-header-cell id="mat-column-name" *matHeaderCellDef>
                                    {{instanceEnum.instance_edit_config_name}}</th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-chip class="edit-chip" *ngIf="element.isModified" aria-label="Edited"
                                        role="none">Edited</mat-chip>
                                    {{element.Name}}
                                </td>
                            </ng-container>

                            <!-- Value Column -->
                            <ng-container matColumnDef="Value">
                                <th mat-header-cell id="mat-column-value" *matHeaderCellDef>
                                    {{instanceEnum.instance_edit_config_value}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.Value}}
                                </td>
                            </ng-container>

                            <!-- ValueType Column -->
                            <ng-container matColumnDef="ValueType">
                                <th mat-header-cell id="mat-column-valueType" *matHeaderCellDef>
                                    {{instanceEnum.instance_edit_config_valueType}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.ValueType}}
                                </td>
                            </ng-container>

                            <!-- OverrideType Column -->
                            <ng-container matColumnDef="OverrideType">
                                <th mat-header-cell id="mat-column-overrideType" *matHeaderCellDef>
                                    {{instanceEnum.instance_edit_config_OverrideType}} <span><mat-icon
                                            matTooltip={{overrideTooltip}}
                                            matTooltipPosition="above">info</mat-icon></span></th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.OverrideType}}
                                </td>
                            </ng-container>

                            <!-- DefaultValue Column -->
                            <ng-container matColumnDef="DefaultValue">
                                <th mat-header-cell id="mat-column-defaultValue" *matHeaderCellDef>
                                    {{instanceEnum.instance_edit_config_DefaultValue}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.DefaultValue}}
                                </td>
                            </ng-container>

                            <!-- IsEncrypted Column -->
                            <ng-container matColumnDef="IsEncrypted">
                                <th mat-header-cell id="mat-column-isEncrypted" *matHeaderCellDef>
                                    {{instanceEnum.instance_edit_config_IsEncrypted}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.IsEncrypted}}
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsPreview"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumnsPreview;">
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
            <div class="pss-form-button-wrapper">
                <button mat-flat-button color="primary" (click)="onPrevious()">
                    {{instanceEnum.instance_initiate_deployment_previous_btn}}
                </button>
                <button mat-flat-button color="primary" (click)="initiateDeployment()" *ngIf="ViewData.instanceType != instanceEnum.instance_constant_production">
                    {{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_submitForDeployment_btn}}
                </button>
                <button mat-flat-button color="primary" (click)="requestDeployment()" *ngIf="ViewData.instanceType == instanceEnum.instance_constant_production">
                   Request Deployment
                </button>
                <button mat-flat-button
                    (click)="onCancel()">{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_cancel_btn}}</button>
            </div>
        </mat-step>
    </mat-stepper>

</div>