<div class="idn-access-denied">
  <div class="wrapper">
    <!-- <mat-icon class="icon-main">help_outline</mat-icon> -->
    <svg xmlns="http://www.w3.org/2000/svg" width="410" height="151.5" viewBox="0 0 410 151.5">
      <g id="Group_4" data-name="Group 4" transform="translate(-791.5 -236)">
        <path id="Subtraction_1" data-name="Subtraction 1" d="M298.352,149.615H6.648c-.1-2.159-.148-4.346-.148-6.5A140.622,140.622,0,0,1,31.434,63.126,144.917,144.917,0,0,1,49.262,41.941,146.674,146.674,0,0,1,123.076,2.91a150.2,150.2,0,0,1,58.848,0,146.586,146.586,0,0,1,73.814,39.031,144.916,144.916,0,0,1,17.828,21.185,142.33,142.33,0,0,1,13.461,24.309,140.153,140.153,0,0,1,11.474,55.68c0,2.161-.05,4.347-.148,6.5Z" transform="translate(842 236.384)" fill="#c8dcf4"/>
        <g id="Group_3" data-name="Group 3" transform="translate(295 -275)">
          <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(582 511)" fill="none" stroke="#283987" stroke-width="2">
            <circle cx="26.5" cy="26.5" r="26.5" stroke="none"/>
            <circle cx="26.5" cy="26.5" r="25.5" fill="none"/>
          </g>
          <g id="Ellipse_3" data-name="Ellipse 3" transform="translate(592 511)" fill="none" stroke="#283987" stroke-width="2">
            <ellipse cx="16.5" cy="26.5" rx="16.5" ry="26.5" stroke="none"/>
            <ellipse cx="16.5" cy="26.5" rx="15.5" ry="25.5" fill="none"/>
          </g>
          <line id="Line_5" data-name="Line 5" y2="50" transform="translate(608.5 512.5)" fill="none" stroke="#283987" stroke-width="2"/>
          <line id="Line_6" data-name="Line 6" x2="44" transform="translate(586.5 525.5)" fill="none" stroke="#283987" stroke-width="2"/>
          <line id="Line_8" data-name="Line 8" x2="44" transform="translate(586.5 549.5)" fill="none" stroke="#283987" stroke-width="2"/>
          <line id="Line_7" data-name="Line 7" x2="50" transform="translate(583.5 537.5)" fill="none" stroke="#283987" stroke-width="2"/>
        </g>
        <line id="Line_1" data-name="Line 1" x2="15" transform="translate(793.5 385.5)" fill="none" stroke="#191e3d" stroke-linecap="round" stroke-width="4"/>
        <line id="Line_2" data-name="Line 2" x2="15" transform="translate(1184.5 385.5)" fill="none" stroke="#191e3d" stroke-linecap="round" stroke-width="4"/>
        <line id="Line_3" data-name="Line 3" x2="361" transform="translate(816.5 385.5)" fill="none" stroke="#191e3d" stroke-linecap="round" stroke-width="4"/>
        <g id="Group_2" data-name="Group 2" transform="translate(-159.242 -144.131)">
          <rect id="Rectangle_3" data-name="Rectangle 3" width="165" height="99" transform="translate(1073 415)" fill="#fff"/>
          <path id="Path_2" data-name="Path 2" d="M905.928,592.75l64.28-68.81h24.322l-88.6,95.841Z" transform="translate(167.072 -109.94)" fill="#f1f8fe"/>
          <path id="Path_3" data-name="Path 3" d="M1037.6,524.664l-97.289,97.578h54.435l74.414-80.205V524.664Z" transform="translate(166 -108.953)" fill="#f1f8fe"/>
          <path id="Path_4" data-name="Path 4" d="M1004.593,621.953l63.99-70.65v12.74l-51.54,57.91Z" transform="translate(166.579 -107.953)" fill="#f1f8fe"/>
          <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(1069 411)" fill="none" stroke="#242b61" stroke-width="6">
            <rect width="171" height="105" rx="5" stroke="none"/>
            <rect x="3" y="3" width="165" height="99" rx="2" fill="none"/>
          </g>
          <path id="Path_5" data-name="Path 5" d="M893.261,638.674c8.976.579,177.494.29,186.759,0s7.528-12.174,7.528-12.174-75.572-.276-79.916,0-6.081,4.646-6.081,4.646H974.914c-4.343,0-5.5-4.646-5.5-4.646h-83.68S884.285,638.1,893.261,638.674Z" transform="translate(167.838 -109.377)" fill="#242b61"/>
          <g id="Group_1" data-name="Group 1" transform="translate(170.5 -109.5)">
            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(1041 559)" fill="none" stroke="#283f76" stroke-width="4">
              <circle cx="25.5" cy="25.5" r="25.5" stroke="none"/>
              <circle cx="25.5" cy="25.5" r="23.5" fill="none"/>
            </g>
            <line id="Line_4" data-name="Line 4" x2="23" y2="24" transform="translate(1085.5 602.5)" fill="none" stroke="#283f76" stroke-linecap="round" stroke-width="8"/>
          </g>
          <path id="Rectangle_4" data-name="Rectangle 4" d="M0,0H44a0,0,0,0,1,0,0V3a3,3,0,0,1-3,3H3A3,3,0,0,1,0,3V0A0,0,0,0,1,0,0Z" transform="translate(1135 517)" fill="#fff"/>
        </g>
        <text id="_404" data-name="404" transform="translate(943 340)" fill="#242b61" font-size="64" font-family="SourceSansPro-Bold, Source Sans Pro" font-weight="700"><tspan x="0" y="0">404</tspan></text>
      </g>
    </svg>

    <h1>Page Not Found</h1>
    <p>We are Sorry! the page you requested could not found</p>
    <p>Please go back to Home page</p>
    <button mat-stroked-button class="button-info" (click)="navigateHome()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span>Back to Home</span>
    </button>
  </div>
</div>
