import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MultiTenantPodService {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  constructor(private http: HttpClient) {}
  public extSubject = new BehaviorSubject<any>('');

  public getMultiTenantPODList(
    filter: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number,
    iciVersion: string,
    region: any
  ): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/MultiTenantPOD/MultiTenantPODList',
      {
        params: {
          filter: filter,
          sortBy: sortBy,
          pageNumber: pageNumber,
          pageSize: pageSize,
          iciVersion: iciVersion,
          regionId: region,
        },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public GetMTPODReferenceData(): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/MultiTenantPOD/GetMTPODReferenceData',
      { headers: this.headers, observe: 'response' }
    );
  }

  public getMultiTenantPODDetails(podId: number): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/MultiTenantPOD/GetMultiTenantPODDetailsById',
      { params: { id: podId }, headers: this.headers, observe: 'response' }
    );
  }
  public getMultiTenantPODSnapShot(podId: number): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/MultiTenantPOD/MultiTenantPODExtensions',
      { params: { id: podId }, headers: this.headers, observe: 'response' }
    );
  }

  public getMultiTenantPODPreviewUpgrade(
    podId: number,
    newVersion: any
  ): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/MultiTenantPOD/GetMultiTenantPODPreviewUpgrade',
      {
        params: {
          id: podId,
          Major: newVersion.Major,
          Minor: newVersion.Minor,
          Patch: newVersion.Patch,
          Build: newVersion.Build,
        },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public UpgradePOD(paraData: any, queryParams:any): Observable<any> {
    return this.http.post(
      this.baseApiUrl + 'api/MultiTenantPOD/UpgradePOD',
      paraData,
      {
        headers: this.headers,
        params:queryParams,
        observe: 'response',
      }
    );
  }

  public downloadPodcalendarTemplate() {
    return this.http.get(this.baseApiUrl + 'api/MTPodUpgradeCalendar/DownloadBlobURL');
  }

  public GetPodCalendarUploadConnection() {
    return this.http.get(this.baseApiUrl + 'api/MTPodUpgradeCalendar/UploadBlobURL');
  }

  public GetPodCalendarList() {
    return this.http.get(this.baseApiUrl + 'api/MTPodUpgradeCalendar/MTPods');
  }

  public saveUploadedFile(params:any){
    return this.http.post( this.baseApiUrl + 'api/MTPodUpgradeCalendar/save',params,
     {
      headers: this.headers,
      observe: 'response',
    })
  }
  //API call to send Email to CloudOps User after file has been uploaded.
  // params :  "ScheduleRecordIds" , "IsCloudOpsEmail".
  public sendEmailToCloudOps(params:any){
    return this.http.post( this.baseApiUrl + 'api/MTPodUpgradeCalendar/SendEmailToCloudOps',params,
     {
      headers: this.headers,
      observe: 'response',
    })
  } 

  public startPodUpgrade(data:any): Observable<any>{
    return this.http.put(this.baseApiUrl + 'api/MTPodUpgradeCalendar/ScheduleEntry' , data,{ headers: this.headers, observe: 'response' })            
  }

  // API call to check if file has been uploaded.
  // id - Unique identifier for File , filename : filename of the file that has been uploaded.
  public checkBlobFileExist(id:any,filename:any) {
    return this.http.get(this.baseApiUrl + 'api/MTPodUpgradeCalendar/CheckBlobFileExist', { params: { 'uniqueidentifier': id, 'filename': filename }, headers: this.headers });
  }

  public startLAActivity(TargetIciVersion:any){
    return this.http.post( this.baseApiUrl + 'api/MTPODTenantUpgrade/StartLAActivity?targetIciVersion=' +TargetIciVersion,
     {
      headers: this.headers,
      observe: 'response',
    })
  }

  public CompatibleUpgradePackage(TargetIciVersion:any, podId:any){
    return this.http.post( this.baseApiUrl + 'api/MTPODTenantUpgrade/CompatibleUpgradePackage?targetIciVersion=' +TargetIciVersion+"&multiTenantPODId="+podId,
     {
      headers: this.headers,
      observe: 'response',
    })
  }
  
}
