import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { StepperOrientation, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvService } from 'src/app/core/services/env.service';
import { resolve } from 'dns';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { PartnerService } from 'src/app/core/services/partner-management/partner.service';
import { IdnConfigurationService } from 'src/app/core/services/common/idn-configuration.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
    <defs>
      <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
        <feOffset dy="8" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="5" result="blur"/>
        <feFlood flood-opacity="0"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
      <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
        <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
      </g>
      <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
    </g>
    </svg>`

    const publicSku = 2; // refers to user who can create the Public SKU.
    const versionCheck = 0; //used to compare version < 0 referes lower version, >0 means the higher version and 0 means same version.
@Component({
  selector: 'app-edit-extension',
  templateUrl: './edit-extension.component.html',
  styleUrls: ['./edit-extension.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false },
    },
  ],
})
export class EditExtensionComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef;

  fileAttr = "Choose File";

  @ViewChild('customername')
  mySelect!: MatSelect;

  @ViewChild('stepper')
  mystepper!: MatStepper;

  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  items: any[] = [];
  ExType: any;
  extTimestamp: any = "";
  soluPackgTimestamp: any = "";
  IsExtenTypeSelected: boolean = false;
  addOnBlur = true;
  CustomerNameList: any;
  CustomerDisplayList: any;
  listofUploadedFiles: any[] = [];
  columnFilterCtrlCo = new FormControl('');
  packageNameFilterCtrl = new FormControl('');
  file: any;
  azUrl: any;
  eventObject: any = {};
  fileArray: any[] = [];
  sas: any;
  uniqueIdentifier: any;
  fileSizeLimit = this.env.fileSizeLimitExtension;
  isFileUploaded: boolean = false;
  packageFileAttr = 'Package zip File';
  thirdFormGroup!: FormGroup;
  extensionName = "";
  isEditable = false;
  extensionTabIndex = 0;
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Development and Utilities", path: "developmentandutilities/extension-management" },
    { label: "Extension Management", path: "developmentandutilities/extension-management" },
    { label: "Edit", path: "developmentandutilities/extension-management/edit" },
  ];
  chooseFileDisable = true;
  packageExtension = ['zip'];
  packageSizeLimit = 2000;
  package: any;
  extId: any;
  pckageId: any;
  ProductType: any;
  productTypeDisable = false;
  isLocked=false;
  isAnimationDisable=false;
  PartnerId:any;
  userCategory:any;

  isViewExtension:any;
  isLockExtensionPermission:any;
  isUnLockExtensionPermission:any;
  isUpdateExtensionPermission:any;
  isCreateExtensionPermission:any;
  isViewSolutionPackagePermission:any;
  isCreateSolutionPackage:any;
  isEditSolutionPackage:any;
  orientation: StepperOrientation = 'horizontal'
  innerWidth: number = 1200;
  public extensionEnum = ExtensionManagement;
  myInputValue:any;
  errorDescriptionName:any;
  public regex = InputPattern;
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  errorDescription: any = this.regex.errorMessage;
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;
  binaryPackage: any =false;
  iciMajorVersion = 0;
  iciMinorVersion = 0;
  iciPatchVersion = 0;
  iciBuildVersion = 0;
  minimumICIVersionForBinaryPackage:any;
  binaryPackageUpload: any = false;
  extensionType: any;
  packageList: any = [];
  linkedPackage:any=null;
  disableLinkedPackage: boolean= true;
  packageDisplayList: any=[];
  linkageAndQueueForUpgradeFeature: any = true;
  packageDetails: any;
  packagePartnerId:any =null;
  isCustomerUser:boolean = false;
  partnerList:any= [];
  hasGlobalDataAccess:any = false;
  ExtensionData: any={};
  currentUserId: any;

  constructor(
    private extensionService: ExtensionService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private blobService: AzureBlobStorageService,
    private msalService: MsalService,
    private formBuilder: FormBuilder,
    private env: EnvService,
    private dialogService: DialogService,
    private permissionService:PermissionsService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private partnerService: PartnerService,
    private idnConfiguration:IdnConfigurationService,
    private changeDetector: ChangeDetectorRef
  ) { 
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }

  frmEditExtension: FormGroup = new FormGroup({
    typeId: new FormControl('', Validators.required),
    customerId: new FormControl('', Validators.required),
    name: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\s]*$/)]),
    tags: new FormControl([]),
    description: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    files: new FormControl([]),
    id: new FormControl(0),
    uniqueIdentifier: new FormControl(),
    locked: new FormControl(),
    customerName: new FormControl(),
    partnerIds: new FormControl([]),
  });

  frmEditPackage: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    description: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    major: new FormControl(0, Validators.required),
    minor: new FormControl(0, Validators.required),
    patch: new FormControl(0, Validators.required),
    build: new FormControl(0, Validators.required),
    icimajor: new FormControl(0),
    iciminor: new FormControl(0),
    icipatch: new FormControl(0),
    icibuild: new FormControl(0),
    fileName: new FormControl('', Validators.required),
    id: new FormControl(0),
    status: new FormControl(null),
    extensionId: new FormControl(''),
    uniqueIdentifier: new FormControl(),
    locked: new FormControl(),
  });

  @HostListener('window:resize') onWindowResize() {
    this.innerWidth = window.innerWidth;
    this.getInitialWindowSize(this.innerWidth);
  }

  getInitialWindowSize(winSize: number){
    if (winSize <= this.env.minBreakpoint) {
      this.orientation = 'vertical';
    } else {
      this.orientation = 'horizontal';
    }
  }

  ngOnInit(): void {    
    let featuresTobeDisabled: any = localStorage.getItem('featuresTobeDisabled');
    this.linkageAndQueueForUpgradeFeature = featuresTobeDisabled?.includes('TenantUpgradeDisableLinkage')? false: true;
    this.isViewExtension = this.permissionService.getPermission("ViewExtension");
    this.isLockExtensionPermission = this.permissionService.getPermission("LockExtension");
    this.isUpdateExtensionPermission = this.permissionService.getPermission("UpdateExtension");
    this.isUnLockExtensionPermission = this.permissionService.getPermission("UnLockExtension");
    this.isCreateExtensionPermission = this.permissionService.getPermission("CreateExtension");
    this.isViewSolutionPackagePermission = this.permissionService.getPermission("ViewSolutionPackage");
    this.isCreateSolutionPackage = this.permissionService.getPermission("CreateSolutionPackage");
    this.isEditSolutionPackage = this.permissionService.getPermission("EditSolutionPackage");
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.innerWidth = window.innerWidth;
    this.getInitialWindowSize(this.innerWidth);
    this.userCategory=this.permissionService.getUsercategoryId();
    this.currentUserId = this.permissionService.getUniqueUserid();
    if(this.userCategory ==  USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
    }
    else{
      this.isCustomerUser = false;
    }
    this.frmEditExtension.controls.customerName.disable();
    this.frmEditExtension.controls.typeId.disable();

    if (!this.isEditSolutionPackage || !this.isCreateSolutionPackage) {
      this.frmEditPackage.disable();
    }

    this.isViewSolutionPackagePermission = this.permissionService.getPermission("ViewSolutionPackage");
    this.PartnerId = this.permissionService.getPartnerDetails().id;

    this.pckageId = localStorage.getItem("packageId");
    this.extId = localStorage.getItem("extId");

    if (this.pckageId != undefined && this.extId != undefined && this.extId != null && this.pckageId != null) {
      this.getExtensionPackageById(this.pckageId);
      if(this.linkageAndQueueForUpgradeFeature){
        this.getSolutionPackagesForLinkage(this.extId, this.pckageId);
      }
    }
    else if (this.extId != undefined && this.extId != null) {
      this.getExtDataById(this.extId);
    }

    this.frmEditExtension.controls["name"].disable();
    this.frmEditExtension.controls["customerId"].disable();
    this.bindExtensionDropDown();
    this.bindCustomerDropDown();

    this.columnFilterCtrlCo.valueChanges.subscribe((value) => {
      this.filterColumnCo(value);
    });
    this.packageNameFilterCtrl.valueChanges.subscribe((value) => {
      this.filterColumnPackageList(value);
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });
    try {
      if (localStorage.getItem("loggedin") != null && localStorage.getItem("loggedin") == "false") {
        this.msalService.instance.logoutRedirect();
      }
    } catch (exception) {
      console.log(exception)
    }

    this.getMinimumIciversion();
    this.frmEditPackage.controls["icimajor"].valueChanges.subscribe((value) => {
      this.iciMajorVersion = value;
    });
    this.frmEditPackage.controls["iciminor"].valueChanges.subscribe((value) => {
      this.iciMinorVersion = value;
    })
    this.frmEditPackage.controls["icipatch"].valueChanges.subscribe((value) => {
      this.iciPatchVersion = value;
    })
    this.frmEditPackage.controls["icibuild"].valueChanges.subscribe((value) => {
      this.iciBuildVersion = value;
    })
  }

  // Get data by Id
  async getDataByExtId(id: any) {
    try {
      return await this.extensionService.getExtensionById(id).toPromise();
    }
    catch (execption) {
      console.log(execption);
    }
  }

  toggleBinaryPackage(event:any){
    if(event.checked){
      this.binaryPackage = true;
      this.frmEditPackage.controls.icimajor.addValidators(Validators.required);
      this.frmEditPackage.controls.iciminor.addValidators(Validators.required);
      this.frmEditPackage.controls.icipatch.addValidators(Validators.required);
      this.frmEditPackage.controls.icibuild.addValidators(Validators.required);
    } else {
      this.binaryPackage = false;
      this.frmEditPackage.controls.icimajor.clearValidators();
      this.frmEditPackage.controls.iciminor.clearValidators();
      this.frmEditPackage.controls.icipatch.clearValidators();
      this.frmEditPackage.controls.icibuild.clearValidators();
    }
    this.frmEditPackage.controls.icimajor.updateValueAndValidity();
    this.frmEditPackage.controls.iciminor.updateValueAndValidity();
    this.frmEditPackage.controls.icipatch.updateValueAndValidity();
    this.frmEditPackage.controls.icibuild.updateValueAndValidity();
  }

  increaseVersion(value: any){
    if (value == 'icimajor') {
      this.iciMajorVersion += 1;
      this.frmEditPackage.controls["icimajor"].setValue(this.iciMajorVersion);
    }
    else if (value == 'iciminor') {
      this.iciMinorVersion += 1;
      this.frmEditPackage.controls["iciminor"].setValue(this.iciMinorVersion);
    }
    else if (value == 'icipatch') {
      this.iciPatchVersion += 1;
      this.frmEditPackage.controls["icipatch"].setValue(this.iciPatchVersion);
    }
    else if (value == 'icibuild') {
      this.iciBuildVersion += 1;
      this.frmEditPackage.controls["icibuild"].setValue(this.iciBuildVersion);
    }
  }

  decreaseVersion(value: any){
    if (value == 'icimajor') {
      if(this.iciMajorVersion>0){
        this.iciMajorVersion -= 1;
        this.frmEditPackage.controls["icimajor"].setValue(this.iciMajorVersion);
      } else {
        this.iciMajorVersion = 0;
        this.frmEditPackage.controls["icimajor"].setValue(this.iciMajorVersion);
      }
      
    }
    else if (value == 'iciminor') {
      if(this.iciMinorVersion>0){
        this.iciMinorVersion -= 1;
        this.frmEditPackage.controls["iciminor"].setValue(this.iciMinorVersion);
      } else {
        this.iciMinorVersion = 0;
        this.frmEditPackage.controls["iciminor"].setValue(this.iciMinorVersion);
      }
    }
    else if (value == 'icipatch') {
      if(this.iciPatchVersion>0){
        this.iciPatchVersion -= 1;
        this.frmEditPackage.controls["icipatch"].setValue(this.iciPatchVersion);
      } else {
        this.iciPatchVersion = 0;
        this.frmEditPackage.controls["icipatch"].setValue(this.iciPatchVersion);
      }
    }
    else if (value == 'icibuild') {
      if(this.iciBuildVersion>0){
        this.iciBuildVersion -= 1;
        this.frmEditPackage.controls["icibuild"].setValue(this.iciBuildVersion);
      }else{
        this.iciBuildVersion = 0;
        this.frmEditPackage.controls["icibuild"].setValue(this.iciBuildVersion);
      }
    }
  }

  getPartnerInformation(){
    let partnerId = this.hasGlobalDataAccess? this.packagePartnerId : this.PartnerId;
    if(!this.isCustomerUser && partnerId !=null){
    this.partnerService.GetPartnerById(partnerId).subscribe((res) => {
      if(res != null && res != undefined) {
        this.binaryPackageUpload = (res.body.allowedSKUScopeId == publicSku && this.extensionType == this.extensionEnum.extension_type_business_app)? true : false;
      }
    });
  }
  }

  async getMinimumIciversion(){
    this.minimumICIVersionForBinaryPackage = await this.idnConfiguration.getMinimumIciversionForBinaryPackage();    
  }

  validateMinimumICIVersionForBinaryPackage(){
    let IciMajor = this.frmEditPackage.controls.icimajor.value;
    let IciMinor = this.frmEditPackage.controls.iciminor.value;
    let IciPatch = this.frmEditPackage.controls.icipatch.value;
    let IciBuild = this.frmEditPackage.controls.icibuild.value;
    let selectedICIVersion = `${IciMajor}.${IciMinor}.${IciPatch}.${IciBuild}`;
    if(this.binaryPackage){
      let validVersion = selectedICIVersion.localeCompare(this.minimumICIVersionForBinaryPackage, undefined, { numeric: true, sensitivity: 'base' });
      return (validVersion < versionCheck);
    } else {
      return false;
    }
  }

  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescriptionName = this.regex.descriptionErrorMessage;
      return;
    }
    if (!this.regexDescription.test(event[0])) {
      this.errorDescriptionName = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescriptionName = this.regex.descriptionErrorMessage;
    }
  }

  //To save Package And redirect to package listing page.
  async updatePackageAndClose() {
    try {
      if (this.package != undefined && this.package.name != undefined && this.package.name != "") {
        this.frmEditPackage.controls["fileName"].setValue(this.package.name);
      }
      if (!this.frmEditPackage.valid) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.package_notify_packageNotUpdated_heading);
        return;
      }
      let JSON = this.frmEditPackage.getRawValue();
      JSON['timestamp'] = this.soluPackgTimestamp;
      JSON["queuedForUpgrade"] = this.packageDetails.queuedForUpgrade;
      if(this.packageList.length){
        let selectedPackage :any = this.packageList?.filter((data:any)=>{ return data?.id == this.linkedPackage});
        if(selectedPackage.length){
          JSON["parentSolutionPackageId"] = selectedPackage[0].id;
          JSON["linkageId"] = selectedPackage[0].linkageId;
        } else {
          JSON["parentSolutionPackageId"] = this.packageDetails.parentSolutionPackageId;
          JSON["linkageId"] = this.packageDetails.linkageId;
        }
      } else {
        JSON["parentSolutionPackageId"] = this.packageDetails.parentSolutionPackageId;
        JSON["linkageId"] = this.packageDetails.linkageId;
      }
      if (!this.chooseFileDisable) {
        await this.setToken();
        this.eventObject['showProgressBar'] = 'true';
        this.eventObject['extName'] = this.frmEditPackage.value.name;
        this.eventObject['extDesc'] = this.frmEditExtension.value.description;
        this.extensionService.emit<any>(this.eventObject);
        JSON.fileName = '';
      }
      if(this.binaryPackage){
        JSON.fileName = '';
        JSON.icI_Major= JSON.icimajor;
        JSON.icI_Minor=JSON.iciminor;
        JSON.icI_Patch=JSON.icipatch;
        JSON.icI_Build=JSON.icibuild;
        JSON["parentSolutionPackageId"] = null;
        JSON["linkageId"] = null;
      }
      delete JSON.icimajor;
      delete JSON.iciminor;
      delete JSON.icipatch;
      delete JSON.icibuild;
      //API Calling
      this.extensionService.putDataUrl("api/SolutionPackage/UpdateSolutionPackage", {}, JSON).subscribe(async (result: any) => {
        if (result.status == 200) {
          let data:any={}
          data['id']=this.extId;
          data["fromCreatePackage"] = true;
          this.extensionService.emitExtDetails(data);
          let navLink = 'home/developmentandutilities/extension-management/view'
          this.router.navigate([navLink]);
          if (this.package != undefined && this.package.name != "") {
            await this.uploadPackageFileAsynchronously().then(async (result: any) => {
              if (result == "success") {
                let x = await this.updatePackageFileName(this.package, this.frmEditPackage.get("id")?.value, this.extId);
                if (x.status == 200) {
                  let res = await this.updatePackageFileStatusUploaded(this.frmEditPackage.get("id")?.value);
                  if (res.status == 200) {
                    this.notificationService.showNotification(this.extensionEnum.package_notify_packageUpdated_body, 'success', this.extensionEnum.package_notify_packageUpdated_heading)
                    this.extensionService.emit<any>(this.eventObject);
                  }
                  else {
                    this.notificationService.showNotification(this.extensionEnum.package_notify_packageNotUpdated_body, 'error', this.extensionEnum.package_notify_packageNotUpdated_heading)
                  }
                  this.mystepper.next();
                }
              }
              else {
                let res = await this.updatePackageFileStatusFailed(this.frmEditPackage.get("id")?.value);
                if (res.status == 200) {
                  this.extensionService.emit<any>(this.eventObject);
                }
              }
            });
          }
          else {
            this.notificationService.showNotification(this.extensionEnum.package_notify_packageUpdated_body, 'success', this.extensionEnum.package_notify_packageUpdated_heading)
            this.eventObject['showProgressBar'] = 'false';
            this.extensionService.emit<any>(this.eventObject);
          }
          this.frmEditPackage.reset();
        }
        else {
          this.notificationService.showNotification(this.extensionEnum.package_notify_packageNotUpdated_body, 'error', this.extensionEnum.package_notify_packageNotUpdated_heading)
        }
      },
        (error: HttpErrorResponse) => {
          console.log(error);
        }
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  //To update File Status
  async updatePackageFileStatusUploaded(packageId: number): Promise<any> {
    return await lastValueFrom(this.extensionService.postDataUrl("api/SolutionPackage/UpdateSolutionStatusPackageUploaded", { solutionPackageId: packageId }))
  }

  // To Update File Status To failed
  async updatePackageFileStatusFailed(packageId: number): Promise<any> {
    return await lastValueFrom(this.extensionService.postDataUrl("api/SolutionPackage/UpdateSolutionStatusUploadFailed", { solutionPackageId: packageId }))
  }

  //To Update Package filename
  async updatePackageFileName(packageFile: any, packageId: any, extId: any): Promise<any> {
    try {
      this.frmEditPackage.patchValue({
        id: packageId,
        extensionId: extId,
        fileName: packageFile.name,
        uniqueIdentifier: this.uniqueIdentifier
      })
      let JSON = this.frmEditPackage.getRawValue();
      JSON['timestamp'] = this.soluPackgTimestamp;
      return await this.extensionService.putDataUrl("api/SolutionPackage/UpdateSolutionPackage", {}, JSON).toPromise();
    }
    catch (exception) {
      console.log("exception :", exception);
    }
  }

  //To Save Package and move to next wizard
  async savePackageAndNext() {
  }

  //To Update data
  async updateAndCLose(val:any) {
    try {
      if (!this.frmEditExtension.valid) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
        return
      }
      if (this.isFileUploaded) {
        await this.setTokenForIdentifier()
      }
      else {
        await this.setToken();
      }
      this.eventObject['showProgressBar'] = 'true';
      this.eventObject['extName'] = this.frmEditExtension.value.name;
      this.eventObject['extDesc'] = this.frmEditExtension.value.description;
      this.extensionService.emit<any>(this.eventObject);

      let JSON = this.frmEditExtension.getRawValue();
      JSON['timestamp'] = this.extTimestamp;
      this.extensionService.putDataUrl("api/Extension/Update", {}, JSON).subscribe(async (result: any) => {
        if ((result.status == 200) || (result.status == 204) ) {
          this.extensionType =  this.frmEditExtension.value.typeId == 1 ? this.extensionEnum.extension_type_business_app : this.extensionEnum.extension_type_custom_solution;
          this.router.navigate(["home/developmentandutilities/extension-management"]);
          if (this.fileArray.length > 0) {
            await this.uploadFileAsynchronously().then(async (result: any) => {
              let x = await this.updateFileName(this.fileArray, JSON.id);
              if (x.status == 200) {
                this.notificationService.showNotification(this.extensionEnum.extension_notify_extUpdatedSuccessfully_body, 'success', this.extensionEnum.extension_notify_extUpdatedSuccessfully_heading);
              }
            });
          }
          else {
            this.notificationService.showNotification(this.extensionEnum.extension_notify_extUpdatedSuccessfully_body, 'success', this.extensionEnum.extension_notify_extUpdatedSuccessfully_heading);
            this.eventObject['showProgressBar'] = 'false';
            this.extensionService.emit<any>(this.eventObject);
            if(val == "Next"){
              this.cancel(val);
            }
          }
        }
      },
        (error) => {
          console.log(error);
        })
    }
    catch (exception) {
      console.log(exception)
    }
  }

  //Check for duplicate extension
  async checkIfExtensionExist(extName: any, customerId: any) {
    return await lastValueFrom(this.extensionService.checkForDuplicateExtension(extName, customerId))
  }

  //To Update File name which is successfully uploaded
  async updateFileName(array: any[], extId: any): Promise<any> {
    try {
      let fileNames: any[] = [];
      array.filter(x => {
        if (x.isUploaded == true) {
          fileNames.push(x.fileName);
        }
      })
      if (this.isFileUploaded && (this.listofUploadedFiles.length > 0)) {
        this.listofUploadedFiles.forEach(x => {
          fileNames.push(x);
        })
      }
      let JSON = this.frmEditExtension.getRawValue();
      if (JSON.customerId == "") {
        JSON.customerId = null;
      }
      return await this.extensionService.putDataUrl("api/Extension/Update", {}, JSON).toPromise();
    }
    catch (exception) {
      console.log(exception);
    }

  }

  //To Set SAS Token
  async setTokenForIdentifier() {
    try {
      await this.extensionService.getBlobUploadConnectionForExtension(this.frmEditExtension.value.id).subscribe((result: any) => {
        this.sas = result.sasUrl;
        this.uniqueIdentifier = result.container;
      })
    }
    catch (execption) {
      console.log(execption)
    }
  }

  //To Set SAS Token
  async setTokenForIdentifierForPackage() {
    try {
      await this.extensionService.getBlobUploadConnectionForSolution(this.frmEditPackage.value.id).subscribe((result: any) => {
        this.sas = result.sasUrl;
        this.uniqueIdentifier = result.container;
      })
    }
    catch (execption) {
      console.log(execption)
    }
  }

  //To Set SAS Token
  async setToken() {
    try {
      await this.extensionService.getBlobUploadConnectionForExtension(this.frmEditExtension.value.id).subscribe((result: any) => {
        this.sas = result.sasUrl;
        this.uniqueIdentifier = result.container;
      })
    }
    catch (execption) {
      console.log(execption)
    }
  }

  getExtensionPackageById(id: any) {
    try {
      this.extensionService.getExtensionPackageById(id).subscribe((resp: any) => {
        if (resp != null || resp != undefined || resp != "") {
          this.packageDetails = resp;
          this.soluPackgTimestamp =resp.timestamp;
          this.frmEditPackage.patchValue({
            id: resp.id,
            name: resp.name,
            description: resp.description,
            major: resp.major,
            minor: resp.minor,
            build: resp.build,
            patch: resp.patch,
            icimajor: resp.icI_Major,
            iciminor: resp.icI_Minor,
            icipatch: resp.icI_Patch,
            icibuild: resp.icI_Build,
            fileName: resp.fileName != ''? resp.fileName : resp.binaryFileName,
            uniqueIdentifier: resp.uniqueIdentifier,
            extensionId: resp.extensionId,
            status: resp.status,
            locked: resp.locked,
          })
          this.binaryPackage = resp.fileName == '';
          this.extensionType =  resp.extensionType;
          this.frmEditPackage.controls["name"].disable();
          this.frmEditPackage.controls["major"].disable();
          this.frmEditPackage.controls["minor"].disable();
          this.frmEditPackage.controls["build"].disable();
          this.frmEditPackage.controls["patch"].disable();
          this.frmEditPackage.controls["icimajor"].disable();
          this.frmEditPackage.controls["iciminor"].disable();
          this.frmEditPackage.controls["icibuild"].disable();
          this.frmEditPackage.controls["icipatch"].disable();
          this.linkedPackage = resp.parentSolutionPackageId;
          this.disableLinkedPackage = resp.linkageId != null? true :false;
          this.packagePartnerId = resp?.partnerId;
          this.packageFileAttr = this.frmEditPackage.get("fileName")?.value;
          this.getPartnerInformation();
          if (this.frmEditPackage.get("status")?.value == "Upload failed" || this.frmEditPackage.get("status")?.value == "Build failed" || this.frmEditPackage.get("status")?.value == "Virus scan failed") {
            this.chooseFileDisable = false;
          }
          if (this.frmEditPackage.get("status")?.value == "Certified package") {
            this.productTypeDisable = true;
          }

        }
      });
    } catch (exception) {
      console.log(exception);
    }

  }
  ngAfterViewInit() {
    if (this.pckageId != undefined && this.extId != undefined && this.extId != null && this.pckageId != null) {
      this.mystepper.linear = false;
      this.mystepper.selectedIndex = 1;
      this.mystepper.linear = true;
      this.isAnimationDisable=true
      this.changeDetector.detectChanges();
    }

  }
  //To Upload package file on blob
  uploadPackageFileAsynchronously() {
    return new Promise(resolve => {
      try {
          this.blobService.uploadImageforExt(this.sas, this.uniqueIdentifier, this.package, this.package.name, (response: any) => {
            if (response._response != undefined) {
              this.azUrl = response._response.request.url;
              resolve('success')
            }
            else {
              resolve('Fail')
            }
            this.eventObject['showProgressBar'] = 'false';
            this.eventObject['extid'] = this.pckageId;
            this.extensionService.emit<any>(this.eventObject);
          })
      }
      catch (execption) {
        console.log(execption);
      }
    });
  }

  //To move to previous stepper
  previousStepper() {
    this.mystepper.previous();
  }

  // To Upload Multiple Files Async
  uploadFileAsynchronously() {
    return new Promise(resolve => {
      try {
          let counter = 0;
          this.fileArray.forEach((x: any) => {
            this.blobService.uploadFiles(this.sas, this.uniqueIdentifier, x.fileContent, x.fileName, (response: any) => {
              counter += 1;
              if (response._response != undefined) {
                this.azUrl = response._response.request.url;
                x["azUrl"] = this.azUrl;
                x.isUploaded = true;
              }
              if (counter == this.fileArray.length) {
                this.eventObject['showProgressBar'] = 'false';
                resolve("Test")
                this.extensionService.emit<any>(this.eventObject);
              }
            })
          })
      }
      catch (execption) {
        console.log(execption);
      }
    });
  }

  //To bind Extension drop down
  bindExtensionDropDown() {
    try {
      this.extensionService.getExtensionTypes(this.userCategory).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.ExType = result;
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  //To bind Customer drop down
  bindCustomerDropDown() {
    const partnerId = this.permissionService.getPartnerDetails().id;
    try {    
      this.extensionService.getCustomers(partnerId).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.CustomerNameList = result;
          if(this.isCustomerUser){          
          this.frmEditExtension.controls.customerId.setValue(this.CustomerNameList[0].id);
            this.frmEditExtension.controls.customerId.disable();
            this.bindExtensionDropDown();
          }         
          this.CustomerDisplayList = this.CustomerNameList;
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  selectedCustomerName: any;
  editCustomerList: any = [];
  // Get data by Id
  getExtDataById(id: any) {
    try {
      this.extensionService.getExtensionById(id).subscribe((result: any) => {
        if (result != undefined && result != null && result != "") {
          this.ExtensionData = result;
          this.extTimestamp = result.timestamp;
          if (result.tags == null) {
            result.tags = [];
          }
          if (result.files == null) {
            result.files = [];
          }
          this.frmEditExtension.patchValue({
            name: result.name,
            description: result.description,
            typeId: result.typeId,
            customerId: result.customerId,
            tags: result.tags,
            files: result.files,
            id: result.id,
            uniqueIdentifier: result.uniqueIdentifier,
            locked: result.locked,
            customerName: result.customerName,
            partnerIds: result.partnerIds,
          });
          this.selectedCustomerName = result.customerName;
          this.editCustomerList.push(result.customerName);
          this.frmEditExtension.controls["name"].disable();
          if(this.frmEditExtension.value.locked){
            this.isLocked=true
            this.frmEditExtension.controls["description"].disable();
          }
          this.listofUploadedFiles = result.files;
          this.getPartnersByCustomerId(result.customerId);
          if (this.listofUploadedFiles.length > 0) {
            this.isFileUploaded = true;
          }
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  //To filter search result in customer name dropdown
  filterColumnCo(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.CustomerNameList = this.CustomerDisplayList.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase)
    )
  }

   //To filter search result in linked package name dropdown
   filterColumnPackageList(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.packageList = this.packageDisplayList?.filter((x:any) =>
      x.name.toLowerCase().includes(valueLowerCase)
    )
  }

  //To Add Tags
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.frmEditExtension.value.tags.push(value);
    }
    event.chipInput!.clear();
  }

  //To Remove Tags
  remove(item: any): void {
    this.items = this.frmEditExtension.value.tags;
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
      this.frmEditExtension.value.tags.splice(index, 1);
    }
  }

  //To Open Customer Name Dropdown on type selection
  extensionTypeSelect(event: any) {
    this.cd.detectChanges();
    this.mySelect.open();
  }

  //To add file on click plus sign.
  fileAdd() {
    if (this.fileAttr != null && this.fileAttr != "" && this.fileAttr != undefined && this.fileAttr != 'Choose File') {
      let isDuplicate = this.checkDuplicateFile(this.fileAttr);
      if (isDuplicate) {
        this.listofUploadedFiles.push(this.fileAttr);
        this.fileArray.push({ 'fileContent': this.file, 'fileName': this.file.name, 'isUploaded': false })
        this.fileAttr = 'Choose File';
        this.file = "";
      }
      else {
        this.fileAttr = 'Choose File';
      }
    }
    else if (this.fileAttr == 'Choose File') {
      this.notificationService.showNotification(this.extensionEnum.extension_notify_noFileChoosen_body, 'warning', this.extensionEnum.extension_notify_noFileChoosen_heading);
    }
  }

  //To check duplicate file.
  checkDuplicateFile(filename: string): boolean {
    if (this.listofUploadedFiles.includes(filename)) {
      this.notificationService.showNotification(this.extensionEnum.extension_notify_duplicateFile_body, 'warning', this.extensionEnum.extension_notify_duplicateFile_heading);
      return false;
    }
    else {
      return true;
    }
  }

  //To remove added file tags.
  removeFileTag(item: any) {
    const index = this.listofUploadedFiles.indexOf(item);
    if (index >= 0) {
      this.listofUploadedFiles.splice(index, 1);
      this.fileArray.splice(index, 1);
    }
  }

  //File upload event
  uploadFileEvt(File: any) {
    if (File.target.files && File.target.files[0]) {
      this.file = File.target.files[0];
      var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
      if (this.file.size == 0) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_emptyFile_body, 'error', this.extensionEnum.extension_notify_emptyFile_heading);
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return
      }
      if (filesize > this.fileSizeLimit) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_invalidFileSize_body, 'error', this.extensionEnum.extension_notify_invalidFileSize_heading);
        return
      }
      this.fileAttr = '';
      this.fileAttr = File.target.files[0].name;
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  //To Reset form and redirect to listing page
  async cancel(val:any) {
    let frmPackage: any = localStorage.getItem("frmPackageToEditExt");
    if (frmPackage == "true" || val == "Next") {
      localStorage.setItem("frmPackageToEditExt", "false");
      let data: any = await this.getDataByExtId(this.extId);
      this.ExType.filter((x: any) => {
        if (x.id == data.typeId) {
          data.extensionType = x.name;
        }
      })
      data["fromCreatePackage"] = true;
      this.extensionService.emitExtDetails(data);
      this.router.navigate(["home/developmentandutilities/extension-management/view"]);
    } else {
      this.frmEditExtension.reset();
      this.router.navigate(["home/developmentandutilities/extension-management"]);
    }
  }

  //Package upload event
  uploadPackageEvt(File: any) {
    if (File.target.files[0] != undefined && File.target.files[0] != null && File.target.files[0] != '') {
      var lastDot = File.target.files[0].name.lastIndexOf(".");
      const extension = File.target.files[0].name.substring(lastDot + 1).toLowerCase();
      const validExtension = this.packageExtension.filter((x) => x == extension).length > 0;
      if (!validExtension) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_invalidFileType_body, 'warning', this.extensionEnum.extension_notify_invalidFileType_heading);
        this.package = null;
        this.packageFileAttr = 'Package zip File *';
        return
      }
      this.file = File.target.files[0];
      var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
      if (filesize > this.packageSizeLimit) {
        this.notificationService.showNotification(this.extensionEnum.package_notify_fileSizeLimit_body, 'error', this.extensionEnum.package_notify_fileSizeLimit_heading);
        this.package = null;
        this.packageFileAttr = 'Package zip File *';
        return
      }
      if (File.target.files && File.target.files[0]) {
        this.package = File.target.files[0];
        this.packageFileAttr = '';
        this.packageFileAttr = File.target.files[0].name;
      }
    }
  }

  lock() {
    try {
      this.extensionService.isLocked(this.extId).subscribe((res: any) => {
        this.router.navigate(["home/developmentandutilities/extension-management"]);
        this.notificationService.showNotification(this.extensionEnum.extension_notify_extLocked_body, 'success', this.extensionEnum.extension_notify_extLocked_heading);
      })
    } catch (e) {
      console.log(e);
    }
  }


  ngOnDestroy(): void {
    localStorage.removeItem("packageId");
  }
  onFileChange(event: any) {
    if (event.target.value.slice(12) != undefined && event.target.value.slice(12) != '') {
      this.frmEditPackage.patchValue(
        { fileName: event.target.value.slice(12) });
    }
  }
  async updateExtensionandNext() {
    this.dialogService.confirmDialog({
      title: 'Confirm Update',
      module: 'Extension',
      message: this.extensionEnum.extension_dialog_Save_msg,
      confirmText: 'Update',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
         this.updateAndCLose('Next');
      }
    })
  }

  unlock() {
    try {
      this.extensionService.isUnlocked(this.extId).subscribe((res: any) => {
        this.router.navigate(["home/developmentandutilities/extension-management"]);
        this.notificationService.showNotification(this.extensionEnum.extension_notify_extUnLocked_body, 'success', this.extensionEnum.extension_notify_extUnLocked_heading);
      })
    } catch (e) {
      console.log(e);
    }
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.extensionEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.extensionEnum.redirect_dialog_body,
        confirmText: this.extensionEnum.redirect_dialog_primary_btn,
        cancelText: this.extensionEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          let navLink;
          if(this.mystepper.selectedIndex == 0){
            navLink ='https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#0-toc-title'
          } else if(this.mystepper.selectedIndex == 1){
            navLink ='https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#upload-package'
          }
          window.open(
            navLink,
            '_blank'
          );
        }
      });
  }

    //To get Extension Package List
    getSolutionPackagesForLinkage(extensionId: any,packageId:any) {
      try {
        this.extensionService.getSolutionPackagesForLinkage(extensionId, packageId).subscribe((resp: any) => {
          if (resp != undefined && resp != null && resp != "") {
            this.packageList = resp;
            this.packageDisplayList = this.packageList;
          }
        });
      } catch (exception) {
        console.log(exception);
      }
    }

    getPartnersByCustomerId(customerId:any){
      try {
        this.extensionService.getPartnersByCustomerId(customerId).subscribe((data:any)=>{
          if(data){
            this.partnerList = data;
          }
        })
      }
      catch (execption) {
        console.log(execption);
      }
    }
}
