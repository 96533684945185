import { StepperOrientation } from '@angular/material/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EnvService } from 'src/app/core/services/env.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WebAppManagement } from 'src/app/shared/enum/webAppManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { WebAppService } from 'src/app/core/services/web-app-management/web-app.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';

export interface TableFormat {
  id: number;
  key: string;
  value: string;
}

@Component({
  selector: 'app-create-web-app',
  templateUrl: './create-web-app.component.html',
  styleUrls: ['./create-web-app.component.scss'],
})
export class CreateWebAppComponent implements OnInit {
  @ViewChild('fileInput') fileInput:any;
  public webAppManagement = WebAppManagement;
  fileAttr = 'Choose File';
  listofUploadedFiles: any[] = [];
  fileArray: any[] = [];
  file: any;
  fileSizeLimit = this.env.fileSizeLimitCustomer;
  extensionArray: any = ['zip', 'rar', 'vsix'];
  noContent = true;
  dataSource: any;
  idIndex: any = 0;
  finalData: any = [];
  filename = '';

  displayedColumns: string[] = ['key', 'value', 'action'];

  breadCrumbItems: any = [
    { label: 'Home', path: 'resources' },
    { label: 'Public App Hosting', path: 'web-app-management/create' },
    { label: 'Create', path: 'web-app-management/create' },
  ];
  orientation: StepperOrientation = 'horizontal';

  frmCreateWebApp: FormGroup = new FormGroup({
    appName: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/),
    ]),
    url: new FormControl(''),
    package: new FormControl('', Validators.required),
    appSettings: new FormGroup({}),
  });

  appSettingsJson = <FormGroup>this.frmCreateWebApp.get('appSettings');
  sas: any;
  uniqueIdentifier: any;
  azUrl: any;

  constructor(
    private env: EnvService,
    private notificationService: NotificationService,
    private router: Router,
    private blobService: AzureBlobStorageService,
    private webAppService: WebAppService,
    private loader: LoaderService
  ) {}

  ngOnInit(): void {
    this.appSettingsJson.addControl('key0', new FormControl(''));
    this.appSettingsJson.addControl('value0', new FormControl(''));
    this.finalData = [
      {
        id: 0,
        key: this.appSettingsJson.get(`key${0}`)?.value,
        value: this.appSettingsJson.get(`value${0}`)?.value,
      },
    ];
    this.dataSource = new MatTableDataSource<TableFormat>(this.finalData);
  }

  addRow(row: any) {
    let index = row.id;
    let data = {
      id: row.id + 1,
      key: this.appSettingsJson.get(`key${index + 1}`)?.value,
      value: this.appSettingsJson.get(`value${index + 1}`)?.value,
    };
    this.appSettingsJson.addControl(`key${row.id + 1}`, new FormControl(''));
    this.appSettingsJson.addControl(`value${row.id + 1}`, new FormControl(''));
    this.finalData.push(data);
    this.idIndex = this.idIndex + 1;
    this.dataSource = new MatTableDataSource<TableFormat>(this.finalData);
    this.noContent = false;
  }

  deleteRow(row: any, ind: any) {
    this.appSettingsJson.removeControl(`key${ind}`);
    this.appSettingsJson.removeControl(`value${ind}`);
    this.finalData = this.finalData.filter((data: any) => {
      return data.id != row.id;
    });
    this.dataSource = new MatTableDataSource<TableFormat>(this.finalData);
  }

  getInitialWindowSize(winSize: number) {
    if (winSize <= this.env.minBreakpoint) {
      this.orientation = 'vertical';
    } else {
      this.orientation = 'horizontal';
    }
  }

  //File upload event
  uploadFileEvt(File: any) {
    let fileExtension = File.target.files[0].name.split('.').pop();
    if (this.extensionArray.indexOf(fileExtension) <= -1) {
      this.notificationService.showNotification(
        this.webAppManagement.webApp_notify_invalid_file_type_body,
        'warning',
        this.webAppManagement.webApp_notify_invalid_file_type_heading
      );
      return;
    }
    this.file = File.target.files[0];
    var filesize = Number((this.file.size / 1024 / 1024).toFixed(4));
    if (filesize > this.fileSizeLimit) {
      this.notificationService.showNotification(
        this.webAppManagement.webApp_notify_fileSizeExceed_body,
        'error',
        this.webAppManagement.webApp_notify_fileSizeExceed_heading
      );
      this.file = null;
      this.fileAttr = 'Choose File';
      return;
    }
    if (File.target.files && File.target.files[0]) {
      this.fileAttr = '';
      this.fileAttr = File.target.files[0].name;
      this.filename = File.target.files[0].name;
    }
  }

  //To remove added file tags.
  removeFileTag() {
    const index = 0;
    this.listofUploadedFiles = [];
    this.fileArray = [];
    this.frmCreateWebApp.controls['appName'].enable();
  }

  //To add file on click plus sign.
  async OnUpload() {
    if (
      this.fileAttr != null &&
      this.fileAttr != '' &&
      this.fileAttr != undefined &&
      this.fileAttr != 'Choose File'
    ) {
      this.listofUploadedFiles.push(this.fileAttr);
      let appName = this.frmCreateWebApp.get('appName')?.value;
      let resToken = await this.setTokenForPackage(appName.toLowerCase());
      if (resToken != null && resToken != undefined) {
        this.loader.show();
        this.sas = resToken.sasUrl;
        this.uniqueIdentifier = resToken.container;
      }
      this.frmCreateWebApp.controls['appName'].disable();
      this.fileArray.push({
        fileContent: this.file,
        fileName: this.frmCreateWebApp.controls['appName']?.value + '.zip',
        isUploaded: false,
      });
      await this.uploadFileAsynchronously().then((data: any) => {
        setTimeout(() => {
          this.loader.hide();
        }, 12000);
      });
    } else if (this.fileAttr == 'Choose File') {
      this.notificationService.showNotification(
        this.webAppManagement.webApp_notify_choose_file_body,
        'warning',
        this.webAppManagement.webApp_notify_choose_file_heading
      );
    }
  }
  
  async onSubmit() {
    if (this.listofUploadedFiles.length > 0) {
      this.frmCreateWebApp.value.files = this.listofUploadedFiles;
    }
    let appSettings: any = [];
    this.finalData.forEach((row: any) => {
      if (row.key != undefined) {
        let data = {
          key: row.key,
          value: row.value,
        };
        appSettings.push(data);
      }
    });

    let JSON = {
      resourceGroupName: '',
      location: '',
      subscriptionId: '',
      deploymentId: '',
      webAppName: this.frmCreateWebApp.get('appName')?.value,
      appSettingJson: appSettings,
    };
    if (this.fileArray.length > 0) {
      this.webAppService.deployAzureAppService(JSON).subscribe(async (data) => {
        if (data.body.isSuccessful) {
          this.notificationService.showNotification(
            data.body.content,
            'success',
            this.webAppManagement.webApp_notify_submit_successful
          );
        } else {
          this.notificationService.showNotification(
            'Failed to create Public App',
            'error',
            this.webAppManagement.webApp_notify_submit_unsuccessful
          );
        }
        this.resetWebApp();
      });
    }
  }

  resetWebApp(){
    this.fileAttr = 'Choose File';
    this.file = null;
    this.fileArray = [];
    this.fileInput.nativeElement.value = "";
    this.frmCreateWebApp.reset();
    this.router.navigate(['home/web-app-management']);
    this.appSettingsJson.addControl('key0', new FormControl(''));
    this.appSettingsJson.addControl('value0', new FormControl(''));
    this.finalData = [
      {
        id: 0,
        key: this.appSettingsJson.get(`key${0}`)?.value,
        value: this.appSettingsJson.get(`value${0}`)?.value,
      },
    ];
    this.dataSource = new MatTableDataSource<TableFormat>(this.finalData);
    this.removeFileTag();
  }

  onCancel() {
    this.resetWebApp();
  }

  async setTokenForPackage(appName: any): Promise<any> {
    try {
      return this.webAppService
        .getBlobUploadConnectionForWebApp(appName)
        .toPromise();
    } catch (execption) {
      console.log(execption);
    }
  }

  uploadFileAsynchronously() {
    return new Promise((resolve) => {
      try {
        let counter = 0;
        this.blobService.uploadFiles(
          this.sas,
          this.uniqueIdentifier,
          this.fileArray[0].fileContent,
          this.fileArray[0].fileName,
          (response: any) => {
            counter += 1;
            if (response._response != undefined) {
              this.azUrl = response._response.request.url;
              this.fileArray[0]['azUrl'] = this.azUrl;
              this.fileArray[0].isUploaded = true;
            }
            if (counter == this.fileArray.length) {
              resolve('Test');
            }
          }
        );
      } catch (execption) {
        console.log(execption);
      }
    });
  }
}
