import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { SharedComponent } from 'src/app/shared/enum/sharedComponentEnum';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-view-approval',
  templateUrl: './view-approval.component.html',
  styleUrls: ['./view-approval.component.scss'],
})
export class ViewApprovalComponent implements OnInit {
  breadCrumbItems: any;
  viewData: any;
  approvalStatus:any = {
    pending:1,
    approved:2,
    rejected:3
  }
  featureCategories : any = {
    debuggingUtilities : 1,
    hangfireDashboard: 3
  }
  public sharedEnum = SharedComponent; 
  public instanceEnum = InstanceManagement;
  featureId:any;
  featureCategoryId:any;
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private titleService: Title,
    private instanceService: InstanceService,
    private notificationService:NotificationService,
    private permissionService: PermissionsService
  ) {}
  ngOnInit(): void {
    this.titleService.setTitle('Actionables | Icertis Developer Network');
    this.breadCrumbItems = [
      { label: 'Home', path: 'actionables' },
      { label: 'My Tasks', path: 'actionables' },
      { label: 'Approval Requests', path: 'actionables/my-approvals' },
      {
        label: 'View',
        path: 'actionables/my-approvals/view-approvals',
      },
    ];
      this.getFeatureAccessrequestDetails();
  }

  getFeatureAccessrequestDetails() {
    let id = Number(localStorage.getItem('debuggingUtilitiesAccessRequestId'));
    this.instanceService.viewFeatureAccessRequest(id).subscribe((resp: any) => {
      if (resp) {
        this.viewData = resp?.body?.content;
        this.featureCategoryId = this.viewData?.featureCategoryId;
        this.featureId = this.viewData?.featureId;
      }
    });
  }

  approveRequest() {
    if (this.featureCategoryId != this.featureCategories.hangfireDashboard){
      this.dialogService
      .confirmDialogComments({
        title: 'Confirm Approval',
        module: 'accessDebuggingUtilities',
        message: this.viewData?.requestedDuration,
        confirmText: 'Approve',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if(res){
          let param ={
            featureCategoryId:this.viewData?.featureCategoryId,
            instanceId:this.viewData?.instanceId,
            featureId:this.featureId,
            statusId:res.approved == true? 2 : 3,
            requesterUserId:this.viewData?.requesterUserId,
            requesterComment:this.viewData?.requesterComment,
            requestedDuration:this.viewData?.requestedDuration,
            approverUserId:this.permissionService.getUniqueUserid(),
            approverComment:res.comment,
            approvedDuration:res.duration == '2 Hours'? 2 : res.duration == '4 Hours'? 4 : 8 
  
          }
          this.instanceService.ApproveRejectFeatureAccessRequest(param).subscribe((res:any)=>{
          if(res?.body?.isSuccessful){
            this.notificationService.showNotification(this.instanceEnum.instance_tools_debugging_utilities_success_body,'success',this.instanceEnum.request_success_header);
          }else{
            this.notificationService.showNotification(this.instanceEnum.instance_tools_debugging_utilities_error_body,'error',this.instanceEnum.request_error_header);
          }
          this.instanceService.getUpdatedCount(true);
          this.getFeatureAccessrequestDetails();
          let navLink = 'home/actionables/my-approvals';
          this.router.navigate([navLink]);
          });
        }   
      });
    }
    else{
      this.dialogService
      .confirmDialogComments({
        title: 'Confirm Approval',
        module: 'accessToHangfireDashboard',
        message: '',
        confirmText: 'Approve',
        cancelText: 'Cancel',
      })
      .subscribe((res:any)=>{
        if(res){
          let param ={
            featureCategoryId:this.viewData?.featureCategoryId,
            instanceId:this.viewData?.instanceId,
            featureId: this.featureId,
            statusId:res.approved == true? 2 : 3,
            requesterUserId:this.viewData?.requesterUserId,
            requesterComment:this.viewData?.requesterComment,
            requestedDuration:this.viewData?.requestedDuration,
            approverUserId:this.permissionService.getUniqueUserid(),
            approverComment:res.comment,
            approvedDuration:0 
  
          }
          this.instanceService.ApproveRejectFeatureAccessRequest(param).subscribe((res:any)=>{
          if(res?.body?.isSuccessful){
            this.notificationService.showNotification(this.instanceEnum.instance_tools_hangfire_success_body,'success',this.instanceEnum.request_success_header);
          }else{
            this.notificationService.showNotification(this.instanceEnum.instance_tools_hangfire_error_body,'error',this.instanceEnum.request_error_header);
          }
          this.instanceService.getUpdatedCount(true);
          this.getFeatureAccessrequestDetails();
          let navLink = 'home/actionables/my-approvals';
          this.router.navigate([navLink]);
          });
        }        
      })
    }
    
  }
  rejectRequest() {
    if(this.featureCategoryId != this.featureCategories.hangfireDashboard){
      this.dialogService
      .confirmDialogComments({
        title: 'Confirm Rejection',
        module: 'accessDebuggingUtilities',
        message: '',
        confirmText: 'Reject',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if(res){
          let param ={
            featureCategoryId:this.viewData?.featureCategoryId,
            featureId:this.featureId,
            instanceId:this.viewData?.instanceId,
            statusId:res.approved == true? 2 : 3,
            requesterUserId:this.viewData?.requesterUserId,
            requesterComment:this.viewData?.requesterComment,
            requestedDuration:this.viewData?.requestedDuration,
            approverUserId:this.permissionService.getUniqueUserid(),
            approverComment:res.comment,
            approvedDuration: null
  
          }
          this.instanceService.ApproveRejectFeatureAccessRequest(param).subscribe((res:any)=>{
          if(res?.body?.isSuccessful){
            this.notificationService.showNotification(this.instanceEnum.instance_tools_debugging_utilities_error_body,'error',this.instanceEnum.request_error_header);
          }else{
            this.notificationService.showNotification(this.instanceEnum.instance_tools_debugging_utilities_failedtodecline_body,'error',this.instanceEnum.request_failed_header);
          }
          this.instanceService.getUpdatedCount(true);
          this.getFeatureAccessrequestDetails();
          let navLink = 'home/actionables/my-approvals';
          this.router.navigate([navLink]);
          })
        }
      });
    }
    else{
      this.dialogService
      .confirmDialogComments({
        title: 'Confirm Rejection',
        module: 'accessToHangfireDashboard',
        message: '',
        confirmText: 'Reject',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if(res){
          let param ={
            featureCategoryId:this.viewData?.featureCategoryId,
            featureId:this.featureId,
            instanceId:this.viewData?.instanceId,
            statusId:res.approved == true? 2 : 3,
            requesterUserId:this.viewData?.requesterUserId,
            requesterComment:this.viewData?.requesterComment,
            requestedDuration:0,
            approverUserId:this.permissionService.getUniqueUserid(),
            approverComment:res.comment,
            approvedDuration: null
  
          }
          this.instanceService.ApproveRejectFeatureAccessRequest(param).subscribe((res:any)=>{
          if(res?.body?.isSuccessful){
            this.notificationService.showNotification(this.instanceEnum.instance_tools_hangfire_error_body,'error',this.instanceEnum.request_error_header);
          }else{
            this.notificationService.showNotification(this.instanceEnum.instance_tools_hangfire_failedtodecline_body,'error',this.instanceEnum.request_failed_header);
          }
          this.instanceService.getUpdatedCount(true);
          this.getFeatureAccessrequestDetails();
          let navLink = 'home/actionables/my-approvals';
          this.router.navigate([navLink]);
          })
        }
      });
    }
  }
  cancelRequest() {
    this.instanceService.getUpdatedCount(true);
    let navLink = 'home/actionables/my-approvals';
    this.router.navigate([navLink]);
  }
}
