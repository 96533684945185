<app-pageheader [pageTitle]="'SDK Mangement/Solution_Revised'" [editInstance]="ViewData.statusId==1 && isEditInstance"
  [breadCrumbs]="breadCrumbItems">
</app-pageheader>

<app-preview-upgrade (closeD)="closedrawer($event)" *ngIf="enablePreviewDrawer" [podDetails]="podDetails"></app-preview-upgrade>

<div class="page-container">
  <div class="idn-view-wrapper">
    <div class="idn-view-header">
      <div class="data-left">
        <div class="idn-view-header-label">{{mtPodEnum.multiTenantPods_View_Header}}</div>
        <div class="idn-view-header-data">
          <h3>{{podName}}</h3>
        </div>
      </div>
    </div>

    <div class="idn-view-body pss-dashboard-form">
      <div class="pss-left-panel">

        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_region}}</div>
          <div class="idn-view-body-items-data">{{ViewData.deploymentRegion}}</div>
        </div>

        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_podName}}</div>
          <div class="idn-view-body-items-data">{{ViewData.masterTenantCname}}</div>
        </div>

      </div>

      <div class="pss-right-panel">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_subscription}}</div>
          <div class="idn-view-body-items-data">{{ViewData.subscription}}</div>
        </div>

        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_PODIciVersion}}</div>
          <div class="idn-view-body-items-data">{{ViewData.iciVersion}}</div>
        </div>

      </div>
    </div>

    <div class="idn-view-body-items">
      <div class="idn-table">


        <div class="table-scroll" *ngIf="isViewMtpodSnapshot">
          <h2>{{mtPodEnum.multiTenantPods_View_Field_extension}}</h2>
          <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position">
            <!-- Instance Name Column -->
            <ng-container matColumnDef="tenantName">
              <th mat-header-cell *matHeaderCellDef matTooltip="Tenant Name">
                {{mtPodEnum.multiTenantPods_View_Field_tenantName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.instanceName}} </td>
            </ng-container>

            <!-- Extension Name Column -->
            <ng-container matColumnDef="extensionName">
              <th mat-header-cell *matHeaderCellDef matTooltip="Extension Name">
                {{mtPodEnum.multiTenantPods_View_Field_extensionName}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.extensionName}} </td>
            </ng-container>

            <!-- Versione Column -->
            <ng-container matColumnDef="version">
              <th mat-header-cell *matHeaderCellDef matTooltip="Existing Extension Package Version">
                {{mtPodEnum.multiTenantPods_View_Field_version}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.version}} </td>
            </ng-container>

            <!--Target ICI Version Column -->
            <ng-container matColumnDef="iciVersion">
              <th mat-header-cell *matHeaderCellDef matTooltip="Extension ICI Version">
                {{mtPodEnum.multiTenantPods_View_Field_ExtensionIciVersion}}
              </th>
              <td mat-cell *matCellDef="let element"> {{element.iciVersion}} </td>
            </ng-container>

            <ng-container matColumnDef="deployed">
              <th mat-header-cell *matHeaderCellDef matTooltip="Deployed">
                DEPLOYED
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-chip-list aria-label="tags">
                  <mat-chip [ngClass]="element.deploymentStatus == 'Deployment Completed' ? 'yes-class' : 'no-class'">
                    <mat-icon *ngIf="element.deploymentStatus == 'Deployment Completed'">check_circle</mat-icon>
                    <mat-icon *ngIf="element.deploymentStatus != 'Deployment Completed'">cancel</mat-icon>
                    <span class="idn-limit-tag">{{element.deploymentStatus=='Deployment Completed'?"Yes":"No"}}</span>
                  </mat-chip>
                </mat-chip-list>
              </td>
            </ng-container>

            <tr mat-header-row
              *matHeaderRowDef="this.isApproveInstance?displayedColumnsOps:displayedColumnsAdmin; sticky: true">
            </tr>
            <tr mat-row *matRowDef="let row; columns: this.isApproveInstance?displayedColumnsOps:displayedColumnsAdmin;"
              [ngClass]="{highlighted: selection.isSelected(row)}" (click)="selectRow(row)">

            </tr>
          </table>

          <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]=pageSize class="mat-paginator-sticky"
            (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
            showFirstLastButtons aria-label="Select page of periodic elements"
            [ngClass]="{'hide-pagination': noContent ? true : false}">
          </mat-paginator>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="pss-form-button-wrapper">
  <button mat-flat-button color="primary" (click)="upgradeTenantPod(ViewData)" *ngIf="isViewPreviewUpgrade && enableLaActivity">
    {{mtPodEnum.btn_label}}
  </button>
  <button mat-flat-button (click)="cancel()">{{mtPodEnum.multiTenantPods_cancel}}</button>
</div>