import { Injectable } from '@angular/core';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageService {

  constructor(){}

  Url: any;
  // Enter your storage account name
  picturesAccount:string= environment.azureAccount;
  uploadAccount:string =  environment.azUploadAccount;
  // container name
  picturesContainer = "sdk";

  public uploadImage(sas: string, content: Blob, name: string, handler: (response: any) => void) {

    this.uploadBlob(content, name, this.containerClient(sas), handler)

  }

  public listImages(sas: string): Promise<string[]> {
    return this.listBlobs(this.containerClient(sas))
  }

  public downloadImage(sas: string, name: string, handler: (response: any) => void) {
    this.downloadBlob(name, this.containerClient(sas), handler)
  }

  public deleteImage(sas: string, name: string, handler: () => void) {
    this.deleteBlob(name, this.containerClient(sas), handler)
  }

  private uploadBlob(content: Blob, name: string, client: ContainerClient, handler: { (response: any): void; }) {

    let blockBlobClient = client.getBlockBlobClient(name);

    blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })

      .then(
        (onres) => {
          handler(onres)
        },
        (onRejected) => {
          handler(onRejected)
        })

  }

  private async listBlobs(client: ContainerClient): Promise<string[]> {
    let result: string[] = []

    let blobs = client.listBlobsFlat();
    for await (const blob of blobs) {
      result.push(blob.name)
    }

    return result;
  }

  private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
    const blobClient = client.getBlobClient(name);
    blobClient.download().then((resp: any) => {
      handler(resp)
    }, (err: any) => {
      handler(err.response)
    })
  }

  private deleteBlob(name: string, client: ContainerClient, handler: () => void) {
    client.deleteBlob(name).then(() => {
      handler()
    })
  }

  public containerClient(sas: string): ContainerClient {
    return new BlobServiceClient(`https://${this.picturesAccount}.blob.core.windows.net?${sas}`)
      .getContainerClient(this.picturesContainer);
  }

  public containerClientUpload(sas: string): ContainerClient {
    return new BlobServiceClient(`https://${this.uploadAccount}.blob.core.windows.net?${sas}`)
      .getContainerClient(this.picturesContainer);
  }


  public uploadImageforExt(sas: string, uniqueIdentifier: string, content: Blob, name: string, handler: (response: any) => void) {
    this.picturesContainer = uniqueIdentifier;
    this.uploadBlob(content, name, this.containerClientUpload(sas), handler)
  }

  public downloadImageExt(sas: string, uniqueIdentifier: string, fileName: any, handler: (response: any) => void) {
    this.picturesContainer = uniqueIdentifier;
    this.downloadBlob(fileName, this.containerClient(sas), handler)
  }

  //Instance Management
  public containerSasClient(sas: string, container: string): ContainerClient {
    return new BlobServiceClient(sas)
      .getContainerClient(container);
  }

  public uploadFiles(sas: string, container: string, content: Blob, name: string, handler: (response: any) => void) {
    this.uploadBlob(content, name, this.containerSasClient(sas, container), handler)
  }

  public downloadInstanceFiles(sasUrl:string, container:string, fileName:string, handler:(response:any) => void) {
    this.downloadBlob(fileName, this.containerSasClient(sasUrl, container), handler)
  }
}
