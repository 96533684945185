import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { EnvService } from '../../src/app/core/services/env.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('slide', [
      transition('void => *', [
        style({
          marginTop: '-100px',
          opacity: 0
        }),
        animate(500, style({
          marginTop: '0',
          opacity: 1
        }))
      ]),
      transition('* => void',[
        style({
          marginTop: '0',
          opacity: 1
        }),
        animate(500, style({
          marginTop: '-100px',
          opacity: 0
        }))
      ])
    ])
  ]
})
export class AppComponent {
  isPushnotification: boolean = false;
  LoginStatus: boolean = false;
  userNotificationAction = false;
  appNotification: string = '';
  title: string = '';
  constructor(
    private msalService:MsalService,
    public translate: TranslateService,
    private ref: ChangeDetectorRef,
    private router:Router,
    private env: EnvService,
    private titleService: Title,
    private bnIdle: BnNgIdleService,
  ){
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.titleService.setTitle('Icertis Developer Network');
    this.bnIdle.startWatching(this.env.autoLogoutTime).subscribe((isTimeout: boolean) => {
      if(isTimeout){
        let homeaccound = (this.msalService.instance.getActiveAccount())?.homeAccountId;
        let loginhint = (this.msalService.instance.getActiveAccount())?.idTokenClaims?.login_hint;
        const currentAccount = this.msalService.instance.getAccountByHomeId(homeaccound!);
        if(currentAccount){
          this.msalService.instance.logoutRedirect({ account: currentAccount, idTokenHint: loginhint, logoutHint: loginhint });
          localStorage.removeItem("authenticatedUser");
          localStorage.clear();
        }
      }
    })
      this.appNotification = this.env.appNotificationBody;
      document.cookie = 'present=true';
      var interval = setInterval(() => {
        if (!document.cookie || document.cookie.indexOf('present') === -1) {
          clearInterval(interval);
          this.msalService.instance.logout();
        }
      }, 2000);

      if (localStorage.getItem("loggedin") == null) {
        localStorage.setItem("loggedin", "unresolved");
      this.router.navigate(['']);
    }
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
    this.isPushnotification = this.env.appNotificationStatus;

    if(localStorage.getItem('appPushnotification') === 'true'){
      this.userNotificationAction = true;
    } else {
      this.userNotificationAction = false;
    }

    if(localStorage.getItem('authenticatedUser')){
      this.LoginStatus = true;
    } else{
      this.LoginStatus = false;
    }
  }

  closeNotification(): void {
    this.isPushnotification = !this.isPushnotification;
    localStorage.setItem('appPushnotification', 'false');
  }

}
