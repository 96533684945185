import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';
import * as _ from 'lodash';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { catchError,take } from 'rxjs';
import { map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { Sort } from '@angular/material/sort';
import * as JSZip from 'jszip';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { MatIconRegistry } from '@angular/material/icon';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`
export interface PeriodicElement {
  extensiontype: string;
  extensionname: string;
  customername: string;
  extensiontag: string;
  extensionFiles: string;
  action: string;
}

export interface Extension {
  id: number;
  name: string;
}

@Component({
  selector: 'app-extension-list',
  templateUrl: './extension-list.component.html',
  styleUrls: ['./extension-list.component.scss']
})
export class ExtensionListComponent implements OnInit {
  displayedColumnsCustomeruser: string[] = ['extensionType', 'name', 'extensiontag', 'extensionFiles', 'status', 'action'];
  displayedColumns: string[] = ['extensionType', 'name', 'customerName', 'extensiontag', 'extensionFiles', 'status', 'action'];
  ExType: any[] = [{ value: 'Extension All' }];
  uploadextData: any = { 'showProgressBar': 'false' };
  dataSource: any
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  selectAll = "selectAll";
  sas: any;
  customerList: any;
  isViewExtension:any;
  noContent: Boolean = false;
  isLockExtensionPermission:any;
  isUnLockExtensionPermission:any;
  isUpdateExtensionPermission:any;
  isCreateExtensionPermission:any;
  isViewSolutionPackagePermission:any;
  isCreateRole:any;
  public extensionEnum = ExtensionManagement;
  currentUserId: any;
  //Filter
  searchfilter: any = '';
  sortBy: any = '';
  pageNumber = 1;
  pageSize = 10;
  extensionType: any = '';
  customer: any = '';
  currentPage = 0;
  pageLength: any = 0;
  listPartner: boolean =false;
  globalDataAccess: boolean =false;
  partnerData:any={
    title: 'Partner',
    list: [],
  };
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('custName') custName !: MatSelect;
  @ViewChild('extType') extType !: MatSelect;
  userCategory:any;
  isCustomerUser:boolean = false;
  constructor(
    private extensionservice: ExtensionService,
    private titleService: Title,
    private router: Router,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private cd: ChangeDetectorRef,
    private msalService: MsalService,
    private permissionService: PermissionsService,    
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Development and Utilities", path: "developmentandutilities/extension-management" },
    { label: "Extension Management", path: "developmentandutilities/extension-management" },
    { label: "List", path: "developmentandutilities/extension-management" },
  ];

  ngAfterViewInit() {
    this.searchInput.nativeElement.value = this.searchfilter;
    this.extType.value = this.extensionType;
    this.custName.value = this.customer
    this.cd.detectChanges();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.isViewExtension = this.permissionService.getPermission("ViewExtension");
    this.isLockExtensionPermission = this.permissionService.getPermission("LockExtension");
    this.isUpdateExtensionPermission = this.permissionService.getPermission("UpdateExtension");
    this.isUnLockExtensionPermission = this.permissionService.getPermission("UnLockExtension");
    this.isCreateExtensionPermission = this.permissionService.getPermission("CreateExtension");
    this.isViewSolutionPackagePermission = this.permissionService.getPermission("ViewSolutionPackage");
    this.isCreateRole = this.permissionService.getPermission("CreateRole");
    this.currentUserId = this.permissionService.getUniqueUserid();
    this.userCategory=this.permissionService.getUsercategoryId();
    this.globalDataAccess = this.permissionService.hasGlobalDataAccess();
    if(this.globalDataAccess){
      this.displayedColumns.splice(3,0,'partnerName')
    }
    if(this.userCategory ==  USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
    }
    else{
      this.isCustomerUser = false;
    }
    localStorage.removeItem('extId');
    localStorage.removeItem('packageId');
    localStorage.removeItem('extIdFromPckgList');
    localStorage.removeItem('fromViewPackage');
    this.bindExtensionDropDown();
    try {
      this.extensionservice.on<any>().pipe(take(2)).subscribe(
        data => {
          if (data != null && data != "") {
            if(data.hasOwnProperty('saveAndClose')){
              this.uploadextData = data;               
                this.cd.detectChanges();
            }
          }
        }
      );

      if (localStorage.getItem("loggedin") != null && localStorage.getItem("loggedin") == "false") {
        this.msalService.instance.logoutRedirect();
      }
    }
    catch (error) {
      console.log(error)
    }
    this.titleService.setTitle('Extension Management | IDN');
    this.getExtension();
    this.cd.detectChanges();
  }

  //To Edit sdk
  edit(navLink: any, element: any) {
    let partnerDetails = this.permissionService.getPartnerDetails();
    let hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    let Json;
    if(hasGlobalDataAccess || partnerDetails.id || this.isCustomerUser){
      if(this.isCustomerUser){
      Json = {
        'search': this.searchInput.nativeElement.value,
        'extensionType': this.extType.value,        
        'pagesize': this.pageSize,
        'pageNumber': this.pageNumber,
        'currentPage':this.currentPage
      };
    }
    else{
      Json = {
        'search': this.searchInput.nativeElement.value,
        'extensionType': this.extType.value,
        'customer': this.custName.value,
        'pagesize': this.pageSize,
        'pageNumber': this.pageNumber,
        'currentPage':this.currentPage
      };

    }
      localStorage.setItem("FilterRecordExtension", JSON.stringify(Json))
      if (element.locked == true) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_extAlreadyLocked_body, 'warning', this.extensionEnum.extension_notify_extAlreadyLocked_heading);
      } else {
        localStorage.setItem("extId", element.id);
        navLink = 'home/developmentandutilities/' + navLink;
        this.router.navigate([navLink]);
      }
    } else {
      this.notificationService.showNotification(this.extensionEnum.extension_notify_error_message_for_no_partner_body, 'error', this.extensionEnum.extension_notify_edit_error_message_for_no_partner_heading);
    }
  }

  //To Get Extension  list
  getExtension() {
    try {
      let filterValue: any = localStorage.getItem("FilterRecordExtension");
      if (filterValue != undefined && filterValue != null && filterValue != '') {
        this.searchfilter = JSON.parse(filterValue).search
        this.extensionType = JSON.parse(filterValue).extensionType != undefined && JSON.parse(filterValue).extensionType != this.selectAll ? JSON.parse(filterValue).extensionType : ""
        this.customer = JSON.parse(filterValue).customer != undefined && JSON.parse(filterValue).customer != this.selectAll ? JSON.parse(filterValue).customer : "";
        }
      this.extensionservice.getExtensions(this.searchfilter, this.sortBy, this.pageNumber, this.pageSize, this.extensionType, this.customer).subscribe((resp: any) => {
        if(resp == null || resp == undefined || resp == "" || resp.records.length == 0) {
          this.noContent = true;
        } else {
          this.noContent = false;
          this.dataSource = new MatTableDataSource(resp.records);
          this.customerList = resp.customers?.filter((data:any)=> data !=null);
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = resp.matchingCount;
          this.paginator._intl.itemsPerPageLabel = 'Records per page';
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  //To Redirect dashboard page
  navigateItem(navLink: any): void {
    if (navLink == 'dashboard') {
      navLink = 'dashboard';
    } else {
      navLink = 'home/developmentandutilities/' + navLink;
    }
    this.router.navigate([navLink]);
  }

  //To View Role
  ViewExtenstion(navLink: any, element: any) {
    let Json;
    if(this.isCustomerUser){
    Json = {
      'search': this.searchInput.nativeElement.value,
      'extensionType': this.extType.value,      
      'pagesize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage':this.currentPage
    };
  }
  else{
    Json = {
      'search': this.searchInput.nativeElement.value,
      'extensionType': this.extType.value,
      'customer': this.custName.value,
      'pagesize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage':this.currentPage
    };
    }
   localStorage.setItem("FilterRecordExtension", JSON.stringify(Json))
    this.extensionservice.emitExtDetails(element);
    this.extensionservice.emitExt<any>(element);
    navLink = 'home/developmentandutilities/' + navLink;
    this.router.navigate([navLink]);
  }

  
  ngOnDestroy(): void {
    const url1:string ='/home/developmentandutilities/extension-management/view';
    const url2:string='/home/developmentandutilities/extension-management/edit';
      if(window.location.pathname != url1 && window.location.pathname != url2){
        localStorage.removeItem('FilterRecordExtension'); 
      } 
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
    this.filter();
  }

  filter() {
    let filters = localStorage.getItem('FilterRecordExtension');
    if (filters != null) {
      let Json = {
        'search': this.searchInput.nativeElement.value,
        'extensionType': this.extType.value,
        'customer': this.custName.value,
        'pagesize': this.pageSize,
        'pageNumber': this.pageNumber,
        'currentPage':this.currentPage
      };
      localStorage.setItem("FilterRecordExtension", JSON.stringify(Json))
    }
    else{
    this.searchfilter = this.searchInput.nativeElement.value;
    if(this.extType.value != undefined && this.extType.value != '' && this.extType.value != this.selectAll){
      this.extensionType = this.extType.value;
    } else {
      this.extensionType = '';
    }
    if (this.userCategory !== USER_CATEGORY_ID.Customer) {
      if (
        this.custName.value != undefined &&
        this.custName.value != '' &&
        this.custName.value != this.selectAll
      ) {
        this.customer = this.custName.value;
      } else {
        this.customer = '';
      }
    }
  }
    this.getExtension();
  }

  // ** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.sortBy = sortState.active + (sortState.direction == "asc" ? '' : '_' + sortState.direction);
    this.getExtension();
  }


  //To bind Extension drop down
  bindExtensionDropDown() {
    try {
      this.extensionservice.getExtensionTypes(this.userCategory).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.ExType = result;
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  //Download zip files
  onDownload = async (el: any) => {
    try {
      const zip = new JSZip();
      var FileSaver = require('file-saver');
      let downloadedFiles: any = [];
      el.files.forEach((x: any) => {
        this.extensionservice
          .getBlobDownloadConnectionForExtension(el.id, x)
          .pipe(
            map((data: any) => {
              this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
                if (response._response != undefined && response._response.status == "200") {
                  response.blobBody.then(
                    (onres: any) => {
                      downloadedFiles.push([x, onres]);
                      if (el.files.length === downloadedFiles.length) {
                        downloadedFiles.forEach((item: any) => {
                          zip.file(item[0], item[1]);
                        });
                        zip.generateAsync({ type: "blob" })
                          .then(function (content) {
                            FileSaver.saveAs(content, el.name);
                          });
                        this.notificationService.showNotification(this.extensionEnum.extension_notify_extDowloaded_body, 'success', this.extensionEnum.extension_notify_extDowloaded_heading);
                      }

                    })
                }
                else {
                  this.notificationService.showNotification(this.extensionEnum.extension_notify_extDowloadedFailed_body, 'error', this.extensionEnum.extension_notify_extDowloadedFailed_heading);
                }
              });
            }),
            catchError((err) => {
              console.log(err);
              return err;
            })
          ).subscribe()
      });
    }
    catch (error) {
      console.log(error)
    }
  };

  async openPackage(element: any) {
    let Json;
    if(!this.isCustomerUser){      
     Json = {
      'search': this.searchInput.nativeElement.value,
      'extensionType': this.extType.value,
      'customer': this.custName.value,
      'pagesize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage':this.currentPage
    };
  }
  else{
     Json = {
      'search': this.searchInput.nativeElement.value,
      'extensionType': this.extType.value,      
      'pagesize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage':this.currentPage
    };

  }
    localStorage.setItem("FilterRecordExtension", JSON.stringify(Json))
    localStorage.setItem("extId", element.id);
    element["fromCreatePackage"] = true;
    this.extensionservice.emitExtDetails(element);
    let navLink = 'home/developmentandutilities/extension-management/view'
    this.router.navigate([navLink]);
  }

  lock(el: any) {
    try {
      this.extensionservice.isLocked(el.id).subscribe((res: any) => {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_extLocked_body, 'success', this.extensionEnum.extension_notify_extLocked_heading);
        this.getExtension();
      })
    } catch (e) {
      console.log(e);
    }
  }

  unlock(el: any) {
    try {
      this.extensionservice.isUnlocked(el.id).subscribe((res: any) => {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_extUnLocked_body, 'success', this.extensionEnum.extension_notify_extUnLocked_heading);
        this.getExtension();
      })
    } catch (e) {
      console.log(e);
    }
  }

  clearFilter() {
      localStorage.removeItem("FilterRecordExtension")
      this.searchInput.nativeElement.value = ""
      if (this.userCategory != USER_CATEGORY_ID.Customer) {
        this.custName.value = '';
      }
      this.extType.value = ""
      this.pageNumber = 1;
      this.pageSize = 10;
      this.currentPage = 0;
      this.filter();
  }
  
  ClearInput(event: any, filter: any) {
    this.searchInput.nativeElement.value = '';
    this.filter();
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.extensionEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.extensionEnum.redirect_dialog_body,
        confirmText: this.extensionEnum.redirect_dialog_primary_btn,
        cancelText: this.extensionEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          window.open(
            'https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#0-toc-title',
            '_blank'
          );
        }
      });
  }

  closeListDrawer(event:any){
    if(event == false){
      this.listPartner = event
    } else{
      this.listPartner = true;
      this.partnerData = {
        title:"Partner",
        list: event.partners
      }
    }
  }
  
}

