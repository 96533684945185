<app-pageheader [pageTitle]="'Instance Requests > Create'" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
    <div class="form-wrapper">
        <h2>{{instanceEnum.instance_header_editInstance}}</h2>
        <form class="pss-dashboard-form" [formGroup]="frmEditInstance">
            <div class="pss-left-panel">

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_customerName}}</mat-label>
                    <mat-select (selectionChange)="GetInstanceTypeByCustomerId($event.value);getPartnersByCustomerId($event.value);resetFormFields()" formControlName="customerId" 
                        disableOptionCentering #customername required>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search Customers"
                                noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlCo">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let customer of customers" [value]="customer.id">
                            {{customer.customerName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.customerId.invalid">{{StateEnum.INSTCname}}</mat-error>
                </mat-form-field>

                <mat-form-field class="pss-block-form" *ngIf="hasGlobalDataAccess">
                    <mat-label>{{instanceEnum.instance_Create_Field_partnerName}}</mat-label>
                    <mat-select formControlName="partnerIds" disableOptionCentering multiple (selectionChange)="getUsersByCustomerIdAndPartners($event.value)"
                        #partnername>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search Partners"
                                noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlPartner">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let partner of partners" [value]="partner.id">
                            {{partner.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.partnerIds.invalid">{{StateEnum.INSTPartnerName}}</mat-error>
                </mat-form-field>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_typeof_instance}}</mat-label>
                    <mat-select formControlName="typeOfInstanceId" disableOptionCentering required>
                        <mat-option *ngFor="let item of typeOfInstance" [value]="item.id">
                            {{item.typeOfInstanceName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.typeOfInstanceId.invalid">{{
                        instanceEnum.instance_create_error_message_requiredField }}</mat-error>
                </mat-form-field>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_environment}}</mat-label>
                    <mat-select formControlName="instanceTypeId" disableOptionCentering
                        required>
                        <mat-option *ngFor="let environment of instanceType" [value]="environment.id">
                            {{environment.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.instanceTypeId.invalid">
                        {{instanceEnum.instance_create_error_message_requiredField}} </mat-error>
                </mat-form-field>


                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_cName}}</mat-label>
                    <span matSuffix>{{cnameSuffix}}</span>
                    <input type="text" maxlength="63" formControlName="cname" [ngModel]="inputCnamevalue" (ngModelChange)="inputCnamevalue = $event?.toLowerCase(); valueChange($event)" matInput placeholder="cname" required>
                    <mat-error *ngIf="frmEditInstance.controls.cname.invalid">
                        {{errorDescriptionCname}} </mat-error>
                </mat-form-field>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_User}}</mat-label>
                    <mat-select formControlName="userId" disableOptionCentering required>
                        <mat-option *ngFor="let user of users" [value]="user.id">
                            {{user.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.userId.invalid">{{
                        instanceEnum.instance_create_error_message_requiredField }}</mat-error>
                </mat-form-field>


            </div>

            <div class="pss-right-panel">

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_region}}</mat-label>
                    <mat-select formControlName="deploymentRegionId" disableOptionCentering required>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search Region"
                                noEntriesFoundLabel="No data found" [formControl]="columnFilterRegion">
                            </ngx-mat-select-search>
                          </mat-option>
                        <mat-option *ngFor="let region of selectRegion" [value]="region.id">
                            {{region.displayName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.deploymentRegionId.invalid">
                        {{instanceEnum.instance_create_error_message_requiredField}} </mat-error>
                </mat-form-field>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_View_Field_instanceVersion}}</mat-label>
                    <mat-select formControlName="InstanceVersion" disableOptionCentering required>
                        <mat-option *ngFor="let version of instanceVersions" [value]="version.value">
                            {{version.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="frmEditInstance.controls.InstanceVersion.invalid">{{
                        instanceEnum.instance_create_error_message_requiredField }}</mat-error>
                </mat-form-field>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_requiredByDate}}</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="minDate" (dateInput)="handleAdmDateChange($event)"
                        formControlName="requiredBy" readonly required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-error *ngIf="dateError && instanceData?.statusId != instanceStatusEnum.created">{{ instanceEnum.instance_error_required_date }}</mat-error>
                <mat-error
                    *ngIf="frmEditInstance.controls.requiredBy.dirty && !frmEditInstance.controls.requiredBy.valid && instanceData?.statusId != instanceStatusEnum.created">{{
                    instanceEnum.instance_error_valid_date }}</mat-error>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_Create_Field_referenceDocument}}</mat-label>
                    <mat-toolbar class="idn-upload-toolbar">
                        <input matInput [(ngModel)]="fileAttr" [ngModelOptions]="{standalone: true}" readonly
                            name="name" [disabled]="instanceData?.statusId == instanceStatusEnum.created"/>
                        <button mat-flat-button color="primary"
                            class="idn-file-upload">{{instanceEnum.instance_Create_browse_btn}}</button>
                    </mat-toolbar>
                    <input type="file" #fileInput aria-label="File Input" id="uploadFile" name="uploadFile" accept=".pdf,.doc,.docx,.txt,.xlsx,.xls"
                        (change)="uploadFileEvt($event)" [disabled]="instanceData?.statusId == instanceStatusEnum.created"/>
                    <mat-hint class="idn-mat-hint">
                        <span class="material-icons">info</span>
                        <span class="message">{{instanceEnum.instance_create_hint_message_provideFile}}</span>
                    </mat-hint>
                    <button mat-icon-button #addbutton class="mat-plus-icon" aria-label="Add File" (click)="fileAdd()" [disabled]="instanceData?.statusId == instanceStatusEnum.created">
                        <mat-icon>add_box</mat-icon>
                    </button>

                </mat-form-field>
                <div class="pss-Bottom-right">
                    <table>
                        <tr><br></tr>
                        <tr *ngFor="let item of listofUploadedFiles">
                            <mat-chip-list #chipList aria-label="Instance Tags">
                                <mat-chip (removed)="removeFileTag(item)">
                                    {{item}}
                                    <button matChipRemove [disabled]="instanceData?.statusId == instanceStatusEnum.created">
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                            </mat-chip-list>
                        </tr>
                    </table>
                </div>
            </div>


            <div class="pss-form-button-wrapper">
                <button mat-raised-button color="primary" [disabled]="!frmEditInstance.valid"
                    (click)="updateInstance()">{{instanceEnum.instance_Create_updateRequest_btn}}</button>
                <button mat-stroked-button (click)="cancel()">{{instanceEnum.instance_Create_cancel_btn}}</button>
            </div>
        </form>
    </div>
</div>