import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { trim } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { PartnerService } from 'src/app/core/services/partner-management/partner.service';
import { PartnerMgmtStaticString } from 'src/app/shared/enum/partnerManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';
@Component({
  selector: 'app-create-partner',
  templateUrl: './create-partner.component.html',
  styleUrls: ['./create-partner.component.scss']
})
export class CreatePartnerComponent implements OnInit {
  public PartnerMgmtEnum = PartnerMgmtStaticString;
  myInputValuename:any;
  myInputValue:any;
  public regex = InputPattern;
  errorDescriptionName:any;
  errorDescriptionField:any;
  regexName: any = new RegExp(this.regex.namePattern);
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  errorDescription: any = this.regex.errorMessage;
  deploymentRegions: any= [];
  copilotPlan:any=[
    {
      name: "Plan-1",
      id:0,
      value: 500
    },
    {
      name: "Plan-2",
      id:1,
      value: 2000
    },
    {
      name: "Plan-3",
      id:2,
      value: 3000
    },
    {
      name: "Custom",
      id:3,
      value: 0
    },
  ]
  constructor(
    private titleService: Title,
    private router: Router,
    private partnerService: PartnerService,
    private notificationService: NotificationService,
    private customerService: CustomerService,

  ) { }

  frmPartner: FormGroup = new FormGroup({
    partnerCode: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
    name: new FormControl('', [Validators.pattern(this.regexName)]),
    description: new FormControl('', [Validators.pattern(this.regexDescription)]),
    regionId: new FormControl(null),
    status: new FormControl(true),
    partnerUserQuota: new FormControl('50'),
    copilotPromptQuota: new FormControl(500,[Validators.pattern(/^[0-9]+$/)]),
    copilotPlan: new FormControl(0),
  });

  ngOnInit(): void {
    this.titleService.setTitle('Create Partner | IDN');
    this.getRegions();
    this.frmPartner.get('copilotPromptQuota')?.disable();
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/partner-management" },
    { label: "Partner Management", path: "administration/partner-management" },
    { label: "Create", path: "administration/partner-management/create" },
  ];
  onCancel() {
    this.router.navigate(["home/administration/partner-management"]);
  }

  async checkIfPartnerExists(name: any, partnerCode: any) {
    if (name != null && name != undefined) {
      return await lastValueFrom(this.partnerService.CheckPartnerName(name, partnerCode, 0));
    }
    else {
      this.router.navigate(["home/administration/partner-management"]);
    }
  }


  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescriptionField = this.regex.descriptionErrorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionField = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescriptionField = this.regex.descriptionErrorMessage;
    }
  }

  valueChangename(event: any) {
    if (event == undefined) {
      this.errorDescriptionName = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionName = this.regex.firstCharacterErrorMessage;
      return;
    }
    else {
      this.errorDescriptionName = this.regex.errorMessage;
    }
  }

  //To Save Role Data
  async createPartner() {
    try {
      if (!this.frmPartner.valid) {
        this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_error_failed_body, 'warning', this.PartnerMgmtEnum.partner_notify_error_failed_heading);
        return;
      }
      let JSON = this.frmPartner.getRawValue();
      let isexist = await this.checkIfPartnerExists(trim(JSON.name), trim(JSON.partnerCode))
      if (isexist.body != undefined && !isexist.body) {
        this.partnerService.PostData("api/Partner/Add", {}, JSON).subscribe((result: Response) => {
          if (result != null && result != undefined) {
            if (result.status == 200) {
              this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_created_body, 'success', this.PartnerMgmtEnum.partner_notify_created_heading);
              this.frmPartner.reset();
              this.router.navigate(["home/administration/partner-management"]);
            }
            else {
              this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_not_created_body, 'error', this.PartnerMgmtEnum.partner_notify_not_created_heading);
              this.frmPartner.reset();
              this.router.navigate(["home/administration/partner-management"]);
            }
          }
        })
      }
      else {
        this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_exist_body, 'error', this.PartnerMgmtEnum.partner_notify_exist_heading);
        //this.frmRole.reset();
        //this.router.navigate(["home/role-management"]);
      }
    }
    catch (e) {
      console.log("Exception Occured :", e)
    }
  }

  getRegions() {
    try {
      this.customerService.getRegions().subscribe((resp: any) => {
        if (resp != null) {
          this.deploymentRegions = resp;
        }
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  selectedCopilotPlan(){
    let selectedPlanId = this.frmPartner.get('copilotPlan')?.value;
    if(selectedPlanId !== 3){
      this.frmPartner.get('copilotPromptQuota')?.disable();
      let planValue = this.copilotPlan?.filter((data:any)=> data.id==selectedPlanId)[0].value;
      this.frmPartner.get('copilotPromptQuota')?.setValue(planValue);
    } else if(selectedPlanId == 3){
      this.frmPartner.get('copilotPromptQuota')?.enable();
      this.frmPartner.get('copilotPromptQuota')?.setValue(0);
    }
  }
}
