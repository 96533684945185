<app-pageheader [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div>
    <app-idn-search-box (searchText)="onSearchData($event)"></app-idn-search-box>
    <button mat-stroked-button class="clear-btn" color="primary" (click)="clearFilter()">
        <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
        <span>Clear Filter</span>
    </button>
</div>
<div>
    <app-idn-table class="logs-table" [data]="viewExportLogsData" (downloadEvent)="downloadLogs($event)"
        (refreshButton)="GetExportedFileHistory()" (onSort)="announceSortChange($event)"
        (onPageChanged)="pageChanged($event)"></app-idn-table>
</div>