import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class InstanceRequestGuardService {

  constructor(
    private router: Router,
    private _router: Router,
    private permssionService: PermissionsService,
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if(this.permssionService.getPermission('ViewInstanceRequest')) {
      return true;
    }

    this.router.navigate(['home/access-denied']);


    return false;
  }
}
