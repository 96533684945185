<div class="custom-date">
    <form class="idn-grid-filter" >
        <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [disabled]="isCustomDateDisabled" [formGroup]="customDateFilter" [min]="minDate" [max]="maxDate">
                <input matStartDate formControlName="start" placeholder="Start date" value="startDate" #start readonly>
                <input matEndDate formControlName="end" (dateChange)="doFilter($event)" placeholder="End date" value="endDate" #end readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div class="daterange-info">
            <mat-icon>info</mat-icon><span>Date range cannot exceed 180 days.</span>
        </div>
    </form>

</div>
