<app-pageheader [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div class="page-container">

    <div class="idn-view-wrapper">
      <div class="idn-view-header">
        <div class="data-left">
          <div class="idn-view-header-label">ICI WorkerTask Configuration For:</div>
          <div class="idn-view-header-data">
            <h3>{{ViewData.cname}}</h3>
          </div>
        </div>
      </div>
  
      <div class="idn-view-body pss-dashboard-form top-spacer">
        <mat-grid-list cols="3" class="idn-view-body" rowHeight="80px">
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">Partner Name</div>
              <div class="idn-view-body-items-data">{{ViewData.partnerName}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">Customer Name</div>
              <div class="idn-view-body-items-data">{{ViewData.customerName}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">Instance Name</div>
              <div class="idn-view-body-items-data">{{ViewData.cname}}</div>
            </div>
          </mat-grid-tile>  
        </mat-grid-list>
  
        <mat-grid-list cols="3" rowHeight="80px">
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">Task Type</div>
              <div class="idn-view-body-items-data">{{ViewData.type}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">Entry Name</div>
              <div class="idn-view-body-items-data">{{ViewData.entryName}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">Requested By</div>
              <div class="idn-view-body-items-data">
                <span class="view-user-info">{{ViewData.requesterdDetail}}</span>
                <span class="view-timestamp">on {{ViewData.createdAt | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>  
      </div>
    </div>
  </div>

<div class="pss-form-button-wrapper">  
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
  </div>