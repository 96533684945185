<app-pageheader [auditLog]="true" [auditLogData]="'certification'" [breadCrumbs]="this.breadCrumbItems"
    [isApprover]="true" [requestCertification]="requestCertification" (toggleCertificationDrawer)="toggleCertificationDrawer($event)"></app-pageheader>
    
<app-certification-request *ngIf="isCertificationDrawer" [certData]="isCertificationDrawer" (toggleCertificationDrawer)="toggleCertificationDrawer($event)">
</app-certification-request>

<div class="page-container">
    <div class="idn-filter">
        <div class="certification-filter">
            <div class="idn-grid-filter">
                <mat-form-field class="idn-grid-filter-search">
                    <mat-label>{{certificationEnum.certification_approver_List_search_label}}</mat-label>
                    <input type="text" matInput #searchInput (keydown.enter)="filter()" placeholder="Search by name">
                    <div class="idn-custom-search" matSuffix>
                        <button type="button" mat-icon-button aria-label="search" (click)="filter()">
                            <mat-icon>search</mat-icon>
                        </button>
                        <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </mat-form-field>

                <mat-form-field *ngIf="!isCustomerUser">
                    <mat-label>{{certificationEnum.certification_approver_List_search_partner_label}}</mat-label>
                    <mat-select (selectionChange)="filter()" #partnerNameInput>
                        <mat-option [value]="selectAll">All</mat-option>
                        <mat-option *ngFor="let partner of partnerName" [value]="partner.id">
                            {{ partner.partnerName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="pss-block-form" class="extesion-full-width margin-left-3">
                    <mat-label>{{certificationEnum.certification_approver_List_search_extension_label}}</mat-label>
                    <mat-select (selectionChange)="filter()" #extensionTypeInput>
                        <mat-option value="Select All">All</mat-option>
                        <mat-option *ngFor="let type of ExType" [value]="type.id">
                            {{type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <button mat-stroked-button color="primary" (click)="clearFilter()">
            <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
            <span>Clear Filter</span>
        </button>
    </div>

    <div class="table-scroll certification-list-table">

        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
            *ngIf="!noContent; else noContentTemplate">

            <!-- PARTNER NAME Column -->
            <ng-container matColumnDef="partnerName" *ngIf="!isCustomerUser" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by PARTNER NAME">
                    {{certificationEnum.certification_approver_List_Field_partnerName}}
                </th>
                <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.partnerName}}"
                matTooltipClass="my-tooltip" matTooltipPosition="below"> <span> {{element.partnerName ? element.partnerName : 'NA'}} </span> </td>
            </ng-container>

            <!-- Customer NAME Column -->
            <ng-container matColumnDef="customerName" *ngIf="!isCustomerUser" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by CUSTOMER NAME">
                    {{certificationEnum.certification_approver_List_Field_customerName}}
                </th>
                <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.customerName}}"
                matTooltipClass="my-tooltip" matTooltipPosition="below"> <span> {{element.customerName}} </span> </td>
            </ng-container>

            <!-- EXTENSION TYPE Column -->
            <ng-container matColumnDef="extensionType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by EXTENSION TYPE">
                    {{certificationEnum.certification_approver_List_Field_extensionType}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.extensionType }} </td>
            </ng-container>

            <!-- PACKAGE NAME Column -->
            <ng-container matColumnDef="packageName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by PACKAGE NAME">
                    {{certificationEnum.certification_approver_List_Field_packageName}}
                </th>
                <td mat-cell *matCellDef="let element" class="overflow-data pckg-name" matTooltip="{{element.packageName}}"
                matTooltipClass="my-tooltip" matTooltipPosition="below"> {{element.packageName}} </td>
            </ng-container>

            <!-- REQUESTER DETAILS Column -->
            <ng-container matColumnDef="requesterName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by REQUESTER DETAILS">
                    {{certificationEnum.certification_approver_List_Field_requestorDetails}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.requesterName }} <span class="requsted-date"> on {{
                        element.requestedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" }}</span> </td>
            </ng-container>

            <ng-container *ngIf="(hasGlobalDataAccess || canApproveCertification)" matColumnDef="slaStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by SLA Status">
                    SLA Status
                </th>
                <td mat-cell *matCellDef="let element" [ngClass]="{'certified': element.slaStatus == certificationRequestEnum.certificationSlaStatus_onTrack || element.slaStatus == certificationRequestEnum.certificationSlaStatus_met, 'expired': element.slaStatus == certificationRequestEnum.certificationSlaStatus_expired ||  element.slaStatus == certificationRequestEnum.certificationSlaStatus_missed,'expiring-soon': element.slaStatus == certificationRequestEnum.certificationSlaStatus_expiringSoon}"> {{element.slaStatus}} 
                <span *ngIf="element.slaStatus != certificationRequestEnum.certificationSlaStatus_onTrack && element.slaStatus != certificationRequestEnum.certificationSlaStatus_missed && element.slaStatus != certificationRequestEnum.certificationSlaStatus_met" class="requsted-date" ><span *ngIf="element?.slaResolutionDeadline">on</span> {{element?.slaResolutionDeadline | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
                <span *ngIf="element.slaStatus == certificationRequestEnum.certificationSlaStatus_onTrack" class="requsted-date"><span *ngIf="element?.slaResolutionDeadline">for</span> {{element?.slaResolutionDeadline | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
                <span *ngIf="element.slaStatus == certificationRequestEnum.certificationSlaStatus_missed" class="requsted-date"><span *ngIf="differenceInDaysandHours"></span>by {{element?.differenceInDaysAndHours?.days}} days {{element?.differenceInDaysAndHours?.hours}} hours {{element?.differenceInDaysAndHours?.minutes}} mins</span> 
                <span *ngIf="element.slaStatus == certificationRequestEnum.certificationSlaStatus_met" class="requsted-date"><span *ngIf="element?.reviewerApprovedOn">on</span> {{element?.reviewerApprovedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span> 
            </td>
            </ng-container>
            <!-- STATUS Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by STATUS">
                    {{certificationEnum.certification_approver_List_Field_status}}
                </th>
                <td mat-cell *matCellDef="let element" class="status-icons">
                    <div
                    [ngClass]="{'certified': element.status == certificationStatus.certified, 'rejected': element.status == certificationStatus.rejected, 'system-check-failed': element.status == certificationStatus.systemCheckFailed, 'review-requested': element.status == certificationStatus.reviewRequested, 'system-check-success': element.status == certificationStatus.systemCheckSuccess, 'certification-requested': element.status == certificationStatus.certificationRequested, 'review-in-progress': element.status == certificationStatus.reviewInProgress}">
                        <mat-icon *ngIf="element.status == certificationStatus.certified">verified_user</mat-icon>
                        <mat-icon *ngIf="element.status == certificationStatus.reviewRequested">start</mat-icon>
                        <mat-icon *ngIf="element.status == certificationStatus.rejected">gpp_bad</mat-icon>
                        <mat-icon *ngIf="element.status == certificationStatus.reviewInProgress">downloading</mat-icon>
                        <mat-icon *ngIf="element.status == certificationStatus.certificationRequested">rotate_right</mat-icon>
                        <mat-icon *ngIf="element.status == certificationStatus.systemCheckSuccess">check_circle_outline</mat-icon>
                        <mat-icon *ngIf="element.status == certificationStatus.systemCheckFailed">error_outline</mat-icon> 
                        {{element.status}}
                        <ng-container *ngIf="(element.status == certificationStatus.certified) && (element.isExceptionalApproval == true)">
                            <mat-icon class="material-symbols-outlined exception-approval-message" matTooltip="Exception Approval Certified"> info_outline </mat-icon>
                        </ng-container>
                        <ng-container *ngIf="(element.status == certificationStatus.rejected) && (element.isExceptionalApproval == true)">
                            <mat-icon class="material-symbols-outlined exception-approval-message" matTooltip="Exception Approval Rejected"> info_outline </mat-icon>
                        </ng-container>
                        <span matTooltip="Withdrawn by {{element.requestWithdrawerName}} on {{ element.requestWithdrawnOn | date:'d MMMM, y h:mm \'(UTC)\'' }}">
                            <mat-icon *ngIf="element.status == certificationStatus.systemCheckSuccess && (element.requestWithdrawerName && element.requestWithdrawnOn ? true:false) ">transit_enterexit</mat-icon>
                        </span>
                    </div>                    
                </td>
            </ng-container>

            <!-- ACTIONS Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="idn-action-column">
                    {{certificationEnum.certification_List_Header_actions}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="View" (click)="redirectToDetails(element)"
                        matTooltip="View Certification" matTooltipClass="idn-tooltip">
                        <mat-icon class="view-icon">remove_red_eye</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="View" [matMenuTriggerFor]="gridMenu" matTooltip="More Actions"
                        matTooltipClass="idn-tooltip">
                        <mat-icon>more_horiz</mat-icon>
                    </button>

                    <mat-menu #gridMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="downloadFiles(element)">
                            <mat-icon>download</mat-icon>
                            {{certificationEnum.certification_approver_List_Field_downloadFiles}}
                        </button >
                        
                        <button mat-menu-item *ngIf = "(element.status == certificationStatus.reviewRequested)&& isInitiateReview && (element.veracodeScanStatus == null && isExceptionalApproval) || (( element?.veracodeScanStatus == 'Failed' && element?.reTriggerCount == 3 && element.status != certificationStatus.certified && element.status != certificationStatus.reviewInProgress  && element.status != certificationStatus.rejected && isInitiateReview) || (element?.veracodeScanStatus == 'Successful' && (element.status != certificationStatus.reviewInProgress && element.status != certificationStatus.certified && element.status != certificationStatus.rejected)) && (element?.veracodeScanStatus != 'InitialState' && element?.veracodeScanStatus != 'In Progress') && isInitiateReview) || (element?.veracodeScanStatus == 'Failed' && element?.veracodeScanScore != null && element.status != certificationStatus.rejected)"
                            (click)="((isInitiateReview == true) && (element.status == certificationStatus.reviewRequested)) ? initiateReviewbyReviewer(element) : ''">
                            <mat-icon>rule</mat-icon>
                            {{certificationEnum.certification_approver_List_Field_initiateReview_btn}}
                        </button>
                        <button mat-menu-item *ngIf ="(element.status != certificationStatus.systemCheckFailed) && (element.status != certificationStatus.systemCheckSuccess)" [disabled]="(element.status != certificationStatus.reviewInProgress) || !isCompleteReview"
                            (click)="((isCompleteReview == true) && (element.status == certificationStatus.reviewInProgress)) ? completeReview(element) : ''">
                            <mat-icon>rule</mat-icon>
                            {{certificationEnum.certification_approver_List_Field_completeReview_btn}}
                        </button>
                        <button class ="initiate-certification-btn" mat-menu-item *ngIf ="element.status == certificationStatus.systemCheckFailed && isExceptionalApproval"
                        (click)="initiateManualCertification(element)">
                        <mat-icon>start</mat-icon>
                        {{certificationEnum.certification_List_Field_initiateManual_btn}}{{certificationEnum.certification_List_Field_ExceptionApproval}}
                    </button>
                    <button mat-menu-item *ngIf ="(element.status == certificationStatus.reviewRequested) && ((element.requesterEmail == loggedinUserEmail) || (hasGlobalDataAccess && requestCertification)) && (!element.isExceptionalApproval) && (element.veracodeScanStatus == 'In Progress' || element.veracodeScanStatus == 'InitialState' || element.veracodeScanStatus ==null)"
                            (click)="withdrawRequest(element)">
                            <mat-icon>transit_enterexit</mat-icon>
                            {{certificationEnum.certification_approver_List_Field_withdraw_request}}
                    </button>
                    <button class ="initiate-certification-btn" mat-menu-item *ngIf ="element.status == certificationStatus.systemCheckSuccess && isInitiateManualCertification && (element?.partnerId == loggedInUserPartnerId || hasGlobalDataAccess)"
                        (click)="initiateManualCertification(element)">
                        <mat-icon>start</mat-icon>
                        {{certificationEnum.certification_List_Field_initiateManual_btn}}
                    </button>                   
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <app-intiate-certification *ngIf="intiateCertificationExceptionDrawerActive" [certDetailsData]="certDetails" ></app-intiate-certification>
    <mat-paginator #paginator [ngClass]="{'hide-pagination': noContent ? true : false}" [pageSize]="10"
        [pageIndex]="currentPage" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
        (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
    <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
        <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
    </button>

</div>
<app-update-certification *ngIf="isUpdateCertification"></app-update-certification>
<app-download-files *ngIf="downloadFilesActive"></app-download-files>

<ng-template #noContentTemplate>
    <div class="idn-no-result">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276"
            height="214.71" viewBox="0 0 203.276 214.71">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39"
                        transform="translate(0 0)" fill="#515165" />
                </clipPath>
            </defs>
            <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
                <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
                    transform="translate(3187.267 7517.8)" fill="#7c7c97" />
                <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
                    transform="translate(3192.911 7517.8)" fill="#515165" />
                <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
                    transform="translate(3178.801 7551.663)" fill="#ababc5" />
                <path id="Union_2" data-name="Union 2"
                    d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                    transform="translate(3178.801 7617.272)" fill="#7c7c97" />
                <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
                    transform="translate(3192.911 7551.663)" fill="#7c7c97" />
                <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
                    <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264"
                        transform="translate(0 0)" fill="#cfdae2" />
                    <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
                        transform="translate(8.466 8.466)" fill="#f6f2f1" />
                </g>
                <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
                    clip-path="url(#clip-path)">
                    <path id="Union_1" data-name="Union 1"
                        d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                        transform="translate(-14.11 0)" fill="#515165" />
                </g>
            </g>
        </svg>
        <h4>{{ certificationRequestEnum.certification_no_result_msg }}</h4>
    </div>
</ng-template>
