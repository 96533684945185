import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatDrawer } from '@angular/material/sidenav';
import { BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import { EnvService } from '../env.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CertificationsService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any =  environment.headers;
  public _certificationList = new BehaviorSubject('');
  public certificationDetails = new BehaviorSubject<any>('');

  //Drawer
  public intiateCertificationToggleSubject: BehaviorSubject<any> = new BehaviorSubject(false);
  private drawer!: MatDrawer;

  constructor(private http: HttpClient, private env: EnvService) { }

  public getCertificationList(extensionDetails: any, sortingColumn: string, pageNumber: number, pageSize: number, filter?: any, certificationStatus?: any){
    return this.http.get<any>(`${this.baseApiUrl}api/Certification/GetSolutionCertificationByExtensionId`, { params: { 'extensionId': extensionDetails.id, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize, 'filter': filter, 'certificationStatus': certificationStatus }, headers :this.headers});
  }

  public getApproverCertificationList(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number, partner: string, extensionType: string, CertificationStatus: string){
    return this.http.get<any>(`${this.baseApiUrl}api/Certification/GetSolutionCertificationForReviewer`, { params: { 'filter': filterSearchValue, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize, 'partner': partner, 'extensionType': extensionType, 'CertificationStatus': CertificationStatus }, headers: this.headers });
  }

  public getCertificationDetails(id: any) {
    return this.http.get<any>(`${this.baseApiUrl}api/Certification/${id}`, { headers :this.headers });
  }

  public submitForSolutionCertification(paraBody: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/Certification`, paraBody,  { headers :this.headers });
  }

  public initiateManualCertification(payload: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/${payload.id}/InitiateManualCertification`, '', { headers : this.headers });
  }

  public initiateReviewbyReviewer(payload: any, reviewerId: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/${payload.id}/InitiateReview`, '', { params: { reviewerId: reviewerId }, headers : this.headers });
  }

  public updateCertificationReview(payload: any, id: any){
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/${id}/UpdateReviewComments`, payload, { headers : this.headers })
  }

  public packageStatusUpdate(id: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/SolutionPackage/StatusCertificationInProgress?solutionPackageId=${id}`, { headers :this.headers })
  }

  public statusCertified(id: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/SolutionPackage/StatusCertified?solutionPackageId=${id}`, { headers :this.headers })
  }

  public certificationRejected(id: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/SolutionPackage/CertificationRejected?solutionPackageId=${id}`, { headers :this.headers })
  }

  public WithdrawManualCertification(payload: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/${payload.id}/WithdrawManualCertification?requesterNote=${payload.requesterNote}`, { headers : this.headers });
  }

  public ReTriggerVeracodeScan(id: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/ReTriggerVeracodeScan?id=${id}`, { headers : this.headers,observe: 'response' });
  }
  // GetBlobDownloadConnection for certification Module
  public certificationFileDownload(payload: any, fileName: any, moduleName?: any, parentPackage?:boolean) {
    if(moduleName == "SolutionPackage" && parentPackage != true){
      return this.http.get<any>(`${this.baseApiUrl}api/${moduleName}/GetBlobDownloadConnection`, { params: { id: payload.solutionId, filename: fileName }, headers : this.headers });
    } if(moduleName == "SolutionPackage" && parentPackage){
      return this.http.get<any>(`${this.baseApiUrl}api/${moduleName}/GetBlobDownloadConnection`, { params: { id: payload, filename: fileName }, headers : this.headers });
    } else {
      return this.http.get<any>(`${this.baseApiUrl}api/Certification/GetBlobDownloadConnection`, { params: { id: payload.id, filename: fileName }, headers : this.headers });
    }
  }
  // GetBlobDownloadConnection for certification Module

  // COMMON Method for downlod file
  private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
    const blobClient = client.getBlobClient(name);
    blobClient.download().then((resp: any) => {
      handler(resp)
    }, (err: any) => {
      handler(err.response)
    })
  }

  public containerSasClient(sas: string, container: string): ContainerClient {
    return new BlobServiceClient(sas)
      .getContainerClient(container);
  }

  public downloadCertificationFiles(sasUrl:string, container:string, fileName:string, handler:(response:any) => void) {
    this.downloadBlob(fileName, this.containerSasClient(sasUrl, container), handler)
  }
  // COMMON Method for downlod file

  public getExtensionTypes() {
    return this.http.get(this.baseApiUrl + 'api/Certification/ExtensionTypes')
  }

  public getCustomers() {
    return this.http.get(this.baseApiUrl + 'api/Certification/CustomerList')
  }

  public getApprovedPartners() {
    return this.http.get(this.baseApiUrl + 'api/Certification/ApprovedPartners', { headers : this.headers })
  }

  public certificationChecklist() {
    return this.http.get<any>(`${this.baseApiUrl}api/Certification/GetCheckList`, { headers : this.headers });
    }

  emitCertificationDetails<T>(data: T) {
    this.certificationDetails.next(data);
  }

  onCertificationDetails<T>(): Observable<T> {
    return this.certificationDetails.asObservable();
  }

  closeIntiateCertification(data: Boolean){
    this.intiateCertificationToggleSubject.next(data)
  }

  setDrawer(drawer: MatDrawer) {
    this.drawer = drawer;
  }

  toggle(): void {
    this.drawer.toggle();
  }

  close(): void {
    this.drawer.close();
  }

  public open() {
		return this.drawer.open();
	}

  certificationList(data: string) {
    this._certificationList.next(data);
  }
  public submitForxceptionCertification(paraBody: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/InitiateExceptionalApproval`, paraBody,  { headers :this.headers,observe: 'response' });
  }
  public UpdateFileNameForxceptionCertificationApproval(paraBody: any) : Observable<any>{
    return this.http.post<any>(`${this.baseApiUrl}api/Certification/UpdateExceptionalApproval`, paraBody,  { headers :this.headers ,observe: 'response'});
  }
  public getUploadTokenForIdentifier(id:any) {
    return this.http.get(`${this.baseApiUrl}api/Certification/GetBlobUploadConnection`, { params: { 'id': id},headers:this.headers, observe: 'response' })
  }
  public getDownloadTokenForIdentifier(id:any) {
    return this.http.get(`${this.baseApiUrl}api/Certification/GetBlobDownloadConnection`, { params: { 'id': id},observe: 'response' })
  }

  emit<T>(data: T) {
    this.certificationDetails.next(data);
  }


}
