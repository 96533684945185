import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDrawer } from '@angular/material/sidenav';
import { catchError, Subscription, throwError } from 'rxjs';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

enum certificationListStatus {
  systemCheckSuccess = 'System Check Success',
  certified = 'Certified',
  systemCheckFailed = 'System Check Failed',
  reviewRequested = 'Review Requested',
  rejected = 'Rejected',
  reviewInProgress = 'Review In-Progress',
  certificationRequested = 'Certification Requested'
}

@Component({
  selector: 'app-certification-request',
  templateUrl: './certification-request.component.html',
  styleUrls: ['./certification-request.component.scss'],
})
export class CertificationRequestComponent implements OnInit {
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @Input() certData: any;

  panelOpenState = false;
  checked = false;
  toggle = 'NO';

  customerId: number = 0;
  extensionTypeId: number = 0;
  extensionId: number = 0;
  solutionPackageId: number = 0;
  iciVersion:string ="";
  solutionPackageName:string="";
  public certificationEnum = CertificationManagement;
  frmControlsInvalid = true;
  solutionPackages: any;
  ExtentionTypes: any;
  Extensions: any;
  Customers: any;
  columnFilterCtrlCustomerName = new FormControl('');
  selectCustomerName:any = [];
  columnFilterCtrlExtensionName = new FormControl('');
  selectExtensionName:any = [];
  columnFilterCtrlSolutionPackageName  = new FormControl('');
  selectSolutionPackageName: any = [];
  @Output() toggleCertificationDrawer: EventEmitter<any> = new EventEmitter();
  isCustomerUser:boolean = false;
  userCategory: any;
  frmRequestCertification: FormGroup = new FormGroup({
    customerId: new FormControl(null, Validators.required),
    extensionTypeId: new FormControl(null, Validators.required),
    extensionId: new FormControl(null, Validators.required),
    solutionPackageId: new FormControl(null, Validators.required),
  });

  constructor(
    private certificationsService: CertificationsService,
    private extensionService: ExtensionService,
    private permissionService: PermissionsService,
    private instanceService: InstanceService,
    private notificationService: NotificationService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let self = this;
    this.frmRequestCertification.controls['extensionTypeId'].disable();
    this.frmRequestCertification.controls['extensionId'].disable();
    this.frmRequestCertification.controls['solutionPackageId'].disable();
    
    this.bindCustomerDropDown();
    
    this.columnFilterCtrlCustomerName.valueChanges.subscribe((value:any) => {
      this.columnFilterCustomerName(value);
    });
    this.columnFilterCtrlExtensionName.valueChanges.subscribe((value:any) => {
      this.columnFilterExtensionName(value);
    });
    this.columnFilterCtrlSolutionPackageName.valueChanges.subscribe((value:any) => {
      this.columnFilterSolutionPackageName(value);
    });
  }

  ngAfterViewInit() {
    this.userCategory=this.permissionService.getUsercategoryId();  
   
    if(this.userCategory == USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
      this.bindCustomerDropDown();
    }
    else{
      this.isCustomerUser = false;
    }
  }

  bindCustomerDropDown() {
    const partnerId = this.permissionService.getPartnerDetails().id;
    this.validateFormdata();
    try {
      this.extensionService.getCustomers(partnerId).subscribe((result: any) => {
        if (result != undefined && result != '' && result != null) {
          this.Customers = result;
          this.selectCustomerName = this.Customers;          
        }        
        if(this.isCustomerUser){         
          let customerUserCustomerId=result[0].id;
          this.frmRequestCertification.controls['customerId'].setValue(customerUserCustomerId);          
          this.frmRequestCertification.controls['customerId'].disable();
          this.bindExtensionDropDown();
        }
      });
    } catch (execption) {
      console.log(execption);
    }
  }

  columnFilterCustomerName(value: any){
    const valueLowerCase = value.toLowerCase();
    this.selectCustomerName = this.Customers?.filter((x: any) =>
      x.customerName.toLowerCase().includes(valueLowerCase),
    );
  }

  bindExtensionDropDown() {
    try {
      this.frmRequestCertification.controls['extensionTypeId'].reset();
      this.frmRequestCertification.controls['extensionId'].reset();
      this.frmRequestCertification.controls['solutionPackageId'].reset();
      this.frmRequestCertification.controls['extensionId'].disable();
      this.frmRequestCertification.controls['solutionPackageId'].disable();
      this.ExtentionTypes = [];
      this.Extensions = [];
      this.solutionPackages = [];
      this.selectSolutionPackageName = this.solutionPackages;
      this.extensionTypeId = 0;
      this.extensionId = 0;
      this.solutionPackageId = 0;
      this.validateFormdata();
      this.customerId = this.frmRequestCertification.get('customerId')?.value;
      this.frmRequestCertification.controls['extensionTypeId'].enable();
     
      this.extensionService.getExtensionTypes(this.userCategory).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.ExtentionTypes = result;
        }
      });
    } catch (execption) {
      console.log(execption);
    }
  }

  columnFilterExtensionName(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.selectExtensionName = this.Extensions?.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase),
    );
  }

  columnFilterSolutionPackageName(value: any){
    const valueLowerCase = value.toLowerCase();
    this.selectSolutionPackageName = this.solutionPackages?.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase),
    );
  }

  getExtensionName(extntypeid: any) {
    try {
      this.frmRequestCertification.controls['extensionId'].reset();
      this.frmRequestCertification.controls['solutionPackageId'].reset();
      this.Extensions = [];
      this.solutionPackages = [];
      this.selectExtensionName = [];
      this.selectSolutionPackageName = this.solutionPackages;
      this.extensionId = 0;
      this.solutionPackageId = 0;
      this.extensionTypeId = extntypeid;
      this.frmRequestCertification.controls['extensionId'].enable();
      this.validateFormdata();

      this.instanceService
        .getExtensionName(this.customerId, this.extensionTypeId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            if (resp.body.length > 0) {
              this.Extensions = resp.body;
              this.selectExtensionName = this.Extensions;
              if(this.extensionTypeId == 2){
                this.frmRequestCertification.controls['extensionId'].setValue(this.Extensions[0].id);
                this.frmRequestCertification.controls['extensionId'].disable();
                this.getExtensionPackageList(this.Extensions[0].id)
              }
            } else {
              this.notificationService.showNotification(
                this.certificationEnum
                  .certification_notify_extensionNotAvailable_body,
                'warning',
                this.certificationEnum
                  .certification_notify_extensionNotAvailable_heading
              );
              return;
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getExtensionPackageList(extnId: any) {
    try {
      this.frmRequestCertification.controls['solutionPackageId'].reset();
      this.solutionPackages = [];
      this.selectSolutionPackageName = this.solutionPackages;
      this.solutionPackageId = 0;
      this.extensionId = extnId;
      this.frmRequestCertification.controls['solutionPackageId'].enable();
      this.validateFormdata();
      //this api call need to modify
      this.extensionService
        .GetSolutionPackagesForCertificationRequest(this.extensionId)
        .subscribe((resp: any) => {
          if (resp != undefined && resp != null && resp != '') {
            if (resp.records.length > 0) {
              this.solutionPackages = resp.records;
              this.selectSolutionPackageName = this.solutionPackages;
              this.iciVersion=resp.records.iciVersion;
            }
            else {
              this.notificationService.showNotification(
                this.certificationEnum
                  .certification_notify_packageNotAvailable_body,
                'warning',
                this.certificationEnum
                  .certification_notify_packageNotAvailable_heading
              );
              return;  
            }
            }
            else {
              this.notificationService.showNotification(
                this.certificationEnum
                  .certification_notify_packageNotAvailable_body,
                'warning',
                this.certificationEnum
                  .certification_notify_packageNotAvailable_heading
              );
              return;  
            }
        });
    } catch (exception) {
      console.log(exception);
    }
  }

  
  getFormData(solutionPackage: any) {    
    this.solutionPackageId = solutionPackage.id;
    this.iciVersion=solutionPackage.iciVersion;
    this.solutionPackageName = solutionPackage.name;
    this.validateFormdata();
  }

  validateFormdata() {
    if (
      this.customerId > 0 &&
      this.extensionTypeId > 0 &&
      this.extensionId > 0 &&
      this.solutionPackageId > 0
    ) {
      this.frmControlsInvalid = false;
    } else {
      this.frmControlsInvalid = true;
    }
  }

  closeDrawer() {
    this.toggleCertificationDrawer.emit(false);
  }

  downloadAllFlies() {
    this.certificationsService.closeIntiateCertification(true);
  }

  save() {

    const reviewerId = this.permissionService.getUniqueUserid();
    const partnerId = this.permissionService.getPartnerDetails().id;
    let payload = {
      "solutionId": this.solutionPackageId,
      "requestorId": reviewerId,
      "requestedOn": `${new Date().toISOString()}`,
      "iciVersion": this.iciVersion,
      "partnerId": partnerId
    }
    
    this.certificationsService.submitForSolutionCertification(payload).pipe(
      catchError(err => {
        if (err.status != 200) {
          this.notificationService.showNotification(this.certificationEnum.certification_notify_RequestcertificationFailed_body_p1 + this.solutionPackageName + this.certificationEnum.certification_notify_RequestcertificationFailed_body_p2, 'error', this.certificationEnum.certification_notify_RequestcertificationFailed_heading);
        }
        return throwError(err);
      })
    ).subscribe(response => {
      if (response == null) {
        this.notificationService.showNotification(this.certificationEnum.certification_notify_RequestcertificationFailed_body_p1 + this.solutionPackageName + this.certificationEnum.certification_notify_RequestcertificationFailed_body_p2, 'error', this.certificationEnum.certification_notify_RequestcertificationFailed_heading);
      } else {
        this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationRequestSubmitted_body_p1 + this.solutionPackageName + this.certificationEnum.certification_notify_certificationRequestSubmitted_body_p2, 'success', this.certificationEnum.certification_notify_certificationRequestSubmitted_heading);
        this.toggleCertificationDrawer.emit("save")
        this.certificationsService.packageStatusUpdate(this.solutionPackageId).subscribe({});
      }
    });
  }
}
