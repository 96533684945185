import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class SkuUpdateGuardService {

  constructor(
    private router: Router,
    private permssionService: PermissionsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if(this.permssionService.getPermission('UpdateSKU')) {
      return true;
    }
    this.router.navigate(['home/access-denied']);
    return false;
  }
}
