import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { EnvironmentTypeFilterComponent } from './environment-type-filter/environment-type-filter.component';
import { FilterComponent } from './filter/filter.component';
import { IdnSharedcomponentsModule } from '../idn-sharedcomponents/idn-sharedcomponents.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FixedDateComponent } from './date-filter/fixed-date/fixed-date.component';
import { CustomDateComponent } from './date-filter/custom-date/custom-date.component';



@NgModule({
  declarations: [
    DateFilterComponent,
    FixedDateComponent,
    CustomDateComponent,
    EnvironmentTypeFilterComponent,
    FilterComponent

],
  imports: [
    CommonModule,
    IdnSharedcomponentsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    DateFilterComponent,
    FixedDateComponent,
    CustomDateComponent,
    EnvironmentTypeFilterComponent,
    FilterComponent
  ]
})
export class IdnFilterModule { }
