<app-pageheader [pageTitle]="'IDN Workbench'" [auditLog]="hasGlobalDataAccess" [auditLogData]="'idn-workbench'" [uploadSdk]="true" [applyFilter]="false"
  [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
  <div class="idn-filter">
  <form class="idn-grid-filter">
    <mat-form-field class="idn-grid-filter-search">
      <mat-label>Search IDN Workbench</mat-label>
      <input (keydown.enter)="doFilter($event,'search')" #name matInput placeholder="Search by Name, Description, Version or Filename" >
      <span class="idn-custom-search" matSuffix>
        <mat-icon  (click)="doFilter($event,'search')">search</mat-icon>
       <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="ClearInput()">
        <mat-icon>close</mat-icon>
      </button>
      </span>
    </mat-form-field>
    <mat-form-field>
      <mat-label>File Uploaded</mat-label>
      <mat-select (selectionChange)="doFilter($event,'FileUploaded')" #isFileUploaded>
        <mat-option [value]="selectAll"> All</mat-option>
        <mat-option *ngFor="let stat of FileUploaded" [value]="stat.value">
          {{stat.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf = "hasGlobalDataAccess == true">
      <mat-label>Status</mat-label>
      <mat-select (selectionChange)="doFilter($event,'Status')" #status>
        <mat-option [value]="selectAll"> All</mat-option>
        <mat-option *ngFor="let stat of Status" [value]="stat.value">
          {{stat.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <button mat-stroked-button color="primary"  (click)="clearFilter()">
    <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
    <span>Clear Filter</span>
  </button>
  </div>
  <div class="table-scroll">

  <table mat-table [dataSource]="dataSource" matSort #empTbSort="matSort" class="idn-grid-position idn-grid-global" (matSortChange)="announceSortChange($event)" *ngIf="!noContent; else noContentTemplate">

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Name">
        Name
      </th>
      <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.name}}" matTooltipClass="my-tooltip"  matTooltipPosition="below"> {{element.name}}
      </td>

    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="description" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Description">
        Description
      </th>
      <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.description}}" matTooltipClass="my-tooltip"  matTooltipPosition="below"> {{element.description}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Version">
        Version
      </th>
      <td mat-cell *matCellDef="let element"> {{element.version}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="idn-grid-th-p1" sortActionDescription="Sort by  File Name">
        File Name
      </th>

        <td  mat-cell *matCellDef="let element" class="blue">
        <button mat-button class="idn-grid-link-button" [disabled]="element.status == 2" *ngIf="element.isFileUploaded ==true" (click)="downloadFile1(element)">
          <span class="pckgname">{{element.fileName}} </span>
          <mat-icon class="download-icon" *ngIf="element.status == 1 && element.isFileUploaded" >download
          </mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="isFileUploaded">
      <th mat-header-cell *matHeaderCellDef  sortActionDescription="Sort by symbol">
        File Uploaded
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="pss-chips-status"
          *ngIf="element.isFileUploaded === true">
          <mat-icon class="sucess"> file_download_done </mat-icon>
          <span class="label">Yes</span>
        </div>
        <div class="pss-chips-status"
          *ngIf="element.isFileUploaded === false">
          <mat-icon class="error">file_upload_off </mat-icon>
          <span class="label">No</span>
        </div>

       </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by symbol">
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="pss-chips-status"
          *ngIf="element.status == 1 && uploadsdkData.sdkName != element.name || uploadsdkData.showProgressBar == 'false' && element.status == 1">
          <span class="marker marker__active" ></span>
          <span class="label">Active</span>
        </div>
        <div class="pss-chips-status"
          *ngIf="element.status == 2 && uploadsdkData.sdkName != element.name || uploadsdkData.showProgressBar == 'false' && element.status == 2">
          <span class="marker marker__in-active"></span>
          <span class="label">In-Active</span>
        </div>
        <mat-progress-bar [color]="color" [mode]="mode" [value]="value"
          *ngIf="uploadsdkData.sdkName == element.name && uploadsdkData.sdkDesc == element.description && uploadsdkData.showProgressBar == 'true' ">
        </mat-progress-bar>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="idn-grid-th-p1 action-cell">
        Actions
      </th>
      <td mat-cell *matCellDef="let element" class="action-cell">
        <div class="idn-cell-action">
          <button  mat-icon-button aria-label="View" (click)="ViewSDK('idn-workbench-management/view',element)" matTooltip="View IDN Workbench">
            <mat-icon>remove_red_eye</mat-icon>
            </button>

          <button *ngIf="isEditSDK || isActivateSDK || isDeactivateSDK" [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="IDN Workbench Actions" matTooltip="IDN Workbench Actions">
            <mat-icon>more_horiz</mat-icon>
          </button>
        </div>
        <mat-menu #gridMenu="matMenu" xPosition="before">
          <button [disabled]="element.status==1 || element.isFileUploaded ==false ? true : false" *ngIf="isActivateSDK"  mat-menu-item (click)="ActivateSDK(element.id)">
            <mat-icon>check</mat-icon> Activate
          </button>
          <button [disabled]="element.status==2 || element.isFileUploaded == false ? true : false" *ngIf="isDeactivateSDK" mat-menu-item (click)="DeactivateSDK(element.id,element)">
            <mat-icon>block</mat-icon> Deactivate
          </button>
          <button mat-menu-item (click)="edit('idn-workbench-management/edit',element)" *ngIf="isEditSDK">
            <mat-icon>mode</mat-icon> Edit
          </button>
        </mat-menu>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
  <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="10" [pageSizeOptions]="[5,10, 15, 20, 25, 50]" showFirstLastButtons
  class="mat-paginator-sticky" (page)="pageChanged($event)" [length]="pageLength"
    aria-label="Select page of periodic elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
  </mat-paginator>
  <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
    <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
  </button>
</div>

<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
        <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
        </g>
      </g>
    </svg>
    <h4>{{ WbMgmtEnum.wb_no_result_msg }}</h4>
  </div>
</ng-template>
