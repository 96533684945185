import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class WebAppGuardService {
  EnablePublicAppHostingFeature:any;
  constructor(
    private router: Router,
    private _router: Router,
    private permssionService: PermissionsService,
  ) { }
 

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.EnablePublicAppHostingFeature = localStorage.getItem('enablePublicAppHostingFeature');
    if (this.EnablePublicAppHostingFeature && this.permssionService.getPermission('CreateSolutionPackage')) {
      return true;
    }
    this.router.navigate(['home/access-denied']);
    return false;
  }
}
