import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { HttpErrorResponse, JsonpClientBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { lastValueFrom, pluck } from 'rxjs';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';
import { EnvService } from 'src/app/core/services/env.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { StepperOrientation } from '@angular/cdk/stepper';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { IdnConfigurationService } from 'src/app/core/services/common/idn-configuration.service';
import { PartnerService } from 'src/app/core/services/partner-management/partner.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants'

const publicSku = 2; // refers to user who can create the Public SKU.
const versionCheck = 0;
const CUSTOMSOLUTION = 2; //used to compare version < 0 referes lower version, >0 means the higher version and 0 means same version.
@Component({
  selector: 'app-create-extension',
  templateUrl: './create-extension.component.html',
  styleUrls: ['./create-extension.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: false },
    },
  ],
})

export class CreateExtensionComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef;

  @ViewChild('majorstep')
  majorstep!: MatInput;

  @ViewChild('customername')
  mySelect!: MatSelect;

  @ViewChild('stepper', { static: false }) private mystepper!: MatStepper;

  @ViewChild('addbutton')
  addbutton!: MatButton

  @ViewChild('saveAndClosebtn')
  saveAndClosebtn!: MatButton
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  fileAttr = "Choose File";
  packageFileAttr = 'Package zip File';
  items: any[] = [];
  ExType: any = [];
  IsExtenTypeSelected: boolean = false;
  addOnBlur = true;
  CustomerNameList: any = [];
  CustomerDisplayList: any = [];
  listofUploadedFiles: any[] = [];
  listofPackageUploadedFiles: any[] = [];
  columnFilterCtrlCo = new FormControl('');
  packageNameFilterCtrl = new FormControl('');
  file: any;
  package: any;
  azUrl: any;
  eventObject: any = {};
  fileArray: any[] = [];
  sas: any;
  uniqueIdentifier: any;
  fileSizeLimit = this.env.fileSizeLimitExtension;
  packageSizeLimit = 2000;
  firstFormGroup!: FormGroup;
  secondFormGroup!: FormGroup;
  thirdFormGroup!: FormGroup;
  isEditable = false;
  isExtensionFormSubmitted = false;
  isaddPackage = false;
  extId: any = "";
  majorversion = 0;
  extensionName = "";
  extensionPartnerId = 0;
  minorversion = 0;
  patchversion = 0;
  buildversion = 0;
  iciMajorVersion = 0;
  iciMinorVersion = 0;
  iciPatchVersion = 0;
  iciBuildVersion = 0;
  fileExtension = ['pdf', 'doc', 'docx', 'txt'];
  packageExtension = ['zip'];
  isPrevDisable = false;
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Development and Utilities", path: "developmentandutilities/extension-management" },
    { label: "Extension Management", path: "developmentandutilities/extension-management" },
    { label: "Create", path: "developmentandutilities/extension-management/create" },
  ];
  initialValues: any;
  ProductType: any;
  extDetails: any;
  isAnimationDisable = false;
  PartnerId: any;
  extTimestamp: any;
  extInEditMode: any = false;
  isFileUploaded: any;
  initialData: any;
  isViewExtension: any;
  isLockExtensionPermission: any;
  isUnLockExtensionPermission: any;
  isUpdateExtensionPermission: any;
  isCreateExtensionPermission: any;
  isViewSolutionPackagePermission: any;
  isCreateSolutionPackage: any;
  orientation: StepperOrientation = 'horizontal'
  innerWidth: number = 1200;
  packageTimestamp: any;
  public extensionEnum = ExtensionManagement;
  errorDescriptionName:any;
   myInputValuename:any;
  myInputValue:any;
  public regex = InputPattern;
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  errorDescription: any = this.regex.errorMessage;
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;
  extensionLabel:any;
  packageLabel:any;
  certificationLabel:any;
  inputiciVersionvalue:any;
  binaryPackage: any =false;
  minimumICIVersionForBinaryPackage:any;
  binaryPackageUpload: any = false;
  extensionType: any;
  packageList:any =[];
  packageDisplayList:any=[]
  linkedPackage:any=null;
  disableLinkedPackage: boolean =false;
  linkageAndQueueForUpgradeFeature: any = true;
  versionSuffix: any;
  partnerList:any= [];
  selectedCustomerName: any=null;
  RLExtensionName: any='';
  userCategory:any; 
  isCustomerUser:boolean = false;
  customeruserCustomerId:any;
  hasGlobalDataAccess: boolean = false;
  
  constructor(
    private cd: ChangeDetectorRef,
    private extensionService: ExtensionService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private loader: LoaderService,
    private env: EnvService,
    private permissionService: PermissionsService,
    private idnConfiguration:IdnConfigurationService,
    private partnerService: PartnerService,
  ) {
  }

  @HostListener('window:resize') onWindowResize() {
    this.innerWidth = window.innerWidth;
    this.getInitialWindowSize(this.innerWidth);
  }

  getInitialWindowSize(winSize: number){
    if (winSize <= this.env.minBreakpoint) {
      this.orientation = 'vertical';
    } else {
      this.orientation = 'horizontal';
    }
  }

  frmCreateExtension: FormGroup = new FormGroup({
    typeId: new FormControl('', Validators.required),
    customerId: new FormControl('', Validators.required),
    partnerIds: new FormControl([]),
    name: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9_-]*$/)]),
    tags: new FormControl([]),
    description: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    files: new FormControl([]),
    id: new FormControl(0),
    uniqueIdentifier: new FormControl(),
    locked: new FormControl(false)
  });

  frmAddPackage: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z0-9](?!.*--)[a-zA-Z0-9_-]*$/)]),
    description: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    major: new FormControl(0, Validators.required),
    minor: new FormControl(0, Validators.required),
    patch: new FormControl(0, Validators.required),
    build: new FormControl(0, Validators.required),
    icimajor: new FormControl(0),
    iciminor: new FormControl(0),
    icipatch: new FormControl(0),
    icibuild: new FormControl(0),
    fileName: new FormControl('', Validators.required),
    id: new FormControl(0),
    status: new FormControl(null),
    extensionId: new FormControl(''),
    uniqueIdentifier: new FormControl(),
    locked: new FormControl(false),
  });

  ngOnInit(): void {
    this.isViewExtension = this.permissionService.getPermission("ViewExtension");
    this.isLockExtensionPermission = this.permissionService.getPermission("LockExtension");
    this.isUpdateExtensionPermission = this.permissionService.getPermission("UpdateExtension");
    this.isUnLockExtensionPermission = this.permissionService.getPermission("UnLockExtension");
    this.isCreateExtensionPermission = this.permissionService.getPermission("CreateExtension");
    this.isViewSolutionPackagePermission = this.permissionService.getPermission("ViewSolutionPackage");
    this.isCreateSolutionPackage = this.permissionService.getPermission("CreateSolutionPackage");
    let featuresTobeDisabled: any = localStorage.getItem('featuresTobeDisabled');
    this.linkageAndQueueForUpgradeFeature = featuresTobeDisabled?.includes('TenantUpgradeDisableLinkage')? false: true;
    this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    this.extensionLabel = this.extensionEnum.extension_header_create;
    this.packageLabel = this.extensionEnum.package_header_create;
    this.certificationLabel = this.extensionEnum.certification_header_create;
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.innerWidth = window.innerWidth;
    this.getInitialWindowSize(this.innerWidth);
    if (!this.isCreateSolutionPackage) {
      this.frmAddPackage.disable();
    }
    this.PartnerId = this.permissionService.getPartnerDetails().id;
    this.userCategory=this.permissionService.getUsercategoryId();   
    if(this.userCategory == USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;      
    }
    else{
      this.isCustomerUser = false;
    }
    this.extDetails = localStorage.getItem("extIdFromPckgList");
    if (this.extDetails != undefined) {
      this.extId = JSON.parse(this.extDetails).id
      this.extensionName = JSON.parse(this.extDetails).name;
      this.extensionPartnerId = JSON.parse(this.extDetails).partnerId;
      this.extensionType = JSON.parse(this.extDetails).extensionType;
      if(JSON.parse(this.extDetails)?.fromPackageListUpload){
        this.linkedPackage = localStorage.getItem('packageId') ? Number(localStorage.getItem('packageId')):0
        this.disableLinkedPackage = this.linkedPackage? true : false;
      }
      this.getMinimumIciversion();
      this.getPartnerInformation();
    } else {
      this.bindCustomerDropDown()      
    }
    if(!this.isCustomerUser){
    this.CheckRLExtensionExistsforPartner();
    }
    this.initialValues = this.frmCreateExtension.value;
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required],
    });

    this.columnFilterCtrlCo.valueChanges.subscribe((value) => {
      this.filterColumnCo(value);
    });
    this.packageNameFilterCtrl.valueChanges.subscribe((value) => {
      this.filterColumnPackageList(value);
    });
    this.frmAddPackage.controls["major"].valueChanges.subscribe((value:any) => {
      this.majorversion = value;
      this.versionSuffix = this.majorversion + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    })
    this.frmAddPackage.controls["minor"].valueChanges.subscribe((value:any) => {
      this.minorversion = value;
      this.versionSuffix = this.frmAddPackage.value.major + '.' + this.minorversion + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    })
    this.frmAddPackage.controls["patch"].valueChanges.subscribe((value) => {
      this.patchversion = value;
      this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.patchversion + '.' + this.frmAddPackage.value.build;
    })
    this.frmAddPackage.controls["build"].valueChanges.subscribe((value) => {
      this.buildversion = value;
      this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.buildversion;
    });
    this.frmAddPackage.controls["icimajor"].valueChanges.subscribe((value) => {
      this.iciMajorVersion = value;
    });
    this.frmAddPackage.controls["iciminor"].valueChanges.subscribe((value) => {
      this.iciMinorVersion = value;
    })
    this.frmAddPackage.controls["icipatch"].valueChanges.subscribe((value) => {
      this.iciPatchVersion = value;
    })
    this.frmAddPackage.controls["icibuild"].valueChanges.subscribe((value) => {
      this.iciBuildVersion = value;
    })
  }

  ngAfterViewInit() {
    if (this.extDetails != undefined && JSON.parse(this.extDetails).hasOwnProperty("fromPackageList")) {
      this.mystepper.linear = false;
      this.mystepper.selectedIndex = 1;
      this.mystepper.linear = true;
      if(!this.linkedPackage){
        this.frmAddPackage.controls["name"].setValue(this.extensionName)
      }
      this.frmAddPackage.get('name')?.markAsTouched();
      const element = document.getElementById('name');
      if (element) {
        element.focus();
      }
      this.isPrevDisable = true
      this.isaddPackage = true
      this.isAnimationDisable = true
      if(this.linkageAndQueueForUpgradeFeature){
        this.getSolutionPackagesForLinkage(this.extId);
      }
    }
  }

    //To get Extension Package List
    getSolutionPackagesForLinkage(extensionId: any) {
      try {
        this.extensionService.getSolutionPackagesForLinkage(extensionId).subscribe((resp: any) => {
          if (resp != undefined && resp != null && resp != "") {
            this.packageList = resp
            this.packageDisplayList = this.packageList;
            if(this.linkedPackage){
              let linkedPackageName = resp?.filter((data:any)=> {return data.id== this.linkedPackage})[0]?.name;
              linkedPackageName = linkedPackageName?.substring(0,linkedPackageName?.lastIndexOf('_')!=-1? linkedPackageName?.lastIndexOf('_') : linkedPackageName?.length)
              this.frmAddPackage.controls['name'].setValue(linkedPackageName);
            }            
          }
        });
      } catch (exception) {
        console.log(exception);
      }
    }

  getPartnerInformation(){
    if(!this.isCustomerUser){
    this.partnerService.GetPartnerById(this.PartnerId).subscribe((res) => {
      if(res != null && res != undefined) {
        this.binaryPackageUpload = (res.body.allowedSKUScopeId == publicSku && this.extensionType == this.extensionEnum.extension_type_business_app) ? true : false;
      }
    });
  }
  }

  async getMinimumIciversion(){
    this.minimumICIVersionForBinaryPackage = await this.idnConfiguration.getMinimumIciversionForBinaryPackage();
    let [majorVersion, minorVersion, patchVersion, buildVersion] = this.minimumICIVersionForBinaryPackage.split('.');
    this.frmAddPackage.patchValue({
      icimajor: Number(majorVersion),
      iciminor: Number(minorVersion),
      icipatch: Number(patchVersion),
      icibuild: Number(buildVersion)
    })
  }

  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescriptionName = this.regex.descriptionErrorMessage;
      return;
    }
    if (!this.regexDescription.test(event[0])) {
      this.errorDescriptionName = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescriptionName = this.regex.descriptionErrorMessage;
    }
  }

  validateMinimumICIVersionForBinaryPackage(){
    let IciMajor = this.frmAddPackage.controls.icimajor.value;
    let IciMinor = this.frmAddPackage.controls.iciminor.value;
    let IciPatch = this.frmAddPackage.controls.icipatch.value;
    let IciBuild = this.frmAddPackage.controls.icibuild.value;
    let selectedICIVersion = `${IciMajor}.${IciMinor}.${IciPatch}.${IciBuild}`;
    if(this.binaryPackage){
      let validVersion = selectedICIVersion.localeCompare(this.minimumICIVersionForBinaryPackage, undefined, { numeric: true, sensitivity: 'base' });
      return (validVersion < versionCheck);
    } else {
      return false;
    }
  }


  //To move to previous stepper
  myStepperNext() {
    this.isExtensionFormSubmitted = true;
    this.mystepper.next();
  }

  //To move to previous stepper
  previousStepper() {
    this.getExtDataById(this.extId)
    this.mystepper.previous();
  }

  //To Update data
  async updateAndCLose(val: any) {
    try {
      if (!this.frmCreateExtension.valid) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
        return
      }

      this.eventObject['extName'] = this.frmCreateExtension.value.name;
      this.eventObject['extDesc'] = this.frmCreateExtension.value.description;
      this.extensionService.emit<any>(this.eventObject);

      let JSON = this.frmCreateExtension.getRawValue();
      JSON['timestamp'] = this.extTimestamp;
      JSON.partnerIds = this.hasGlobalDataAccess? JSON.partnerIds : this.isCustomerUser ? [] :[this.PartnerId];

      this.extensionService.putDataUrl("api/Extension/Update", {}, JSON).subscribe(async (result: any) => {
        if (result.status == 200) {
          this.notificationService.showNotification(this.extensionEnum.extension_notify_extUpdatedSuccessfully_body, 'success', this.extensionEnum.extension_notify_extUpdatedSuccessfully_heading);
          if (val == 'close') {
            this.router.navigate(["home/developmentandutilities/extension-management"]);
            this.extensionService.emit<any>(this.eventObject);

          }
          else {
            this.mystepper.next();
          }

        }
      },
        (error) => {
          console.log(error);
        })
    }
    catch (exception) {
      console.log(exception)
    }
  }

  toggleBinaryPackage(event:any){
    if(event.checked){
      this.binaryPackage = true;
      this.frmAddPackage.controls.icimajor.addValidators(Validators.required)
      this.frmAddPackage.controls.iciminor.addValidators(Validators.required)
      this.frmAddPackage.controls.icipatch.addValidators(Validators.required)
      this.frmAddPackage.controls.icibuild.addValidators(Validators.required)
    } else {
      this.binaryPackage = false;
      this.frmAddPackage.controls.icimajor.clearValidators();
      this.frmAddPackage.controls.iciminor.clearValidators();
      this.frmAddPackage.controls.icipatch.clearValidators();
      this.frmAddPackage.controls.icibuild.clearValidators();
    }
    this.frmAddPackage.controls.icimajor.updateValueAndValidity();
    this.frmAddPackage.controls.iciminor.updateValueAndValidity();
    this.frmAddPackage.controls.icipatch.updateValueAndValidity();
    this.frmAddPackage.controls.icibuild.updateValueAndValidity();
  }

  // Get data by Id
  getExtDataById(id: any) {
    try {
      this.extensionService.getExtensionById(id).subscribe((result: any) => {
        if (result != undefined && result != null && result != "") {
          this.extTimestamp = result.timestamp;
          if (result.tags == null) {
            result.tags = [];
          }
          if (result.files == null) {
            result.files = [];
          }
          this.frmCreateExtension.patchValue({
            name: result.name,
            description: result.description,
            typeId: result.typeId,
            customerId: result.customerId,
            tags: result.tags,
            files: result.files,
            id: result.id,
            uniqueIdentifier: result.uniqueIdentifier,
            locked: result.locked
          })

          this.frmCreateExtension.controls["name"].disable();
          this.frmCreateExtension.controls["typeId"].disable();
          this.frmCreateExtension.controls["customerId"].disable();
          this.extInEditMode = true;
          this.initialData = this.frmCreateExtension.value.description;

          this.listofUploadedFiles = result.files;
          if (this.listofUploadedFiles.length > 0) {
            this.isFileUploaded = true;
          }
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }
  async updateExtensionandNext() {
    this.dialogService.confirmDialog({
      title: 'Confirm Update',
      module: 'Extension',
      message: 'Are you sure you want to Update Extension?',
      confirmText: 'Update',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.updateAndCLose('Next');
      }
    })
  }
  checkIfExtensionDataChanged(description: any): Boolean {
    if (this.initialData == description) {
      return true;
    }
    return false;
  }
  //To Create and Save Extension
  async saveOnNext() {
    if (this.extId !== "") {
      if (this.checkIfExtensionDataChanged(this.frmCreateExtension.value.description)) {
        this.mystepper.next();
      }
      else {
        this.updateExtensionandNext()
      }
    }
    else {
      try {
        if (!this.frmCreateExtension.valid && this.frmCreateExtension.status != 'DISABLED') {
          this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
          return
        }
        else if (this.frmCreateExtension.status == 'DISABLED') {
          this.myStepperNext();
          return
        }
        this.dialogService.confirmDialog({
          title: 'Confirm Save',
          module: 'extension',
          message: this.extensionEnum.extension_dialog_Save_msg,
          confirmText: 'Save',
          cancelText: 'Cancel'
        }).subscribe(async (res: any) => {
          if (res) {
            this.eventObject['showProgressBar'] = 'true';
            this.eventObject['extName'] = this.frmCreateExtension.value.name?.trim();
            this.eventObject['extDesc'] = this.frmCreateExtension.value.description;
            this.extensionService.emit<any>(this.eventObject);

            if (!this.frmCreateExtension.valid) {
              this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
              return;
            }
            if (this.listofUploadedFiles.length > 0) {
              this.frmCreateExtension.value.files = this.listofUploadedFiles;
            }

            let JSON = this.frmCreateExtension.getRawValue();
            JSON['name'] = JSON['name']?.trim()
            if (JSON.customerId == "") {
              JSON.customerId = null;
            }
            JSON.partnerIds = this.hasGlobalDataAccess? JSON.partnerIds : this.isCustomerUser ? [] :[this.PartnerId];
            delete JSON.uniqueIdentifier;
            let isexist: any = await this.checkIfExtensionExist(JSON.name, JSON.customerId)
            if (isexist != undefined && !isexist.body) {
              //API Calling
              this.extensionService.postDataUrl("api/Extension/Add", {}, JSON).subscribe(async (result: any) => {
                if (result.status == 200) {
                  this.extId = result.body;
                  let resToken = await this.setToken(this.extId);
                  if (resToken != null && resToken != undefined) {
                    this.sas = resToken.sasUrl;
                    this.uniqueIdentifier = resToken.container;
                    this.frmCreateExtension.controls['uniqueIdentifier'].setValue(this.uniqueIdentifier);
                  }
                  this.extensionName = this.frmCreateExtension.get('name')?.value?.trim();
                  this.extensionType =  this.frmCreateExtension.get('typeId')?.value == 1 ? this.extensionEnum.extension_type_business_app : this.extensionEnum.extension_type_custom_solution;
                  if (this.fileArray.length > 0) {
                    this.loader.show();
                    await this.uploadFileAsynchronously().then(async (result: any) => {
                      let x = await this.updateFileName(this.fileArray, this.extId);
                      if (x.status == 200) {
                        this.notificationService.showNotification(this.extensionEnum.extension_notify_extensionCreated_body, 'success', this.extensionEnum.extension_notify_extensionCreated_heading)
                        this.frmAddPackage.controls["name"].setValue(this.extensionName);
                        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build
                        this.isExtensionFormSubmitted = true;
                        this.loader.hide();
                        this.mystepper.next();
                        this.eventObject['showProgressBar'] = 'false';
                        this.extensionService.emit<any>(this.eventObject);
                      }
                      this.getMinimumIciversion();
                      this.getPartnerInformation();
                    });
                  }
                  else {
                    this.notificationService.showNotification(this.extensionEnum.extension_notify_extensionCreated_body, 'success', this.extensionEnum.extension_notify_extensionCreated_heading)
                    this.frmAddPackage.controls["name"].setValue(this.extensionName);
                    this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build
                    this.isExtensionFormSubmitted = true;
                    this.mystepper.next();
                    this.eventObject['showProgressBar'] = 'false';
                    this.extensionService.emit<any>(this.eventObject);
                    this.getMinimumIciversion();
                    this.getPartnerInformation();
                  }
                }
                else {
                  this.notificationService.showNotification(this.extensionEnum.extension_notify_savefailed_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
                  this.eventObject['showProgressBar'] = 'false';
                  this.extensionService.emit<any>(this.eventObject);

                }
              },
                (error: HttpErrorResponse) => {
                  console.log(error);
                }
              );
            }
            else {
              if(this.userCategory == USER_CATEGORY_ID.Partner){
                this.notificationService.showNotification(`\"${JSON.name}\" ${this.extensionEnum.extension_notify_duplicateExt_body_partner_user}`, 'error', this.extensionEnum.extension_notify_duplicateExt_heading);
              } else {
                this.notificationService.showNotification(this.extensionEnum.extension_notify_duplicateExt_body, 'error', this.extensionEnum.extension_notify_duplicateExt_heading);
              }
              this.eventObject['showProgressBar'] = 'false';
              this.extensionService.emit<any>(this.eventObject);
            }
          }
        });

      }
      catch (e) {
        console.log(e);
      }
    }
  }

  //To Create and Save Extension
  async saveAndClose() {
    try {
      this.eventObject['showProgressBar'] = 'true';
      this.eventObject['extName'] = this.frmCreateExtension.value.name;
      this.eventObject['extDesc'] = this.frmCreateExtension.value.description;
      this.eventObject['saveAndClose'] = 'true';
      this.extensionService.emit<any>(this.eventObject);

      if (!this.frmCreateExtension.valid && this.frmCreateExtension.status != 'DISABLED') {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
        return
      }
      else if (this.frmCreateExtension.status == 'DISABLED') {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_extensionAlreadyCreated_body, 'warning', this.extensionEnum.extension_notify_extensionAlreadyCreated_heading);
        return
      }
      if (this.listofUploadedFiles.length > 0) {
        this.frmCreateExtension.value.files = this.listofUploadedFiles;
      }
      let JSON = this.frmCreateExtension.getRawValue();
      JSON['name'] = JSON['name']?.trim()
      if (JSON.customerId == "") {
        JSON.customerId = null;
      }
      delete JSON.uniqueIdentifier;
      JSON.partnerIds = this.hasGlobalDataAccess? JSON.partnerIds : this.isCustomerUser ? [] :[this.PartnerId];
      let isexist: any = await this.checkIfExtensionExist(JSON.name, JSON.customerId)
      if (isexist != undefined && !isexist.body) {
        //API Calling
        this.extensionService.postDataUrl("api/Extension/Add", {}, JSON).subscribe(async (result: any) => {
          if (result.status == 200) {

            let extId = result.body;
            let resToken = await this.setToken(extId);
            if (resToken != null && resToken != undefined) {
              this.sas = resToken.sasUrl;
              this.uniqueIdentifier = resToken.container;
              this.frmCreateExtension.controls['uniqueIdentifier'].setValue(this.uniqueIdentifier);
            }
            this.router.navigate(["home/developmentandutilities/extension-management"]);
            if (this.fileArray.length > 0) {
              await this.uploadFileAsynchronously().then(async (result: any) => {
                let x = await this.updateFileName(this.fileArray, extId);
                if (x.status == 200) {
                  this.isExtensionFormSubmitted = true;
                  this.notificationService.showNotification(this.extensionEnum.extension_notify_extensionCreated_body, 'success', this.extensionEnum.extension_notify_extensionCreated_heading)
                  this.mystepper.next();
                  this.eventObject['showProgressBar'] = 'false';
                  this.extensionService.emit<any>(this.eventObject);
                }
              });
            }
            else {
              this.notificationService.showNotification(this.extensionEnum.extension_notify_extensionCreated_body, 'success', this.extensionEnum.extension_notify_extensionCreated_heading)
              this.eventObject['showProgressBar'] = 'false';
              this.extensionService.emit<any>(this.eventObject);
            }
            this.frmCreateExtension.reset(this.initialValues);
            this.listofUploadedFiles = [];
          }
          else {
            this.notificationService.showNotification(this.extensionEnum.extension_notify_savefailed_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
          }
        },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
        );
      }
      else {
        if(this.userCategory == USER_CATEGORY_ID.Partner){
        this.notificationService.showNotification(`\"${JSON.name}\" ${this.extensionEnum.extension_notify_duplicateExt_body_partner_user}`, 'error', this.extensionEnum.extension_notify_duplicateExt_heading);
        } else {
          this.notificationService.showNotification(this.extensionEnum.extension_notify_duplicateExt_body, 'error', this.extensionEnum.extension_notify_duplicateExt_heading);
        }
      }


    }
    catch (e) {
      console.log(e);
    }
  }


  async checkIfExtensionExist(extName: any, customerId: any) {
    try {
      return await lastValueFrom(this.extensionService.checkForDuplicateExtension(extName, customerId))
    }
    catch (e) {
      console.log(e)
      return e;
    }
  }

  //To generate default GUID
  defaultGuid() {
    return 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'.replace(/[x]/g, function (c) {
      var r = 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  //To save Package And redirect to package listing page.
  async savePackageAndClose() {
    try {
      let solutionPackageApi ='';
      this.eventObject['showProgressBar'] = 'true';
      this.eventObject['pckgName'] = this.frmAddPackage.value.name;
      this.eventObject['extDesc'] = this.frmCreateExtension.value.description;
      this.extensionService.emit<any>(this.eventObject);

      if (this.package != undefined && this.package.name != undefined && this.package.name != "") {
        this.frmAddPackage.patchValue(
          { fileName: this.package.name });
      }
      if (this.extId != "" && this.extId != undefined) {
        this.frmAddPackage.controls["extensionId"].setValue(this.extId);
      }
      this.frmAddPackage.controls["uniqueIdentifier"].setValue(this.defaultGuid());

      let JSON = this.frmAddPackage.getRawValue();
      JSON["name"] = this.frmAddPackage.value.name?.trim()+"_"+this.versionSuffix
      if (!this.frmAddPackage.valid) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_notvalid_msg, 'warning', this.extensionEnum.package_notify_packageNotCreated_heading);
        return;
      }
      delete JSON.uniqueIdentifier;
      JSON.PartnerId = this.extensionPartnerId ? this.extensionPartnerId : this.PartnerId;
      if(this.packageList.length){
        this.packageList?.forEach((data:any)=>{
          if(data?.id == this.linkedPackage){
            JSON["parentSolutionPackageId"] = data.id;
            JSON["linkageId"] = data.linkageId;
          }
        })
      } else {
        JSON["parentSolutionPackageId"] = null;
        JSON["linkageId"] = null;
      }
      
      
      //API Calling
      if(!this.binaryPackage){
        solutionPackageApi = "api/SolutionPackage/CreateSolutionPackage"
      } else {
        solutionPackageApi = "api/SolutionPackage/BinarySolutionPackage";
        JSON.iciVersion = {
          major: JSON.icimajor,
          minor:JSON.iciminor,
          patch:JSON.icipatch,
          build:JSON.icibuild
        }
        JSON["parentSolutionPackageId"] = null;
        JSON["linkageId"] = null;
      }
      delete JSON.icimajor;
      delete JSON.iciminor;
      delete JSON.icipatch;
      delete JSON.icibuild;
        this.extensionService.postDataUrl(solutionPackageApi, {}, JSON).subscribe(async (result: any) => {
          if (result.status == 200) {
            let packageId = result.body;
            let resToken = await this.setTokenForPackage(packageId);
            if (resToken != null && resToken != undefined) {
              this.sas = resToken.sasUrl;
              this.uniqueIdentifier = resToken.container;
              this.frmAddPackage.controls['uniqueIdentifier'].setValue(this.uniqueIdentifier);
            }
            let data:any={}
            data['id']=this.extId;
            data["fromCreatePackage"] = true;
            this.extensionService.emitExtDetails(data);
            let navLink = 'home/developmentandutilities/extension-management/view'
            this.router.navigate([navLink]);
            await this.uploadPackageFileAsynchronously().then(async (result: any) => {
              if (result == 'success') {
                this.isExtensionFormSubmitted = true;
                let res = await this.updatePackageFileStatusUploaded(packageId);
                if (res.status == 200) {
                  this.notificationService.showNotification(
                    this.extensionEnum.package_notify_packageCreated_body,
                    'success',
                    this.extensionEnum.package_notify_packageCreated_heading
                  );
                  this.eventObject['showProgressBar'] = 'false';
                  this.eventObject['extid'] = this.extId;
                  this.eventObject['savePackageAndClose'] = 'true';
                  this.extensionService.emit<any>(this.eventObject);
                } else {
                  this.notificationService.showNotification(
                    this.extensionEnum.package_notify_packageNotcreated_body,
                    'error',
                    this.extensionEnum.package_notify_packageNotCreated_heading
                  );
                }
                this.mystepper.next();
              } else {
                let res = await this.updatePackageFileStatusFailed(packageId);
                if (res.status == 200) {
                  this.extensionService.emit<any>(this.eventObject);
                }
              }
            });
          }
          else if (result.status == 201) {
            this.notificationService.showNotification(this.extensionEnum.package_notify_packageAlreadyExist_body, 'error',this.extensionEnum.package_notify_packageAlreadyExist_heading);
  
          } else {
            this.notificationService.showNotification(this.extensionEnum.extension_notify_savefailed_msg, 'warning', this.extensionEnum.extension_notify_extensionNotCreated);
          }
          this.eventObject['showProgressBar'] = 'false';
          this.extensionService.emit<any>(this.eventObject);
        },
          (error: HttpErrorResponse) => {
            console.log(error);
          }
        );
    }
    catch (e) {
      console.log(e);
    }
  }

  async updatePackageFileStatusUploaded(packageId: number): Promise<any> {
    return await lastValueFrom(this.extensionService.postDataUrl("api/SolutionPackage/UpdateSolutionStatusPackageUploaded", { solutionPackageId: packageId }))
  }

  async updatePackageFileStatusFailed(packageId: number): Promise<any> {
    return await lastValueFrom(this.extensionService.postDataUrl("api/SolutionPackage/UpdateSolutionStatusUploadFailed", { solutionPackageId: packageId }))
  }

  //To Save Package and move to next wizard
  async savePackageAndNext() {
  }

  //To Upload package file on blob
  uploadPackageFileAsynchronously() {
    return new Promise(resolve => {
      try {
          this.blobService.uploadFiles(this.sas, this.uniqueIdentifier, this.package, this.package.name, (response: any) => {
            if (response._response != undefined) {
              this.azUrl = response._response.request.url;
              resolve('success')
            }
            else {
              resolve('Fail')
            }

          })
      }
      catch (execption) {
        console.log(execption);
      }
    });
  }

  //To add package
  addPackage() {
    this.isaddPackage = true;
  }

  // To Upload Multiple Files Async
  uploadFileAsynchronously() {
    return new Promise(resolve => {
      try {
          let counter = 0;
          this.fileArray.forEach((x: any) => {
            this.blobService.uploadFiles(this.sas, this.uniqueIdentifier, x.fileContent, x.fileName, (response: any) => {
              counter += 1;
              if (response._response != undefined) {
                this.azUrl = response._response.request.url;
                x["azUrl"] = this.azUrl;
                x.isUploaded = true;
              }
              if (counter == this.fileArray.length) {
                resolve("Test")
              }
            })
          })
      }
      catch (execption) {
        console.log(execption);
      }
    });
  }

  //To Update Package filename
  async updatePackageFileName(packageFile: any, packageId: any, extId: any): Promise<any> {
    try {
      this.frmAddPackage.patchValue({
        id: packageId,
        extensionId: extId,
        fileName: packageFile.name,
        uniqueIdentifier: this.uniqueIdentifier
      })
      this.frmAddPackage.addControl('timestamp', new FormControl(this.packageTimestamp));
      let JSON = this.frmAddPackage.getRawValue();
      return await this.extensionService.putDataUrl("api/SolutionPackage/UpdateSolutionPackage", {}, JSON).toPromise();
    }
    catch (exception) {
      console.log("exception :", exception);
    }
  }

  //To Update File name which is successfully uploaded
  async updateFileName(array: any[], extId: any): Promise<any> {
    try {
      let fileNames: any[] = [];
      array.filter(x => {
        if (x.isUploaded == true) {
          fileNames.push(x.fileName);
        }
      })
      this.frmCreateExtension.patchValue({
        id: extId,
        files: fileNames,
        uniqueIdentifier: this.uniqueIdentifier
      })
      let JSON = this.frmCreateExtension.getRawValue();
      if (JSON.customerId == "") {
        JSON.customerId = null;
      }
      JSON.partnerIds = this.hasGlobalDataAccess? JSON.partnerIds : this.isCustomerUser ? [] :[this.PartnerId];
      return await this.extensionService.putDataUrl("api/Extension/Update", {}, JSON).toPromise();
    }
    catch (exception) {
      console.log(exception);
    }

  }

  //To filter search result in customer name dropdown
  filterColumnCo(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.CustomerNameList = this.CustomerDisplayList.filter((x:any) =>
      x.customerName.toLowerCase().includes(valueLowerCase)
    )
  }

  //To filter search result in linked package name dropdown
  filterColumnPackageList(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.packageList = this.packageDisplayList?.filter((x:any) =>
      x.name.toLowerCase().includes(valueLowerCase)
    )
  }

  //To Add Tags
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.items.push(value);
      this.frmCreateExtension.patchValue({
        tags: this.items
      })
    }
    event.chipInput!.clear();
  }

  //To Remove Tags
  remove(item: any): void {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
      this.frmCreateExtension.patchValue({
        tags: this.items
      })
    }
  }

  //To get the extension types based on customer id
  extensionTypeSelect(event: any) {
    let customerName = this.CustomerNameList?.filter((data:any)=>{ return data.id == this.frmCreateExtension.get('customerId')?.value})[0]?.customerName;
    let defaultExtensionName = `${customerName}_CustomSolutions`
    if(event.value == CUSTOMSOLUTION){
      this.frmCreateExtension.controls['name'].setValue(defaultExtensionName);
      this.frmCreateExtension.controls['name'].disable();
    } else {
      this.frmCreateExtension.controls['name'].reset();
      this.frmCreateExtension.controls['name'].enable();
    }
  }

  //To add file on click plus sign.
  fileAdd() {
    if (this.file != undefined && this.file != null && this.file != '') {
      var lastDot = this.file.name.lastIndexOf(".");
      const extension = this.file.name.substring(lastDot + 1).toLowerCase();
      const validExtension = this.fileExtension.filter((x) => x == extension).length > 0;
      if (!validExtension) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_invalidFileType_body, 'warning', this.extensionEnum.extension_notify_invalidFileType_heading);
        this.file = null;
        this.fileAttr = 'Choose File';
        return
      }
      if (this.fileAttr != null && this.fileAttr != "" && this.fileAttr != undefined && this.fileAttr != 'Choose File') {
        let isDuplicate = this.checkDuplicateFile(this.fileAttr);
        if (isDuplicate) {
          this.listofUploadedFiles.push(this.fileAttr);
          this.fileArray.push({ 'fileContent': this.file, 'fileName': this.file.name, 'isUploaded': false })
          this.fileAttr = 'Choose File';
          this.file = "";
        }
        else {
          this.fileAttr = 'Choose File';
        }
      }
      else if (this.fileAttr == 'Choose File') {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_noFileChoosen_body, 'warning', this.extensionEnum.extension_notify_noFileChoosen_heading);
      }
    }
    else {
      this.notificationService.showNotification(this.extensionEnum.extension_notify_noFileChoosen_body, 'warning', this.extensionEnum.extension_notify_noFileChoosen_heading);

    }
  }

  //To check duplicate file.
  checkDuplicateFile(filename: string): boolean {
    if (this.listofUploadedFiles.includes(filename)) {
      this.notificationService.showNotification(this.extensionEnum.extension_notify_duplicateFile_body, 'warning', this.extensionEnum.extension_notify_duplicateFile_heading);
      return false;
    }
    else {
      return true;
    }
  }

  //To remove added file tags.
  removeFileTag(item: any) {
    const index = this.listofUploadedFiles.indexOf(item);
    if (index >= 0) {
      this.listofUploadedFiles.splice(index, 1);
      this.fileArray.splice(index, 1);
    }
  }

  //File upload event
  uploadFileEvt(File: any) {
    if (File.target.files && File.target.files[0]) {
      this.file = File.target.files[0];
      var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
      if (this.file.size == 0) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_emptyFile_body, 'error', this.extensionEnum.extension_notify_emptyFile_heading);
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return
      }
      if (filesize > this.fileSizeLimit) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_invalidFileSize_body, 'error', this.extensionEnum.extension_notify_invalidFileSize_heading);
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return
      }
      this.fileAttr = '';
      this.fileAttr = File.target.files[0].name;
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  //Package upload event
  uploadPackageEvt(File: any) {  
    if (File.target.files[0] != undefined && File.target.files[0] != null && File.target.files[0] != '') {
      var lastDot = File.target.files[0].name.lastIndexOf(".");
      const extension = File.target.files[0].name.substring(lastDot + 1).toLowerCase();
      const validExtension = this.packageExtension.filter((x) => x == extension).length > 0;
      if (!validExtension) {
        this.notificationService.showNotification(this.extensionEnum.extension_notify_invalidFileType_body, 'warning', this.extensionEnum.extension_notify_invalidFileType_heading);
        this.package = null;
        this.fileInput.nativeElement.value = "";
        this.packageFileAttr = 'Package zip File *';
        this.frmAddPackage.get('fileName')?.reset();
        return
      }
      this.file = File.target.files[0];
      var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
      if (filesize > this.packageSizeLimit) {
        this.notificationService.showNotification(this.extensionEnum.package_notify_fileSizeLimit_body, 'error', this.extensionEnum.package_notify_fileSizeLimit_heading);
        this.package = null;
        this.fileInput.nativeElement.value = "";
        this.packageFileAttr = 'Package zip File *';
        this.frmAddPackage.get('fileName')?.reset();
        return
      }
      else if (filesize == 0) {
        this.notificationService.showNotification(this.extensionEnum.package_notify_fileblank_body, 'error', this.extensionEnum.package_notify_fileblank_heading);
        this.package = null;
        this.fileInput.nativeElement.value = "";
        this.packageFileAttr = 'Package zip File *';
        this.frmAddPackage.get('fileName')?.reset();
        return
      }
      if (File.target.files && File.target.files[0]) {
        this.package = File.target.files[0];
        this.packageFileAttr = '';
        this.packageFileAttr = File.target.files[0].name;
        this.frmAddPackage.patchValue(
          { fileName: this.packageFileAttr });
      }
    }
  }

  //To bind Extension drop down
  bindExtensionDropDown() {
    try {

      let customerId = this.isCustomerUser ? this.customeruserCustomerId : this.frmCreateExtension.value.customerId;
      this.extensionService.getExtensionTypes(this.userCategory,customerId,this.PartnerId).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.ExType = result;
          let customExtensionExists = !(this.ExType?.length && this.ExType?.some((data:any)=> data.id == CUSTOMSOLUTION));
          if(customExtensionExists){
            this.selectedCustomerName = this.CustomerNameList.filter((data:any)=>{
              return data.id == customerId;
            })[0]?.customerName
          } else{
            this.selectedCustomerName = null
          }
          this.frmCreateExtension.get("typeId")?.reset();
          this.frmCreateExtension.get("name")?.reset();
          this.frmCreateExtension.get("name")?.enable();
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  CheckRLExtensionExistsforPartner(){
    try {
      this.extensionService.CheckRLExtensionExistsForPartner(this.PartnerId).subscribe((result: any) => {
        if(result != null){
          this.RLExtensionName = result
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  getPartnersByCustomerId(){
    try {
      let customerId = this.frmCreateExtension.value.customerId;
      this.extensionService.getPartnersByCustomerId(customerId).subscribe((data:any)=>{
        if(data){
          this.partnerList = data;
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  //To bind Customer drop down
  bindCustomerDropDown() {
    const partnerId = this.permissionService.getPartnerDetails().id;
    try {    
      this.extensionService.getCustomers(partnerId).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.CustomerNameList = result;
          if(this.isCustomerUser){
            this.customeruserCustomerId = this.CustomerNameList[0].id;       
            this.frmCreateExtension.controls.customerId.setValue(this.CustomerNameList[0].id);
            this.frmCreateExtension.controls.customerId.disable();
            this.bindExtensionDropDown();
          }         
          this.CustomerDisplayList = this.CustomerNameList;
        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  //To Set SAS Token
  async setToken(ExtId: any): Promise<any> {
    try {
      return this.extensionService.getBlobUploadConnectionForExtension(ExtId).toPromise();
    }
    catch (execption) {
      console.log(execption)
    }
  }
  async setTokenForPackage(PackageId?: any): Promise<any> {
    try {
      return this.extensionService.getBlobUploadConnectionForSolution(PackageId).toPromise();
    }
    catch (execption) {
      console.log(execption)
    }
  }

  //To Reset form and redirect to listing page
  cancel() {
    this.frmCreateExtension.reset();
    this.router.navigate(["home/developmentandutilities/extension-management"]);
  }

  async cancelPackage() {
    let frmPackage: any = localStorage.getItem("frmPackageToCreateExt");
    if (frmPackage == "true") {
      localStorage.setItem("frmPackageToCreateExt", "false");
      let data: any = await this.getDataByExtId(this.extId);
      data["fromCreatePackage"] = true;
      this.extensionService.emitExtDetails(data);
      this.router.navigate(["home/developmentandutilities/extension-management/view"]);
    } else {
      this.frmAddPackage.reset();
      this.router.navigate(["home/developmentandutilities/extension-management"]);
    }
  }

  //Increase value by 1 pressing plus button
  increaseValue(value: any) {
    if (value == 'major') {
      this.majorversion += 1;
      this.frmAddPackage.controls["major"].setValue(this.majorversion);
      this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    }
    else if (value == 'minor') {
      this.minorversion += 1;
      this.frmAddPackage.controls["minor"].setValue(this.minorversion);
     this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    }
    else if (value == 'patch') {
      this.patchversion += 1;
      this.frmAddPackage.controls["patch"].setValue(this.patchversion);
      this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    }
    else if (value == 'build') {
      this.buildversion += 1;
      this.frmAddPackage.controls["build"].setValue(this.buildversion);
      this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
    }
  }

  //Decrease value by 1 pressing plus button
  decreaseValue(value: any) {
    if (value == 'major') {
      if (this.majorversion > 0) {
        this.majorversion -= 1;
        this.frmAddPackage.controls["major"].setValue(this.majorversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
      else {
        this.majorversion = 0;
        this.frmAddPackage.controls["major"].setValue(this.majorversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
    }
    else if (value == 'minor') {
      if (this.minorversion > 0) {
        this.minorversion -= 1;
        this.frmAddPackage.controls["minor"].setValue(this.minorversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
      else {
        this.minorversion = 0;
        this.frmAddPackage.controls["minor"].setValue(this.minorversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
    }
    else if (value == 'patch') {
      if (this.patchversion > 0) {
        this.patchversion -= 1;
        this.frmAddPackage.controls["patch"].setValue(this.patchversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
      else {
        this.patchversion = 0;
        this.frmAddPackage.controls["patch"].setValue(this.patchversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
    }
    else if (value == 'build') {
      if (this.buildversion > 0) {
        this.buildversion -= 1;
        this.frmAddPackage.controls["build"].setValue(this.buildversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
      else {
        this.buildversion = 0;
        this.frmAddPackage.controls["build"].setValue(this.buildversion);
        this.versionSuffix = this.frmAddPackage.value.major + '.' + this.frmAddPackage.value.minor + '.' + this.frmAddPackage.value.patch + '.' + this.frmAddPackage.value.build;
      }
    }
  }

  increaseVersion(value: any){
    if (value == 'icimajor') {
      this.iciMajorVersion += 1;
      this.frmAddPackage.controls["icimajor"].setValue(this.iciMajorVersion);
    }
    else if (value == 'iciminor') {
      this.iciMinorVersion += 1;
      this.frmAddPackage.controls["iciminor"].setValue(this.iciMinorVersion);
    }
    else if (value == 'icipatch') {
      this.iciPatchVersion += 1;
      this.frmAddPackage.controls["icipatch"].setValue(this.iciPatchVersion);
    }
    else if (value == 'icibuild') {
      this.iciBuildVersion += 1;
      this.frmAddPackage.controls["icibuild"].setValue(this.iciBuildVersion);
    }
  }

  decreaseVersion(value: any){
    if (value == 'icimajor') {
      if(this.iciMajorVersion>0){
        this.iciMajorVersion -= 1;
        this.frmAddPackage.controls["icimajor"].setValue(this.iciMajorVersion);
      } else {
        this.iciMajorVersion = 0;
        this.frmAddPackage.controls["icimajor"].setValue(this.iciMajorVersion);
      }
      
    }
    else if (value == 'iciminor') {
      if(this.iciMinorVersion>0){
        this.iciMinorVersion -= 1;
        this.frmAddPackage.controls["iciminor"].setValue(this.iciMinorVersion);
      } else {
        this.iciMinorVersion = 0;
        this.frmAddPackage.controls["iciminor"].setValue(this.iciMinorVersion);
      }
    }
    else if (value == 'icipatch') {
      if(this.iciPatchVersion>0){
        this.iciPatchVersion -= 1;
        this.frmAddPackage.controls["icipatch"].setValue(this.iciPatchVersion);
      } else {
        this.iciPatchVersion = 0;
        this.frmAddPackage.controls["icipatch"].setValue(this.iciPatchVersion);
      }
    }
    else if (value == 'icibuild') {
      if(this.iciBuildVersion>0){
        this.iciBuildVersion -= 1;
        this.frmAddPackage.controls["icibuild"].setValue(this.iciBuildVersion);
      }else{
        this.iciBuildVersion = 0;
        this.frmAddPackage.controls["icibuild"].setValue(this.iciBuildVersion);
      }
    }
  }

  // Get data by Id
  async getDataById(id: any) {
    try {
      return await this.extensionService.getExtensionById(id).toPromise();
    }
    catch (execption) {
      console.log(execption);
    }
  }

  // Get data by Id
  patchExtDataById(id: any) {
    try {
      this.extensionService.getExtensionById(id).subscribe((result: any) => {
        if (result != undefined && result != null && result != "") {
          if (result.tags == null) {
            result.tags = [];
          }
          if (result.files == null) {
            result.files = [];
          }
          this.frmCreateExtension.patchValue({
            name: result.name,
            description: result.description,
            typeId: result.typeId,
            customerId: result.customerId,
            tags: result.tags,
            files: result.files,
            id: result.id,
            uniqueIdentifier: result.uniqueIdentifier
          })
          this.frmCreateExtension.controls["name"].disable();
          this.listofUploadedFiles = result.files;
          this.isPrevDisable = true
          this.isaddPackage = true
          this.extensionName = this.frmCreateExtension.get("name")?.value;
          this.mystepper.linear = false;
          this.mystepper.selectedIndex = 1;
          this.mystepper.linear = true;

        }
      })
    }
    catch (execption) {
      console.log(execption);
    }
  }

  // Get data by Id
  async getDataByExtId(id: any) {
    try {
      return await this.extensionService.getExtensionById(id).toPromise();
    }
    catch (execption) {
      console.log(execption);
    }
  }
}
