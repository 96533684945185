import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { RoleService } from 'src/app/core/services/role-management/role.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
import { MsalService } from '@azure/msal-angular';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import * as _ from 'lodash';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { RoleMgmtStaticString } from 'src/app/shared/enum/roleManagement';

export interface PeriodicElement {
  name: string;
  description: string;
  category: string;
  status: string;
  action: string;
}

export interface Role {
  id: number;
  name: string;
}

@Component({
  selector: 'app-role-management',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent {
  public RoleMgmtEnum = RoleMgmtStaticString;
  displayedColumns: string[] = ['name', 'description', 'category', 'status', 'action'];
  dataSource: any;
  roles: Role[] = [];
  isUserAssociate: boolean = false;
  isRoleEdit: boolean = false;
  isRoleActivate: boolean = false;
  isRoleDeactivate: boolean = false;
  globalList: any
  managePermissions: any;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('name') inputName: any;
  filteredRoleSearchList: any = {}
  filterText: string = "";
  filterArray: any = {};
  currentPage = 0;
  pageLength: any = 0;
  noContent: Boolean = false;
  constructor(
    private roleService: RoleService,
    private titleService: Title,
    private router: Router,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private sharedata: ShareDataService,
    private ApiCall: RoleService,
    private permssionService: PermissionsService,
  ) { }

  ngAfterViewInit() {
    this.inputName.nativeElement.value = this.filterText;
    this.cd.detectChanges();
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/role-management" },
    { label: "Role Management", path: "administration/role-management" },
    { label: "List", path: "administration/role-management" },
  ];


  ngOnInit(): void {
    localStorage.removeItem('roleId');
    this.paginator._intl.itemsPerPageLabel = 'Records per page';
    this.isRoleEdit = this.permssionService.getPermission('UpdateRole');
    this.isRoleActivate = this.permssionService.getPermission('ActivateRole');
    this.isRoleDeactivate = this.permssionService.getPermission('DeactivateRole');
    this.managePermissions = this.permssionService.getPermission('ManagePermission');
    this.titleService.setTitle('Role Management | IDN');
    this.GetRoles();
  }

  ngOnDestroy(): void {
    const url1: string = '/home/role-management/view';
    const url2: string = '/home/role-management/edit';
    if (window.location.pathname != url1 && window.location.pathname != url2) {
      localStorage.removeItem('RoleManagementFilter');
    }
  }
  //To Get Role Category Dropdown
  GetRoleCategory() {
    this.roleService.GetRoleCategory().subscribe((res: any) => {
      if (res) {
        this.roles = res;
      }
    })
  }

  //To View Role
  ViewRole(navLink: any, element: any) {
    let Json = {
      'search': this.inputName.nativeElement.value,
      'currentPage':this.currentPage
    };
    localStorage.setItem("RoleManagementFilter", JSON.stringify(Json));
    localStorage.setItem("roleId", element.id);
    this.sharedata.sendData(JSON.stringify(element));
    navLink = 'home/administration/' + navLink;
    this.router.navigate([navLink]);
  }

  //To Edit Role
  edit(navLink: any, element: any) {
    let Json = {
      'search': this.inputName.nativeElement.value,
      'currentPage':this.currentPage
    };
    localStorage.setItem("RoleManagementFilter", JSON.stringify(Json));
    localStorage.setItem("roleId", element.id);
    navLink = 'home/administration/' + navLink;
    this.router.navigate([navLink]);
  }

  //View permissions
  viewPermissions(navLink: any, element: any) {
    if(localStorage.hasOwnProperty('permissions'))
    {
      localStorage.removeItem('permissions');
    }
    let data: any = { id: element.id, roleName: element.name }
    localStorage.setItem("permissions", JSON.stringify(data))
    navLink = 'home/administration/' + navLink;
    this.router.navigate([navLink]);
  }

  //To Get Role list
  GetRoles() {
    let filterData: any = (localStorage.getItem("RoleManagementFilter") == null) ? '' : localStorage.getItem("RoleManagementFilter");
    this.roleService.GetRoles().subscribe((resp: any) => {
      if (resp.length > 0) {
        this.noContent = false;   
        let listSuperAdminAndNonIdnUserFiltered=resp.filter((item:any)=>{return (item.name!=RoleMgmtStaticString.role_superadmin && item.name!=RoleMgmtStaticString.role_non_idn_user)});
        let reverse = listSuperAdminAndNonIdnUserFiltered.reverse();
        this.globalList = reverse;
        if(filterData != undefined && filterData != ''){
          this.inputName.nativeElement.value = JSON.parse(filterData).search
          this.currentPage = JSON.parse(filterData).currentPage
          this.doFilter('search');
        }
        else{
          this.dataSource = new MatTableDataSource(reverse);
          this.dataSource.paginator = this.paginator;
          this.paginator.pageIndex = this.currentPage;
          this.empTbSort.disableClear = true;
          this.dataSource.sort = this.empTbSort;
          this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => data[sortHeaderId].toLocaleLowerCase();
        }
      } else{
        this.noContent = true
      }
    });
  }

  //To Redirect dashboard page
  navigateItem(navLink: any): void {
    if (navLink == 'dashboard') {
      navLink = 'dashboard';
    } else {
      navLink = 'home/administration/' + navLink;
    }
    this.router.navigate([navLink]);
  }

  //To check User Associate With Role
  async checkUserAssociateWithRole(id: number): Promise<any> {
    if (id != null && id != undefined && id > 0) {
      return await this.ApiCall.RoleUserCheck(id).toPromise();
    }
    else {
      this.router.navigate(["home/administration/role-management"]);
    }
  }

  //To In-active Role Status
  async DeactivateRole(id: number) {
    let Json = {
      'search': this.inputName.nativeElement.value,
    };
    localStorage.setItem("RoleManagementFilter", JSON.stringify(Json));
    this.isUserAssociate = await this.checkUserAssociateWithRole(id);
    if (this.isUserAssociate) {
      this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_not_deactivate_body, 'error', this.RoleMgmtEnum.role_notify_not_deactivate_heading);
      this.ngOnInit();
    }
    else {
      this.dialogService.confirmDialog({
        title: this.RoleMgmtEnum.role_dialog_deactivate_heading,
        module: 'role',
        message: this.RoleMgmtEnum.role_dialog_deactivate_msg,
        confirmText: this.RoleMgmtEnum.role_dialog_deactivate_primary_btn,
        cancelText: this.RoleMgmtEnum.role_dialog_cancel_btn
      }).subscribe(res => {
        if (res) {
          this.roleService.deActivateRole(id, false).subscribe((res: Response) => {
            let Result: any = res.body;
            if (res.status == 200) {
              this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_deactivate_body, 'success', this.RoleMgmtEnum.role_notify_deactivate_heading);
            } else {
              this.notificationService.showNotification(Result.message, 'error', this.RoleMgmtEnum.role_notify_not_deactivate_heading);
            }
            this.GetRoles();
          })
        }
      })
    }
  }

  //To Activate Role Status
  ActivateRole(id: number) {
    let Json = {
      'search': this.inputName.nativeElement.value,
    };
    localStorage.setItem("RoleManagementFilter", JSON.stringify(Json));
    this.dialogService.confirmDialog({
      title: this.RoleMgmtEnum.role_dialog_activate_heading,
      module: 'role',
      message: this.RoleMgmtEnum.role_dialog_activate_msg,
      confirmText: this.RoleMgmtEnum.role_dialog_activate_primary_btn,
      cancelText: this.RoleMgmtEnum.role_dialog_cancel_btn
    }).subscribe((res: any) => {
      if (res) {
        this.roleService.activateRole(id, true).subscribe((res: Response) => {
          let Result: any = res.body;
          if (res.status == 200) {
            this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_activate_body, 'success', this.RoleMgmtEnum.role_notify_activate_heading);
          } else {
            this.notificationService.showNotification(Result.message, 'warning', this.RoleMgmtEnum.role_notify_not_activate_heading);
          }
          this.GetRoles();
        })
      }
    })
  }

  ClearInput() {
    localStorage.removeItem("RoleManagementFilter");
    this.inputName.nativeElement.value = '';
    this.currentPage = 0;
    this.GetRoles();
  }
  clearFilter() {
    localStorage.removeItem("RoleManagementFilter");
    this.inputName.nativeElement.value = '';
    this.filterText = "";
    this.currentPage = 0;
    this.GetRoles();
  }
  public doFilter = (name: string) => {
    switch (name) {
      case 'search':
        let filterValues = this.inputName.nativeElement.value;
        let filtereddatas = this.globalList.filter((item: any) => {
          return item.name.toLowerCase().includes(filterValues.toLowerCase()) ||
            item.description.toLowerCase().includes(filterValues.toLowerCase())
        })
        if(filtereddatas.length > 0){
          this.noContent = false
        this.filteredRoleSearchList = filtereddatas;
        this.dataSource = new MatTableDataSource(filtereddatas)
        this.dataSource.paginator = this.paginator;
        this.paginator.pageIndex = this.currentPage;
        this.dataSource.sort = this.empTbSort;
        this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: any) => data[sortHeaderId].toLocaleLowerCase();
        }else{
          this.noContent = true
        }
        break;
      default:
        break;
    }
  }

  pageChanged(event: PageEvent) {
    this.currentPage = event.pageIndex
  }
}
