import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { UserService } from 'src/app/core/services/user-management/user.service';

@Component({
  selector: 'app-idn-dropdown',
  templateUrl: './idn-dropdown.component.html',
  styleUrls: ['./idn-dropdown.component.scss']
})
export class IdnDropdownComponent implements OnInit{
  userCategories:any = [];
  selectedUserCategory:any;
  @ViewChild ('customer') usercategory !: MatSelect;
  @Output() selectedData = new EventEmitter<any>();
  @Input() moduleName :any;
  partnerAccess:any;
  storeData:any;
  constructor(
    private userService: UserService,
    private permissionService: PermissionsService,
  ){}
ngOnInit(): void {
  this.partnerAccess = this.permissionService.hasGlobalDataAccess();
  if(this.moduleName == 'user-management'){
    let data:any = localStorage.getItem('userFilterData');
    this.storeData = JSON.parse(data)
    if(this.storeData){
      this.selectedUserCategory = this.storeData?.userCategory;
    }
  }
  if(this.partnerAccess){
    this.getUserCategories();
  }
}

getUserCategories() {
  try {
    this.userService.GetUserCategories().subscribe((res: any) => {
      if (res != undefined && res != null && res != '') {
        this.userCategories = res;
      }
    })
  }
  catch (e) {
    console.log('Exception :', e)
  }
}

onUserCategoryChange(event:any){
  this.selectedUserCategory = event.value;
  this.selectedData.emit (this.selectedUserCategory)
}
}
