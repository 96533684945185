import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { Deployment } from 'src/app/shared/enum/deployment';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { environment } from 'src/environments/environment';
import { DatePipe, Location } from '@angular/common';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';

@Component({
  selector: 'app-initiate-deployment',
  templateUrl: './initiate-deployment.component.html',
  styleUrls: ['./initiate-deployment.component.scss'],
})
export class InitiateDeploymentComponent implements OnInit {
  @ViewChild('stepper', { static: false }) mystepper!: MatStepper;
  @ViewChild('firstStep') firstStep!: MatStep;
  breadCrumbItems: any;
  isViewInstance: boolean = false;
  isApproveInstance: boolean = false;
  isRejectInstance: boolean = false;
  isViewDeployment: boolean = false;
  isInitiateDeployment: boolean = false;
  isAddInstance: boolean = false;
  isEditInstance: boolean = false;
  isSubmitInstance: boolean = false;
  ViewData: any = [];
  public instanceEnum = InstanceManagement;
  public deploymentEnum = Deployment;
  deploymentCount: number = 0;
  deployments: any = [];
  showSolutionPackage: any = false;
  extensionType: any = [];
  extensionName: any = [];
  solutionPackages: any = [];
  multiTenantPodMajor: number = 0;
  multiTenantPodMinor: number = 0;
  multiTenantPodPatch: number = 0;
  customerId: number = 0;
  instanceTypeId: number = 0;
  skuList: any = [];
  isDeploymentInvalid: boolean = true;
  isAddtoDeployment: boolean = true;
  skuData: any = {};
  deploymentIndex: number = 0;
  instanceId: any;
  packageList: any = {
    instanceRequestId: 0,
    instanceUniqueIdentifier: '',
    items: [],
  };
  instanceUniqueIdentifier: any;
  editedFinalData: any = [];
  columnFilterCtrlExtensionName = new FormControl('');
  selectExtensionName:any = [];
  columnFilterCtrlSolutionPackageName  = new FormControl('');
  selectSolutionPackageName: any = [];
  //config key
  isConfigDrawer: boolean = false;
  isTenantConfig: boolean = true;
  noContent = true;
  dataSource: any;
  invalidData = false;
  validateError = '';
  editMode = false;
  displayedColumns: string[] = [
    'Name',
    'Value',
    'ValueType',
    'OverrideType',
    'DefaultValue',
    'IsEncrypted',
    'Action',
  ];
  displayedColumnsPreview: string[] = [
    'Name',
    'Value',
    'ValueType',
    'OverrideType',
    'DefaultValue',
    'IsEncrypted',
  ];
  editableFeilds :any;
  editData: any;
  initialConfigData: any;
  editedData: any = [];
  dataSource1: any;
  finalDataFormat: any = {
    Messages: null,
    Data: [
      {
        Tenants: [
          {
            TenantPropertyGroups: [
              {
                Properties: [],
                TypeName: 'CustomerType',
                Context: '',
                Path: '.CustomerTypePrimary.Customer',
                Type: 'Custom',
                Name: 'Customer',
              },
            ],
          },
        ],
      },
    ],
  };

  overrideTooltip: any = "Merge: This indicates that the value entered here will be appended to the existing value of the key. Override: This indicates that the value entered here will replace the existing value of the key"
    

  frmRequestDeployment: FormGroup = new FormGroup({
    instanceId: new FormControl(0),
    extensionTypeId: new FormControl(null, Validators.required),
    solutionPackageId: new FormControl(null, Validators.required),
    extensionId: new FormControl(null, Validators.required),
    packageSourceId: new FormControl(0, Validators.required),
    skuId: new FormControl(null, Validators.required),
  });

  firstStepControl = new FormControl('');
  secondStepControl = new FormControl('');
  thirdStepControl = new FormControl('');
  selectedSolutionPackage: any;
  initialConfigFile: any;
  deploymentRequiredBy: any = null;
  userCategory: any;

  constructor(
    private permissionService: PermissionsService,
    private instanceService: InstanceService,
    private extensionService: ExtensionService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: DialogService,
    private location: Location,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.permissionService.GetIDNConfiguration().subscribe(resp =>{
      this.editableFeilds = resp.body.content.configFileEditableFields;
    });
    let instanceLabel: string;
    let instancePath: string;
    this.isViewInstance = this.permissionService.getPermission('ViewInstance');
    this.isApproveInstance =
      this.permissionService.getPermission('ApproveInstance');
    this.isRejectInstance =
      this.permissionService.getPermission('RejectInstance');
    this.isViewDeployment =
      this.permissionService.getPermission('ViewDeployment');
    this.isInitiateDeployment =
      this.permissionService.getPermission('InitiateDeployment');
    this.isAddInstance = this.permissionService.getPermission('AddInstance');
    this.isEditInstance = this.permissionService.getPermission('EditInstance');
    this.isSubmitInstance =
      this.permissionService.getPermission('SubmitInstance');
    if (this.isApproveInstance) {
      instanceLabel = 'Instance Requests';
      instancePath = 'instanceoperations/instance-requests';
    } else {
      instanceLabel = 'Instance Requests';
      instancePath = 'instanceoperations/instance-requests';
    }
    this.userCategory=this.permissionService.getUsercategoryId(); 
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: instancePath },
      { label: instanceLabel, path: instancePath },
      { label: 'Initiate Deployment', path: 'initiate-deployment' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    if (this.isDeploymentInvalid) {
      this.firstStepControl.setErrors({ required: true });
      this.secondStepControl.setErrors({ required: true });
    }

    this.validateFormInputChange();
    this.columnFilterCtrlExtensionName.valueChanges.subscribe((value:any) => {
      this.columnFilterExtensionName(value);
    });

    this.columnFilterCtrlSolutionPackageName.valueChanges.subscribe((value:any) => {
      this.columnFilterSolutionPackageName(value);
    });
  }

  //Get all the instances
  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.multiTenantPodMajor = this.ViewData.multiTenantPod_Major;
            this.multiTenantPodMinor = this.ViewData.multiTenantPod_Minor;
            this.customerId = this.ViewData.customerId;
            this.instanceTypeId = this.ViewData.instanceTypeId;
            this.multiTenantPodPatch = this.ViewData.multiTenantPod_Patch;
            this.packageList.instanceUniqueIdentifier =
              this.ViewData.uniqueIdentifier;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  columnFilterExtensionName(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.selectExtensionName = this.extensionName.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase),
    );
  }

  columnFilterSolutionPackageName(value: any){
    const valueLowerCase = value.toLowerCase();
    this.selectSolutionPackageName = this.solutionPackages.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase),
    );
  }
  removeDeploymentPackage(item: number): any {
    if (this.editedData.length) {
      this.dialogService
        .confirmDialog({
          title: this.instanceEnum.instance_initiate_deployment_warning_header,
          module: 'deployment_package',
          message:
            '<h3>Updating packages for deployment may result in <br> losing the modified tenant config data.</h3>' +
            '<p> Are you sure you want to continue?</p>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if (res) {
            this.editedData = [];
            if (this.deployments.length) {
              this.deployments = this.deployments.filter((el: any) => {
                return !(item === el.id);
              });
              this.packageList.items = this.packageList.items.filter(
                (data: any) => {
                  return !(item === data.id);
                }
              );
              this.deploymentCount = this.deployments.length;
              if (this.deploymentCount == 0) {
                this.mystepper.reset();
                this.firstStepControl.setErrors({ required: true });
                this.isDeploymentInvalid = true;
                this.packageList.items = [];
              } else {
                this.secondStepControl.setErrors({ required: true });
                this.onPrevious();
              }
            }
          }
        });
    } else {
      if (this.deployments.length) {
        this.deployments = this.deployments.filter((el: any) => {
          return !(item === el.id);
        });
        this.packageList.items = this.packageList.items.filter((data: any) => {
          return !(item === data.id);
        });
        this.deploymentCount = this.deployments.length;
        if (this.deploymentCount == 0) {
          this.mystepper.reset();
          this.firstStepControl.setErrors({ required: true });

          this.isDeploymentInvalid = true;
          this.packageList.items = [];
          this.editedData = [];
        } else {
          this.thirdStepControl.setErrors({ required: true });
        }
      }
    }
  }

  validateDeployment(eventType: any) {
      if (eventType) {
        this.isAddtoDeployment = false;
      } else {
        this.isAddtoDeployment = true;
      }

      if (this.deployments) {
        if (this.deployments.length > 0) {
          this.isDeploymentInvalid = false;
        }
      }
  }

  validateDeploymentType(eventType: any) {
    let perviousEvent = eventType;
      if (this.deployments) {
        if (this.deployments.length > 0) {
          this.isDeploymentInvalid = false;
          if (eventType != false) {
            if (perviousEvent != eventType && this.deployments.length) {
              this.isAddtoDeployment = false;
            } else {
              this.isAddtoDeployment = true;
            }
          }
        } else {
          this.isDeploymentInvalid = true;
        }
      }
  }

  getDeploymentType(eventType: any) {
    if (eventType == 1) {
      this.showSolutionPackage = false;
      this.frmRequestDeployment.controls['skuId'].reset();
      this.frmRequestDeployment.get('skuId')?.clearValidators();
      this.frmRequestDeployment.get('skuId')?.updateValueAndValidity();
      this.frmRequestDeployment.get('extensionTypeId')?.addValidators;
      this.frmRequestDeployment
        .get('extensionTypeId')
        ?.updateValueAndValidity();
      this.frmRequestDeployment.get('solutionPackageId')?.addValidators;
      this.frmRequestDeployment
        .get('solutionPackageId')
        ?.updateValueAndValidity();
      this.frmRequestDeployment.get('extensionId')?.addValidators;
      this.frmRequestDeployment.get('extensionId')?.updateValueAndValidity();
      this.frmRequestDeployment.get('extensionId')?.enable();
      this.getDeploymentReferenceData();
    } else {
      this.frmRequestDeployment.controls['extensionTypeId'].reset();
      this.frmRequestDeployment.controls['extensionId'].reset();
      this.frmRequestDeployment.controls['solutionPackageId'].reset();
      this.showSolutionPackage = true;
      this.extensionType = [];
      this.extensionName = [];
      this.selectExtensionName = this.extensionName;
      this.solutionPackages = [];
      this.selectSolutionPackageName = this.solutionPackages;
      this.frmRequestDeployment.get('extensionTypeId')?.clearValidators();
      this.frmRequestDeployment
        .get('extensionTypeId')
        ?.updateValueAndValidity();
      this.frmRequestDeployment.get('solutionPackageId')?.clearValidators();
      this.frmRequestDeployment
        .get('solutionPackageId')
        ?.updateValueAndValidity();
      this.frmRequestDeployment.get('extensionId')?.clearValidators();
      this.frmRequestDeployment.get('extensionId')?.updateValueAndValidity();

      this.frmRequestDeployment.get('skuId')?.addValidators;
      this.frmRequestDeployment.get('skuId')?.updateValueAndValidity();
      this.getSkuByCustomerId();
    }
  }

  getDeploymentReferenceData() {
    try {
      this.extensionService.getExtensionTypes(this.userCategory).subscribe((result: any) => {
        if (result != undefined && result != "" && result != null) {
          this.extensionType = result;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  getSkuByCustomerId() {
    try {
      if (this.multiTenantPodMajor == null) {
        this.multiTenantPodMajor = 0;
      }
      if (this.multiTenantPodMinor == null) {
        this.multiTenantPodMinor = 0;
      }
      this.instanceService
        .getSkuByCustomerID(
          this.customerId,
          this.multiTenantPodMajor,
          this.multiTenantPodMinor
        )
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            if (resp.body == null) {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_skuNotFound_body,
                'warning',
                this.instanceEnum.instance_notify_skuNotFound_heading
              );
              return;
            } else {
              if (resp.body.length > 0) {
                this.skuList = resp.body;
              } else {
                this.skuList = resp.body;
                this.notificationService.showNotification(
                  this.instanceEnum.instance_notify_skuNotFound_body,
                  'warning',
                  this.instanceEnum.instance_notify_skuNotFound_heading
                );
              }
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getSkuData(skuID: any) {
    if (skuID && this.skuList.length != 0) {
      let currSku = this.skuList.filter((sku: any) => {
        if (sku.id === skuID) {
          return skuID;
        }
      });
      this.skuData = currSku[0];
      this.getDeploymentReferenceData();
    }
  }

  getExtensionName(event: any) {
    try {
      this.selectExtensionName=[];
      this.instanceService
        .getExtensionName(this.customerId, event.value)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            if (resp.body.length > 0) {
              this.extensionName = resp.body;
              this.selectExtensionName = this.extensionName;
              if(event.value == 2){               
                this.frmRequestDeployment.get('extensionId')?.disable();
                this.frmRequestDeployment.get('extensionId')?.setValue(this.extensionName[0]?.id);
                this.getSolutionPackageById(this.extensionName[0]?.id)
              } else {
                this.frmRequestDeployment.get('extensionId')?.enable();
              }
            } else {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_extensionNotAvailable_body,
                'warning',
                this.instanceEnum.instance_notify_extensionNotAvailable_heading
              );
              return;
            }
          } else {
            this.notificationService.showNotification(
              this.instanceEnum.instance_notify_extensionNotAvailable_body,
              'warning',
              this.instanceEnum.instance_notify_extensionNotAvailable_heading
            );
            return;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getSolutionPackageById(extID: any) {
    try {
      if (this.multiTenantPodMajor == null) {
        this.multiTenantPodMajor = 0;
      }
      if (this.multiTenantPodMinor == null) {
        this.multiTenantPodMinor = 0;
      }
      if (this.multiTenantPodPatch == null) {
        this.multiTenantPodPatch = 0;
      }
      this.instanceService
        .getSolutionPackageById(
          extID,
          this.instanceTypeId,
          this.multiTenantPodMajor,
          this.multiTenantPodMinor,
          this.multiTenantPodPatch
        )
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            let res = resp.body;
            if (res == null) {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_solutionPackageNotAvail_body,
                'warning',
                this.instanceEnum
                  .instance_notify_solutionPackageNotAvail_heading
              );
              return;
            } else {
              if (res.length > 0) {
                this.solutionPackages = res;
                this.selectSolutionPackageName = this.solutionPackages;
              } else {
                this.solutionPackages = [];
                this.selectSolutionPackageName = this.solutionPackages;
                this.frmRequestDeployment.controls[
                  'solutionPackageId'
                ].setValue(null);
                this.notificationService.showNotification(
                  this.instanceEnum
                    .instance_notify_solutionPackageNotAvail_body,
                  'warning',
                  this.instanceEnum
                    .instance_notify_solutionPackageNotAvail_heading
                );
                return;
              }
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  packageExists(checkExtensionId: any): boolean {
    if (this.deployments.length) {
      return this.deployments.some(function (el: any) {
        return el.extensionId == checkExtensionId;
      });
    }
    return false;
  }

  skuPackageExists(id: any): boolean {
    if (this.deployments.length) {
      return this.deployments.some(function (el: any) {
        return el.skuId == id;
      });
    }
    return false;
  }

  getSelectedExtensionTypeName(extTypeId: any): string {
    if (extTypeId && this.extensionType.length != 0) {
      let currExtType = this.extensionType.filter((ext: any) => {
        if (ext.id === extTypeId) {
          return ext;
        }
      });
      let currExtTypeName = currExtType[0].name;
      return currExtTypeName;
    }
    return '';
  }

  getSelectedExtensionName(extId: any): string {
    if (extId && this.extensionName.length != 0) {
      let currExt = this.extensionName.filter((ext: any) => {
        if (ext.id === extId) {
          return ext;
        }
      });
      let currExtName = currExt[0].name;
      return currExtName;
    }
    return '';
  }

  getSelectedSolutionPackageName(solId: any): string {
    if (solId && this.solutionPackages.length != 0) {
      let currSol = this.solutionPackages.filter((sol: any) => {
        if (sol.id === solId) {
          return sol;
        }
       
      });
      this.selectedSolutionPackage = currSol[0];
      let currSolName = this.selectedSolutionPackage.name;
      return currSolName;
    }
    return '';
  }

  getSelectedDeployment(pacId: any): string {
    if (pacId) {
      let deploymentName =
        pacId === 1 ? 'Customer Extension Package' : 'SKU Deployment';
      return deploymentName;
    }
    return '';
  }

  pushDeployment(): void {
    if (this.frmRequestDeployment.get('packageSourceId')?.value == 1) {
      this.deployments.push({
        id: this.deploymentIndex,
        deploymentRequestId: 0,
        extensionTypeId:
          this.frmRequestDeployment.get('extensionTypeId')?.value,
        extensionType: this.getSelectedExtensionTypeName(
          this.frmRequestDeployment.get('extensionTypeId')?.value
        ),
        extensionId: this.frmRequestDeployment.get('extensionId')?.value,
        extensionName: this.getSelectedExtensionName(
          this.frmRequestDeployment.get('extensionId')?.value
        ),
        solutionPackageId:
          this.frmRequestDeployment.get('solutionPackageId')?.value,
        solutionPackage: this.getSelectedSolutionPackageName(
          this.frmRequestDeployment.get('solutionPackageId')?.value
        ),
        skuId: this.frmRequestDeployment.get('skuId')?.value,
        packageSource: this.frmRequestDeployment.get('packageSourceId')?.value,
        deploymentFor: this.getSelectedDeployment(
          this.frmRequestDeployment.get('packageSourceId')?.value
        ),
      });

      this.packageList.items.push({
        id: this.deploymentIndex,
        extensionName: this.getSelectedExtensionName(
          this.frmRequestDeployment.get('extensionId')?.value
        ),
        path: '',
        binaryFileName: this.selectedSolutionPackage.binaryFileName,
        uniqueIdentifier: this.selectedSolutionPackage.uniqueIdentifier,
      });
    } else {
      this.deployments.push({
        id: this.deploymentIndex,
        deploymentRequestId: 0,
        extensionTypeId: this.skuData.extensionTypeId,
        extensionType: this.getSelectedExtensionTypeName(
          this.skuData.extensionTypeId
        ),
        extensionId: this.skuData.extensionId,
        extensionName: this.skuData.extensionName,
        solutionPackageId: this.skuData.solutionPackageId,
        solutionPackage: this.skuData.packageName,
        skuId: this.frmRequestDeployment.get('skuId')?.value,
        productName: this.skuData.productName,
        packageSource: this.frmRequestDeployment.get('packageSourceId')?.value,
        deploymentFor: this.getSelectedDeployment(
          this.frmRequestDeployment.get('packageSourceId')?.value
        ),
      });
      this.packageList.items.push({
        id: this.deploymentIndex,
        extensionName: this.skuData.extensionName,
        path: '',
        binaryFileName: this.skuData.binaryFileName,
        uniqueIdentifier: this.skuData.uniqueIdentifier,
      });
    }
  }

  addPackage() {
    if (
      (this.frmRequestDeployment.get('solutionPackageId')?.value != null &&
        !this.packageExists(
          this.frmRequestDeployment.get('extensionId')?.value
        )) ||
      (this.frmRequestDeployment.get('skuId')?.value != null &&
        !this.skuPackageExists(this.frmRequestDeployment.get('skuId')?.value) &&
        !this.packageExists(this.skuData.extensionId))
    ) {
      this.pushDeployment();
      this.deploymentIndex += 1;
      this.deploymentCount = this.deployments.length;
      this.isAddtoDeployment = true;
      this.firstStepControl.setErrors({ required: true });
      this.secondStepControl.setErrors({ required: true });
      this.thirdStepControl.setErrors({ required: true });
      if (this.editedData.length > 0) {
        this.editedData = [];
      }
    } else {
      this.notificationService.showNotification(
        this.instanceEnum.instance_notify_duplicate_body,
        'warning',
        this.instanceEnum.instance_notify_duplicate_heading
      );
    }
    this.frmRequestDeployment.reset();
    this.frmRequestDeployment.get('extensionId')?.enable();
    this.extensionType = [];
    this.extensionName = [];
    this.solutionPackages = [];
    this.selectSolutionPackageName = this.solutionPackages;
    this.skuList = [];
  }

  addDeploymentPackage(): void {
    if (this.editedData.length) {
      this.dialogService
        .confirmDialog({
          title: this.instanceEnum.instance_initiate_deployment_warning_header,
          module: 'deployment_package',
          message:
            '<h3>Updating packages for deployment may result in <br> losing the modified tenant config data.</h3>' +
            '<p> Are you sure you want to continue?</p>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if (res) {
            this.addPackage();
          }
          this.frmRequestDeployment.reset();
          this.extensionType = [];
          this.extensionName = [];
          this.solutionPackages = [];
          this.selectSolutionPackageName = this.solutionPackages;
          this.skuList = [];
        });
    } else {
      this.addPackage();
    }
  }

  onCancel() {
    if (this.deploymentCount > 0) {
      this.dialogService
        .confirmDialog({
          title: this.instanceEnum.instance_initiate_deployment_wait_header,
          module: 'deployment_wait',
          message:
            '<h3>You are about to cancel deployment request.</h3>' +
            '<p> Are you sure you want to continue?</p>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if (res) {
            this.location.back();
          }
        });
    } else {
      this.location.back();
    }
  }


  onNext() {
    this.frmRequestDeployment.markAsUntouched();
    this.firstStepControl.reset();
    this.secondStepControl.setErrors({ required: true });
    this.mystepper.next();
    if (this.editedData.length) {
      this.dataSource = new MatTableDataSource<any>(this.initialConfigData);
    } else {
      this.getConfigData();
    }
  }

  getConfigData() {
    try {
      this.packageList.instanceRequestId = Number(this.instanceId),
      this.instanceService
        .MergeTenantConfigkeys(this.packageList)
        .subscribe((data: any) => {
          data = data.body;
          if (data.Result) {
            this.dataSource = [];
            this.noContent = true;
            this.isTenantConfig = false;
          } else if (
            data.Data[0].Tenants[0].TenantPropertyGroups[0].Properties
          ) {
            this.initialConfigFile = data;
            this.initialConfigData =
              data.Data[0].Tenants[0].TenantPropertyGroups[0].Properties.map(
                (property: any) => {
                  let isEditable = false;
                  if (property.ValueType.includes('System.Boolean')) {
                    property.ValueType = 'System.Boolean';
                  } else if (property.ValueType.includes('System.String')) {
                    property.ValueType = 'System.String';
                  } else if (property.ValueType.includes('System.Int32')) {
                    property.ValueType = 'System.Int32';
                  } else if (property.ValueType.includes('System.Int64')) {
                    property.ValueType = 'System.Int64';
                  } else if (property.ValueType.includes('System.Json')) {
                    property.ValueType = 'System.Json';
                  }
                  return { ...property, isEditable };
                }
              );
            this.dataSource = new MatTableDataSource<any>(
              this.initialConfigData
            );
            this.editedData = this.initialConfigData.filter((data: any) => {
              let item = Object.assign({}, data);
              return item.isModified == true;
            });
            this.noContent = false;
            this.isTenantConfig = true;
          } else {
            this.dataSource = [];
            this.noContent = true;
            this.notificationService.showNotification(
              this.instanceEnum.instance_notify_invalidConfig_body,
              'error',
              this.instanceEnum.instance_notify_invalidConfig_heading
            );
            this.isTenantConfig = true;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }



  edit(element: any) {
    this.editData = element;
    this.isConfigDrawer = true;
    element.isEditable = true;
    this.editMode = true;
  }

  closeConfigdrawer(event: any) {
    this.isConfigDrawer = false;

    this.editedData = this.initialConfigData.map((data: any) => {
      return data.Name == event.Name ? event : data;
    });
    this.dataSource = new MatTableDataSource<any>(this.initialConfigData);
  }

  onPrevious() {
    if (this.editedData.length == 0) {
      this.getConfigData();
      this.dataSource1 = [];
    }
    this.selectExtensionName=[];
    this.selectSolutionPackageName =[];
    this.extensionType =[];
    this.skuList =[];
    this.clearDeploymentForm();
    this.mystepper.previous();
  }

  clearDeploymentForm(){
    this.frmRequestDeployment.get('extensionTypeId')?.clearValidators();
    this.frmRequestDeployment.get('solutionPackageId')?.clearValidators();
    this.frmRequestDeployment.get('extensionId')?.clearValidators();
    this.frmRequestDeployment.get('packageSourceId')?.clearValidators();
    this.frmRequestDeployment.get('skuId')?.clearValidators();

    this.frmRequestDeployment.get('extensionTypeId')?.updateValueAndValidity();
    this.frmRequestDeployment.get('solutionPackageId')?.updateValueAndValidity();
    this.frmRequestDeployment.get('extensionId')?.updateValueAndValidity();
    this.frmRequestDeployment.get('packageSourceId')?.updateValueAndValidity();
    this.frmRequestDeployment.get('skuId')?.updateValueAndValidity();

    this.frmRequestDeployment.get('extensionTypeId')?.addValidators
    this.frmRequestDeployment.get('solutionPackageId')?.addValidators
    this.frmRequestDeployment.get('extensionId')?.addValidators
    this.frmRequestDeployment.get('packageSourceId')?.addValidators
    this.frmRequestDeployment.get('skuId')?.addValidators

    this.frmRequestDeployment.get('extensionTypeId')?.setValue(null);
    this.frmRequestDeployment.get('solutionPackageId')?.setValue(null);
    this.frmRequestDeployment.get('extensionId')?.setValue(null);
    this.frmRequestDeployment.get('packageSourceId')?.setValue(null);
    this.frmRequestDeployment.get('skuId')?.setValue(null);
    this.frmRequestDeployment.get('extensionId')?.enable();
  }

  onNextPreview() {
    this.secondStepControl.reset();
    this.mystepper.next();
    if (this.editedData.length) {
      this.dataSource1 = new MatTableDataSource<any>(this.initialConfigData);
      this.editedFinalData = this.editedData.filter((data: any) => {
        return data.isModified == true;
      });
      this.editedFinalData = this.editedFinalData.map((data: any) => {
        let property = Object.assign({}, data);
        delete property.isModified;
        delete property.isEditable;
        return property;
      });
      this.finalDataFormat.Data[0].Tenants[0].TenantPropertyGroups[0].Properties =
        this.editedFinalData;
    }
  }

  initiateDeployment() {
    try {
        if (this.deployments == 0) {
          this.notificationService.showNotification(
            this.instanceEnum
              .instance_notify_RequiredField_deploymentNotInitiated_body,
            'warning',
            this.instanceEnum
              .instance_notify_RequiredField_deploymentNotInitiated_heading
          );
          return;
        }
      let reqJson: any = null;
      let finalJsonStringData;
      finalJsonStringData = JSON.stringify(this.finalDataFormat, null, 2);
      reqJson = {
        instanceId: this.instanceId,
        deploymentPackageDTOs: this.deployments,
        isApprovalRequired: (this.ViewData.instanceType == this.instanceEnum.instance_constant_production),
        requiredDate: this.deploymentRequiredBy,
        instanceModifiedConfigJson: this.editedFinalData.length ? finalJsonStringData : ''
      };
      this.deploy(reqJson);
    } catch (error) {
      console.log(error);
    }
  }

  deploy(reqJson: any) {
    try {
      this.instanceService
        .requestForDeployment(reqJson)
        .subscribe((resp: any) => {
          if(this.ViewData.instanceType == this.instanceEnum.instance_constant_production){
            if (resp.status == 200) {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_deployment_requested_body,
                'success',
                this.instanceEnum.instance_notify_deployment_requested_heading
              );
              this.router.navigate(['home/instanceoperations/instance-requests']);
            } else {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_deployment_request_failed_body,
                'error',
                this.instanceEnum.instance_notify_deployment_request_failed_heading,
              );
              this.location.back();
            }
          } else{
            if (resp.status == 200) {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_deploymentInitiated_body,
                'success',
                this.instanceEnum.instance_notify_deploymentInitiated_heading
              );
              this.router.navigate(['home/instanceoperations/instance-requests']);
            } else {
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_deploymentNotInitiated_body,
                'error',
                this.instanceEnum.instance_notify_deploymentNotInitiated_heading
              );
              this.location.back();
            }
          }          
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_List_initiateDeployment_header)
        });
    } catch (error) {
      console.log(error);
    }
  }

  validateFormInputChange() {
    this.frmRequestDeployment
      .get('extensionTypeId')
      ?.valueChanges.subscribe((selectedValue) => {
        if (selectedValue != null) {
          this.frmRequestDeployment.controls['extensionId'].reset();
          this.frmRequestDeployment.controls['solutionPackageId'].reset();
          this.isAddtoDeployment = true;
        }
      });
    this.frmRequestDeployment
      .get('extensionId')
      ?.valueChanges.subscribe((selectedValue) => {
        if (selectedValue != null) {
          this.frmRequestDeployment.controls['solutionPackageId'].reset();
          this.isAddtoDeployment = true;
        }
      });
  }

  requestDeployment(){
    this.dialogService
        .confirmDialogComments({
          title: "Confirm",
          module: 'request-deployment',
          message: '',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if(res){
            this.deploymentRequiredBy = this.transformDate(res);
            this.initiateDeployment()
          }
        })
  }

  transformDate(selectedDate: any) {
    return this.datePipe.transform((selectedDate.toISOString()), 'YYYY-MM-ddTHH:mm:ss');
  }
}
