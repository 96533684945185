<mat-drawer-container class="idn-drawer-internal">
  <mat-drawer #drawerConfigView class="idn-drawer" mode="over">
    <div class="header">
      <div class="heading">
        <mat-icon>verified_user</mat-icon>
        <h2>Certification Request</h2>
      </div>
      <button id="closeBtn" mat-button="closebtn" (click)="closeDrawer()" aria-label="close Button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="body pt-1">
      <div class="page-container">
        <div class="idn-view-wrapper">
          <div class="idn-view-body">
            <div class="idn-info">
              <div class="idn-config-info">
                <mat-icon>info</mat-icon><span>Please enter the required details to request certification.</span>
              </div>
              <form [formGroup]="frmRequestCertification">

                <mat-form-field class="cert-req-field">
                  <mat-label>Customer name</mat-label>
                  <mat-select matNativeControl disableOptionCentering required
                    (selectionChange)="bindExtensionDropDown()" formControlName="customerId">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search Customer Name"
                          noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlCustomerName">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let custName of selectCustomerName" [value]="custName.id">
                      {{custName.customerName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="cert-req-field">
                  <mat-label>Extension Type</mat-label>
                  <mat-select matNativeControl disableOptionCentering required formControlName="extensionTypeId"
                    required (selectionChange)="getExtensionName($event.value)">
                    <mat-option *ngFor="let type of ExtentionTypes" [value]="type.id">
                      {{type.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="cert-req-field">
                  <mat-label>Extension name</mat-label>
                  <mat-select matNativeControl disableOptionCentering required
                    (selectionChange)="getExtensionPackageList($event.value)" formControlName="extensionId">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search Extension Name"
                          noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlExtensionName">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let extn of selectExtensionName" [value]="extn.id">
                      {{extn.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field class="cert-req-field">
                  <mat-label>Customer extension package</mat-label>
                  <mat-select matNativeControl disableOptionCentering required formControlName="solutionPackageId"
                    (selectionChange)="getFormData($event.value)">
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search Customer Extension Package Name"
                          noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlSolutionPackageName">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let Solution of selectSolutionPackageName" [value]="Solution">
                      {{Solution.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </form>
              <div class="pss-drawer-button-wrapper">
                <button mat-flat-button color="primary" [disabled]="frmControlsInvalid" (click)="save()">Save</button>
                <button mat-flat-button (click)="closeDrawer()">Cancel</button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>