export enum ICIComponentDeploymentManagement {
    
    componentDeployment_notify_deploymentInitiated_heading = "Deployment initiated",
    componentDeployment_notify_deploymentInitiated_body = "ICI SKU deployment is initiated successfully",
    componentDeployment_notify_deploymentNotInitiated_heading = "Deployment not initiated",
    componentDeployment_notify_deploymentNotInitiated_body = "ICI SKU deployment is not initiated",
    componentDeployment_notify_infoNotAvailable_heading = "Information not available",
    componentDeployment_notify_infoNotAvailable_body="Detailed information is not available",
    componentDeployment_create_hint_message_validCharacters = "Valid characters include a-z,0-9,- and no special characters.",
    componentDeployment_Create_Field_cName = "Cname",
    componentDeployment_Create_Field_iciVersion = "Instance ICI Version",
    componentDeployment_Create_Field_sku = "SKU",
    componentDeployment_Create_Field_serviceNowId = "Service Now Ticket Id",
    componentDeployment_Create_Field_pageHeading = "ICI SKU Deployment Request",
    componentDeployment_create_hint_message_validCharacters_serviceNowId = "Valid characters include A-Z, a-z and 0-9.",
    componentDeployment_create_hint_message_select_iciVersion = "Please Select Instance ICI Version.",
    componentDeployment_create_hint_message_select_sku = "Please Select SKU.",
    componentDeployment_table_header_cname = "CNAME",
    componentDeployment_table_header_iciVersion = "ICI VERSION",
    componentDeployment_table_header_serviceNowTicketId ="SERVICENOW TICKET ID",
    componentDeployment_table_header_requestorDetails = "REQUESTER DETAILS",
    componentDeployment_table_header_status = "STATUS",
    componentDeployment_table_header_actions =  "ACTIONS",
    componentDeployment_view_header = "ICI SKU Deployment For:",
    componentDeployment_view_deploymentFor = "Deployment For",
    componentDeployment_view_extensionType = "Extension Type",
    componentDeployment_view_productName = "Product Name",
    componentDeployment_view_packageName = "BinaryFile",
    componentDeployment_view_skuDeployment = "SKU Deployment",
    componentDeployment_name =  'ICI SKU Deployment',
}
