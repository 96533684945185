import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fixed-date',
  templateUrl: './fixed-date.component.html',
  styleUrls: ['./fixed-date.component.scss']
})
export class FixedDateComponent {
  @Input() isFixedDateDisabled!:boolean;
@Input() isCustomDateDisabled!:boolean;
@Output() outputData = new EventEmitter<any>();
@Input() parentEvent !: any;
@Input() moduleName : any;
getdate:any ;
SelectedDateFilter:any = '';
storeData:any;
fixedDateValue:any = {
  thirtyDays : '1',
  threeMonths : '2',
  sixMonths : '3'
}
constructor
(
  private datePipe: DatePipe,
){}
ngOnInit(): void {
  if(this.moduleName == 'customer-management'){
    let data:any = localStorage.getItem('customerFilterData');
    this.storeData = JSON.parse(data)
    if(this.storeData && this.storeData?.dateType?.includes('fixed')){
      this.SelectedDateFilter = this.storeData?.dateType == 'fixed1'? this.fixedDateValue.thirtyDays : this.storeData?.dateType == 'fixed2'? this.fixedDateValue.threeMonths : this.fixedDateValue.sixMonths;
    }
  }
  else if(this.moduleName == 'user-management'){
    let data:any = localStorage.getItem('userFilterData');
    this.storeData = JSON.parse(data)
    if(this.storeData && this.storeData?.dateType?.includes('fixed')){
      this.SelectedDateFilter = this.storeData?.dateType == 'fixed1'? this.fixedDateValue.thirtyDays : this.storeData?.dateType == 'fixed2'? this.fixedDateValue.threeMonths : this.fixedDateValue.sixMonths;
    }
  }
 }

 ngOnChanges(){
  if(this.parentEvent == 'Custom Date'){
    this.SelectedDateFilter = '';
  }
 }
 transformDate(date:any) {
  return this.datePipe.transform((date.toISOString()), 'YYYY-MM-dd');

}
  onDateChange(event: any) {
    this.getdate = this.transformDate(new Date());
    this.getdate = new Date(this.getdate)
    this.SelectedDateFilter = event.value;
    if (event.value == this.fixedDateValue.thirtyDays) {
    
     this.getdate.setDate(new Date(this.getdate).getDate()-30);
    
    }
    else if (event.value == this.fixedDateValue.threeMonths) {
     this.getdate.setMonth(new Date(this.getdate).getMonth()-3);
    }
    else if (event.value == this.fixedDateValue.sixMonths) {
    this.getdate.setMonth(new Date(this.getdate).getMonth()-6);
    }
    this.getdate = this.datePipe.transform((this.getdate).toISOString(), 'YYYY-MM-dd');
    let Data ={
      dateType: event.value == '1' ? 'fixed1' : event.value== '2' ? 'fixed2' : 'fixed3',
      customerCreateDateFrom: this.getdate,
      customerCreateDateTo: this.transformDate(new Date())
    } 
    this.outputData.emit(Data);
  }
}
