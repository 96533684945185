export enum ExtensionManagement {
    /* HEADERS */
    extension_header_create = "Extension Details",
    package_header_create = "Package",
    certification_header_create = "Certification",
    extension_header_view = "Extension Details",
    extension_header_edit = "Edit Role",
    package_label_create = "Enter the package version in this format: Major.Minor.Patch.Build. For example, 1.0.0.0.",
    package_label_create_iciversion = "Enter the ICI version in this format: Major.Minor.Patch.Build. For example, ",
    extension_Search_label_searchExtension = "Search Extension",
    extension_Search_label_Extension_type = "Extension Type",
    extension_Search_label_Customer = "Customer",
    /*EXTENSION LIST COLUMN  HEADER */
    extension_List_Header_type = "Type",
    extension_List_Header_name = "Name",
    extension_List_Header_customerName = "Customer Name",
    extension_List_Header_partnerName = "Partner Name",
    extension_List_Header_tag = "Tags",
    extension_List_Header_RrferenceDocument = "Reference Documents",
    extension_List_Header_status = "Status",
    extension_List_Header_actions = "Actions",
    extension_List_create_primary_btn = "Create",
    /*EXTENSION LIST ACTION BUTTON LABEL*/
    extension_List_Action_Label_downloadDocuments = "Download Documents",
    extension_List_Action_Label_edit = "Edit",
    extension_List_Action_Label_package = "Package",
    extension_List_Action_Label_lock = "Lock",
    extension_List_Action_Label_unlock = "Unlock",
    /*EXTENSION ERROR */
    extension_error_field_tag = "You can enter A-Z,a-z and no special characters.",
    extension_error_field_description = "You can enter A-Z,a-z,0-9 and no special characters.",
    extension_error_field_name = "You can enter A-Z,a-z,0-9 and no special characters.",
    extension_error_field_type_required = "Field can not be blank.",
    extension_error_field_customer_name_required = "Field can not be blank.",
    package_error_field_product_name = "You can enter A-Z,a-z,0-9 with special characters.",
    package_error_field_build_version = "Please enter values for major, minor, patch and build for product release version.",
    /*EXTENSION VIEW FIELD LABEL */
    extension_View_Field_type = "Extension Type",
    extension_View_Field_name = "Extension Name",
    extension_View_Field_customerName = "Customer Name",
    extension_View_Field_partnerName = "Partner Name",
    extension_View_Field_tag = "Tags",
    extension_View_Field_extensionDescription = "Extension Description",
    extension_View_Field_referenceDocument = "Extension Documents",
    extension_View_Field_status = "Status",
    extension_View_Field_actions = "Actions",
    extension_View_dowloadAllFiles_primary_btn = "Download all files",
    extension_View_cancel_btn = "Cancel",
    extension_View_edit_btn = "Edit",
    extension_View_lockDescription = "Locked By",
    /*EXTENSION CREATE FIELD LABEL */
    extension_Create_Field_type = "Extension Type",
    extension_Create_Field_name = "Extension Name",
    extension_Create_Field_customerName = "Customer Name",
    extension_Create_Field_partnerName = "Partner Name",
    extension_Create_Field_tag = "Extension Tags",
    extension_Create_Field_extensionDescription = "Description",
    extension_Create_Field_referenceDocument = "Supported Files",
    extension_Create_Field_status = "Status",
    extension_Create_Field_actions = "Actions",
    extension_Create_dowloadAllFiles_primary_btn = "Download all files",
    extension_Create_saveAndClose_btn = "Save and Close",
    extension_Create_next_btn = "Next",
    extension_Create_cancel_btn = "Cancel",
    extension_Create_browse_btn = "Browse",
    extension_Create_updateAndClose_btn = "Update and Close",
    /*EXTENSION/PACKAGE HINT MESSAGE LABEL */
    extension_create_hint_message_provide = "Provide necessary details for the extension",
    extension_create_hint_message_provideTags = "Enter tag and press enter,please keep on adding multiple tags",
    extension_create_hint_message_provideFile = "You can upload multiple files (up to 10 MB each). Supported formats: PDF, DOC, DOCX, TXT.",
    package_create_hint_message_provide = "Enter package details and upload the package ZIP file",
    package_create_hint_message_packageProvide = "The package name is autogenerated and can be modified.",
    extension_package_create_error_message_name = "Start with a letter or number. Use only underscores or hyphens as special characters. Avoid consecutive hyphens.",
    package_create_hint_message_required_release_date = "Only future date input allowed.",
    package_create_hint_message_filelimit = "Upload files up to 2 GB in .zip format.",
    package_create_label_message_noPackageUpload = "No Package Uploaded for this Extension",
    extension_view_hint_message_noRefDocument_label="No Reference Documents",
    extension_view_hint_message_noRefDocument_para="we could not find any relevant document uploaded by Admin for Extension Management",
    /*PACKAGE LIST COLUMN  HEADER */
    package_List_Header_Package = "Package",
    package_List_Header_description = "Description",
    package_List_Header_status = "Status",
    package_List_Header_actions = "Actions",
    /*PACKAGE CREATE FIELD LABEL */
    package_Create_Field_packageName = "Package Name",
    package_Create_Field_packageVersion = "Package Version",
    package_Create_Field_projectType = "Project Type",
    package_Create_Field_major = "Major",
    package_Create_Field_minor = "Minor",
    package_Create_Field_patch = "Patch",
    package_Create_Field_build = "Build",
    package_Create_Field_description = "Description",
    package_View_Field_iciVersion = "ICI Version",
    package_Create_Field_supportedFiles = "Supported Files",
    package_Create_saveAndClose_btn = "Save and Close",
    package_Create_saveAndNext_btn = "Save and Next",
    package_Create_previous_btn = "Previous",
    package_Create_cancel_btn = "Cancel",
    package_Create_browse_btn = "Browse",
    package_Create_addNewPackage_btn = "Add New Package",
    package_Edit_updateAndClose="Update Package and Close",
    /*PACKAGE VIEW FIELD LABEL */
    package_View_Field_packageName = "Package Name",
    package_View_Field_packageVersion = "Package Version",
    package_View_Field_projectType = "Project Type",
    package_View_Field_CustomerName = "Customer Name",
    package_View_Field_partnerName = "Partner Name",
    package_View_Field_status = "Status",
    package_View_Field_certifiedRequestDate = "Certification Request Date",
    package_View_Field_lastUpdateDate = "Last Update Date",
    package_View_Field_createdBy="Created By",
    package_View_Field_packageDescription = "Package Description",
    package_View_Field_PackageLockDescription = "Locked By",
    package_View_Field_packageFile = "Package File",
    package_View_Field_binaryFile = "Binary File",
    package_View_Field_logFile = "Log File",
    package_View_dowloadSingleFiles_primary_btn = "Download Files",
    package_View_dowloadAllFiles_primary_btn = "Download all files",
    package_View_cancel_btn = "Cancel",
    package_View_submitforSolutionCertification_btn = "Request Certification",
    /*CERTIFICATION LIST COLUMN  HEADER */
    certification_List_Header_extenionName = "Extenion Name",
    certification_List_Header_packageName = "Package Name",
    certification_List_Header_projectType = "Project Type",
    certification_List_Header_status = "Status",
    certification_List_Header_actions = "Actions",
    certification_List_ActionLabel_dowloadFile_btn = "Download files",
    certification_List_ActionLabel_initiateManualCetification_btn = "Initiate Manual Certification (Exception Approval)",
    certification_List_Header_customerName = "Icertis Role",
    certification_List_Header_tag = "Tags",
    certification_List_Header_RrferenceDocument = "Reference Documents",
    certification_List_create_primary_btn = "Create",
    /*CERTIFICATION VIEW FIELD LABEL */
    certification_View_Field_packageName = "Package Name",
    certification_View_Field_packageVersion = "Package Version",
    certification_View_Field_projectType = "Project Type",
    certification_View_Field_partnerName = "Partner Name",
    certification_View_Field_status = "Status",
    certification_View_Field_requestDate = "Request Date",
    certification_View_Field_lastUpdateDate = "Last Update Date",
    certification_View_Field_packageDescription = "Package Description",
    certification_View_Field_packageFile = "Package File",
    certification_View_dowloadFiles_btn = "Download files",
    certification_View_cancel_btn = "Cancel",
    certification_View_submitforSolutionCertification_btn = "Submit for Solution Certification",
    certification_View_initiateManualCetification_btn = "Initiate Manual Certification (Exception Approval)",
    /*INITIATE CERTIFICATION FIELD COLUMN  HEADER */
    initiateCertification_Field_initiateCertificationInfo = "To proceed with exception approval, please attach relevent details for the Certification Approver.",
    initiateCertification_Field_approvalDocuments = "Approval Documents",
    initiateCertification_Field_fileInput = "File Input",
    initiateCertification_Field_fileInputHelpMesaage = "Please upload a zip file upto 5MB that can contain an approval email or any related information",
    initiateCertification_Field_noteForApprover = "Note For Approver",
    initiateCertification_Field_cancel_btn = "Cancel",
    initiateCertification_Field_submit_btn = "Submit",
    /* DIALOG HEADING MESSAGE AND BUTTONS */
    extension_dialog_Save_msg = "Are you sure you want to save the extension details and proceed to the next step?",
    extension_dialog_activate_heading = "Confirm Deactivate",
    extension_dialog_activate_msg = "Are you sure you want to activate the role?",
    extension_dialog_activate_primary_btn = "Activate",
    extension_dialog_deactivate_heading = "Confirm Deactivate",
    extension_dialog_deactivate_msg = "Are you sure you want to deactivate the role? Other changes would be lost.",
    extension_dialog_deactivate_primary_btn = "Deactivate",
    extension_dialog_cancel_btn = "Cancel",
    /* NOTIFICATION MESSAGE AND HEADING */
    extension_notify_notvalid_msg = "Please submit all required fields.",
    extension_notify_extensionNotCreated = "Extension Not Created",
    extension_notify_extensionCreated_heading = "Extension Created",
    extension_notify_extensionCreated_body = "New Extension has been Created Successfully",
    extension_notify_extensionAlreadyCreated_heading = "Extension Already Created",
    extension_notify_extensionAlreadyCreated_body = "The Extension could not be created as it is already created.",
    extension_notify_invalidFileType_heading = "Invalid file type",
    extension_notify_invalidFileType_body = "Please upload valid file type",
    extension_notify_noFileChoosen_heading = "No File choosen",
    extension_notify_noFileChoosen_body = "Please choose file",
    extension_notify_duplicateFile_heading = "Duplicate File",
    extension_notify_duplicateFile_body = "File Already Added",
    extension_notify_invalidFileSize_heading = "File size exceeded",
    extension_notify_invalidFileSize_body = "File size is more than 10 MB",
    extension_notify_emptyFile_heading = "Empty File Selected",
    extension_notify_emptyFile_body = "Uploaded file is an empty file, please check and upload a valid file.",
    extension_notify_extUpdatedSuccessfully_heading = "Extension Updated",
    extension_notify_extUpdatedSuccessfully_body = "Extension has been Updated Successfully",
    extension_notify_savefailed_msg = "The Extension could not be created.",
    extension_notify_duplicateExt_heading = "Extension Already Exists",
    extension_notify_duplicateExt_body = "A record with the same Extension name already exists in the system.",
    extension_notify_extLocked_heading = "Extension is locked",
    extension_notify_extLocked_body = "Extension is locked successfully.",
    extension_notify_extUnLocked_heading = "Extension is unlocked",
    extension_notify_extUnLocked_body = "Extension is un-locked successfully.",
    extension_notify_extDowloaded_heading = "Extension Documents Downloaded",
    extension_notify_extDowloaded_body = "Extension Documents have been downloaded successfully.",
    extension_notify_extDowloadedFailed_heading = "Extension Documents failed to Download",
    extension_notify_extDowloadedFailed_body = "Failed to download Extension Documents.",
    extension_notify_extAlreadyLocked_heading = "Extension is Locked",
    extension_notify_extAlreadyLocked_body = "You cannot edit extension as it is locked.",
    extension_notify_edit_error_message_for_no_partner_heading = "Unable to edit Extension",
    extension_notify_create_error_message_for_no_partner_heading = "Unable to create Extension",
    extension_notify_error_message_for_no_partner_body = "Partner and customer are not assigned to the user. Kindly assign the partner and customer in the user management to proceed with the request update.",
    extension_notify_error_message_for_create_edit_extension_guard_body ="Partner and customer are not assigned to the user. Kindly assign the partner and customer in the user management to proceed with the request creation.",
    extension_notify_error_message_for_extension_pageheader_body ="Partner and customer are not assigned to the user. Kindly assign the partner and customer in the user management to proceed with the request update.",
    package_notify_packageNotUpdated_heading = "Package Not Updated",
    package_notify_packageNotUpdated_body="Package has not been updated successfully",
    package_notify_packageUpdated_heading="Package Updated",
    package_notify_packageUpdated_body ="Package details updated successfully",
    package_notify_packageNotAddedExtLocked = "You cannot add a new package as it is locked.",
    package_notify_packageCreated_heading = "Package Added",
    package_notify_packageCreated_body = "New Package has been Added successfully",
    package_notify_packageNotCreated_heading = "Package Not Added",
    package_notify_packageNotcreated_body = "New Package has not been Added successfully",
    package_notify_packageAlreadyExist_heading = "Package already exist",
    package_notify_packageAlreadyExist_body = "Package with the same name and version already exists, please choose a different name",
    package_notify_fileSizeLimit_heading = "File size exceeded",
    package_notify_fileSizeLimit_body = "File size is more than 2 GB",
    package_notify_fileblank_heading = "Empty File Selected",
    package_notify_fileblank_body = "Uploaded file is an empty file, please check and upload a valid file.",
    package_notify_packageLocked_heading ="Package is locked",
    package_notify_package_queuedForUpgrade_heading ="Queued for Deployment on Upgrade",
    package_notify_packageLocked_body_p1="Package ",
    package_notify_packageLocked_body_p2=" is locked successfully.",
    package_notify_package_mark_queuedForUpgrade_body_p2=" is queued for deployment and will deploy automatically after instance upgrade.",
    package_notify_packageUnLocked_heading ="Package is Unlocked",
    package_notify_packageUnLocked_body_p1="Package ",
    package_notify_packageUnLocked_body_p2=" is Unlocked successfully.",
    package_notify_editPackageLocked_heading ="Package is locked",
    package_notify_editPackageLocked_body = "You cannot edit package as it is locked.",
    package_notify_view_downloadfile_heading = "Downloaded",
    package_notify_view_downloadfile_body_p1 = " has been downloaded.",
    package_notify_view_failedDownloadfile_heading="failed to Downloaded",
    package_notify_view_failedDownloadfile_body_p1 = "Failed to download",
    certification_notify_certificationRequestFailed_heading="Certification Request Failed",
    certification_notify_certificationRequestFailed_body_p1="Certification Request for package ",
    certification_notify_certificationRequestFailed_body_p2=" failed",
    certification_notify_certificationRequestSubmitted_heading="Certification Request Submitted",
    certification_notify_certificationRequestSubmitted_body_p1="Certification Request for package ",
    certification_notify_certificationRequestSubmitted_body_p2=" submitted for approval",
    certification_notify_manualCertiRequested_heading = "Manual certification requested",
    certification_notify_manualCertiRequested_body_p1 = "Manual Certification requested for package ",
    certification_notify_manualCertiRequested_body_p2 = " submitted successfully.",
    initiateCertification_notify_requestSubmittedForApproval = "Request for exception approval submitted successfully.",
    certification_notify_initiateReleaseVersionFailed_heading="Initiate Release Version approval failed",
    certification_notify_initiateReleaseVersionFailed_body="Submit for Initiate Release Version approval failed",
    certification_notify_initiateReleaseVersionApprovalSuccess_heading = "Initiate Release Version approval success",
    certification_notify_initiateReleaseVersionApprovalSuccess_body = "Submit for Initiate Release Version approval success",
    /* No Result Found */
    extension_no_result_msg = "No Extension Found!",
    package_no_result_msg = "No Customer Extension Package Found!",
    certification_no_result_msg = "No Certification Found!",

    redirect_dialog_heading = "Confirm Re-direct",
    redirect_dialog_body = "The link will open in a new browser tab. Are you sure you want to continue?",
    redirect_dialog_primary_btn = 'Yes',
    redirect_dialog_secondary_btn = 'No',

    package_lable_binary_package= "Binary Package",
    package_lable_solution_package= "Solution Package",
    extension_type_business_app = "Business App",
    extension_type_custom_solution = "Custom Solution",
    iciversion_error_message_binary_package_p1 = "ICI version must be ",
    iciversion_error_message_binary_package_p2 = " or later.",
    more_action_btn = "More Action",
    extension_notify_duplicateExt_body_partner_user = "exists for this customer. If it doesn’t appear in the Extension Management list, contact your customer point of contact (POC) for assistance.",
}
