import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class IciComponentDeploymentGuard {

  constructor(
    private router: Router,
    private permssionService: PermissionsService,
  ) { }

  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

      if(this.permssionService.getPermission('AddInstance')) {
        return true;
      }
  
      this.router.navigate(['home/access-denied']);
    return true;
  }
  
}
