import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-view-ici-workertask',
  templateUrl: './view-ici-workertask.component.html',
  styleUrls: ['./view-ici-workertask.component.scss', '../../instance-list/instance-list.component.scss']
})
export class ViewIciWorkertaskComponent  implements OnInit{

  breadCrumbItems:any;
  ViewData:any={};
  workerTaskId: any;
  public instanceEnum = InstanceManagement;
  instanceId: any;
  ViewInstanceData: any = {};
  interestTable: any;
  isDecrypted:any = false;
  displayedColumnsInterest: string[] = ['name', 'topic', 'subscription','parallelWorkers', 'filter'];
  dataSourceValuesInterest: any = {
    name:{
      label:'Name',
      data: 'name',
    },
    topic:{
      label:'Topic',
      data: 'topicName',
    },
    subscription:{
      label:'Subscription',
      data: 'subscriptionName',
    },
    parallelWorkers:{
      label:'Parallel Workers',
      data: 'parallelWorkers',
    },
    filter:{
      label:'Filter',
      data: 'filter',
    }
  };
  dataSourceInterestTable: any;
  settingTable: any;
  displayedSettingColumns:  string[] = ['name', 'encrypted'];
  dataSourceSettingValues: any = {
    name:{
      label:'Name',
      data: 'name',
    },
    encrypted:{
      label:'Encrypted',
      data: 'isEncrypted',
    },
  };
  dataSourceSettingTable: any;

  constructor(private router: Router,
    private titleService: Title,
    private instanceService: InstanceService,
    private dialogService: DialogService,
    private notificationService: NotificationService,) { }

  ngOnInit(): void {
    this.titleService.setTitle('ICI WorkerTask Configuration | IDN');
    this.instanceId = localStorage.getItem('instanceID');
    this.workerTaskId = localStorage.getItem('workerTaskId');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      {label: 'Instance Operations', path: 'instanceoperations/instance-requests'},
      {label: 'Instance Requests', path: 'instanceoperations/instance-requests'},
      { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
      {label: 'ICI Worker Tasks', path: 'instanceoperations/instance-requests/ici-workertask-configuration/list'},
      { label: 'View', path: 'instanceoperations/instance-requests/ici-workertask-configuration/view' },
    ];
    this.getInstanceByID();
    // this.getIciWorkerTaskConfigurationById();
  }

  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewInstanceData = result;
            this.getIciWorkerTaskConfigurationById();
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getIciWorkerTaskConfigurationById() {
    try {
      this.instanceService.getIciWorkerTaskConfigurationById(this.ViewInstanceData?.cname, this.workerTaskId).subscribe(
        (resp: any) => {
          let res = resp.body;
          if (res != undefined && res != null) {
            this.ViewData = res;
            this.isDecrypted =  res.useDecryption;
            this.dataSourceInterestTable = {
              records: this.setEncryptedValues(res.interestList, "parallelWorkers")
            };
            this.dataSourceSettingTable = {
              records: this.setEncryptedValues(res.settingList, "isEncrypted")
            };
            this.setTableData()
          } else {
            this.notificationService.showNotification(
              "Detailed information is not available",
              'warning',
              "Information not available"
            );
            this.router.navigate(['instanceoperations/instance-requests/ici-workertask-configuration/list']);
          }
        },
        (error: HttpErrorResponse) => {
          let message: any = error;
          this.notificationService.showNotification(
            message,
            'warning',
            "Information not available"
          );
          this.router.navigate(['instanceoperations/instance-requests/ici-workertask-configuration/list']);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  setEncryptedValues(data:any, item:any){
    return data?.map((value:any)=> {
      if(value[item] == 1){
        value[item] = (item == 'parallelWorkers')? 'On': 'Yes'
      } else {
        value[item] = (item == 'parallelWorkers')? 'Off': 'No'
      }
      return value
    });
  }
  
  setTableData(){
    this.interestTable = {
      reference: 'interestTable',
      displayedColumns: this.displayedColumnsInterest,
      dataSourceValues: this.dataSourceValuesInterest,
      dataSource: this.dataSourceInterestTable,
    };
    this.settingTable = {
      reference: 'settingTable',
      displayedColumns: this.displayedSettingColumns,
      dataSourceValues: this.dataSourceSettingValues,
      dataSource: this.dataSourceSettingTable,
    };
  }

  onClose(){
    this.router.navigate(['home/instanceoperations/instance-requests/ici-workertask-configuration/list']);
  }

  openSite(siteUrl:any) {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.instanceEnum.redirect_dialog_body,
      confirmText: this.instanceEnum.redirect_dialog_primary_btn,
      cancelText: this.instanceEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open("https://" + siteUrl, '_blank');
      }
    });
 }

   //deactivate the workertask entry
   deactivateEntry(id: any) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.workertask_redirect_dialog_heading,
        module: 'partner',
        message: this.instanceEnum.workertask_redirect_dialog_body,
        confirmText: this.instanceEnum.workertask_redirect_dialog_primary_btn,
        cancelText: this.instanceEnum.workertask_redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if(res){
          try {
            this.instanceService
              .ActivateDeactivateWorkerTask(id, this.ViewInstanceData.cname, false)
              .subscribe((resp: any) => {
                if(resp.isSuccess){
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_disable_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_disable_heading
                  );
                  this.getIciWorkerTaskConfigurationById();
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_disable_failed_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_disable_failed_heading
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  //activate the workertask entry
  activateEntry(id: any) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.workertask_redirect_dialog_heading_enable,
        module: 'partner',
        message: this.instanceEnum.workertask_redirect_dialog_body_enable,
        confirmText: this.instanceEnum.workertask_redirect_dialog_primary_btn,
        cancelText: this.instanceEnum.workertask_redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if(res){
          try {
            this.instanceService
              .ActivateDeactivateWorkerTask(id, this.ViewInstanceData.cname, true)
              .subscribe((resp: any) => {
                if(resp.isSuccess){
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_enable_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_enable_heading
                  );
                  this.getIciWorkerTaskConfigurationById();
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_enable_failed_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_enable_failed_heading
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

}
