import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuditGuardService {

  constructor(
    private router: Router
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let logType = localStorage.getItem('auditLogData');

    if( logType !== null || logType === 'idn-workbench' ) {
      return true;
    }
    this.router.navigate(['home/access-denied']);
    return false;
  }
}
