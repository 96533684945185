export enum WorkbenchMgmtStaticString {
  /* HEADERS */
  wb_header_create = "Create IDN Workbench",
  wb_header_edit = "Edit IDN Workbench",
  wb_header_view = "IDN Workbench",
  /* LABELS AND BUTTONS */
  wb_label_name = "Name",
  wb_label_description = "Description",
  wb_label_version = "Version",
  wb_label_file = "IDN Workbench File",
  wb_label_ici_version = "ICI Version",
  wb_label_release_notes = "Release Notes",
  wb_label_release_date = "Release Date",
  wb_label_eol_date = "End of Life Date",
  wb_label_status = "Status",
  wb_label_save_btn ="Save",
  wb_label_cancel_btn ="Cancel",
  wb_label_update_btn ="Update",
  wb_label_file_uploaded = "File Uploaded",
  wb_label_activate_btn ="Activate",
  wb_label_deactivate_btn ="Deactivate",
  /* FIELD ERROR MESSAGES */
  wb_error_name = "You can enter A-Z,0-9,a-z and no special characters.",
  wb_error_description = "You can enter A-Z,0-9,a-z and no special characters.",
  wb_error_version = "Please add the IDN Workbench version which includes Major, Minor, Patch and Build numbers in the format. Example for version number should be 1.0.0.0",
  wb_error_ici_version = "Please select at least one value in the drop down.",
  wb_error_release_notes = "You can enter A-Z,a-z,0-9 and no special characters.",
  wb_error_endOfLife_date = "End of life date cannot be less than Release date. Kindly update the End of life date.",
  /* FIELD ERROR MESSAGES */
  wb_hint_file = "Upload files upto 500 MB.Supported file types are *.zip,*.vsix and *.rar",
  /* DIALOG HEADING MESSAGE AND BUTTONS */
  wb_dialog_deactivate_heading = "Confirm Deactivate",
  wb_dialog_deactivate_body = "Are you sure you want to De-activate IDN Workbench?<br> Once IDN Workbench is de-activated it will not be available for Developer to download.",
  wb_dialog_activate_heading = "Confirm Activate",
  wb_dialog_activate_body = "Are you sure you want to Activate IDN Workbench?",
  /* NOTIFICATION MESSAGE AND HEADING */
  wb_notify_deactivated_heading = "IDN Workbench De-Activated",
  wb_notify_deactivated_body = "IDN Workbench has been De-activated Successfully.",
  wb_notify_not_deactivated_heading = "IDN Workbench not De-Activated",
  wb_notify_not_deactivated_body = "Cannot deactivate the IDN Workbench as it is already been downloaded by users.",
  wb_notify_activated_heading = "IDN Workbench Activated",
  wb_notify_activated_body = "IDN Workbench has been activated Successfully.",
  wb_notify_not_activated_heading = "IDN Workbench not Activated",
  wb_notify_not_activated_body = "Cannot activate the IDN Workbench as it is already been downloaded by users.",
  wb_notify_mark_download_heading = "IDN Workbench Downloaded",
  wb_notify_mark_download_body = "IDN Workbench has been downloaded.",
  wb_notify_download_start_heading = "Download Started",
  wb_notify_download_start_body = "The download has started and will continue in the background.", 
  wb_notify_not_mark_download_heading = "IDN Workbench not mark Downloaded",
  wb_notify_not_mark_download_body = "IDN Workbench has been failed to Mark download.",
  wb_notify_download_failed_heading = "IDN Workbench not Downloaded",
  wb_notify_download_failed_body = "IDN Workbench has been failed to download.",
  wb_notify_invalid_file_heading = "Invalid file type",
  wb_notify_invalid_file_body = "Please upload valid file type.",
  wb_notify_exceed_file_limit_heading = "File size exceeded",
  wb_notify_exceed_file_limit_body = "File size cannot exceed 500 MB.",
  wb_notify_emptyFile_limit_heading = "Empty File Selected",
  wb_notify_emptyFile_limit_body = "Uploaded file is an empty file, please check and upload a valid file.",
  wb_notify_choose_wb_heading = "Choose IDN Workbench",
  wb_notify_choose_wb_body = "Please upload the file.",
  wb_notify_wb_exist_heading = "IDN Workbench already exist",
  wb_notify_wb_exist_body = "IDN Workbench with same name and version already exist, please choose a different name or version.",
  wb_notify_file_uploaded_heading = "IDN Workbench file uploaded",
  wb_notify_file_uploaded_body = "IDN Workbench file has been uploaded successfully.",
  wb_notify_file_not_uploaded_heading = "IDN Workbench upload failed",
  wb_notify_file_not_uploaded_body = "IDN Workbench file upload failed.",
  wb_notify_wb_updated_heading = "IDN Workbench updated",
  wb_notify_wb_updated_body = "IDN Workbench has been updated successfully.",
  wb_notify_wb_not_updated_heading = "IDN Workbench update failed!",
  wb_notify_wb_not_updated_body = "IDN Workbench did not update.",
  wb_notify_wb_already_deactivate_body = "IDN workbench was deactivated hence cannot be downloaded.",
  wb_notify_wb_already_deactivate_heading = "IDN Workbench was De-Activated.",
  /* No Result Found */
  wb_no_result_msg = "No Workbench Found!",

  redirect_dialog_heading = "Confirm Redirection",
  redirect_dialog_body = "The link will open in a new browser tab. Click <b>Yes</b> to confirm.",
  redirect_dialog_primary_btn = 'Yes',
  redirect_dialog_secondary_btn = 'No',
}
