import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  constructor(private http: HttpClient) { }
  public getThirdPartyLibraries(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/ThirdPartyLibrary/ThirdPartyLibraries', { headers: this.headers });
  }

}
