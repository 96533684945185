<app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false" [createRole]="false" [mapRole]="false"
    [applyFilter]="false" [clearFilter]="false" [archive]="false"></app-pageheader>
<div class="page-container">
    <div class="form-wrapper">
        <h2>{{ this.webAppManagement.webApp_header_create }}</h2>
        <form class="idn-form-element pss-dashboard-form" [formGroup]="frmCreateWebApp">
            <div class="col-left">
                <mat-grid-list cols="2" rowHeight="80px">
                    <mat-grid-tile [colspan]="2">
                        <mat-form-field >
                            <mat-label>{{ this.webAppManagement.webApp_label_app_name }}</mat-label>
                            <input type="text" matInput maxlength="100" formControlName="appName" 
                                placeholder={{this.webAppManagement.webApp_label_app_name}} required>
                            <mat-error *ngIf="frmCreateWebApp.controls.appName.invalid">{{
                                this.webAppManagement.webApp_error_app_name }}</mat-error>
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>

                <!-- <mat-grid-list cols="2" rowHeight="80px">
                    <mat-grid-tile [colspan]="2">
                        <mat-form-field>
                            <mat-label>{{ this.webAppManagement.webApp_label_url }}</mat-label>
                            <input matInput placeholder="URL" formControlName="url" maxlength="100" />
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list> -->

                <div  class="app-setting">
                    <div class="table-scroll" formGroupName="appSettings">
                        <h3>{{ this.webAppManagement.webApp_label_app_settings }}</h3>
                        <table mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort style="width: 100%"
                            class="idn-grid-position">

                            <!-- key Column -->
                            <ng-container matColumnDef="key">
                                <th mat-header-cell *matHeaderCellDef>
                                    Key
                                </th>
                                <td mat-cell *matCellDef="let element;let i=index">
                                    <input type="text" matInput maxlength="100" formControlName="key{{element.id}}" placeholder="Key" [(ngModel)]="finalData[i].key"
                                        required>
                                </td>
                            </ng-container>

                            <!-- value Column -->
                            <ng-container matColumnDef="value">
                                <th mat-header-cell *matHeaderCellDef>
                                    Value
                                </th>
                                <td mat-cell *matCellDef="let element;let i=index">
                                    <input id="element.id" type="text" matInput maxlength="100" formControlName="value{{element.id}}" [(ngModel)]="finalData[i].value"
                                        placeholder="Value" required>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>
                                    action
                                </th>
                                <td mat-cell *matCellDef="let row; let i = index; let last=last">
                                    <mat-icon *ngIf="!last" mat-raised-button color="primary"
                                        (click)="deleteRow(row,i)">cancel</mat-icon>
                                    <mat-icon  *ngIf="last" 
                                        mat-raised-button color="primary" (click)="addRow(row);">add_box</mat-icon>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
                        </table>
                    </div>
                </div>

                <div class="idn-upload-web-app">
                    <mat-grid-list cols="2" rowHeight="80px" class="pss-block-form-web-page">
                        <mat-grid-tile [colspan]="2">
                            <mat-form-field >
                                <mat-label>{{ this.webAppManagement.webApp_label_package }}</mat-label>
                                <mat-toolbar class="idn-upload-toolbar">
                                    <input id="browse-button" class="input-files" matInput [(ngModel)]="fileAttr" [disabled]="listofUploadedFiles.length>=1"
                                        [ngModelOptions]="{standalone: true}" readonly name="name" required />
                                    <button mat-flat-button color="primary" class="idn-file-upload"  [disabled]="listofUploadedFiles.length>=1"
                                        (click)="file.click()">{{
                                        this.webAppManagement.webApp_label_file_browse_btn }}</button>
                                </mat-toolbar>
                                <input type="file" #fileInput id="uploadFile" name="uploadFile"  accept=".zip,.rar,.vsix" [disabled]="listofUploadedFiles.length>=1"
                                    (change)="uploadFileEvt($event)" />
                                <mat-hint class="idn-mat-hint">
                                    <span class="material-icons">info</span>
                                    <span class="message">{{ this.webAppManagement.webApp_hint_documents }}</span>
                                </mat-hint>
                            </mat-form-field>

                        </mat-grid-tile>
                    </mat-grid-list>
                    <button mat-icon-button id="addbutton" color="primary" type="button" (click)="OnUpload()">
                        <mat-icon>upload</mat-icon>
                        Upload
                    </button>
                </div>

            </div>
        </form>

    </div>
</div>
<div class="pss-form-button-wrapper">
    <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>


    <button mat-stroked-button (click)="onCancel()">Cancel</button>
</div>