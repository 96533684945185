import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { environment } from 'src/environments/environment';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})

export class ActivityNotificationService {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  public _notificationSubject = new BehaviorSubject<any>('');
  constructor(private http: HttpClient, private env: EnvService,
    private notificationService: NotificationService,
  ) { }

  //todo --> when pagination and filtering done then need to chnage from UI side.
  public getNotification(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Notification/GetNotifications', { params: { 'pageNumber': 1, 'pageSize': 5,}, headers: this.headers });
  }

  public getNotificationUser(pageNumber: number, pageSize: number, filterSearchValue: string, sortingColumn: string,notificationDateFrom:string,notificationDateTo:string) {
    return this.http.get(this.baseApiUrl + 'api/Notification/GetDetailedNotifications', { params: { 'pageNumber': pageNumber, 'pageSize': pageSize,'filter': filterSearchValue, 'sortBy': sortingColumn,'notificationDateFrom':notificationDateFrom,'notificationDateTo':notificationDateTo}, observe: 'response' , headers: this.headers });
  }

  public updateNotificationStatus(data: any): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Notification/UpdateNotificationStatus', data, { headers: this.headers, observe: 'response' })
  }
  public markAllasRead(): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Notification/UpdateAllNotificationStatus/', {}, { observe: 'response' })
  }
  emitNotification<T>(data: T) {
    this._notificationSubject.next(data);
  }
  onNotification<T>(): Observable<T> {
    return this._notificationSubject.asObservable();
  }

  public sendSDKNotification(id: any, isUpload: any): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/sdk/SDKNotification/' + id, { 'id': id, 'isUpload': isUpload }, { observe: 'response', headers: this.headers, responseType: 'text' });
  }
  TriggerSDKNotification(id: any, isUploadFlag: any) {
    this.sendSDKNotification(id, isUploadFlag).subscribe((resp: any) => {
      console.log("sendSDKNotification", resp);
      if (resp == null || resp.status != 200) {
        this.notificationService.showNotification('Notification sent failed', 'error', 'Failed to send notification');
      }
    })
  }
}
