<app-header></app-header>
<div class="header-wrapper">
  <header class="pss-header">
    <div class="container">
      <section class="pss-hiro-section">
        <div class="pss-banner-caption">
          <h4>{{ 'HOME.HOMEPAGE.GREETING_MAIN' | translate }}</h4>
          <h1>{{ 'HOME.HOMEPAGE.GREETING_SECONDARY' | translate }}</h1>
          <p>{{ 'HOME.HOMEPAGE.GREETING_CONTENT' | translate }}</p>
       
          <button class="pss-button-primary pss-button-primary__btn-large d-flex-center" (click)="scrollToSection(solution)">
            {{ 'HOME.HOMEPAGE.LEARN_MORE' | translate }}
            <mat-icon>arrow_right_alt</mat-icon>
          </button>
        </div>
      </section>
    </div>
  </header>
</div>
<section>
  <div class="section-header" #solution>
    <h2>{{ 'HOME.HOMEPAGE.SOLUTION_HEADING' | translate }}</h2>
  </div>
  <div class="pss-offering">
    <div class="card">
      <span class="steps">1</span>
      <mat-icon class="icon" svgIcon="download"></mat-icon>
      <h3 class="heading">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_ONE.HEADING' | translate }}</h3>
      <p class="details">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_ONE.CONTENT' | translate }}</p>
    </div>
    <div class="card">
      <span class="steps">2</span>
      <mat-icon class="icon icon-scale-down" svgIcon="develope"></mat-icon>
      <h3 class="heading">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_TWO.HEADING' | translate }}</h3>
      <p class="details">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_TWO.CONTENT' | translate }}</p>
    </div>
    <div class="card">
      <span class="steps">3</span>
      <mat-icon class="icon" svgIcon="validate"></mat-icon>
      <h3 class="heading">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_THREE.HEADING' | translate }}</h3>
      <p class="details">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_THREE.CONTENT' | translate }}</p>
    </div>
    <div class="card">
      <span class="steps">4</span>
      <mat-icon class="icon" svgIcon="ready"></mat-icon>
      <h3 class="heading">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_FOUR.HEADING' | translate }}</h3>
      <p class="details">{{ 'HOME.HOMEPAGE.SOLUTION_CARD_FOUR.CONTENT' | translate }}</p>
    </div>

  </div>
</section>

<section class="pss-bg-white">
  <div class="container">
    <div class="section-header">
      <h2>{{ 'HOME.HOMEPAGE.PARTNER_HEADING' | translate }}</h2>
    </div>
    <div class="partner-logo-wrapper">
      <div class="logo">
        <img src="../../../../assets/images/partners/microsoft.png" alt="Microsoft">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/accenture.png" alt="Accenture">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/docusign.png" alt="Docusign">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/whatfix.png" alt="whatfix">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/adobe.png" alt="Adobe">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/sap.png" alt="SAP">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/wipro.png" alt="Wipro">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/tcs.png" alt="TCS">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/pwc.png" alt="PWC">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/box.png" alt="box">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/deloitte.png" alt="Deloitte">
      </div>
      <div class="logo">
        <img src="../../../../assets/images/partners/workday.png" alt="Workday">
      </div>
    </div>
  </div>
</section>
<section>
  <div class="container">
    <div class="pss-testimonial">
      <div class="heading">
        <h2>{{ 'HOME.HOMEPAGE.TESTIMONIAL.HEADING_ONE' | translate }}<br>{{ 'HOME.HOMEPAGE.TESTIMONIAL.HEADING_TWO' | translate }}</h2>
      </div>
      <div class="testimonial">
        <div class="avatar">UO</div>
        <p>{{ 'HOME.HOMEPAGE.TESTIMONIAL.ITEM_ONE.CONTENT' | translate }}</p>
        <div class="author">
          <h3>{{ 'HOME.HOMEPAGE.TESTIMONIAL.ITEM_ONE.AUTHOR_NAME' | translate }}</h3>
          <p>{{ 'HOME.HOMEPAGE.TESTIMONIAL.ITEM_ONE.AUTHOR_COMPANY' | translate }}</p>
        </div>
        <!-- <div class="bullets">
          <ul>
            <li class="active"></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
