export enum MessageEnum {
    CMDBCustName = "Valid characters include A-z and 0-9.",
    CMDBCustFirstName = "Valid characters include A-z and 0-9.",
    CMDBCustLastName = "Valid characters include A-z and 0-9.",
    CMDBCustContactPrimaryNumber = "Valid characters include 0-9,+ and -",
    CMDBCustContactSecondaryNumber = "Valid characters include 0-9,+ and -",
    CMDBPartnerCode = "Valid characters include A-z and 0-9.",
    CMDBPartnerName = "Valid characters include A-z and all special characters",
    INSTCname = "Valid characters include A-z and 0-9.",
    INSTPartnerName = "Valid characters include A-z and 0-9.",
    USERFirstName = "Valid characters include A-z and special characters",
    USERLastName = "Valid characters include A-z and special characters",
    SKUProductName = "Valid characters include A-z and 0-9.",
    INSTRquiredByDate = "Only future date can be selected.",
    INSTSupportingDoc = "Upload multiple files of 50 MB each. Supported file types are pdf, doc,docx, xls, xlsx and txt",
}
