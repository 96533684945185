import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RoleService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  constructor(
    private http: HttpClient
  ) { }

  public GetRoles() {
    return this.http.get(this.baseApiUrl + 'api/Role/List')
  }
  public GetRoleCategory() {
    return this.http.get(this.baseApiUrl + 'api/Role/CategoryList')
  }
  public PostData(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }
  public PutData(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.put(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }
  public activateRole(id: number, status: boolean): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Role/Activate/' + id, status, { observe: 'response' })
  }
  public deActivateRole(id: number, status: boolean): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Role/DeActivate/' + id, status, { observe: 'response' })
  }
  public GetUserById(id: number): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Role/' + id)
  }
  public RoleUserCheck(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Role/RoleUserCheck/' + id, { observe: 'response' })
  }
  public GetPermissionForRole(roleId: number) {
    return this.http.get(this.baseApiUrl + 'api/Permission/' + roleId)
  }
  public GetPermissionModule() {
    return this.http.get(this.baseApiUrl + 'api/Permission/Modules')
  }
  public UpdatePermissions(data: any): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Role/AssignPermissions', data, { observe: 'response' })
  }
  public CheckRoleName(roleName: any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Role/CheckIfRoleNameExists', { params: { 'name': roleName }, headers: this.headers })
  }
}
