import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalStorageExpiry } from '@ng-idle/core';


@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent  implements OnInit {
 @Output() FilteredDateData = new EventEmitter<any>();
  dateFilter:any =[];
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  isFixedDateDisabled: boolean = false;
  isCustomDateDisabled: boolean = true;
  receivedInput: string = '';
  DateType:any;
  storeData: any;
  eventFromParent:any = '';
  @Input() moduleName :any;
  receiveInputValue(inputValue: any) {
    this.receivedInput = inputValue;
    this.FilteredDateData.emit(this.receivedInput);
  }
  ngOnInit(): void {
    this.DateType = 'Fixed Date';
    this.isFixedDateDisabled = false;
    if (this.moduleName == 'customer-management') {
      let data: any = localStorage.getItem('customerFilterData');
      this.storeData = JSON.parse(data);
      if (this.storeData?.dateType == 'custom') {
        this.DateType = 'Custom Date';
        this.isFixedDateDisabled = true;
        this.isCustomDateDisabled = false;
      } else {
        this.DateType = 'Fixed Date';
        this.isFixedDateDisabled = false;
        this.isCustomDateDisabled = true;
      }
    }
    else if (this.moduleName == 'user-management') {
      let data: any = localStorage.getItem('userFilterData');
      this.storeData = JSON.parse(data);
      if (this.storeData?.dateType == 'custom') {
        this.DateType = 'Custom Date';
        this.isFixedDateDisabled = true;
        this.isCustomDateDisabled = false;
      } else {
        this.DateType = 'Fixed Date';
        this.isFixedDateDisabled = false;
        this.isCustomDateDisabled = true;
      }
    }
 }
  onRadioChange(value: string) {
    this.DateType = value;
    if (value === 'Fixed Date') {
      this.isFixedDateDisabled = false;
      this.isCustomDateDisabled = true;
    } else if (value === 'Custom Date') {
      this.isFixedDateDisabled = true;
      this.isCustomDateDisabled = false;
    }
    this.eventFromParent = this.DateType;
  }
}
