import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogData } from 'src/app/core/interfaces/models/confirm-dialog-data';
import { ConfirmDialogComponent } from './confirm-dialog-component/confirm-dialog.component';
import { ConfirmDialogCommentsComponent } from './confirm-dialog-comments/confirm-dialog-comments.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  confirmDialog(data: ConfirmDialogData): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      data,
      width: (data?.module == 'config-details')?'800px':(data?.module == 'package-details')?'1200px':'450px',
      disableClose: true
    }).afterClosed();
  }

  confirmDialogComments(data: ConfirmDialogData): Observable<boolean> {
    return this.dialog.open(ConfirmDialogCommentsComponent, {
      data,
      width: data?.module == 'jsonView'?'100%':data?.module == 'withdraw-certification' ? '470px' : '450px',
      disableClose: true
    }).afterClosed();
  }
}
