import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MultiTenantpodListComponent } from './multi-tenantpod-list/multi-tenantpod-list.component';
import { ViewMultiTenantPodComponent } from './view-multi-tenant-pod/view-multi-tenant-pod.component';
import { PreviewUpgradeComponent } from './preview-upgrade/preview-upgrade.component';

const routes: Routes = [
  {
    path: '',
    component: MultiTenantpodListComponent,
  },
  {
    path: 'view',
    component: ViewMultiTenantPodComponent,
  },
  {
    path: 'preview',
    component: PreviewUpgradeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MultiTenantPodsRoutingModule {}
