<section>
    <div #top id="top-section">
      <app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false" [createRole]="false" [mapRole]="false"
        [applyFilter]="false" [clearFilter]="false" [archive]="false" ></app-pageheader>
      </div>
  </section>
<div>
   <div class="cards-header">{{this.sharedEnum.cards_header}}</div>
    <mat-card  class="cards-body" appearance="outlined">
        <mat-card-content class="card-content" >
            <div>
                <mat-icon class="icon-cls" svgIcon="debug_access_approval_icon"></mat-icon>
            </div>           
            <div class="request-approval">
               {{ this.sharedEnum.approval_request_header}}
            </div>
            <span class="approval-awaiting">{{this.sharedEnum.awaiting_approval_heading}}</span>
            <div class="count">
                <span>{{count}}</span>
            </div>
            <div class="view-btn">
                <button mat-raised-button color="primary" (click)="ViewApprovalListPage()">{{this.sharedEnum.View_btn}}</button>
            </div>
           
        </mat-card-content>
      </mat-card>
</div>