<app-pageheader [pageTitle]="'IDN Workbench / Edit'" [auditLog]="false" [createRole]="false" [mapRole]="true" [breadCrumbs]="this.breadCrumbItems"
  [applyFilter]="false" [clearFilter]="false" [archive]="false"></app-pageheader>
<div class="page-container">
  <div class="form-wrapper">
    <h2>{{ this.WbMgmtEnum.wb_header_edit }}</h2>

    <form class="pss-dashboard-form" [formGroup]="frmSDK">
      <div class="pss-left-panel">
      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_name }}</mat-label>
        <input type="text" maxlength="100" matInput placeholder="Enter Name of IDN Workbench" formControlName="Name" [(ngModel)]="myInputValuename" (ngModelChange)="valueChangename($event)"  required>
        <mat-error *ngIf="frmSDK.controls.Name.invalid">{{ errorDescriptionName }}</mat-error>
      </mat-form-field>
      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_description }}</mat-label>
        <textarea class="idn-min-textarea-height" length="1000" width="50" matInput placeholder="Description of IDN Workbench" [(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)"
          formControlName="Description" required></textarea>
          <mat-error *ngIf="frmSDK.controls.Description.invalid">{{ errorDescriptionField }}</mat-error>
      </mat-form-field>
      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_version }}</mat-label>
        <input type="text" length="1000" width="50" matInput placeholder="IDN Workbench Version" formControlName="Version"
          required>
          <mat-error *ngIf="frmSDK.controls.Version.invalid">{{ this.WbMgmtEnum.wb_error_version }}</mat-error>
      </mat-form-field>

      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_file }}</mat-label>
        <mat-toolbar class="idn-upload-toolbar">
            <input matInput [(ngModel)]="fileAttr" [ngModelOptions]="{standalone: true}" readonly
                name="name" [disabled]="isuploadFile"/>
            <button mat-flat-button color="primary" class="idn-file-upload" [disabled]="isuploadFile">Browse</button>
        </mat-toolbar>
        <label for="uploadFile" class="idn-off-focus-label">File Input</label>
        <input type="file" #fileInput  value="file" id="uploadFile" name="uploadFile"  accept=".zip,.rar, .vsix"  (change)="uploadFileEvt($event)"   [disabled]="isuploadFile"/>
        <mat-hint class="idn-mat-hint">
          <span class="material-icons">info</span>
          <span class="message">{{ this.WbMgmtEnum.wb_hint_file }}</span>
        </mat-hint>
    </mat-form-field>

    </div>
    <div class="pss-right-panel">

      <mat-form-field class="pss-block-form"><mat-label>{{ this.WbMgmtEnum.wb_label_ici_version }}</mat-label>
        <mat-select placeholder="ICI version" formControlName="IClist" multiple required>
          <div *ngFor="let IClist of icVersionList;let i = index">
            <mat-option   [value]="IClist.id">
            <mat-checkbox [checked]="IClist.isChecked" [disabled]="isDisabled" color="warn"  (change)="getselectedICIversion(IClist)">
              {{IClist.version}}
            </mat-checkbox>
          </mat-option>
        </div>
        </mat-select>

      </mat-form-field>

      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_release_notes }}</mat-label>
        <textarea matInput class="idn-min-textarea-height" formControlName="ReleaseNote" placeholder="Release Notes" required></textarea>
          <mat-error *ngIf="frmSDK.controls.EOLDate.invalid">{{ errorDescription }}</mat-error>
      </mat-form-field>
      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_release_date }}</mat-label>
        <input matInput [matDatepicker]="picker" (dateInput)="addEvent('input', $event)" formControlName="ReleaseDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="pss-block-form">
        <mat-label>{{ this.WbMgmtEnum.wb_label_eol_date }}</mat-label>
        <input matInput [matDatepicker]="pickerr" [min]="minDateEolDate" formControlName="EOLDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="pickerr"></mat-datepicker-toggle>
        <mat-datepicker #pickerr></mat-datepicker>
        <mat-error *ngIf="frmSDK.controls.EOLDate.invalid">{{ this.WbMgmtEnum.wb_error_endOfLife_date }}</mat-error>
      </mat-form-field>
    </div>
      <div class="pss-form-button-wrapper">

        <button mat-stroked-button class="pss-btn-danger" (click)="DeactivateSDKs()" [disabled]=" isuploadFile ==false ? true : false" *ngIf="Status==1 && isDeactivateSDK">
          <mat-icon>block</mat-icon> <span>{{ this.WbMgmtEnum.wb_label_deactivate_btn }}</span>
        </button>
        <button mat-stroked-button class="pss-btn-success"  (click)="ActivateSDKs()" [disabled]=" isuploadFile==false ? true : false" *ngIf="Status==2 || isActiveSDK">
          <mat-icon>check</mat-icon> <span>{{ this.WbMgmtEnum.wb_label_activate_btn }}</span>
        </button>
        <button mat-raised-button color="primary" type="submit" (click)="onUpdateSDK()" [disabled]="!frmSDK.valid || Status==2">{{ this.WbMgmtEnum.wb_label_update_btn }}</button>
        <button mat-stroked-button (click)="onCancel('idn-workbench-management')">{{ this.WbMgmtEnum.wb_label_cancel_btn }}</button>

      </div>
    </form>
    <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
      <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
    </button>
  </div>

</div>
