import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ExtensionManagementRoutingModule } from './extension-management-routing.module';
import { CertificationsModule } from '../certifications-management/certifications.module';
import { MaterialModule } from 'src/app/material.module';
import { EditExtensionGuardService } from 'src/app/core/Guards/editExtensionGuard/edit-extension-guard.service';
import { CreateExtensionGuardService } from 'src/app/core/Guards/createExtensionGuard/create-extension-guard.service';


@NgModule({
  declarations: [
  ],
  imports: [
    RouterModule,
    CommonModule,
    ExtensionManagementRoutingModule,
    CertificationsModule,
    MaterialModule
  ],
  exports: [
  ],
  providers: [
    EditExtensionGuardService,
    CreateExtensionGuardService
  ]
})
export class ExtensionManagementModule { }
