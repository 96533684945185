import { formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { RoleService } from 'src/app/core/services/role-management/role.service';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { RoleMgmtStaticString } from 'src/app/shared/enum/roleManagement';

export interface Role {
  id: number;
  name: string;
}
@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss']
})

export class ViewRoleComponent implements OnInit, OnDestroy {
  public RoleMgmtEnum = RoleMgmtStaticString;
  ViewData: any = { 'name': '', 'description': '', 'categoryId': '', 'active': '' };
  UserId: any;
  roles: Role[] = [];
  $subs!: Subscription;
  IsActive: boolean = false;
  isUserAssociate: boolean = false;
  isRoleEdit: boolean = false;
  isRoleActivate: boolean = false;
  isRoleDeactivate: boolean = false;

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/role-management" },
    { label: "Role Management", path: "administration/role-management" },
    { label: "View", path: "view" },
  ];
  pageHeading: string = '';
  labelName: string = '';
  labelDescription: string = '';
  labelCategory: string = '';
  labelStatus: string = '';
  errorName: string = '';
  errorDescription: string = '';
  actionSave: string = '';
  actionCancel: string = '';
  actionDeactivate: string = '';
  actionActivate: string = '';

  constructor(
    private roleService: RoleService,
    private route: ActivatedRoute,
    private sharedata: ShareDataService,
    private router: Router,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private ApiCall: RoleService,
    private permssionService: PermissionsService,
  ) { }

  ngOnDestroy(): void {
    this.$subs.unsubscribe();
  }

  ngOnInit(): void {
    this.pageHeading = this.RoleMgmtEnum.role_header_view;
    this.labelName = this.RoleMgmtEnum.role_label_name;
    this.labelDescription = this.RoleMgmtEnum.role_label_description;
    this.labelCategory = this.RoleMgmtEnum.role_category;
    this.labelStatus = this.RoleMgmtEnum.role_label_status;
    this.errorName = this.RoleMgmtEnum.role_error_roleName;
    this.errorDescription = this.RoleMgmtEnum.role_error_roleDescription;
    this.actionSave = this.RoleMgmtEnum.role_edit_primary_btn;
    this.actionCancel = this.RoleMgmtEnum.role_cancel_btn;
    this.actionDeactivate = this.RoleMgmtEnum.role_deactivate_btn;
    this.actionActivate = this.RoleMgmtEnum.role_activate_btn;
    this.isRoleEdit= this.permssionService.getPermission('UpdateRole');
    this.isRoleActivate= this.permssionService.getPermission('ActivateRole');
    this.isRoleDeactivate= this.permssionService.getPermission('DeactivateRole');
    this.$subs = this.sharedata.receiveData().subscribe(async data => {
      if (data) {
        let json = JSON.parse(data);
        if (json.id != null && json.id != undefined && json.id > 0) {
          this.ViewData = json;
          let roleData = await this.getCustomer(this.ViewData.id);
          if (roleData != null && roleData != undefined) {
            this.ViewData.createdByDisplayName = roleData.createdByDisplayName;
          }
          this.UserId = json.id;
          localStorage.setItem('role-user-id', this.UserId);
        }
      } else {
        this.UserId = localStorage.getItem('role-user-id');
        this.roleService.GetUserById(this.UserId).subscribe((res) => {
          this.ViewData = res;
        })
      }
      this.checkUserAssociateWithRole(this.UserId);
    });
    if (this.ViewData.active) {
      this.IsActive = false;
    }
    else {
      this.IsActive = true;
    }
  }

  async getCustomer(id: any): Promise<any> {
    try {
      return this.roleService.GetUserById(id).toPromise();
    }
    catch (execption) {
      console.log(execption)
    }
  }


  checkUserAssociateWithRole(id: number) {
    if (id != null && id != undefined && id > 0) {
      this.ApiCall.RoleUserCheck(id).subscribe(resp => {
        this.isUserAssociate = resp;
      });
    }
    else {
      this.router.navigate(["home/administration/role-management"]);
    }
  }

  ActivateRole() {
    this.dialogService.confirmDialog({
      title: 'Confirm Activate',
      module: 'role',
      message: 'Are you sure you want to Activate User Role?',
      confirmText: 'Activate',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.roleService.activateRole(this.UserId, this.IsActive).subscribe((res: Response) => {
          let Result: any = res.body;
          if (res.status == 200) {
            this.notificationService.showNotification('Role has been activated Successfully', 'success', 'Role Activated');
            this.router.navigate(["home/administration/role-management"]);
          } else {
            this.notificationService.showNotification(Result.message, 'warning', 'Role not Activated');
            this.router.navigate(["home/administration/role-management"]);
          }
        })
      }
    });
  }

  DeactivateRole() {
    if (this.isUserAssociate) {
      this.notificationService.showNotification("The Role cannot be deactivated since it is already assigned to users", 'error', 'Role not De-Activated');
      this.router.navigate(["home/administration/role-management"]);
    }
    else {
      this.dialogService.confirmDialog({
        title: 'Confirm Deactivate',
        module: 'role',
        message: 'Are you sure you want to De-activate User Role?',
        confirmText: 'Deactivate',
        cancelText: 'Cancel'
      }).subscribe(res => {
        if (res) {
          this.roleService.deActivateRole(this.UserId, this.IsActive).subscribe((res: Response) => {
            let Result: any = res.body;
            if (res.status == 200) {
              this.notificationService.showNotification('Role has been De-activated Successfully', 'success', 'Role De-Activated');
              this.router.navigate(["home/administration/role-management"]);
            } else {
              this.notificationService.showNotification(Result.message, 'warning', 'Role not De-Activated');
              this.router.navigate(["home/administration/role-management"]);
            }
          });
        }
      });
    }
  }

  //To Reset Form
  Cancel() {
    this.router.navigate(["home/administration/role-management"]);
  }
}
