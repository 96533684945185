import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebAppService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;

  constructor(
    private http: HttpClient
  ) { }

  public getBlobUploadConnectionForWebApp(appName: any) {
    return this.http.get(this.baseApiUrl + 'api/PublicPortal/GetBlobUploadConnection', { params: { 'webAppName': appName }, headers: this.headers })
  }

  public deployAzureAppService(data:any):Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/PublicPortal/DeployAzureAppService', data, { observe: 'response' });
  }
}
