import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { type } from 'os';
import { catchError, map, Subscription, throwError } from 'rxjs';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';

enum certificationListStatus {
  certified = 'Certified',
  reviewRequested = 'Review Requested',
  rejected = 'Rejected',
  reviewInProgress = 'Review In-Progress',
  certificationRequested='Certification Requested',
  systemCheckSuccess='System Check Success',
  systemCheckFailed='System Check Failed'
}
const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
    <defs>
      <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
        <feOffset dy="8" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="5" result="blur"/>
        <feFlood flood-opacity="0"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
      <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
        <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
      </g>
      <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
    </g>
    </svg>`
@Component({
  selector: 'app-certification-approver-details',
  templateUrl: './certification-approver-details.component.html',
  styleUrls: ['./certification-approver-details.component.scss']
})
export class CertificationApproverDetailsComponent implements OnInit {

  certificationDetailsData: any;
  certificationId: any;
  certificationsServiceSubscription!: Subscription;
  drawerSubscription!: Subscription;
  isUpdateCertification: Boolean = false;
  downloadFilesActive: Boolean = false;
  isCertificationChecklist: Boolean = false;
  intiateCertificationExceptionDrawerActive: boolean = false;
  certDetails: any;
  isInitiateManualCertification: any;
  isExceptionalApproval:any;
  isRequestorWithdraw: Boolean = false;
  hasGlobalDataAccess:boolean= false;
  canApproveCertification:boolean = false;
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Development and Utilities", path: "developmentandutilities/certification-request" },
    { label: "Certification Requests", path: "developmentandutilities/certification-request" },
    { label: "View", path: "developmentandutilities/certification-request" },
  ];
  isCompleteReview: Boolean = false;
  isInitiateReview: Boolean = false;
  certificationDetailsRefreshSubscription !: Subscription;
  // Make a variable reference to certificationListStatus Enum
  certificationStatus = certificationListStatus;
  certificationEnum = CertificationManagement;
  differenceInDaysandHours : any ;
  daysDifference: any;
  hoursDifference: any;
  minutesDifference:any;
  previousPackage:any;
  linkageAndQueueForUpgradeFeature: any = false;
  isInitiateAutomaticCertificationPermission: any;
  canViewCertification:any;
  loggedInUserPartnerId: any;
  constructor(
    private router: Router,
    private certificationsService: CertificationsService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private permissionsService: PermissionsService,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private extensionservice: ExtensionService,
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }


  ngOnInit(): void {
    let featuresTobeDisabled: any = localStorage.getItem('featuresTobeDisabled');
    this.linkageAndQueueForUpgradeFeature = featuresTobeDisabled?.includes('TenantUpgradeDisableLinkage')? false: true;
    this.isInitiateReview = this.permissionsService.getPermission("InitiateReview");
    this.isCompleteReview = this.permissionsService.getPermission("CompleteReview");
    this.isInitiateManualCertification = this.permissionsService.getPermission("InitiateManualCertification");
    this.isInitiateAutomaticCertificationPermission = this.permissionsService.getPermission("InitiateAutomaticCertification");
    this.isExceptionalApproval = this.permissionsService.getPermission("InitiateManualCertification-ExceptionApproval");
    this.hasGlobalDataAccess = this.permissionsService.hasGlobalDataAccess();
    this.loggedInUserPartnerId = this.permissionsService.getPartnerDetails().id;
    this.canApproveCertification = this.permissionsService.getPermission('ApproveCertification');
    this.canViewCertification = this.permissionsService.getPermission('ViewCertification');
    this.certificationsServiceSubscription = this.certificationsService.onCertificationDetails<any>().subscribe(
      data => {
        if (data != null && data != "") {
          this.certificationId = data.id;
        } else {
          let certificationDetails = localStorage.getItem('certificationId');
          this.certificationId = certificationDetails
        }
        this.getCertificationDetails();
      }
    );
    setTimeout(() => {
      this.certificationsService.emitCertificationDetails(this.certificationDetailsData);
    }, 300);
    this.drawerSubscription = this.certificationsService.intiateCertificationToggleSubject.subscribe(data => {
      if(data) {
        this.isUpdateCertification = false;
        this.downloadFilesActive = false;
        this.isCertificationChecklist = false;
        this.intiateCertificationExceptionDrawerActive = false;
      }
    });
    this.certificationDetailsRefreshSubscription = this.certificationsService._certificationList.subscribe(res => {
      if(res == "redirectToCertificationList" || res == "redirectToCertificationListFromDetails") {
        this.getCertificationDetails();
      }
    })
    
  }

  onCancel() {
    this.router.navigate(['home/developmentandutilities/certification-request/'])
  }

getCertificationDetails(){
  this.certificationsService.getCertificationDetails(this.certificationId).subscribe(response => {
    this.certificationDetailsData = response;
    if(this.linkageAndQueueForUpgradeFeature){
      this.linkedPackage(this.certificationDetailsData?.solutionId)
    }
    if(this.certificationDetailsData != null || this.certificationDetailsData == undefined || this.certificationDetailsData != ''){
      this.calculateDateDifferences();
    }
    if(localStorage.getItem('email') == response.requesterEmail){
      this.isRequestorWithdraw = true;
    }
    else{
      this.isRequestorWithdraw = false;
    }    
  })
}

withdrawRequest(element:any){
  try{
  this.dialogService
      .confirmDialogComments({
        title: "Confirm Request Withdrawal",
          module: 'withdraw-certification',         
          message: ' To withdraw your certification request for package <b>'+element.packageName+'</b>, click <b>Confirm</b>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
          if(res){

            let JSON = {
              id: element.id,
              requesterNote: res,
            };
            
            this.certificationsService.WithdrawManualCertification(JSON).subscribe((resp: any) => { 
                this.notificationService.showNotification(
                  'Your certification request is withdrawn. Request status is changed to System Check Success.',
                  'success',
                  'Success'
                );
                this.getCertificationDetails();              
            });
          }
        });
      } catch (e) {
        console.log(e);
      }
}

calculateDateDifferences() {
  const reviewerApprovedOn = new Date(this.certificationDetailsData?.reviewerApprovedOn);
  const slaResolutionDeadline = new Date(this.certificationDetailsData?.slaResolutionDeadline);
  if(reviewerApprovedOn > slaResolutionDeadline){
   this.differenceInDaysandHours = reviewerApprovedOn.getTime() - slaResolutionDeadline.getTime();
  }
  else{
    this.differenceInDaysandHours = slaResolutionDeadline.getTime() - reviewerApprovedOn.getTime();
  }
  this.daysDifference = Math.floor(this.differenceInDaysandHours / (1000 * 60 * 60 * 24));
  this.hoursDifference = Math.floor((this.differenceInDaysandHours % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  this.minutesDifference = Math.floor((this.differenceInDaysandHours % (1000 * 60 * 60)) / (1000 * 60));

}




initiateReviewbyReviewer(element: any) {
  const reviewerId = this.permissionsService.getUniqueUserid();
  this.certificationsService.initiateReviewbyReviewer(element, reviewerId).pipe(
    catchError(err => {
      if(err.status != 200) {
        this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationRequestFailed_body_p1 +element.packageName+this.certificationEnum.certification_notify_certificationRequestFailed_body_p2, 'error', this.certificationEnum.certification_notify_certificationRequestFailed_heading);
      }
      return throwError(err);
    })
  ).subscribe(response => {
    this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationRequest_body_p1+element.packageName+this.certificationEnum.certification_notify_certificationRequest_body_p2, 'success', this.certificationEnum.certification_notify_certificationRequest_heading);
    this.getCertificationDetails();
  });
}

  // Certification Checklist
  certificationChecklist(element: any) {
    if(!this.isCertificationChecklist) {
      this.isCertificationChecklist = true;
    }
  }

  //Complete Review
  completeReview(element: any){
    if(!this.isUpdateCertification) {
      this.isUpdateCertification = true;
      this.certificationsService.emitCertificationDetails(element);
    }
    if(this.certificationsServiceSubscription) {
      this.certificationsServiceSubscription.unsubscribe();
    }
  }

  // Download Certification Files
  downloadCertificationFiles(element: any) {
    if(!this.downloadFilesActive) {
      this.downloadFilesActive = true;
    }
  }

  // Download Package Download
  downloadSingleFile(fileName: any, uniqueIdentifier: any, moduleName?: any, parentPackage?:boolean) {
    try {
      var FileSaver = require('file-saver');
        this.certificationsService.certificationFileDownload(parentPackage ? this.previousPackage.id : this.certificationDetailsData, fileName, moduleName, parentPackage).pipe(
          catchError(err => {
            if(err.status != 200) {
              this.notificationService.showNotification(this.certificationEnum.certification_notify_packageFailedDownload_body, 'error',this.certificationEnum.certification_notify_packageFailedDownload_heading);
            }
            return throwError(err);
          })
        ).pipe(
          map((data: any) => {
            this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
              if (response._response != undefined && response._response.status == "200") {
                response.blobBody.then(
                  (onres: any) => {
                    FileSaver.saveAs(onres, fileName);
                    if(moduleName == 'veracodeReportFile'){
                      this.notificationService.showNotification(this.certificationEnum.certification_details_veracodeDownload_body, 'success', this.certificationEnum.certification_details_veracodeDownload_heading);
                    }
                    else{
                      this.notificationService.showNotification(this.certificationEnum.certification_notify_packageDownload_body, 'success', this.certificationEnum.certification_notify_packageDownload_heading);
                    }
                   
                  })
              } else if(moduleName != 'veracodeReportFile' ){
                this.notificationService.showNotification(this.certificationEnum.certification_notify_packageFailedDownload_body, 'error',this.certificationEnum.certification_notify_packageFailedDownload_heading);
              }
              else{
                this.notificationService.showNotification(this.certificationEnum.certification_details_veracodeDownload_failed_body, 'error',this.certificationEnum.certification_details_veracodeDownload_failed_heading);
              }
            });
          }), catchError((err) => {
            console.log(err);
            return err;
          })
        ).subscribe();
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy() {
    if(this.certificationsServiceSubscription) {
      this.certificationsServiceSubscription.unsubscribe();
    }
  }

  redirectToHelp() {
    this.dialogService.confirmDialog({
      title: this.certificationEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.certificationEnum.redirect_dialog_body,
      confirmText: this.certificationEnum.redirect_dialog_primary_btn,
      cancelText: this.certificationEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open('https://developer-help.icertis.com/docs/managing-certifications-and-releasing-packages/#0-toc-title', '_blank');
      }
    });
 }

 initiateManualCertification(element:any){
  this.certDetails=element;
    if(element.status == certificationListStatus.systemCheckSuccess) {
      this.certificationsService.initiateManualCertification(element).pipe(
          catchError(err => {
            if(err.status != 200) {
              this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationFailed_body_p1+element.packageName+this.certificationEnum.certification_notify_manualCertificationFailed_body_p2, 'error', this.certificationEnum.certification_notify_manualCertificationFailed_heading);
            }
            return throwError(err);
          })
      ).subscribe(response => {
        this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationRequested_body_p1+element.packageName+this.certificationEnum.certification_notify_manualCertificationRequested_body_p2, 'success', this.certificationEnum.certification_notify_manualCertificationRequested_heading);
        this.getCertificationDetails();
      })
     } else if(element.status == certificationListStatus.systemCheckFailed) {
      this.intiateCertificationExceptionDrawerActive = true;
    }

  }

  linkedPackage(id:any){
    try{
      this.extensionservice.getSolutionPackageHierarchyById(id).subscribe((resp:any)=>{
       if(resp?.parentSolutionPackage){
          this.previousPackage = resp.parentSolutionPackage;
       }
      });      
    } catch(error){
      console.log(error)
    }
    
  }

  resubmitForVulnerabilityScan(id:any){
    try{
      this.certificationsService.ReTriggerVeracodeScan(id).subscribe((res:any)=>{
          this.notificationService.showNotification(
            this.certificationEnum.certification_details_veracodeResubmit_success_body ,
            'success',
            this.certificationEnum.certification_details_veracodeResubmit_success_header
          );
          this.getCertificationDetails();
      })
        
    }catch(exception:any){
      this.notificationService.showNotification(
        exception,
        'error',
        this.certificationEnum.certification_details_veracodeResubmit_error_body
      );
    } 
  }
  
  refreshStatus(){
    this.getCertificationDetails();
  }
}
