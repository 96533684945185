import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PermissionsService } from '../../services/common/permissions.service';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

@Injectable()
export class CreateExtensionGuardService {
  extensionEnum = ExtensionManagement;
  constructor(
    private router: Router,
    private _router: Router,
    private permissionService: PermissionsService,
    private notificationService: NotificationService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    let userCategory=this.permissionService.getUsercategoryId(); 
    let partnerDetails = this.permissionService.getPartnerDetails();

    if(partnerDetails.id || userCategory == USER_CATEGORY_ID.Customer) {
      return true;
    }
    this.notificationService.showNotification(this.extensionEnum.extension_notify_error_message_for_create_edit_extension_guard_body, 'error', "Unable to create Extension");
    this.router.navigate(['home/access-denied']);
    return false;
  }
}
