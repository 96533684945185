export enum ActivityNotification {
    /*Activity Notification */
    notification_notify_success_heading = "Marked as read",
    notification_notify_success_body = "Marked notifications as read.",
    notification_notify_failed_heading = "Failed to mark all as read",
    notification_notify_failed_body = "Unable to Mark notifications as read",

    /* Dialog strings */
    notification_dialog_heading = "Mark all notification as read",
    notification_dialog_body = 'Are you sure you want to mark all the notifications as read? You can still find all the notifications by clicking on View all Notifications.',
    notification_dialog_primary_btn = 'Confirm',
    notification_dialog_secondary_btn = 'Cancel',
    /* No Result Found */
    notification_no_result_msg = "No Notification Found!",
    audit_no_result_msg = "No Audit Log Found!"
}
