<app-pageheader class="pageheader" [pageTitle]="'ICI Worktask Configuration Requests'" [breadCrumbs]="this.breadCrumbItems" [editWorkerTask]="ViewData.isEnabled"></app-pageheader>

  <div class="page-container">
    <div class="idn-view-wrapper">
      <div class="idn-card-wrapper">
        <mat-card class="idn-card">
          <mat-card-header class="card-header idn-view-header">
            <div class="data-left">
              <mat-card-title class="card-title idn-view-header-label">{{instanceEnum.instance_header_viewInstance}}</mat-card-title>
              <div class="idn-view-header-data">
                <div *ngIf="ViewInstanceData.statusId == 7" class="cname-link"><a href="#" (contextmenu)="openSite(ViewInstanceData.cname)"
                  (click)="openSite(ViewInstanceData.cname); $event.preventDefault();"><h3>{{ViewInstanceData.cname}}</h3></a> <mat-icon
                  (click)="openSite(ViewInstanceData.cname)">open_in_new</mat-icon></div>
                <h3 *ngIf="ViewInstanceData.statusId != 7">{{ViewInstanceData.cname}}</h3>
                <em><b>Created on: {{ViewInstanceData.createdAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" }} by
                    {{ViewInstanceData.requesterdDetail}}
                  </b></em>
              </div>
            </div>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content class="idn-view-body">
              <div class="card-content">
                  <div class="pss-column-panel">
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_task_id}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.taskId}}</div>
                    </div>
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_task_name}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.taskName}}</div>
                    </div>
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_description}}</div>
                      <div class="idn-view-body-items-data">{{ViewData.description}}</div>
                    </div>
                  </div>
  
                  <div class="pss-column-panel">
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_partnerName}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.partnerName ? ViewData.partnerName : 'NA'}}</div>
                    </div>
                    
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_category}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.category == 'EventBased' ?"Event Based": ViewData.category}}</div>
                    </div>

                    <div class="idn-view-body-items" *ngIf="ViewData.category != 'EventBased'">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_form_field_cron_expression}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.cronExpression}}</div>
                    </div>
                  </div>
  
                  <div class="pss-column-panel">
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_customerName}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.customerName}}</div>
                    </div>

                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_status}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.isEnabled ? 'Active' : 'In-Active'}}</div>
                    </div>                    
                  </div>
  
                  <div class="pss-column-panel">
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_running}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.isRunning? 'Yes' : 'No'}}</div>
                    </div> 
                    
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_last_executed_on}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.lastExecutedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</div>
                    </div>
                  </div>

                  <div class="pss-column-panel">
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_task_type}}</div>
                      <div class="idn-view-body-items-data single-Line">{{ViewData.category=='EventBased'? "Event Task" : "Schedule Task"}}</div>
                    </div>
            
                    <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">{{instanceEnum.workertask_list_field_requestor_details}}</div>
                      <div class="idn-view-body-items-data single-Line">
                        <span class="view-user-info">{{ViewData.requestedBy}}</span>
                        <span class="view-timestamp" *ngIf="ViewData.requestedBy && ViewData.requestedOn">on {{ViewData.requestedOn | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
                      </div>
                    </div> 
                                        
                    <div class="idn-view-body-items">
                      <mat-slide-toggle [(ngModel)]="isDecrypted" [ngModelOptions]="{standalone: true}" color="primary"  [disabled]="true">{{instanceEnum.workertask_form_field_use_decryption}}</mat-slide-toggle>
                    </div> 
                  </div>                
              </div>
          </mat-card-content>
      </mat-card>
      </div>

      <mat-accordion class="idn-panel">
        <mat-expansion-panel *ngIf="ViewData.category=='EventBased'">
            <mat-expansion-panel-header class="header">
                <mat-panel-title>
                    Interest Table Configuration
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-idn-table [data]="interestTable"></app-idn-table>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header class="header">
              <mat-panel-title>
                  Setting Table Configuration
              </mat-panel-title>
          </mat-expansion-panel-header>
          <app-idn-table [data]="settingTable"></app-idn-table>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div class="pss-form-button-wrapper">
    <button mat-stroked-button (click)="onClose()">Close</button>
    <button mat-stroked-button class="pss-btn-danger" (click)="deactivateEntry(ViewData.taskId)" *ngIf="ViewData.isEnabled">
      <mat-icon>block</mat-icon> Deactivate
    </button> 
    <button mat-stroked-button class="pss-btn-success" (click)="activateEntry(ViewData.taskId)" *ngIf="!ViewData.isEnabled">
      <mat-icon>check</mat-icon> Activate
    </button>
</div>