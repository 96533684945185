<mat-drawer-container class="update-certification-container colorred ">
  <mat-drawer #drawer class="update-certification-drawer" position="end" mode="side">
    <div class="update-certification-header">
      <h2><mat-icon class="close-icon">newspaper</mat-icon>Complete Review</h2>
      <button mat-icon-button (click)="closeDrawer()" aria-label="close-label">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
    <div class="update-certification-content">
      <div class="update-certification-info" aria-label="error-label">
        <mat-icon class="close-icon">error</mat-icon>
        <p>{{certificationEnum.certification_Update_helpMessage}}</p>
      </div>
      <div class="update-certification-form">
        <div class="update-certification-details">
          <ul *ngIf="certificationDetailsData">
            <li><span>{{certificationEnum.certification_Update_Field_packageName}}</span>{{
              certificationDetailsData.packageName }}</li>
            <li><span>{{certificationEnum.certification_Update_Field_projectVersion}}</span>{{
              certificationDetailsData.packageVersion }}</li>
            <li><span>{{certificationEnum.certification_Update_Field_partnerName}}</span>{{
              certificationDetailsData.partnerName ? certificationDetailsData.partnerName : 'NA' }}</li>
            <li><span>{{certificationEnum.certification_Update_Field_customerName}}</span>{{
              certificationDetailsData.customerName }}</li>
            <li><span>{{certificationEnum.certification_Update_Field_requesterDetails}}</span>{{
              certificationDetailsData.requesterName }} <br> {{ certificationDetailsData.requestedOn | date: "dd MMMM,
              yyyy 'at' HH:mm '(UTC)'" | dateFormat }}</li>
            <li
              [ngClass]="{'exceptional-approval': certificationDetailsData.isExceptionalApproval == true, 'not-exceptional-approval': certificationDetailsData.isExceptionalApproval == false }">
              <span>EXCEPTION APPROVAL</span>{{ certificationDetailsData.isExceptionalApproval == true ? "YES" : "NO" }}
            </li>
            <li class="full-width"><span>{{certificationEnum.certification_Update_Field_packageDescription}}</span>{{
              certificationDetailsData.packageDescription }}</li>
          </ul>
          <div class="certification-checklist">
            <h3>{{certificationEnum.certification_Update_Field_certificationCheckList}}</h3>
            <mat-hint class="idn-mat-hint">
              <span class="material-icons">info</span>
              <span class="message">{{certificationEnum.certification_Update_HintMessage}}</span>
            </mat-hint>
            <mat-accordion>
              <mat-expansion-panel #mep="matExpansionPanel" hideToggle *ngFor="let list of certificationCheckList"
                mat-expanded>
                <mat-expansion-panel-header aria-hidden="true">
                  <mat-panel-title>
                    <div class="certification-question" (click)="toggleQuestion(checkListComment[list.id], list.id);">
                      <div>
                        <span>{{ list.id }}</span>
                      </div>
                      <p>{{ list.question }}</p>
                    </div>
                    <div class="certification-icons">
                      <span #messageVar class="message-indicate"></span>
                      <mat-icon class="close-icon">speaker_notes</mat-icon>
                      <mat-slide-toggle [checked]="selectedAnswer[list.id]==true" color="primary"
                        aria-label="{{'toggle'+list.id}}" (click)="$event.stopPropagation();"
                        (change)="handleChange($event, list, checkListComment[list.id])"></mat-slide-toggle>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="certification-question-body">
                  <form>
                    <quill-editor aria-label="rich-text" [(ngModel)]="checkListComment[list.id]"
                      (onEditorCreated)="onEditorCreated($event);(checkListComment[list.id]!=null && checkListComment[list.id]!='')? messageVar.classList.add('message-available'):messageVar.classList.remove('message-available')"
                      matInput [styles]="editorStyle" [modules]="config" name="checkListComment"
                      placeholder="Brief Note for Approver (if any)..." #editor></quill-editor>
                    <div class="certification-question-btn">
                      <button mat-stroked-button class="submit-btn" color="primary" class="idn-button-secondary"
                        (click)="mep.expanded = !mep.expanded; updateComments('', list, checkListComment[list.id]); (checkListComment[list.id] != '' && checkListComment[list.id] != null) ? messageVar.classList.add('message-available') : messageVar.classList.remove('message-available')">Update</button>
                      <button mat-stroked-button class="submit-btn" color="primary" class="idn-button-cancel"
                        (click)="mep.expanded = !mep.expanded; checkListComment[list.id] = messageTemp">Cancel</button>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <h3>{{certificationEnum.certification_Update_Field_overallComment}}</h3>
            <quill-editor aria-label="rich-text" matInput [styles]="editorStyle" [modules]="config"
              [(ngModel)]="comments" (onEditorCreated)="onEditorCreated($event)"
              (onContentChanged)="onContentChanged($event)" placeholder="Brief Note for Approver (if any)..."
              #editor></quill-editor>
            <div *ngIf="OnUpdate()" class="error-msg">{{errorMessage}}</div>
            <mat-form-field class="pss-block-form pss-input-upload mb-2">
              <mat-label>{{certificationEnum.certification_Update_Field_approvalDocuments}}</mat-label>
              <mat-toolbar class="idn-upload-toolbar">
                <input matInput [(ngModel)]="fileAttr" class="input-files" [ngModelOptions]="{standalone: true}"
                  readonly name="name" />
                <button mat-flat-button color="primary"
                  class="idn-file-upload">{{certificationEnum.certification_Update_Field_browse_btn}}</button>
              </mat-toolbar>
              <label for="uploadFile"
                class="idn-off-focus-label">{{certificationEnum.certification_Update_Field_fileInput}}</label>
              <input type="file" #fileInput id="uploadFile" name="uploadFile" accept=".zip"
                (change)="uploadFileEvt($event)" />
              <mat-hint class="idn-mat-hint">
                <span class="material-icons">info</span>
                <span class="message">{{certificationEnum.certification_Update_HelpMessage_fileSize}}</span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="update-certification-footer">
      <button mat-stroked-button *ngIf="isApproveCertification"
        [disabled]="!(enableDraft || (comments != '' && comments != null)) || OnUpdate() " class="idn-button-secondary"
        color="primary" (click)="onApproveOrReject('draft')"><mat-icon>check_circle_outline</mat-icon>Draft</button>
      <button mat-stroked-button *ngIf="isApproveCertification"
        [disabled]="emptyComment || (comments == '') || (comments == null) || (!checkListValidation) || OnUpdate() "
        class="idn-button-secondary" color="primary"
        (click)="onApproveOrReject('approve')"><mat-icon>check_circle_outline</mat-icon>{{certificationEnum.certification_Update_Field_approve_btn}}</button>
      <button mat-stroked-button *ngIf="isRejectCertification"
        [disabled]="emptyComment || (comments == '') || (comments == null) || (!checkListValidation) || OnUpdate() "
        class="reject-btn" color="primary"
        (click)="onApproveOrReject('reject')"><mat-icon>cancel_outline</mat-icon>{{certificationEnum.certification_Update_Field_reject_btn}}</button>
      <button mat-stroked-button
        (click)="closeDrawer()">{{certificationEnum.certification_Update_Field_cancel_btn}}</button>
    </div>
  </mat-drawer>
</mat-drawer-container>