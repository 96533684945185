import { E } from '@angular/cdk/keycodes';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-config-drawer',
  templateUrl: './config-drawer.component.html',
  styleUrls: ['./config-drawer.component.scss'],
})
export class ConfigDrawerComponent implements OnInit {
  
  public drawerConfigView!: MatDrawer;
  @Output() closeD = new EventEmitter<String>();
  @Input() EditData: any;
  public instanceEnum = InstanceManagement;
  isTenantConfig: boolean = false;
  editableFeilds :any;
  invalidData = false;
  validateError = '';
  editMode = false;
  compareValue: any;
  jsonInput:boolean=false;

  constructor( private permissionService:PermissionsService) {}

  ngOnInit(): void {
    this.permissionService.GetIDNConfiguration().subscribe(resp =>{
      if(resp){
        this.editableFeilds = resp.body.content.configFileEditableFields;
      }
    });
    this.compareValue = Object.assign({}, this.EditData);
    if(this.EditData){
      this.jsonInput=this.EditData.ValueType == "System.String"?(this.checkJsonType(this.EditData.Value) && (this.EditData.Value).includes('{')):false;
    }
  }

  closeDrawer() {
    this.closeD.emit('false');
  }

  validateJsonType(data: any) {
    try {
      if (JSON.parse(data.target.value) && (data.target.value).includes('{')) {
        this.invalidData = false;
        if((data.target.value != this.EditData.Value)){
          this.compareValue.isModified=true;
        } else{
          this.compareValue.isModified=false;
        }
      } else{
        this.invalidData = true;
        this.validateError = this.instanceEnum.instance_config_json_error_message;
      }     
    } catch (e) {
      if (e instanceof SyntaxError) {
        this.invalidData = true;
        this.validateError = this.instanceEnum.instance_config_json_error_message;
        return false;
      }
    }
    return true;
  }

  checkJsonType(data:any){
    try {
      if (JSON.parse(data)) {
        return true;
      }
    } catch (e) {
      if (e instanceof SyntaxError) {
        return false;
      }
    }
    return false;
  }

  validateInt32Type(data: any) {
    // Validating input field as int32
    const lowerLimitInt32 = -2147483648;
    const higherLimitInt32 = 2147483647;
    data = data.target.value;
    if (isNaN(data)) {
      this.invalidData = true;
      this.validateError = this.instanceEnum.instance_config_int32_error_message;
      return;
    }
    const userInput = parseInt(data);
    if (userInput > lowerLimitInt32 && userInput < higherLimitInt32) {
      // Matches numbers between -2147483648 and 2147483647
      this.invalidData = false;
    } else {
      this.invalidData = true;
      this.validateError = this.instanceEnum.instance_config_int32_error_message;
    }
    if(data != this.EditData.Value){
      this.compareValue.isModified=true;
    } else{
      this.compareValue.isModified=false;
    }
  }

  validateInt64Type(data: any) {
    // Validating input field as int64
    const lowerLimitInt64 = '-9223372036854775808';
    const higherLimitInt64 = '9223372036854775807';
    data = data.target.value;
    if (isNaN(data)) {
      this.invalidData = true;
      this.validateError = this.instanceEnum.instance_config_int64_error_message;
      return;
    }
    const userInput = BigInt(data);
    if (
      userInput > BigInt(lowerLimitInt64) &&
      userInput < BigInt(higherLimitInt64)
    ) {
      // Matches numbers between -9223372036854775808 and 9223372036854775807
      this.invalidData = false;
    } else {
      this.invalidData = true;
      this.validateError = this.instanceEnum.instance_config_int64_error_message;
    }
    if(data != this.EditData.Value){
      this.compareValue.isModified=true;
    } else{
      this.compareValue.isModified=false;
    }
  }

  validateStringType(data: any) {
    const userInput = data.target.value;
    if (typeof userInput === 'string' && userInput != "") {
      this.invalidData = false;
    } else {
      this.invalidData = true;
      this.validateError = this.instanceEnum.instance_config_string_error_message;
    }
    if(userInput != this.EditData.Value){
      this.compareValue.isModified=true;
    } else{
      this.compareValue.isModified=false;
    }
  }

  validateBoolean(data:any){
    const userInput = data;
    if(userInput != this.EditData.Value){
      this.compareValue.isModified=true;
    } else{
      this.compareValue.isModified=false;
    }
  }

  save(element: any) {
    if(this.compareValue.isModified){
      this.EditData.Value= this.compareValue.Value;
      this.EditData.isModified=true;
    }
    element.isEditable = false;
    this.editMode = false;
    this.closeD.emit(this.EditData);
  }

  checkIntType(event:any){
    const lowLimit=48;
    const highLimit=57;
    return event.charCode >= lowLimit && event.charCode <= highLimit
  }
}
