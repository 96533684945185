
//TODO Localization

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/core/services/role-management/role.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom } from 'rxjs';
import { RoleMgmtStaticString } from 'src/app/shared/enum/roleManagement';
import { UserService } from 'src/app/core/services/user-management/user.service';
import { InputPattern } from 'src/app/shared/enum/inputPattern';

export interface Role {
  id: string;
  name: string;
}



@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent implements OnInit {
  public RoleMgmtEnum = RoleMgmtStaticString;
  selectedValue: string = '';
  roles: Role[] = [];
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/role-management" },
    { label: "Role Management", path: "administration/role-management" },
    { label: "Create", path: "create" },
  ];
  pageHeading: string = '';
  labelName: string = '';
  labelDescription: string = '';
  labelCategory: string = '';
  errorName: string = '';
  errorDescription: string = '';
  actionSave: string = '';
  actionCancel: string = '';
  roleCategory :any;
  public regex = InputPattern;
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;

  constructor(
    private ApiCall: RoleService,
    private titleService: Title,
    private router: Router,
    private notificationService: NotificationService,
    private msalService: MsalService,
    private userService: UserService,
  ) { }

  frmRole: FormGroup = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
    Description: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    Active: new FormControl(true, Validators.requiredTrue),
    UserCategoryId: new FormControl('',Validators.required)
  });

  ngOnInit(): void {
    this.titleService.setTitle('Create New Role | Partner Self Service');
    this.pageHeading = this.RoleMgmtEnum.role_header_create;
    this.labelName = this.RoleMgmtEnum.role_label_name;
    this.labelDescription = this.RoleMgmtEnum.role_label_description;
    this.labelCategory = this.RoleMgmtEnum.role_label_category;
    this.errorName = this.RoleMgmtEnum.role_error_roleName;
    this.errorDescription = this.regex.descriptionErrorMessage;
    this.actionSave = this.RoleMgmtEnum.role_create_primary_btn;
    this.actionCancel = this.RoleMgmtEnum.role_cancel_btn;
    this.getUserCategories();   
  }

  getUserCategories() {
    try {
      this.userService.GetUserCategories().subscribe((res: any) => {
        if (res != undefined && res != null && res != '') {
          this.roleCategory = res;
        }
      })
    }
    catch (e) {
      console.log('Exception :', e)
    }
  }

  //To Save Role Data
  async createRole() {
    if (!this.frmRole.valid) {
      this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_not_create_body, 'warning', this.RoleMgmtEnum.role_notify_not_create_heading);
      return;
    }
    let JSON = this.frmRole.value;
    let isexist = await this.checkIfRoleExsists(JSON.Name.trim(), JSON.UserCategoryId)
    if (isexist != undefined && !isexist) {
      this.ApiCall.PostData("api/Role/Add", {}, JSON).subscribe((result: Response) => {
        if (result != null && result != undefined) {
          if (result.status == 200) {
            this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_create_body, 'success', this.RoleMgmtEnum.role_notify_create_heading);

            this.frmRole.reset();
            this.router.navigate(["home/administration/role-management"]);
          }
        }
      })
    }
    else {
      this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_create_duplicate_body, 'error', this.RoleMgmtEnum.role_notify_create_duplicate_heading);
      //this.frmRole.reset();
      //this.router.navigate(["home/role-management"]);
    }
  }


  async checkIfRoleExsists(roleName: any, userCategoryId:any) {
    return await lastValueFrom(this.ApiCall.CheckRoleName(roleName, userCategoryId))
  }

  //To Reset Form
  Cancel() {
    this.frmRole.reset();
    this.router.navigate(["home/administration/role-management"]);
  }

  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescription = this.regex.descriptionErrorMessage;
      return;
    }
    if (!this.regexDescription.test(event[0])) {
      this.errorDescription = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescription = this.regex.descriptionErrorMessage;
    }
  }
}
