<div class="filter-idn" #filterDiv>
    <div class="heading">
        <div class="label"><mat-icon>filter_alt</mat-icon><span>Filters</span></div>
        <button id="closeButton" mat-icon-button (click)="closeAdvanceFilter()" aria-label="Close Button">
            <mat-icon aria-label="close Button">close</mat-icon>
        </button>
    </div>
    <mat-tab-group animationDuration="0ms">
        <mat-tab *ngFor="let item of tabsData" label="{{item.label}}">
            <ng-template mat-tab-label>
                {{ item.label }}
                <mat-icon class="circle-icon" *ngIf="item.show">{{ item.icon }}</mat-icon>                
              </ng-template>
            <div *ngIf="item.label == 'DATE (UTC)'">
                <app-date-filter (FilteredDateData)="receivedInput($event)" [moduleName]="referenceModule"></app-date-filter>
            </div>
            <div *ngIf="item.label == 'ENVIRONMENT TYPE' && referenceModule != 'user-management'">
                <app-environment-type-filter (selectedData)="getSelectedEnvironmentType($event)"></app-environment-type-filter>
            </div>
            <div *ngIf="item.label == 'REGION' && referenceModule != 'user-management'">
                <app-idn-checkbox-list (selectedData)="getSelectedRegions($event)" [dataSet]="dataSetRegion"></app-idn-checkbox-list>
            </div>
            <div *ngIf="item.label == 'REQUIRED ICI PRODUCTS' && referenceModule != 'user-management'">
                <app-idn-checkbox-list (selectedData)="getSelectedSkuId($event)" [dataSet]="dataSetRequiredICIProducts"></app-idn-checkbox-list>
            </div>
            <div *ngIf="item.label == 'USER CATEGORY' && referenceModule != 'customer-management' && partnerAccess">
            <app-idn-dropdown (selectedData)="getSelectedUserCategory($event)" [moduleName]="referenceModule"></app-idn-dropdown>
            </div>
            <div class="pss-drawer-button-wrapper">
                <button class="mr-1" mat-flat-button mat-button color="primary" (click)="GetAdvanceFilteredData()">Apply</button>
                <button class="mr-1" mat-stroked-button (click)="resetAdvanceFilter()">Reset</button>
            </div>
        </mat-tab>
    </mat-tab-group>

</div>