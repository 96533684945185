export enum PartnerMgmtStaticString {
  /* HEADERS */
  partner_header_create = "Create Partner",
  partner_header_edit = "Edit Partner",
  partner_header_view = "View Partner",
  /* LABELS AND BUTTONS */
  partner_label_code = "Partner Code",
  partner_label_name = "Name",
  partner_label_description = "Description",
  partner_label_region = "Region",
  partner_label_user_limit = "User Limit",
  partner_label_status = "Status",
  partner_create_primary_btn = "Save",
  partner_cancel_btn = "Cancel",
  partner_edit_primary_btn = "Update",
  partner_activate_btn = "Activate",
  partner_deactivate_btn = "Deactivate",


  /* FIELD ERROR MESSAGES */
  partner_error_code = "Valid characters include A-z and 0-9",
  partner_error_name = "Valid characters include A-z and 0-9",
  partner_error_description = "You can enter A-Z,a-z,0-9 and no special characters",
  /* DIALOG HEADING MESSAGE AND BUTTONS */
  partner_dialog_deactivate_heading = "Confirm Deactivate",
  partner_dialog_deactivate_body = "Are you sure you want to deactivate this partner?",
  partner_dialog_activate_heading = "Confirm Activate",
  partner_dialog_activate_body = "Are you sure you want to activate this partner?",
  partner_dialog_cancel_btn = "Cancel",
  partner_dialog_deactivate_primary_btn = "Deactivate",
  partner_dialog_activate_primary_btn = "Activate",

  /* NOTIFICATION MESSAGE AND HEADING */
  partner_notify_linked_heading = "Partner not De-Activated",
  partner_notify_linked_body = "Cannot deactivate the Partner as it is already linked to Customers",
  partner_notify_deactivated_heading = "Partner De-Activated",
  partner_notify_deactivated_body = "Partner has been De-activated Successfully",
  partner_notify_not_deactivated_heading = "Partner not De-Activated",
  partner_notify_not_activated_heading = "Partner not Activated",
  partner_notify_activated_heading = "Partner Activated",
  partner_notify_activated_body = "Partner has been activated Successfully",
  partner_notify_error_failed_heading = "Partner Not Created",
  partner_notify_error_failed_body = "Please enter required field",
  partner_notify_created_heading = "Partner created",
  partner_notify_created_body = "Partner has been created successfully.",
  partner_notify_updated_heading = "Partner updated",
  partner_notify_updated_body = "Partner has been updated successfully.",
  partner_notify_not_updated_heading = "Partner Not Updated",
  partner_notify_not_updated_body = "Partner has not been Updated.",
  partner_notify_not_created_heading = "Partner Not created",
  partner_notify_not_created_body = "New Partner has not been Created Successfully",
  partner_notify_already_deactivated_body = "This Partner is already De-activated",
  partner_notify_already_activated_body = "This Partner is already Activated",
  partner_notify_exist_heading = "Partner already exists",
  partner_notify_exist_body = "Record with same Partner Code and Partner Name already exists.",
  /* No Result Found */
  partner_no_result_msg = "No Partner Found!",
  partner_userlimit_info_note = "Specifies the number of IDN partner users that can be created in the system. This does not include users created with the “Non IDN” role.",
  partner_userlimit_msg = "Specifies the number of IDN partner users available in the system. This does not include users created with the “Non IDN” role.",
  partner_heading_partner_users = "Partner Users",
  partner_heading_partner_details = "Partner Details",
  partner_column_partner_name = "Partner Name",
  partner_column_created_by = "Created By",
  partner_column_created_on = "Created On",
}
