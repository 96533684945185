import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvService } from '../env.service';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {
  baseApiUrl: string = environment.appGatewayUrl;
  public _auditSubject = new BehaviorSubject<any>('');

  constructor(private http: HttpClient, private env: EnvService) { }

  public getAuditListById(id: any, pageNumber: number, pageSize: number, filterSearchValue: string, sortingColumn: string,auditDateFrom:string,auditDateTo:string) {
    return this.http.get(this.baseApiUrl + 'api/Audit/EntityType', { params: { 'Entitytype': id ,'pageNumber': pageNumber, 'pageSize': pageSize,'filter': filterSearchValue, 'soryBy': sortingColumn,'auditDateFrom':auditDateFrom,'auditDateTo':auditDateTo}, observe: 'response' })
  }

}
