import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class EditInstanceGuard  {

  constructor(
    private router: Router,
    private permssionService: PermissionsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {
    
    if (this.permssionService.getPermission('EditInstance')) {
      return true;
    }

    this.router.navigate(['home/access-denied']);

    return false;

  }
  
}
