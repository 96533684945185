import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-management',
  templateUrl: './partner-management.component.html',
  styleUrls: ['./partner-management.component.scss']
})
export class PartnerManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
