import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-custom-date',
  templateUrl: './custom-date.component.html',
  styleUrls: ['./custom-date.component.scss']
})
export class CustomDateComponent {
@Input() isFixedDateDisabled!:boolean;
@Input() isCustomDateDisabled!:boolean;
@Output() outputData = new EventEmitter<any>();
@Input() parentEvent !: any;
@Input() moduleName : any;
maxDate :any=new Date();
minDate: any =new Date();
customDateFilter: FormGroup = new FormGroup({
  start: new FormControl<Date | null>(null),
  end: new FormControl<Date | null>(null)
});
  storeData: any;
constructor
(
  private datePipe: DatePipe,
){}
ngOnInit(): void { 
  if (this.moduleName == 'customer-management') {
    let data:any = localStorage.getItem('customerFilterData');
    this.storeData = JSON.parse(data);
  }
  else if(this.moduleName == 'user-management') {
    let userData:any = localStorage.getItem('userFilterData');
    this.storeData = JSON.parse(userData);
  }
  
  if(this.storeData && this.storeData.dateType == 'custom'){
    this.customDateFilter.patchValue({
      start: this.storeData?.customerCreateDateFrom? new Date(this.storeData?.customerCreateDateFrom): '',
      end: this.storeData?.customerCreateDateTo? new Date(this.storeData?.customerCreateDateTo): ''
    });
  }
  this.minDate.setMonth(new Date().getMonth()-6);
 }

 ngOnChanges(){
  if(this.parentEvent == 'Fixed Date'){
   this.customDateFilter.reset();
  }
 }

transformDate(date:any) {
  return this.datePipe.transform((date.toISOString()), 'YYYY-MM-dd');

}

doFilter(event:any){
  if (this.customDateFilter.value.start != "" && this.customDateFilter.value.end != "" && this.customDateFilter.value.start != null && this.customDateFilter.value.end != null) {
   let data = {
    dateType: 'custom',
    customerCreateDateFrom : this.transformDate(this.customDateFilter.value.start),
    customerCreateDateTo : this.transformDate(this.customDateFilter.value.end)
   }
   this.outputData.emit(data);
  }
}
}
