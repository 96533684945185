<app-pageheader [pageTitle]="'Role Management / Edit Role'" [auditLog]="false" [createRole]="false" [mapRole]="true" [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
  <div class="form-wrapper">
    <h2>{{pageHeading}}</h2>
    <!-- <div class="pss-info-widget">
      <mat-icon class="icon">info</mat-icon>
      <p>To edit existing role please enter Name, Description, Category</p>
    </div> -->

    <form class="pss-dashboard-form" [formGroup]="frmRole">
      <mat-form-field class="pss-block-form">
        <mat-label>{{labelName}}</mat-label>
        <input type="text" matInput maxlength="100"   placeholder="Enter Name of Role" formControlName="Name" required>
        <mat-error *ngIf="frmRole.controls.Name.invalid">{{errorName}}</mat-error>
      </mat-form-field>

      <mat-form-field class="pss-block-form">
        <mat-label>{{labelDescription}}</mat-label>
        <textarea class="idn-min-textarea-height" matInput maxlength="500" placeholder="Description of Role" formControlName="Description" required></textarea>
        <mat-error *ngIf="frmRole.controls.Description.invalid">{{errorDescription}}</mat-error>
      </mat-form-field>

      <mat-form-field class="pss-block-form">
        <mat-label>Role Category</mat-label>
        <mat-select formControlName="UserCategoryId" aria-hidden="true" required>          
          <mat-option *ngFor="let role of roleCategory" [value]="role.id">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="pss-form-button-wrapper">
  <button mat-stroked-button class="pss-btn-danger" (click)="DeactivateRole()" *ngIf="!IsActive && isRoleDeactivate">
    <mat-icon>block</mat-icon> <span>{{actionDeactivate}}</span>
  </button>
  <button mat-stroked-button class="pss-btn-success"  (click)="ActivateRole()" *ngIf="IsActive && isRoleActivate">
    <mat-icon>check</mat-icon> <span>{{actionActivate}}</span>
  </button>
  <button mat-raised-button color="primary"  [disabled]="!frmRole.valid || IsActive" (click)="updateRole()">{{actionSave}}</button>
  <button mat-stroked-button (click)="OnCancel()">{{actionCancel}}</button>
</div>
