<!-- <div class="pss-loader" *ngIf="loading$ | async">
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div> -->

<div class="idn-loader-overview" *ngIf="loading$ | async">
  <div class="pss-loader-v2" ><mat-spinner [strokeWidth]="3" [diameter]="32"></mat-spinner><p>Please wait...</p></div>
</div>

<mat-toolbar color="primary" class="idn-custom-header">
  <mat-toolbar-row>
    <button mat-icon-button aria-label="Navigation" class="pss-button-header-focus" matTooltip="{{ toggleTooltip }}  Navigation" matTooltipClass="idn-tooltip" (click)="toggleMenu()" *ngIf="!whatsNew">
      <mat-icon class="idn-header-menu">menu</mat-icon>
    </button>
    <img class="pss-brand" src="../../../assets/images/logo/Icertis_logo.svg" alt="Icertis Logo" role="button" aria-label="Back to Home" (click)="navigateItem('resources')" *ngIf="!whatsNew">
    <span class="menu-spacer"></span>

    <button mat-button aria-label="Go to Service Now" class="idn-appbar-rounded-btn" (click)="redirectToHome()" *ngIf="whatsNew">
      <mat-icon>home</mat-icon>
      <span>Home</span>
    </button>
    <button mat-button aria-label="Go to Service Now" class="idn-appbar-rounded-btn" *ngIf="hasSupportAccess" (click)="redirectToSupport()">
      <mat-icon>support_agent</mat-icon>
      <span>IDN Support</span>
    </button>

    <button class="mr-1 pss-button-header-focus" mat-icon-button aria-label="Check Notification" matTooltip="Notifications" matTooltipClass="idn-tooltip"  [matMenuTriggerFor]="notification">
      <mat-icon *ngIf="badgeCount <= 99" matBadge="{{badgeCount}}"  class="idn-badgage">notifications</mat-icon>
      <mat-icon *ngIf="badgeCount > 99" matBadge="99+"  class="idn-badgage">notifications</mat-icon>
    </button>
    <mat-menu #notification="matMenu" xPosition="before" >
      <div class="header" role="menuitem" >
        <h3>Notifications</h3>
        <button [disabled]="userNotificationData.length == 0" mat-icon-button color="primary" matTooltip="Mark all as read" matTooltipClass="idn-tooltip" (click)="clearNotification()" aria-label="Done button">
          <mat-icon>done_all</mat-icon>
        </button>
      </div>
      <div class="pss-portal-notification"  >
        <!-- empty notification list  -->
        <div class="idn-notification-empty"  *ngIf="userNotificationData.length == 0" role="menuitem">
        <!-- <div class="idn-notification-empty" > -->
          <mat-icon svgIcon="empty_notification_icon"></mat-icon>
          <h5>We do not have any new notification to display here</h5>
        </div>

        <!-- Notification List -->
        <div >
          <mat-list-item *ngFor ="let x of userNotificationData" role="menuitem" aria-label="Notification List Box">
            <div class="pss-list-items">
              <div>
                <h4 class="new overflow-data">{{x.eventTitle}}</h4>
                <em>{{x.eventDate | date:'dd MMM yyyy'}} at {{x.eventDate | date:'HH:mm' }} (UTC) </em>
              </div>
              <button mat-icon-button color="primary" (click) ="removeNotification(x)" aria-label="check Button">
                <mat-icon>check_circle</mat-icon>
              </button>
            </div>
          </mat-list-item>

        </div>
        <div class="pss-menu-item-footer" role="menuitem">
          <a mat-button class="pss-button-block pss-button-link" (click)="navigateItem('notification')">View all Notifications</a>
        </div>
      </div>

    </mat-menu>

    <button mat-stroked-button [matMenuTriggerFor]="userMenu" class="pss-button-avatar pss-button-header-focus">
      <span class="pss-avatar">{{userAvatar}}</span>
      {{Username()}}
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #userMenu="matMenu" xPosition="before">
      <!-- <button mat-menu-item>
        <mat-icon>person</mat-icon> Profile
      </button>
      <button mat-menu-item>
        <mat-icon>lock</mat-icon> Change Password
      </button> -->
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon> Logout
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
<div class="workbench-release" *ngIf="releaseLink && !removeWBReleasebanner">
  <div class="link"><b>New Version Alert:</b>&nbsp; Upgrade IDN Workbench now for uninterrupted access to ICI Hooks. To proceed, view the&nbsp;<a href="#"
    (contextmenu)="redirectToAnnounceMents()"
    (click)="redirectToAnnounceMents(); $event.preventDefault();">
    <b>help</b>
  </a>.</div>
  <button mat-icon-button aria-label="close" (click)="closeReleaseBanner();">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-sidenav-container [ngClass]="{'pss-dashboard-container-banner': (releaseLink && !removeWBReleasebanner) == true, 'pss-dashboard-container': (releaseLink && !removeWBReleasebanner) == false }">

  <mat-sidenav #sidenav mode="side" [opened]="isOpened" class="pss-sidenav" role="navigation" *ngIf="!whatsNew">  
    <mat-selection-list #navItems [multiple]="false" aria-label="IDN Main Navigation Links">
      <ng-container *ngFor="let item of menuItems">
        <mat-list-option class="idn-nav-transition" id="{{item.id}}"  routerLinkActive="active" [routerLink]="item.subMenu? [] : item.path" [routerLinkActiveOptions]="{exact: false}" (click)="item.subMenu? '' : navigateItem(item.path)" (keyup.enter)="item.subMenu ? '' :navigateItem(item.path)" >
          <div class="nav-items" *ngIf="!item.subMenu" (click)="item.label =='My Tasks'? getPendingCount() :''">
            <span *ngIf="item.icon !== 'deployment' && item.label != 'Multi Tenant-PODs' && item.label != iciComponentDeploymentEnum.componentDeployment_name "><mat-icon class="icon" >{{item.icon}}</mat-icon> {{item.label}}</span>
            <span *ngIf="item.label == 'Multi Tenant-PODs' || item.label == iciComponentDeploymentEnum.componentDeployment_name"><mat-icon class="icon">{{item.icon}}</mat-icon> {{item.label}} <span class="beta-tag">(Beta)</span></span>
            <span *ngIf="item.icon == 'deployment' && item.label != 'Multi Tenant-PODs'"><mat-icon class="icon" svgIcon="{{item.icon}}"></mat-icon> {{item.label}}</span>
            <span *ngIf="item.icon == 'fact_check'&& item.label =='My Tasks'" class="count">{{count}}</span>
          </div>
          <div class="nav-items" *ngIf="item.subMenu" (click)="toggleItems(item.path)" [class.active]="toggleMenuContainer[item.path]" >
            <span><mat-icon class="icon" svgIcon="{{item.icon}}"></mat-icon>{{item.label}}<mat-icon class="has-child" *ngIf="!toggleMenuContainer[item.path]">expand_more</mat-icon> <mat-icon class="has-child" *ngIf="toggleMenuContainer[item.path]">expand_less</mat-icon></span>
          </div>
          
        </mat-list-option>
        <ng-container *ngIf="toggleMenuContainer[item.path]">
          <ng-container *ngFor="let menu of item.subMenu">
            <mat-list-option class="idn-nav-transition" *ngIf="menu" id="{{menu.id}}" routerLinkActive ='active'  [routerLink]="menu.subMenu? [] : menu.path"  [routerLinkActiveOptions]="{exact: false}"  (click)="menu.subMenu? '' :navigateItem(menu.path)" (keyup.enter)="menu.subMenu ? '':navigateItem(menu.path)"  >
              <div class="nav-items" *ngIf="!menu.subMenu" (click)="navigateItem(menu.path)" id="menu-label"> {{menu.label}} </div>
            </mat-list-option>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-selection-list>
    
    <div class="idn-dashboard-terms">
      
      <a mat-button href="javascript:void(0);"  (click)="openTerms()">Terms of Use</a>
     <a mat-button href="javascript:void(0);"  (click)="openAbout()">About</a>
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="pss-main-content " role="main">
    <div class="iframe-style" *ngIf="whatsNew">
      <iframe #iframeElement [src]="urlSafe" style="height: 100%;width: 100%;" (load)="onIframeLoad()" ></iframe>
    </div>
    <router-outlet *ngIf="!whatsNew"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-about (closeD)="closedrawer($event)" *ngIf="isAboutDrawer" >
  <app-about>