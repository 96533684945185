<div class="table-scroll certification-list-table">
    <table mat-table [dataSource]="dataSource" matSort class="idn-grid-global" style="width: 100%" *ngIf="!noCertification; else notFound" (matSortChange)="announceSortChange($event)">

    <!-- Extension Name Column -->
    <ng-container matColumnDef="extensionName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Extension Name">
           {{certificationEnum.certification_List_Header_extensionName}}
        </th>
        <td mat-cell *matCellDef="let element"> <span> {{element.extensionName}} </span> </td>
    </ng-container>

    <!-- Package Name Column -->
    <ng-container matColumnDef="packageName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Package Name">
            {{certificationEnum.certification_List_Header_packageName}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.packageName }} </td>
    </ng-container>

    <!-- Partner Name Column -->
    <ng-container matColumnDef="partnerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by partner Name">
            {{certificationEnum.certification_Request_View_Field_partnerName}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.partnerName? element.partnerName :'NA'}} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div class="pckg-status-col"><span mat-sort-header sortActionDescription="Sort by Status" aria-hidden="true">{{certificationEnum.certification_List_Header_status}}</span>
            <button mat-button (click)="refresh()" title="Refresh package status">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="status-icons">
            <div [ngClass]="{'certified': element.status == certificationStatus.certified, 'rejected': element.status == certificationStatus.rejected, 'system-check-failed': element.status == certificationStatus.systemCheckFailed, 'review-requested': element.status == certificationStatus.reviewRequested, 'system-check-success': element.status == certificationStatus.systemCheckSuccess, 'certification-requested': element.status == certificationStatus.certificationRequested, 'review-in-progress': element.status == certificationStatus.reviewInProgress}">
                <mat-icon *ngIf="element.status == certificationStatus.systemCheckSuccess">check_circle_outline</mat-icon>
                <mat-icon class="green" *ngIf="element.status == certificationStatus.certified">verified_user</mat-icon>
                <mat-icon *ngIf="element.status == certificationStatus.systemCheckFailed">error_outline</mat-icon>
                <mat-icon *ngIf="element.status == certificationStatus.reviewRequested">start</mat-icon>
                <mat-icon *ngIf="element.status == certificationStatus.rejected">gpp_bad</mat-icon>
                <mat-icon *ngIf="element.status == certificationStatus.reviewInProgress">downloading</mat-icon>
                <mat-icon *ngIf="element.status == certificationStatus.certificationRequested">rotate_right</mat-icon>
                {{element.status}}
                <span matTooltip="Withdrawn by {{element.requestWithdrawerName}} on {{ element.requestWithdrawnOn | date:'d MMMM, y h:mm \'(UTC)\'' }}">
                  <mat-icon *ngIf="element.status == certificationStatus.systemCheckSuccess && (element.requestWithdrawerName && element.requestWithdrawnOn ? true:false) ">transit_enterexit</mat-icon>
                </span>
                <ng-container *ngIf="(element.status == certificationStatus.certified) && (element.isExceptionalApproval == true)">
                    <mat-icon class="material-symbols-outlined exception-approval-message" matTooltip="Exception Approval Certified"> info_outline </mat-icon>
                </ng-container>
                <ng-container *ngIf="(element.status == certificationStatus.rejected) && (element.isExceptionalApproval == true)">
                    <mat-icon class="material-symbols-outlined exception-approval-message" matTooltip="Exception Approval Rejected"> info_outline </mat-icon>
                </ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Action" class="action-cell">
             {{certificationEnum.certification_List_Header_actions}}             
        </th>
        <td mat-cell *matCellDef="let element" class="actions-icons">
            <mat-icon (click)="viewCertificationDetails(element)">visibility_outline</mat-icon>
            <mat-icon [matMenuTriggerFor]="gridMenu" aria-label="Extension Actions" matTooltip="Package Actions">more_horiz</mat-icon>
            <mat-menu #gridMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="extDetailsData.status == 1 ? downloadFiles(element) : ''" [disabled]="!(extDetailsData.status == 2 ? false : true)">
                <mat-icon>download</mat-icon> {{certificationEnum.certification_List_ActionLabel_dowloadFile_btn}}
              </button>
              <button class="initiate-certification-btn" mat-menu-item *ngIf="element.status == certificationStatus.systemCheckFailed && isExceptionalApproval" (click)="initiateManualCertification(element)">
                  <mat-icon>start</mat-icon>{{certificationEnum.certification_List_Field_initiateManual_btn}} (Exception Approval)
              </button>
              <button class="initiate-certification-btn" mat-menu-item *ngIf="element.status == certificationStatus.systemCheckSuccess && isInitiateManualCertification && (element?.partnerId == loggedInUserPartnerId || hasGlobalDataAccess)" (click)="initiateManualCertification(element)">
                  <mat-icon>start</mat-icon>{{certificationEnum.certification_List_Field_initiateManual_btn}}
              </button>
              <button mat-menu-item *ngIf = "(element.status == certificationStatus.reviewRequested) && ((element.requesterEmail == loggedinUserEmail) || (hasGlobalDataAccess && isInitiateAutomaticCertificationPermission)) && (!element.isExceptionalApproval) && (element.veracodeScanStatus == 'In Progress' || element.veracodeScanStatus == 'InitialState' || element.veracodeScanStatus == null)"
              (click)="withdrawRequest(element)">
              <mat-icon>transit_enterexit</mat-icon>
              {{certificationEnum.certification_approver_List_Field_withdraw_request}}
              </button>              
            </mat-menu>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<mat-paginator [ngClass]="{'hide-pagination': noCertification ? true : false}" #paginator [pageSize]="10" [pageIndex]="currentPage" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
<app-intiate-certification *ngIf="intiateCertificationActive" [certDetailsData]="certDetails" ></app-intiate-certification>
<app-download-files *ngIf="downloadFilesActive"></app-download-files>

<ng-template #notFound>
    <div class="idn-no-result">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
          </clipPath>
        </defs>
        <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
          <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
          <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
          <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
          <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
          <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
          <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
            <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
            <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
          </g>
          <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
            <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
          </g>
        </g>
      </svg>
      <h4>{{ extensionEnum.certification_no_result_msg }}</h4>
    </div>
  </ng-template>

