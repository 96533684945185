import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnyCnameRecord } from 'dns';
import { Observable,BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  public _subject = new BehaviorSubject<any>('');

  constructor(
    private http: HttpClient
  ) { }

  emit<T>(data: T) {
    this._subject.next(data);
  }

  public GetUser(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number,isActive:any,roleId:any,partner?:any,customer?:any,idnCertified?:any,status?:any,userCreatedDateFrom?:any,userCreatedDateTo?:any,userCategoryId?:any) {
    return this.http.get(this.baseApiUrl + 'api/User', { params: {'filter': filterSearchValue, 'sortBy': sortingColumn,'pageNumber': pageNumber, 'pageSize': pageSize,'isActive': isActive,'roleId': roleId, 'partner':partner?partner : '', 'customer':customer?customer : '','isIDNCertified':idnCertified == true? idnCertified : idnCertified == false ? idnCertified : '' ,'status':status? status:'','userCreatedDateFrom': userCreatedDateFrom ? userCreatedDateFrom : '' , 'userCreatedDateTo':userCreatedDateTo ? userCreatedDateTo :'','userCategoryId':userCategoryId} })
  }
  
  public getUserSnapshot() {
    return this.http.get(this.baseApiUrl + 'api/User/GetUserSnapshot')
  }
  
  public PostData(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }
  public GetUserRoles(id:any){
    return this.http.get(this.baseApiUrl + 'api/User/UserRoles',{params:{'userCategoryId':id}});
  }
  public GetUserRolesForFilter(id:any){
    return this.http.get(this.baseApiUrl + 'api/User/UserRolesForFilter',{params:{'userId':id}});
  }

  public GetUserCategories(){
    return this.http.get(this.baseApiUrl + 'api/User/UserCategories')
  }
  public GetUserById(id:number) {
    return this.http.get(this.baseApiUrl + 'api/User/'+id)
  }
  public deActivateRole(id:any): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/User/'+id+'/Deactivate', {}, { params: {}, observe: 'response' });
  }
  public ActivateRole(id:any): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/User/'+id+'/Activate', {}, { params: {}, observe: 'response' });
  }
  public UpdateUser(id:any, paraBody?: any): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/User/'+id, paraBody, { params: {}, observe: 'response' });
  }
  public CheckUserEmailIdExist(email:string){
    return this.http.get(this.baseApiUrl + 'api/User/CheckIfUserEmailIdExists',{ params: { 'emailId': email }, observe: 'response' })
  }
  public GetPartnerList(){
    return this.http.get(this.baseApiUrl + 'api/User/Partners')
  }
  public GetCustomerListBasedOnPartner(userCategoryId?:any,partnerId?:any, userId?:any){
    return this.http.get(this.baseApiUrl + 'api/User/Customers',{ params: { 'userCategoryId': userCategoryId? userCategoryId: '' ,'partnerId': partnerId? partnerId: '' , 'userId':userId? userId :'' } , observe: 'response'})
  }

  public GetBulkCreateTemplateDownloadConnection() {
    return this.http.get(this.baseApiUrl + 'api/User/GetBulkCreateTemplateDownloadConnection');
  }

  public GetBulkUploadConnection() {
    return this.http.get(this.baseApiUrl + 'api/User/GetBulkUploadConnection');
  }

  public putDataUrl(url: string,paraData?: any,paraBody?: any): Observable<any> {
    return this.http.put(this.baseApiUrl + url, paraBody, 
      {headers: this.headers,params: paraData,observe: 'response'});
  }

  public postDataUrl(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, 
      { headers: this.headers, params: paraData, observe: 'response' });
  }

  public GetBulkUserHistoryLogs(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number) {
    return this.http.get(this.baseApiUrl + 'api/User/GetBulkUserHistoryLogs', { params: {'filter': filterSearchValue, 'sortBy': sortingColumn,'pageNumber': pageNumber, 'pageSize': pageSize} })
  }

  public GetBulkUploadInputFileDownloadConnection(id: any, filename: any) {
    return this.http.get(this.baseApiUrl + 'api/User/GetBulkUploadInputFileDownloadConnection', { params: { 'id': id, 'filename': filename }, headers: this.headers })
  }

  public GetBulkUploadErrorLogDownloadConnection(id: any) {
    return this.http.get(this.baseApiUrl + 'api/User/GetBulkUploadErrorLogDownloadConnection/'+id, {headers: this.headers })
  }

  public CheckBlobFileExist(id:any,filename:any) {
    return this.http.get(this.baseApiUrl + 'api/User/CheckBlobFileExist', { params: { 'uniqueidentifier': id, 'filename': filename }, headers: this.headers });
  }
  
  public GetIDNConfiguration(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/PublicPortal/GetIDNConfiguration', { observe: 'response' })
  }

  // get user count by partner id
  public getUserCountByPartnerId(partnerId?:any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/User/UserCountByPartnerId', { params: { partnerId: partnerId }, observe: 'response' })
  }

  public userExport(filterParams:any): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/User/UserExport',filterParams,{ headers: this.headers , observe: 'response' })
  }

}
