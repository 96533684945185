import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { IciWorkertaskConfigurationRoutingModule } from './ici-workertask-configuration-routing.module';
import { ListIciWorkertaskConfigurationComponent } from './list-ici-workertask-configuration/list-ici-workertask-configuration.component';
import { ViewIciWorkertaskConfigurationComponent } from './view-ici-workertask-configuration/view-ici-workertask-configuration.component';
import { EditIciWorkertaskConfigurationComponent } from './edit-ici-workertask-configuration/edit-ici-workertask-configuration.component';



@NgModule({
  declarations: [
    ListIciWorkertaskConfigurationComponent,
    ViewIciWorkertaskConfigurationComponent,
    EditIciWorkertaskConfigurationComponent
  ],
  imports: [
    IciWorkertaskConfigurationRoutingModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    PageHeaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
  ]
})
export class IciWorkertaskConfigurationModule { }
