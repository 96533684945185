<div class="pss-page-header">
  <div class="header-items-left">
    <button #skipHere class="pss-button-primary pss-button-primary__rounded pss-dash-back-button page-header-mt-0" aria-label="Home" matTooltip="Home" matTooltipClass="idn-tooltip" (click)="navigateItem('resources')">
      <mat-icon>home</mat-icon>
    </button>
    <ul class="idn-breadcrumbs">
      <li *ngFor="let item of breadCrumbs; let indexOfelement = index;">
        <a *ngIf="indexOfelement < breadCrumbs.length -1" href="javascript:void(0)" (keyup.enter)="navigateItem(item.path)" (click)="navigateItem(item.path)"><span>{{item.label}}</span></a>
        <a *ngIf="indexOfelement == breadCrumbs.length -1" href="javascript:void(0)"><span class="idn-bc-last-item">{{item.label}}</span></a>
      </li>
    </ul>
  </div>
  <div class="header-items-right">
    <button (click)="navigateItem('administration/role-management/create')" class="idn-primary-button" *ngIf="createRole && isCreateRole"  mat-stroked-button color="primary">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>


    <button (click)="navigateItem('notification/archive')" *ngIf="archivePage" mat-flat-button color="primary">
      <span>Go to Archive</span>
    </button>

    <button (click)="navigateItem('notification')" *ngIf="notificationPage" mat-flat-button color="primary">
      <span>Back to Notification</span>
    </button>

    <button (click)="confirmArchiveBulk()" *ngIf="unArchive"  mat-stroked-button>
      <mat-icon class="icon-btn-spacer material-icons-outlined">folder_zip</mat-icon>
      <span>Un-Archive Selected</span>
    </button>

    <button (click)="navigateItem('administration/customer-management/create')" class="idn-primary-button" *ngIf="createCustomer"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>

    <button (click)="navigateItem('administration/partner-management/create')" class="idn-primary-button" *ngIf="createPartner"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>

    <button (click)="navigateItem('web-app-management/create')" class="idn-primary-button" *ngIf="createWebApp"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>

    <button (click)="navigateItem('sku-management/ici-component-deployment/create')" class="idn-primary-button" *ngIf="requestIciSkuDeployment"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>

    <button (click)="navigateEditItem('administration/partner-management/edit')" *ngIf="editPartner"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateEditItem('developmentandutilities/idn-workbench-management/edit')" *ngIf="editSDK && isEditWorkbench"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateEditItem('administration/customer-management/edit')" *ngIf="editCustomer"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateEditItem('administration/role-management/edit')" *ngIf="editRole"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateEditItem('developmentandutilities/extension-management/edit')" *ngIf="editExtension"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateEditItem('web-app-management/edit')" *ngIf="editWebApp"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateItem('instanceoperations/instance-requests/create')" *ngIf="createInstance" class="idn-primary-button"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Request Instance</span>
    </button>

    <button (click)="navigateItem('instanceoperations/instance-requests/history')" *ngIf="instanceTools" class="idn-primary-button"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">history</mat-icon>
      <span>History</span>
    </button>

    <button (click)="openCertificationDrawer()" *ngIf="requestCertification" class="idn-primary-button"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Request Certification</span>
    </button>

    <button *ngIf="deployment" mat-stroked-button color="primary" (click)="navigateItem(!isApproveInstance?'instanceoperations/instance-requests/deployment':'instanceoperations/instance-requests/deployment')">
      <mat-icon svgIcon="deployment" class="icon-btn-spacer"></mat-icon>
      <span>Package Deployment</span>
    </button>

    <button (click)="navigateItem('instanceoperations/instance-requests/edit')" *ngIf="editInstance"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateItem('sku-management/sku-master/edit')" *ngIf="editSku"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

    <button (click)="navigateItem('administration/user-management/edit')" *ngIf="editUser"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>


    <button (click)="navigateItem('developmentandutilities/extension-management/create')" *ngIf="createextension" class="idn-primary-button"  mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>

    <button (click)="navigateItem('referencedocuments')" *ngIf="ReferenceDocumentsExtension"  mat-stroked-button color="primary">
      <mat-icon class="icon-btn-spacer">assignment</mat-icon>
      <span>Reference Documents</span>
    </button>

    <button (click)="navigateItem('developmentandutilities/idn-workbench-management/create')" class="idn-primary-button" *ngIf="uploadSdk && isCreateWorkbench" mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>

    <button (click)="navigateItem('administration/user-management/create')" class="idn-primary-button" *ngIf="addUser" mat-stroked-button color="accent">
      <mat-icon class="icon-btn-spacer">add_circle_outline</mat-icon>
      <span>Create</span>
    </button>  

    <!-- <button *ngIf="auditLog" mat-stroked-button color="primary" (click)="navigateItem('audit-log')"> -->
    <button *ngIf="auditLog" mat-stroked-button color="primary" (click)="toauditLogList('audit-log',auditLogData)">
      <mat-icon class="icon-btn-spacer">notes</mat-icon>
      <span>Audit Log</span>
    </button> 

    <button mat-stroked-button color="primary" *ngIf="viewLogsUser" mat-button [matMenuTriggerFor]="bulkActionUser">Bulk Action <span><mat-icon class="mat-icon-arrow-down-customer">arrow_drop_down</mat-icon></span></button>
      <mat-menu #bulkActionUser="matMenu">
      <button mat-menu-item *ngIf="addUser" value="create" (click)="navigateBulkAction('create')" >Create</button>
      <button mat-menu-item value="viewlogs" (click)="navigateBulkAction('viewlogs')" >View Logs</button>
      <button mat-menu-item value="Export" [matMenuTriggerFor]="ExportUserFilter">Export</button>
      </mat-menu>      
      <mat-menu #ExportUserFilter="matMenu">
        <button mat-menu-item value="xlsx" (click)="navigateActionForExportType($event)" > XLSX </button>
        <button mat-menu-item  value="csv" (click)="navigateActionForExportType($event)"> CSV </button>
      </mat-menu>

    <button mat-stroked-button color="primary" *ngIf="viewLogsCustomer" mat-button [matMenuTriggerFor]="bulkActionCustomer" >Bulk Action <span><mat-icon class="mat-icon-arrow-down-customer">arrow_drop_down</mat-icon></span></button>
    <mat-menu #bulkActionCustomer="matMenu" >
      <button mat-menu-item value="Export" [matMenuTriggerFor]="Export">Export</button>
      <button mat-menu-item value="viewLogs" (click)="navigateActionForExportType('View Logs')" >View Logs</button>
    </mat-menu>
    <mat-menu #Export="matMenu">
      <button mat-menu-item value="xlsx" (click)="navigateActionForExportType($event)" > XLSX </button>
      <button mat-menu-item  value="csv" (click)="navigateActionForExportType($event)"> CSV </button>
    </mat-menu>


    <button *ngIf="clearNotification" mat-stroked-button color="primary">
      <mat-icon class="icon-btn-spacer">delete_outline</mat-icon>
      <span>Clear Notifications</span>
    </button>

    <button mat-stroked-button color="primary" *ngIf="clearFilter" [disabled]="clearFilterButton" (click)="clearFIlteredList()">
      <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
      <span>Clear Filter</span>
    </button>

    <button mat-stroked-button color="primary" *ngIf="applyFilter" class="page-header-select-action" (click)="toggleFilterDrawer()">
      <mat-icon class="icon-btn-spacer">filter_list</mat-icon>
      <span class="icon-btn-spacer">Filter</span>
    </button>

    <button mat-stroked-button *ngIf="archive" class="page-header-select-action" [matMenuTriggerFor]="archiveMenu">
      <mat-icon class="icon-btn-spacer">folder_zip</mat-icon>
      <span class="icon-btn-spacer">Archive</span>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>

    <mat-menu #archiveMenu="matMenu" xPosition="before">
      <button mat-menu-item>Older than a Month</button>
      <button mat-menu-item (click)="confirmArchive()">Older than 3 Months</button>
      <button mat-menu-item>Older than 6 Months</button>
    </mat-menu>

    <button mat-stroked-button class="idn-primary-button idn-drop-button" color="accent" *ngIf="createWorkerTask" mat-button [matMenuTriggerFor]="workerTask" >Create Task<span><mat-icon class="mat-icon-arrow-down-customer">arrow_drop_down</mat-icon></span></button>
    <mat-menu #workerTask="matMenu" >
      <button mat-menu-item value="eventTask" (click)="navigateCreateWorkerTask('Event')" >Event Task</button>
      <button mat-menu-item value="sechduleTask" (click)="navigateCreateWorkerTask('Schedule')" >Scheduled Task</button>
    </mat-menu>

    <button (click)="navigateEditWorkerTask('ici-workertask-configuration/edit')" *ngIf="editWorkerTask"  mat-stroked-button color="accent" class="idn-edit-btn">
      <mat-icon class="icon-btn-spacer">edit</mat-icon>
      <span>Edit</span>
    </button>

  </div>
</div>

<mat-drawer-container class="idn-drawer-internal" *ngIf="isFilterDrawer">
  <mat-drawer #drawerFilter class="idn-drawer">
    <div class="header">
      <div class="heading">
          <mat-icon>web_stories</mat-icon>
          <h2>Notification Filter</h2>
      </div>
      <button mat-icon-button (click)="toggleFilterDrawer()">
          <mat-icon>close</mat-icon>
      </button>
  </div>
  <form [formGroup]="notificationFilter">
  <div class="body">
    <div class="page-container">
      <div class="list-header">
        <h3>Filter by Date</h3>
      </div>
      <mat-form-field>
        <mat-label>Choose Date</mat-label>
        <mat-select formControlName="byMonths" #month >
          <mat-option *ngFor="let option of options" [value]="option.value" (click)="clearInputFields('byMonthsNotif')">
            {{option.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>custom date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
          <input matStartDate formControlName="start" placeholder="Start date" value="startDate" #start>
          <input matEndDate formControlName="end" placeholder="End date" value="endDate" #end>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker" (click)="clearInputFields('datePickerNotif')"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

  </div>
  <div class="pss-drawer-button-wrapper">
    <button mat-flat-button color="primary" (click)="notificationFilterList()" [disabled]="!(notificationFilter.controls['byMonths'].value || (notificationFilter.controls['start'].value && notificationFilter.controls['end'].value))">
        Apply Filter
    </button>
      <button mat-flat-button (click)="toggleFilterDrawer()">Cancel</button>
  </div>
</form>
  </mat-drawer>
</mat-drawer-container>

<!-- User Selection Drawer -->
<mat-drawer-container class="idn-drawer-internal" *ngIf="isSelectionDrawer">
  <mat-drawer #drawerFilter class="idn-drawer">
    <div class="header">
      <div class="heading">
          <mat-icon>person</mat-icon>
          <h2>Add new user</h2>
      </div>
      <button mat-icon-button (click)="toggleSelectionDrawer()">
          <mat-icon>close</mat-icon>
      </button>
  </div>
  <div class="body">
    <div class="idn-grid-filter">
      <mat-form-field class="add-user-search">
        <mat-label>Search user</mat-label>
        <input matInput placeholder="Search for user by name or email address">
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
    </div>

    <div class="searching-user">
      <div class="outCircle">
        <div class="rotate">
          <div class="counterrotate">
            <div class="inner">
              <mat-icon svgIcon="searching"></mat-icon>
            </div>
          </div>
        </div>
      </div>
      <h4>Please wait...</h4>
      <p>Loading user list from Icertis AD</p>
    </div>

    <!-- <div class="idn-user-list">
      <ng-container *ngIf="selected">
        <div class="idn-list-customer" *ngFor="let customer of customers">
          <div class="logo">
            <mat-icon>person_outline</mat-icon>
          </div>
          <div class="info">
            <h4>Lorem Customer User</h4>
            <p>lorem.ipsum@accenture.com</p>
          </div>
          <button mat-icon-button color="primary" class="add-user-list-items" (click)="confirmAdd()">
            <mat-icon> add_circle</mat-icon>
          </button>
        </div>
      </ng-container>
      <div *ngIf="!selected">
        <div class="idn-list-customer">
          <div class="logo">
            <mat-icon>person_outline</mat-icon>
          </div>
          <div class="info">
            <h4>Lorem Customer User</h4>
            <p>lorem.ipsum@accenture.com</p>
          </div>
          <button mat-icon-button color="primary" class="add-user-list-items">
            <mat-icon> check_circle</mat-icon>
          </button>
        </div>
        <mat-form-field class="mt-1">
          <mat-label>User Role</mat-label>
          <mat-select>
            <mat-option>Icertis User</mat-option>
            <mat-option>Partner User</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div> -->

  </div>
  <div class="pss-drawer-button-wrapper">
    <button mat-flat-button color="primary" >
        Add Selected User
    </button>
      <button mat-flat-button (click)="toggleSelectionDrawer()">Cancel</button>
  </div>
  </mat-drawer>
</mat-drawer-container>
