import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Subscription } from 'rxjs';
import { ActivityNotificationService } from 'src/app/core/services/activity-notification/activity-notification.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { ShareDataService } from 'src/app/core/services/sdk-mangement/share-data.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { WorkbenchMgmtStaticString } from 'src/app/shared/enum/workbenchManagement';
import { MatIconRegistry } from '@angular/material/icon';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`
export interface SDK {
  id: Number;
  name: string;
}
@Component({
  selector: 'app-view-sdk',
  templateUrl: './view-sdk.component.html',
  styleUrls: ['./view-sdk.component.scss']
})
export class ViewSdkComponent implements OnInit, OnDestroy {
  public WbMgmtEnum = WorkbenchMgmtStaticString;
  ViewData: any = { 'name': '', 'description': '', 'version': '', 'filename': '', 'releaseNote': '', 'releaseDate': '', 'endOfLifeDate': '', 'isActive': '', 'createdAt': '', 'createdByDisplayName': '', 'isFileUploaded': '' };
  UserId: any;
  isTrueSet: any = true;
  sdks: SDK[] = [];
  sas: any;
  $subs!: Subscription;
  Status: number = 2;
  isUserAssociate: boolean = false;
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: 'Development and Utilities', path: 'developmentandutilities/idn-workbench-management' },
    { label: 'IDN Workbench', path: 'developmentandutilities/idn-workbench-management' },
    { label: "View", path: "view" },
  ];
  isEditSDK: boolean = false;
  isViewSDK: boolean = false;
  isActivateSDK: boolean = false;
  isDeactivateSDK: boolean = false;
  hasGlobalDataAccess: boolean = false;

  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  dataSource: any
  modulevalue = '';
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  uploadsdkData: any = {};
  globalList: any;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('name') inputName: any;

  constructor(
    private SdkServices: SdkServiceService,
    private route: ActivatedRoute,
    private sharedata: ShareDataService,
    private router: Router,
    private titleService: Title,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private ApiCall: SdkServiceService,
    private permssionService: PermissionsService,
    private activityNotifService: ActivityNotificationService,
    private loader: LoaderService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }

  ngOnDestroy(): void {
    this.$subs.unsubscribe();
    if(window.location.pathname == '/home/idn-workbench-management/edit'){
      localStorage.removeItem('IDNManagementFilter');  
     }
  }

  ngOnInit(): void {
    //this.getSas();
    this.titleService.setTitle('View Workbench | IDN');
    this.isEditSDK = this.permssionService.getPermission('UpdateSDK');
    this.isViewSDK = this.permssionService.getPermission('ViewSDK');
    this.isActivateSDK = this.permssionService.getPermission('ActivateSDK');
    this.isDeactivateSDK = this.permssionService.getPermission('DeactivateSDK');
    this.hasGlobalDataAccess = this.permssionService.hasGlobalDataAccess();
    this.$subs = this.sharedata.receiveData().subscribe(data => {

      if (data) {
        let json = JSON.parse(data);
        if (json.id != null && json.id != undefined && json.id > 0) {
          this.UserId = json.id;
          this.Status = json.Status;
          localStorage.setItem("viewSDkID",this.UserId);
          this.checkUserAssociateWithSDK(this.UserId);
        }
        else {
          this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
        }
      }
      else {
        // this.router.navigate(["home/idn-workbench-management"]);
        if(this.UserId == undefined){
          let id=parseInt(localStorage.viewSDkID);
          this.checkUserAssociateWithSDK(id);
        }
       
      }
      

    });

  }

  checkUserAssociateWithSDK(id: number) {
    if (id != null && id != undefined && id > 0) {
      this.ApiCall.GetSDKById(id).subscribe(resp => {
        this.isUserAssociate = resp;
        this.ViewData = resp;
        this.UserId = resp.id;
      });
    }
    else {
      this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
    }
  }

  DeactivateSDKs() {
    if (this.UserId != 0) {
      try {

        // this.SdkServices.IsDownloaded(this.UserId).subscribe((res: Response) => {
        //   let Result: any = res.body;
        //   if (Result) {
        //     this.notificationService.showNotification("Cannot deactivate the IDN Workbench as it is already been downloaded by users.", 'warning', 'IDN Workbench not De-activated');
        //   } else {
        this.dialogService.confirmDialog({
          title: this.WbMgmtEnum.wb_dialog_deactivate_heading,
          module: 'sdk',
          message: this.WbMgmtEnum.wb_dialog_deactivate_body,
          confirmText: this.WbMgmtEnum.wb_label_deactivate_btn,
          cancelText: this.WbMgmtEnum.wb_label_cancel_btn,
        }).subscribe((res) => {
          if (res) {

            this.SdkServices.Deactivate(this.UserId).subscribe((resp: Response) => {
              if (resp == null) {
                this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_deactivated_body, 'success', this.WbMgmtEnum.wb_notify_deactivated_heading);
                this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
              } else {
                this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_deactivated_body, 'warning', this.WbMgmtEnum.wb_notify_not_deactivated_heading);
                this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
              }

            })

          }
        })
        //   }

        // })

      }
      catch (error) {
        console.log(error)
      }
    }
  }

  ActivateSDKs() {
    if (this.UserId != 0) {
      try {
        // this.SdkServices.IsDownloaded(this.UserId).subscribe((res: Response) => {
        //   let Result: any = res.body;
        //   if (Result) {
        //     this.notificationService.showNotification("Cannot activate the IDN Workbench as it is already been downloaded by users.", 'warning', 'IDN Workbench not activate');
        //   } else {
        this.dialogService.confirmDialog({
          title: this.WbMgmtEnum.wb_dialog_activate_heading,
          module: 'sdk',
          message: this.WbMgmtEnum.wb_dialog_activate_body,
          confirmText: this.WbMgmtEnum.wb_label_activate_btn,
          cancelText: this.WbMgmtEnum.wb_label_cancel_btn,
        }).subscribe((res: any) => {
          if (res) {
            this.SdkServices.Activate(this.UserId).subscribe((resp: Response) => {
              if (resp == null) {
                this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_activated_body, 'success', this.WbMgmtEnum.wb_notify_activated_heading);
                this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
              } else {
                this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_activated_body, 'warning', this.WbMgmtEnum.wb_notify_not_activated_heading);
                this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
              }
            })

          }
        })
        //   }
        // })


      }
      catch (error) {
        console.log(error)
      }
    }
  }

  onCancel(val: any) {
    let navLink: any = 'home/developmentandutilities/' + val;
    this.router.navigate([navLink]);
  }


  edit(navLink: any, ViewData: any) {
    this.SdkServices.emitSDK<any>(ViewData);
    navLink = 'home/developmentandutilities/' + navLink;
    this.router.navigate([navLink]);
  }

  downloadFile(element: any) {
    try {
      this.loader.show();
      var FileSaver = require('file-saver');
      this.SdkServices.getBlobDownloadConnection(element.id, element.fileName).subscribe((resp: any) => {
        if (resp != null && resp != undefined) {

          let fileSize = resp.blobSize / (1024 * 1024);  //convert byte to MB          

          if(fileSize >= 5){
            this.notificationService.showNotification(
              this.WbMgmtEnum.wb_notify_download_start_body,
              'success',
              this.WbMgmtEnum.wb_notify_download_start_heading
            );
          }
         
          setTimeout(() => {
            this.loader.hide();             
          }, 2000);

          this.blobService.downloadImageExt(resp.sasUrl, resp.container, resp.blob, (response: any) => {
            if (response._response != undefined && response._response.status == "200") {
              response.blobBody.then(
                (onres: any) => {
                  this.SdkServices.markDownloaded(element.id).pipe(map((data: any) => {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_mark_download_body, 'success', this.WbMgmtEnum.wb_notify_mark_download_heading);
                    this.activityNotifService.TriggerSDKNotification(element.id, false);
                  }), catchError((err) => {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_mark_download_body, 'error', this.WbMgmtEnum.wb_notify_not_mark_download_heading);
                    console.log(err);
                    return err;
                  })
                  ).subscribe();
                  FileSaver.saveAs(onres, element.fileName);
                })
            } else {
              this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_download_failed_body, 'error', this.WbMgmtEnum.wb_notify_download_failed_heading);
            }
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  downloadFile1(element: any){
     if(this.ViewData.status == 1 && this.ViewData.sdkStatus == "Active"){
      this.ApiCall.GetSDKById(this.ViewData.id).subscribe(resp => { 
        this.ViewData = resp;
          if(resp != null && resp != "" && resp.status == 1 && resp.sdkStatus == "Active"){  
              this.downloadFile(element);
          }else{
            this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_wb_already_deactivate_body, 'warning', this.WbMgmtEnum.wb_notify_wb_already_deactivate_heading);
          }
      });
     }
  }

  //To In-active SDK  Status
  deactivateSDK(id: number) {
    if (id != 0) {
      try {

        this.SdkServices.IsDownloaded(id).subscribe((res: Response) => {
          let Result: any = res.body;
          if (Result) {
            this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_deactivated_body, 'warning', this.WbMgmtEnum.wb_notify_not_deactivated_heading);
          } else {
            this.dialogService.confirmDialog({
              title: this.WbMgmtEnum.wb_dialog_deactivate_heading,
              module: 'sdk',
              message: this.WbMgmtEnum.wb_dialog_deactivate_body,
              confirmText: this.WbMgmtEnum.wb_label_deactivate_btn,
              cancelText: this.WbMgmtEnum.wb_label_cancel_btn
            }).subscribe((res) => {
              if (res) {

                this.SdkServices.Deactivate(id).subscribe((resp: Response) => {
                  if (resp == null) {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_deactivated_body, 'success', this.WbMgmtEnum.wb_notify_deactivated_heading);
                    this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
                  } else {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_deactivated_body, 'warning', this.WbMgmtEnum.wb_notify_not_deactivated_heading);
                    this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
                  }

                })

              }
            })
          }

        })

      }
      catch (error) {
        console.log(error)
      }
    }
  }

  //To Activate SDK Status
  activateSDK(id: number) {
    if (id != 0) {
      try {
        this.SdkServices.IsDownloaded(id).subscribe((res: Response) => {
          let Result: any = res.body;
          if (Result) {
            this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_activated_body, 'warning', this.WbMgmtEnum.wb_notify_not_activated_heading);
          } else {
            this.dialogService.confirmDialog({
              title: this.WbMgmtEnum.wb_dialog_activate_heading,
              module: 'sdk',
              message: this.WbMgmtEnum.wb_dialog_activate_body,
              confirmText: this.WbMgmtEnum.wb_label_activate_btn,
              cancelText: this.WbMgmtEnum.wb_label_cancel_btn
            }).subscribe((res: any) => {
              if (res) {
                this.SdkServices.Activate(id).subscribe((resp: Response) => {
                  if (resp == null) {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_activated_body, 'success', this.WbMgmtEnum.wb_notify_activated_heading);
                    this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
                  } else {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_activated_body, 'warning', this.WbMgmtEnum.wb_notify_not_activated_heading);
                    this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
                  }
                })

              }
            })
          }
        })


      }
      catch (error) {
        console.log(error)
      }
    }
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.WbMgmtEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.WbMgmtEnum.redirect_dialog_body,
        confirmText: this.WbMgmtEnum.redirect_dialog_primary_btn,
        cancelText: this.WbMgmtEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          window.open(
            'https://developer-help.icertis.com/docs/setting-up-idn-workbench/',
            '_blank'
          );
        }
      });
  }

}
