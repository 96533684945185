
<div class="idn-filter">

  <form class="idn-grid-filter">
    <mat-form-field class="idn-grid-filter-search">
      <mat-label>Search</mat-label>
      <input type="text" matInput (keydown.enter)="filter()" #searchInput matInput placeholder="Search by Package name and Partner name">
      <div class="idn-custom-search" matSuffix>
        <button type="button" mat-icon-button aria-label="search" (click)="filter()">
          <mat-icon>search</mat-icon>
        </button>
        <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{extensionEnum.extension_View_Field_status}}</mat-label>
      <mat-select (selectionChange)="filter()" #status>
        <mat-option [value]="selectAll">All</mat-option>
        <mat-option *ngFor="let type of PackageStatus" [value]="type.id">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <button mat-stroked-button color="primary"  (click)="clearFilter()">
    <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
    <span>Clear Filter</span>
  </button>
</div>
<div class="table-scroll">
  <table mat-table [dataSource]="dataSource" matSort style="width: 100%" #empTbSort="matSort" 
  (matSortChange)="announceSortChange($event)" *ngIf="!noContent; else noContentTemplate">

  <!-- Position Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
      Package
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="package-name">
        <button mat-button class="idn-grid-link-button idn-cell-btn-align" [disabled]="element.isActive == false" (click)="( !element.locked && extDetailsData.status == 1 ) && element.fileName!='' && element.fileName!=undefined &&((element.status !=='Upload failed' && element.status !=='Package uploaded' && element.status !=='Virus scan in progress' && element.status !=='Virus scan failed' && element.status !=='Upload in progress' ))? downloadSingleFile(element.id, [element.fileName], ['Package']) : ''">
          <div class="button-name">
            <div class="button-data" matTooltip="{{element.name}}"
            matTooltipPosition="below" matTooltipClass="my-tooltip">{{element.name}}</div>
            <mat-icon class="download-icon"
              *ngIf="( !element.locked && extDetailsData.status == 1 ) && element.fileName!='' && element.fileName!=undefined &&((element.status !=='Upload failed' && element.status !=='Package uploaded' && element.status !=='Virus scan in progress' && element.status !=='Virus scan failed' && element.status !=='Upload in progress' ))">download
            </mat-icon>  
          </div>
                           
        </button>        
        <button mat-button class="idn-grid-link-button idn-cell-link-btn-align" [disabled]="element.isActive == false"  matTooltip="Linked packages" *ngIf="linkageAndQueueForUpgradeFeature"
        matTooltipClass="my-tooltip" matTooltipPosition="below" aria-label="link" >
          <mat-icon *ngIf="element?.linkageId" (click)="linkedPackage(element)">link</mat-icon>
        </button>
        <mat-icon *ngIf="element?.linkageId && element?.queuedForUpgrade && linkageAndQueueForUpgradeFeature" class="icon-scale" svgIcon="queue_for_upgrade" matTooltip="Queued for deployment on upgrade" matTooltipClass="my-tooltip" matTooltipPosition="below"></mat-icon>
        <mat-icon class="material-symbols-outlined green" *ngIf="element.isProdDeployedPackage == true" matTooltip="Production deployed package" matTooltipClass="my-tooltip" matTooltipPosition="above">
            credit_score          
        </mat-icon>            
      </div>
    </td>
  </ng-container>

  <!-- Partner name Column -->
  <ng-container matColumnDef="partnerName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by partner">
      Partner Name
    </th>
    <td mat-cell *matCellDef="let element" class="pckgdesc overflow-data" matTooltip="{{element.partnerName}}"
      matTooltipClass="my-tooltip" matTooltipPosition="below">{{element.partnerName? element.partnerName : 'NA'}} </td>
  </ng-container>

  <!-- description Column -->
  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
      Description
    </th>
    <td mat-cell *matCellDef="let element" class="pckgdesc overflow-data" matTooltip="{{element.description}}"
      matTooltipClass="my-tooltip" matTooltipPosition="below">{{element.description}} </td>
  </ng-container>

  <!-- ICI Version Column -->
  <ng-container matColumnDef="iciVersion">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by iciVersion">
      ICI Version
    </th>
    <td mat-cell *matCellDef="let element" class="pckgdesc overflow-data" matTooltip="{{element.iciVersion}}"
      matTooltipClass="my-tooltip" matTooltipPosition="below"> {{ element.iciVersion ? element.iciVersion : '-' }} </td>
  </ng-container>

  <!-- Created By Column -->
  <ng-container matColumnDef="createdBy" *ngIf="linkageAndQueueForUpgradeFeature">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by iciVersion">
      Created By
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="idn-cell-date"  matTooltip="{{element.createdByDisplayName}}" class="overflow-data" id="createdBy-container">{{ element.createdByDisplayName }}</div>
      <div class="overflow-data requested-date" id="createdBy-container"> on {{ element.createdAt | date:"dd MMMM, yyyy " }}{{ element.createdAt | date:"'at' HH:mm '(UTC)'" }}</div>
    </td>
  </ng-container>

  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
     Status
        <div class="refresh-btn">
          <mat-icon (click)="refresh($event)" >refresh</mat-icon>
        </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <div class="icon-style" *ngIf="element.locked == false">
        <span *ngIf="element.status =='Upload failed' " matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale red" svgIcon="upload_failed"></mat-icon></span>
        <span *ngIf="element.status =='Package uploaded' " matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale blue">check_circle</mat-icon></span>
        <span *ngIf="element.status =='Build successful'" matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale green" svgIcon="build_success"></mat-icon></span>
        <span *ngIf="element.status =='Certified package' " matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale green">verified_user</mat-icon></span>
        <span *ngIf="element.status =='Virus scan failed' " matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale red">flip</mat-icon></span>
        <span *ngIf="element.status =='Virus scan in progress' " matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale orange">flip</mat-icon></span>
        <span *ngIf="element.status =='Upload in progress'" matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale orange rotate-180">downloading</mat-icon></span>
        <span *ngIf="element.status =='Certification in progress'" matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale orange"
            svgIcon="certify_progress"></mat-icon></span>
        <span *ngIf="element.status =='Build failed'" matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale red" svgIcon="build_failed"></mat-icon></span>
        <span *ngIf="element.status =='Build in progress'" matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale orange" svgIcon="build_progress"></mat-icon></span>
        <span *ngIf="element.status =='Rejected'" matTooltip="{{element.status}}"
          matTooltipClass="idn-tooltip"><mat-icon class="icon-scale red">block</mat-icon></span>

        <span class="pckgstatus"
          [ngClass]="element.status =='Upload failed' || element.status == 'Virus scan failed' || element.status == 'Rejected' ? 'red' : ''">
          {{ element.status == "" ? '' : element.status }} </span>
      </div>
      <div class="icon-style" *ngIf="element.locked == true">
        <mat-icon class="grey">lock</mat-icon>
        <span class="lockUnlockDetails" *ngIf="element.locked == true">{{element.modifiedByDisplayName}} <br> on {{element.modifiedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}</span>
      </div>
      
      <mat-progress-bar [color]="color" [mode]="mode" [value]="value"
        *ngIf="uploadPackageData.showProgressBar == 'true' && element.name == uploadPackageData.pckgName ">
      </mat-progress-bar>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      Actions
    </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="View" (click)="viewPackage('package-details',element)"
        matTooltip="View Package">
        <mat-icon class="view-icon">remove_red_eye</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="gridMenu"
        *ngIf="extDetailsData.status != 2 && (isEditSOlutionPackagePermission || isUnLockSOlutionPackagePermission || isLockSOlutionPackagePermission || (isViewSolutionPackagePermission && (isCreateSolutionPackage || !linkageAndQueueForUpgradeFeature)))"
        aria-label="Extension Actions" matTooltip="Package Actions">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #gridMenu="matMenu" xPosition="before">
        <button mat-menu-item *ngIf="!linkageAndQueueForUpgradeFeature"
          (click)="(element.locked === false && extDetailsData.status == 1 && (element.status !=='Upload failed' && element.status !=='Package uploaded' && element.status !=='Virus scan in progress' && element.status !=='Virus scan failed' )) ? downloadSingleFile(element.id, [element.fileName, element.binaryFileName, element.logFileName], ['Package', 'Binary', 'Log']) : ''"
          [disabled]="element.locked || extDetailsData.status == 2 || element.status =='Upload failed' || element.status =='Package uploaded' || element.status =='Virus scan in progress' || element.status =='Virus scan failed' ">
          <mat-icon>download</mat-icon> Download Files
        </button>
        <button mat-menu-item
          (click)="(element.locked === false && extDetailsData.status == 1) ? edit('extension-management/edit',element):''"
          *ngIf="isEditSOlutionPackagePermission && !((element.locked) || (extDetailsData.status == 2) || (element.status == 'Build failed') || (element.status == 'Virus scan failed') || (element.status == 'Upload failed')) && (element?.partnerId == loggedInUserPartnerId || partnerAccess)"
          [disabled]="(element.locked) || (extDetailsData.status == 2) || (element.status == 'Build failed') || (element.status == 'Virus scan failed') || (element.status == 'Upload failed')">
          <mat-icon>mode</mat-icon> Edit
        </button>
        <button mat-menu-item *ngIf="linkageAndQueueForUpgradeFeature && isCreateSolutionPackage && element?.fileName != ''"
          (click)="uploadLinkedPackage('extension-management/create',element)">
          <mat-icon>link</mat-icon> Upload New Version
        </button>
        <button mat-menu-item *ngIf="linkageAndQueueForUpgradeFeature && !element.locked && element?.linkageId && !element?.queuedForUpgrade && isCreateSolutionPackage && (element.status == 'Certified package'
        || element.status == 'Certification in progress'
        || element.status == 'Build successful')" 
          (click)="queueForUpgrade(element)">
          <mat-icon class="svg" svgIcon="queue_for_upgrade"></mat-icon> Deploy on Instance Upgrade
        </button>
        <button mat-menu-item
          (click)="((element.status == 'Build successful') && (!element.locked)) && (extDetailsData.status == 1)? submitSolutionCertification(element) : ''"
          *ngIf="isInitiateAutomaticCertificationPermission && (element?.partnerId == loggedInUserPartnerId || partnerAccess) && !((element.status != 'Build successful') || (element.locked) || (extDetailsData.status == 2))"
          [disabled]="(element.status != 'Build successful') || (element.locked) || (extDetailsData.status == 2)">
          <mat-icon>local_police</mat-icon> Request Certification
        </button>
        <button mat-menu-item (click)="(element.locked === false
        && extDetailsData.status == 1 &&
        !(element.status == 'Certified package'
        || element.status == 'Virus scan in progress'
        || element.status == 'Upload in progress'
        || element.status == 'Certification in progress'
        || element.status == 'Build in progress') )
        ? lock(element.id, element)
        : '' " *ngIf="isLockSOlutionPackagePermission && ((currentUserId == element.createdBy) || (this.isCreateSolutionPackage && this.isCreateRole)) && !(element.locked
        || extDetailsData.status == 2
        || element.status == 'Certified package'
        || element.status == 'Virus scan in progress'
        || element.status == 'Upload in progress'
        || element.status == 'Certification in progress'
        || element.status == 'Build in progress')" [disabled]="element.locked
         || extDetailsData.status == 2
         || element.status == 'Certified package'
         || element.status == 'Virus scan in progress'
         || element.status == 'Upload in progress'
         || element.status == 'Certification in progress'
         || element.status == 'Build in progress'">
          <mat-icon>lock</mat-icon> Lock
        </button>
        <button mat-menu-item
          (click)="(element.locked === true && extDetailsData.status == 1) ? unlock(element.id, element):''"
          *ngIf="isUnLockSOlutionPackagePermission && ((currentUserId == element.createdBy) || (this.isCreateSolutionPackage && this.isCreateRole)) && !(!element.locked || extDetailsData.status == 2)" [disabled]="!element.locked || extDetailsData.status == 2">
          <mat-icon>lock_open</mat-icon> Unlock
        </button>
        <button mat-menu-item
          (click)="((element.locked === false) && (extDetailsData.status == 1) && (element.status == 'Certified package') && (element.skuInitiated === false) ) ? toggleRequestDrawer(element) : '' "
          *ngIf="isSubmitReleaseVersionPermission && (element?.partnerId == loggedInUserPartnerId || partnerAccess) && !(element.status != 'Certified package' || element.skuInitiated == true || element.locked || extDetailsData.status == 2)"
          [disabled]="element.status != 'Certified package' || element.skuInitiated == true || element.locked || extDetailsData.status == 2">
          <mat-icon svgIcon="idn-sku"></mat-icon> Initiate release version
        </button>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row;columns: displayedColumns;"> </tr>

  </table>
</div>
<mat-paginator #paginator [ngClass]="{'hide-pagination': noContent ? true : false}" [pageSize]="10"
  [pageIndex]="currentPage" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
  (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>

<!-- Drawer -->
<mat-drawer-container class="idn-drawer-internal" *ngIf="isRequestDrawer">
  <mat-drawer #drawerInfo class="idn-drawer">
    <div class="header">
      <div class="heading">
        <mat-icon svgIcon="idn-sku" class="idn-drawer-icon-primary"></mat-icon>
        <h2>Initiate Release Version</h2>
      </div>
      <button mat-icon-button aria-label="Close Button" (click)="toggleRequestDrawer('close')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <form [formGroup]="initiateReleaseVersionForm" (ngSubmit)="onSubmitInitiateRelease()">
      <div class="body pt-1">
        <div class="page-container">
          <div class="idn-view-wrapper">
            <div class="idn-view-body">
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span class="idn-drawer-acc-heading">Package Details</span>
                    </mat-panel-title>
                    <mat-panel-description>
                      <mat-icon *ngIf="panelOpenState == false " class="heading-hint"
                        matTooltip="View full package details" [matTooltipPosition]="'before'">help</mat-icon>
                      <mat-icon *ngIf="panelOpenState == true " class="heading-hint"
                        matTooltip="Hide full package details" [matTooltipPosition]="'before'">help</mat-icon>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="pss-left-panel">
                    <mat-grid-list cols="2" rowHeight="72px">
                      <mat-grid-tile [colspan]="2">
                        <div class="idn-view-body-items">
                          <div class="idn-view-body-items-label">Product Family</div>
                          <div class="idn-view-body-items-data">{{ drawerPackageDetails.extensionType }}</div>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>

                    <mat-grid-list cols="2" rowHeight="72px">
                      <mat-grid-tile [colspan]="2">
                        <div class="idn-view-body-items">
                          <div class="idn-view-body-items-label">Customer Extension Package Name</div>
                          <div class="idn-view-body-items-data">{{ drawerPackageDetails.name }}</div>
                        </div>
                      </mat-grid-tile>
                      <mat-grid-tile [colspan]="2">
                        <div class="idn-view-body-items">
                          <div class="idn-view-body-items-label">Customer Extension Package Version</div>
                          <div class="idn-view-body-items-data">{{ drawerPackageDetails.major }}.{{
                            drawerPackageDetails.minor }}.{{ drawerPackageDetails.patch }}.{{ drawerPackageDetails.build
                            }}</div>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>

                    <mat-grid-list cols="2" rowHeight="72px">
                      <mat-grid-tile [colspan]="2">
                        <div class="idn-view-body-items">
                          <div class="idn-view-body-items-label">ICI Compatible Version</div>
                          <div class="idn-view-body-items-data">{{ drawerPackageDetails.iciVersion }}</div>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>

                    <mat-grid-list cols="2" rowHeight="80px">
                      <mat-grid-tile [colspan]="2">
                        <div class="idn-view-body-items">
                          <div class="idn-view-body-items-label">Product Description</div>
                          <div class="idn-view-body-items-data">{{ drawerPackageDetails.description }}</div>
                        </div>
                      </mat-grid-tile>
                    </mat-grid-list>

                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>


          <mat-form-field class="pss-block-form mt-1">
            <mat-label>Product Name</mat-label>
            <input formControlName="productName" matInput required>
          </mat-form-field>
          <mat-error class="error-message"
            *ngIf="initiateReleaseVersionForm.controls.productName.invalid && (initiateReleaseVersionForm.controls.productName.dirty || initiateReleaseVersionForm.controls.productName.touched)">{{
            extensionEnum.package_error_field_product_name }}</mat-error>

          <div class="idn-section-label">Product Release Version</div>
          <div class="idn-version">
            <mat-form-field>
              <mat-label>Major</mat-label>
              <input matInput min="0" type="number" step="any" formControlName="major" required>
              <button matSuffix mat-icon-button aria-label="Incerement" class="idn-version-button"
                (click)="$event.preventDefault(); incerement('major')">
                <mat-icon>add</mat-icon>
              </button>
              <button matSuffix mat-icon-button aria-label="Decrement" class="idn-version-button"
                (click)="$event.preventDefault(); decrement('major')"
                [disabled]="(initiateReleaseVersionForm.controls['major'].value == '0') || (initiateReleaseVersionForm.controls['major'].value == null)">
                <mat-icon>remove</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Minor</mat-label>
              <input matInput min="0" type="number" step="any" formControlName="minor" required>
              <button matSuffix mat-icon-button aria-label="Incerement" class="idn-version-button"
                (click)="$event.preventDefault(); incerement('minor')">
                <mat-icon>add</mat-icon>
              </button>
              <button matSuffix mat-icon-button aria-label="Decrement" class="idn-version-button"
                (click)="$event.preventDefault(); decrement('minor')"
                [disabled]="(initiateReleaseVersionForm.controls['minor'].value == '0') || (initiateReleaseVersionForm.controls['minor'].value == null)">
                <mat-icon>remove</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Patch</mat-label>
              <input matInput min="0" type="number" step="any" formControlName="patch" required>
              <button matSuffix mat-icon-button aria-label="Incerement" class="idn-version-button"
                (click)="$event.preventDefault(); incerement('patch')">
                <mat-icon>add</mat-icon>
              </button>
              <button matSuffix mat-icon-button aria-label="Decrement" class="idn-version-button"
                (click)="$event.preventDefault(); decrement('patch')"
                [disabled]="(initiateReleaseVersionForm.controls['patch'].value == '0') || (initiateReleaseVersionForm.controls['patch'].value == null)">
                <mat-icon>remove</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Build</mat-label>
              <input matInput min="0" type="number" step="any" formControlName="build" required>
              <button matSuffix mat-icon-button aria-label="Incerement" class="idn-version-button"
                (click)="$event.preventDefault(); incerement('build')">
                <mat-icon>add</mat-icon>
              </button>
              <button matSuffix mat-icon-button aria-label="Decrement" class="idn-version-button"
                (click)="$event.preventDefault(); decrement('build')"
                [disabled]="(initiateReleaseVersionForm.controls['build'].value == '0') || (initiateReleaseVersionForm.controls['build'].value == null)">
                <mat-icon>remove</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <mat-error class="error-message"
            *ngIf="(initiateReleaseVersionForm.controls.major.invalid && (initiateReleaseVersionForm.controls.major.dirty || initiateReleaseVersionForm.controls.major.touched)) || (initiateReleaseVersionForm.controls.minor.invalid && (initiateReleaseVersionForm.controls.minor.dirty || initiateReleaseVersionForm.controls.minor.touched)) || (initiateReleaseVersionForm.controls.patch.invalid && (initiateReleaseVersionForm.controls.patch.dirty || initiateReleaseVersionForm.controls.patch.touched)) || (initiateReleaseVersionForm.controls.build.invalid && (initiateReleaseVersionForm.controls.build.dirty || initiateReleaseVersionForm.controls.build.touched))">{{
            extensionEnum.package_error_field_build_version }}</mat-error>

          <mat-form-field class="pss-block-form mt-1">
            <mat-label>Product Release Date</mat-label>
            <input matInput [min]="startDate" [matDatepicker]="picker" formControlName="productReleaseDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-hint class="idn-mat-hint">
              <span class="material-icons">info</span>
              <span class="message">{{ extensionEnum.package_create_hint_message_required_release_date }}</span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field class="pss-block-form mt-1">
            <mat-label>Product Release Notes</mat-label>

            <textarea class="idn-min-textarea-height" matInput maxlength="500" #comments
              formControlName="productReleaseNotes"
              placeholder="Brief release note ( maximum 500 characters )"></textarea>
          </mat-form-field>

          <mat-form-field class="pss-block-form mt-1">
            <mat-label>Product Tag</mat-label>
            <mat-chip-list #chipList aria-label="Tag selection" aria-hidden="true">
              <mat-chip *ngFor="let tag of tags" [selectable]="selectable" [removable]="removable"
                (removed)="remove(tag)">
                {{tag.name}}
                <button matChipRemove *ngIf="removable"><mat-icon>cancel</mat-icon></button>
              </mat-chip>
              <input placeholder="New Tag..." [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
            </mat-chip-list>
          </mat-form-field>

        </div>
      </div>
      <div class="pss-drawer-button-wrapper">
        <button type="submit" mat-flat-button color="primary" [disabled]="!initiateReleaseVersionForm.valid">Submit for
          Approval</button>
        <button mat-flat-button (click)="toggleRequestDrawer('close')">Cancel</button>
      </div>
    </form>
  </mat-drawer>
</mat-drawer-container>
<!-- Drawer -->

<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71"
      viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)"
            fill="#515165" />
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
          transform="translate(3187.267 7517.8)" fill="#7c7c97" />
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
          transform="translate(3192.911 7517.8)" fill="#515165" />
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
          transform="translate(3178.801 7551.663)" fill="#ababc5" />
        <path id="Union_2" data-name="Union 2"
          d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
          transform="translate(3178.801 7617.272)" fill="#7c7c97" />
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
          transform="translate(3192.911 7551.663)" fill="#7c7c97" />
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)"
            fill="#cfdae2" />
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
            transform="translate(8.466 8.466)" fill="#f6f2f1" />
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
          clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1"
            d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
            transform="translate(-14.11 0)" fill="#515165" />
        </g>
      </g>
    </svg>
    <h4>{{ extensionEnum.package_no_result_msg }}</h4>
  </div>
</ng-template>