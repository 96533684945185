<mat-drawer-container class="idn-drawer-internal">
    <mat-drawer #aboutDrawer class="idn-drawer" mode="over">
        <div class="header">
            <div class="heading">
                <h2>About IDN</h2>
            </div>
            <button id="closeBtn" mat-button="closebtn" (click)="closeDrawer()" aria-label="close Button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="body">
            <mat-tab-group mat-align-tabs="start">
                <mat-tab label="IDN Version">
                    <div class="about-left-panel">
                        <h5>PRODUCT VERSION</h5>
                    </div>
                    <div class="about-right-panel">
                        <h5>{{this.idnVersion}}</h5>
                    </div>
                </mat-tab>
                <mat-tab label="Open Source Notice">
                    <mat-accordion class="example-headers-align" multi="true">
                        <mat-expansion-panel class="description" *ngFor="let value of softwareList" hideToggle disabled>
                            <mat-expansion-panel-header aria-hidden="true">
                                <mat-panel-title>
                                    {{ value.libraryName }}
                                </mat-panel-title>
                                <mat-panel-description>
                                    <a href="{{value.referenceURL}}" target="_blank">Home Page</a>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-tab>
            </mat-tab-group>
        </div>
    </mat-drawer>

</mat-drawer-container>