export enum Deployment {
    /*DEPLOYMENT LIST COLUMN  HEADER */
    deployment_List_Header_type = "Type",
    deployment_List_Header_customerName = "Customer Name",
    deployment_List_Header_name = "CName",
    deployment_List_Header_extensionType ="EXTENSION TYPE",
    deployment_List_Header_status = "Status",
    deployment_List_Header_actions = "Actions",
    deployment_List_Filter_searchFieldCaption ="Search Deployment",
    deployment_List_Filter_statusCaption="Status",
    /*DEPLOYMENT LIST ACTION BUTTON LABEL*/
    deployment_List_Action_Label_trackStatus_btn = "Track Status",
    /*EXTENSION VIEW FIELD LABEL */
    deployment_List_Action_Label_downloadLogs = "Deployment Logs",
    deployment_View_Heading="Deployment Request for:",
    deployment_View_Field_partnerName = "Partner Name",
    deployment_View_Field_customerName = "Customer Name",
    deployment_View_Field_type = "Type",
    deployment_View_Field_deployment_for = "Deployment For",
    deployment_View_Field_cName = "cName",
    deployment_View_Field_region = "Region",
    deployment_View_Field_skuDetails="SKU Details",
    deployment_View_Field_extensionType = "Extension Type",
    deployment_View_Field_extensionName = "Extension Name",
    deployment_View_Field_packageName = "Package Name",
    deployment_View_Field_solutionPackage = "Package Name",
    deployment_View_Field_productName = "Product Name",
    deployment_View_Field_binaryFile = "BinaryFile",

    deployment_View_Field_status = "Status",
    deployment_View_Field_requestorDetails="Requester Details",
    deployment_View_trackStatus_primary_btn = "Track Status",
    deployment_View_CustomDrawer_Header_screenHelpMessage = "Track Deployment Status",
    deployment_View_CustomDrawer_Title_screenHelpMessage = "Track the status of this deployment request",
    deployment_View_CustomDrawer_Cancel_btn = "Cancel",
    deployment_view_downloadfile_body_p1 = " has been downloaded.",
    deployment_view_failedDownloadfile_body_p1 = "Failed to download",
    deployment_view_downloadfile_heading = "Downloaded",
    deployment_view_faileddownloadfile_heading="failed to Downloaded",
    deployment_view_approval_details="Approval Details",
    deployment_view_reviewer_details="Reviewer Details",
    deployment_view_deployment_approved_date="Deployment Approved Date",
    deployment_view_comments=" Comments",
    deployment_view_deployment_requested_date="Deployment Requested Date",
    deployment_view_deployment_orchestrated_package="Orchestrated Package",
    deployment_view_deployment_orchestrated_package_download=" Download",



    /*NOTIFICATION TOAST MESSAGE */

    deployment_notify_infoNotAvailable_heading = "Information not available",
    deployment_notify_infoNotAvailable_body="Detailed information is not available",
    deployment_notify_deployment_log_failed_heading = "Unable to download Log",
    deployment_notify_deployment_log_failed_404_heading = "Deployment logs not available",
    deployment_notify_deployment_log_failed_body = "Deployment Log for this instance can not be downloaded due to some Issue.",
    deployment_notify_deployment_log_success_heading = "Log Downloaded",
    deployment_notify_deployment_log_success_body = "Deployment Log for this instance downloaded successfully.",
    deployment_notify_deployment_log_failed_404_body = "Deployment logs are not available. They might not have been created, or this deployment request was for a package queued for auto-deployment after instance upgrade.",
    deployment_notify_orchestration_package_success_body = "Orchestrated package for this instance downloaded successfully.",
    deployment_notify_orchestration_package_failed_404_body = "Orchestrated package for this instance can not be downloaded because Log file is not available for download.",
    deployment_notify_orchestration_package_failed_body = "Orchestrated package for this instance can not be downloaded due to some Issue.",
    deployment_notify_orchestration_package_success_heading = "Orchestrated package Downloaded",
    deployment_notify_orchestration_package_failed_heading="Unable to download Orchestrated package",

    /* No Result Found */
    deployment_no_result_msg = "No Deployment Found!",
    deployment_no_data = "Not updated",
    deployment_no_package_selected_heading = "Package not selected",
    deployment_no_package_selected_body = "no package selected for the deployment, please select minimum 1 extension/ package",

    
    redirect_dialog_heading = "Confirm Redirection",
    redirect_dialog_body = "The link will open in a new browser tab. Click <b>Yes</b> to confirm.",
    redirect_dialog_primary_btn = 'Yes',
    redirect_dialog_secondary_btn = 'No',
    
    deployment_status_deployment_requested = "Deployment Requested",
    deployment_status_package_bundling_started ="Package Bundling Started",
    deployment_status_package_bundling_completed ="Package Bundling Completed",
    deployment_status_deployment_in_progress ="Deployment In Progress",
    deployment_status_deployment_completed ="Deployment Completed",

    deployment_notify_deployment_request_approved_body = "Deployment Request Approved",
    deployment_notify_deployment_request_approved_heading = "Request Approved",
    deployment_notify_deployment_request_approval_failed_body = "Deployment Request Approval Failed",
    deployment_notify_deployment_request_approval_failed_heading = "Request Failed",
    deployment_notify_deployment_request_rejected_body = "Deployment Request Rejected",
    deployment_notify_deployment_request_rejected_heading = "Request Rejected",
    deployment_notify_deployment_request_rejection_failed_body = "Deployment Request Rejection Failed",
    deployment_notify_deployment_request_rejection_failed_heading = "Request Failed",
    deployment_constant_approve = "Approve",
    deployment_constant_reject = "Reject",
    deployment_constant_approve_reject = "Approve/Reject",


}
