
<div *ngIf="isRMuser" class="close-btn-rm-user">
    <button mat-icon-button [mat-dialog-close]="false" aria-label="close Button" class="cancel-btn">
        <mat-icon>close</mat-icon>
      </button>
</div>
<div class="upload-dialog" *ngIf="isRMuser">
    
    <div class="idn-download-div">
        <input type="file"   aria-label="upload file">
        <mat-icon class="upload-icon">cloud_upload</mat-icon>
        <h3>Drag and drop file here</h3>
        <h3>or</h3>
        <label for="uploadFile">Browse File</label>
        <input type="file" #fileInput id="uploadFile" name="uploadFile" accept=".xlsx,.xls"
            (change)="uploadFileEvt($event)" required/>
    </div>
    <div>
        <span> <b>Note: </b>{{podCalendarEnum.pod_calendar_rm_file_uplaod_extension_msg}}</span>
    </div>
    <div class="pss-Bottom-right">
      <table>
          <tr><br></tr>
          <tr *ngFor="let item of listofUploadedFiles">
              <mat-chip-list #chipList aria-label="Instance Tags">
                  <mat-chip (removed)="removeFileTag(item)">
                      {{item}}
                      <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                      </button>
                  </mat-chip>
              </mat-chip-list>
          </tr>
      </table>
  </div>              
<div mat-dialog-actions [align]="'center'">
    <button mat-flat-button cdkFocusInitial [mat-dialog-close]="true" color="primary" [disabled]="isFileExists"  (click)="uploadAndSaveFile()">Confirm</button>
</div>
</div>
<div *ngIf="isCloudOpsuser">
    <div class="pss-dialog-header">
       <div mat-dialog-title>Complete POD Upgrade</div>
        <button mat-icon-button [mat-dialog-close]="false" aria-label="close Button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div>
        You are notifying the customers that their ICI upgrade is completed:
        <div class="pod-details">
            <span> POD Name: {{data.podName}}</span>
            <span>Planned upgrade date: {{data.plannedDate}}</span>
        </div>
      </div>
    <div class="comment-header"> <mat-icon>info</mat-icon><span>Please enter comment below.</span></div>
    <form [formGroup]="frmPodUploadCalendar">
        <mat-form-field class="pss-block-form">
            <mat-label>Please enter Comments</mat-label>
            <textarea formControlName="enterComments" class="idn-min-textarea-height" type="text" maxlength="500" width="50" matInput
                placeholder="Comments" height="30" required (ngModelChange)="valueChangeDescription($event)"></textarea>
        </mat-form-field>
        <mat-error *ngIf="frmPodUploadCalendar.controls.enterComments.invalid && frmPodUploadCalendar.controls.enterComments.touched">{{errorDescription}}</mat-error>
            <mat-form-field class="pss-block-form">
                <mat-label>Upload file</mat-label>
                <mat-toolbar class="idn-upload-toolbar">
                    <input class="file-name" matInput readonly [(ngModel)]="fileAttr" [ngModelOptions]="{standalone: true}"  name="name" id="uploadfile"/>
                    <button mat-flat-button color="primary"
                        class="idn-file-upload" >Browse</button>
                </mat-toolbar>
                <input  class="upload-file" type="file" #fileInput id="uploadFile" name="uploadFile" accept=".pdf,.doc,.docx,.txt,.xlsx,.xls"
                    (change)="uploadFileEvt($event)" aria-label="upload file" />
               
            </mat-form-field>
            <div class="idn-loader-overview" *ngIf="loading$ | async">

                <div class="pss-loader-v2" ><mat-spinner [strokeWidth]="3" [diameter]="32"></mat-spinner><p>Please Wait...</p></div>
              
              </div>
            <div>
                <span class="upload-note"><mat-icon>info</mat-icon>{{podCalendarEnum.pod_calendar_rm_file_uplaod_extension_msg}}</span>
            </div>
            <div mat-dialog-actions [align]="'center'">
                <button mat-flat-button cdkFocusInitial color="primary" [disabled]="!frmPodUploadCalendar.valid"  (click)="$event.preventDefault();CompletePodUpgrade()">Confirm</button>
                <button mat-stroked-button [mat-dialog-close]="false" (click)="onNoClick()" class="min-width-button">Cancel</button>
            </div>
    </form>
   
</div>