<app-pageheader [pageTitle]="'Pod Upgrade'" [breadCrumbs]="this.breadCrumbItems" [auditLog]="true" [auditLogData]="'mtpodupgradecalendar'" [applyFilter]="false" [clearFilter]="false" [archive]="false"></app-pageheader>
<div *ngIf="isRMuser">
<div class="page-container">
<div class="notes-rm">
  <div class="notes-header-rm">
    <h2>Notes And Instructions</h2>
  </div>
    <ol class="pod-calendar-notes-rm">
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point1}}</li>
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point2}}</li>
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point3}}</li>
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point4}}</li>
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point5}}</li>
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point6}}</li>
      <li>{{podCalendarEnum.pod_calendar_instruction_rm_point7}}</li>
      </ol>
 </div>
</div>
<div class="pss-form-button-wrapper">
    <button mat-stroked-button color="primary" class="download-btn" (click)="downloadTemplate()"><mat-icon>download</mat-icon>Download Template</button>
    <button mat-raised-button color="primary" (click)="uploadCalendar()">Upload Upgrade Calendar</button>
</div>
</div>

<div *ngIf="isCloudOpsuser">
    <div class="page-container">
       <div class="header">POD Upgrade Calendar</div>
       <div class="pod-upgrade">
        <form [formGroup]="frmPodUpgrade">
          <mat-form-field class="select-pod-input">
            <mat-label>Select POD</mat-label>
              <mat-select formControlName="selectPod" disableOptionCentering required name="pod" [(value)]="selectedPOD"  (selectionChange)="PODSelectionChange($event)">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search POD"
                      noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlPodname">
                  </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let pod of selectPodName" [value]="pod.podId">
                  {{pod.podName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="select-pod-input">
              <mat-label>Select Version</mat-label>
              <mat-select formControlName="selectVersion"  disableOptionCentering required name="pod" [(value)]="selectedVersion"  (selectionChange)="versionSelectionChange($event)">
                <mat-option *ngFor="let version of versions" [value]="version.scheduleId">
                  {{version.targetVersion}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div class="pod-planned-date">
              <span> Planned upgrade date (YYYY-MM-DD) - {{this.plannedDate | date: "yyyy-MM-dd"}} </span> 
             </div>
          </form>
          <div class="notes">
            <div class="notes-header">
              <h2>Notes And Instructions</h2>
            </div>
              <ol class="pod-calendar-notes">
                <li>{{podCalendarEnum.pod_calendar_instruction_cloudops_point1}}</li>
                <li>{{podCalendarEnum.pod_calendar_instruction_cloudops_point2}}</li>
                <li>{{podCalendarEnum.pod_calendar_instruction_cloudops_point3}}</li>
                <li>{{podCalendarEnum.pod_calendar_instruction_cloudops_point4}}</li>
                </ol>
           </div>
       </div>
        <div class="pss-form-button-wrapper">
            <button mat-raised-button color="primary"  [disabled]="!frmPodUpgrade.valid || isCompleteUpgrade" (click)="startUpgrade()">Notify Upgrade Start</button>
            <button mat-raised-button color="primary" [disabled]=" !frmPodUpgrade.valid || isStartUpgrade" (click)="uploadCalendar()">Notify Upgrade Completion</button>
            
        </div> 
     

</div>