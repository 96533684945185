<div class="pss-dialog-header">
  <h2 mat-dialog-title>Confirm user type</h2>
  <button mat-icon-button [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div mat-dialog-content>
  <div class="dialog-content">
    <button mat-flat-button class="idn-add-user-selection" (click)="addIcertisUser()">
      <div class="description">
        <h4>Add Icertis user</h4>
        <p>A user with Icertis role like admin, Sales Ops, Approver</p>
      </div>
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
    <button mat-flat-button class="idn-add-user-selection" (click)="gotoAdduser()">
      <div class="description">
        <h4>Add Partner user</h4>
        <p>A user with Partner role like Partner Admin, Developer, Customer</p>
      </div>
      <mat-icon>arrow_right_alt</mat-icon>
    </button>
  </div>
</div>
