import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { AboutService } from 'src/app/core/services/about/about.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  @ViewChild('aboutDrawer', { static: true }) public aboutDrawer!: MatDrawer;
  @Output() closeD = new EventEmitter<String>();
  openSourceNotice: any = [];
  softwareList: any;
  idnVersion:any;
  constructor(
    private aboutservice: AboutService,
    private permserv:PermissionsService) { }

  ngOnInit(): void {
    this.getThirdPartySoftwareList();
    this.permserv.GetIDNConfiguration().subscribe(resp =>{
      this.idnVersion = resp.body.content.idnVersion
    });
  }

  getThirdPartySoftwareList() {
    this.aboutservice.getThirdPartyLibraries().subscribe((res: any) => {
      this.softwareList = res;


    })
  }
  closeDrawer() {
    this.closeD.emit('false');
  }
}
