import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';

@Component({
  selector: 'app-environment-type-filter',
  templateUrl: './environment-type-filter.component.html',
  styleUrls: ['./environment-type-filter.component.scss']
})
export class EnvironmentTypeFilterComponent implements OnInit{
   @Output() selectedData = new EventEmitter<any>();
  allComplete: boolean = false;
  maxNoOfInstance: number = 999;
  instanceTypes:any = [];
  recievedData: any=[];
  checkedInstanceVal: any=[];
  instancelists: any=[];
  storeData: any;
  constructor(private customerService: CustomerService,){}
  emptyFieldRegex = /<(.|\n)*?>/g;
  ngOnInit(): void {
    let data:any = localStorage.getItem('customerFilterData');
    this.storeData = JSON.parse(data)?.customerInstanceTypes
    if(this.storeData){
      this.instancelists = this.storeData.map((item:any)=>{
        let data = item.split(',');
        this.checkedInstanceVal.push(Number(data[0]))
        let obj = {
          instanceTypeId: data[0],
          numberOfInstasnces: data[1],
          instanceTypeName: ""
        }
        return obj;
      })
    }
  this.getInstanceTypes();
  }

  getInstanceTypes() {
    try {
      this.customerService.getInstanceTypes().subscribe((resp: any) => {
        if (resp != null) {
          this.instanceTypes = resp;
          this.instanceTypes.forEach((data:any)=>{
            data['completed']= false;
         });
         if(this.storeData?.length){
          this.instanceTypes.forEach((data:any)=>{
            if(this.checkedInstanceVal?.includes(data.id)){
              data['completed']= true;
            }
         }); 
         }
         this.recievedData = this.instanceTypes;
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.instanceTypes == null) {
      return;
    }
    this.instanceTypes.forEach((task:any) => {
      task.completed = completed;
      if(task.completed){
        let obj = {
          instanceTypeId: task.id,
          numberOfInstasnces: 1,
          instanceTypeName: ""
        }
        if(this.checkedInstanceVal.includes(task.id)){
          return
        } else{
          this.checkedInstanceVal.push(task.id);
          this.instancelists.push(obj);
        }
      } else{
        this.checkedInstanceVal =[];
        this.instancelists = [];
      }
    });
    this.updateOriginalList();
  }

  updateAllComplete(event:any, typeId:any) {
    if (event) {
      let obj = {
        instanceTypeId: typeId,
        numberOfInstasnces: 1,
        instanceTypeName: ""
      }
      this.checkedInstanceVal.push(typeId);
      this.instancelists.push(obj);
    }
    else {
      let index = this.checkedInstanceVal.indexOf(typeId);
      if (index != undefined) {
        this.checkedInstanceVal.splice(index, 1);
        this.instancelists.splice(index, 1)
      }
    }
    this.allComplete = this.instanceTypes != null && this.instanceTypes.every((task:any) => task.completed);
    this.updateOriginalList();
  }

  onSearchData(data: any) {
    if (data?.replace(this.emptyFieldRegex, '').trim().length !== 0) {
      this.instanceTypes = this.recievedData.filter((x: any) =>
        x.name.toLowerCase().includes(data.toLowerCase()),
      );
    } else {
      this.instanceTypes = this.recievedData;
      this.allComplete = this.instanceTypes != null && this.instanceTypes.every((task: any) => task.completed);
    }

  }

  addValue(event: any, id: any) {
    this.instancelists.forEach((element: any) => {
      if (element.instanceTypeId == id) {
        element.numberOfInstasnces = +(event.target.value);
      }
    });
    this.updateOriginalList();
  }

  validateNumber(event:any) {
    const inputElement = event.target;
    const value = inputElement.value;

    // Remove any non-numeric characters
    const sanitizedValue = value.replace(/[^0-9]/g, '');

    // Update the input with sanitized value
    if (value !== sanitizedValue) {
        inputElement.value = sanitizedValue;
    }
    if(value == ""){
      event.target.value= 1;
    }
    if (parseInt(event.target.value) > this.maxNoOfInstance) {
      event.target.value = event.target.value.slice(0, 3);
      return false;
    }
    else if (parseInt(event.target.value) <= 0 ) {
      event.target.value= 1;
      return false;
    }

    return true;

    

  }

  increaseValue(id: any) {
    this.instancelists.forEach((element: any) => {
      if (element.instanceTypeId == id && !( element.numberOfInstasnces >= this.maxNoOfInstance)) {
        let number = Number(element.numberOfInstasnces);
        number += 1;
        element.numberOfInstasnces = number;
      }
    });
    this.updateOriginalList();
  }
  decreaseValue(id: any) {
    this.instancelists.forEach((element: any) => {
      if (element.instanceTypeId == id) {
        let number = Number(element.numberOfInstasnces);
        number -= 1;
        if (number > 0) {
          element.numberOfInstasnces = number;
        }
        else {
          element.numberOfInstasnces = 1;
        }
      }
    });
    this.updateOriginalList();
  }

  updateOriginalList(){
    let data = this.instancelists.map((obj: any) => {
      const numberOfInstances = obj.numberOfInstasnces <= 0 ? 1 : obj.numberOfInstasnces;
      return `${obj.instanceTypeId},${numberOfInstances}`;
  });
    this.selectedData.emit(data);
  }

  allowOnlyNumbers(event:any){
    const charCode = event.which ? event.which : event.keyCode;
            if (
                (charCode >= 48 && charCode <= 57) || // Numbers (0-9)
                charCode === 8 || // Backspace
                charCode === 9 // Tab
            ) {
                return true;
            }
            return false; // Prevent any other character
  }
}
