<app-pageheader [pageTitle]="'Customer Package Upgrades'" [breadCrumbs]="breadCrumbItems">
</app-pageheader>

<app-preview-upgrade (closeD)="closedrawer($event)" *ngIf="enablePreviewDrawer" [podDetails]="podDetails"></app-preview-upgrade>

<div class="page-container">
  <div class="idn-view-wrapper">
    <div class="idn-view-header">
      <div class="data-left">
        <div class="idn-view-header-label">{{mtPodEnum.multiTenantPods_View_Header}}</div>
        <div class="idn-view-header-data">
          <h3>{{podName}}</h3>
        </div>
      </div>
    </div>

    <div class="idn-view-body pss-dashboard-form ">
      <mat-grid-list cols="4" rowHeight="80px">
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_region}}</div>
            <div class="idn-view-body-items-data">{{ViewData?.deploymentRegion}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_subscription}}</div>
          <div class="idn-view-body-items-data" matTooltip="{{ViewData?.subscription}}" [matTooltipDisabled]="isTooltipDisabled(subscriptionName)" #subscriptionName>{{ViewData?.subscription}}</div>
        </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{mtPodEnum.multiTenantPods_View_Field_podName}}</div>
            <div class="idn-view-body-items-data" matTooltip="{{ViewData?.masterTenantCname}}" [matTooltipDisabled]="isTooltipDisabled(masterTenantCname)" #masterTenantCname>{{ViewData?.masterTenantCname}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">Current ICI Version</div>
          <div class="idn-view-body-items-data">{{ViewData?.iciVersion}}</div>
        </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="4" rowHeight="80px">
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">Target ICI Version</div>
            <div class="idn-view-body-items-data">{{ViewData?.targetVersion}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">Upgrade Date</div>
          <div class="idn-view-body-items-data">{{ViewData?.upgradeDate | date:"dd MMMM, yyyy "}}</div>
        </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">Upgrade Status</div>
            <div class="idn-view-body-items-data">{{ViewData?.upgradeStatus}}</div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      
    </div>

    <div class="idn-view-body-items">
      <div class="idn-table">

        <div *ngIf="isViewMtpodSnapshot">
          <h2>{{mtPodEnum.multiTenantPods_View_Field_extension}}</h2>
          <div class="idn-filter">
            <form class="idn-grid-filter">
              <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
                <mat-label>search</mat-label>
                <input type="text" (keydown.enter)="doFilter()" #search_input matInput [(ngModel)]="filter" name="filter"
                  placeholder="Search by Tenant name and Package name">
                <span class="idn-custom-search" matSuffix>
                  <mat-icon (click)="doFilter()">search</mat-icon>
                  <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
                    <mat-icon>close</mat-icon> 
                  </button>
                </span>
              </mat-form-field>
              <mat-form-field class="select" >
                <mat-label>EMAIL SENT</mat-label>
                <mat-select (selectionChange)="doFilter()" #select_emailSent [(ngModel)]="emailSent" name="emailSent">
                  <mat-option value= false>No</mat-option>
                  <mat-option value= true>Yes</mat-option>
                </mat-select>
              </mat-form-field>
            </form>
            <button mat-stroked-button color="primary" (click)="clearFilter()">
              <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
              <span>Clear Filter</span>
            </button>
          </div>
          <div class="table-scroll">
            <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position">
              <!-- Instance Name Column -->
              <ng-container matColumnDef="tenantName">
                <th mat-header-cell *matHeaderCellDef>
                  {{mtPodEnum.multiTenantPods_View_Field_tenantName}}
                </th>
                <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.instanceName}}"> {{element.instanceName}} </td>
              </ng-container>

              <!-- Extension Name Column -->
              <ng-container matColumnDef="packageName">
                <th mat-header-cell *matHeaderCellDef>
                  {{mtPodEnum.multiTenantPods_view_field_packageName}}
                </th>
                <td mat-cell *matCellDef="let element" > 
                  <div class="overflow-data" matTooltip="{{element.solutionPackageName? element.solutionPackageName : 'No package available'}}">{{element.solutionPackageName? element.solutionPackageName : 'No package available'}}</div> 
                </td>
              </ng-container>

              <ng-container matColumnDef="iciVersion">
                <th mat-header-cell *matHeaderCellDef>
                  {{mtPodEnum.multiTenantPods_view_field_package_ici_version}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.iciVersion? element.iciVersion: "-"}} </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                  {{mtPodEnum.multiTenantPods_view_field_package_status}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <div [ngClass]="element.isCompatible? 'yes-class overflow-data' : ''" *ngIf="element.solutionPackageName" matTooltip="{{element.isCompatible? 'Compatible': 'Not Compatible'}}">{{element.isCompatible? 'Compatible': 'Not Compatible'}}</div>
                  <div class="overflow-data" *ngIf="element.solutionPackageName" matTooltip="{{element.isQueuedForDeployment? 'Queued for Deployment':''}}">{{element.isQueuedForDeployment? 'Queued for Deployment':''}}</div>                  
                  <div *ngIf="!element.solutionPackageName">-</div>                  
                </td>
              </ng-container>

              <!-- Email Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>
                  {{mtPodEnum.multiTenantPods_view_field_email_recipient}}
                </th>
                <td mat-cell *matCellDef="let element"> 
                  <div class="overflow-data email-recipient-width" matTooltip="{{(element.solutionPackageName && element.isCompatible)? mtPodEnum.multiTenantPods_view_field_email_recipient_tooltip_no_email_sent : !element.solutionPackageName? '' : element.emailRecipient}}">{{(element.solutionPackageName && element.isCompatible)? 'NA' : !element.solutionPackageName? '-' : element.emailRecipient}}</div>
                  <div class="overflow-data email-recipient-width" *ngIf="element.emailRecipient">on {{element.emailSentDateTime | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</div>
                </td>
              </ng-container>

              <tr mat-header-row
                *matHeaderRowDef="this.isApproveInstance?displayedColumnsOps:displayedColumnsAdmin; sticky: true">
              </tr>
              <tr mat-row *matRowDef="let row; columns: this.isApproveInstance?displayedColumnsOps:displayedColumnsAdmin;"
                [ngClass]="{highlighted: selection.isSelected(row)}" (click)="selectRow(row)">

              </tr>
            </table>
          </div> 

          <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]=pageSize class="mat-paginator-sticky"
            (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
            showFirstLastButtons aria-label="Select page of periodic elements"
            [ngClass]="{'hide-pagination': noContent ? true : false}">
          </mat-paginator>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="pss-form-button-wrapper">
  <button mat-flat-button color="primary" (click)="upgradeTenantPod(ViewData)" *ngIf="isViewPreviewUpgrade && enableLaActivity">
    {{mtPodEnum.btn_label}}
  </button>
  <button mat-flat-button (click)="cancel()">{{mtPodEnum.multiTenantPods_cancel}}</button>
</div>