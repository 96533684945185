import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-certification-management',
  templateUrl: './certification-management.component.html',
  styleUrls: ['./certification-management.component.scss']
})
export class CertificationManagementComponent implements OnInit {

  certificationApproverDetails: Boolean = false;

  constructor(private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Certification Requests | IDN');
    
  }

}
