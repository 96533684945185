import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PodUpgradeListComponent, uploadPodCalendar } from './pod-upgrade-list/pod-upgrade-list.component';
import { PodUpgradeRoutingModule } from './pod-upgrade-routing.module';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { MaterialModule } from 'src/app/material.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';



@NgModule({
  declarations: [
    PodUpgradeListComponent,
    uploadPodCalendar
  ],
  imports: [
    CommonModule,
    PodUpgradeRoutingModule,
    PageHeaderModule,
    MaterialModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    NgxMatSelectSearchModule,
    MatSelectModule
  ]
})
export class PodUpgradeModule { }
