
<section>
  <div #top id="top-section">
    <app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false" [createRole]="false" [mapRole]="false"
      [applyFilter]="false" [clearFilter]="false" [archive]="false" ></app-pageheader>
    </div>
</section>
        <div  class="image-container">
        <img src="../../../assets/images/idn_info.png" alt="Icertis info Logo"  class="overlay-image"/>
          <div class="overlay text-container">
            <span class="overlay-text">Welcome to Icertis Developer Network</span>
            <p>The Icertis Developer Network is a web-based self-service portal that provides developer tools, certification, pre-configured implementation, and assistance in developing solutions on top of the Icertis Platform.</p>
            <div class="idn-button-div">
              <p>To know more, see <b>What's new in IDN.</b></p>
              <button  mat-button aria-label="Go to what's new"  class="button-glow" (click)="redirectLink(url)">
                Learn More
                <mat-icon>arrow_right_alt</mat-icon>
              </button>
            </div>
            <div class="idn-button-div">
              <p><b>IDN Help:</b> Get access to a library of Icertis learning material to help you get started.</p>
              <button  mat-button aria-label="Go to Help"  class="button-glow help-button" (click)="redirectLink(resources[0].link)">
                Explore
                <mat-icon>arrow_right_alt</mat-icon>
              </button>
            </div>
          </div>
      </div>

      <div class="quick-start"  *ngIf="!this.partnerAccess">
        <div class="quick-start-header">
          <mat-divider></mat-divider> <span class="header">Quick Start Guide</span> <mat-divider></mat-divider> 
        </div>
          <span class="body-desc">Welcome to the Icertis Developer Network. We’re excited about our partnership and we look forward to the customer opportunities we will create together. Here are the next steps.</span>
          <div class="quick-start-body">
            <div class="guide-image">
              <img src="../../../assets/images/quick-start.png" alt="Icertis info Logo"/>
            </div>
            <div class="guide-steps">
              <span>Step 1 - Identify Roles</span>
              <span>Step 2 - Complete IDN Certification in ICI Academy</span>
              <span>Step 3 - Build Solutions and Get Them Certified</span>
              <mat-chip-list class="idn-chips-download" aria-label="file selection">
                <mat-chip (click)="downloadFile()" >
                  Download start guide
                  <mat-icon>download</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
      </div>
      <div class="quick-start" *ngIf="isPartnerDeveloper || isPartnerAdmin">
        <div class="quick-start-header divider">
          <mat-divider></mat-divider> <span class="header">Quick Links</span> <mat-divider></mat-divider> 
        </div>
          <div class="quick-links-body">
            <div class="guide-link-image">
              <img src="../../../assets/images/quick-links.png" alt="Icertis info Logo"/>
            </div>
            <div class="guide-link" *ngIf="isPartnerDeveloper">
                  <span> Get started by setting up the <a href= "#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/setting-up-idn-workbench/')" (click)="openSite('https://developer-help.icertis.com/docs/setting-up-idn-workbench/');$event.preventDefault();">IDN Workbench</a></span>
                  <span> <a href="#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/preparing-for-developing-solutions/')" (click)="openSite('https://developer-help.icertis.com/docs/preparing-for-developing-solutions/');$event.preventDefault();" >Prepare</a> and <a href="#"(contextmenu)="openSite('https://developer-help.icertis.com/docs/developing-and-building-solutions/')" (click)="openSite('https://developer-help.icertis.com/docs/developing-and-building-solutions/');$event.preventDefault();">Develop</a> your first solution by referring the standard ICI <a href="#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/ici-coding-guidelines-3/') "  (click)="openSite('https://developer-help.icertis.com/docs/ici-coding-guidelines-3/');$event.preventDefault();">Coding Guidelines</a></span>
                  <span> Refer <a href="#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/customizing-a-ui-field/')" (click)="openSite('https://developer-help.icertis.com/docs/customizing-a-ui-field/');$event.preventDefault();" >Customization Examples</a>, <a href="#"(contextmenu)="openSite('https://developer-help.icertis.com/docs/ici-task-overview/')" (click)="openSite('https://developer-help.icertis.com/docs/ici-task-overview/');$event.preventDefault();">ICI Tasks</a>, <a href="#"(contextmenu)="openSite('https://developer-help.icertis.com/docs/overview-of-hooks-2/')" (click)="openSite('https://developer-help.icertis.com/docs/overview-of-hooks-2/');$event.preventDefault();">ICI Hooks</a>, <a href="#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/icertis-apis/')" (click)="openSite('https://developer-help.icertis.com/docs/icertis-apis/');$event.preventDefault();">API's</a> and <a href= "#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/faqs-3/')" (click)="openSite('https://developer-help.icertis.com/docs/faqs-3/');$event.preventDefault();">FAQ's</a> to help you build the right solution to cater the requirements </span>
                  <span> In case of any discrepancies during the development, <a href= "#" (contextmenu)="openSite('https://developer-help.icertis.com/docs/idn-debugging-tools/')" (click)="openSite('https://developer-help.icertis.com/docs/idn-debugging-tools/');$event.preventDefault();">Debug</a> your implementation to identify and resolve issues</span>
              </div>
              <div class="guide-link" *ngIf="isPartnerAdmin">
                  <span> <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/managing-customers-and-instances/')" (click)="openSite('https://developer-help.icertis.com/docs/managing-customers-and-instances/'); $event.preventDefault();">Create and Manage Users</a> within your organization to kick start the implementation. </span>
                  <span> Newly added users need to mandatorily complete the <a href='#' (contextmenu)="openSite('https://icertis.litmos.com/course/9553870')" (click)="openSite('https://icertis.litmos.com/course/9553870'); $event.preventDefault();">IDN Course</a> to be able to access IDN </span>
                  <span> Facilitate the technical customizations by <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/')" (click)="openSite('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/'); $event.preventDefault();">Managing Extensions</a>, <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/')" (click)="openSite('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/'); $event.preventDefault();">Uploading Packages</a>, <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/managing-certifications-and-releasing-packages/')" (click)="openSite('https://developer-help.icertis.com/docs/managing-certifications-and-releasing-packages/'); $event.preventDefault();">Initiating Certification</a> and <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/')" (click)="openSite('https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/'); $event.preventDefault();">Deploying Packages</a> on the ICI instance </span>
                  <span> To deploy technical customization to the Production instance, follow the process documented in <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/raising-tickets-in-servicenow/#deploy-to-production')" (click)="openSite('https://developer-help.icertis.com/docs/raising-tickets-in-servicenow/#deploy-to-production'); $event.preventDefault();">Deploy to Production</a></span>
                  <span> For raising a new instance request or to report a problem on IDN, raise a <a href='#' (contextmenu)="openSite('https://developer-help.icertis.com/docs/raising-tickets-in-servicenow/')" (click)="openSite('https://developer-help.icertis.com/docs/raising-tickets-in-servicenow/'); $event.preventDefault();">Support Ticket</a> with us </span>
              </div>
          </div>
      </div>
      <div class="quick-start" *ngIf="!this.partnerAccess">
        <div class="quick-start-header divider">
          <mat-divider></mat-divider> <span class="header">Starter FAQ</span> <mat-divider></mat-divider> 
        </div>
          <div class="starter-faq-body">
            <div class="starter-faq-image">
              <img src="../../../assets/images/starter_faq.png" alt="Icertis info Logo"/>
            </div>
            <div class="starter-faq-questions">
              <mat-accordion>
                <mat-expansion-panel class="questions" *ngFor="let value of questions">
                    <mat-expansion-panel-header> {{ value.question }}</mat-expansion-panel-header>
                    <mat-panel-description *ngIf="!value.link">
                        {{value.answer}}
                    </mat-panel-description>
                    <mat-panel-description *ngIf="value?.link && value?.question == sharedEnum.starter_faq_question4" class="faq-link">
                      <span>• For IDN access or logistics related queries, reach out to your Icertis point of contact.</span>
                      <span>• For IDN related issues, raise a support ticket from the Icertis Support Portal. To know more, see <a href=# (contextmenu)="openSite(value.link)" (click)="openSite(value.link); $event.preventDefault();" target="_blank\">Raising support tickets for IDN.</a></span>
                      <span>• For issues related to customer implementations, raise a general support ticket from the Icertis Support Portal. </span>
                  </mat-panel-description>
                  <mat-panel-description *ngIf="value?.link && value?.question == sharedEnum.starter_faq_question5" class="faq-link">
                    <span>To deploy technical customization to the Production instance, follow the process documented in <a href=# (contextmenu)="openSite(value.link)" (click)="openSite(value.link); $event.preventDefault();" target="_blank\">Deploy to Production</a></span>
                </mat-panel-description>
                </mat-expansion-panel>
            </mat-accordion>
            </div>
          </div>
      </div>

   <div class="help-icon" *ngIf="isShow">
      <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button" (click)="gotoTop(top)">
        <mat-icon>arrow_upward</mat-icon>
      </button>
   </div>

