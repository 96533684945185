//TODO Localization

import { ChangeDetectorRef, Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MatSort, Sort } from '@angular/material/sort';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import * as _ from 'lodash';
import { MsalService } from '@azure/msal-angular';
import { ShareDataService } from 'src/app/core/services/sdk-mangement/share-data.service';
import { catchError, map, take } from 'rxjs';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ActivityNotificationService } from 'src/app/core/services/activity-notification/activity-notification.service';
import { WorkbenchMgmtStaticString } from 'src/app/shared/enum/workbenchManagement';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatIconRegistry } from '@angular/material/icon';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`
export interface PeriodicElement {
  name: string;
  description: string;
  version: string;
  filename: string;
  status: string;
  action: string;
}

export interface SDK {
  id: number;
  name: string;
}

@Component({
  selector: 'app-sdk-list',
  templateUrl: './sdk-list.component.html',
  styleUrls: ['./sdk-list.component.scss'],
})
export class SdkListComponent {
  public WbMgmtEnum = WorkbenchMgmtStaticString;
  displayedColumns: string[] = [
    'name',
    'description',
    'version',
    'fileName',
    'isFileUploaded',
    'isActive',
    'action',
  ];
  dataSource: any;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;
  sas: any;
  modulevalue = '';
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild(MatSort)
  sort!: MatSort;
  uploadsdkData: any = {};
  globalList: any;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild('name') inputName: any;
  isEditSDK: boolean = false;
  isActivateSDK: boolean = false;
  isDeactivateSDK: boolean = false;
  isViewSDK: boolean = false;
  isCreateSDK: boolean = false;
  globalFilteredList: any;
  hasGlobalDataAccess: boolean = false;
  //listing
  @ViewChild('isFileUploaded') isFileUpload!: MatSelect;
  @ViewChild('status') status!: MatSelect;
  filterVal: string = '';
  sortBy: string = '';
  pageNumber: number = 1;
  pageSize: number = 10;
  currentPage: number = 0;
  pageLength: number = 0;
  Status: any = [
    { name: 'In-Active', value: 2 },
    { name: 'Active', value: 1 },
  ];
  FileUploaded: any = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];
  selectAll = 'Select All';
  isActiveStatus: any = '';
  isFileUploaded: any = '';
  noContent: Boolean = false;
  breadCrumbItems: any = [
    { label: 'Home', path: 'resources' },
    { label: 'Development and Utilities', path: 'developmentandutilities/idn-workbench-management' },
    { label: 'IDN Workbench', path: 'developmentandutilities/idn-workbench-management' },
    { label: 'List', path: 'idn-workbench-management' },
  ];

  constructor(
    private SdkServices: SdkServiceService,
    private titleService: Title,
    private router: Router,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private sharedata: ShareDataService,
    private blobService: AzureBlobStorageService,
    private extensionservice: ExtensionService,
    private msalService: MsalService,
    private permssionService: PermissionsService,
    private activityNotifService: ActivityNotificationService,
    iconRegistry: MatIconRegistry,
    private loader: LoaderService,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }

  ngAfterViewInit() {
    this.inputName.nativeElement.value = this.filterVal;
    this.isFileUpload.value = this.isFileUploaded;
    if (!this.hasGlobalDataAccess) {
      this.isActiveStatus = 1;
    } else {
      this.status.value = this.isActiveStatus;
    }
    this.cd.detectChanges();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }
  ngOnDestroy(): void {
    const url1: string = '/home/dedevelopmentandutilities/idn-workbench-management/view';
    const url2: string = '/home/developmentandutilities/idn-workbench-management/edit';
    if (window.location.pathname != url1 && window.location.pathname != url2) {
      localStorage.removeItem('IDNManagementFilter');
    }
  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel = 'Records per page';
    // this.getSas();
    this.isEditSDK = this.permssionService.getPermission('UpdateSDK');
    this.isViewSDK = this.permssionService.getPermission('ViewSDK');
    this.isCreateSDK = this.permssionService.getPermission('CreateSDK');
    this.isActivateSDK = this.permssionService.getPermission('ActivateSDK');
    this.isDeactivateSDK = this.permssionService.getPermission('DeactivateSDK');
    this.hasGlobalDataAccess = this.permssionService.hasGlobalDataAccess();
    this.titleService.setTitle('IDN Workbench | IDN');
    this.FileUploaded = this.FileUploaded?.sort((a:any, b:any) =>{ return a.name.localeCompare(b.name);});
    this.Status = this.Status?.sort((a:any, b:any) =>{ return a.name.localeCompare(b.name);});
    try {
      this.SdkServices.on<any>()
        .pipe(take(1))
        .subscribe((data) => {
          if (data != '') {
            this.uploadsdkData = data;
            this.GetSdks();
          } else {
            this.GetSdks();
          }
          this.cd.detectChanges();
        });
      if (
        localStorage.getItem('loggedin') != null &&
        localStorage.getItem('loggedin') == 'false'
      ) {
        this.msalService.instance.logoutRedirect();
      }
      this.cd.detectChanges();
    } catch (error) {
      console.log(error);
    }
    this.SdkServices.onRefreshSDK<any>()
      .pipe(take(2))
      .subscribe((data) => {
        if (data.refreshSDK == true) {
          this.GetSdks();
          this.cd.detectChanges();
        }
      });
  }

  ngDoCheck() {}

  edit(navLink: any, element: any): void {
    let Json = {
      search: this.filterVal,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
      isFileUploaded: this.isFileUploaded,
      status: this.isActiveStatus,
    };
    localStorage.setItem('IDNManagementFilter', JSON.stringify(Json));
    this.sharedata.sendData(JSON.stringify(element));
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/developmentandutilities/' + navLink;
    }
    this.router.navigate([navLink]);
  }

  //To Get SDK  list

  GetSdks() {
    try {
      let filterValue: any =
        localStorage.getItem('IDNManagementFilter') == null
          ? ''
          : localStorage.getItem('IDNManagementFilter');
      if (
        filterValue != undefined &&
        filterValue != null &&
        filterValue != ''
      ) {
        this.filterVal = JSON.parse(filterValue).search;
        this.pageSize = JSON.parse(filterValue).pageSize;
        this.pageNumber = JSON.parse(filterValue).pageNumber;
        this.isActiveStatus =
          JSON.parse(filterValue).status != undefined &&
          JSON.parse(filterValue).status != this.selectAll
            ? JSON.parse(filterValue).status
            : '';
        this.isFileUploaded =
          JSON.parse(filterValue).isFileUploaded != undefined &&
          JSON.parse(filterValue).isFileUploaded != this.selectAll
            ? JSON.parse(filterValue).isFileUploaded
            : '';
        this.currentPage = JSON.parse(filterValue).currentPage;
      }
      if (!this.hasGlobalDataAccess) {
        this.isActiveStatus = 1;
      }
      this.SdkServices.GetSdk(
        this.filterVal,
        this.sortBy,
        this.pageNumber,
        this.pageSize,
        this.isActiveStatus,
        this.isFileUploaded
      ).subscribe((resp: any) => {
        if (resp != null && resp != undefined && resp != '') {
          this.noContent = false;
          let res: any;
          this.dataSource = new MatTableDataSource(resp.records);
          this.paginator.pageIndex = this.currentPage; //for listing
          this.paginator.length = resp.matchingCount; //for listing
          this.globalFilteredList = resp.records;
          this.globalList = resp.records;
        } else {
          this.noContent = true;
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  //To Redirect dashboard page
  navigateItem(navLink: any): void {
    if (navLink == 'dashboard') {
      navLink = 'dashboard';
    } else {
      navLink = 'home/developmentandutilities/' + navLink;
    }
    this.router.navigate([navLink]);
  }

  //To In-active SDK  Status
  DeactivateSDK(id: number, element: any) {
    if (id != 0) {
      try {
        this.dialogService
          .confirmDialog({
            title: this.WbMgmtEnum.wb_dialog_deactivate_heading,
            module: 'sdk',
            message: this.WbMgmtEnum.wb_dialog_deactivate_body,
            confirmText: this.WbMgmtEnum.wb_label_deactivate_btn,
            cancelText: this.WbMgmtEnum.wb_label_cancel_btn,
          })
          .subscribe((res) => {
            if (res) {
              this.SdkServices.Deactivate(id).subscribe((resp: Response) => {
                if (resp == null) {
                  this.notificationService.showNotification(
                    this.WbMgmtEnum.wb_notify_deactivated_body,
                    'success',
                    this.WbMgmtEnum.wb_notify_deactivated_heading
                  );
                } else {
                  this.notificationService.showNotification(
                    this.WbMgmtEnum.wb_notify_not_deactivated_body,
                    'warning',
                    this.WbMgmtEnum.wb_notify_not_deactivated_heading
                  );
                }
                this.GetSdks();
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  //To Activate SDK Status
  ActivateSDK(id: number) {
    if (id != 0) {
      try {
        this.dialogService
          .confirmDialog({
            title: this.WbMgmtEnum.wb_dialog_activate_heading,
            module: 'sdk',
            message: this.WbMgmtEnum.wb_dialog_activate_body,
            confirmText: this.WbMgmtEnum.wb_label_activate_btn,
            cancelText: this.WbMgmtEnum.wb_label_cancel_btn,
          })
          .subscribe((res: any) => {
            if (res) {
              this.SdkServices.Activate(id).subscribe((resp: Response) => {
                if (resp == null) {
                  this.notificationService.showNotification(
                    this.WbMgmtEnum.wb_notify_activated_body,
                    'success',
                    this.WbMgmtEnum.wb_notify_activated_heading
                  );
                  this.GetSdks();
                } else {
                  this.notificationService.showNotification(
                    this.WbMgmtEnum.wb_notify_not_activated_body,
                    'warning',
                    this.WbMgmtEnum.wb_notify_not_activated_heading
                  );
                }
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  downloadFile1(element: any) {
    if (element.status == 1 && element.sdkStatus == 'Active') {
      this.SdkServices.GetSDKById(element.id).subscribe((resp) => {
        if (
          resp != null &&
          resp != '' &&
          resp.status == 1 &&
          resp.sdkStatus == 'Active'
        ) {
          this.downloadFile(element);
        } else {
          this.notificationService.showNotification(
            this.WbMgmtEnum.wb_notify_wb_already_deactivate_body,
            'warning',
            this.WbMgmtEnum.wb_notify_wb_already_deactivate_heading
          );
          this.GetSdks();
        }
      });
    }
  }

  downloadFile(element: any) {
    try {
      this.loader.show();
      var FileSaver = require('file-saver');
      this.SdkServices.getBlobDownloadConnection(
        element.id,
        element.fileName
      ).subscribe(async (resp: any) => {
        if (resp != null && resp != undefined) {
          
         let fileSize = resp.blobSize / (1024 * 1024);  //convert byte to MB          

          if(fileSize >= 5){
            this.notificationService.showNotification(
              this.WbMgmtEnum.wb_notify_download_start_body,
              'success',
              this.WbMgmtEnum.wb_notify_download_start_heading
            );
          }
         
          setTimeout(() => {
            this.loader.hide();             
          }, 2000);
          
          this.blobService.downloadImageExt(
            resp.sasUrl,
            resp.container,
            resp.blob,
            (response: any) => {
              if (
                response._response != undefined &&
                response._response.status == '200'
              ) {
                response.blobBody.then((onres: any) => {
                  this.SdkServices.markDownloaded(element.id)
                    .pipe(
                      map((data: any) => {
                        this.notificationService.showNotification(
                          this.WbMgmtEnum.wb_notify_mark_download_body,
                          'success',
                          this.WbMgmtEnum.wb_notify_mark_download_heading
                        );
                        this.activityNotifService.TriggerSDKNotification(
                          element.id,
                          false
                        );
                      }),
                      catchError((err) => {
                        this.notificationService.showNotification(
                          this.WbMgmtEnum.wb_notify_not_mark_download_body,
                          'error',
                          this.WbMgmtEnum.wb_notify_not_mark_download_heading
                        );

                        console.log(err);

                        return err;
                      })
                    )
                    .subscribe();
                  FileSaver.saveAs(onres, element.fileName);
                });
              } else {
                this.notificationService.showNotification(
                  this.WbMgmtEnum.wb_notify_download_failed_body,
                  'error',
                  this.WbMgmtEnum.wb_notify_download_failed_heading
                );
              }
            }
          );
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  //To View SDK
  ViewSDK(navLink: any, element: any) {
    let Json = {
      search: this.filterVal,
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
      isFileUploaded: this.isFileUploaded,
      status: this.isActiveStatus,
    };
    localStorage.setItem('IDNManagementFilter', JSON.stringify(Json));
    this.sharedata.sendData(JSON.stringify(element));
    navLink = 'home/developmentandutilities/' + navLink;
    this.router.navigate([navLink]);
  }
  ClearInput() {
    localStorage.removeItem('IDNManagementFilter');
    if (this.inputName.nativeElement.value != '') {
      this.inputName.nativeElement.value = '';
      this.filterVal = '';
      this.GetSdks();
    }
  }
  clearFilter() {
    localStorage.removeItem('IDNManagementFilter');
    this.inputName.nativeElement.value = '';
    this.filterVal = '';
    this.sortBy = '';
    this.pageNumber = 1;
    this.pageSize = 10;
    this.isActiveStatus = '';
    this.isFileUploaded = '';
    if (this.status != undefined) {
      this.status.value = '';
    }
    this.isFileUpload.value = '';
    this.currentPage = 0;
    this.GetSdks();
  }
  public doFilter = (event: any, name: string) => {
    let filters = localStorage.getItem('IDNManagementFilter');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        pageSize: this.pageSize,
        pageNumber: 1,
        currentPage: 0,
        isFileUploaded:
          this.isFileUpload.value == undefined ||
          this.isFileUpload.value == 'Select All'
            ? ''
            : this.isFileUpload.value,
        status:
          this.status == undefined ||
          this.status.value == undefined ||
          this.status.value == 'Select All'
            ? ''
            : this.status.value,
      };
      localStorage.setItem('IDNManagementFilter', JSON.stringify(Json));
    } else {
      this.pageNumber = 1;
      this.currentPage = 0;
      this.filterVal = this.inputName.nativeElement.value; //for listing
      if (this.status != undefined) {
        this.isActiveStatus =
          this.status.value == undefined || this.status.value == 'Select All'
            ? ''
            : this.status.value;
      }
      this.isFileUploaded =
        this.isFileUpload.value == undefined ||
        this.isFileUpload.value == 'Select All'
          ? ''
          : this.isFileUpload.value;
    }
    this.GetSdks();
  };

  async checkForOption(result: any) {
    result.forEach((element: any) => {
      if (element.status == 1) {
        if (this.isViewSDK) {
          element['hideMoreOptions'] = false;
        } else {
          if (element.isViewSDK == null) {
            element['hideMoreOptions'] = true;
          }
        }
      }
      // else if (element.isViewSDK == 4) {
      //   if (element.files == null) {
      //     element['hideMoreOptions'] = true;
      //   }
      // }
    });

    return result;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
    let filters = localStorage.getItem('IDNManagementFilter');
    if (filters != null) {
      let Json = {
        search: this.inputName.nativeElement.value,
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        currentPage: this.currentPage,
        isFileUploaded:
          this.isFileUpload.value == undefined ||
          this.isFileUpload.value == 'Select All'
            ? ''
            : this.isFileUpload.value,
        status: this.isActiveStatus,
      };
      localStorage.setItem('IDNManagementFilter', JSON.stringify(Json));
    }
    this.GetSdks();
  }
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    this.GetSdks();
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.WbMgmtEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.WbMgmtEnum.redirect_dialog_body,
        confirmText: this.WbMgmtEnum.redirect_dialog_primary_btn,
        cancelText: this.WbMgmtEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          window.open(
            'https://developer-help.icertis.com/docs/setting-up-idn-workbench/',
            '_blank'
          );
        }
      });
  }
}
