//TODO Localization

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RoleService } from 'src/app/core/services/role-management/role.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { RoleMgmtStaticString } from 'src/app/shared/enum/roleManagement';
import { UserService } from 'src/app/core/services/user-management/user.service';
import { InputPattern } from 'src/app/shared/enum/inputPattern';

export interface Role {
  id: number;
  name: string;
}

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss']
})

export class EditRoleComponent implements OnInit {
  public RoleMgmtEnum = RoleMgmtStaticString;
  selectedValue: string = '';
  EditData: any;
  editRoleTimeStamp:any="";
  isTrueSet: any = true;
  UserId: any;
  roles: Role[] = [];
  IsActive: boolean = false;
  $subs!: Subscription;
  isUserAssociate: boolean = false;
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/role-management" },
    { label: "Role Management", path: "administration/role-management" },
    { label: "Edit", path: "edit" },
  ];
  isRoleActivate: boolean = false;
  isRoleDeactivate: boolean = false;
  pageHeading: string = '';
  labelName: string = '';
  labelDescription: string = '';
  labelCategory: string = '';
  errorName: string = '';
  errorDescription: string = '';
  actionSave: string = '';
  actionCancel: string = '';
  actionDeactivate: string = '';
  actionActivate: string = '';
  roleCategory : any;
  public regex = InputPattern;
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;

  constructor(
    private ApiCall: RoleService,
    private router: Router,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef,
    private roleService: RoleService,
    private dialogService: DialogService,
    private sharedata: ShareDataService,
    private permssionService: PermissionsService,
    private userService: UserService,

  ) { }


  frmRole = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.pattern(/^[^-\s][a-zA-Z0-9\s-]+$/)]),
    Description: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    UserCategoryId: new FormControl('',Validators.required),
    Active: new FormControl(true),
    Id: new FormControl('')
  });

  ngOnInit(): void {
    let id = localStorage.getItem("roleId");
    this.getUserCategories();
    this.getRolesByID(id)
    this.isRoleActivate= this.permssionService.getPermission('ActivateRole');
    this.isRoleDeactivate= this.permssionService.getPermission('DeactivateRole');
    this.pageHeading = this.RoleMgmtEnum.role_header_edit;
    this.labelName = this.RoleMgmtEnum.role_label_name;
    this.labelDescription = this.RoleMgmtEnum.role_label_description;
    this.labelCategory = this.RoleMgmtEnum.role_label_category;
    this.errorName = this.RoleMgmtEnum.role_error_roleName;
    this.errorDescription = this.regex.descriptionErrorMessage;
    this.actionSave = this.RoleMgmtEnum.role_edit_primary_btn;
    this.actionCancel = this.RoleMgmtEnum.role_cancel_btn;
    this.actionDeactivate = this.RoleMgmtEnum.role_deactivate_btn;
    this.actionActivate = this.RoleMgmtEnum.role_activate_btn;  

  }

  getRolesByID(id: any) {
    try {
      this.roleService.GetRoles().subscribe((resp: any) => {
        this.editRoleTimeStamp = resp.timestamp;
        if (resp.length > 0) {
          const res = resp.filter((d: any) => d.id == id);
          if (res.length > 0) {
            let json = res[0];
            this.EditData = json;
            this.UserId = json.id;
            this.isTrueSet = json.active;
            this.frmRole.patchValue({
              Name: json.name,
              Description: json.description,
              UserCategoryId: json.userCategoryId,
              Active: this.isTrueSet,
              Id: json.id
            });
            if(!this.isTrueSet){
              this.frmRole.controls["Description"].disable();
            }
            this.frmRole.controls["UserCategoryId"].disable();
            this.checkUserAssociateWithRole(Number(this.frmRole.controls["Id"].value));
            if (this.frmRole.value.Active) {
              this.IsActive = false;
            }
            else {
              this.IsActive = true;
            }
          }
        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  getUserCategories() {
    try {
      this.userService.GetUserCategories().subscribe((res: any) => {
        if (res != undefined && res != null && res != '') {
          this.roleCategory = res;
        }
      })
    }
    catch (e) {
      console.log('Exception :', e)
    }
  }

  checkUserAssociateWithRole(id: number) {
    if (id != null && id != undefined && id > 0) {
      this.ApiCall.RoleUserCheck(id).subscribe((resp: any) => {
        this.isUserAssociate = resp;
        this.frmRole.controls["Name"].disable();
        this.cd.detectChanges();
      });
    }
    else {
      this.router.navigate(["home/administration/role-management"]);
    }

  }

  //To Get Role List
  GetRoleCategory() {
    this.ApiCall.GetRoleCategory().subscribe((res: any) => {
      if (res) {
        this.roles = res;
      }
    })
  }

  //To Activate Role
  ActivateRole() {
    this.dialogService.confirmDialog({
      title: 'Confirm Activate',
      module: 'role',
      message: 'Are you sure you want to Activate User Role?',
      confirmText: 'Activate',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.roleService.activateRole(this.UserId, this.IsActive).subscribe((res: Response) => {
          let Result: any = res.body;
          if (res.status == 200) {
            this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_activate_body, 'success', this.RoleMgmtEnum.role_notify_activate_heading);
            this.OnCancel();

          } else {
            this.notificationService.showNotification(Result.message, 'warning', this.RoleMgmtEnum.role_notify_not_activate_heading);
            this.OnCancel();
          }
        })
      }
    })
  }

  DeactivateRole() {
    if (this.isUserAssociate) {
      this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_not_deactivate_body, 'error', this.RoleMgmtEnum.role_notify_not_deactivate_heading);
      this.OnCancel();
    }
    else {
      if (this.frmRole.touched) {
        this.dialogService.confirmDialog({
          title:  this.RoleMgmtEnum.role_dialog_deactivate_heading,
          module: 'role',
          message: this.RoleMgmtEnum.role_dialog_deactivate_msg,
          confirmText: this.RoleMgmtEnum.role_dialog_deactivate_primary_btn,
          cancelText: this.RoleMgmtEnum.role_dialog_cancel_btn
        }).subscribe((res: any) => {
          if (res) {
            this.DeactivateRoleAfterCheck();
          }
        })
      }
      else {
        this.dialogService.confirmDialog({
          title: this.RoleMgmtEnum.role_dialog_deactivate_heading,
          module: 'role',
          message: this.RoleMgmtEnum.role_dialog_deactivate_msg,
          confirmText: this.RoleMgmtEnum.role_dialog_deactivate_primary_btn,
          cancelText: this.RoleMgmtEnum.role_dialog_cancel_btn
        }).subscribe((res: any) => {
          if (res) {
            this.DeactivateRoleAfterCheck();
          }
        })
      }
    }
  }

  DeactivateRoleAfterCheck() {
    this.roleService.deActivateRole(this.UserId, this.IsActive).subscribe((res: Response) => {
      let Result: any = res.body;
      if (res.status == 200) {
        this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_deactivate_body, 'success', this.RoleMgmtEnum.role_notify_deactivate_heading);
        this.OnCancel();
      } else {
        this.notificationService.showNotification(Result.message, 'warning', this.RoleMgmtEnum.role_notify_deactivate_heading);
        this.OnCancel();
      }
    })
  }

  //--Get User By Id---
  GetUserById(UserId: any) {
    this.ApiCall.GetUserById(UserId).subscribe((resp: any) => {
      (resp.status == 200)
      {
        this.frmRole.patchValue({
          Name: resp.name,
          Description: resp.description,
          UserCategoryId: resp.UserCategoryId,
          Active: resp.active,
          Id: resp.id
        })
      }
    });
    this.cd.detectChanges();
  }

  //-- To Update Data--
  async updateRole() {
    let jsonrole: any =this.frmRole.getRawValue();
    jsonrole['timestamp'] =this.editRoleTimeStamp;
    this.ApiCall.PutData("api/Role/Update/", {}, jsonrole).subscribe((result: any) => {
      if (result != null && result != undefined) {
        if (result.status == 200) {
          this.router.navigate(["home/administration/role-management"]);
          this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_updated_success_body, 'success', this.RoleMgmtEnum.role_notify_updated_success_heading);
        }
        else {
          this.router.navigate(["home/administration/role-management"]);
          this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_updated_success_body, 'success', this.RoleMgmtEnum.role_notify_updated_success_heading);
        }
      }
    })
  }

  async checkIfRoleExsists(roleName: any, userCategoryId:any) {
    return await lastValueFrom(this.ApiCall.CheckRoleName(roleName, userCategoryId))
  }

  //-- Re-Direct to Role Listing --
  OnCancel() {
    let val = 'role-management';
    let navLink: any = 'home/administration/' + val;
    this.router.navigate([navLink]);
  }

  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescription = this.regex.descriptionErrorMessage;
      return;
    }
    if (!this.regexDescription.test(event[0])) {
      this.errorDescription = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescription = this.regex.descriptionErrorMessage;
    }
  }
}
