import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class IciWorkerTaskConfigurationGuardService {
  viewIciWorkertaskConfiguration: any;

  constructor(
    private router: Router,
    private permissionService: PermissionsService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.viewIciWorkertaskConfiguration = localStorage.getItem(
      'enableICIWorkerTaskConfigurationFeature'
    );
    if (this.viewIciWorkertaskConfiguration && this.permissionService.getPermission('ViewSDK')) {
      return true;
    } else {
      this.router.navigate(['home/access-denied']);
      return false;
    }
  }
}
