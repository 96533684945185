import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDrawer } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as JSZip from 'jszip';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { Deployment } from 'src/app/shared/enum/deployment';
import { EnvService } from 'src/app/core/services/env.service';
import { enableDisableIDNFeatures } from 'src/app/shared/enum/disable-idn-feature';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { catchError, map } from 'rxjs';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';
import { HttpErrorResponse } from '@angular/common/http';

const NO_DOCUMENT = `
    <svg xmlns="http://www.w3.org/2000/svg" width="34.536" height="37.53" viewBox="0 0 34.536 37.53">
    <g id="Group_465" data-name="Group 465" transform="translate(-45.48 -10.639)">
      <path id="Path_301" data-name="Path 301" d="M461.891,292.259H443.219a1.431,1.431,0,0,0-1.429,1.429v9.355h2.873a1.235,1.235,0,0,1,1.051.581l1,1.585.952,1.515.179.285,1.09,1.733.012.02H463.32V293.688A1.431,1.431,0,0,0,461.891,292.259Z" transform="translate(-392.926 -281.62)" fill="#ccc"/>
      <path id="Path_302" data-name="Path 302" d="M476.241,319.769H466.99a.925.925,0,1,0,0,1.849h9.251a.925.925,0,1,0,0-1.849Z" transform="translate(-414.677 -306.27)" fill="#fff"/>
      <path id="Path_303" data-name="Path 303" d="M481.367,363.449h-14.3a1.009,1.009,0,1,0,0,2.019h14.3a1.009,1.009,0,1,0,0-2.019Z" transform="translate(-414.673 -345.409)" fill="#fff"/>
      <path id="Path_304" data-name="Path 304" d="M490.425,398.01a1.009,1.009,0,0,0-.913-.581h-14.3l-.064,0a1.239,1.239,0,0,1,.456.43l1,1.585h12.91a1.008,1.008,0,0,0,.913-1.437Z" transform="translate(-422.817 -375.856)" fill="#fff"/>
      <path id="Path_305" data-name="Path 305" d="M511.237,432.282a1.009,1.009,0,0,0-1-.873H498.28l.179.285,1.089,1.733h10.689a1,1,0,0,0,.585-.188,1.008,1.008,0,0,0,.423-.821A.962.962,0,0,0,511.237,432.282Z" transform="translate(-443.543 -406.304)" fill="#fff"/>
      <path id="Path_306" data-name="Path 306" d="M524.191,310.408l-1.455-.569-7.256-2.832L506.8,303.62a1.432,1.432,0,0,0-1.852.813l-.3.779-.723,1.849-1.05,2.692-.788,2.019-.591,1.514L500.7,315.3l-.592,1.515-.017.044L500,317.1l1.09,1.733.012.02h20.18a1.239,1.239,0,0,1,.966.462l.085-.216,1.042-2.668L525,312.261A1.432,1.432,0,0,0,524.191,310.408Z" transform="translate(-445.084 -291.712)" fill="#6332a4"/>
      <path id="Path_307" data-name="Path 307" d="M571.215,339.971l-.778-.3-7.841-3.06a.925.925,0,1,0-.673,1.724l2.59,1.01,3.2,1.25,2.721,1.062.1.041a.925.925,0,1,0,.673-1.724Z" transform="translate(-500.043 -321.302)" fill="#fff"/>
      <path id="Path_308" data-name="Path 308" d="M559.8,382.74l-3.9-1.524-2.787-1.087-1.489-.581-3.878-1.514-1.26-.492a1.009,1.009,0,0,0-1.271.492c-.013.026-.025.053-.035.081a1.009,1.009,0,0,0,.573,1.307l.322.126,5.169,2.018,4.656,1.817,3.171,1.238a1.009,1.009,0,1,0,.734-1.881Z" transform="translate(-485.497 -357.974)" fill="#fff"/>
      <path id="Path_309" data-name="Path 309" d="M544.819,413.368l-.25-.1-2.45-.956-2.238-.873L536,409.926l-1.878-.733a1.009,1.009,0,1,0-.733,1.881l.94.367,4.688,1.83.482.188.05.02H545.1Z" transform="translate(-474.425 -386.335)" fill="#fff"/>
      <path id="Path_310" data-name="Path 310" d="M524.267,441.816l-.482-.188-2.016-.787a1.008,1.008,0,0,0-1.364.787.952.952,0,0,0-.011.188.118.118,0,0,0,0,.02h3.923Z" transform="translate(-463.357 -414.693)" fill="#fff"/>
      <path id="Path_311" data-name="Path 311" d="M428.833,399.906l-.482-.188h-7.544a.955.955,0,0,0-.011.188h-1.031l.012.02h9.106Zm0,0h-9.068l.012.02h9.106Zm0,0h-9.068l.012.02h9.106Zm.05.02-.05-.02h-9.068l.012.02Zm-18.194,20.818a1.243,1.243,0,0,1-1.241-1.241V395.449a1.243,1.243,0,0,1,1.241-1.242h1.935V394h-1.935a1.452,1.452,0,0,0-1.449,1.45V419.5a1.45,1.45,0,0,0,.849,1.318h18.316l-.05-.077Zm30.319-20.573a1.442,1.442,0,0,0-1.051-.453H419.893l-1.123-1.786-.027-.044-.952-1.515-1.067-1.7A1.453,1.453,0,0,0,415.5,394h-4.808a1.452,1.452,0,0,0-1.449,1.45V419.5a1.45,1.45,0,0,0,.849,1.318,1.429,1.429,0,0,0,.6.131h29.268a1.434,1.434,0,0,0,.6-.131,1.45,1.45,0,0,0,.849-1.318V401.168a1.442,1.442,0,0,0-.4-1ZM441.2,419.5a1.243,1.243,0,0,1-1.242,1.241H410.689a1.243,1.243,0,0,1-1.241-1.241V395.449a1.243,1.243,0,0,1,1.241-1.242H415.5a1.235,1.235,0,0,1,1.051.581l1,1.585.952,1.515.179.285,1.09,1.733.012.02h20.18a1.243,1.243,0,0,1,1.242,1.242Zm-12.366-19.6h-9.068l.013.02h9.106Zm0,0h-9.068l.013.02h9.106Zm0,0h-9.068l.013.02h9.106Zm0,0-.482-.188h-8.458l-1.123-1.786-.095.241,1.09,1.733.012.02h9.106Z" transform="translate(-363.76 -372.783)" fill="#3f3d56" opacity="0.4"/>
    </g>
    </svg>

    `;
const DEPLOYMENT_MENU = `
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16.224 18.192" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
<path id="Subtraction_1" data-name="Subtraction 1" d="M15.628,19.132H9.878l.031-7.04,1.633,2.982h4.086v4.057Zm-6.582,0H3.331V15.074H7.555l1.521-2.967-.03,7.024ZM17.6,14.206H12.024L9.935,10.391h5.854L17.6,14.2Zm-10.548,0H1.429l-.051-.024,1.872-3.791H9.012L7.055,14.205Zm5.591-4.683H6.468a9.211,9.211,0,0,1-.206-2.979H7.9a1.667,1.667,0,0,0,3.262,0h1.688a9.072,9.072,0,0,1-.21,2.978ZM9.536,7a.889.889,0,1,1,.851-.888A.871.871,0,0,1,9.536,7ZM7.9,5.676H6.4A8.066,8.066,0,0,1,9.528.941a8.065,8.065,0,0,1,3.186,4.734H11.167a1.667,1.667,0,0,0-3.262,0Z" transform="translate(-1.379 -0.94)" fill="#FFFFFF"></path>
</svg>
`;
const PACKAGE_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="46.13" height="46.807" viewBox="0 0 46.13 46.807">
<g id="Group_576" data-name="Group 576" transform="translate(-72.438)">
  <path id="Path_439" data-name="Path 439" d="M11075.541,384.726l22.932,11.987v23.036l-22.932-11.57Z" transform="translate(-11003 -373.052)" fill="#926e43"/>
  <path id="Path_440" data-name="Path 440" d="M11098.577,396.5l22.932-11.57v23.348l-22.932,11.576Z" transform="translate(-11003.104 -373.052)" fill="#a67e4f"/>
  <path id="Path_441" data-name="Path 441" d="M11075.438,384.726l23.035,11.987,23.095-11.987-23.095-11.778Z" transform="translate(-11003 -372.948)" fill="#d9ac80"/>
  <path id="Path_442" data-name="Path 442" d="M11083.984,380.452l5.941-3.127,22.827,12.091-5.629,2.919Z" transform="translate(-11002.896 -373.156)" fill="#f2d3ba"/>
  <path id="Path_443" data-name="Path 443" d="M11107.124,392.221l5.733-3.013v5.524l-2.919-1.251-2.814,4.065Z" transform="translate(-11003 -372.872)" fill="#d7baa4"/>
</g>
</svg>

`;

@Component({
  selector: 'app-view-instance',
  templateUrl: './view-instance.component.html',
  styleUrls: [
    './view-instance.component.scss',
    '../instance-list/instance-list.component.scss',
  ],
})
export class ViewInstanceComponent implements OnInit {
  breadCrumbItems: any;
  instanceId: any;
  ViewData: any = [];
  eventObject: any = {};
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  viewDrawer: any = false;
  isTrackStatusDrawer: boolean = false;
  currentInstanceStatusID: any;
  iciinstanceId: any;
  StatusClasses: any = [];
  isViewInstance: boolean = false;
  isApproveInstance: boolean = false;
  isRejectInstance: boolean = false;
  skuList: any = [];
  instanceStatusChanges: any = [];
  isEditInstance: boolean = false;
  isInitiateDeployment: boolean = false;
  isSubmitInstance: boolean = false;
  todayDate: any = new Date();
  public instanceEnum = InstanceManagement;
  public enableDisableIdnFeaturesEnum = enableDisableIDNFeatures;
  public deploymentEnum = Deployment;
  customerId: number = 0;
  instanceTypeId: number = 0;
  partnerAccess: any;
  isWriteUtilities:any;
  userLimit:any;
  isLoggingUtilities: any;
  instanceVersion:any;
  viewIciWorkertaskConfiguration:any;
  isIciWorkertaskConfiguration: boolean = false;
  viewApprovalDetails:any =false;
  solutionPackagePackageDetails:any=[];
displayedColumns:any =[ 
  'extensionType',
  'solutionPackageName',
  'deployedBy',
  'status'
  ]
  disableFeature: boolean = true;
  disableInstanceHealthFeature: boolean = true
  isViewSolutionPackage: boolean = false;
  listPartner: boolean =false;
  partnerData:any={
    title: 'Partner',
    list: [],
  };
  isViewICIToolLogActionsProd: boolean = false;
  isViewICIToolWriteActionsProd: boolean = false;
  hasApprovalAccessToDebuggingUtilitiesProd:boolean = false;
  loggedInUserPartnerAdmin: any;
  userCategory: any;
  userCategoryID: { [key: string]: number } = {
    Icertis: 1,
    Partner: 2,
    Customer: 3
  };
  instanceStatusEnum: any = {
    draft: 1,
    created: 7
  };
  filteredSnapshotData: any = [];
  instanceHealth: any = [];
  dataSourceInstanceHealth: any = {
    records: [],
  };
  displayedColumnsInstanceHealth: any  = [
    'name',
    'health'
  ];
  dataSourceValuesInstanceHealth: any = {
    name: {
      label: 'Name',
      data: 'key',
    },
    health: {
      label: 'Health',
      data: 'value',
    },
  }
  instanceHealthError: boolean = false;
  tableInstanceHealth: any;
  instanceHealthPanelExpanded: boolean = false;
  constructor(
    private instanceService: InstanceService,
    private router: Router,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    sanitizer: DomSanitizer,
    private sdkService: SdkServiceService,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    private permissionService: PermissionsService,
    private datePipe: DatePipe,
    private extensionservice: ExtensionService,
    private customerService: CustomerService,
    private loader: LoaderService,
  ) {
    iconRegistry.addSvgIconLiteral(
      'no_document_icon',
      sanitizer.bypassSecurityTrustHtml(NO_DOCUMENT)
    );
    iconRegistry.addSvgIconLiteral(
      'deployment_menu_light',
      sanitizer.bypassSecurityTrustHtml(DEPLOYMENT_MENU)
    );
    iconRegistry.addSvgIconLiteral(
      'package_icon',
      sanitizer.bypassSecurityTrustHtml(PACKAGE_ICON)
    );
  }

  ngOnInit(): void {
    let instanceLabel: string;
    let instancePath: string;
    this.viewIciWorkertaskConfiguration  = localStorage.getItem('enableICIWorkerTaskConfigurationFeature');
    let featuresTobeDisabled :any =  localStorage.getItem('featuresTobeDisabled');
    this.disableFeature = featuresTobeDisabled?.includes('TUDeploymentSnapshot')? true : false; 
    this.disableInstanceHealthFeature = featuresTobeDisabled?.includes('InstanceHealth')? true : false; 
    this.isIciWorkertaskConfiguration = this.permissionService.getPermission('ViewSDK');
    this.isViewInstance = this.permissionService.getPermission('ViewInstance');
    this.partnerAccess = this.permissionService.hasGlobalDataAccess();
    this.isApproveInstance =
      this.permissionService.getPermission('ApproveInstance');
    this.isRejectInstance =
      this.permissionService.getPermission('RejectInstance');
    this.isEditInstance = this.permissionService.getPermission('EditInstance');
    this.isInitiateDeployment =
      this.permissionService.getPermission('InitiateDeployment');
    this.isSubmitInstance =
      this.permissionService.getPermission('SubmitInstance');
      this.isWriteUtilities = this.permissionService.getPermission('WriteUtilities');
      this.isLoggingUtilities = this.permissionService.getPermission("LoggingUtilities");
      this.isViewSolutionPackage = this.permissionService.getPermission(
        'ViewSolutionPackage'
      );
      this.loggedInUserPartnerAdmin = this.permissionService.getPartnerDetails().id;
      this.userCategory=this.permissionService.getUsercategoryId();
    if (this.isApproveInstance) {
      instanceLabel = 'Instance Requests';
      instancePath = 'instanceoperations/instance-requests';
    } else {
      instanceLabel = 'Instance Requests';
      instancePath = 'instanceoperations/instance-requests';
    }
    this.isViewICIToolLogActionsProd = this.permissionService.getPermission('LoggingUtilities-Prod');
    this.isViewICIToolWriteActionsProd = this.permissionService.getPermission('WriteUtilities-Prod');
    this.hasApprovalAccessToDebuggingUtilitiesProd = this.permissionService.getPermission('ApprovalAccessToDebuggingUtilities-Prod');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: instancePath },
      { label: instanceLabel, path: instancePath },
      { label: 'View', path: 'instanceoperations/instance-requests/view' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    
  }

  onICITools() {
    this.iciinstanceId = this.instanceId;
      let navLink = 'home/instanceoperations/instance-requests/instance-tools-landing';
      this.router.navigate([navLink]);
  }

  async getInstanceHealth(instance:any){
    try {
      (await this.instanceService.getInstanceHealth(instance)).subscribe(async (data:any)=>{
        this.instanceHealthPanelExpanded = true;
        this.instanceHealthError = false;
        if(data){
          let computedData = Object.entries(data).map(([key, value]) =>
          {
            if(key == "ICI Search Health"){
              key = "Search Health";
              value == "Green" ? value = "Healthy" : value == "Red"? value = "Unhealthy" : value == "Yellow"? value = "Warning" : value;
            } else  if(key == "Redis Cache Hit"){
              key = "Cache Health";
              value == "Succeded" ? value = "Healthy" :  value = "Unhealthy" ;
            } else if(key == "API Call"){
              key = "API Health";
              value == "Succeded" ? value = "Healthy" :  value = "Unhealthy" ;
            } else if(key == "Tasks") {
              value = '-';
            }
             return ({ key, value })
          });
          this.instanceHealth = JSON.parse(JSON.stringify(computedData));
          this.dataSourceInstanceHealth.records = this.instanceHealth?.filter((item:any)=> {return (item.key != "Host Health" && item.key != "Tasks")});
          this.tableInstanceHealth = {
            reference: 'instanceHealth',
            displayedColumns: this.displayedColumnsInstanceHealth,
            dataSourceValues: this.dataSourceValuesInstanceHealth,
            dataSource: this.dataSourceInstanceHealth,
          };
        } else {
          this.instanceHealthPanelExpanded = false;
          this.instanceHealthError = true;
        }
        
    },(error: HttpErrorResponse) => {
      this.instanceHealthPanelExpanded = false;
      this.instanceHealthError = true;
    })
    } catch(error:any){      
      console.log(error);
      this.instanceHealthPanelExpanded = false;
      this.instanceHealthError = true;
    }
  }

  getCustomerDeploymentSnapshot(customerId:any, instanceName:any){
    try {
      this.customerService.getCustomerDeploymentSnapshot(customerId,instanceName).subscribe((resp:any)=>{
        if(resp?.length){
          this.filteredSnapshotData = resp?.filter((item:any)=> {return item.instanceId == this.ViewData?.id});
          let data = this.filteredSnapshotData[0].solutionPackagePackageDetails?.map((item:any)=>{
            item['hasExtensionAccess'] = (item.extensionPartnerIds?.includes(this.loggedInUserPartnerAdmin) || (this.loggedInUserPartnerAdmin== null && this.partnerAccess) || (this.userCategory == this.userCategoryID.Customer));
            return item;
          })
          this.solutionPackagePackageDetails = data;
        }
      })
    }catch (error) {
      console.log(error);
    }
  }


  navigateProvisionPage(instanceId: any){
    localStorage.setItem('instanceID', instanceId);
    let navLink = 'home/instanceoperations/instance-requests/provision-users';
    this.router.navigate([navLink]);
  }
  //Get all the instances
  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.viewApprovalDetails = this.ViewData.statusId != this.instanceStatusEnum.draft? true :false;
            this.userLimit = this.ViewData.partnerUserQuota;
            if(this.ViewData.instanceVersion == 'ExistingPodVersion'){
              this.instanceVersion = 'N (Current)';
            } else if(this.ViewData.instanceVersion == 'ExistingPodVersion-1'){
              this.instanceVersion = 'N-1 (Previous)';
            }
            if(!this.disableFeature){
              this.getCustomerDeploymentSnapshot(this.ViewData.customerId, this.ViewData.cname)
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  navigate(element:any){
    if (this.isViewSolutionPackage) {
      element['fromCustomerPcakageDeployedSummary'] = true;
      element['id'] = element?.extensionId;
      element['solutionPackageId'] = element?.solutionPackageId;
      this.extensionservice.emitExtDetails(element);
      let navLink: any =
        'home/developmentandutilities/extension-management/view';
      this.router.navigate([navLink]);
    } else {
      this.notificationService.showNotification(
        'You do not have permission to view package',
        'warning',
        'Authorisation Error'
      );
    }
  }

  //downloadFile
  downloadSingleFile(instanceId: any, file: any) {
    try {
      var FileSaver = require('file-saver');
      this.instanceService
        .getBlobDownloadConnection(instanceId, file)
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              (response: any) => {
                if (response._response.status == 200) {
                  response.blobBody.then((onres: any) => {
                    FileSaver.saveAs(onres, file);
                  });
                  this.notificationService.showNotification(
                    this.instanceEnum.instance_notify_documentsDownloaded_body,
                    'success',
                    this.instanceEnum
                      .instance_notify_documentsDownloaded_heading
                  );
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum
                      .instance_notify_documentsNotDownloaded_body,
                    'error',
                    this.instanceEnum
                      .instance_notify_documentsNotDownloaded_heading
                  );
                }
              }
            );
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  Oncancel() {
    let currentPage = localStorage.getItem('pageIndex');
    this.eventObject['pageIndex'] = currentPage;
    this.sdkService.emit<any>(this.eventObject);
    let instance: any = this.isApproveInstance
      ? 'instance-requests'
      : 'instance-requests';
    this.router.navigate(['home/instanceoperations/' + instance]);
  }

  requestForApproval(instanceId: any, reqDate: string) {
    this.dialogService
      .confirmDialog({
        title: 'Submit for Approval',
        module: 'instance',
        message: 'Are you sure you want to Request for instance approval?',
        confirmText: 'Submit',
        cancelText: 'Cancel',
      })
      .subscribe((res: any) => {
        if (res) {
          let dateResult = this.checkDate(reqDate);
          if (dateResult) {
            this.notificationService.showNotification(
              this.instanceEnum.instance_notify_cannotSendApproval_body,
              'error',
              this.instanceEnum.instance_notify_cannotSendApproval_heading
            );
          } else {
            this.instanceService
              .submitForApproval(instanceId)
              .subscribe((resp: any) => {
                if (resp.status == 200) {
                  this.notificationService.showNotification(
                    this.instanceEnum.instance_notify_instanceRequested_body,
                    'success',
                    this.instanceEnum.instance_notify_instanceRequested_heading
                  );
                  this.getInstanceByID();
                }
              });
          }
        }
      });
  }

  checkDate(instanceDate: any) {
    let instDate: any = this.datePipe.transform(instanceDate, 'MM/dd/yyyy');
    let todaysDate: any = this.datePipe.transform(this.todayDate, 'MM/dd/yyyy');
    let instanceModDate = new Date(instDate);
    let currentModDate = new Date(todaysDate);
    if (instanceModDate.getTime() < currentModDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  approveOrReject(id: any) {
    localStorage.setItem('instanceID', id);
    let navLink = 'home/instanceoperations/instance-requests/instance-approval';
    this.router.navigate([navLink]);
  }

  closedrawer(event: any) {
    this.viewDrawer = false;
    this.getInstanceByID();
  }

  toggleTrackStatus(statusID: any, instanceStatusChanges: any) {
    this.currentInstanceStatusID = statusID;
    this.instanceStatusChanges = instanceStatusChanges.filter(
      (obj: any, pos: any, arr: any) => {
        return (
          arr.map((mapObj: any) => mapObj.statusId).indexOf(obj.statusId) == pos
        );
      }
    );
    for (let i = 1; i <= 7; i++) {
      if (i <= statusID) {
        this.StatusClasses.push('completed');
      } else if (i == statusID + 1) {
        this.StatusClasses.push('progress');
      } else {
        this.StatusClasses.push('noClass');
      }
    }
    if (statusID == 4) {
      this.StatusClasses.splice(6, 0, 'progress');
      this.StatusClasses.join();
    }
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
  }
  closeTrackStatus() {
    this.isTrackStatusDrawer = !this.isTrackStatusDrawer;
    this.StatusClasses = [];
  }
  navigateDeploymentPage(customerId: any) {
    let navLink = 'home/instanceoperations/instance-requests/initiate-deployment';
    this.router.navigate([navLink]);
  }

  //Download zip files
  downloadAllfiles = async () => {
    try {
      const zip = new JSZip();
      var FileSaver = require('file-saver');
      let downloadedFiles: any = [];
      let fileName = 'Instance request documents';
      this.ViewData.files.forEach((x: any) => {
        this.instanceService
          .getBlobDownloadConnection(this.ViewData.id, x)
          .subscribe((resp: any) => {
            if (resp != null && resp != undefined) {
              this.blobService.downloadImageExt(
                resp.sasUrl,
                resp.container,
                resp.blob,
                (response: any) => {
                  if (
                    response._response != undefined &&
                    response._response.status == '200'
                  ) {
                    response.blobBody.then((onres: any) => {
                      downloadedFiles.push([x, onres]);
                      if (
                        this.ViewData.files.length == downloadedFiles.length
                      ) {
                        downloadedFiles.forEach((item: any) => {
                          zip.file(item[0], item[1]);
                        });
                        zip
                          .generateAsync({ type: 'blob' })
                          .then(function (content) {
                            FileSaver.saveAs(content, fileName);
                          });
                        this.notificationService.showNotification(
                          this.instanceEnum
                            .instance_notify_documentsDownloaded_body,
                          'success',
                          this.instanceEnum
                            .instance_notify_documentsDownloaded_heading
                        );
                      }
                    });
                  } else {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_documentsNotDownloaded_body,
                      'error',
                      this.instanceEnum
                        .instance_notify_documentsNotDownloaded_heading
                    );
                  }
                }
              );
            }
          });
      });
    } catch (error) {
      console.log(error);
    }
  };

  // Download Instance Provisioning Log
  downloadProvisioningLog = async (id: number, type: string) => {
    const datepipe: DatePipe = new DatePipe('en-US');
    try {
      let FileSaver = require('file-saver');
      let dateTime = new Date();
      let fileNameTimeStamp = datepipe.transform(
        dateTime,
        'dd_MMMM_YYYY_HH_mm_ss'
      );
      let fileName =
        'Instance_' + id + '_Provisioning_log_' + fileNameTimeStamp;
      this.instanceService
        .getBlobDownloadConnectionLog(id)
        .subscribe((resp: any) => {
          if (resp != null && resp != undefined) {
            this.blobService.downloadImageExt(
              resp.sasUrl,
              resp.container,
              resp.blob,
              (response: any) => {
                if (
                  response._response != undefined &&
                  response._response.status == 200
                ) {
                  response.blobBody.then((onresponse: any) => {
                    FileSaver.saveAs(onresponse, fileName);
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_provisioning_log_success_body,
                      'success',
                      this.instanceEnum
                        .instance_notify_provisioning_log_success_heading
                    );
                  });
                } else {
                  if (response.status == 404) {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_404_body,
                      'warning',
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_heading
                    );
                  } else {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_body,
                      'warning',
                      this.instanceEnum
                        .instance_notify_provisioning_log_failed_heading
                    );
                  }
                }
              }
            );
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  openSite(siteUrl:any) {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.instanceEnum.redirect_dialog_body,
      confirmText: this.instanceEnum.redirect_dialog_primary_btn,
      cancelText: this.instanceEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open("https://" + siteUrl, '_blank');
      }
    });
 }

 iciWorkertaskConfiguration(instanceId: any) {
    localStorage.setItem('instanceID', instanceId);
    let navLink = 'home/instanceoperations/instance-requests/ici-workertask-configuration';
    this.router.navigate([navLink]);
  }

  
  closeListDrawer(event:any){
    if(event == false){
      this.listPartner = event
    } else{
      this.listPartner = true;
      this.partnerData = {
        title:"Partner",
        list: event.partners
      }
    }
  }

  isDisabled(e: any) {
    return e.scrollWidth <= e.clientWidth;
  }

}

const dummyData = {
  "Date": "12/06/2024 11:54:33",
  "Host Health": "Running",
  "ICI Search Health": "Green",
  "Redis Cache Hit": "Succeded",
  "API Call": "Succeded",
  "Tasks": "\r\n \r\nName Failed Count Last Successful Last Failed \r\nAllTasks 6 12/06/2024 12/06/2024 \r\nReportDataProcessorTask 6   NA   12/06/2024 \r\n \r\n"
}
