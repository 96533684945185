<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [auditLogData]="'instance'" [mapRole]="true" [applyFilter]="false"
    [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

    <div class="page-container">
        <div class="idn-acc-wrapper">
            <mat-card class="idn-acc-list-card">
                <mat-card-header class="card-header">
                    <mat-card-title class="card-title">{{instanceEnum.instance_card_header_non_idn_user_instance}}</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content class="idn-view-body">
                    <div class="card-content">
        
                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">
                                {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_customerName}}
                            </div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.customerName}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.customerName}}</div>
                        </div>
                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">
                                {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_cName}}</div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.cname}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.cname}}</div>
                        </div>

                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">
                                {{instanceEnum.instance_Create_Field_typeof_instance}}</div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.typeOfInstanceName}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.typeOfInstanceName}}</div>
                        </div>
        
                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">
                                {{instanceEnum.instance_Create_Field_environment}}</div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.instanceType}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.instanceType}}</div>
                        </div>

                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">{{instanceEnum.instance_List_Header_requestorDetails}}</div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.requesterdDetail}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.requesterdDetail}} <br> on
                                {{ViewData.createdAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</div>
                        </div>

                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">{{instanceEnum.instance_label_sfdc_customerId}}</div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.sfdcCustomerId}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.sfdcCustomerId}}
                            </div>
                        </div>

                        <div class="idn-view-body-items">
                            <div class="idn-view-body-items-label">{{instanceEnum.instance_label_netsuiteId}}</div>
                            <div class="idn-view-body-items-data" matTooltip="{{ViewData.netSuiteId}}" matTooltipPosition="below"  matTooltipClass="my-tooltip">{{ViewData.netSuiteId}}
                            </div>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <mat-stepper class="stepper" linear #stepper aria-busy="true">
            <mat-step #firstStep [stepControl]="firstStepControl" completed=false>
                <br>
                <ng-template matStepLabel>{{instanceEnum.instance_approve_infrastructure_details}} <br>
                    <h6>
                        <mat-hint class="info">{{instanceEnum.instance_approve_infrastructure_details_hint}}
                        </mat-hint>
                    </h6>
                </ng-template>
                <form class="pss-dashboard-form" [formGroup]="frmApproveInstance">
                        <div class="pss-left-panel">
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_Create_Field_region}}</mat-label>
                                <mat-select formControlName="regionId" disableOptionCentering required
                                    (selectionChange)="getMultiTenantPod()">
                                    <mat-option *ngFor="let region of deploymentRegions" [value]="region.id">
                                        {{region.displayName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="frmApproveInstance.controls.regionId.invalid">
                                    {{instanceEnum.instance_create_error_message_requiredField}} </mat-error>
                            </mat-form-field>
    
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_Create_Field_subscriptions}}</mat-label>
                                <mat-select formControlName="subscriptionId" disableOptionCentering required
                                    (selectionChange)="onSubscriptionChange()">
                                    <mat-option *ngFor="let subscription of subscriptionsList"
                                        [value]="subscription.id">
                                        {{subscription.subscriptionName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="frmApproveInstance.controls.subscriptionId.invalid">
                                    {{instanceEnum.instance_create_error_message_requiredField}}</mat-error>
                            </mat-form-field>
    
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_View_Field_pod}}</mat-label>
                                <mat-select matNativeControl disableOptionCentering formControlName="multiTenantPODId"
                                    required (selectionChange)="updatePodCode($event)">
                                    <mat-option *ngFor="let multitenant of multiTenantPods" [value]="multitenant.id">
                                        {{multitenant.masterTenantCname}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="frmApproveInstance.controls.multiTenantPODId.invalid">{{instanceEnum.instance_create_error_message_requiredField}}</mat-error>
                            </mat-form-field>
                        
                        </div>
                        <div class="pss-right-panel ">
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_View_Field_abbr}}</mat-label>
                                <input type="text" formControlName="cnamewrk" matInput
                                    placeholder="CName Abbreviation (should start with 'imt')" maxlength="9" [ngModel]="inputCnamewrkvalue"
                                    (ngModelChange)="inputCnamewrkvalue = $event?.toLowerCase()" required>
                                <mat-error *ngIf="frmApproveInstance.controls.cnamewrk.invalid">{{instanceEnum.instance_approve_cname_error_message}} </mat-error>
                            </mat-form-field>

                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_View_Field_podcode}}</mat-label>
                                <input type="text" maxlength="50" formControlName="podCode" matInput
                                    placeholder="PODCODE" required>
                                <mat-error *ngIf="frmApproveInstance.controls.podCode.invalid">{{instanceEnum.instance_create_error_message_requiredField}}</mat-error>
                            </mat-form-field>

                        </div>
                </form>
            </mat-step>
            <mat-step #secondStep [stepControl]="secondStepControl" completed=false>
                <br>
                <ng-template matStepLabel>{{instanceEnum.instance_approve_instance_properties}}<br>
                    <h6>
                        <mat-hint class="info">{{instanceEnum.instance_approve_instance_properties_hint}}
                        </mat-hint>
                    </h6>
                </ng-template>
                
                <form class="pss-dashboard-form" [formGroup]="frmApproveInstance">
                        <div class="pss-left-panel">
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_Create_Field_type}}</mat-label>
                                <mat-select matNativeControl disableOptionCentering formControlName="instanceTypeId"
                                    required>
                                    <mat-option *ngFor="let type of instanceType" [value]="type.id">
                                        {{type.infraDBInstanceTypeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="frmApproveInstance.controls.instanceTypeId.invalid">{{instanceEnum.instance_create_error_message_requiredField}}</mat-error>
                            </mat-form-field>

                            <mat-form-field class="pss-block-form" >
                                <mat-label>{{instanceEnum.instance_View_Field_instanceVersion}}</mat-label>
                                <input type="text" formControlName="InstanceVersion" matInput
                                    placeholder="Instance Version" maxlength="50" required>                                
                            </mat-form-field>
    
                            <mat-form-field class="pss-block-form" >
                                <mat-label>{{instanceEnum.instance_View_Field_actions}}</mat-label>
                                <input type="text" formControlName="iciVersion" matInput
                                    placeholder="ICI Version" maxlength="15" [(ngModel)]="inputiciVersionvalue"
                                     required>
                                     <mat-error *ngIf="frmApproveInstance.controls.iciVersion.invalid">
                                        {{instanceEnum.instance_approve_iciversion_error}}
                                    </mat-error>                                
                            </mat-form-field>
      
                                                     
                        </div>
                        <div class="pss-right-panel "> 
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_approve_field_billing_type}}</mat-label>
                                <mat-select matNativeControl disableOptionCentering formControlName="billingTypeId"
                                    required>
                                    <mat-option *ngFor="let type of billingTypes" [value]="type.id">
                                        {{type.billingTypeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="frmApproveInstance.controls.billingTypeId.invalid">{{instanceEnum.instance_create_error_message_requiredField}}</mat-error>
                            </mat-form-field>

                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_Create_Field_environments}}</mat-label>
                                <mat-select formControlName="InstanceTagId" disableOptionCentering required>
                                    <mat-option *ngFor="let environment of environmentList"
                                        [value]="environment.id">
                                        {{environment.instanceTagName}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="frmApproveInstance.controls.InstanceTagId.invalid">
                                    {{instanceEnum.instance_create_error_message_requiredField}}</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field class="pss-block-form">
                                <mat-label>{{instanceEnum.instance_view_field_expiration_date}}</mat-label>
                                <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="expirationDate" readonly>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error
                                *ngIf="!frmApproveInstance.controls.expirationDate.valid">
                                {{instanceEnum.instance_create_error_message_futureDate}} </mat-error>
                            </mat-form-field>
                              
                        </div>
                </form>
            </mat-step>
            <mat-step #thirdStep [stepControl]="thirdStepControl" completed=false>
                <br>
                <ng-template matStepLabel>{{instanceEnum.instance_approve_checklist}} <br>
                    <h6>
                        <mat-hint class="info">{{instanceEnum.instance_approve_checklist_hint}}
                        </mat-hint>
                    </h6>
                </ng-template>
                <span>
                    <ul>
                      <li *ngFor="let list of checkList">
                        <mat-checkbox [(ngModel)]="list.isSelected">
                          {{list.data}}
                        </mat-checkbox>
                      </li>
                    </ul>
                  </span>
            </mat-step>
        </mat-stepper>
        <div class="pss-form-button-wrapper">
            <button mat-stroked-button color="primary" class="idn-btn" *ngIf="stepper.selectedIndex != 0" (click)="onPrevious(ViewData.id)" [disabled]="validateDraft()"> {{instanceEnum.instance_previous_btn}}</button>
            <button mat-raised-button color="primary" *ngIf="stepper.selectedIndex != 2" (click)="onNext(ViewData.id)" [disabled]="validateDraft()"> {{instanceEnum.instance_next_btn}}</button>
            <button mat-raised-button color="primary" (click)="saveAsDraft(ViewData.id, true)" [disabled]="validateDraft()">{{instanceEnum.instance_draft_btn}}</button>
            <button mat-raised-button color="primary" *ngIf="isApproveInstance && stepper.selectedIndex == 2" (click)="approveInstance(ViewData.id)" [disabled]="validateApprove()">
                <mat-icon class="icon-mr">check_circle</mat-icon><span>{{instanceEnum.instance_approve_btn}}</span>
            </button>
            <button mat-stroked-button class="pss-btn-danger" *ngIf="isRejectInstance "
                (click)="rejectInstance(ViewData.id)">
                <mat-icon>cancel</mat-icon> <span>{{instanceEnum.instance_reject_btn}}</span>
            </button>
            <button mat-stroked-button (click)="onCancel()">{{instanceEnum.instance_cancel_btn}}</button>
          </div>
    </div>
