<app-pageheader [pageTitle]="'Instance Requests'" [auditLog]="true" [auditLogData]="'instance'"
  [createInstance]="isAddInstance" [mapRole]="true" [applyFilter]="false" [clearFilter]="false" [archive]="false"
  [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div class="page-container">
  <app-idn-list-drawer *ngIf="listPartner" [data]="partnerData" (closeEvent)="closeListDrawer($event)"></app-idn-list-drawer>
  <div class="idn-filter">
    <form class="idn-grid-filter">
      <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">

        <mat-label>{{instanceEnum.instance_Filter_Search_label_searchInstance}}</mat-label>
        <input type="text" (keydown.enter)="doFilter()" #search_input matInput
          placeholder="Search by Type, cname and customer name...">
        <span class="idn-custom-search" matSuffix>
          <mat-icon (click)="doFilter()">search</mat-icon>
          <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
            <mat-icon>close</mat-icon>
          </button>
        </span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{instanceEnum.instance_Filter_label_status}}</mat-label>
        <mat-select (selectionChange)="doFilter()" #instanceStatus>
          <mat-option [value]="selectAll"> All</mat-option>
          <mat-option *ngFor="let stat of statusList" [value]="stat.id">
            {{stat.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <button mat-stroked-button color="primary" (click)="clearFilter()">
      <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
      <span>Clear Filter</span>
    </button>
  </div>

  <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" matSort style="width: 100%" matSort #empTbSort="matSort"
      (matSortChange)="announceSortChange($event)" class="idn-grid-position" *ngIf="!noContent; else noContentTemplate">

      <!-- Type Column -->
      <ng-container matColumnDef="instanceType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
          {{instanceEnum.instance_List_Header_type}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.instanceType}} </td>
      </ng-container>

       <!-- Partner Name Column -->
      <ng-container matColumnDef="partnerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{instanceEnum.instance_List_Header_partnerName}}
        </th>
        <td mat-cell *matCellDef="let element" class="extNameStyle">  
          <div class="columnStyle">
            <div class="overflow-data" matTooltip="{{element.partners[0]?.partnerName}}"
              matTooltipClass="my-tooltip" matTooltipPosition="below">{{element.partners[0]?.partnerName ? element.partners[0]?.partnerName : 'NA'}}</div><a *ngIf="element.partners?.length > 0" aria-label="View partners" class="idn-grid-rec-count"
              href="javascript:void (0)" (click)="closeListDrawer(element)">{{0>element.partners?.length - 1
              ? '' : element.partners?.length-1 >0 ? '+'+(element.partners?.length-1) : ''}}</a>
          </div>
        </td>
      </ng-container>

      <!-- customerName Column -->
      <ng-container matColumnDef="customerName" *ngIf="!isCustomerUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
          {{instanceEnum.instance_List_Header_customerName}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.customerName}} </td>
      </ng-container>

      <!-- cname Column -->
      <ng-container matColumnDef="cname">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
          {{instanceEnum.instance_List_Header_cName}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.statusId == instanceStatusEnum.created" class="cname-link"><a href="#" 
            (contextmenu)="openSite(element.cname)" (click)="openSite(element.cname); $event.preventDefault();">{{element.cname}} </a> <mat-icon (click)="openSite(element.cname)">open_in_new</mat-icon></div>
          <span *ngIf="element.statusId != instanceStatusEnum.created">{{element.cname}}</span>
        </td>
      </ng-container>

      <!-- iciversion Column -->
      <ng-container matColumnDef="iciversion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{instanceEnum.instance_View_Field_actions}}
        </th>
        <td mat-cell *matCellDef="let element">{{element.iciVersion}}</td>
      </ng-container>

      <!-- Required by Date Column -->
      <ng-container matColumnDef="requiredBy" *ngIf="!this.isApproveInstance">
        <th mat-header-cell *matHeaderCellDef>
          {{instanceEnum.instance_List_Header_requiredByDate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.requiredBy.slice(0, 10) | date:'dd MMMM, YYYY'}} </td>
      </ng-container>

      <!-- Required by Date Column -->
      <ng-container matColumnDef="requesterdDetail" *ngIf="this.isApproveInstance">
        <th mat-header-cell *matHeaderCellDef>
          {{instanceEnum.instance_List_Header_requestorDetails}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.requesterdDetail}} <br>
          <span class="date-requiredby">Required by: {{element.requiredBy | date:
            'dd MMMM, YYYY'}} </span>
        </td>
      </ng-container>


      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{instanceEnum.instance_List_Header_status}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="pss-chips-status">
            <mat-icon *ngIf="element.statusId === 1" class="status colorDraft">bookmark</mat-icon>
            <mat-icon *ngIf="element.statusId === 2" class="status colorSuccess">fact_check</mat-icon>
            <mat-icon *ngIf="element.statusId === 3" class="status colorSuccess">check_circle</mat-icon>
            <mat-icon *ngIf="element.statusId === 4" class="status colorWarning">auto_mode</mat-icon>
            <mat-icon *ngIf="element.statusId === 5" class="status colorWarning">warning</mat-icon>
            <mat-icon *ngIf="element.statusId === 6" class="status colorError">dangerous</mat-icon>
            <mat-icon *ngIf="element.statusId === 7" class="status colorSuccess">beenhere</mat-icon>
            <span class="label">{{element.status}}</span>
          </div>
          <mat-progress-bar [color]="color" [mode]="mode" [value]="value"
            *ngIf="uploadextData.cname == element.cname && uploadextData.showProgressBar == 'true' ">
          </mat-progress-bar>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{instanceEnum.instance_List_Header_actions}}
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="View" (click)="viewInstance('view',element)" matTooltip="View Instance">
            <mat-icon class="view-icon">remove_red_eye</mat-icon>
          </button>
          <button [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="Instance Actions"
            matTooltip="Instance Actions">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #gridMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngIf="element.isInstanceCreatedInHouse == 'Yes'" (click)="toggleTrackStatus(element.statusId,element.instanceStatusChanges)">
              <mat-icon>touch_app</mat-icon> {{instanceEnum.instance_List_Action_Label_trackStatus_btn}}
            </button>
            <button mat-menu-item *ngIf="(element.statusId == instanceStatusEnum.draft || element.statusId == instanceStatusEnum.created) && isEditInstance" (click)="edit('edit',element)">
              <mat-icon>mode</mat-icon> {{instanceEnum.instance_List_Action_Label_Edit_btn}}
            </button>

            <button mat-menu-item *ngIf="isSubmitInstance && element.statusId === instanceStatusEnum.draft"
              (click)="approveRequest(element.id, element.requiredBy)">
              <mat-icon>call_split</mat-icon> {{instanceEnum.instance_List_Action_Label_submitForApproval_btn}}
            </button>

            <button mat-menu-item *ngIf="element.statusId == 2 && isApproveInstance"
              (click)="approveOrReject(element.id)">
              <mat-icon>rule</mat-icon> {{instanceEnum.instance_List_Action_Label_ApproveReject_btn}}
            </button>
            <button mat-menu-item *ngIf="element.statusId == 7 && (isLoggingUtilities || isWriteUtilities || isViewICIToolLogActionsProd || isViewICIToolWriteActionsProd || hasApprovalAccessToDebuggingUtilitiesProd)" (click)="onICITools(element.id)">
              <mat-icon>settings</mat-icon> {{instanceEnum.instance_List_Action_Label_iciTools_btn}}
            </button>
            <button mat-menu-item *ngIf="element.statusId === 7 && isInitiateDeployment "
              (click)="navigateDeploymentPage(element.id)">
              <mat-icon class="mr-1">account_tree</mat-icon>
              {{instanceEnum.instance_List_Action_Label_initiatePackageDeployment_btn}}
            </button>
            <button mat-menu-item *ngIf="element.files!=null" (click)="downloadAllfiles(element.files,element.id)">
              <mat-icon>save_alt</mat-icon> {{instanceEnum.instance_List_Action_Label_downloadDocuments}}
            </button>
            <button mat-menu-item (click)="downloadProvisioningLog(element.id, element.instanceType)"
              *ngIf="element.statusId === 5 || element.statusId === 7">
              <div class="idn-long-menu-items">
                <mat-icon>download</mat-icon>
                <span class="idn-long-label">{{instanceEnum.instance_List_Action_Label_downloadLogs}}</span>
              </div>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row
        *matHeaderRowDef="this.isApproveInstance?displayedColumnsOps:displayedColumnsAdmin; sticky: true">
      </tr>
      <tr mat-row *matRowDef="let row; columns: this.isApproveInstance?displayedColumnsOps:displayedColumnsAdmin;"></tr>
    </table>
  </div>
  <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="10" class="mat-paginator-sticky"
    (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
    aria-label="Select page of periodic elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
  </mat-paginator>
</div>

<mat-drawer-container class="idn-drawer-internal" *ngIf="isTrackStatusDrawer">
  <mat-drawer #drawerInfo class="idn-drawer">
    <div class="header">
      <div class="heading">
        <mat-icon>touch_app</mat-icon>
        <h2>{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_heading}}</h2>
      </div>
      <button mat-icon-button (click)="closeTrackStatus()" aria-label="close Button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="body pt-1">
      <div class="page-container">
        <div class="idn-view-wrapper">
          <div class="idn-view-body">
            <div class="idn-info">
              <mat-icon>info</mat-icon>
              <h4>
                {{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_screenMessage}}
              </h4>
            </div>
            <div class="idn-track-status">
              <div class="steps" id="1" *ngIf="instanceStatusChanges[0].statusId == 1">
                <span class={{StatusClasses[0]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceRqstCreated}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 1">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" id="2">
                <span class={{StatusClasses[1]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceRequested}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 2">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID!=6" id="3">
                <span class={{StatusClasses[2]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>
                    {{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationScheduled}}
                  </h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 3">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID!=6" id="4">
                <span class={{StatusClasses[3]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>
                    {{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationInProgress}}
                  </h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 4">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID==5 && currentInstanceStatusID!=6" id="5">
                <span class="rejected">
                  <mat-icon>dangerous</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationFailed}}
                  </h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 5">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID==6" id="6">
                <span class="rejected">
                  <mat-icon>dangerous</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreationRejected}}
                  </h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 6">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" class={{StatusClasses[6]}}
                *ngIf="currentInstanceStatusID!=5 && currentInstanceStatusID!=6" id="7">
                <span class="currentInstanceStatusID == '4'? progress: {{StatusClasses[6]}}">
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_instanceCreated}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 7">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pss-drawer-button-wrapper">
      <button mat-stroked-button
        (click)="closeTrackStatus()">{{instanceEnum.instance_List_initiateDeployment_customDrawerTrackStatus_field_Close_btn}}</button>
    </div>
  </mat-drawer>
</mat-drawer-container>

<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71"
      viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)"
            fill="#515165" />
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
          transform="translate(3187.267 7517.8)" fill="#7c7c97" />
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
          transform="translate(3192.911 7517.8)" fill="#515165" />
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
          transform="translate(3178.801 7551.663)" fill="#ababc5" />
        <path id="Union_2" data-name="Union 2"
          d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
          transform="translate(3178.801 7617.272)" fill="#7c7c97" />
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
          transform="translate(3192.911 7551.663)" fill="#7c7c97" />
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)"
            fill="#cfdae2" />
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
            transform="translate(8.466 8.466)" fill="#f6f2f1" />
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
          clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1"
            d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
            transform="translate(-14.11 0)" fill="#515165" />
        </g>
      </g>
    </svg>
    <h4>{{ instanceEnum.instance_no_result_msg }}</h4>
  </div>
</ng-template>