import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {

  transform(str:any): unknown {
    let regex :any= /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
    if(regex.test(str)){
      const d :any = new Date(str).toDateString()
      const nd:any= d.split(' ');
      return nd[2]+' '+nd[1]+' '+nd[3]+' at '+(new Date(str).getUTCHours() < 10 ? '0' : '') + new Date(str).getUTCHours()+':'+(new Date(str).getUTCMinutes() < 10 ? '0' : '') + new Date(str).getUTCMinutes() +' (UTC)';
    }else if(str == "01/01/0001"){
      return "--"
    }else{
      return str;
    }
  }

}
