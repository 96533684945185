<div class="idn-acc-wrapper">
    <mat-card class="idn-acc-list-card">
        <mat-card-header class="card-header">
            <mat-card-title class="card-title">{{ViewData?.title}}</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="idn-view-body">
            <div class="card-content">
                <div *ngFor="let entries of ViewData?.records">
                    <div class="idn-view-body-items" id="{{entries?.id}}">
                        <div class="idn-view-body-items-label">
                            {{entries?.label}} <mat-icon *ngIf="entries?.info" matTooltip="{{entries?.info}}" matTooltipClass="card-tooltip">info</mat-icon>
                        </div>
                        <div class="idn-view-body-items-data" matTooltip="{{entries?.data}}">{{entries?.data}}</div>
                    </div>
            </div>
            </div>
        </mat-card-content>
    </mat-card>

</div>
