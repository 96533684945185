import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IciComponentDeploymentService } from 'src/app/core/services/iciComponentDeployment/ici-component-deployment.service';
import { ICIComponentDeploymentManagement } from 'src/app/shared/enum/iciComponentDeployment';

@Component({
  selector: 'app-list-ici-deployment',
  templateUrl: './list-ici-deployment.component.html',
  styleUrls: ['./list-ici-deployment.component.scss'],
})
export class ListIciDeploymentComponent implements OnInit {
  public iciComponenetDeploymentEnum = ICIComponentDeploymentManagement;
  breadCrumbItems: any;
  noContent: boolean = false;
  displayedColumns: string[] = [
    'cnameToEnter',
    'iciVersion',
    'servicenowticketid',
    'requestedBy',
    'status',
    'action',
  ];
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  currentPage = 0;
  pageLength: any = 0;
  pageNumber: any = 1;
  pageSize: any = 10;
  status: any = '';
  filter: any = '';
  sortBy: string = '';
  startDate: string = '';
  endDate: string = '';
  ViewData: any;

  constructor(
    private router: Router,
    private titleService: Title,
    private iciComponentDeploymentService: IciComponentDeploymentService
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('ICI SKU Deployment | IDN');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'SKU Management', path: 'sku-management/ici-component-deployment' },
      { label: 'ICI SKU Deployment', path: 'sku-management/ici-component-deployment' },
      { label: 'List', path: 'ici-component-deployment/list' },
    ];
    this.getDeploymentListData();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
    this.getDeploymentListData();
  }

  navigateItem(navLink: any, skudeploymentId: any): void {
    let Json = {
      search: this.filter,
      status: this.status,
      pagesize: this.pageSize,
      pageNumber: this.pageNumber,
      currentPage: this.currentPage,
    };
    localStorage.setItem('FilterRecordIciSkuDep', JSON.stringify(Json));
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/sku-management/' + navLink;
    }
    localStorage.setItem('skudeploymentId', skudeploymentId);
    this.router.navigate([navLink]);
  }

  getDeploymentListData() {
    try {
      this.iciComponentDeploymentService
        .getAllDeployementList(
          this.filter,
          this.sortBy,
          this.pageNumber,
          this.pageSize,
          this.status,
          this.startDate,
          this.endDate
        )
        .subscribe((resp: any) => {
          if (resp != undefined && resp != null) {
            this.ViewData = resp.records;
            this.noContent = false;
            this.paginator.length = resp.matchingCount;
          } else {
            this.noContent = true;
            this.ViewData = [];
          }
          this.dataSource = new MatTableDataSource<any>(this.ViewData);
          this.empTbSort.disableClear = true;
          this.paginator.pageIndex = this.currentPage;
        });
    } catch (error) {
      console.log(error);
    }
  }

    /** Announce the change in sort state for assistive technology. */
    announceSortChange(sortState: Sort) {
      if (sortState.direction == 'desc') {
        this.sortBy = sortState.active + '_' + sortState.direction;
      } else {
        this.sortBy = sortState.active;
      }
      this.getDeploymentListData();
    }
}
