import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-idn-search-box',
  templateUrl: './idn-search-box.component.html',
  styleUrls: ['./idn-search-box.component.scss']
})
export class IdnSearchBoxComponent implements OnInit{
  @ViewChild('search_input') searchField: any;
  searchInput:any=''
  @Output() searchText = new EventEmitter<any>();
  ngOnInit(): void {
  }

  onSearch(){
    this.searchText.emit(this.searchInput)
  }
  onClear(){
    if(this.searchField){
      this.searchInput = '';
      this.searchField.nativeElement = '';
      this.searchText.emit(this.searchInput);
    }
  }

}
