<app-pageheader [pageTitle]="'Extension Management > Edit'" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
    <div class="form-wrapper">
        <mat-stepper linear [@.disabled]="isAnimationDisable" #stepper [orientation]="this.orientation" aria-busy="true">
            <mat-step [stepControl]="frmEditExtension" errorMessage="Mandatory fields required." completed=false>
                <form class="pss-dashboard-form" [formGroup]="frmEditExtension">
                    <br>
                    <ng-template matStepLabel>Extension Details <br>
                        <h6>
                            <mat-hint class="info">{{extensionEnum.extension_create_hint_message_provide}}
                            </mat-hint>
                        </h6>
                    </ng-template>
                    <div></div>
                    <div class="pss-left-panel">
                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_type}}</mat-label>
                            <mat-select formControlName="typeId" disableOptionCentering
                                        required [disabled]="true">
                                <mat-option *ngFor="let type of ExType" [value]="type.id" >
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_customerName}}</mat-label>
                            <mat-select formControlName="customerName" disableOptionCentering #customername required [disabled]="true" [(value)]="selectedCustomerName">
                                <mat-option *ngFor="let customer of editCustomerList" [value]="customer">
                                    {{ customer }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="pss-block-form" *ngIf="hasGlobalDataAccess">
                            <mat-label>{{extensionEnum.extension_Create_Field_partnerName}}</mat-label>
                            <mat-select multiple formControlName="partnerIds" disableOptionCentering #customername aria-hidden="true">
                                <mat-option *ngFor="let partner of partnerList" [value]="partner.id">
                                    {{partner.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_name}}</mat-label>
                            <input type="text" maxlength="100" formControlName="name" matInput
                                placeholder="Extension Name" required [disabled]="true">
                            <mat-error *ngIf="frmEditExtension.controls.name.invalid">{{ extensionEnum.extension_error_field_name }}</mat-error>

                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_extensionDescription}}</mat-label>
                            <input type="text" maxlength="500" formControlName="description" matInput
                                placeholder="Description" height="30"[(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)" required>
                            <mat-error *ngIf="frmEditExtension.controls.description.invalid">{{errorDescriptionName}} </mat-error>
                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_tag}}</mat-label>
                            <mat-chip-list #chipList  role="list" aria-label="Extension Tags" formControlName="tags" aria-hidden="true">
                                <mat-chip *ngFor="let item of frmEditExtension.value.tags" (removed)="remove(item)" role="button">
                                    {{item}}
                                    <button matChipRemove aria-label="cancel btn">
                                        <mat-icon aria-label="close Button" >cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="+Tag" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">

                            </mat-chip-list>
                            <mat-error *ngIf="frmEditExtension.controls.tags.invalid">{{ extensionEnum.extension_error_field_tag }}</mat-error>
                            <mat-hint class="idn-mat-hint">
                              <span class="material-icons">info</span>
                              <span class="message">{{extensionEnum.extension_create_hint_message_provideTags}}</span>
                            </mat-hint>
                        </mat-form-field>
                    </div>

                    <div class="pss-right-panel">
                      <div class="idn-field-add">
                        <mat-form-field class="pss-block-form">
                          <mat-label>{{extensionEnum.extension_Create_Field_referenceDocument}}</mat-label>
                          <mat-toolbar class="idn-upload-toolbar">
                                <input matInput id="browse-button" class="input-files" [(ngModel)]="fileAttr"
                                    [ngModelOptions]="{standalone: true}" readonly name="name" />
                                <button mat-flat-button class="idn-file-upload" color="primary" aria=>Browse</button>
                            </mat-toolbar>
                            <label for="uploadFileReferenceDoc" class="idn-off-focus-label">{{extensionEnum.extension_Create_Field_referenceDocument}}</label>
                            <input type="file" #fileInput id="uploadFileReferenceDoc" 
                                accept=".pdf,.doc,.docx,.txt" (change)="uploadFileEvt($event)" />
                                <mat-hint class="idn-mat-hint">
                                  <span class="material-icons">info</span>
                                  <span class="message">{{extensionEnum.extension_create_hint_message_provideFile}}</span>
                                </mat-hint>
                        </mat-form-field>
                        <button mat-button class="mat-plus-icon" (click)="fileAdd()" #addbutton aria-label="add btn">
                          <mat-icon color="white">add_box</mat-icon>
                        </button>
                      </div>

                      <div class="pss-Bottom-right">
                        <div class="idn-chips-wrapper">
                          <mat-chip-list #chipList formControlName="tags" role="list" aria-label="Supported Files" aria-hidden="true">
                            <mat-chip *ngFor="let item of listofUploadedFiles" (removed)="removeFileTag(item)" role="button">
                                {{item}}
                                <span matChipRemove >
                                    <mat-icon aria-label="cancel btn">cancel</mat-icon>
                                    <span class="sr-only">Cancel</span>
                                </span>
                            </mat-chip>
                        </mat-chip-list>
                        </div>
                      </div>
                    </div>

                </form>
                <div class="pss-form-button-wrapper">
                   <button mat-stroked-button class="pss-btn-danger" *ngIf="!isLocked && isUnLockExtensionPermission && ((currentUserId == ExtensionData.createdBy) || hasGlobalDataAccess)" (click)="lock()">
                        <mat-icon>lock</mat-icon><span>{{extensionEnum.extension_List_Action_Label_lock}}</span>
                    </button>
                    <button mat-stroked-button class="pss-btn-success" *ngIf="isLocked && isLockExtensionPermission && ((currentUserId == ExtensionData.createdBy) || hasGlobalDataAccess)" (click)="unlock()">
                            <mat-icon>lock_open</mat-icon><span>{{extensionEnum.extension_List_Action_Label_unlock}}</span>
                          </button>
                    <button mat-raised-button color="primary" #saveAndClosebtn [disabled]="!frmEditExtension.valid || isLocked"
                        (click)="updateAndCLose('')">{{extensionEnum.extension_Create_updateAndClose_btn}}</button>

                    <button type="submit" *ngIf="isViewSolutionPackagePermission" mat-stroked-button (click)="updateExtensionandNext()">Next</button>
                    <button mat-stroked-button (click)="cancel('')">{{extensionEnum.extension_View_cancel_btn}}</button>

                </div>
            </mat-step>
            <mat-step [stepControl]="frmEditPackage" errorMessage="Mandatory fields required." completed=false>
                <form [formGroup]="frmEditPackage">
                    <ng-template matStepLabel>Package <br>
                        <h6>
                            <mat-hint class="info">{{extensionEnum.package_create_hint_message_provide}}
                            </mat-hint>
                        </h6>
                    </ng-template>
                    <div class="form-wrapper">
                        <form [formGroup]="frmEditPackage">
                            <br>
                            <div class="pss-left-panel">
                                <mat-form-field class="pss-block-form">
                                    <mat-label>{{extensionEnum.package_Create_Field_packageName}}</mat-label>
                                    <input type="text" #packageNameinp maxlength="100" formControlName="name"
                                        [value]="extensionName" matInput placeholder="Extension Name" readonly required>
                                </mat-form-field>
                                <br>

                                <mat-form-field class="pss-block-form" *ngIf="linkageAndQueueForUpgradeFeature && !binaryPackage">
                                    <mat-label>Linked Package</mat-label>
                                    <mat-select [(ngModel)]="linkedPackage" [ngModelOptions]="{standalone: true}" [disabled]="!isEditSolutionPackage || !isCreateSolutionPackage || disableLinkedPackage" disableOptionCentering >
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search Package"
                                                noEntriesFoundLabel="No data found" [formControl]="packageNameFilterCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let list of packageList" [value]="list.id">
                                            {{list.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            <div *ngIf="binaryPackageUpload">
                                <mat-slide-toggle [checked]="binaryPackage" color="primary" aria-label="binary package" (click)="$event.stopPropagation();"
                        (change)="toggleBinaryPackage($event)" [disabled]="true">{{binaryPackage? extensionEnum.package_lable_binary_package : extensionEnum.package_lable_solution_package}}</mat-slide-toggle>
                                <br><br>
                                <div *ngIf="binaryPackage">
                                    <h2>ICI Version</h2>
                                    <p>{{extensionEnum.package_label_create_iciversion}}{{minimumICIVersionForBinaryPackage}}.</p>
                                    <div class="idn-version" *ngIf="binaryPackage">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_major}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="icimajor" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement" [disabled]="true"
                                                (click)="increaseVersion('icimajor')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement" [disabled]="true"
                                                (click)="decreaseVersion('icimajor')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_minor}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="iciminor" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement" [disabled]="true"
                                                (click)="increaseVersion('iciminor')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement" [disabled]="true"
                                                (click)="decreaseVersion('iciminor')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_patch}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="icipatch" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement" [disabled]="true"
                                                (click)="increaseVersion('icipatch')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement" [disabled]="true"
                                                (click)="decreaseVersion('icipatch')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_build}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="icibuild" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement" [disabled]="true"
                                                (click)="increaseVersion('icibuild')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement" [disabled]="true"
                                                (click)="decreaseVersion('icibuild')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                    <mat-error *ngIf="validateMinimumICIVersionForBinaryPackage()">{{extensionEnum.iciversion_error_message_binary_package_p1}} {{minimumICIVersionForBinaryPackage}} {{extensionEnum.iciversion_error_message_binary_package_p2}}</mat-error>
                                </div>
                            </div>
                                <h2>{{extensionEnum.package_Create_Field_packageVersion}}</h2>
                                <p>{{extensionEnum.package_label_create}}
                                </p>
                                <div class="idn-version">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>{{extensionEnum.package_Create_Field_major}}</mat-label>
                                        <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                            formControlName="major" required>
                                        <button matSuffix mat-icon-button aria-label="Incerement" [disabled]="true">
                                            <mat-icon class="idn-version-button">add</mat-icon>
                                        </button>
                                        <button matSuffix mat-icon-button aria-label="Decrement" [disabled]="true">
                                            <mat-icon class="idn-version-button">remove</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>{{extensionEnum.package_Create_Field_minor}}</mat-label>
                                        <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                            formControlName="minor" required>
                                        <button matSuffix mat-icon-button aria-label="Incerement" [disabled]="true">
                                            <mat-icon class="idn-version-button">add</mat-icon>
                                        </button>
                                        <button matSuffix mat-icon-button aria-label="Decrement" [disabled]="true">
                                            <mat-icon class="idn-version-button">remove</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>{{extensionEnum.package_Create_Field_patch}}</mat-label>
                                        <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                            formControlName="patch" required>
                                        <button matSuffix mat-icon-button aria-label="Incerement" [disabled]="true">
                                            <mat-icon class="idn-version-button">add</mat-icon>
                                        </button>
                                        <button matSuffix mat-icon-button aria-label="Decrement" [disabled]="true">
                                            <mat-icon class="idn-version-button">remove</mat-icon>
                                        </button>
                                    </mat-form-field>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>{{extensionEnum.package_Create_Field_build}}</mat-label>
                                        <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                            formControlName="build" required>
                                        <button matSuffix mat-icon-button aria-label="Incerement" [disabled]="true">
                                            <mat-icon class="idn-version-button">add</mat-icon>
                                        </button>
                                        <button matSuffix mat-icon-button aria-label="Decrement" [disabled]="true">
                                            <mat-icon class="idn-version-button">remove</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>

                                <mat-form-field class="pss-block-form">
                                    <mat-label>{{extensionEnum.package_Create_Field_description}}</mat-label>
                                    <textarea type="text" maxlength="500" formControlName="description" matInput
                                        placeholder="Description" height="30" [(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)" required></textarea>
                                    <mat-error *ngIf="frmEditPackage.controls.description.invalid">{{ errorDescriptionName }} </mat-error>

                                </mat-form-field>

                                <mat-form-field class="pss-block-form">
                                    <mat-label>{{extensionEnum.package_Create_Field_supportedFiles}}</mat-label>
                                    <mat-toolbar class="idn-upload-toolbar">
                                        <input matInput [(ngModel)]="packageFileAttr"
                                            [ngModelOptions]="{standalone: true}" readonly name="name" required />
                                        <button mat-flat-button color="primary" [disabled]="chooseFileDisable"
                                            class="idn-file-upload">{{extensionEnum.package_Create_browse_btn}}</button>
                                    </mat-toolbar>
                                    <input type="file" (change)="onFileChange($event)" id="uploadFile" #fileInput accept=".zip" 
                                        (change)="uploadPackageEvt($event)" [disabled]="chooseFileDisable" aria-label="uploadFile"/>
                                        <mat-hint class="idn-mat-hint">
                                          <span class="material-icons">info</span>
                                          <span class="message">{{extensionEnum.package_create_hint_message_filelimit}}</span>
                                        </mat-hint>
                                </mat-form-field>

                            </div>
                        </form>
                    </div>
                </form>
                <div class="pss-form-button-wrapper">
                    <button mat-stroked-button color="primary" [disabled]="true" (click)="previousStepper()">{{extensionEnum.package_Create_previous_btn}}</button>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!frmEditPackage.valid || validateMinimumICIVersionForBinaryPackage()" (click)="updatePackageAndClose()">{{extensionEnum.package_Edit_updateAndClose}}</button>
                    <button *ngIf="false" mat-stroked-button color="primary" type="submit"
                        (click)="savePackageAndNext()">{{extensionEnum.extension_Create_next_btn}}</button>
                    <button mat-stroked-button (click)="cancel('Next')">{{extensionEnum.package_Create_cancel_btn}}</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable">
                <form [formGroup]="thirdFormGroup">
                    <ng-template matStepLabel>{{extensionEnum.certification_header_create}}</ng-template>
                    <mat-form-field>
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
        <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
            <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
          </button>
    </div>

</div>
