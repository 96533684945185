import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { SharedComponent } from 'src/app/shared/enum/sharedComponentEnum';

const debug_access = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="83" height="83" viewBox="0 0 83 83">
  <defs>
    <filter id="Ellipse_18" x="0" y="0" width="83" height="83" filterUnits="userSpaceOnUse">
      <feOffset dy="3" input="SourceAlpha"/>
      <feGaussianBlur stdDeviation="3" result="blur"/>
      <feFlood flood-opacity="0.161"/>
      <feComposite operator="in" in2="blur"/>
      <feComposite in="SourceGraphic"/>
    </filter>
  </defs>
  <g id="Group_869" data-name="Group 869" transform="translate(-401 -727)">
    <g transform="matrix(1, 0, 0, 1, 401, 727)" filter="url(#Ellipse_18)">
      <circle id="Ellipse_18-2" data-name="Ellipse 18" cx="32.5" cy="32.5" r="32.5" transform="translate(9 6)" fill="#ffcece"/>
    </g>
    <g id="Group_741" data-name="Group 741" transform="translate(419.141 744.628)">
      <path id="Path_486" data-name="Path 486" d="M1.058,3.582a1.042,1.042,0,0,0,.15-.668.891.891,0,0,0-.277-.587L1.158.466.366,0,.072,2.636A1.034,1.034,0,0,0,.258,3.709.5.5,0,0,0,1.058,3.582Z" transform="translate(41.395 21.984) rotate(150)" fill="#ffb8b8"/>
      <path id="Path_492" data-name="Path 492" d="M.875,10.891a.35.35,0,0,0,.322-.308L2,6.392,3.075,1.6A1.6,1.6,0,0,0,2.882.412.778.778,0,0,0,2.025.023a1.094,1.094,0,0,0-.705.91L.44,5.623,0,10.376a.387.387,0,0,0,.291.437l.544.074Z" transform="matrix(-0.848, 0.53, -0.53, -0.848, 46.72, 30.543)" fill="#6332a4"/>
      <path id="Path_487" data-name="Path 487" d="M1.35,3.69a.826.826,0,0,0,.036-1.266L2,.639,1.007,0,.174,2.533A.831.831,0,0,0,1.35,3.69Z" transform="translate(35.433 37.711) rotate(5)" fill="#ffb8b8"/>
      <ellipse id="Ellipse_17" data-name="Ellipse 17" cx="2.164" cy="2.164" rx="2.164" ry="2.164" transform="translate(39.768 23.652)" fill="#ffb8b8"/>
      <path id="Path_488" data-name="Path 488" d="M1.028,10.5a.4.4,0,0,1-.11-.016L.287,10.3a.4.4,0,0,1-.272-.489l1.3-4.6L3.132.759A1.158,1.158,0,0,1,4.116,0a1.008,1.008,0,0,1,.95.553h0A1.376,1.376,0,0,1,5.1,1.768L3.034,6.28l-1.64,3.972A.4.4,0,0,1,1.028,10.5Z" transform="translate(36.123 29.23)" fill="#6332a4"/>
      <path id="Path_489" data-name="Path 489" d="M797.156,326.265l-1.2.021a.8.8,0,0,1-.784-1.021,1.852,1.852,0,0,0,.058-.265,1.34,1.34,0,0,0,.008-.3.436.436,0,0,0-.842-.124h0c-.2,0-.633-.063-.835-.06-.429-1.1.506-2.54,1.513-3.081a1.979,1.979,0,0,1,2.753,1.159,1.225,1.225,0,0,1,1.06,1.155,2.355,2.355,0,0,1-.561,1.7A2.08,2.08,0,0,1,797.156,326.265Z" transform="translate(-754.003 -298.634)" fill="#2f2e41"/>
      <path id="Path_491" data-name="Path 491" d="M781.54,402.913l-.1,0a5.03,5.03,0,0,1-3.229-1.645.4.4,0,0,1-.1-.361l.932-3.944-.247-3.429a3.381,3.381,0,0,1,.926-2.611,2.79,2.79,0,0,1,2.149-.869,3.111,3.111,0,0,1,2.826,3.2,12.906,12.906,0,0,1-.087,2.907,9.847,9.847,0,0,0-.206,5.149.4.4,0,0,1-.118.369A3.978,3.978,0,0,1,781.54,402.913Z" transform="translate(-740.008 -361.395)" fill="#6332a4"/>
      <path id="Path_493" data-name="Path 493" d="M283,199.553H258.334a2.4,2.4,0,0,1,0-4.8H283a2.4,2.4,0,0,1,0,4.8Z" transform="translate(-255.933 -194.75)" fill="#e6e6e6"/>
      <path id="Path_494" data-name="Path 494" d="M266.049,203.25a1.616,1.616,0,0,0,0,3.233H290.71a1.616,1.616,0,0,0,0-3.233Z" transform="translate(-263.648 -202.465)" fill="#fff"/>
      <path id="Path_495" data-name="Path 495" d="M621.371,196.431a2.23,2.23,0,0,0,0,4.46h0a2.23,2.23,0,0,0,0-4.46Z" transform="translate(-585.594 -196.276)" fill="#0fca3b"/>
      <path id="Path_395" data-name="Path 395" d="M632.292,211.1a.271.271,0,0,1-.163-.054l0,0-.613-.469a.273.273,0,1,1,.332-.433l.4.3.939-1.225a.272.272,0,0,1,.382-.05h0l-.006.008.006-.008a.273.273,0,0,1,.05.382l-1.1,1.44a.273.273,0,0,1-.217.106Z" transform="translate(-596.726 -207.789)" fill="#fff"/>
      <path id="Path_496" data-name="Path 496" d="M283,287.553H258.334a2.4,2.4,0,0,1,0-4.8H283a2.4,2.4,0,0,1,0,4.8Z" transform="translate(-255.933 -274.622)" fill="#e6e6e6"/>
      <path id="Path_497" data-name="Path 497" d="M266.049,291.25a1.616,1.616,0,0,0,0,3.233H290.71a1.616,1.616,0,0,0,0-3.233Z" transform="translate(-263.648 -282.337)" fill="#fff"/>
      <path id="Path_498" data-name="Path 498" d="M621.371,284.431a2.23,2.23,0,1,0,0,4.46h0a2.23,2.23,0,0,0,0-4.46Z" transform="translate(-585.594 -276.148)" fill="#0fca3b"/>
      <path id="Path_395-2" data-name="Path 395" d="M632.292,299.1a.271.271,0,0,1-.163-.054l0,0-.613-.469a.273.273,0,1,1,.332-.433l.4.3.939-1.225a.272.272,0,0,1,.382-.05h0l-.006.008.006-.008a.273.273,0,0,1,.05.382l-1.1,1.44a.272.272,0,0,1-.217.106Z" transform="translate(-596.726 -287.661)" fill="#fff"/>
      <path id="Path_499" data-name="Path 499" d="M283,375.553H258.334a2.4,2.4,0,0,1,0-4.8H283a2.4,2.4,0,1,1,0,4.8Z" transform="translate(-255.933 -354.494)" fill="#e6e6e6"/>
      <path id="Path_500" data-name="Path 500" d="M266.049,379.25a1.616,1.616,0,0,0,0,3.233H290.71a1.616,1.616,0,0,0,0-3.233Z" transform="translate(-263.648 -362.209)" fill="#fff"/>
      <path id="Path_501" data-name="Path 501" d="M621.371,372.431a2.23,2.23,0,0,0,0,4.46h0a2.23,2.23,0,0,0,0-4.46Z" transform="translate(-585.594 -356.02)" fill="#e6e6e6"/>
    </g>
  </g>
</svg>
`


@Component({
  selector: 'app-approval-management',
  templateUrl: './approval-management.component.html',
  styleUrls: ['./approval-management.component.scss']
})
export class ApprovalManagementComponent implements OnInit{

  breadCrumbItems:any = [];
  count:any;
  public sharedEnum = SharedComponent;
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private router: Router,
    private titleService: Title,
    private instanceService:InstanceService,
  ){
    iconRegistry.addSvgIconLiteral('debug_access_approval_icon', sanitizer.bypassSecurityTrustHtml(debug_access));
  }
  ngOnInit(): void{
    this.titleService.setTitle('Actionables | Icertis Developer Network');
    this.breadCrumbItems = [
      { label: "Home", path: "actionables"},
      { label: "My Tasks", path: "actionables"},
    ];
      this.getPendingCount();
  }
  ViewApprovalListPage(){
    let navLink = 'home/actionables/my-approvals';
    this.router.navigate([navLink]);
  }

  getPendingCount(){
    this.instanceService.getToolsAccessRequestApprovalPendingCount().subscribe((res:any)=>{
      if(res){
        this.count = res.body.content;
      }
    })
  }
}
