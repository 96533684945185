export enum HttpErrorMsgStaticString {
  /* 400 Bad Request */
  error_400_unauthorized_heading = "Authorization error",
  error_400_unauthorized_body = "Sorry, but we’re having trouble signing you in. Your user ID is not active. Please contact the system administrator.",
  error_400_general_heading = "System Error",
  error_400_general_body = "Your request could not be processed at this time. Please try again soon. ",
  error_400_initiate_deployment_heading = "Deployment not initiated",
  /* 401 Unauthorized */
  error_401_general_heading = "Unauthorized",
  error_401_general_body = "The requested page needs a username and a password.",
  /* 403 Forbidden */
  error_403_general_heading = "Authorization error",
  error_403_general_body = "Sorry, but you don't appear to have the necessary authorization. Please contact the system administrator.",
  /* 404 Not Found */
  error_404_general_heading = "Page not found",
  error_404_general_body = "The page you are trying to access could not be found at this moment. Please try again or contact your administrator for details.",
  /* 408 Request Timeout */
  error_408_general_heading = "Request Timeout",
  error_408_general_body = "System is taking longer to respond than expected. Please try again soon. ",
  /* 409 Conflict */
  error_409_general_heading = "Conflict Error",
  error_409_general_body = "A conflict has been detected: another user is editing the same record. Please try again soon. ",
  /* 500 Internal Server Error */
  error_500_general_heading = "System Error",
  error_500_general_body = "A system issue prevented the completion of your request. Please try soon.",
  /* 502 Bad Gateway */
  error_502_general_heading = "System Error",
  error_502_general_body = "Your request could not be completed due to a system issue. Please try again later or contact your administrator for details.",
  /* 503 Service Unavailable */
  error_503_general_heading = "System Error",
  error_503_general_body = "We were unable to fulfil your request at this time due to the extraordinary traffic volume. Please try again soon.",
  /* 504 Gateway Timeout */
  error_504_general_heading = "System Error",
  error_504_general_body = "A system issue prevented the completion of your request. Please try again soon.",
  /* Cache flush role restart error */
  error_503_504_cacheflush_rolerestart_heading = "System Notification",
  error_503_504_cacheflush_rolerestart_body ="It is taking longer than anticipated. It may take around 5-10 minutes for this operation to complete. Please try to resume your activities on the instance after 10 minutes",
  /* Default Error */
  error_default_general_heading = "System Error",
  error_default_general_body = "Oops! Something Went Wrong. Please try again soon.",
}
