<app-header></app-header>
<div class="idn-terms-header">
  <div class="container">
    <h1>Terms of use</h1>
  </div>
</div>
<section class="idn-terms-content-wrapper">
  <div class="container">
    <div class="idn-terms-content" [innerHtml]="content"></div>
  </div>
</section>
<app-footer></app-footer>
