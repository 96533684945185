import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
 public  minBreakpoint = 900;
 public  fileSizeLimit= 500;
 public  fileSizeLimitExtension = 10;
 public  fileSizeLimitInstance = 50;
 public  fileSizeLimitCertifications = 5;
 public  fileSizeLimitConfigFile = 2;
 public  fileSizeLimitCustomer = 50;
 public  autoLogoutTime= 1800;
 public  appNotificationStatus= false;
 public  appNotificationBody='Downtime Scheduled for IDN for maintainance and feature update purpose on 12th June, 2022 at 11:30 PM IST. Portal wil be resumed by 13th June, 2022 by 03:00 AM IST';

  constructor() { }
}
