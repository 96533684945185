import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-view-ici-workertask-configuration',
  templateUrl: './view-ici-workertask-configuration.component.html',
  styleUrls: ['./view-ici-workertask-configuration.component.scss']
})
export class ViewIciWorkertaskConfigurationComponent implements OnInit {

  breadCrumbItems:any;
  ViewData:any={partnerName:'test', customerName:'Acme',cname:'test@icertis.com', type:'Event Task', entryName:'test_abcd', requesterdDetail:'Mohammed Waseem', createdAt:"2023-03-16T08:20:09.2759016"};
  workerTaskId: any;

  constructor(private router: Router,
    private titleService: Title,
    private instanceService: InstanceService,
    private notificationService: NotificationService,) { }

  ngOnInit(): void {
    this.titleService.setTitle('ICI WorkerTask Configuration | IDN');
    this.workerTaskId = localStorage.getItem('workerTaskId');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'ICI WorkerTask Configuration', path: 'ici-workertask-configuration' },
      { label: 'View', path: 'ici-workertask-configuration/view' },
    ];
  }

  getIciWorkerTaskConfigurationById() {
    try {
      // this.instanceService.getIciWorkerTaskConfigurationById(this.workerTaskId).subscribe(
      //   (resp: any) => {
      //     let res = resp.body;
      //     if (res != undefined && res != null) {
      //       this.ViewData = res;
      //     } else {
      //       this.notificationService.showNotification(
      //         "Detailed information is not available",
      //         'warning',
      //         "Information not available"
      //       );
      //       this.router.navigate(['home/ici-component-deployment']);
      //     }
      //   },
      //   (error: HttpErrorResponse) => {
      //     let message: any = error;
      //     this.notificationService.showNotification(
      //       message,
      //       'warning',
      //       "Information not available"
      //     );
      //     this.router.navigate(['home/ici-workertask-configuration']);
      //   }
      // );
    } catch (error) {
      console.log(error);
    }
  }

  onCancel(){
    this.router.navigate(['home/ici-workertask-configuration']);
  }

}
