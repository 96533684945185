<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="{{this.customHeaderClass}}">
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <button id="closeButton" mat-icon-button [mat-dialog-close]="false" aria-label="Close Button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content>
    <div class="dialog-content"  *ngIf="data.module != 'config-details' && data.module != 'package-details' && data.module != 'partner-provisionedUser'">
      <div class="{{this.customIconClass}}">
        <mat-icon svgIcon="confirm_dialog_icon"></mat-icon>
      </div>
      <div class="{{this.customContentClass}}" [innerHtml]="data.message"></div>
    </div>
    <div class="dialog-content" *ngIf="data.module == 'config-details'">
      <form class="config-form">
        <mat-form-field class="pss-block-form">
          <mat-label class="mat-label-text">Name</mat-label>
          <input type="text" width="50" [value]="configName" name="comment" matInput
            readonly />
        </mat-form-field>
        <mat-form-field class="pss-block-form">
          <div class="label-text">
            <button mat-button class="copy-button" [cdkCopyToClipboard]="configValue" matTooltip="Copy" matTooltipPosition="above" aria-label="copy-button">
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
          <mat-label class="mat-label-text" >Value</mat-label>
          <textarea class="idn-min-textarea-height" type="text" width="50" [value]="configValue" name="comment" matInput
            readonly></textarea>
        </mat-form-field>
      </form>
    </div>
    <div class="dialog-content" *ngIf="data.module == 'package-details'">
      <mat-tab-group mat-align-tabs="left" animationDuration="0ms">
        <mat-tab label="Extension Details" *ngIf="packageDetails?.parentSolutionPackage">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">article</mat-icon>
            Parent Package
          </ng-template>
          <app-idn-table [data]="packageParentTable"></app-idn-table>
        </mat-tab>
        <mat-tab label="Extension Details" *ngIf="packageDetails?.childSolutionPackages?.length">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">article</mat-icon>
            Child Package
          </ng-template>
          <app-idn-table [data]="packageChildTable"></app-idn-table>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div class="dialog-content" *ngIf="data.module == 'partner-provisionedUser'">
      <app-idn-table class="provision_user" [data]="partnerProvisionedUserTable"></app-idn-table>
    </div>
  </div>
  <div mat-dialog-actions [align]="'center'">
    <button *ngIf="data?.confirmText" mat-button [mat-dialog-close]="true" class="pss-dialog-focused-button min-width-button c-dark" cdkFocusInitial color="accent">{{ data.confirmText }}</button>
    <button mat-stroked-button [mat-dialog-close]="false" class="min-width-button idn-btn-cancel">{{ data.cancelText }}</button>
  </div>

</div>
