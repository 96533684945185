<app-pageheader [pageTitle]="'Extension Management'" [auditLog]="true" [auditLogData]="'extension'" [createextension]="isCreateExtensionPermission"
  [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
  <div class="idn-filter">

  <form class="idn-grid-filter">
    <mat-form-field class="idn-grid-filter-search">
      <mat-label>{{extensionEnum.extension_Search_label_searchExtension}}</mat-label>
      <input type="text" matInput (keydown.enter)="filter()" #searchInput matInput placeholder="Search by name,tag">
      <div class="idn-custom-search" matSuffix>
        <button type="button" mat-icon-button aria-label="search" (click)="filter()">
          <mat-icon>search</mat-icon>
        </button>
        <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{extensionEnum.extension_Search_label_Extension_type}}</mat-label>
      <mat-select (selectionChange)="filter()" #extType>
        <mat-option [value]="selectAll"> All</mat-option>
        <mat-option *ngFor="let type of ExType" [value]="type.id">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!isCustomerUser">
      <mat-label>{{extensionEnum.extension_Search_label_Customer}}</mat-label>
      <mat-select (selectionChange)="filter()" #custName>
        <mat-option [value]="selectAll">Not Selected</mat-option>
        <mat-option *ngFor="let type of customerList" [value]="type.id">
          {{type.customerName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <button mat-stroked-button color="primary"  (click)="clearFilter()">
    <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
    <span>Clear Filter</span>
  </button>
</div>
  <app-idn-list-drawer *ngIf="listPartner" [data]="partnerData" (closeEvent)="closeListDrawer($event)"></app-idn-list-drawer>
  <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position idn-grid-global" (matSortChange)="announceSortChange($event)" *ngIf="!noContent; else noContentTemplate">

      <!-- Position Column -->
      <ng-container matColumnDef="extensionType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{extensionEnum.extension_List_Header_type}}
        </th>
        <td mat-cell *matCellDef="let element" class="extTypeStyle"> {{element.extensionType}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{extensionEnum.extension_List_Header_name}}
        </th>
        <td mat-cell *matCellDef="let element" class="extNameStyle overflow-data" matTooltip="{{element.name}}"
          matTooltipClass="my-tooltip" matTooltipPosition="below"> {{element.name}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="customerName" *ngIf="!isCustomerUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
           {{extensionEnum.extension_List_Header_customerName}}
        </th>
        <td mat-cell *matCellDef="let element" class="extNameStyle"> {{element.customerName != "" ? element.customerName
          :
          '....' }} </td>
      </ng-container>

      <!-- Partner Name Column -->
      <ng-container matColumnDef="partnerName" *ngIf="globalDataAccess">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
           {{extensionEnum.extension_List_Header_partnerName}}
        </th>
        <td mat-cell *matCellDef="let element" class="extNameStyle">  
          <div class="columnStyle">
            <div class="overflow-data" matTooltip="{{element.partners[0]?.partnerName}}"
              matTooltipClass="my-tooltip" matTooltipPosition="below">{{element.partners[0]?.partnerName? element.partners[0]?.partnerName : 'NA'}}</div><a *ngIf="element.partners?.length > 0" aria-label="View Customers" class="idn-grid-rec-count"
              href="javascript:void (0)" (click)="closeListDrawer(element)">{{0>element.partners?.length - 1
              ? '' : element.partners?.length-1 >0 ? '+'+(element.partners?.length-1) : ''}}</a>
          </div>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="extensiontag">
        <th mat-header-cell *matHeaderCellDef>
          {{extensionEnum.extension_List_Header_tag}}
        </th>
        <td mat-cell *matCellDef="let element" class="tagStyle overflow-data" matTooltipClass="my-tooltip" matTooltipPosition="below">
          <mat-chip-list class="tagStyle" aria-label="tags">
            <mat-chip *ngIf='!element.tags' class="tags-na">NA</mat-chip>
            <mat-chip *ngFor='let tag of element.tags | extensionTagFilter' matTooltip="{{tag}}" class="tags">

              <span *ngIf="element.tags != null || element.tags != ''" class="idn-limit-tag">{{tag}}</span>
            </mat-chip>
            <span class="countNum" matTooltip="{{element.tags | extensiontagcountfilter}}" *ngIf="element.tags != null  && element.tags.length > 2">+{{element.tags.length > 2 ?
              element.tags.length - 2 : ''}} </span>
          </mat-chip-list>
        </td>
      </ng-container>

      <ng-container matColumnDef="extensionFiles">
        <th mat-header-cell *matHeaderCellDef>
          {{extensionEnum.extension_List_Header_RrferenceDocument}}
        </th>
        <td mat-cell *matCellDef="let element" class="counts">
          <div class="rows">
            <div *ngIf="uploadextData.extName != element.name && uploadextData.extDesc != element.description || uploadextData.showProgressBar == 'false' ">{{element.files != null ? element.files.length: 0}}
            </div>
            <div
              *ngIf="uploadextData.extName != element.name && uploadextData.extDesc != element.description || uploadextData.showProgressBar == 'false' ">
              <mat-icon *ngIf="element.status == 1 && element.files != null" class="size" (click)="onDownload(element)">download</mat-icon>
            </div>
          </div>
          <mat-progress-bar [color]="color" [mode]="mode" [value]="value"
            *ngIf=" uploadextData.extName == element.name && uploadextData.extDesc == element.description && uploadextData.showProgressBar == 'true' ">
          </mat-progress-bar>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by weight">
          {{extensionEnum.extension_List_Header_status}}
        </th>
        <td mat-cell *matCellDef="let element" >
          <div class="lock-unlock-styling">
            <mat-icon class="orange" *ngIf="element.status == 2" matTooltip="Locked">lock</mat-icon>
            <span class="lockUnlockDetails"  *ngIf="element.status == 2">{{element.modifiedByDisplayName}}<br>on {{element.modifiedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}</span>
          <mat-icon class="green" *ngIf="element.status == 1" matTooltip="Unlocked">verified_user</mat-icon>
          </div>
          
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="idn-grid-th-p1 action-cell">
          {{extensionEnum.extension_List_Header_actions}}
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="View" (click)="ViewExtenstion('extension-management/view',element)"
            matTooltip="View Extension" >
            <mat-icon class="view-icon">remove_red_eye</mat-icon>
          </button>

          <button [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="Extension Actions"
            matTooltip="Extension Actions" *ngIf="isUpdateExtensionPermission || isUnLockExtensionPermission || isLockExtensionPermission || isViewSolutionPackagePermission"
            >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #gridMenu="matMenu" xPosition="before">
            <button mat-menu-item *ngIf="element.files!=null" (click)="(element.status === 1) ? onDownload(element) :''" [disabled]="element.status === 2">
              <mat-icon>save_alt</mat-icon> {{extensionEnum.extension_List_Action_Label_downloadDocuments}}
            </button>

            <button mat-menu-item (click)="(element.status === 1) ? edit('extension-management/edit',element):''" *ngIf="isUpdateExtensionPermission" [disabled]="element.status === 2">
              <mat-icon>mode</mat-icon> {{extensionEnum.extension_List_Action_Label_edit}}
            </button>

            <button mat-menu-item (click)="(element.status === 1) ? openPackage(element): ''" *ngIf="isViewSolutionPackagePermission" [disabled]="element.status === 2">
              <mat-icon>inventory_2</mat-icon> {{extensionEnum.extension_List_Action_Label_package}}
            </button>
            <button mat-menu-item (click)="(element.status === 1) ? lock(element) : ''" *ngIf="isLockExtensionPermission && ((currentUserId == element.createdBy) || (this.isCreateExtensionPermission && this.isCreateRole))" [disabled]="element.status === 2">
              <mat-icon>lock</mat-icon> {{extensionEnum.extension_List_Action_Label_lock}}
            </button>
            <button mat-menu-item (click)="(element.status === 2) ? unlock(element) : ''" *ngIf="isUnLockExtensionPermission && ((currentUserId == element.createdBy) || (this.isCreateExtensionPermission && this.isCreateRole))" [disabled]="element.status === 1">
              <mat-icon>lock_open</mat-icon> {{extensionEnum.extension_List_Action_Label_unlock}}
            </button>

          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="!isCustomerUser ? displayedColumns : displayedColumnsCustomeruser; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: !isCustomerUser ? displayedColumns : displayedColumnsCustomeruser;"></tr>
    </table>
  </div>
  <mat-paginator #paginator [ngClass]="{'hide-pagination': noContent ? true : false}" [pageSize]="10" [pageIndex]="currentPage" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page of periodic elements"></mat-paginator>
  <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
    <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
  </button>
</div>

<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
        <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
        </g>
      </g>
    </svg>
    <h4>{{ extensionEnum.extension_no_result_msg }}</h4>
  </div>
</ng-template>
