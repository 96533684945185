import { ChangeDetectorRef, Component,ElementRef,Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogData } from 'src/app/core/interfaces/models/confirm-dialog-data';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { MultiTenantPods } from 'src/app/shared/enum/multi-tenant-pods';
import { DatePipe } from '@angular/common';
import { SharedComponent } from 'src/app/shared/enum/sharedComponentEnum';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-confirm-dialog-comments',
  templateUrl: './confirm-dialog-comments.component.html',
  styleUrls: ['./confirm-dialog-comments.component.scss'],
})
export class ConfirmDialogCommentsComponent implements OnInit {
  @ViewChild('picker') picker: any;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = true;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  minDate: any = new Date();
  isInvalid: boolean = true;
  comments: any = '';
  targetIciVersion: any = '';
  emptyComment: boolean = true;
  searchText: any = '';
  canStartLaActivity: boolean = false;
  errorMsg: boolean = false;
  public laActivityEnum = MultiTenantPods;
  public sharedEnum = SharedComponent;
  dataJson: any;
  allowCopy: boolean = false;
  public dateControl = new FormControl(new Date());
  requiredByDate: any;
  utcDate: any;
  approveRejectData: any = { requiredBy: '', comments: '', event: '' };
  param: any = { selectedIciVersion: '', podId: '' };
  currentDate: any;
  serverJsonData: any;
  noDataFound: any = false;
  Duration: any = [
    { id: 1, displayName: '2 Hours' },
    { id: 2, displayName: '4 Hours' },
    { id: 3, displayName: '8 Hours' },
  ];
  selectedHours: any;
  approverAction: any = { approved: '', duration: '', comment: '' };
  approvercomments: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
    private permissionService: PermissionsService,
    private changeDetector: ChangeDetectorRef,
    private datePipe: DatePipe,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.canStartLaActivity =
      this.permissionService.getPermission('ViewMultiTenantPOD');
    if (this.data.module == 'jsonView') {
      let result = JSON.parse(this.data?.message);
      try {
        this.dataJson = JSON.parse(result?.blobContents);
        this.serverJsonData = JSON.parse(result?.blobContents);
      } catch (e) {
        this.dataJson = result?.blobContents;
        console.log(e);
      }
      this.allowCopy = result.instanceType == 'Development';
    }
    this.utcDate = this.transformDate(new Date());
    this.minDate = new Date(this.utcDate);
    this.dateControl.setValue(new Date(this.utcDate));
    if (this.data.module == 'approve-deployment') {
      this.requiredByDate = this.convertDateStringToDateFormat(
        this.data.message
      );
      this.dateControl.setValue(this.requiredByDate);
      this.dateControl.markAllAsTouched();
    }
    if (this.data.module == 'accessDebuggingUtilities') {
      this.selectedHours =
        this.data?.message == '2'
          ? '2 Hours'
          : this.data?.message == '4'
          ? '4 Hours'
          : '8 Hours';
    }
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }

  validateComments() {
    if (this.canStartLaActivity && this.data.module === 'upgradeActivity') {
      let compareVersion = '8.5.0.0';
      const regex = /^[1-9]{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?:)?$/;
      let compareTargetICIVersionValue = this.targetIciVersion.localeCompare(
        compareVersion,
        undefined,
        { numeric: true, sensitivity: 'base' }
      );
      if (
        compareTargetICIVersionValue >= 0 &&
        regex.test(this.targetIciVersion)
      ) {
        if (this.data.module == 'upgradeActivity') {
          this.param = {
            selectedIciVersion: this.targetIciVersion,
            podId: this.data?.message,
          };
        }
        this.errorMsg = false;
        return false;
      } else {
        this.targetIciVersion == ''
          ? (this.errorMsg = false)
          : (this.errorMsg = true);
        return true;
      }
    } else if (this.data?.module == 'request-deployment') {
      this.currentDate = this.transformDate(new Date());
      this.minDate = new Date(this.currentDate);
      if (this.dateControl?.value && this.dateControl?.value >= this.minDate) {
        return false;
      } else {
        this.dateControl.markAllAsTouched();
        return true;
      }
    } else if (this.data?.module == 'approve-deployment') {
      this.currentDate = this.transformDate(new Date());
      this.minDate = new Date(this.currentDate);
      if (
        this.comments?.replace(/\s/g, '').trim().length != 0 &&
        this.dateControl.value != null &&
        this.dateControl.value >= this.minDate
      ) {
        return false;
      } else {
        this.dateControl.markAllAsTouched();
        return true;
      }
    } else if (this.data?.module == 'withdraw-certification') {
      if (this.comments?.replace(/\s/g, '').trim().length != 0) {
        return false;
      } else {
        return true;
      }
    } else if (this.data?.module == 'accessDebuggingUtilities') {
      if (
        (this.selectedHours != '' || this.selectedHours != undefined) &&
        this.comments?.replace(/\s/g, '').trim().length != 0
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.data.module == 'accessToHangfireDashboard') {
      if (this.comments?.replace(/\s/g, '').trim().length != 0) {
        return false;
      } else {
        return true;
      }
    } else {
      if (this.comments?.replace(/\s/g, '').trim().length === 0) {
        this.emptyComment = true;
        return true;
      } else {
        this.emptyComment = false;
        return false;
      }
    }
  }

  validateRejectDeployment() {
    if (this.comments?.replace(/\s/g, '').trim().length === 0) {
      return true;
    } else {
      return false;
    }
  }

  approveRejectDeployment(data: any) {
    this.approveRejectData = {
      requiredBy: this.dateControl.value,
      comments: this.comments,
      event: data,
    };
    return this.approveRejectData;
  }

  //datetime Validation
  transformDate(selectedDate: any) {
    return this.datePipe.transform(
      selectedDate.toISOString(),
      'YYYY-MM-ddTHH:mm',
      'UTC'
    );
  }

  convertDateStringToDateFormat(date: any) {
    return new Date(date);
  }

  onToggle() {
    this.changeDetector.detectChanges();
    let inputs = document.querySelectorAll('input');
    inputs?.forEach((input: any, index: any) => {
      input?.setAttribute('aria-label', 'input' + index);
    });
    let action_button = document.querySelectorAll('.actions button')[0];
    action_button?.setAttribute('title', 'select-button');
  }

  onInputChange(): any[] {
    const result: any = [];
    const stack = [this.serverJsonData];
    this.searchText = this.searchText.replace(/"/g, '');
    if (this.searchText?.replace(/\s/g, '').trim().length !== 0) {
      while (stack.length) {
        const current = stack.pop();
        for (const key in current) {
          if (current.hasOwnProperty(key)) {
            const value = current[key];
            if (
              typeof value === 'string' &&
              value
                .toLowerCase()
                .replace(/\s+|"/g, '')
                .includes(this.searchText.toLowerCase().replace(/\s+/g, ''))
            ) {
              result.push(JSON.parse(JSON.stringify(current)));
              break; // Break to avoid duplicate entries
            } else if (typeof value === 'object') {
              stack.push(value);
            }
          }
        }
      }
      if (result.length) {
        this.dataJson = result;
        this.noDataFound = false;
      } else {
        this.dataJson = [];
        this.noDataFound = true;
      }
    } else {
      this.dataJson = this.serverJsonData;
      this.noDataFound = false;
    }

    return result;
  }

  ClearInput(event: any) {
    this.searchText = '';
    this.dataJson = this.serverJsonData;
    this.noDataFound = false;
  }
  approveOrRejectRequest() {
    if (this.data?.module == 'accessDebuggingUtilities') {
      if (this.data?.confirmText == 'Approve') {
        this.approverAction = {
          approved: true,
          duration: this.selectedHours,
          comment: this.comments,
        };
      } else {
        this.approverAction = {
          approved: false,
          duration: '',
          comment: this.comments,
        };
      }
      return this.approverAction;
    }
    if(this.data.module == 'accessToHangfireDashboard'){
      if(this.data?.confirmText == 'Approve'){
        this.approverAction = {
          approved: true,
          duration: 0,
          comment: this.comments,
        };
      }
      else{
        this.approverAction = {
          approved: false,
          duration: 0,
          comment: this.comments,
        };
      }
      return this.approverAction;
    }
  }
}


