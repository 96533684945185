import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatDrawer } from '@angular/material/sidenav';
import { catchError, map, Subscription, throwError } from 'rxjs';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-download-files',
  templateUrl: './download-files.component.html',
  styleUrls: ['./download-files.component.scss']
})
export class DownloadFilesComponent implements OnInit {

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  certificationDetails: any;
  certificationFiles: any = [];
  fileAttr = "Choose File";
  downloadFileName: any = [];
  selectedFilesList: any = [];
  certificationsSubscription!: Subscription;
  certificationId: any;
  certificationEnum = CertificationManagement;
  constructor(private certificationsService: CertificationsService, private extensionservice: ExtensionService, private blobService :AzureBlobStorageService, private notificationService: NotificationService) { }

  ngOnInit(): void {
    let self = this;
    this.certificationsService.setDrawer(this.drawer);
    this.certificationsSubscription = this.certificationsService.onCertificationDetails<any>().subscribe(
      data => {
        if (data != null && data != "") {
          this.certificationId = data.id
        } else {
          let certificationDetails = localStorage.getItem('certificationId');
          this.certificationId = certificationDetails
        }
        this.certificationsService.getCertificationDetails(this.certificationId).subscribe(response => {
          if(response) {
            this.certificationDetails = response;
            this.certificationFiles = response.files;
          }
          self.certificationsService.open();
        })
      }
    );
	}

  closeDrawer() {
    this.certificationsService.closeIntiateCertification(true);
  }

  fileSelect(event: any, value: any) {
    if(event.checked == true) {
      this.selectedFilesList.push(value);
    } else {
      let item = this.selectedFilesList.indexOf(value);
      if(item !== -1) {
        this.selectedFilesList.splice(item, 1);
      }
    }
  }

  selectAll(event: any) {
    if(event.checked == true){
      this.certificationFiles.forEach((file: any) => {
        this.selectedFilesList.push(file.fileName)
      })
    }else {
      this.selectedFilesList = [];
    }
  }

  isChecked() {
    return (this.certificationFiles == (null || undefined)) ? false : (this.selectedFilesList.length === this.certificationFiles.length);
  };

  exists(item: any) {
    return this.selectedFilesList.indexOf(item) > -1;
  };

  downloadAllFlies(selectedfileName: any, singleFile: any) {
    if(singleFile == true){
      this.downloadFileName = selectedfileName;
    } else {
      this.downloadFileName = this.selectedFilesList;
    }
    for(let fileName of this.downloadFileName) {
      if(this.certificationFiles != null && this.certificationFiles != undefined){
        this.certificationsService.certificationFileDownload(this.certificationDetails, fileName).pipe(
          catchError(err => {
            if(err.status != 200) {
              this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationDownloadFailed_heading +fileName, 'error', this.certificationEnum.certification_notify_certificationDownloadFailed_heading);
            }
            return throwError(err);
          })
        ).subscribe(res => {
          if (res != null && res != undefined) {
            var FileSaver = require('file-saver');
            this.blobService.downloadImageExt(res.sasUrl, res.container, res.blob, (response: any) => {
              if (response._response != undefined && response._response.status == 200) {
                  response.blobBody.then(
                    (onres: any) => {
                      FileSaver.saveAs(onres, fileName);
                    })
                    this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationDownloaded_body, 'success', this.certificationEnum.certification_notify_certificationDownloaded_heading);
                    this.certificationsService.closeIntiateCertification(true);
                } else {
                  this.notificationService.showNotification(this.certificationEnum.certification_notify_certificationDownloadFailed_heading +fileName, 'error', this.certificationEnum.certification_notify_certificationDownloadFailed_heading);
                }
            });
          }
        });
      }
    }
  }

  ngOnDestroy(){
    this.certificationsService.closeIntiateCertification(false);
    if(this.certificationsSubscription) {
      this.certificationsSubscription.unsubscribe();
    }
  }

}
