import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { lastValueFrom } from 'rxjs';
import { LogoutString } from 'src/app/shared/enum/logoutString';

const DOWNLOAD_ICON = `
<svg _ngcontent-gdq-c244="" class="svg-inline--fa fa-download" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"></path></svg>
`;

const DEVELOPE_ICON = `
<svg _ngcontent-gdq-c244="" class="svg-inline--fa fa-file-code" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-code" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM154.1 353.8c7.812 7.812 7.812 20.5 0 28.31C150.2 386.1 145.1 388 140 388s-10.23-1.938-14.14-5.844l-48-48c-7.812-7.812-7.812-20.5 0-28.31l48-48c7.812-7.812 20.47-7.812 28.28 0s7.812 20.5 0 28.31L120.3 320L154.1 353.8zM306.1 305.8c7.812 7.812 7.812 20.5 0 28.31l-48 48C254.2 386.1 249.1 388 244 388s-10.23-1.938-14.14-5.844c-7.812-7.812-7.812-20.5 0-28.31L263.7 320l-33.86-33.84c-7.812-7.812-7.812-20.5 0-28.31s20.47-7.812 28.28 0L306.1 305.8zM256 0v128h128L256 0z"></path></svg>
`;

const VALIDATE_ICON = `
<svg _ngcontent-gdq-c244="" class="svg-inline--fa fa-circle-check" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="circle-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path></svg>
`;

const READY_ICON = `
<svg _ngcontent-ftb-c244="" class="svg-inline--fa fa-server" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="server" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M480 288H32c-17.62 0-32 14.38-32 32v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32v-128C512 302.4 497.6 288 480 288zM352 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S365.3 408 352 408zM416 408c-13.25 0-24-10.75-24-24s10.75-24 24-24s24 10.75 24 24S429.3 408 416 408zM480 32H32C14.38 32 0 46.38 0 64v128c0 17.62 14.38 32 32 32h448c17.62 0 32-14.38 32-32V64C512 46.38 497.6 32 480 32zM352 152c-13.25 0-24-10.75-24-24S338.8 104 352 104S376 114.8 376 128S365.3 152 352 152zM416 152c-13.25 0-24-10.75-24-24S402.8 104 416 104S440 114.8 440 128S429.3 152 416 152z"></path></svg>
`;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public constructor(
    private titleService: Title,
    private router: Router,
    private msalService: MsalService,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private permissionService: PermissionsService,
    private changeDetector: ChangeDetectorRef

  ) {
    iconRegistry.addSvgIconLiteral('download', sanitizer.bypassSecurityTrustHtml(DOWNLOAD_ICON));
    iconRegistry.addSvgIconLiteral('develope', sanitizer.bypassSecurityTrustHtml(DEVELOPE_ICON));
    iconRegistry.addSvgIconLiteral('validate', sanitizer.bypassSecurityTrustHtml(VALIDATE_ICON));
    iconRegistry.addSvgIconLiteral('ready', sanitizer.bypassSecurityTrustHtml(READY_ICON));
  }
  activateGoTop: boolean = true;
  isLoggedIn: any;
  activeAccountInfo: any;
  public logoutEnum = LogoutString;

  isLoggedin(): boolean {
    if (this.msalService.instance.getActiveAccount != null) {
      return true;
    }
    else {
      return false;
    }
  }

  async ngOnInit(): Promise<void> {
    window.scrollTo(0, 0);
    if (localStorage.getItem("authenticatedUser") != null) {
      let userInfo: any = await this.getuserinfo()
      this.isLoggedIn = userInfo.body.user.isActive;
      this.msalService.instance.handleRedirectPromise().then(
        res => {
          if (res != null && res.account != null) {
            this.msalService.instance.setActiveAccount(res.account);
            this.activeAccountInfo = this.msalService.instance.getActiveAccount();
            localStorage.setItem("username", this.activeAccountInfo.username);
            this.router.navigate(['home']);
          }
        }
      );
    }
    this.titleService.setTitle('Home | Icertis Developer Network');
    if (this.isLoggedIn) {
      localStorage.setItem("activeNavLink", "resources");
      localStorage.setItem("isToggleMenuContainer", "false");
      let toggleMenuContainer = JSON.parse(
        localStorage.getItem('toggleMenuContainer') || ''
      );
      if (
        this.permissionService.getPermission('AddInstance') ||
        this.permissionService.getPermission('ViewInstance') ||
        this.permissionService.getPermission('ViewExtension')
      ) {
        this.router.navigate(['home/resources']);
        if (toggleMenuContainer) {
          for (let item in toggleMenuContainer) {
            toggleMenuContainer[item] = false;
          }
        }
        localStorage.setItem(
          'toggleMenuContainer',
          JSON.stringify(toggleMenuContainer)
        );
      } else if (
        this.permissionService.getPermission('UpgradeMultiTenantPOD')
      ) {
        this.router.navigate(['home/upgrade/pod-upgrade']);
        if (toggleMenuContainer) {
          for (let item in toggleMenuContainer) {
            if (item == 'upgrade') {
              toggleMenuContainer[item] = true;
            } else {
              toggleMenuContainer[item] = false;
            }
          }
        }
        localStorage.setItem(
          'toggleMenuContainer',
          JSON.stringify(toggleMenuContainer)
        );
      } else {
        this.router.navigate(['home/resources']);
        if (toggleMenuContainer) {
          for (let item in toggleMenuContainer) {
            toggleMenuContainer[item] = false;
          }
        }
        localStorage.setItem(
          'toggleMenuContainer',
          JSON.stringify(toggleMenuContainer)
        );
      }
    } else {
      this.router.navigate(['']);
    }
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  async getuserinfo(): Promise<any> {
    return await lastValueFrom(this.permissionService.getPermissionListForUser());
  }

  scrollToTop() {
    return window.scrollTo(0, 0);
  }

  scrollToSection(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
  partnerItems: any = [
    { label: "Airbus", logo: "../../../../assets/images/partners/airbus.png" },
    { label: "Cognizant", logo: "../../../../assets/images/partners/cognizant.png" },
    { label: "Daimler", logo: "../../../../assets/images/partners/daimler.png" },
    { label: "Johnson & Johnson", logo: "../../../../assets/images/partners/johnson.png" },
    { label: "Microsoft", logo: "../../../../assets/images/partners/microsoft.png" },
    { label: "Sanofi", logo: "../../../../assets/images/partners/sanofi.png" },
    { label: "Vizient", logo: "../../../../assets/images/partners/vizient.png" },
    { label: "Auth0", logo: "../../../../assets/images/partners/auth0.png" },
  ];

  login() {
    if (this.isLoggedIn) {
      if(this.permissionService.getPermission('AddInstance') && !this.permissionService.getPermission('UpgradeMultiTenantPOD')){
        this.router.navigate(['home/resources'])
        }
        else{
          this.router.navigate(['home/upgrade/pod-upgrade']);
        }
    } else {
      this.router.navigate(['login']);
    }
  }

  logout(): void {
    this.dialogService.confirmDialog({
      title: this.logoutEnum.logout_dialog_heading,
      module: 'logout',
      message: this.logoutEnum.logout_dialog_body,
      confirmText: this.logoutEnum.logout_dialog_primary_btn,
      cancelText: this.logoutEnum.logout_dialog_secondary_btn
    }).subscribe(res => {
      if (res) {
        localStorage.removeItem("authenticatedUser");
        let ss = (this.msalService.instance.getActiveAccount())?.homeAccountId;
        let loginhint = (this.msalService.instance.getActiveAccount())?.idTokenClaims?.login_hint;
        const currentAccount = this.msalService.instance.getAccountByHomeId(ss!);
        if (currentAccount != null) {
          this.msalService.instance.logoutRedirect({ account: currentAccount, idTokenHint: loginhint, logoutHint: loginhint });
          localStorage.clear();
        } else {
          this.msalService.instance.logoutRedirect()
        }

      }
    });

  }

  navigateTerms() {
    this.router.navigate(['/terms-and-conditions']);
  }

  navigateToDashboard() {
    this.router.navigate(['/home/resources']);
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
  navigateTo(navLink: any): void {
    let staticAddress = 'https://www.icertis.com/';
    this.dialogService.confirmDialog({
      title: this.logoutEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.logoutEnum.redirect_dialog_body,
      confirmText: this.logoutEnum.redirect_dialog_primary_btn,
      cancelText: this.logoutEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if (res) {
        if (navLink == 'home') {
          window.open(staticAddress, "_blank");
        } else {
          window.open(staticAddress + navLink, "_blank");
        }

      }
    })
  }

}
