import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';


const ClmAdmin_Icon = `<svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-450q-58 0-97.5-39.5T343-587q0-58 39.5-97.5T480-724q58 0 97.5 39.5T617-587q0 58-39.5 97.5T480-450Zm0-60q34 0 55.5-21.5T557-587q0-34-21.5-55.5T480-664q-34 0-55.5 21.5T403-587q0 34 21.5 55.5T480-510Zm0 429q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Zm0-399Zm0-337-260 98v196q0 63 17.5 120.5T287-296q46-25 93.5-37.5T480-346q52 0 99.5 12.5T673-296q32-49 49.5-106.5T740-523v-196l-260-98Zm0 531q-39 0-78 10t-77 30q32 35 71 61.5t84 41.5q45-15 84-41.5t71-61.5q-38-20-77-30t-78-10Z"/></svg>`
@Component({
  selector: 'app-provision-users',
  templateUrl: './provision-users.component.html',
  styleUrls: ['./provision-users.component.scss']
})
export class ProvisionUsersComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild(MatSort) sort: MatSort | undefined;
  breadCrumbItems: any;
  instanceId:any;
  public instanceEnum = InstanceManagement;
  @ViewChild('search_input') inputName: any;
  @ViewChild('provisioningStatus') provisioningStatus!: MatSelect;
  @ViewChild('userCategory') userCategory!: MatSelect;
  @ViewChild('userType') userType!: MatSelect;
  selectAll :any = 'selectAll';
  sortBy: string = '';
  filter: string = '';
  status: any = '';
  ViewData:any=[];
  deploymentCount:number=0;
  dataSource:any=[];
  noContent:boolean=false;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'isProvisionStatus',
    'isCLMAdmin',
    'action',
  ];
  currentPage = 0;
  pageLength: any = 0;
  pageNumber: any = 1;
  pageSize: any = 5;
  customerId:any;
  isInstanceUserProvision: boolean = false;
  listData: any=[];
  userLimit:any;
  limitProvision:boolean=false;
  userCategoryFilter:any='';
  icertisRole: boolean = false;
  provisionedUsers:number = 0;
  isAddInstance: boolean = false;
  userTypeFilter: any = '';

  constructor(
    private instanceService: InstanceService,
    private router: Router,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialogService: DialogService,
    ) { iconRegistry.addSvgIconLiteral('ClmAdmin_Icon', sanitizer.bypassSecurityTrustHtml(ClmAdmin_Icon));}

  ngOnInit(): void {
    this.isInstanceUserProvision = this.permissionService.getPermission('InstanceUserProvision');
    this.icertisRole = this.permissionService.hasGlobalDataAccess();
    this.isAddInstance = this.permissionService.getPermission('AddInstance');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'Provision Users', path: 'provision-users' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
  }

  //Get all the instances
  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.customerId = this.ViewData.customerId;
            this.userLimit = this.ViewData.partnerUserQuota;
            this.getUserProvisionListData();
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  Provision(data:any){
    try{
      this.instanceService
      .provisionUserInICI(this.instanceId, data.id)
        .subscribe((resp: any) => {
          if(resp.isSuccessful){
            this.notificationService.showNotification(
              this.instanceEnum.instance_non_idn_user_provision_success_body,
              'success',
              this.instanceEnum
                .instance_non_idn_user_provision_success_header
            );
            this.getUserProvisionListData();
          } else {
            this.notificationService.showNotification(
              this.instanceEnum
                .instance_non_idn_user_provision_failure_body,
              'error',
              this.instanceEnum
                .instance_non_idn_user_provision_failure_header
            );
          }
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_List_user_provision)
        })
    } catch(error) {
      console.log(error);
    }
  }

  makeCLMAdmin(data:any){
    try{
      this.instanceService
      .makeCLMAdmin(this.instanceId, data.id)
        .subscribe((resp: any) => {
          if(resp.isSuccessful){
            this.notificationService.showNotification(
              'Mapping to CLM admin has completed',
              'success',
              'Success'
            );
            this.getUserProvisionListData();
          } else {
           let errorMsg = resp.message
            this.notificationService.showNotification(
              errorMsg,
              'error',
              this.instanceEnum.instance_list_clm_Admin_failed_header
            );
          }
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_list_clm_Admin_header)
        })
    } catch(error) {
      console.log(error);
    }
  }

  getUserProvisionListData(){
    try {
      this.instanceService
      .getInstanceICIUsers(this.customerId, this.instanceId, this.filter, this.sortBy, this.pageNumber, this.pageSize, this.status, this.userCategoryFilter, this.userTypeFilter)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.listData = result
            this.noContent = false;
            if(resp.status == 200){
              this.provisionedUsers = this.listData.totalUsersProvisioned;
            }
            this.listData.totalUsersProvisioned >= this.userLimit? this.limitProvision=true: this.limitProvision=false;
            this.paginator.length = result.matchingCount;            
          }else{
            this.noContent = true;
            this.listData = []
          }
          this.dataSource = new MatTableDataSource<any>(this.listData.instanceICIUserDetails);
          this.paginator.pageIndex = this.currentPage;
          if(this.empTbSort){
            this.empTbSort.disableClear = true;
          }
              
        });
    } catch (error) {
      console.log(error);
    }
  }

  onCancel(){
    localStorage.setItem('instanceID', this.instanceId);
    this.router.navigate(['home/instanceoperations/instance-requests/view']);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
    this.getUserProvisionListData();
  }

  public doFilter = () => {
    this.filter = this.inputName?.nativeElement.value;
    this.status =
      this.provisioningStatus?.value == undefined ||
        this.provisioningStatus?.value == this.selectAll
        ? ''
        : this.provisioningStatus?.value;
    this.userCategoryFilter = this.userCategory?.value == undefined || 
        this.userCategory?.value == this.selectAll
        ? ''
        :this.userCategory?.value;
    this.userTypeFilter = this.userType?.value == undefined || 
        this.userType?.value == this.selectAll
        ? ''
        :this.userType?.value;
    this.getUserProvisionListData();
  };

  clearFilter() {
    this.inputName.nativeElement.value = '';
    if(this.provisioningStatus){
    this.provisioningStatus.value = '';
    }
    if(this.userCategory){
      this.userCategory.value = ''
    }
    if(this.userType){
      this.userType.value = ''
    }
    this.pageNumber = 1;
    this.pageSize = 5;
    this.currentPage = 0;
    this.doFilter();
  }

  ClearInput(event: any, filter: any) {
    this.inputName.nativeElement.value = '';
    this.doFilter();
  }

   /** Announce the change in sort state for assistive technology. */
   announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    this.getUserProvisionListData();
  }

  viewPartnerProvisionedUsers(){
    this.dialogService.confirmDialog({
      title: "Instance Users",
      module: 'partner-provisionedUser',
      message: JSON.stringify(this.listData?.partners),
      confirmText: '',
      cancelText: 'Close'
    }).subscribe((response: any) => {});
  }
}