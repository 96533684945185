import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';

export interface TableFormat {
  id: number;
  eventTaskInterestName: string;
  eventTaskInterestTopicName: string;
  eventTaskInterestSubscriptionName:string;
  eventTaskInterestParellelWorkers:boolean;
  eventTaskInterestFilter:string;
}

export interface TableFormat1 {
  id: number;
  taskSettingName: string;
  taskSettingIsEncrypted:boolean;
}

@Component({
  selector: 'app-edit-ici-workertask',
  templateUrl: './edit-ici-workertask.component.html',
  styleUrls: ['./edit-ici-workertask.component.scss'],
})
export class EditIciWorkertaskComponent implements OnInit {
  breadCrumbItems: any;
  instanceId: any = 0;
  ViewData: any = [];
  public instanceEnum = InstanceManagement;
  selectedTaskId: any = null;
  selectTask = new FormControl('');
  Tasks: any = [
    { id: 1, name: 'Event Task' },
    { id: 2, name: 'Scheduled Task' },
  ];
  eventTaskId: any = '1';
  scheduledTaskId: any = '2';
  public regex = InputPattern;
  regexName: any = new RegExp(this.regex.namePattern);
  finalInterestConfiguration: any = [];
  finalSettingConfiguration: any = [];
  interestConfiguration: any = [
    {
      id: 0,
      eventTaskInterestName: '',
      eventTaskInterestTopicName: '',
      eventTaskInterestSubscriptionName: '',
      eventTaskInterestParellelWorkers: false,
      eventTaskInterestFilter: '',
    },
  ];
  settingConfiguration: any = [
    {
      id: 0,
      taskSettingName: '',
      taskSettingIsEncrypted: false,
    },
  ];
  isDecrypted: boolean = false;
  dataSource: any = [];
  dataSource1: any = [];
  cronExpression: any;
  displayedColumns: string[] = [
    'Name',
    'Topic Name',
    'Subscription Name',
    'Parallel Workers',
    'Filter',
    'action',
  ];

  displayedColumns1: string[] = ['Name', 'isEncrypted', 'action'];
  partnerId: any = 0;
  customerId: any = 0;
  workerTaskId: any;
  viewTaskData: any = {};
  regexDescriptionName: any = new RegExp(this.regex.descriptionPattern);
  errorDescription: any = this.regex.descriptionErrorMessage;

  constructor(
    private permissionService: PermissionsService,
    private instanceService: InstanceService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.selectTask.disable();
    this.frmTask.get('id')?.disable();
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      {label: 'Instance Operations', path: 'instanceoperations/instance-requests'},
      {label: 'Instance Requests', path: 'instanceoperations/instance-requests'},
      { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
      {label: 'ICI Worker Tasks', path: 'instanceoperations/instance-requests/ici-workertask-configuration/list'},
      { label: 'Edit', path: 'instanceoperations/instance-requests/ici-workertask-configuration/edit' },
    ];
    this.workerTaskId = localStorage.getItem('workerTaskId');
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    this.frmTask.get('description')?.valueChanges.subscribe((event:any)=>{
      if (event == undefined) {
        this.errorDescription = this.regex.descriptionErrorMessage;
        return;
      }
      if (!this.regexName.test(event[0]) && event != undefined) {
        this.errorDescription = this.regex.firstCharacterErrorMessage;
        return;
      }
      else {
        this.errorDescription = this.regex.descriptionErrorMessage;
      }
    })
  }

  frmTask: FormGroup = new FormGroup({
    id: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?!1000$)(?:[1-9][0-9]{3,5})$/),
    ]),
    name: new FormControl('', [Validators.pattern(this.regexName)]),
    type: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.pattern(this.regexDescriptionName)]),
  });

    //Get all the instances
    getInstanceByID() {
      try {
        this.instanceService
          .getInstanceByID(this.instanceId)
          .subscribe((resp: any) => {
            let result = resp.body;
            if (result != undefined && result != null) {
              this.ViewData = result;
              this.customerId = result.customerId;
              this.getIciWorkerTaskConfigurationById()
            }
          });
      } catch (error) {
        console.log(error);
      }
    }

  getIciWorkerTaskConfigurationById() {
    try {
      this.instanceService
        .getIciWorkerTaskConfigurationById(this.ViewData?.cname, this.workerTaskId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.viewTaskData = result;
            this.isDecrypted = result.useDecryption;
            this.partnerId = result.partnerId;
            this.selectedTaskId = result?.category == "EventBased"? Number(this.eventTaskId) : Number(this.scheduledTaskId);
            this.frmTask.patchValue({
              id: result.taskId,
              name: result.taskName,
              type: result.taskType,
              description: result.description,
            });
            this.cronExpression = result.cronExpression;
            let settingConfig = result.settingList;
            if(settingConfig.length){
              this.settingConfiguration = settingConfig?.map((data: any, index:any) => {
                data = { id: index, taskSettingName:data.name, taskSettingIsEncrypted: data.isEncrypted};
                return data;
              });
            }
            let interestConfig = result.interestList;
            this.interestConfiguration = interestConfig?.map((data:any, index:any)=>{
              data = { 
                id: index, 
                eventTaskInterestName:data.name, 
                eventTaskInterestTopicName: data.topicName,
                eventTaskInterestSubscriptionName: data.subscriptionName,
                eventTaskInterestParellelWorkers: data.parallelWorkers == 1? true : false,
                eventTaskInterestFilter:data.filter
              };
              return data;
            });
            this.dataSource = new MatTableDataSource<TableFormat>(this.interestConfiguration);
            this.dataSource1 = new MatTableDataSource<TableFormat1>(this.settingConfiguration);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  selectedTask(taskid: any) {
    this.frmTask.reset();
    this.isDecrypted = false;
    this.cronExpression = '';
    this.interestConfiguration = [
      {
        id: 0,
        eventTaskInterestName: '',
        eventTaskInterestTopicName: '',
        eventTaskInterestSubscriptionName: '',
        eventTaskInterestParellelWorkers: false,
        eventTaskInterestFilter: '',
      },
    ];
    this.settingConfiguration = [
      {
        id: 0,
        taskSettingName: '',
        taskSettingIsEncrypted: false,
      },
    ];
    this.selectedTaskId = taskid;
  }

  deleteRow(row: any) {
    this.interestConfiguration = this.interestConfiguration.filter(
      (data: any) => {
        return data.id != row.id;
      }
    );
    this.dataSource = new MatTableDataSource<TableFormat>(
      this.interestConfiguration
    );
  }

  deleteSettingConfigurationRow(row: any) {
    this.settingConfiguration = this.settingConfiguration.filter(
      (data: any) => {
        return data.id != row.id;
      }
    );
    this.dataSource1 = new MatTableDataSource<TableFormat1>(
      this.settingConfiguration
    );
  }

  addRow(row: any) {
    let index = row.id;
    let data = {
      id: index + 1,
      eventTaskInterestName: '',
      eventTaskInterestTopicName: '',
      eventTaskInterestSubscriptionName: '',
      eventTaskInterestParellelWorkers: false,
      eventTaskInterestFilter: '',
    };
    this.interestConfiguration.push(data);
    this.dataSource = new MatTableDataSource<TableFormat>(
      this.interestConfiguration
    );
  }

  addSettingConfigurationRow(row: any) {
    let index = row.id;
    let data = {
      id: index + 1,
      taskSettingName: '',
      taskSettingIsEncrypted: false,
    };
    this.settingConfiguration.push(data);
    this.dataSource1 = new MatTableDataSource<TableFormat1>(
      this.settingConfiguration
    );
  }

  onSubmit() {
    let JsonInput: any = {};
    JsonInput.taskID = (this.frmTask.controls['id'].value)?.toString();
    JsonInput.taskName = this.frmTask.controls['name'].value;
    JsonInput.taskType = this.frmTask.controls['type'].value;
    JsonInput.taskDescription = this.frmTask.controls['description'].value;
    JsonInput.taskSetting = this.settingConfiguration.filter((row: any) => {
      return row.taskSettingName != '';
    });
    JsonInput.operation = 2;
    JsonInput.taskUseDecryption = this.isDecrypted;
    JsonInput.workerTaskType = this.selectedTaskId;
    if (this.selectedTaskId == 2) {
      JsonInput.eventTaskInterest = [];
    } else {
      JsonInput.eventTaskInterest = this.interestConfiguration;
    }
    JsonInput.taskCronExpression = this.cronExpression;
    JsonInput.id = this.workerTaskId;
    if(this.partnerId == null){
      this.partnerId="";
    }
    this.instanceService
      .updateTask(JsonInput, this.instanceId, this.partnerId, this.customerId)
      .subscribe((data) => {
        if (data.body.isSuccess) {
          this.notificationService.showNotification(
            this.instanceEnum.workertask_notify_task_update_body,
            'success',
            this.instanceEnum.workertask_notify_task_update_heading
          );
          this.router.navigate(['home/instanceoperations/instance-requests/ici-workertask-configuration/list']);
        } else {
          this.notificationService.showNotification(
            this.instanceEnum.workertask_notify_task_update_failed_body,
            'error',
            this.instanceEnum.workertask_notify_task_update_failed_heading
          );
        }
      });
  }

  onCancel() {
    this.frmTask.reset();
    this.isDecrypted = false;
    this.cronExpression = '';
    this.interestConfiguration = [
      {
        id: 0,
        eventTaskInterestName: '',
        eventTaskInterestTopicName: '',
        eventTaskInterestSubscriptionName: '',
        eventTaskInterestParellelWorkers: false,
        eventTaskInterestFilter: '',
      },
    ];
    this.settingConfiguration = [
      {
        id: 0,
        taskSettingName: '',
        taskSettingIsEncrypted: false,
      },
    ];
    this.router.navigate(['home/instanceoperations/instance-requests/ici-workertask-configuration/list']);
  }

  validateForm() {
    let validateInterest = this.interestConfiguration?.length && this.interestConfiguration?.every((row: any) => {
      return (
        row.eventTaskInterestName != '' &&
        row.eventTaskInterestTopicName != '' &&
        row.eventTaskInterestSubscriptionName != ''
      );
    });
    if (this.frmTask.valid && (validateInterest || (this.cronExpression != '' && this.cronExpression != 'NULL'))) {
      return false;
    } else {
      return true;
    }
  }
}
