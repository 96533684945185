import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AESEncryptDecryptService } from './encryption/aesencrypt-decrypt.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  msalService: any;
  headers: any = environment.headers;
  constructor(
    private http: HttpClient,
    private _AESEncryptDecryptService: AESEncryptDecryptService
  ) { }
  baseApiUrl: string = environment.appGatewayUrl;

  public getPermissionListForUser(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/userpermissions', { observe: 'response' })
  }
  public getPermission(permission: string) {
    if (localStorage.getItem("authenticatedUser") != null && this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser")).permissions.includes(permission)) {
      return true;
    } else {
      return false;
    }
  }

  isActiveUser() {
    if (localStorage.getItem('authenticatedUser') != null) {
      return this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.isActive;
    }
  }

  isTermsAccepted() {
    if (localStorage.getItem('authenticatedUser') != null) {
      return this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.isTermsAccepted;
    }
  }

  isICIAcademyCertificationCompleted() {
    if (localStorage.getItem('authenticatedUser') != null) {
      return this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.isICIAcademyCertificationCompleted;
    }
  }

  hasGlobalDataAccess() {
    if (localStorage.getItem('authenticatedUser') != null) {
      return this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.hasGlobalDataAccess
    }
  }

  getUniqueUserid() {
    if (localStorage.getItem('authenticatedUser') != null) {
      let userID = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.id;
      return userID;
    }
  }

  getUsercategoryId() {
    if (localStorage.getItem('authenticatedUser') != null) {
      let userID = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.userCategoryId;
      return userID;
    }
  }

  getUserCustomers() {
    if (localStorage.getItem('customers') != null) {
      let customers = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.userCustomers; 
      return customers;
    }
  }

  getuserEmail() {
    if (localStorage.getItem('authenticatedUser') != null) {
      let userEmail = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.email;
      return userEmail;
    }
  }

  getPartnerDetails() {
    if (localStorage.getItem('authenticatedUser') != null) {
      var partnerID = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.partnerId;
      var partnerName = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.partnerName;
    }
    let partnerDetails = {
      'id': partnerID,
      'name': partnerName
    };
    return partnerDetails;
  }

  getCustomerDetails() {
    if (localStorage.getItem('authenticatedUser') != null) {
      var userCategoryId = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.userCategoryId;
      var userCategoryName = this._AESEncryptDecryptService.decrypt(localStorage.getItem("authenticatedUser"))!.user.userCategoryName;
    }
    let customerDetails = {
      'id': userCategoryId,
      'name': userCategoryName
    };
    return customerDetails;
  }

  public GetIDNConfiguration(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/PublicPortal/GetIDNConfiguration', { observe: 'response' })
  }


}
