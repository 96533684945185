
<div class="idn-loader-overview" *ngIf="loading$ | async" >
  <div class="pss-loader-v2" ><mat-spinner [strokeWidth]="3" [diameter]="32"></mat-spinner><p>Please wait...</p></div>
</div>
<div class="pss-login">
  <div class="pss-login-content">
    <div class="wrapper">
      <img src="../../../assets/images/logo/Icertis_logo_vertical.svg" alt="Icertis Logo">
      <h1>{{ greeting }}</h1>
      <h2>{{ 'HOME.LOGIN.SUBHEADING' | translate }}</h2>
      <button class="pss-button-primary pss-button-primary__rounded" (click)="navigateHome()" aria-label="Go back to Home Page">
        <mat-icon>west</mat-icon>
      </button>
    </div>
  </div>
  <div class="pss-login-form">
    <div class="form-wrapper">
      <h2 class="heading">{{ 'HOME.LOGIN.LOGIN' | translate }}</h2>
      <div class="info">
        <span class="material-icons-outlined">info</span>
        <p>{{ 'HOME.LOGIN.INFO' | translate }}</p>
      </div>
      <form class="pss-form" [formGroup]="frmemail">
        <mat-form-field class="pss-block-form mb-1">
          <mat-label>{{ 'HOME.LOGIN.EMAIL' | translate }}</mat-label>
          <mat-icon matPrefix class="pss-form-icon">email</mat-icon>
          <input type="email" matInput placeholder="Ex. pat@example.com" formControlName="emailAddress">
          <mat-error *ngIf="frmemail.controls.emailAddress.errors">
            {{ 'HOME.LOGIN.ERROR' | translate }}
          </mat-error>
        </mat-form-field>
        <button mat-flat-button color="accent" class="pss-mat--button-large idn-theme-btn" (click)="navigateDash()">
          {{ 'HOME.LOGIN.NEXT' | translate }}
          <mat-icon>arrow_right_alt</mat-icon>
        </button>
      </form>

    </div>
  </div>

</div>
