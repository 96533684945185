import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { LoaderService } from 'src/app/core/interceptors/loader/loader.service';
import { AESEncryptDecryptService } from 'src/app/core/services/common/encryption/aesencrypt-decrypt.service';
import { catchError, lastValueFrom, throwError } from 'rxjs';
import { IdnConfigurationService } from 'src/app/core/services/common/idn-configuration.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    private titleService: Title,
    private router: Router, private msalService: MsalService,
    private permserv: PermissionsService,
    private loaderService: LoaderService,
    private permissionService:PermissionsService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private idnConfiguration:IdnConfigurationService,
  ) { }

  loading$ = this.loaderService.loading$;
  greeting: string = 'Greeting';
  time: number = 10;
  activeAccount: any;
  frstemail = "";
  isuserLoggedin: boolean = false;
  viewPodUpgradeFeature:any;
  viewEnableICIWorkerTaskConfigurationFeature: any;
  featuresTobeDisabled:any =[];
  toggleMenuContainer: any = { 
    "administration": false,
    "developmentandutilities": false,
    "instanceoperations": false,
    "sku-management": false,
    "upgrade": false
  };

  ngOnInit(): void {
    this.titleService.setTitle('Login | Icertis Developer Network');
    this.getGreeting();
    this.msalService.instance.handleRedirectPromise().then(
      res => {
        if (res != null && res.account != null) {
          this.msalService.instance.setActiveAccount(res.account);
          localStorage.setItem("loggedin", "true");
          localStorage.setItem("vialoginpage",'false');
          this.activeAccount = this.msalService.instance.getActiveAccount();
          try {
            localStorage.setItem('appPushnotification', 'true');
            this.permserv.getPermissionListForUser().pipe(
              catchError(err => {
                if(err == "User not be found or not active") {
                  this.userNotFound();
                }
                return throwError(err);
              })
            ).subscribe(async res => {
              if (res.body != undefined || res.body != null) {
                  let authuserpermissions = res.body;
                  localStorage.setItem("authenticatedUser", this._AESEncryptDecryptService.encrypt(JSON.stringify(authuserpermissions)));
                  var cleanStr = this.activeAccount.name.replace("(External)", '');
                  localStorage.setItem("username", cleanStr);
                  var username = new String(cleanStr);
                  var usernamesplits = username.split(" ");
                  var splittedUsername = usernamesplits[0].charAt(0);
                  var userAvatar = splittedUsername.concat(usernamesplits[1].charAt(0));
                  localStorage.setItem("userAvatar", userAvatar);
                  localStorage.setItem("email", authuserpermissions.user.email);
                  localStorage.setItem("customers", authuserpermissions.user.userCustomers[0]);
                  localStorage.setItem("roleId", authuserpermissions.user.roleId);
                  localStorage.setItem("WBReleaseLink", JSON.stringify(true));
                  if(authuserpermissions.user.userCategoryId == USER_CATEGORY_ID.Customer){
                    await this.handleNavigation(authuserpermissions);
                  }
                  else{
                    if (authuserpermissions.user.isICIAcademyCertificationCompleted) {
                      await this.handleNavigation(authuserpermissions);
                    }
                       else{
                         this.router.navigate(['authorize-icicertification']);
                      }
                  }
              } else {
                  this.userNotFound();
              }
            })
          }
          catch (execption) {
            console.log(execption)
          }
        }
      }
    );
  }
  frmemail: FormGroup = new FormGroup({
    emailAddress: new FormControl('', [
      Validators.required,
      Validators.pattern("^([\\w-]+(?:\\.[\\w-]+)*)@((?:[\\w-]+\\.)*\\w[\\w-]{0,66})\\.([A-Za-z]{2,6}(?:\\.[A-Za-z]{2,6})?)$"),
      Validators.email
    ]),
  });

  async handleNavigation(authuserpermissions:any){
    if (authuserpermissions.user.isTermsAccepted) {
      let configValues =await this.idnConfiguration.getConfigValues();        
      if(configValues){
         if(!(this.permissionService.getPermission('UpgradeMultiTenantPOD') && !this.permissionService.getPermission('PreviewMultiTenantPODUpgrade'))){
           this.router.navigate(['home/resources']);
           localStorage.setItem('toggleMenuContainer', JSON.stringify(this.toggleMenuContainer));
         }else{
           this.router.navigate(['home/upgrade/pod-upgrade']);
            for (let item in this.toggleMenuContainer) {
              if (item == 'upgrade') {
                this.toggleMenuContainer[item] = true;
              } else {
                this.toggleMenuContainer[item] = false;
              }
            }
          localStorage.setItem('toggleMenuContainer', JSON.stringify(this.toggleMenuContainer));
         }
       }
    } else {
      this.router.navigate(['accept-terms-of-use']);
    }
  }

   async getConfigValues(){
    try {
      return await lastValueFrom(this.permissionService.GetIDNConfiguration());
    }
    catch (error: any) {
      console.error(error);
    }
  }

  userNotFound(){
    var cleanStr = this.activeAccount.name.replace("(External)", '');
    localStorage.setItem("username", cleanStr);
    var username = new String(cleanStr);
    var usernamesplits = username.split(" ");
    var splittedUsername = usernamesplits[0].charAt(0);
    var userAvatar = splittedUsername.concat(usernamesplits[1].charAt(0));
    localStorage.setItem("userAvatar", userAvatar);
    this.router.navigate(['home/resources']);
  }
  getGreeting(): void {
    let time = new Date();
    let hours = time.getHours();
    if (hours < 12) {
      this.greeting = 'Good Morning';
    } else if (hours >= 12 && hours <= 17) {
      this.greeting = 'Good Afternoon';
    } else if (hours >= 17 && hours <= 24) {
      this.greeting = 'Good Evening';
    }
  }
  navigateHome(): void {
    this.router.navigate(['']);
  }
  navigateDash(): void {
    if (this.frmemail.valid) {
      var request = {
        scopes: ["user.read"],
        loginHint: this.frstemail
      }
      localStorage.setItem("vialoginpage",'true');
      this.msalService.loginRedirect(request);
    }
  }
}
