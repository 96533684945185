import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root'
})
export class IdnConfigurationService {

  constructor(private permissionService: PermissionsService) { }

  viewPodUpgradeFeature:any;
  viewEnableICIWorkerTaskConfigurationFeature:any;
  featuresTobeDisabled:any;
  minimumSupportedICIVersionInIDN:any = "";
  uploadSetIntervalDuration:any;
  uploadStopIntervalDuration:any;
  public whatsNew = new BehaviorSubject<any>('');

  async getConfigValues(){
    try {
      let config= await lastValueFrom(this.permissionService.GetIDNConfiguration()); 
      if(config){    
        this.viewPodUpgradeFeature = config?.body.content.enableMTPodUpgradeCalendarFeature;
          this.viewEnableICIWorkerTaskConfigurationFeature = config?.body.content.enableICIWorkerTaskConfigurationFeature;
          this.featuresTobeDisabled = config?.body.content.featuresTobeDisabled;
          this.minimumSupportedICIVersionInIDN = config?.body.content.minimumSupportedICIVersionInIDN;
          this.uploadSetIntervalDuration = config?.body.content.bulkUploadSetIntervalDuration;
          this.uploadStopIntervalDuration = config?.body.content.bulkUploadStopIntervalDuration;
          localStorage.setItem('uploadSetIntervalDuration',this.uploadSetIntervalDuration);
          localStorage.setItem('uploadStopIntervalDuration',this.uploadStopIntervalDuration);
          localStorage.setItem('enableMTPodUpgradeCalendarFeature', this.viewPodUpgradeFeature);
          localStorage.setItem('enableICIWorkerTaskConfigurationFeature',this.viewEnableICIWorkerTaskConfigurationFeature);
          localStorage.setItem('featuresTobeDisabled',this.featuresTobeDisabled);
      }
      return config;
    }
    catch (error: any) {
      console.error(error);
    }
  }

  async getMinimumIciversionForBinaryPackage(){
    try{
      if(this.minimumSupportedICIVersionInIDN){
        return this.minimumSupportedICIVersionInIDN;
      } else {
        let config= await lastValueFrom(this.permissionService.GetIDNConfiguration());
        return config?.body.content.minimumSupportedICIVersionInIDN;
      }
    } catch (error: any) {
      console.error(error);
    }
  }

  emitWhatsNewFeature<T>(data: T) {
    this.whatsNew.next(data);
  }

  onWhatsNewFeatue<T>(): Observable<T> {
    return this.whatsNew.asObservable();
  }
}
