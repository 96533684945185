import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IdnTermsService } from 'src/app/core/services/common/auth/idn-terms/idn-terms.service';
import { DomSanitizer, SafeHtml, Title } from "@angular/platform-browser";

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {
  content: SafeHtml;
  constructor(
    private idnTermsIdnTermsService: IdnTermsService,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private changeDetector: ChangeDetectorRef
  ) {
    this.content = this.sanitizer.bypassSecurityTrustHtml(
      this.idnTermsIdnTermsService.getTermsContent()
    );
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.titleService.setTitle('Terms and Conditions | IDN');
   }
   ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
