import { HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionsService } from '../../services/common/permissions.service';
import { EnvService } from '../../services/env.service';
import { AESEncryptDecryptService } from '../../services/common/encryption/aesencrypt-decrypt.service';

@Injectable()
export class IdnGuardService {
  headers: any = environment.headers;
  constructor(
    private router: Router,
    private permissionService: PermissionsService,
    private msalService: MsalService,
    private env: EnvService,
    private http: HttpBackend,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
  ) { }
  baseApiUrl: string = environment.appGatewayUrl;
  activeAccount: any;

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let isLoggedIn = this.msalService.instance.getActiveAccount() != null;
    let isActive: any;
    let terms: any;
    let isICIAcademycertified: any;
    // Check if user is authorize or not
    if (localStorage.getItem("authenticatedUser") != null && localStorage.getItem("authenticatedUser") != undefined) {
      let value = await this.getpermission();
      let authuserpermissions = value.body;
      localStorage.setItem('authenticatedUser', this._AESEncryptDecryptService.encrypt(JSON.stringify(authuserpermissions)));
      isActive = value.body.user.isActive;
      terms = value.body.user.isTermsAccepted;
      isICIAcademycertified = value.body.user.isICIAcademyCertificationCompleted;
    }

    if (isICIAcademycertified && isActive && terms) {
      return true;
    }
    else if (!isICIAcademycertified && isICIAcademycertified != undefined) {
      this.router.navigate(['authorize-icicertification']);
    }
    else if (!isActive && isActive != undefined) {
      this.router.navigate(['un-authorized']);
    } else if (!terms && terms != undefined) {
      this.router.navigate(['accept-terms-of-use']);
    }
    else {
      this.router.navigate(['login']);
    }
    return false;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let isActive: any;
    let terms: any;
    let isICIAcademycertified: any;
    // Check if user is authorize or not
    if (localStorage.getItem("authenticatedUser") != null && localStorage.getItem("authenticatedUser") != undefined) {
      isActive = this.permissionService.isActiveUser();
      terms = this.permissionService.isTermsAccepted();
      isICIAcademycertified = this.permissionService.isICIAcademyCertificationCompleted();
    }
    //Check if user is active or not
    if (isICIAcademycertified && isActive && terms) {
      return true;
    }
    else if (!isICIAcademycertified  && isICIAcademycertified != undefined) {
      this.router.navigate(['authorize-icicertification']);
    }
    else if (!isActive && isActive != undefined) {
      this.router.navigate(['un-authorized']);
    } else if (!terms && terms != undefined) {
      this.router.navigate(['accept-terms-of-use']);
    }
    else {
      this.router.navigate(['login']);
    }
    return false;
  }

  async getpermission(): Promise<any> {
    try {
      // Call userpermission api (bypassing interceptor)
      return await lastValueFrom(this.permissionService.getPermissionListForUser());
    } catch (err: any) {
      if (err.error == "User not be found or not active") {
        this.router.navigate(['un-authorized']);
      }
    }
  }

}
