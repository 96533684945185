<mat-drawer-container>
    <mat-drawer #drawer class="idn-preview" mode="over" position="end">
        <div class="header">
            <div class="heading">
                <mat-icon>upgrade</mat-icon>
                <h2>{{mtPodEnum.multiTenantPods_preview_header}}</h2>
            </div>
            <button mat-icon-button (click)="closeDrawer()" aria-label="Close button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="body">
            <div class="pss-info-widget-mt-pod">
                <mat-icon class="icon">info</mat-icon>
                <p>Proceed with upgrade process by selecting compatible version and packages
                </p>
            </div>

            <div class="page-container">
                <div>
                    <form [formGroup]="frmAddPackage">
                        <div class="idn-pod-upgrade-version">
                            <h3>
                             {{mtPodEnum.multiTenantPods_current_version}}<span> [{{podDetails.iciVersion}}]</span>
                            </h3>
                            <h3>
                                {{mtPodEnum.multiTenantPods_upgrade_to_version}}<span> [ {{frmAddPackage.value.name}}
                                    ]</span>
                            </h3>
                        </div>
                        
                        <span>
                            <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
                                <mat-label class="label">{{mtPodEnum.package_Create_Field_major}}</mat-label>
                                <input matInput placeholder=">=0" min="0" type="number" step="any"
                                    formControlName="major" required>
                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                    (click)="$event.preventDefault(); increaseValue('major')">
                                    <mat-icon class="idn-version-button">add</mat-icon>
                                </button>
                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                    (click)="$event.preventDefault(); decreaseValue('major')">
                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                </button>
                            </mat-form-field>
                        </span>
                        <span>
                            <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
                                <mat-label class="label">{{mtPodEnum.package_Create_Field_minor}}</mat-label>
                                <input matInput placeholder=">=0" min="0" type="number" step="any"
                                    formControlName="minor" required>
                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                    (click)="$event.preventDefault(); increaseValue('minor')">
                                    <mat-icon class="idn-version-button">add</mat-icon>
                                </button>
                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                    (click)="$event.preventDefault(); decreaseValue('minor')">
                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                </button>
                            </mat-form-field>
                        </span>
                        <span>
                            <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
                                <mat-label class="label">{{mtPodEnum.package_Create_Field_patch}}</mat-label>
                                <input matInput placeholder=">=0" min="0" type="number" step="any"
                                    formControlName="patch" required>
                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                    (click)="$event.preventDefault(); increaseValue('patch')">
                                    <mat-icon class="idn-version-button">add</mat-icon>
                                </button>
                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                    (click)="$event.preventDefault(); decreaseValue('patch')">
                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                </button>
                            </mat-form-field>
                        </span>
                        <span>
                            <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
                                <mat-label class="label">{{mtPodEnum.package_Create_Field_build}}</mat-label>
                                <input matInput placeholder=">=0" min="0" type="number" step="any"
                                    formControlName="build" required>
                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                    (click)="$event.preventDefault(); increaseValue('build')">
                                    <mat-icon class="idn-version-button">add</mat-icon>
                                </button>
                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                    (click)="$event.preventDefault(); decreaseValue('build')">
                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                </button>
                            </mat-form-field>
                        </span>
                        <span>
                            <button class="idn-version-preview" mat-raised-button color="primary"
                                [disabled]="allowPreview" *ngIf="isViewPreviewUpgrade" (click)="previewUpgrade()">
                                {{mtPodEnum.multiTenantPods_preview_upgrade}}
                            </button>
                        </span>
                        <mat-error
                            *ngIf="allowPreview">{{mtPodEnum.multiTenantPods_upgrade_pod_downgrade_message}}</mat-error>
                    </form>

                    <div class="idn-noPreview" *ngIf="!isPreviewTable">
                        <mat-icon class="idn-noPreview" svgIcon="no_preview_icon"></mat-icon>
                        <div class="no-preview"> We do not have anything to preview </div>
                        <div class="no-preview-details">Input required upgrade versions and check for <br> extensions
                            preview and compatibility
                        </div>
                    </div>

                    <h2 class="upgrade-extension" *ngIf="isPreviewTable && !noContent">{{mtPodEnum.multiTenantPods_View_Field_extension}}</h2>
                    <div class="idn-table" *ngIf="isPreviewTable && !noContent">                        
                        <table mat-table *ngIf="!noContent " [dataSource]="dataSource" class="mat-elevation-z8 upgrade-table">
                            <ng-container matColumnDef="select" class="upgrade-table">
                                <th mat-header-cell *matHeaderCellDef>
                                    <mat-checkbox #ref (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="isSomeSelected() && !isAllSelected()">
                                    </mat-checkbox>
                                </th>
                                <td mat-cell *matCellDef="let row, let i=index">
                                    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="row.selected"
                                        [disabled]="!row.isCompatible" [checked]="selection.isSelected(row)"
                                        (change)="$event ? selection.toggle(row) : null;updateCheckedList($event,row)">
                                    </mat-checkbox>
                                </td>
                            </ng-container>

                            <!-- Position Column -->
                            <ng-container matColumnDef="tenantName">
                                <th mat-header-cell *matHeaderCellDef matTooltip="Tenant Name">
                                    {{mtPodEnum.multiTenantPods_preview_feild_instance}}</th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.instanceName}} </td>
                            </ng-container>

                            <!-- Name Column -->
                            <ng-container matColumnDef="extensionName">
                                <th mat-header-cell *matHeaderCellDef matTooltip="Extension Name">
                                    {{mtPodEnum.multiTenantPods_preview_feild_extension}}</th>
                                <td mat-cell *matCellDef="let element"> {{element.extensionName}} </td>
                            </ng-container>

                            <!-- Weight Column -->
                            <ng-container matColumnDef="version">
                                <th mat-header-cell *matHeaderCellDef matTooltip="Existing Extension Package Version">
                                    {{mtPodEnum.multiTenantPods_preview_feild_version}}</th>
                                <td mat-cell *matCellDef="let element"> {{element.version}} </td>
                            </ng-container>

                             <!-- Symbol Column -->
                             <ng-container matColumnDef="newVersion">
                                <th mat-header-cell *matHeaderCellDef matTooltip="New Extension Package Version">
                                    {{mtPodEnum.multiTenantPods_preview_feild_newVersion}}</th>
                                <td mat-cell *matCellDef="let element"> {{element.newVersion}} </td>
                            </ng-container>

                            <!-- ICI Column -->
                            <!-- <ng-container matColumnDef="iciVersion">
                                <th mat-header-cell *matHeaderCellDef matTooltip="ICI Version">
                                    {{mtPodEnum.multiTenantPods_preview_feild_iciVersion}}</th>
                                <td mat-cell *matCellDef="let element"> {{element.iciVersion}} </td>
                            </ng-container> -->

                            <!-- compatible Column -->
                            <ng-container matColumnDef="compatible">
                                <th mat-header-cell *matHeaderCellDef matTooltip="Compatible">
                                    {{mtPodEnum.multiTenantPods_preview_feild_compatible}}
                                </th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-chip-list class="tagStyle" aria-label="tags">
                                        <mat-chip [ngClass]="element.isCompatible == true ? 'yes-class' : 'no-class'">
                                            <mat-icon *ngIf="element.isCompatible == true">check_circle</mat-icon>
                                            <mat-icon *ngIf="element.isCompatible == false">cancel</mat-icon>
                                            <span class="idn-limit-tag">{{element.isCompatible == true ? 'Yes' :
                                                'No'}}</span>
                                        </mat-chip>
                                    </mat-chip-list>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                            </tr>
                        </table>
                    </div>

                    <div class="idn-noPreview" *ngIf="isPreviewTable && noContent">
                        <div class="idn-no-result">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
                                <defs>
                                    <clipPath id="clip-path">
                                        <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39"
                                            transform="translate(0 0)" fill="#515165" />
                                    </clipPath>
                                </defs>
                                <g id="Group_559" data-name="Group 559"
                                    transform="translate(2759.66 -7619.728) rotate(42)">
                                    <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
                                        transform="translate(3187.267 7517.8)" fill="#7c7c97" />
                                    <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
                                        transform="translate(3192.911 7517.8)" fill="#515165" />
                                    <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
                                        transform="translate(3178.801 7551.663)" fill="#ababc5" />
                                    <path id="Union_2" data-name="Union 2"
                                        d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                                        transform="translate(3178.801 7617.272)" fill="#7c7c97" />
                                    <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
                                        transform="translate(3192.911 7551.663)" fill="#7c7c97" />
                                    <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
                                        <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264"
                                            r="53.264" transform="translate(0 0)" fill="#cfdae2" />
                                        <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798"
                                            r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1" />
                                    </g>
                                    <g id="Mask_Group_7" data-name="Mask Group 7"
                                        transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
                                        <path id="Union_1" data-name="Union 1"
                                            d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                                            transform="translate(-14.11 0)" fill="#515165" />
                                    </g>
                                </g>
                            </svg>
                            <h4>{{mtPodEnum.multiTenantPods_preview_no_content_message}}</h4>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <div class="pss-drawer-button-wrapper">
            <button mat-raised-button color="primary" [disabled]="!(checkedItems.length>0)" (click)="startUpgrade()"
                *ngIf="isMtPodStartUpgrade">
                <mat-icon>check_circle</mat-icon> <span>{{mtPodEnum.multiTenantPods_preview_btn_start_upgrade}}</span>
            </button>
            <button mat-stroked-button (click)="closeDrawer()">Cancel</button>
        </div>

    </mat-drawer>
</mat-drawer-container>