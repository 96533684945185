import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { LogoutString } from 'src/app/shared/enum/logoutString';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ViewChild('stickyMenu')
  sticky: boolean = false;
  elementPosition: any;
  public logoutEnum = LogoutString;
  currentYear:any;
  constructor(
    private router: Router,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();

  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }

  scrollToTop() {
    return window.scrollTo(0, 0);
  }

  scrollToSection(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }

  navigateTerms() {
    this.router.navigate(['/terms-and-conditions']);
  }

  navigateToDashboard() {
    this.router.navigate(['/home/resources']);
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
  navigateTo(navLink: any): void {
    let staticAddress = 'https://www.icertis.com/';
    this.dialogService.confirmDialog({
      title: this.logoutEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.logoutEnum.redirect_dialog_body,
      confirmText: this.logoutEnum.redirect_dialog_primary_btn,
      cancelText: this.logoutEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if (res) {
        if (navLink == 'home') {
          window.open(staticAddress, "_blank");
        } else {
          window.open(staticAddress + navLink, "_blank");
        }

      }
    })
  }

}
