import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(value!=null){
      let dateObj = value.substring(2);
      let date = value.substring(0, 2);
      return `${date}${this.nth(date)}${dateObj}`;
    }
    else{
      return '';
    }
  }

  nth(d: any) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

}
