<mat-drawer-container class="download-files-container colorred">
  <mat-drawer #drawer class="download-files-drawer" position="end" mode="side">
    <div class="download-files-header">
      <h2><mat-icon class="close-icon">description</mat-icon>{{certificationEnum.certification_download_FieldLabel_downloadFiles}}</h2>
      <button mat-icon-button aria-label="Close Button" (click)="closeDrawer()">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
    <div class="download-files-content">
      <div class="download-files-info">
        <mat-icon class="close-icon">error</mat-icon>
        <p>{{certificationEnum.certification_download_Header_helpMessage}}</p>
      </div>
      <div class="download-files-form">
          <div class="files-list">
              <h3>{{certificationEnum.certification_download_FieldLabel_extenionName}}<span class="select-all-btn"><mat-checkbox color="primary" (change)="selectAll($event)" [checked]="isChecked()">{{certificationEnum.certification_download_FieldLabel_packageName}}</mat-checkbox></span></h3>
              <ul>
                  <ng-container *ngFor="let list of certificationFiles">
                    <li *ngIf="list.category == 'User Uploaded File'">
                        <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? fileSelect($event, list.fileName) : null" [checked]="exists(list.fileName)">{{ list.fileName }}<mat-icon class="download-icon" (click)="$event.preventDefault(); downloadAllFlies([list.fileName], true)">download</mat-icon></mat-checkbox>
                    </li>
                  </ng-container>
              </ul>
              <p *ngIf="(certificationFiles == null) || (certificationFiles == undefined) || !(certificationFiles.length > 0)">No files available</p>
          </div>
          <div class="files-list">
              <h3>{{certificationEnum.certification_download_FieldLabel_report}}</h3>
              <ul>
                <ng-container *ngFor="let list of certificationFiles">
                  <li *ngIf="list.category == 'Report'">
                    <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="$event ? fileSelect($event, list.fileName) : null" [checked]="exists(list.fileName)">{{ list.fileName }}<mat-icon class="download-icon" (click)="$event.preventDefault(); downloadAllFlies([list.fileName], true)">download</mat-icon></mat-checkbox>
                  </li>
                </ng-container>
            </ul>
          </div>
      </div>
    </div>
    <div class="download-files-footer">
      <button mat-stroked-button class="idn-button-secondary" color="primary" [disabled]="selectedFilesList.length == 0" (click)="downloadAllFlies(certificationFiles, false)">{{certificationEnum.certification_download_FieldLabel_downloadFiles}}</button>
    </div>
  </mat-drawer>
</mat-drawer-container>
