import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable({
  providedIn: 'root'
})

//To activate the page once user is logged in.
export class PodUpgradeGuardService {
  enablePodUpgradeFeature:any;
  constructor(
    private router: Router,
    private permissionService: PermissionsService,
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.enablePodUpgradeFeature = localStorage.getItem('enableMTPodUpgradeCalendarFeature');
    if (this.enablePodUpgradeFeature && this.permissionService.getPermission('UpgradeMultiTenantPOD')) {
      return true;
    }
    else{
      this.router.navigate(['home/access-denied']);
      return false;
    }
 
  }
  
}
