<app-pageheader class="pageheader" [pageTitle]="'SDK Mangement/Solution_Revised'" [editInstance]="(ViewData.statusId== instanceStatusEnum.draft || ViewData.statusId== instanceStatusEnum.created) && isEditInstance"
  [breadCrumbs]="breadCrumbItems">
</app-pageheader>



<div class="page-container">
  <app-idn-list-drawer *ngIf="listPartner" [data]="partnerData" (closeEvent)="closeListDrawer($event)"></app-idn-list-drawer>
  <div class="idn-view-wrapper">
    <div class="idn-card-wrapper">
      <mat-card class="idn-card">
        <mat-card-header class="card-header idn-view-header">
          <div class="data-left">
            <mat-card-title class="card-title idn-view-header-label">{{instanceEnum.instance_header_viewInstance}}</mat-card-title>
            <div class="idn-view-header-data">
              <div *ngIf="ViewData.statusId == instanceStatusEnum.created" class="cname-link"><a href="#" (contextmenu)="openSite(ViewData.cname)"
                (click)="openSite(ViewData.cname); $event.preventDefault();"><h3>{{ViewData.cname}}</h3></a> <mat-icon
                (click)="openSite(ViewData.cname)">open_in_new</mat-icon></div>
              <h3 *ngIf="ViewData.statusId != instanceStatusEnum.created">{{ViewData.cname}}</h3>
              <em><b>Created on: {{ViewData.createdAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}} by
                  {{ViewData.requesterdDetail}}
                </b></em>
            </div>
          </div>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="idn-view-body">
            <div class="card-content">
                <div class="pss-column-panel">
                  <div class="idn-view-body-items" *ngIf="partnerAccess">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_partnerName}}</div>
                    <div class="idn-view-body-items-data"><div class="overflow-data" matTooltip="{{ViewData.partners?.length? ViewData.partners[0]?.partnerName: ''}}">{{ViewData.partners?.length? ViewData.partners[0]?.partnerName: 'NA'}}</div>
                      <a *ngIf="ViewData.partners?.length > 0"  class="idn-grid-rec-count" aria-label="cname link"
                        href="javascript:void (0)" (click)="closeListDrawer(ViewData)">{{0>ViewData.partners.length - 1
                        ? '' : ViewData.partners.length-1 >0 ? '+'+(ViewData.partners.length-1) : ''}}</a>
                    </div>
                  </div>
                            
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_customerName}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.customerName}}</div>
                  </div>

                  <div class="idn-view-body-items"  *ngIf="!partnerAccess">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_user}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.instanceDefaultUserName}}</div>
                  </div>
                   
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_approverNote}}</div>
                    <div class="idn-view-body-items-data approver-comments overflow-data" matTooltip="{{ViewData.cloudOpsApprovalComment}}" [matTooltipDisabled]="isDisabled(commentsToolTip)" #commentsToolTip>{{ViewData.cloudOpsApprovalComment}}</div>
                  </div>
                </div>

                <div class="pss-column-panel">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_actions}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.iciVersion}}</div>
                  </div> 

                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_Create_Field_requiredByDate}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.requiredBy | date:
                      'dd MMMM, YYYY'}}</div>
                  </div>

                  <div class="idn-view-body-items" *ngIf="ViewData?.statusId == instanceStatusEnum.created">
                    <div class="idn-view-body-items-label">Upcoming Major Upgrade</div>
                    <div class="idn-view-body-items-data upgrade-dates">
                      <span class="view-user-info">{{filteredSnapshotData[0]?.nextMajorUpgradeVersion? filteredSnapshotData[0]?.nextMajorUpgradeVersion : 'NA'}}</span>
                      <span class="view-timestamp" *ngIf="filteredSnapshotData[0]?.nextMajorUpgradeVersion && filteredSnapshotData[0]?.nextMajorUpgradeDate">on {{filteredSnapshotData[0]?.nextMajorUpgradeDate | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
                    </div>
                  </div>
                </div>

                <div class="pss-column-panel">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_Create_Field_typeof_instance}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.typeOfInstanceName}}</div>
                  </div>

                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_status}}</div>
                    <div class="icon-style">
                      <mat-icon *ngIf="ViewData.statusId === 1" class="status colorDraft">bookmark</mat-icon>
                      <mat-icon *ngIf="ViewData.statusId === 2" class="status colorSuccess">fact_check</mat-icon>
                      <mat-icon *ngIf="ViewData.statusId === 3" class="status colorSuccess">check_circle</mat-icon>
                      <mat-icon *ngIf="ViewData.statusId === 4" class="status colorWarning">auto_mode</mat-icon>
                      <mat-icon *ngIf="ViewData.statusId === 5" class="status colorWarning">warning</mat-icon>
                      <mat-icon *ngIf="ViewData.statusId === 6" class="status colorError">dangerous</mat-icon>
                      <mat-icon *ngIf="ViewData.statusId === 7" class="status colorSuccess">beenhere</mat-icon>
                      <span class="pckgstatus">{{ViewData.status}}</span>
                    </div>
                  </div>

                  <div class="idn-view-body-items" *ngIf="ViewData?.statusId == instanceStatusEnum.created">
                    <div class="idn-view-body-items-label">Upcoming MP Upgrade</div>
                    <div class="idn-view-body-items-data upgrade-dates">
                      <span class="view-user-info">{{filteredSnapshotData[0]?.nextMPUpgradeVersion? filteredSnapshotData[0]?.nextMPUpgradeVersion : 'NA'}}</span>
                      <span class="view-timestamp" *ngIf="filteredSnapshotData[0]?.nextMPUpgradeVersion && filteredSnapshotData[0]?.nextMPUpgradeDate">on {{filteredSnapshotData[0]?.nextMPUpgradeDate | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
                    </div>
                  </div>
                  
                </div>

                <div class="pss-column-panel">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_Create_Field_environment}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.instanceType}}</div>
                  </div> 
                  
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_instanceVersion}}</div>
                    <div class="idn-view-body-items-data">{{instanceVersion}}</div>
                </div>
                </div>
                <div class="pss-column-panel">

                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_region}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.deploymentRegion}}</div>
                  </div>
          
                  <div class="idn-view-body-items" *ngIf="partnerAccess">
                    <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_user}}</div>
                    <div class="idn-view-body-items-data">{{ViewData.instanceDefaultUserName}}</div>
                  </div>
                  
                  <div>
                    <button mat-raised-button color="primary" class="instance-user-btn" (click)="navigateProvisionPage(ViewData.id)" *ngIf="ViewData.statusId === instanceStatusEnum.created && ViewData.instanceType != instanceEnum.instance_constant_production" [disabled]="ViewData.isInstancePointingToCustomerAD">
                      <div class="inst-usr-div" >
                        <span>{{instanceEnum.instance_card_header_non_idn_user_instance_users}}</span>
                        <mat-icon>arrow_forward</mat-icon>
                      </div>
                    </button>
                    <div class="idn-info-provision" *ngIf="ViewData.isInstancePointingToCustomerAD">
                      <mat-icon>info</mat-icon><span>{{instanceEnum.instance_View_Field_user_provision_info}}</span>
                    </div>
                  </div>
                  
                </div>                
            </div>
        </mat-card-content>
    </mat-card>
    </div>
    <mat-accordion class="idn-panel">
      <mat-expansion-panel *ngIf="!disableInstanceHealthFeature && ViewData.statusId == instanceStatusEnum.created" (opened)="getInstanceHealth(ViewData?.cname)" [expanded]="instanceHealthPanelExpanded">
        <mat-expansion-panel-header class="header">
            <mat-panel-title>
              Instance Health Summary <div class="pss-chips-status" *ngIf="instanceHealthError" matTooltip="{{instanceEnum.instance_health_error_tooltip_message}}" matTooltipClass="idn-tooltip" matTooltipPosition="above"><span class="marker marker__failed" ></span></div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <app-idn-table [data]="tableInstanceHealth"  *ngIf="this.instanceHealthPanelExpanded"></app-idn-table>
    </mat-expansion-panel>
      <mat-expansion-panel *ngIf="!disableFeature">
          <mat-expansion-panel-header class="header">
              <mat-panel-title>
                  Deployed Package Summary
              </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="table-scroll table-align" tabindex="0">
            <table mat-table [dataSource]="solutionPackagePackageDetails" style="width: 100%">                    
              <ng-container matColumnDef="extensionType">
                <th mat-header-cell *matHeaderCellDef>
                  Extension Type
                </th>
                <td mat-cell *matCellDef="let element" > {{element.extensionType}} </td>
              </ng-container>
        
              <ng-container matColumnDef="solutionPackageName">
                <th mat-header-cell *matHeaderCellDef id="packageColumn">
                  Solution PackageName
                </th>
                <td mat-cell *matCellDef="let element" >
                  <div class="package-details">
                    <a href="#" (contextmenu)="navigate(element)" matTooltip="{{element.solutionPackageName}}" matTooltipClass="idn-tooltip" matTooltipPosition="above"
                            (click)="navigate(element); $event.preventDefault();" *ngIf="isViewSolutionPackage && element?.hasExtensionAccess">{{element.solutionPackageName}}</a>
                    <span *ngIf="!isViewSolutionPackage || !element?.hasExtensionAccess" matTooltip="{{element.solutionPackageName}}" matTooltipClass="idn-tooltip" matTooltipPosition="above">{{element.solutionPackageName}}</span>
                  </div>
                </td>
              </ng-container>
        
              <ng-container matColumnDef="deployedBy">
                <th mat-header-cell *matHeaderCellDef>
                  Deployment Details
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="idn-cell-date">{{ element.deployedBy }}</div>
                  <div class="deployed-date"> on {{ element.deploymentDate | date:"dd MMMM, yyyy " }}{{ element.deploymentDate |
                    date:"'at' HH:mm '(UTC)'" }}</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                  Certification Status
                </th>
                <td mat-cell *matCellDef="let element">{{element.status}}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
            </table>
            
            <ng-container *ngIf="!solutionPackagePackageDetails.length;">
              <div class="idn-no-result">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
                  <defs>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
                    </clipPath>
                  </defs>
                  <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
                    <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
                    <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
                    <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
                    <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
                    <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
                    <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
                      <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
                      <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
                    </g>
                    <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
                      <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
                    </g>
                  </g>
                </svg>
                <h4>{{instanceEnum.instance_View_no_data_found}}</h4>
              </div>
            </ng-container>
          </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="partnerAccess">
        <mat-expansion-panel-header class="header">
            <mat-panel-title>
              {{instanceEnum.instance_approval_details}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="idn-view-body pss-dashboard-form">
          <div class="pss-left-panel">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_view_Subscription}}</div>
              <div class="idn-view-body-items-data">{{ViewData.subscription}}</div>
            </div>
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_approvedBy}}</div>
              <div class="idn-view-body-items-data">{{ViewData.instanceApproverDetail}}</div>
            </div>            
          </div>

          <div class="pss-right-panel">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_abbr}}</div>
              <div class="idn-view-body-items-data">{{ViewData.cnamewrk}}</div>
            </div>
           <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_podcode}}</div>
              <div class="idn-view-body-items-data">{{ViewData.podCode}}</div>
            </div>
            
    
          </div>
          <div class="pss-right-panel">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_pod}}</div>
              <div class="idn-view-body-items-data">{{ViewData.masterTenantCname}}</div>
            </div>
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_type}}</div>
              <div class="idn-view-body-items-data">{{ViewData.infraDBInstanceTypeName}}</div>
            </div>
          </div>

          <div class="pss-right-panel">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_View_Field_instancetag}}</div>
              <div class="idn-view-body-items-data">{{ViewData.instanceTagName}}</div>
            </div>
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_approve_field_billing_type}}</div>
              <div class="idn-view-body-items-data">{{ViewData.billingTypeName}}</div>
            </div>
          </div>

          <div class="pss-right-panel">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{instanceEnum.instance_view_field_expiration_date}}</div>
              <div class="idn-view-body-items-data">{{ViewData.expirationDate | date:
                'dd MMMM, YYYY'}}</div>
            </div>
          </div>
      </div>
    </mat-expansion-panel>
    </mat-accordion>
    
      <div *ngIf="partnerAccess">
      <div class="idn-view-body pss-dashboard-form instance-approval-details">
        <div class="pss-left-panel">        
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{instanceEnum.instance_View_instanceDocuments}}</div>
            <div class="idn-view-body-items-data">
              <div class="idn-view-mt-1">
                <div class="idn-norecord-comp" *ngIf="ViewData.files == null || ViewData.files == undefined">
                  <mat-icon svgIcon="no_document_icon"></mat-icon>
                  <div>
                    <h4>No Reference Documents</h4>
                    <p>we could not find any relevant document uploaded</p>
                  </div>
                </div>
                <mat-chip-list class="idn-chips-download" aria-label="File Download" *ngFor="let file of ViewData.files">
                  <mat-chip (click)="downloadSingleFile(ViewData.id,file)">{{file}}
                    <mat-icon>download</mat-icon>
                  </mat-chip>
                </mat-chip-list>
              </div>
  
            </div>
          </div>  
        </div>

        <div class="pss-right-panel">
          <div class="idn-view-body-items" *ngIf="ViewData.statusId === 5 || ViewData.statusId === 7">
            <div class="idn-view-body-items-label">{{instanceEnum.instance_List_Action_Label_downloadLogs}}</div>
            <div class="idn-view-body-items-data">
              <div class="mt-1">
                <div class="rows">
                  <mat-chip-list class="idn-chips-download" aria-label="file selection">
                    <mat-chip (click)="downloadProvisioningLog(ViewData.id, ViewData.type)">
                      {{'Download Instance Provisioning Log'}}
                      <mat-icon>download</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<div class="pss-form-button-wrapper">
  <!-- code for ICI Tools  -->
  <button mat-stroked-button color="primary" class="idn-edit-btn" *ngIf="ViewData.statusId== instanceStatusEnum.created && (isLoggingUtilities || isWriteUtilities ||isViewICIToolLogActionsProd || isViewICIToolWriteActionsProd || hasApprovalAccessToDebuggingUtilitiesProd)" (click)="onICITools()">
    <mat-icon class="mr-1"> settings</mat-icon>
    <span>{{instanceEnum.instance_view_ICITools}}</span>
  </button>
  <button mat-raised-button color="primary" *ngIf="ViewData.statusId== instanceStatusEnum.draft && isSubmitInstance"
    (click)="requestForApproval(ViewData.id,ViewData.requiredBy)">{{instanceEnum.instance_View_btn_submitForApproval}}</button>

  <button mat-raised-button color="primary" *ngIf="ViewData.files!=null && partnerAccess"
    (click)="downloadAllfiles()">{{instanceEnum.instance_View_downloadAllFiles}}</button>
  <button mat-stroked-button color="primary" *ngIf="isInitiateDeployment && ViewData.statusId == instanceStatusEnum.created" class="idn-edit-btn"
    (click)="navigateDeploymentPage(ViewData.customerId)">
    <div class="idn-button-icon">
      <mat-icon class="mr-1">account_tree</mat-icon>
      <span>{{instanceEnum.instance_View_initiatePackageDeployment}}</span>
    </div>
  </button>
  <button mat-raised-button color="primary" (click)="approveOrReject(ViewData.id)"
    *ngIf="ViewData.statusId==2 && isApproveInstance">{{instanceEnum.instance_approve_reject_btn}}</button>
    <button mat-stroked-button color="primary"  *ngIf="ViewData.isInstanceCreatedInHouse == 'Yes' && !(ViewData.statusId == instanceStatusEnum.created)"
      (click)="toggleTrackStatus(ViewData.statusId,ViewData.instanceStatusChanges)" >
      <mat-icon>touch_app</mat-icon>
      <span>{{instanceEnum.instance_view_trackStatus}}</span>
    </button>
  <button [matMenuTriggerFor]="gridMenu" mat-stroked-button aria-label="More Actions" color="primary" class="idn-edit-btn" *ngIf="(ViewData.statusId == instanceStatusEnum.created) && ViewData.instanceType != instanceEnum.instance_constant_production && ViewData.isInstanceCreatedInHouse == 'Yes'"
    matTooltip="More Actions" >
    <div class="idn-button-icon">
      <mat-icon class="mr-1">add</mat-icon>
      <span>{{instanceEnum.instance_more_action_btn}}</span>
    </div>
  </button>
  <mat-menu #gridMenu="matMenu" xPosition="before" >
    <button mat-menu-item class="menu-item" *ngIf="ViewData.isInstanceCreatedInHouse == 'Yes'"
      (click)="toggleTrackStatus(ViewData.statusId,ViewData.instanceStatusChanges)" >
      <mat-icon>touch_app</mat-icon>
      <span>{{instanceEnum.instance_view_trackStatus}}</span>
    </button>    
  </mat-menu>
  
  <button mat-stroked-button (click)="Oncancel()">{{instanceEnum.instance_View_cancel_btn}}</button>

  
</div>

<mat-drawer-container class="idn-drawer-internal" *ngIf="isTrackStatusDrawer">
  <mat-drawer #drawerInfo class="idn-drawer">
    <div class="header">
      <div class="heading">
        <mat-icon>touch_app</mat-icon>
        <h2>{{instanceEnum.instance_View_trackInstanceStatus}}</h2>
      </div>
      <button mat-icon-button (click)="closeTrackStatus()" aria-label="close Button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="body pt-1">
      <div class="page-container">
        <div class="idn-view-wrapper">
          <div class="idn-view-body">
            <div class="idn-info">
              <mat-icon>info</mat-icon>
              <h4>
                {{instanceEnum.instance_View_CustomDrawer_screenHelpMessage}}
              </h4>
            </div>
            <div class="idn-track-status">
              <div class="steps" id="1">
                <span class={{StatusClasses[0]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>Instance Request Created</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 1">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" id="2">
                <span class={{StatusClasses[1]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_VIew_instanceRequested}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 2">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID!=6" id="3">
                <span class={{StatusClasses[2]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_View_instanceCreationScheduled}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 3">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID!=6" id="4">
                <span class={{StatusClasses[3]}}>
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_View_instanceCreationInProgress}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 4">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID==5 && currentInstanceStatusID!=6" id="5">
                <span class="rejected">
                  <mat-icon>dangerous</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_View_instanceCreationFailed}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 5">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" *ngIf="currentInstanceStatusID==6" id="6">
                <span class="rejected">
                  <mat-icon>dangerous</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_View_instanceCreationRejected}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 6">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="steps" class={{StatusClasses[6]}}
                *ngIf="currentInstanceStatusID!=5 && currentInstanceStatusID!=6" id="7">
                <span class="currentInstanceStatusID == '4'? progress: {{StatusClasses[6]}}">
                  <mat-icon>check_circle</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{instanceEnum.instance_View_instanceCreated}}</h4>
                  <div *ngFor="let tractStatus of instanceStatusChanges">
                    <p *ngIf="tractStatus.statusId == 7">
                      on {{tractStatus.timestamp | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pss-drawer-button-wrapper">
      <button mat-stroked-button (click)="closeTrackStatus()">Close</button>
    </div>
  </mat-drawer>
</mat-drawer-container>