import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

const EVENT_TASK = 1;
const SCHEDULE_TASK = 2;
@Component({
  selector: 'app-list-ici-workertask',
  templateUrl: './list-ici-workertask.component.html',
  styleUrls: ['./list-ici-workertask.component.scss']
})
export class ListIciWorkertaskComponent implements OnInit {
  breadCrumbItems: any;
  noContent: boolean = true;
  displayedColumns: string[] = [
    'taskId',
    'partnerName',
    'isRunning',
    'category',
    'taskName',
    'requestedBy',
    'isEnabled',
    'action',
  ];

  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  currentPage = 0;
  pageLength: any = 0;
  pageNumber: any = 1;
  pageSize: any = 5;
  sortBy: string = '';
  ViewData: any=[];
  ViewInstanceData: any=[];
  public instanceEnum = InstanceManagement;
  userCategory: any;
  isCustomerUser: any;
  instanceId: any;
  taskTypes:any= [{id:0, name:'Event Task'}, {id:1, name:'Scheduled Task'}];
  Status:any= [{id:0, name:'Active', value:true}, {id:1, name:'In-Active', value:false}];
  taskType:any='';
  status:any='';
  selectAll = "Select All";
  initialData: any = [];

  constructor(
    private titleService: Title,
    private router: Router,
    private instanceService: InstanceService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('ICI WorkerTask Configuration | IDN');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      {label: 'Instance Operations', path: 'instanceoperations/instance-requests'},
      {label: 'Instance Requests', path: 'instanceoperations/instance-requests'},
      { label: 'Instance Tools',path: 'instanceoperations/instance-requests/instance-tools-landing'},
      {label: 'ICI Worker Tasks', path: 'instanceoperations/instance-requests/ici-workertask-configuration/list'},
      { label: 'List', path: 'instanceoperations/instance-requests/ici-workertask-configuration/list' },
    ];
    
    this.userCategory=this.permissionService.getUsercategoryId();
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    if(this.userCategory ==  USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
      this.displayedColumns = [
        'taskId',
        'isRunning',
        'category',
        'taskName',
        'requestedBy',
        'isEnabled',
        'action',
      ];
    }
    else{
      this.isCustomerUser = false;
    }
    
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
  }

  getWorkerTaskConfigurationListData() {
    try {
      this.instanceService.getAllWorkerTask(this.ViewInstanceData?.cname)
      .subscribe((data:any)=>{
        if (data.body != undefined && data.body != null && data.body.records?.length) {
          this.ViewData = data.body.records;
          this.initialData = JSON.parse(JSON.stringify(this.ViewData));;
          this.noContent = false;
        } else {
          this.noContent = true;
          this.ViewData = [];
          this.initialData = this.ViewData;
        }
        let filterValue: any = localStorage.getItem("FilterRecordWorkerTaskList");
        if (filterValue != undefined && filterValue != null && filterValue != '') {
          this.taskType = JSON.parse(filterValue).task;
          this.status = JSON.parse(filterValue).status;
          this.filterData();
        } else {
          this.dataSource = new MatTableDataSource<any>(this.ViewData); 
          this.paginator? this.paginator.length = data.body?.matchingCount:'';
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.paginator.pageIndex = this.currentPage;
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  navigateItem(navLink: any, workerTaskId: any): void {
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/instanceoperations/instance-requests/' + navLink;
    }
    let Json = {
      task: this.taskType != this.selectAll? this.taskType : '',
      status :  this.status != this.selectAll ?  this.status : ''
    };
    localStorage.setItem("FilterRecordWorkerTaskList", JSON.stringify(Json));
    localStorage.setItem('workerTaskId', workerTaskId);
    this.router.navigate([navLink]);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(column: any) {
    let sortedData = [];
    let initialData = JSON.parse(JSON.stringify(this.ViewData));
    if(column.active == 'taskName' || column.active == 'partnerName'){
      let columnName = column.active;
      if(column.direction == 'asc') {
        sortedData = initialData?.sort((a: any, b: any)=>{ return a[columnName]?.toLowerCase().localeCompare(b[columnName]?.toLowerCase()) });
      }else if(column.direction == 'desc'){
        sortedData = initialData?.sort((a: any, b: any)=>{ return b[columnName]?.toLowerCase().localeCompare(a[columnName]?.toLowerCase()) });
      } else {
        sortedData = this.ViewData;
      }
      this.dataSource = new MatTableDataSource<any>(sortedData);
    } else {
      this.dataSource = new MatTableDataSource<any>(this.ViewData);
      this.dataSource.sort = this.sort;
    }
    
    this.paginator? this.paginator.length = this.dataSource?.length :'';
    this.paginator.pageIndex = this.currentPage;
    this.pageNumber = this.currentPage + 1;
    this.dataSource.paginator = this.paginator;
  }

  //deactivate the workertask entry
  deactivateEntry(id: any) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.workertask_redirect_dialog_heading,
        module: 'partner',
        message: this.instanceEnum.workertask_redirect_dialog_body,
        confirmText: this.instanceEnum.workertask_redirect_dialog_primary_btn,
        cancelText: this.instanceEnum.workertask_redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if(res){
          try {
            this.instanceService
              .ActivateDeactivateWorkerTask(id, this.ViewInstanceData.cname, false)
              .subscribe((resp: any) => {
                if(resp.isSuccess){
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_disable_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_disable_heading
                  );
                  this.getWorkerTaskConfigurationListData();
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_disable_failed_body,
                    'warning',
                    this.instanceEnum.workertask_notify_task_disable_failed_heading
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  //activate the workertask entry
  activateEntry(id: any) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.workertask_redirect_dialog_heading_enable,
        module: 'partner',
        message: this.instanceEnum.workertask_redirect_dialog_body_enable,
        confirmText: this.instanceEnum.workertask_redirect_dialog_primary_btn,
        cancelText: this.instanceEnum.workertask_redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if(res){
          try {
            this.instanceService
              .ActivateDeactivateWorkerTask(id, this.ViewInstanceData.cname, true)
              .subscribe((resp: any) => {
                if(resp.isSuccess){
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_enable_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_enable_heading
                  );
                  this.getWorkerTaskConfigurationListData();
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_enable_failed_body,
                    'warning',
                    this.instanceEnum.workertask_notify_task_enable_failed_heading
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewInstanceData = result;
            this.getWorkerTaskConfigurationListData();
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  handleCreateWorkerTaskEvent(data:any){
    let id = (data == 'Event'? EVENT_TASK : SCHEDULE_TASK);
    localStorage.setItem('workerTaskEvent', id.toString());
    let navLink = 'home/instanceoperations/instance-requests/ici-workertask-configuration';
    this.router.navigate([navLink]);
  }

  filterData(){
    let category: any = '';
    let statusFilter: any = this.status != this.selectAll? this.status : '';
    category = this.taskType === 0? 'EventBased' : this.taskType === 1? 'Scheduled' : '';
    this.ViewData = JSON.parse(JSON.stringify(this.initialData))
    this.ViewData = this.ViewData?.filter((data:any) => { 
      if(category != '' && statusFilter !== ''){
        return (data.category == category && data.isEnabled == statusFilter);
      } else if(category != ''){
        return data.category == category;
      } else if(statusFilter !== ''){
        return data.isEnabled == statusFilter;
      } else {
        return data
      }
    });
    this.currentPage = 0;
    this.ViewData.length? this.noContent = false : this.noContent = true;
    this.dataSource = new MatTableDataSource<any>(this.ViewData);
    this.paginator? this.paginator.length = this.dataSource?.length :'';
    this.paginator.pageIndex = this.currentPage;
    this.pageNumber = this.currentPage + 1;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  taskTypeChange(data:any){
    this.taskType = data?.value;
    this.filterData();
  }

  statusChange(data:any){
    this.status = data?.value;
    this.filterData();
  }

  clearFilter(){
    this.taskType = '';
    this.status = '';
    this.filterData();
    localStorage.removeItem("FilterRecordWorkerTaskList");
  }

  onClose(){
    this.status = '';
    this.taskType='';
    this.router.navigate(['/home/instanceoperations/instance-requests/instance-tools-landing']);
  }

  ngOnDestroy(): void {
    const url1: string = '/home/instanceoperations/instance-requests/ici-workertask-configuration/view';
    const url2: string = '/home/instanceoperations/instance-requests/ici-workertask-configuration/edit';
    if (window.location.pathname != url1 && window.location.pathname != url2) {
      localStorage.removeItem('FilterRecordWorkerTaskList');
    }
  }

}
