import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { catchError } from 'rxjs';
import { map } from 'rxjs/operators';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';
import * as JSZip from 'jszip';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';

const NO_DOCUMENT = `
    <svg xmlns="http://www.w3.org/2000/svg" width="34.536" height="37.53" viewBox="0 0 34.536 37.53">
    <g id="Group_465" data-name="Group 465" transform="translate(-45.48 -10.639)">
      <path id="Path_301" data-name="Path 301" d="M461.891,292.259H443.219a1.431,1.431,0,0,0-1.429,1.429v9.355h2.873a1.235,1.235,0,0,1,1.051.581l1,1.585.952,1.515.179.285,1.09,1.733.012.02H463.32V293.688A1.431,1.431,0,0,0,461.891,292.259Z" transform="translate(-392.926 -281.62)" fill="#ccc"/>
      <path id="Path_302" data-name="Path 302" d="M476.241,319.769H466.99a.925.925,0,1,0,0,1.849h9.251a.925.925,0,1,0,0-1.849Z" transform="translate(-414.677 -306.27)" fill="#fff"/>
      <path id="Path_303" data-name="Path 303" d="M481.367,363.449h-14.3a1.009,1.009,0,1,0,0,2.019h14.3a1.009,1.009,0,1,0,0-2.019Z" transform="translate(-414.673 -345.409)" fill="#fff"/>
      <path id="Path_304" data-name="Path 304" d="M490.425,398.01a1.009,1.009,0,0,0-.913-.581h-14.3l-.064,0a1.239,1.239,0,0,1,.456.43l1,1.585h12.91a1.008,1.008,0,0,0,.913-1.437Z" transform="translate(-422.817 -375.856)" fill="#fff"/>
      <path id="Path_305" data-name="Path 305" d="M511.237,432.282a1.009,1.009,0,0,0-1-.873H498.28l.179.285,1.089,1.733h10.689a1,1,0,0,0,.585-.188,1.008,1.008,0,0,0,.423-.821A.962.962,0,0,0,511.237,432.282Z" transform="translate(-443.543 -406.304)" fill="#fff"/>
      <path id="Path_306" data-name="Path 306" d="M524.191,310.408l-1.455-.569-7.256-2.832L506.8,303.62a1.432,1.432,0,0,0-1.852.813l-.3.779-.723,1.849-1.05,2.692-.788,2.019-.591,1.514L500.7,315.3l-.592,1.515-.017.044L500,317.1l1.09,1.733.012.02h20.18a1.239,1.239,0,0,1,.966.462l.085-.216,1.042-2.668L525,312.261A1.432,1.432,0,0,0,524.191,310.408Z" transform="translate(-445.084 -291.712)" fill="#6332a4"/>
      <path id="Path_307" data-name="Path 307" d="M571.215,339.971l-.778-.3-7.841-3.06a.925.925,0,1,0-.673,1.724l2.59,1.01,3.2,1.25,2.721,1.062.1.041a.925.925,0,1,0,.673-1.724Z" transform="translate(-500.043 -321.302)" fill="#fff"/>
      <path id="Path_308" data-name="Path 308" d="M559.8,382.74l-3.9-1.524-2.787-1.087-1.489-.581-3.878-1.514-1.26-.492a1.009,1.009,0,0,0-1.271.492c-.013.026-.025.053-.035.081a1.009,1.009,0,0,0,.573,1.307l.322.126,5.169,2.018,4.656,1.817,3.171,1.238a1.009,1.009,0,1,0,.734-1.881Z" transform="translate(-485.497 -357.974)" fill="#fff"/>
      <path id="Path_309" data-name="Path 309" d="M544.819,413.368l-.25-.1-2.45-.956-2.238-.873L536,409.926l-1.878-.733a1.009,1.009,0,1,0-.733,1.881l.94.367,4.688,1.83.482.188.05.02H545.1Z" transform="translate(-474.425 -386.335)" fill="#fff"/>
      <path id="Path_310" data-name="Path 310" d="M524.267,441.816l-.482-.188-2.016-.787a1.008,1.008,0,0,0-1.364.787.952.952,0,0,0-.011.188.118.118,0,0,0,0,.02h3.923Z" transform="translate(-463.357 -414.693)" fill="#fff"/>
      <path id="Path_311" data-name="Path 311" d="M428.833,399.906l-.482-.188h-7.544a.955.955,0,0,0-.011.188h-1.031l.012.02h9.106Zm0,0h-9.068l.012.02h9.106Zm0,0h-9.068l.012.02h9.106Zm.05.02-.05-.02h-9.068l.012.02Zm-18.194,20.818a1.243,1.243,0,0,1-1.241-1.241V395.449a1.243,1.243,0,0,1,1.241-1.242h1.935V394h-1.935a1.452,1.452,0,0,0-1.449,1.45V419.5a1.45,1.45,0,0,0,.849,1.318h18.316l-.05-.077Zm30.319-20.573a1.442,1.442,0,0,0-1.051-.453H419.893l-1.123-1.786-.027-.044-.952-1.515-1.067-1.7A1.453,1.453,0,0,0,415.5,394h-4.808a1.452,1.452,0,0,0-1.449,1.45V419.5a1.45,1.45,0,0,0,.849,1.318,1.429,1.429,0,0,0,.6.131h29.268a1.434,1.434,0,0,0,.6-.131,1.45,1.45,0,0,0,.849-1.318V401.168a1.442,1.442,0,0,0-.4-1ZM441.2,419.5a1.243,1.243,0,0,1-1.242,1.241H410.689a1.243,1.243,0,0,1-1.241-1.241V395.449a1.243,1.243,0,0,1,1.241-1.242H415.5a1.235,1.235,0,0,1,1.051.581l1,1.585.952,1.515.179.285,1.09,1.733.012.02h20.18a1.243,1.243,0,0,1,1.242,1.242Zm-12.366-19.6h-9.068l.013.02h9.106Zm0,0h-9.068l.013.02h9.106Zm0,0h-9.068l.013.02h9.106Zm0,0-.482-.188h-8.458l-1.123-1.786-.095.241,1.09,1.733.012.02h9.106Z" transform="translate(-363.76 -372.783)" fill="#3f3d56" opacity="0.4"/>
    </g>
    </svg>

    `;

@Component({
  selector: 'app-extension-details',
  templateUrl: './extension-details.component.html',
  styleUrls: ['./extension-details.component.scss']
})
export class ExtensionDetailsComponent implements OnInit {
  @Input() extDetailsData: any;
  @Output() toggleListDrawer: EventEmitter<any> = new EventEmitter()
  sas: any;
  public extensionEnum = ExtensionManagement;
  listPartner: boolean =false;
  partnerData:any={
    title: 'Partner',
    list: [],
  };

  constructor(
    private extensionservice: ExtensionService,
    private blobService: AzureBlobStorageService,
    private router: Router,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private notificationService: NotificationService,) {
    iconRegistry.addSvgIconLiteral('no_document_icon', sanitizer.bypassSecurityTrustHtml(NO_DOCUMENT));
  }

  ngOnInit(): void { }

  downloadSingleFile(id: any, fileName: any) {
    try {
      var FileSaver = require('file-saver');
      this.extensionservice
        .getBlobDownloadConnectionForExtension(id, fileName)
        .pipe(
          map((data: any) => {
            this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
              if (response._response != undefined && response._response.status == "200") {
                response.blobBody.then(
                  (onres: any) => {
                    FileSaver.saveAs(onres, fileName);
                    this.notificationService.showNotification(this.extensionEnum.extension_notify_extDowloaded_body, 'success', this.extensionEnum.extension_notify_extDowloaded_heading);
                  })
              } else {
                this.notificationService.showNotification(this.extensionEnum.extension_notify_extDowloadedFailed_body, 'error', this.extDetailsData.extension_notify_extDowloadedFailed_heading);
              }
            });
          }), catchError((err) => {
            console.log(err);
            return err;
          })
        ).subscribe();
    } catch (err) {
      console.log(err);
    }
  }

  onCancel(val: any) {
    let navLink: any = 'home/developmentandutilities/' + val;
    this.router.navigate([navLink]);
  }

  //Download zip files
  downloadAllFiles = async (el: any) => {
    try {
      const zip = new JSZip();
      var FileSaver = require('file-saver');
      let downloadedFiles: any = [];
      el.files.forEach((x: any) => {
        this.extensionservice
          //.getDownloadTokenForIdentifier(el.uniqueIdentifier)
          .getBlobDownloadConnectionForExtension(el.id, x)
          .pipe(
            map((data: any) => {
              this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
                if (response._response != undefined && response._response.status == "200") {
                  response.blobBody.then(
                    (onres: any) => {
                      downloadedFiles.push([x, onres]);
                      if (el.files.length === downloadedFiles.length) {
                        downloadedFiles.forEach((item: any) => {
                          zip.file(item[0], item[1]);
                        });
                        zip.generateAsync({ type: "blob" })
                          .then(function (content) {
                            FileSaver.saveAs(content, el.name);
                          });
                          this.notificationService.showNotification(this.extensionEnum.extension_notify_extDowloaded_body, 'success', this.extensionEnum.extension_notify_extDowloaded_heading);
                        }

                    })
                }
                else {
                  this.notificationService.showNotification(this.extensionEnum.extension_notify_extDowloadedFailed_body, 'error', this.extDetailsData.extension_notify_extDowloadedFailed_heading);
                }
              })
              // })
            }),
            catchError((err) => {
              console.log(err);
              return err;
            })
          ).subscribe()
      });
    }
    catch (error) {
      console.log(error)
    }
  };

  closeListDrawer(event:any){
    if(event == false){
      this.listPartner = event;
      this.toggleListDrawer.emit(false);
    } else{
      this.toggleListDrawer.emit(true);
      this.listPartner = true;
      this.partnerData = {
        title:"Partner",
        list: event.partners
      }
    }
  }
}
