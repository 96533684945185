<app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false" [createRole]="false" [mapRole]="false"
  [applyFilter]="false" [clearFilter]="false" [archive]="false"></app-pageheader>
  <div class="page-container">
    <div class="form-wrapper">
      <h2>{{PartnerMgmtEnum.partner_header_edit}}: <span class="idn-edit-record-label">{{this.EditData.name}}</span></h2>
      <div class="pss-dashboard-form">
        <div class="pss-left-panel">
          <form [formGroup]="frmEditPartner">
            <mat-form-field class="pss-block-form">
              <mat-label>{{PartnerMgmtEnum.partner_label_code}}</mat-label>
              <input type="text" matInput maxlength="30" placeholder="Partner Code" formControlName="partnerCode" required>
            </mat-form-field>

            <mat-form-field class="pss-block-form">
              <mat-label>{{PartnerMgmtEnum.partner_label_name}}</mat-label>
              <input type="text" matInput maxlength="100" placeholder="Partner Name" formControlName="name" [(ngModel)]="myInputValuename" (ngModelChange)="valueChangename($event)" required>
              <mat-error *ngIf="frmEditPartner.controls.name.invalid">{{errorDescriptionName}}</mat-error>
            </mat-form-field>

            <mat-form-field class="pss-block-form">
              <mat-label>{{PartnerMgmtEnum.partner_label_description}}</mat-label>
              <textarea matInput maxlength="500" placeholder="Description of Partner" formControlName="description" class="idn-min-textarea-height" [(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)"></textarea>
              <mat-error *ngIf="frmEditPartner.controls.description.invalid">{{errorDescriptionField}}</mat-error>
            </mat-form-field>

            <mat-form-field class="pss-block-form">
              <mat-label>{{PartnerMgmtEnum.partner_label_region}}</mat-label>
              <mat-select formControlName="regionId">
                <mat-option *ngFor="let region of deploymentRegions" [value]="region.id">
                  {{region.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="pss-block-form">
              <mat-label>{{PartnerMgmtEnum.partner_label_user_limit}}</mat-label>
              <input type="text" matInput  placeholder="User Limit" formControlName="partnerUserQuota" required>
            </mat-form-field>

            <mat-form-field class="pss-block-form">
              <mat-label>Copilot Plan</mat-label>
              <mat-select formControlName="copilotPlan" (selectionChange)="selectedCopilotPlan()">
                <mat-option *ngFor="let plan of copilotPlan" [value]="plan.id">
                  {{plan.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="pss-block-form">
              <mat-label>{{PartnerMgmtEnum.partner_label_user_limit}}</mat-label>
              <input type="text" matInput maxlength="10"  placeholder="Copilot Prompt Quota" formControlName="copilotPromptQuota" required>
              <mat-error>Invalid number.</mat-error>
            </mat-form-field>

          </form>
        </div>
        <div class="pss-right-panel">
          <div class="idn-acc-wrapper">
            <mat-card class="idn-acc-list-card-1">
                <mat-card-header class="card-header">
                    <mat-card-title class="card-title">
                      {{PartnerMgmtEnum.partner_heading_partner_users}}
                    </mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                <mat-card-content class="acc-count-chips card-content">
                    <span class="count-label">{{PartnerMgmtEnum.partner_label_user_limit}}</span>
                    <span class="count">{{EditData?.usersCount}}/{{EditData?.partnerUserQuota}}</span>
                </mat-card-content>
            </mat-card>
      
            <mat-card class="idn-acc-list-card-2">
                <mat-card-content class="idn-view-body-description">
                    <mat-icon>info</mat-icon>
                    <div class="provision-desc">
                        <span>{{PartnerMgmtEnum.partner_userlimit_msg}}</span>
                    </div>                
                </mat-card-content>
            </mat-card>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pss-form-button-wrapper">
    <button mat-stroked-button class="pss-btn-danger" (click)="deactivatePartner()" *ngIf="!IsActive && dectivatePartner">
      <mat-icon>block</mat-icon> <span>{{PartnerMgmtEnum.partner_deactivate_btn}}</span>
    </button>
    <button mat-stroked-button class="pss-btn-success" (click)="activatePartner()" *ngIf="IsActive && activatePartnerPermission">
      <mat-icon>check</mat-icon> <span>{{PartnerMgmtEnum.partner_activate_btn}}</span>
    </button>
    <button mat-raised-button color="primary" [disabled]="IsActive || frmEditPartner.invalid" (click)="updatePartner()" >{{PartnerMgmtEnum.partner_edit_primary_btn}}</button>
    <button mat-stroked-button (click)="onCancel()">{{PartnerMgmtEnum.partner_cancel_btn}}</button>
  </div>
