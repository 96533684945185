import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MultiTenantPodsRoutingModule } from './multi-tenant-pods-routing.module';
import { MultiTenantpodListComponent } from './multi-tenantpod-list/multi-tenantpod-list.component';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { ViewMultiTenantPodComponent } from './view-multi-tenant-pod/view-multi-tenant-pod.component';
import { DateFormatPipe } from 'src/app/core/Pipes/certification/date-format.pipe';
import { PreviewUpgradeComponent } from './preview-upgrade/preview-upgrade.component';

@NgModule({
  declarations: [
    MultiTenantpodListComponent,
    ViewMultiTenantPodComponent,
    PreviewUpgradeComponent,
  ],
  imports: [
    CommonModule,
    MultiTenantPodsRoutingModule,
    MaterialModule,
    PageHeaderModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class MultiTenantPodsModule {}
