import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SdkServiceService {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  public _subject = new BehaviorSubject<any>('');
  public _editSDKsubject = new BehaviorSubject<any>('');
  public _refreshSDKsubject = new BehaviorSubject<any>('');
  constructor(
    private http: HttpClient
  ) { }

  public PostData(url: string, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, { headers: this.headers });
  }

  public GetSdk(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number,status:any,isFileUploaded:any) {
    return this.http.get(this.baseApiUrl + 'api/SDK/List', { params: { 'filter': filterSearchValue, 'sortBy': sortingColumn,'pageNumber': pageNumber, 'pageSize': pageSize,'status': status, 'isFileUploaded': isFileUploaded,} })
  }


  public UpdateSdkStatus(id: number, status: boolean): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SDK/UpdateSdkStatus/' + id + '/' + status, status, { observe: 'response', headers: this.headers })
  }

  public GetSDKById(id: number): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/SDK/' + id, { headers: this.headers })
  }
  public GetICVersionList(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/SDK/IciVersionList', { headers: this.headers })
  }

  public getBlobUploadConnection(id: any) {
    return this.http.get(this.baseApiUrl + 'api/SDK/GetBlobUploadConnection', { params: { 'id': id }, headers: this.headers })
  }

  public getBlobDownloadConnection(id: any, filename: any) {
    return this.http.get(this.baseApiUrl + 'api/SDK/GetBlobDownloadConnection', { params: { 'id': id, 'filename': filename }, headers: this.headers })
  }

  public EditSDK(json: any): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SDK/Update' + [], json, { observe: 'response', headers: this.headers })
  }
  emit<T>(data: T) {
    this._subject.next(data);
  }
  on<T>(): Observable<T> {
    return this._subject.asObservable();
  }
  emitSDK<T>(data: T) {
    this._subject.next(data);
  }
  onSDK<T>(): Observable<T> {
    return this._subject.asObservable();
  }
  emitRefreshSDK<T>(data: T) {
    this._refreshSDKsubject.next(data);
  }
  onRefreshSDK<T>(): Observable<T> {
    return this._refreshSDKsubject.asObservable();
  }

  public Activate(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SDK/Activate/' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
  }

  public Deactivate(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SDK/Deactivate/' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
  }

  public IsDownloaded(id: number): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/SDK/IsDownloaded/' + id, { observe: 'response', headers: this.headers })
  }

  public markDownloaded(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SDK/MarkDownloaded/' + id, { observe: 'response', headers: this.headers })
  }

  public checkForDuplicateSDK(sdkName: any, sdkVersion: any) {
    return this.http.get(this.baseApiUrl + 'api/SDK/CheckIfSDKNameExists', { params: { 'name': sdkName, 'version': sdkVersion }, observe: 'response' })
  }

  public FileUploadSuccessful(id: number) {
    return this.http.put(this.baseApiUrl + 'api/SDK/FileUploadSuccessful/' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
  }
}
