<app-pageheader [pageTitle]="'ICI Component deployment Requests'" [breadCrumbs]="this.breadCrumbItems" [auditLog]="true"
  [auditLogData]="'deploymentrequest'"></app-pageheader>
  <div class="page-container">
    <div class="form-wrapper">
      <h2>{{iciComponenetDeploymentEnum.componentDeployment_Create_Field_pageHeading}}</h2>
  
      <form class="pss-dashboard-form" [formGroup]="frmIciCompDeploy">
        <mat-form-field class="pss-block-form">
          <mat-label>{{iciComponenetDeploymentEnum.componentDeployment_Create_Field_cName}}</mat-label>
          <span matSuffix>{{cnameSuffix}}</span>
          <input type="text" matInput maxlength="100" placeholder="Enter Name of Cname" formControlName="cname"  required>
          <mat-error *ngIf="frmIciCompDeploy.controls.cname.invalid">{{iciComponenetDeploymentEnum.componentDeployment_create_hint_message_validCharacters}}</mat-error>
        </mat-form-field>
  
        <mat-form-field class="pss-block-form">
          <mat-label>{{iciComponenetDeploymentEnum.componentDeployment_Create_Field_iciVersion}}</mat-label>
          <mat-select formControlName="iciVersion" disableOptionCentering required (selectionChange)="getPublicSKUsbyICIVersion()">
            <mat-option *ngFor="let version of iciVersionList" [value]="version">
                {{version}}
            </mat-option>
        </mat-select>
          <mat-error *ngIf="frmIciCompDeploy.controls.iciVersion.invalid">{{iciComponenetDeploymentEnum.componentDeployment_create_hint_message_select_iciVersion}}</mat-error>
        </mat-form-field>

        <mat-form-field class="pss-block-form">
            <mat-label>{{iciComponenetDeploymentEnum.componentDeployment_Create_Field_sku}}</mat-label>
            <mat-select multiple formControlName="skuId" disableOptionCentering required>
                <mat-option *ngFor="let sku of skuList" [value]="sku.id">
                    {{sku.productName}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="frmIciCompDeploy.controls.skuId.invalid">{{iciComponenetDeploymentEnum.componentDeployment_create_hint_message_select_sku}}</mat-error>
        </mat-form-field>
  
        <mat-form-field class="pss-block-form">
          <mat-label>{{iciComponenetDeploymentEnum.componentDeployment_Create_Field_serviceNowId}}</mat-label>
          <input type="text" matInput maxlength="100" placeholder="Enter Service Now Ticket Id" formControlName="serviceNowId">
          <mat-error *ngIf="frmIciCompDeploy.controls.serviceNowId.invalid">{{iciComponenetDeploymentEnum.componentDeployment_create_hint_message_validCharacters_serviceNowId}}</mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="pss-form-button-wrapper">
    <button mat-raised-button color="primary" [disabled]="!frmIciCompDeploy.valid" (click)="initiateComponentDeployment()">Submit</button>
    <button mat-stroked-button (click)="Cancel()">Cancel</button>
  </div>
