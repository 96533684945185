<div class="radio-btns">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="DateType" (change)="onRadioChange($event.value)">
        <div class="fixed-date-style">
            <mat-radio-button value="Fixed Date">Fixed Date (UTC)</mat-radio-button>
            <app-fixed-date (outputData)="receiveInputValue($event)" [isFixedDateDisabled]="isFixedDateDisabled"
                [isCustomDateDisabled]="isCustomDateDisabled" [parentEvent]="eventFromParent" [moduleName]="moduleName"></app-fixed-date>
        </div>
        <div class="custom-date-style">
            <mat-radio-button value="Custom Date">Custom Date (UTC)</mat-radio-button>
            <app-custom-date (outputData)="receiveInputValue($event)" [isFixedDateDisabled]="isFixedDateDisabled"
                [isCustomDateDisabled]="isCustomDateDisabled" [parentEvent]="eventFromParent" [moduleName]="moduleName"></app-custom-date>
        </div>
</mat-radio-group>
</div>