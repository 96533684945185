import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IdnTableComponent } from './idn-table/idn-table.component';
import { CardComponent } from './card/card.component';
import { CertificationsModule } from 'src/app/modules/certifications-management/certifications.module';

@NgModule({
  declarations: [
    CardComponent,
    IdnTableComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    CertificationsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CardComponent,
    IdnTableComponent
  ]
})
export class SharedComponentsModule { }
