import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

export interface PeriodicElement {
  title: string;
  position: number;
  date: string;
  user: string;
  description: string;
  action: string;
  isImportant: boolean;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    position: 1,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: false
  }, {
    position: 2,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: false
  }, {
    position: 3,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 4,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 5,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 6,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 7,
    title: 'Role Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 8,
    title: 'SDK Created and Requested for approval',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 9,
    title: 'Portal Admin Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }, {
    position: 10,
    title: 'Customer Permissions Updated',
    date: 'Jan 1, 2022, 12:00:34 AM',
    user: 'John Doe, Partner Admin',
    description: `Role Permission updated, revoked Edit Permission to the Partner Admin, Role Permission updated, revoked Edit Permission to the Partner Admin. Lorem ipsum
    dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
    nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    action: 'true',
    isImportant: true
  }
];

@Component({
  selector: 'app-activity-notification',
  templateUrl: './activity-notification.component.html',
  styleUrls: ['./activity-notification.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ActivityNotificationComponent implements OnInit {

  isFilterDrawer: boolean = false;
  panelOpenState = false;
  dataSource = ELEMENT_DATA;
  columnsToDisplay = ['title', 'date', 'user', 'action'];
  expandedElement: PeriodicElement | null | undefined;

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
  ) { }

  ngOnInit(): void {
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Notification", path: "notification" }
  ];

  confirmArchive(){
    this.dialogService.confirmDialog({
      title: 'Confirm Activate',
      module: 'activity-notification',
      message: 'Are you sure you want to archive Last 3 Months Notifications?',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })
  }

  toggleFilterDrawer(){
    this.isFilterDrawer = !this.isFilterDrawer;
  }

}
