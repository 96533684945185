<app-pageheader [pageTitle]="'Role Management / View Role'" [auditLog]="false"  [editRole]="isRoleEdit" [createRole]="false"
  [mapRole]="false" [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
  <div class="idn-view-wrapper">
    <div class="idn-view-header">
      <div class="data-left">
        <div class="idn-view-header-label">{{pageHeading}} :</div>
        <div class="idn-view-header-data">
          <h3> {{ ViewData.name }} </h3>
          <em><b>Created on: {{ ViewData.createdAt | date: "dd MMM, yyyy 'at' HH:mm:ss '(UTC)'" }} by {{ ViewData.createdByDisplayName }}</b></em>
        </div>
      </div>
    </div>

    <div class="idn-view-body">
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{labelName}}</div>
        <div class="idn-view-body-items-data">{{ViewData.name}}</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{labelDescription}}</div>
        <div class="idn-view-body-items-data">{{ViewData.description}}</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{labelCategory}}</div>
        <div class="idn-view-body-items-data">{{ViewData.userCategoryName}}</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{labelStatus}}</div>
        <div class="idn-view-body-items-data">
          <span [ngClass]="ViewData.active ? 'active': 'in-active'">{{ViewData.active ? 'Active' : 'In-Active'}}</span>
        </div>
      </div>

    </div>
  </div>

</div>
<div class="pss-form-button-wrapper">
  <button mat-stroked-button class="pss-btn-danger" (click)="DeactivateRole()" *ngIf="!IsActive && isRoleDeactivate">
    <mat-icon>block</mat-icon> <span>{{actionDeactivate}}</span>
  </button>
  <button mat-stroked-button class="pss-btn-success" (click)="ActivateRole()" *ngIf="IsActive && isRoleActivate">
    <mat-icon>check</mat-icon> <span>{{actionActivate}}</span>
  </button>
  <button mat-stroked-button (click)="Cancel()">{{actionCancel}}</button>
</div>
