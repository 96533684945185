import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class AESEncryptDecryptService {
  secretKey = "jkdlgfdjkgfeuiwareuikljkdgbfkjdsghfakdsfbgds";
  keysize = 128/8;

  constructor() { }

  encrypt(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.secretKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secretKey);
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()), _key, {
        keySize: this.keysize,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );
      return encrypted.toString();
  }

  decrypt(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.secretKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secretKey);
    let decrypted = CryptoJS.AES.decrypt(value, _key, {
        keySize: this.keysize,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    let decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  }

  decryptString(value: any) {
    let _key = CryptoJS.enc.Utf8.parse(this.secretKey);
    let _iv = CryptoJS.enc.Utf8.parse(this.secretKey);
    let decrypted = CryptoJS.AES.decrypt(value, _key, {
        keySize: this.keysize,
        iv: _iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    );
    let decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }


}
