import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListIciWorkertaskConfigurationComponent } from './list-ici-workertask-configuration/list-ici-workertask-configuration.component';
import { ViewIciWorkertaskConfigurationComponent } from './view-ici-workertask-configuration/view-ici-workertask-configuration.component';
import { EditIciWorkertaskConfigurationComponent } from './edit-ici-workertask-configuration/edit-ici-workertask-configuration.component';

const routes: Routes = [
  {
    path: '',
    component: ListIciWorkertaskConfigurationComponent
  },
  {
    path:'edit',
    component: EditIciWorkertaskConfigurationComponent
  },
  // {
  //   path:'view',
  //   component: ViewIciWorkertaskConfigurationComponent
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IciWorkertaskConfigurationRoutingModule { }
