export enum RoleMgmtStaticString {
  /* HEADERS */
  role_header_create = "Create Role",
  role_header_view = "View Role",
  role_header_edit = "Edit Role",
  /* LABELS AND BUTTONS */
  role_label_name = "Role",
  role_label_description = "Description",
  role_label_category = "Icertis Role",
  role_category = "Role Category",
  role_label_status = "Status",
  role_create_primary_btn = "Save",
  role_cancel_btn = "Cancel",
  role_edit_primary_btn = "Update",
  role_permission_primary_btn = "Update",
  role_activate_btn = "Activate",
  role_deactivate_btn = "Deactivate",
  /* FIELD ERROR MESSAGES */
  role_error_roleName = "You can enter A-Z,a-z and no special characters",
  role_error_roleDescription = "You can enter A-Z,a-z and no special characters",
  /* DIALOG HEADING MESSAGE AND BUTTONS */
  role_dialog_activate_heading = "Confirm Activate",
  role_dialog_activate_msg = "Are you sure you want to activate the role?",
  role_dialog_activate_primary_btn = "Activate",
  role_dialog_deactivate_heading = "Confirm Deactivate",
  role_dialog_deactivate_msg = "Are you sure you want to deactivate the role? Other changes would be lost.",
  role_dialog_deactivate_primary_btn = "Deactivate",
  role_dialog_cancel_btn = "Cancel",
  /* NOTIFICATION MESSAGE AND HEADING */
  role_notify_activate_heading = "Role Activated",
  role_notify_not_activate_heading = "Role not Activated",
  role_notify_activate_body = "Role has been activated Successfully",
  role_notify_deactivate_heading = "Role De-Activated",
  role_notify_not_deactivate_heading = "Role not De-Activated",
  role_notify_not_deactivate_body = "The Role cannot be deactivated since it is already assigned to users",
  role_notify_deactivate_body = "Role has been De-activated Successfully",
  role_notify_not_create_heading = "Role Not Created",
  role_notify_not_create_body = "Please enter required field",
  role_notify_create_heading = "Role Created",
  role_notify_create_body = "New Role has been Created Successfully",
  role_notify_create_duplicate_heading = "Role already exists",
  role_notify_create_duplicate_body = "Role name already exists",
  role_notify_permission_success_heading = "Permissions Updated",
  role_notify_permission_success_body = "Permissions has been Updated Successfully",
  role_notify_updated_success_heading="Role Updated",
  role_notify_updated_success_body = "Role has been Updated Successfully",
  /* No Result Found */
  role_no_result_msg = "No Role Found!",
  role_superadmin="Super Admin",
  role_non_idn_user='Non-IDN User'
}
