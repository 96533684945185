<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [auditLog]="true" [auditLogData]="'ICIWorkerTaskConfiguration'"
  [breadCrumbs]="this.breadCrumbItems" [createWorkerTask]="true" (createWorkerTaskEvent)="handleCreateWorkerTaskEvent($event)"></app-pageheader>

  <div class="page-container">
    <div class="idn-acc-wrapper">
      <mat-card class="idn-acc-list-card">
          <mat-card-header class="card-header">
              <mat-card-title class="card-title">Instance Details</mat-card-title>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content class="idn-view-body">
              <div class="card-content">

                  <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">
                          CUSTOMER NAME
                      </div>
                      <div class="idn-view-body-items-data">{{ViewInstanceData.customerName}}</div>
                  </div>
                  <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">
                          CNAME</div>
                      <div class="idn-view-body-items-data">{{ViewInstanceData.cname}}</div>
                  </div>

                  <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">
                          INSTANCE TYPE</div>
                      <div class="idn-view-body-items-data">{{ViewInstanceData.instanceType}}</div>
                  </div>

                  <div class="idn-view-body-items">
                      <div class="idn-view-body-items-label">
                          ICI VERSION</div>
                      <div class="idn-view-body-items-data">{{ViewInstanceData?.iciVersion}}</div>
                  </div>
              </div>
          </mat-card-content>
      </mat-card>
  </div>
  <div class="heading-title">
    <span>{{instanceEnum.instance_view_ici_worker_tasks_header}}</span>
  </div>
  <div class="display-list">
    <mat-form-field class="select-input">
        <mat-label>Task Type</mat-label>
        <mat-select disableOptionCentering name="action" [(value)]="taskType"
            (selectionChange)="taskTypeChange($event)">
            <mat-option [value]="selectAll"> All</mat-option>
            <mat-option *ngFor="let task of taskTypes" [value]="task.id">
                {{task.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field class="select-input">
        <mat-label>Status</mat-label>
        <mat-select disableOptionCentering name="status" [(value)]="status"
            (selectionChange)="statusChange($event)">
            <mat-option [value]="selectAll"> All</mat-option>
            <mat-option *ngFor="let status of Status" [value]="status.value">
                {{status.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button class="clear-filter-tools" mat-stroked-button color="primary" (click)="clearFilter()">
        <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
        <span>Clear Filter</span>
    </button>
</div>
  <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position idn-grid-global" 
      #empTbSort="matSort" (matSortChange)="announceSortChange($event)" *ngIf="!noContent; else noContentTemplate">

      <!-- workerTaskId Column -->
      <ng-container matColumnDef="taskId">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by TaskId">
          {{instanceEnum.workertask_list_field_task_id}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"> <span matTooltip="{{element.taskId}}" matTooltipClass="idn-tooltip" >{{element.taskId}}</span></td>
      </ng-container>

       <!-- Partner Name -->
       <ng-container matColumnDef="partnerName" *ngIf="!isCustomerUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by partnerName">
          {{instanceEnum.workertask_list_field_partner_name ? instanceEnum.workertask_list_field_partner_name : 'NA' }}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.partnerName? element.partnerName : ''}}" matTooltipClass="idn-tooltip">{{element.partnerName? element.partnerName : 'NA' }}</span></td>
      </ng-container>

      <!-- Instance Name -->
      <ng-container matColumnDef="isRunning">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IsRunning">
          {{instanceEnum.workertask_list_field_running}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.isRunning? 'Yes': 'No'}}" matTooltipClass="idn-tooltip">{{element.isRunning? 'Yes': 'No'}}</span></td>
      </ng-container>

      <!-- Type of table name -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Task Type">
          {{instanceEnum.workertask_list_field_task_type}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.category=='EventBased'?'Event Task':'Scheduled Task'}}" matTooltipClass="idn-tooltip">{{element.category=='EventBased'?'Event Task':'Scheduled Task'}}</span></td>
      </ng-container>

      <!-- Entry Name -->
      <ng-container matColumnDef="taskName">
        <th mat-header-cell *matHeaderCellDef  id="mat-column-name" mat-sort-header sortActionDescription="Sort by Task Name">
          {{instanceEnum.workertask_list_field_task_name}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.taskName}}" matTooltipClass="idn-tooltip">{{element.taskName}}</span></td>
      </ng-container>

      <!-- Requestor Details -->
      <ng-container matColumnDef="requestedBy">
        <th mat-header-cell *matHeaderCellDef id="mat-column-req-details">
          {{instanceEnum.workertask_list_field_requestor_details}}
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-data">
          <div class="overflow-data" id="requestedBy-container">{{ element.requestedBy }}</div>
          <div class="overflow-data requested-date" id="requestedBy-container" *ngIf="element.requestedBy && element.requestedOn"> on {{ element.requestedOn | date:"dd MMMM, yyyy " }}{{ element.requestedOn | date:"'at' HH:mm '(UTC)'" }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="isEnabled">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">
          {{instanceEnum.workertask_list_field_status}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="pss-chips-status">
            <span [ngClass]="element.isEnabled? 'marker marker__active': 'marker marker__in-active'"></span>
            <span class="label" matTooltip="{{element.isEnabled? 'Active': 'In-Active'}}" matTooltipClass="idn-tooltip" class="label" tabindex="0" aria-label="Status">{{element.isEnabled ? 'Active': 'In-Active'}}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          Action
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="View worker task configuration" matTooltip="View worker task configuration"
            (click)="navigateItem('ici-workertask-configuration/view', element.taskId)">
            <mat-icon class="view-icon">remove_red_eye</mat-icon>
          </button>         
          <button [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="WorkerTask Actions"
            matTooltip="WorkerTask Actions">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #gridMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="navigateItem('ici-workertask-configuration/edit', element.taskId)" *ngIf="element.isEnabled">
              <mat-icon>mode</mat-icon> Edit
            </button> 
            <button mat-menu-item (click)="deactivateEntry(element.taskId)" *ngIf="element.isEnabled">
              <mat-icon>block</mat-icon> Deactivate
            </button> 
            <button mat-menu-item (click)="activateEntry(element.taskId)" *ngIf="!element.isEnabled">
              <mat-icon>check</mat-icon> Activate
            </button>         
          </mat-menu>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  
  <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="5" class="mat-paginator-sticky"
  (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
  aria-label="Select page of icicomponentdeploymentrequest elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
</mat-paginator>
  
  </div>

  <div class="pss-form-button-wrapper">
    <button mat-stroked-button (click)="onClose()">Close</button>
</div>
  <ng-template #noContentTemplate>
    <div class="idn-no-result">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
          </clipPath>
        </defs>
        <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
          <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
          <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
          <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
          <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
          <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
          <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
            <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
            <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
          </g>
          <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
            <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
          </g>
        </g>
      </svg>
      <h4>No Data found</h4>
    </div>
  </ng-template>