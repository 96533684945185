import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCase'
})
export class CamelCasePipe implements PipeTransform {

  transform(name: any): unknown {
let n :any;
     if(name == 'ICIVersion' || name =='ICI Version'){
      n = 'ICI Version';
     }
     else if(name == 'CName'){
      n = 'CName';
     }else{
      n=name.replace(/([A-Z])/g, ' $1').replace(/^./, (str:any) => str.toUpperCase())
     }
    return n;
  }

}
