<div class="idn-home-navigation" #stickyMenu [class.sticky] = "sticky">
  <div class="container">
    <nav class="pss-nav-primary">
      <div class="pss-brand idn-link-cursor" (click)="navigateToHome()">
        <img src="../../../../assets/images/logo/Icertis_logo.svg" alt="Icertis Logo">
      </div>
      <ul>
        <li class="active">
          <a href="javascript:void(0)"  (click)="navigateToHome()">{{ 'HOME.MENU.HOME' | translate }}</a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="navigateTo('company')">{{ 'HOME.MENU.ABOUT' | translate }}</a>
        </li>
        <li>
          <a href="javascript:void(0)" (click)="navigateTo('company/contact')">{{ 'HOME.MENU.CONTACT' | translate }}</a>
        </li>
        <li>
          <div class="home-btn-wrapper">
            <button class="pss-button-primary d-flex-center btn-danger" (click)="logout()" *ngIf="isLoggedIn">
              {{ 'HOME.MENU.LOGOUT' | translate }}
              <mat-icon>logout</mat-icon>
            </button>
            <button class="pss-button-primary d-flex-center" (click)="login()" *ngIf="!isLoggedIn">
              {{ 'HOME.MENU.LOGIN' | translate }}
              <mat-icon>arrow_right_alt</mat-icon>
            </button>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</div>
