import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

var url = environment.postLogoutRedirectUri;
var clean_url = url.substring(0, url.length - 1);
var TERMS_AND_CONDITIONS =
  `
  <p style="font-size: 110%; margin-bottom: 1.5rem;"><strong><em>Please scroll down and carefully read the Icertis Developer Network License Agreement ("Agreement").</em></strong></p>
  <p>By clicking "I Accept" or trying to download, install, or use the ICERTIS software and other materials made available to you by accessing the site located at <span style="font-weight: 600">${clean_url}</span> (the “Site”), you agree that this Agreement is a legally binding contract between you ("You" or "Your") and ICERTIS, and you agree to be bound by all of the terms and conditions in this Developer Agreement. </p>
  <p>If You are trying to access or download the ICERTIS Developer Network on behalf of Your employer or as a consultant or agent of a third party ("Your Company"), You represent and warrant that You have the authority to act on behalf of and bind Your Company to the terms of this Agreement, and anywhere in this Developer Agreement that says "You" or "Your" also means "Your Company." </p>
  <p>Icertis Developer Network (IDN) provides several types of resources, including one or more of the following: information, software, text, displays, images, video, audio recordings, and user interfaces included in or generated by the IDN, and the design, selection and arrangement thereof, except that provided expressly and conspicuously under a third party license (collectively, the "IDN Content"); product specifications and product documentation (collectively, "Documentation"); software such as application program interfaces, development tools, code snippets and sample code, published by ICERTIS to permit customization and configuration of Icertis offerings, ("Development Tools"); and ability to get instances of the Icertis Products (“Sandbox Instance”) (collectively, the Development Tools, IDN Content, Documentation and Sandbox Instances are referred to as “IDN Material”). </p>
  <p>Your right to use the IDN Material is subject to and conditioned on your acceptance of these Terms of Use. </p>
  <p>We may revise and update these Terms of Use from time to time at our sole discretion. </p>
  <p>Your continued use of the IDN Material following the posting of the revised Terms of Use means that you accept and agree to the changes.</p>
  <p>When accepted by you, the revised Terms of Use automatically revoke any license or other rights that we granted to you under the prior version. Personally identifiable information collected about you by Icertis Developer Network is treated following the <a style="border-bottom: 2px solid #00e4bc; text-decoration: none; color: #000 !important;" href="https://www.icertis.com/privacy-statement/" target="_new">https://www.icertis.com/privacy-statement/</a>, which is hereby incorporated into these Terms of Use by reference.  </p>
  <p>If you don't agree with these rules, don't click "I Accept" and don't try to access or use the ICERTIS Developer Network or IDN Material. </p>
  <ol start="1"style="padding-left: 0; margin-left: 0">
    <li style="list-style: none;">
    <h3 style="font-weight: 800">1. Access</h3>
    <p>We reserve the right, in our sole discretion, to terminate or modify Icertis Developer Network (IDN) or any of its content, including the IDN Material, in whole or in part, in any way and without notice. We are not liable if, for any reason, the IDN is unavailable at any time or for any length of time. Occasionally, we may restrict access to the IDN to registered users or another subset of users. You are solely responsible for securing access to the Site. </p></li>
   
   </li>
    </ol>
    <ol start="2"style="padding-left: 0; margin-left: 0">
    <li style="list-style: none;">
    <h3 style="font-weight: 800">2. Accounts</h3>
    <p>To access portions of the Site or particular resources, you may be required to provide registration information or other details. It is a condition of your use of the Site and IDN Material that the information you provide is accurate, current, and complete, which you represent and warrant herein. If you select or are assigned a user name, password, or any other piece of information as part of our security procedures, you must treat this information as confidential, and you must not disclose it to a third party. You acknowledge that you are solely liable for all activities associated with your account. You agree to notify Icertis immediately of any unauthorised use of your login credentials or any other breach of security. You are required to log out of your account after each session. You should exercise extreme caution when accessing your account from a public or shared computer so that others cannot view or record your password or other sensitive data. Icertis has the unrestricted right to disable any user name or password at any time and for any reason, including if, in our sole discretion, we determine that you have violated any provision of these Terms of Service. </p></li>
</li>
    </ol>
    <ol start="3"style="padding-left: 0; margin-left: 0">
    <li style="list-style: none;">
    <h3 style="font-weight: 800">3. Code of Conduct</h3>
    <p>You agree not to access or use the Site in any way that:  </p>
   <ul>
      <li style="list-style: disc outside none; margin-bottom: 1rem;">Violates any applicable federal, state, local, or international law or regulation, or advocates, promotes, or aids in any illegal act constitutes an illegal threat or violates export control laws. </li>
      <li style="list-style: disc outside none; margin-bottom: 1rem;">That violates the rights of any person or entity, which may result in civil or criminal liability under applicable laws or regulations applicable to you, another user, and/or Icertis, such as infringement of any copyright, patent, trademark, trade secret, or other intellectual property rights, or conflicts with these Terms of Use or the Privacy Policy. </li>
      <li style="list-style: disc outside none; margin-bottom: 1rem;">To send or introduce offensive materials, such as profanity, violence, sexual conduct, pornography, or discrimination based on race, gender, religion, nationality, disability, sexual orientation, or age. </li>
      <li style="list-style: disc outside none; margin-bottom: 1rem;">To monitor or attempt to gain unauthorised access to (a) an account or computer that does not belong to you; (b) any data, information, or communications on any network or system that you do not own; (c) any system or network user accounts or passwords of other users; or (d) e-mail addresses, screen names, or other identifiers without the consent of the person identified (including, without limitation, phishing, password robbery, spidering and harvesting). </li>
      <li style="list-style: disc outside none; margin-bottom: 1rem;">That is false, deceptive, misleading, or fraudulent, including but not limited to: (a) any attempt to impersonate any person or entity, including another user, Icertis, or an Icertis employee; (b) any attempt to misrepresent your identity or affiliation with any person or organization; and (c) any attempt to give the impression that you are posting materials from someone other than yourself if that is not the case, including but not limited to changing your IP source. </li>
      <li style="list-style: disc outside none; margin-bottom: 1rem;">That may impair the IDN's function, user interaction, or security, including but not limited to: (a)     gaining unauthorised access to, or attempting to compromise the security of, any network, system, computing facility, equipment, data, or information; (b) attempting to intercept, redirect, or otherwise interfere with communications intended for others; and (c) disabling, damaging, overburdening, or impairing the IDN or any server, computer, or database connection. </li>
    </ul></li>
  </ol>
  <ol start="4" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">4. License Grants and Use Rights</h3>
    <ol>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.1 General Grant of License. </h4>
      <p>The IDN Materials, and all Intellectual Property Rights embodied in the foregoing, shall be the sole and exclusive property of Icertis, subject to any rights expressly granted herein. Subject to and conditioned upon your compliance with the terms of this Agreement, Icertis hereby grants to you a limited, worldwide, royalty free, fully paid-up, non-exclusive, non-transferable right and license (without the right to grant sublicenses) under Icertis’s Intellectual Property Rights, for the term of this Agreement, to (a) use and reproduce the IDN Materials for the purpose of (a) training on the use, configuration, deployment of Icertis solutions, (b) configuration, deployment of Icertis solutions pursuant to an agreement between you and an Icertis customer (Note: any subcontracting of such work to you by Icertis will be governed by that separate subcontracting agreement), and (c) developing, testing and maintaining your offerings in connection with Icertis solutions. Icertis retains title to all individual copies of the IDN Materials.  </p>
      </li>

      <li style="list-style: none">
      <h4 style="font-weight: 800">4.2 Consent to Use Of Data</h4>
      <p>You agree that Icertis and its affiliates may collect and use technical information you provide as a part of support services related to the IDN Material.  </p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.3 Export Restrictions</h4>
      <p>You acknowledge that the IDN Material may be subject to U.S. export jurisdiction. You agree to comply with all applicable international and national laws that apply to the IDN Material, including the U.S. Export Administration Regulations, as well as end-user, end-use and destination restrictions issued by U.S. and other governments.</p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.4 Production Use</h4>
      <p>The IDN Material may only be used for development and deployment purposes as described in this Agreement and may not be used in a production environment.</p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.5 Prerelease Code</h4>
      <p>Portions of the IDN Material may be identified as prerelease code (“Prerelease Code”).  Such Prerelease Code is not at the level of performance and compatibility of the final, generally available product offering.  The Prerelease Code may not operate correctly and may be substantially modified prior to first commercial shipment.  Icertis is not obligated to make this or any later version of the Prerelease Code commercially available.  The grant of license to use Prerelease Code expires upon availability of a commercial release of the Prerelease Code from Icertis.</p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.6 Update License Terms</h4>
      <p>All UPDATES shall be considered part of the IDN Material and subject to the terms and conditions of this Agreement.  Additional license terms may accompany UPDATES (as defined in the first paragraph of this Agreement).  By installing, copying, or otherwise using any UPDATE, you agree to be bound by the terms accompanying each such UPDATE.  If you do not agree to the additional license terms accompanying such UPDATES, do not install, copy, or otherwise use such UPDATES.</p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.7 Testing</h4>
      <p>“Testing” as defined in this Agreement does NOT include staging in a production environment such as loading content on a staging server prior to production use.</p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">4.8 Performance or Benchmark Testing</h4>
      <p>You may not disclose the results of any benchmark test of either the Server Software or Client Software to any third party without Icertis’s prior written approval.</p>
      </li>

    </ol>
    </li>
  </ol>
  <ol start="5" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">5. Sandbox</h3>
    <ol>
      <li style="list-style: none">
      <h4 style="font-weight: 800">5.1 Access</h4>
      <p>Subject to the terms and conditions of this Agreement, Icertis may provide you with access to a sandbox instance of the Icertis solution.  If and to the extent provided, Icertis grants you a limited, non-exclusive, non-sublicensable, non-transferable, revocable right during the term of this Agreement to access and use the Icertis Sandbox solely for internal training and demonstration purposes in furtherance of the partnership between the parties, up to the number of users stated by Icertis.  You are solely responsible for all use of the Icertis Sandbox by authorized users, including any damage to the Icertis Sandbox caused by such users, and will ensure that authorized users comply with this Agreement.</p>
      </li>
      <li style="list-style: none">
      <h4 style="font-weight: 800">5.2 Restrictions</h4>
      <p>Except as otherwise explicitly provided in this Agreement, you will not, and will not permit any third party to: (a) rent, lease, or otherwise permit third parties to use the Icertis Sandbox; (b) use the Icertis Sandbox to provide services to third parties as a service bureau or in any way that violates applicable law; (c) circumvent or disable any security or other technological features or measures of the Icertis Sandbox; (d) send, store, or distribute any viruses, worms, Trojan horses, or other disabling code or malware component harmful to a network or system, in connection with use of or access to Icertis Sandbox. Except with prior written consent of Icertis, Partner will not copy, reproduce, modify, translate, enhance, decompile, disassemble, reverse engineer, or create derivative works of the Icertis Sandbox.  </p>
      </li>
    </ol>
  </li>
  </ol>
  <ol start="6" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">6. Any Software Developer Kit Software or Material</h3>
    <p>Icertis grants you the nonexclusive, royalty-free right to install and use copies of any SDK IDN Material components solely for the purpose of designing, developing, testing, and demonstrating your applications that work in conjunction with Icertis offerings.  You may modify such provided code to design, develop, test, and demonstrate your applications. </p>
  </li>
  </ol>
  <ol start="7" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">7. Distribution Requirements/Identified Software</h3>
    <ol>
      <li style="list-style: none">
      <h4 style="font-weight: 800">7.1 Distribution Requirements</h4>
      <p>If Icertis should provide you with software that is explicitly provided by Icertis to be distributed by you (for purposes of this Section 7.1, collectively the ”Redistributable Code “) with a software product developed by you as described in this Agreement (an “Application”), you must (a) not permit further redistribution of the Redistributable Code by your end-user customers; (b) not use Icertis ’s name, logo, or trademarks to market your Application; (c) include a valid copyright notice on your Application; (d) agree to indemnify, hold harmless, and defend Icertis from and against any claims or lawsuits, including attorney’s fees, that arise or result from the use or distribution of your Application; (e) include “Copyright Icertis Corporation” in all Redistributable Code used within your program(s); (f) otherwise comply with the terms of this Agreement; and (g) agree that Icertis reserves all rights not expressly granted. You may permit further redistribution of the Redistributable Code by your distributors to your end-user customers if your distributors only distribute the Redistributable Code in conjunction with, and as part of, your software product and you and your distributors comply with all other terms of this Agreement.</p>
      </li>
    </ol>
  </li>
  </ol>
  <ol start="8" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">8. DISCLAIMER OF WARRANTIES</h3>
    <p>To the maximum extent permitted by applicable law, Icertis and its suppliers provide the Product and support services (if any) AS IS AND WITH ALL FAULTS, and hereby disclaim all other warranties and conditions, either express, implied, or statutory, including, but not limited to, any (if any)implied warranties, duties or conditions of merchantability, of fitness for a particular purpose, of accuracy or completeness of responses, of results, of workmanlike effort, of lack of viruses, and of lack of negligence, all with regard to the Product, and the provision of or failure to provide support services. ALSO, THERE IS NO WARRANTY OR CONDITION OF TITLE, QUIET ENJOYMENT, QUIET POSSESSION, CORRESPONDENCE TO DESCRIPTION, OR NON-INFRINGEMENT WITH REGARD TO THE PRODUCT.</p>
  </li>
  </ol>
  <ol start="9" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">9. EXCLUSION OF INCIDENTAL, CONSEQUENTIAL, AND CERTAIN OTHER DAMAGES</h3>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL ICERTIS OR ITS SUPPLIERS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER (INCLUDING,BUT NOT LIMITED TO,DAMAGES FOR LOSS OF PROFITS OR CONFIDENTIAL OR OTHER INFORMATION, FOR BUSINESS INTERRUPTION, FOR PERSONAL INJURY, FOR LOSS OF PRIVACY, FOR FAILURE TO MEET ANY DUTY INCLUDING OF GOOD FAITH OR OF REASONABLE CARE, FOR NEGLIGENCE, AND FOR ANY OTHER PECUNIARY OR OTHER LOSS WHATSOEVER) ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF OR INABILITY TO USE THE IDN MATERIAL, OR OTHERWISE UNDER OR IN CONNECTION WITH ANY PROVISION OF THIS AGREEMENT, EVEN IN THE EVENT OF THE FAULT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY,BREACH OF CONTRACT, OR BREACH OF WARRANTY OF ICERTIS OR ANY SUPPLIER, AND EVEN IF ICERTIS OR ANY SUPPLIER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
  </li>
  </ol>
  <ol start="10" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">10. LIMITATION OF LIABILITY AND REMEDIES</h3>
    <p>Notwithstanding any damages that you might incur for any reason whatsoever (including, without limitation, all damages referenced above and all direct or general damages), the entire liability of Icertis and any of its suppliers under any provision of this Agreement and your exclusive remedy for all of the foregoing (except for any remedy of repair or replacement elected by Icertis with respect to any breach of the Limited Warranty) shall be limited to the greater of the amount actually paid by you for the IDN Material giving rise to the breach, or U.S.$5.00.  The foregoing limitations, exclusions, and disclaimers shall apply to the maximum extent permitted by applicable law, even if any remedy fails its essential purpose.</p>
  </li>
  </ol>
  <ol start="11" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">11. CONFIDENTIALITY</h3>
    <ol>
      <li style="list-style: none">
      <p><span style="font-weight: 800">11.1 </span>Each Party agrees to take reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary information, but not less than reasonable care, to prevent the unauthorized duplication or use of the other Party’s Confidential Information and the disclosure of the other Party’s Confidential Information to third parties without the other Party’s prior written consent. Each Party may disclose the other Party’s Confidential Information to its employees or agents who reasonably need to have access to such information to perform its obligations under this Agreement, and who will treat such Confidential Information under the terms of this Agreement.  </p>
      </li>
      <li style="list-style: none">
      <p><span style="font-weight: 800">11.2 </span>If a Party discloses or uses (or threatens to disclose or use) any Confidential Information of the other Party in breach of confidentiality protections hereunder, the other Party shall have the right, in addition. </p>
      </li>
      <li style="list-style: none">
      <p><span style="font-weight: 800">11.3 </span>to any other remedies available, to seek injunctive relief to enjoin such acts, it being acknowledged by the Parties that any other available remedies are inadequate.</p>
      </li>
      <li style="list-style: none">
      <p><span style="font-weight: 800">11.4 </span>Confidential Information shall not include any information that:  (i) is or becomes generally known to the public without breach of any obligation owed to the other Party; (ii) was known to a Party prior to its disclosure by the other Party without breach of any obligation owed to the other Party; (iii) was independently developed by a Party without breach of any obligation owed to the other Party; or (iv) is received from a third Party without breach of any obligation owed to the other Party.  </p>
      </li>
      <li style="list-style: none">
      <p><span style="font-weight: 800">11.5 </span>If a Party is required by a court or other body of competent authority and jurisdiction to disclose the Confidential Information of the other, such Party may disclose only so much of the other Party’s Confidential Information as is legally required, provided that (if legally permissible) it has given notice of such compelled disclosure to the other Party and has given that Party a reasonable opportunity to object to such disclosure and has provided reasonable assistance in obtaining and enforcing a protective order, filing under seal and/or other appropriate means of safeguarding the confidentiality of any of the other Party’s Confidential Information so required to be disclosed.</p>
      <li style="list-style: none">
      <p><span style="font-weight: 800">11.6 </span>“Confidential Information” means:  (i) all Icertis property; (ii) all software, information, personal information, data, materials, subject matter, algorithms, source code, object code, workflows, models, formulae, structures, schematics, designs, drawings, specifications, flow charts, diagrams and methods and processes of, contained in or embodied by any of Icertis’s products and related documentation; (iii) all information, data, materials, subject matter, methods and processes, concerning the current or prospective businesses, products, customers, finances, contractual arrangements, employees, contractors and third party suppliers of either party whether such information is furnished in writing, orally or in any physical or intangible medium; (v) all third party materials in the possession, under the control, or at the premises of either party; (vi) all information reasonably understood to be confidential given the nature of the information and the circumstances of disclosure.</p>
      </li>
    </ol>
  </li>
  </ol>
  <ol start="12" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">12. OWNERSHIP</h3>
    <p>The IDN Material is protected by copyright and other intellectual property laws and treaties.  Icertis or its suppliers own the title, copyright, and other intellectual property rights in the IDN Material.  The IDN Material is licensed, not sold. The parties agree that any rights, title and interest to work, materials or intellectual property, including tools, processes, know-how and methodology, owned or developed by you prior to or independently of this agreement (“Pre-Existing Property”) shall remain your exclusive property, and any derivative works of your Pre-Existing Property will be owned solely by you. To the extent that any derivative works of Icertis property are created by Partner, such derivative works of Icertis property shall be owned and belong to Icertis. Any such derivative works are licensed back to you for use only to serve Icertis customers.  Upon Icertis’ reasonable request and at its cost and expense, you agree to execute all documents required to record, perfect or evidence Icertis’ ownership, in and to the Icertis property, any derivative works of Icertis property thereof.</p>
  </li>
  </ol>
  <ol start="13" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">13. ENTIRE AGREEMENT</h3>
    <p>This Agreement (including any addendum or amendment to this Agreement which is included with the Product) are the entire agreement between you and Icertis relating to the Product and the Support Services (if any) and they supersede all prior or contemporaneous oral or written communications, proposals, and representations with respect to the Product or any other subject matter covered by this Agreement.  To the extent the terms of any Icertis policies or programs for Support Services conflict with the terms of this Agreement, the terms of this Agreement shall control.</p>
  </li>
  </ol>
  <ol start="14" style="padding-left: 0; margin-left: 0">
  <li style="list-style: none;">
    <h3 style="font-weight: 800">14. MISCELLANEOUS</h3>
    <p>If you acquired this product in the United States, this Agreement is governed by the laws of the State of Washington. </p>
  </li>
  </ol>
`

@Injectable({
  providedIn: 'root'
})

export class IdnTermsService {

  constructor() { }

  public getTermsContent() {
    return TERMS_AND_CONDITIONS;
  }

}
