import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';
import { NotificationService } from '../../notification/notification.service';
import { CustomerMgmtStaticString } from '../../enum/customerManagement';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { IdnSearchBoxComponent } from '../idn-search-box/idn-search-box.component';

@Component({
  selector: 'app-view-export-logs',
  templateUrl: './view-export-logs.component.html',
  styleUrls: ['./view-export-logs.component.scss']
})
export class ViewExportLogsComponent implements OnInit {
  public CustomerMgmtEnum = CustomerMgmtStaticString;
  @ViewChild(IdnSearchBoxComponent, { static: false })
  childComponent!: IdnSearchBoxComponent;
  breadCrumbItems:any;
  viewExportLogsData:any = [];
  displayedColumnsViewExportLogs: any = ['action','status','createdby','filename', 'createdon','Error_Message'];
  dataSourceValuesViewExportLogs: any = {
    action: {
      label: 'Action',
      data: 'action',
    },
    status: {
      label: 'Status',
      data: 'exportedFileStatus',
    },
    createdby:{
      label: 'File Requested By',
      data: 'requestedByDisplayName'
    },
    filename :{
      label: 'File Name',
      data: 'fileName'
    },
    createdon :{
      label: 'File created On',
      data: 'createdAt'
    },
    Error_Message :{
      label: 'Error Message',
      data: 'errorMessage'
    }
  };
  dataSourceViewExportLogs:any ;
  searchText: any;
  filterVal: any;
  customerStatus: any;
  partnerName: any;
  exportType: any;
  pageNumber: number = 1;
  pageSize: number = 10;
  currentPage: number = 0;
  pageLength: number = 0;
  sortBy: any ;
constructor(
  private customerService: CustomerService,
  private blobService: AzureBlobStorageService,
  private notificationService: NotificationService,
) { }

  ngOnInit(): void {
    this.breadCrumbItems  = [
      { label: "Home", path: "resources" },
      { label: "Administration", path: "administration/customer-management" },
      { label: "Customer Management", path: "administration/customer-management" },
      { label: "view Logs", path: "view-logs" },
    ];
    this.GetExportedFileHistory();
  }
  onSearchData(searchData:any){
    this.searchText = searchData ? searchData : '';
    this.GetExportedFileHistory();
  }
  
  clearFilter(){
    this.childComponent.onClear();
    this.GetExportedFileHistory();
  }

  pageChanged(data:any) {
    let event = data?.event;
    this.pageSize = event?.pageSize;
    this.currentPage = event?.pageIndex;
    this.pageNumber=event?.pageIndex+1;
    let filters = localStorage.getItem('viewLogsFilter');
    if (filters != null) {
      let Json = {
        'search': this.searchText.value ?this.searchText.value : '' ,
        'pageSize':  this.pageSize?  this.pageSize : '',
        'pageNumber':  this.pageNumber  ?  this.pageNumber : '',
        'currentPage': this.currentPage ? this.currentPage : ''
      };
      localStorage.setItem('viewLogsFilter', JSON.stringify(Json))
    }
    this.GetExportedFileHistory();
  }

  announceSortChange(data: any) {
    let sortState: Sort = data?.event;
    if (sortState?.direction == 'desc') {
      this.sortBy = sortState?.active + '_' + sortState?.direction;
    } else {
      this.sortBy = sortState?.active;
    }
    
    this.GetExportedFileHistory();
  }

  GetExportedFileHistory(){
    this.searchText = this.searchText ? this.searchText : '';
    this.sortBy = this.sortBy ? this.sortBy : '';
    try{
      this.customerService.GetExportedFileHistory(this.searchText,this.sortBy, this.pageNumber, this.pageSize).subscribe((resp:any)=>{
        if(resp){
          this.dataSourceViewExportLogs = {
            matchingCount: resp.matchingCount,
            records:resp.records
          }
        }
        else{
          this.dataSourceViewExportLogs = [];
        }
        this.viewExportLogsData = {
          reference: 'exportLogsTable',
          displayedColumns: this.displayedColumnsViewExportLogs,
          dataSourceValues: this.dataSourceValuesViewExportLogs,
          dataSource: this.dataSourceViewExportLogs,
          pagination: {
            required: true,
            currentPage: this.currentPage,
            pageLength: this.pageLength,
            pageNumber : this.pageNumber,
            pageSize : this.pageSize
          },
        };
      })
    }catch(error){
      console.log(error)
    }
   
  }
  downloadLogs(event:any){
   
    try{
      var FileSaver = require('file-saver');         
      this.customerService.GetExportedFileBlobDownloadConnection(event.uniqueIdentifier,event.fileName).subscribe((resp: any) => {

        if(resp!=undefined && resp!=null){
          this.blobService.downloadImageExt(resp.sasUrl, resp.container, resp.blob, (response: any) => {

            if (response._response.status == 200) {
              response.blobBody.then(
                (onres: any) => {
                  FileSaver.saveAs(onres, event.fileName);
                })
              this.notificationService.showNotification(this.CustomerMgmtEnum.bulkaction_viewlogs_download_success_body, 'success', this.CustomerMgmtEnum.bulkaction_viewlogs_download_head);
            } else {
              this.notificationService.showNotification(this.CustomerMgmtEnum.bulkaction_viewlogs_download_error_body, 'error', this.CustomerMgmtEnum.bulkaction_viewlogs_download_head);
            }
          })
        }
      });
    }
    catch(err) {
      console.log(err);
  }
  }
}
