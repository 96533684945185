import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateExtensionGuardService } from 'src/app/core/Guards/createExtensionGuard/create-extension-guard.service';
import { EditExtensionGuardService } from 'src/app/core/Guards/editExtensionGuard/edit-extension-guard.service';
import { CreateExtensionComponent } from './create-extension/create-extension.component';
import { EditExtensionComponent } from './edit-extension/edit-extension.component';
import { ExtensionListComponent } from './extension-list/extension-list.component';
import { ExtensionManagementComponent } from './extension-management.component';
import { ViewExtensionComponent } from './view-extension/view-extension.component';

const routes: Routes = [
  {
    path: '',
    component: ExtensionListComponent
  },
  {
    path: 'create',
    component: CreateExtensionComponent,
    canActivate: [CreateExtensionGuardService]
  },
  {
    path: 'edit',
    component: EditExtensionComponent,
    canActivate: [EditExtensionGuardService]
  },
  {
    path: 'view',
    component: ViewExtensionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  declarations: [
    ExtensionManagementComponent
],
  exports: [RouterModule]
})
export class ExtensionManagementRoutingModule { }
