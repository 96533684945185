import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MsalService } from '@azure/msal-angular';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { lastValueFrom, pluck } from 'rxjs';
import { EnvService } from 'src/app/core/services/env.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { WorkbenchMgmtStaticString } from 'src/app/shared/enum/workbenchManagement';
import { InputPattern } from 'src/app/shared/enum/inputPattern';

@Component({
  selector: 'app-upload-sdk',
  templateUrl: './upload-sdk.component.html',
  styleUrls: ['./upload-sdk.component.scss']
})
export class UploadSdkComponent implements OnInit {
  public WbMgmtEnum = WorkbenchMgmtStaticString;
  @ViewChild('myfile')
  myfileVariable!: ElementRef;
  sas: any;
  fileSizeLimit = this.env.fileSizeLimit; //environment.fileSizeLimit;
  filename = '';
  azUrl: string = "pathNotFound";
  filePath: any;
  file: any;
  showProgressBar = 'false';
  eventObject: any = {};
  icVersionList: any = [];
  SelectICIVersion: any;
  newArray: any = [];
  sdkRowId: any;
  currentSDK: any;
  eolDate: any;
  maxAllowedDate: any;
  pickerEol: any;
  maxAllowedDate1: any;
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  fileAttr = "Choose File";
  listofUploadedFiles: any[] = [];
  fileArray: any[] = [];
  uniqueIdentifier: any;
  rDate: any
  myInputValueReleaseNotes: any;
  errorDescriptionName: any;
  myInputValuename: any;
  errorDescriptionReleaseNote: any;
  errorDescriptionField: any;
  myInputValue: any;
  public regex = InputPattern;
  regexName: any = new RegExp(this.regex.namePattern);
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  errorDescription: any = this.regex.errorMessage;
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;
  minDate = new Date();
  cloneDate: any;
  minDateEodofDate: any;
  minDateEolDate = new Date();
  icListing = new FormControl();
  events: string[] = [];
  fileExtension = ['zip', 'rar', 'vsix'];
  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: 'Development and Utilities', path: 'developmentandutilities/idn-workbench-management' },
    { label: 'IDN Workbench', path: 'developmentandutilities/idn-workbench-management' },
    { label: "Create", path: "developmentandutilities/idn-workbench-management/upload" },
  ];
  isEditSDK: boolean = false;
  isViewSDK: boolean = false;
  isActivateSDK: boolean = false;
  isDeactivateSDK: boolean = false;
  isCreateSDK: boolean = false;
  hasGlobalDataAccess: boolean = false;
  refreshSDKList : any = {}
  // myFilter = (d: Date | null): boolean => {
  //   const days = (d || new Date()).getDate();
  //   const day = (d || new Date()).getMonth() + 2;
  //   return day >= 31 && day <= 7;

  // };

  dropdownSettings: IDropdownSettings = {};
  constructor(private blobService: AzureBlobStorageService,
    private sdkService: SdkServiceService,
    private router: Router,
    private titleService: Title,
    private cd: ChangeDetectorRef,
    private permssionService: PermissionsService,
    private notificationService: NotificationService, private msalService: MsalService,
    private env: EnvService) {
    this.getVersionList()
  }

  frmSDK: FormGroup = new FormGroup({
    Name: new FormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern(this.regexName)]),
    Description: new FormControl('', [Validators.required, Validators.maxLength(500),Validators.pattern(this.regexDescription)]),
    Version: new FormControl('', [Validators.required, Validators.pattern(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?:)?$/)]),
    file: new FormControl('', Validators.required),
    uniqueIdentifier: new FormControl(null),
    ReleaseNote: new FormControl('', [Validators.required, Validators.pattern(this.regexDescription)]),
    ReleaseDate: new FormControl(''),
    EOLDate: new FormControl(''),
    IClist: new FormControl('', Validators.required),
  });

  ngOnInit(): void {
    //this.getSas();
    this.titleService.setTitle('Create Workbench | IDN');
    this.isCreateSDK = this.permssionService.getPermission('CreateSDK');
    this.isEditSDK = this.permssionService.getPermission('UpdateSDK');
    this.isViewSDK = this.permssionService.getPermission('ViewSDK');
    this.isActivateSDK = this.permssionService.getPermission('ActivateSDK');
    this.isDeactivateSDK = this.permssionService.getPermission('DeactivateSDK');

    this.hasGlobalDataAccess = this.permssionService.hasGlobalDataAccess();
    this.UploadFileAsynchronously
    this.dropdownSettings = {
      idField: 'id',
      textField: 'version',
    };
  }

  getVersionList() {
    try {
      this.sdkService.GetICVersionList().subscribe((result: Response) => {
        this.icVersionList = result;
        this.icVersionList = this.icVersionList?.sort((a:any, b:any) =>{ return b.version.localeCompare(a.version, undefined, { numeric: true, sensitivity: 'base' });});
      })
    }
    catch (error) {
      console.log(error);
    }
  }


  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescriptionField = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionField = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescriptionField = this.regex.errorMessage;
    }
  }

  valueChangename(event: any) {
    if (event == undefined) {
      this.errorDescriptionName = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionName = this.regex.firstCharacterErrorMessage;
      return;
    }
    else {
      this.errorDescriptionName = this.regex.errorMessage;
    }
  }
  
  valueChangeReleaseNotes(event: any) {
    if (event == undefined) {
      this.errorDescriptionReleaseNote = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionReleaseNote = this.regex.firstCharacterErrorMessage;
      return;
    }
    else {
      this.errorDescriptionReleaseNote = this.regex.errorMessage;
    }
  }
  

  uploadFileEvt(File: any) {
    if (File.target.files[0] != undefined && File.target.files[0] != null && File.target.files[0] != '') {
      var lastDot = File.target.files[0].name.lastIndexOf(".");
      const extension = File.target.files[0].name.substring(lastDot + 1).toLowerCase();
      const validExtension = this.fileExtension.filter((x) => x == extension).length > 0;
      if (!validExtension) {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_invalid_file_body, 'warning', this.WbMgmtEnum.wb_notify_invalid_file_heading);
        return
      }
      this.file = File.target.files[0];
      var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
      if (filesize == 0) {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_emptyFile_limit_body, 'error', this.WbMgmtEnum.wb_notify_emptyFile_limit_heading);
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        this.frmSDK.get('file')?.reset();
        return
      }
      if (filesize > this.fileSizeLimit) {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_exceed_file_limit_body, 'error', this.WbMgmtEnum.wb_notify_exceed_file_limit_heading);
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose IDN Workbench File';
        this.frmSDK.get('file')?.reset();
        return
      }
      if (File.target.files && File.target.files[0]) {
        this.fileAttr = '';
        this.fileAttr = File.target.files[0].name;
        this.filename = File.target.files[0].name;
        this.frmSDK.patchValue({ file: this.filename });
      }
    }
  }

  getselectedICIversion(event: any) {
    if (this.newArray.includes(event.id)) {
      for (var i = 0; i < this.newArray.length; i++) {
        if (this.newArray[i] == event.id) {
          this.newArray.splice(i, 1);
          break;
        }
      }
    } else {
      this.newArray.push(event.id);
    }
  }

  async onSaveSDk(): Promise<void> {
    try {

      let releaseDatelocalISOTime;
      let EOLDatelocalISOTime;
      if (this.filename == "" || this.filename == undefined) {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_choose_wb_body, 'error', this.WbMgmtEnum.wb_notify_choose_wb_heading);
        return;
      }

      if (this.frmSDK.value.ReleaseDate != "" && this.frmSDK.value.EOLDate != "") {
        let d = new Date(this.frmSDK.value.ReleaseDate);
        let d2 = new Date(this.frmSDK.value.EOLDate)

        let releaseDatetzoffset = d.getTimezoneOffset() * 60000;
        releaseDatelocalISOTime = new Date(Number(d) - releaseDatetzoffset).toISOString().slice(0, -1);

        let EOLDatetzoffset = d2.getTimezoneOffset() * 60000;
        EOLDatelocalISOTime = new Date(Number(d2) - EOLDatetzoffset).toISOString().slice(0, -1);
      }

      const json = {
        "id": 0,
        "name": this.frmSDK.value.Name,
        "description": this.frmSDK.value.Description,
        "version": this.frmSDK.value.Version,
        "fileUrl": this.azUrl,
        "fileName": this.filename,
        "Status": 2,
        "releaseNote": this.frmSDK.value.ReleaseNote,
        "releaseDate": this.frmSDK.value.ReleaseDate == "" ? null : releaseDatelocalISOTime,
        "endOfLifeDate": this.frmSDK.value.EOLDate == "" ? null : EOLDatelocalISOTime,
        "isDownloaded": true,
        "iciIds": this.frmSDK.value.IClist,
      }

      let isexist = await this.checkIfExtensionSDK(json.name, json.version)
      if (isexist != undefined && !isexist.body) {
        this.showProgressBar = 'true';
        this.eventObject['showProgressBar'] = this.showProgressBar;
        this.eventObject['sdkName'] = this.frmSDK.value.Name;
        this.eventObject['sdkDesc'] = this.frmSDK.value.Description;
        this.eventObject['sdkVers'] = this.frmSDK.value.Version;
        this.sdkService.emit<any>(this.eventObject);
        this.sdkService.PostData("api/SDK/Add", json).subscribe(async (result: Response) => {
          this.sdkRowId = result;
          this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
          this.sdkService.GetSDKById(this.sdkRowId).subscribe((res: any) => {
            this.currentSDK = res.body;
          })
          let resToken = await this.setToken(this.sdkRowId);
          if (resToken != null && resToken != undefined) {
            this.sas = resToken.sasUrl;
            this.uniqueIdentifier = resToken.container;
            this.frmSDK.controls['uniqueIdentifier'].setValue(this.uniqueIdentifier);
          }
          this.UploadFileAsynchronously();
          this.sdkService.emit<any>(this.eventObject);
        })
      } else {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_wb_exist_body, 'error', this.WbMgmtEnum.wb_notify_wb_exist_heading);
        this.eventObject['showProgressBar'] = 'false';
        this.sdkService.emit<any>(this.eventObject);
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  //To Upload package file on blob
  UploadFileAsynchronously() {
    return new Promise(resolve => {
        this.blobService.uploadFiles(this.sas, this.uniqueIdentifier, this.file, this.file.name, (response: any) => {
          if (response._response != undefined) {
            this.azUrl = response._response.request.url;
                this.sdkService.FileUploadSuccessful(this.sdkRowId).subscribe((resp: any) => {
                        this.refreshSDKList['refreshSDK'] = true
                    this.sdkService.emitRefreshSDK<any>(this.refreshSDKList);
                 });
                this.notificationService.showNotification('IDN Workbench file has been uploaded successfully', 'success', 'IDN Workbench file uploaded')
            } else {
                this.notificationService.showNotification('IDN Workbench file upload failed', 'error', 'IDN Workbench upload failed');
          }
          this.eventObject['showProgressBar'] = 'false';
          this.sdkService.emit<any>(this.eventObject);
        });

    });
  }

  //To Set SAS Token
  async setToken(sdkId: any): Promise<any> {
    try {
      return this.sdkService.getBlobUploadConnection(sdkId).toPromise();
    }
    catch (execption) {
      console.log(execption)
    }
  }

  onCancel(val: any) {
    let navLink: any = 'home/developmentandutilities/' + val;
    this.router.navigate([navLink]);
  }

  async checkIfExtensionSDK(sdkName: any, version: any) {
    return await lastValueFrom(this.sdkService.checkForDuplicateSDK(sdkName, version))
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    try {
      let rdate: any = event.value;
      this.minDateEodofDate = new Date(rdate);
      this.minDateEolDate = new Date(this.minDateEodofDate.valueOf());
      this.minDateEolDate.setDate(this.minDateEolDate.getDate() + 1);
      //this.minDateEolDate= new Date(rdate);
    } catch (err) {
      console.log(err);
    }
  }
}


