import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { MultiTenantPodService } from 'src/app/core/services/multiTenantPod-management/multi-tenant-pod.service';
import { MultiTenantPods } from 'src/app/shared/enum/multi-tenant-pods';
import { NotificationService } from 'src/app/shared/notification/notification.service';

const NO_PREVIEW = `<svg xmlns="http://www.w3.org/2000/svg" width="46.13" height="46.807" viewBox="0 0 46.13 46.807">
<g id="Group_576" data-name="Group 576" transform="translate(-72.438)">
  <path id="Path_439" data-name="Path 439" d="M11075.541,384.726l22.932,11.987v23.036l-22.932-11.57Z" transform="translate(-11003 -373.052)" fill="#926e43"/>
  <path id="Path_440" data-name="Path 440" d="M11098.577,396.5l22.932-11.57v23.348l-22.932,11.576Z" transform="translate(-11003.104 -373.052)" fill="#a67e4f"/>
  <path id="Path_441" data-name="Path 441" d="M11075.438,384.726l23.035,11.987,23.095-11.987-23.095-11.778Z" transform="translate(-11003 -372.948)" fill="#d9ac80"/>
  <path id="Path_442" data-name="Path 442" d="M11083.984,380.452l5.941-3.127,22.827,12.091-5.629,2.919Z" transform="translate(-11002.896 -373.156)" fill="#f2d3ba"/>
  <path id="Path_443" data-name="Path 443" d="M11107.124,392.221l5.733-3.013v5.524l-2.919-1.251-2.814,4.065Z" transform="translate(-11003 -372.872)" fill="#d7baa4"/>
</g>
</svg>`;

export interface UpgradeData {
  id: number;
  instanceName: string;
  extensionName: string;
  version: string;
  targetIciVersion: string;
  newVersion: string;
  compatible: boolean;
}

@Component({
  selector: 'app-preview-upgrade',
  templateUrl: './preview-upgrade.component.html',
  styleUrls: ['./preview-upgrade.component.scss'],
})
export class PreviewUpgradeComponent implements OnInit {
  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @Output() closeD = new EventEmitter<String>();
  @Input() podDetails: any;
  isApproveInstance: boolean = true;
  checked: boolean = true;
  @ViewChild("ref") ref:any

  public mtPodEnum = MultiTenantPods;
  minorversion = 0;
  patchversion = 0;
  buildversion = 0;
  majorversion = 0;
  extensionName = '';
  isPreviewTable: boolean = false;
  checkedItems: any = [];
  noContent: Boolean = true;
  pageSize: number = 5;
  pageLength: any = 0;
  currentPage: number = 0;
  pageNumber: number = 1;
  dataSource: any = [];
  compatible: any = [];
  newVersion: any = '';
  isViewPreviewUpgrade: boolean = false;
  isMtPodStartUpgrade: boolean = false;
  selection = new SelectionModel<UpgradeData>(true, []);
  major:any;
  minor:any;
  build:any;
  patch:any;

  frmAddPackage: FormGroup = new FormGroup({
    name: new FormControl('0.0.0.0', Validators.required),
    major: new FormControl(0, Validators.required),
    minor: new FormControl(0, Validators.required),
    patch: new FormControl(0, Validators.required),
    build: new FormControl(0, Validators.required),
  });

  constructor(
    private multiTenatPodService: MultiTenantPodService,
    private router: Router,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'no_preview_icon',
      sanitizer.bypassSecurityTrustHtml(NO_PREVIEW)
    );
  }

  ngOnInit(): void {
    [this.major, this.minor, this.patch, this.build] = this.podDetails.iciVersion.split('.');
    this.majorversion = Number(this.major);
    this.minorversion = Number(this.minor);
    this.patchversion = Number(this.patch)+1;
    this.buildversion = Number(this.build);
    this.drawer.toggle();
    this.frmAddPackage.patchValue({
      major: this.majorversion,
      minor: this.minorversion,
      patch: this.patchversion,
      build: this.buildversion,
      name:this.majorversion+'.'+this.minorversion+'.'+this.patchversion+'.'+this.buildversion
    });
    this.isViewPreviewUpgrade = this.permissionService.getPermission(
      'PreviewMultiTenantPODUpgrade'
    );
    this.isMtPodStartUpgrade = this.permissionService.getPermission(
      'UpgradeMultiTenantPOD'
    );
    this.frmAddPackage.controls['major'].valueChanges.subscribe((value) => {
      this.majorversion = value;
      this.frmAddPackage.controls['name'].setValue(
        this.majorversion +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.frmAddPackage.value.build
      );
    });
    this.frmAddPackage.controls['minor'].valueChanges.subscribe((value) => {
      this.minorversion = value;
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.minorversion +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.frmAddPackage.value.build
      );
    });
    this.frmAddPackage.controls['patch'].valueChanges.subscribe((value) => {
      this.patchversion = value;
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.patchversion +
          '.' +
          this.frmAddPackage.value.build
      );
    });
    this.frmAddPackage.controls['build'].valueChanges.subscribe((value) => {
      this.buildversion = value;
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.buildversion
      );
    });
    this.validateInputIciVersion()
  }

  allowPreview:boolean=false;

  validateInputIciVersion(){
    let presentVersion = this.podDetails.iciVersion;
    this.frmAddPackage.valueChanges.subscribe((value:any)=>{
      let inputVersion=`${value.major}.${value.minor}.${value.patch}.${value.build}`;
      let compapeValue =inputVersion.localeCompare(presentVersion)
      if(compapeValue>=0){
        this.allowPreview=false;
      } else {
        this.allowPreview=true;
      }
    })
    this.allowPreview
  }

  getMultiPodPreviewDetails() {
    try {
      let version = {
        Major: this.frmAddPackage.get('major')?.value,
        Minor: this.frmAddPackage.get('minor')?.value,
        Patch: this.frmAddPackage.get('patch')?.value,
        Build: this.frmAddPackage.get('build')?.value,
      };

      let filterResult: any = [];
      this.multiTenatPodService
        .getMultiTenantPODPreviewUpgrade(this.podDetails.id, version)
        .subscribe((data: any) => {
          let resp= data.body.records;
          if (resp != undefined && resp != null && resp.length>0) {
            this.noContent = false;
            // this.newVersion = this.frmAddPackage.get('name')?.value;
            filterResult = resp;
            filterResult = filterResult.map((obj: any) => {
              if (obj.isCompatible) {
                return { ...obj, selected: true };
              } else {
                return { ...obj, selected: false };
              }
            });
            this.compatibleList(filterResult);
          } else {
            this.noContent = true;
            this.dataSource = [];
          }
          filterResult = filterResult.sort((a: any, b: any) =>
            a.instanceName.localeCompare(b.instanceName)
          );
          this.dataSource = new MatTableDataSource<any>(filterResult);
          if(this.dataSource){
            this.dataSource.data.forEach((row:any) => {
              if (row.selected == true) this.selection.select(row);
            });
          }          
        });
    } catch (error) {
      console.log(error);
    }
  }

  compatibleList(filterResult: any) {
    this.compatible = filterResult.filter((ele: any) => ele.isCompatible);
    this.compatible.forEach((data: any) => {
      let reqData = {
        InstanceName: data.instanceName,
        InstanceId: data.instanceId,
        ExtensionId: data.extensionId,
        SolutionPackageId: data.solutionPackageId,
        DeploymentRequestId: 0,
        ExtensionTypeId: data.extensionTypeId,
        selected: true,
      };
      this.checkedItems.push(reqData);
    });
    this.compatible = this.checkedItems;
  }

  ngAfterViewInit() {
    this.frmAddPackage.controls['name'].setValue(
      this.frmAddPackage.value.major +
        '.' +
        this.frmAddPackage.value.minor +
        '.' +
        this.frmAddPackage.value.patch +
        '.' +
        this.frmAddPackage.value.build
    );
  }

  displayedColumns: string[] = [
    'select',
    'tenantName',
    'extensionName',
    'version',
    'newVersion',
    // 'iciVersion',
    'compatible',
  ];

  

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    if (this.dataSource.data) {
      const numSelected = this.selection.selected.length;
      const numRows = this.compatible.length;
      return numSelected === numRows;
    }
    return false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
      this.isAllSelected()
        ? (this.selection.clear(), (this.checkedItems = []))
        : (this.dataSource.data.forEach((row: any) =>
            row.isCompatible ? this.selection.select(row) : null
          ),
          (this.checkedItems = this.compatible));
  }

  isSomeSelected() {
    return this.selection.selected.length > 0;
  }

  increaseValue(value: any) {
    if (value == 'major') {
      this.majorversion += 1;
      this.frmAddPackage.controls['major'].setValue(this.majorversion);
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.frmAddPackage.value.build
      );
    } else if (value == 'minor') {
      this.minorversion += 1;
      this.frmAddPackage.controls['minor'].setValue(this.minorversion);
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.frmAddPackage.value.build
      );
    } else if (value == 'patch') {
      this.patchversion += 1;
      this.frmAddPackage.controls['patch'].setValue(this.patchversion);
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.frmAddPackage.value.build
      );
    } else if (value == 'build') {
      this.buildversion += 1;
      this.frmAddPackage.controls['build'].setValue(this.buildversion);
      this.frmAddPackage.controls['name'].setValue(
        this.frmAddPackage.value.major +
          '.' +
          this.frmAddPackage.value.minor +
          '.' +
          this.frmAddPackage.value.patch +
          '.' +
          this.frmAddPackage.value.build
      );
    }
  }

  //Decrease value by 1 pressing plus button
  decreaseValue(value: any) {
    if (value == 'major') {
      if (this.majorversion > 0) {
        this.majorversion -= 1;
        this.frmAddPackage.controls['major'].setValue(this.majorversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      } else {
        this.majorversion = 0;
        this.frmAddPackage.controls['major'].setValue(this.majorversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      }
    } else if (value == 'minor') {
      if (this.minorversion > 0) {
        this.minorversion -= 1;
        this.frmAddPackage.controls['minor'].setValue(this.minorversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      } else {
        this.minorversion = 0;
        this.frmAddPackage.controls['minor'].setValue(this.minorversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      }
    } else if (value == 'patch') {
      if (this.patchversion > 0) {
        this.patchversion -= 1;
        this.frmAddPackage.controls['patch'].setValue(this.patchversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      } else {
        this.patchversion = 0;
        this.frmAddPackage.controls['patch'].setValue(this.patchversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      }
    } else if (value == 'build') {
      if (this.buildversion > 0) {
        this.buildversion -= 1;
        this.frmAddPackage.controls['build'].setValue(this.buildversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      } else {
        this.buildversion = 0;
        this.frmAddPackage.controls['build'].setValue(this.buildversion);
        this.frmAddPackage.controls['name'].setValue(
          this.frmAddPackage.value.major +
            '.' +
            this.frmAddPackage.value.minor +
            '.' +
            this.frmAddPackage.value.patch +
            '.' +
            this.frmAddPackage.value.build
        );
      }
    }
  }

  previewUpgrade() {
    this.isPreviewTable = true;
    this.checkedItems=[];
    this.selection.clear()
    this.getMultiPodPreviewDetails();
  }

  updateCheckedList(checkBox: any, data: any) {
    if (checkBox.checked) {
      let reqData = {
        InstanceName: data.instanceName,
        InstanceId: data.instanceId,
        ExtensionId: data.extensionId,
        SolutionPackageId: data.solutionPackageId,
        DeploymentRequestId: 0,
        ExtensionTypeId: data.extensionTypeId,
        selected: true,
      };
      this.checkedItems.push(reqData);
    } else {
      this.checkedItems = this.checkedItems.filter((ele: any) => {
        if (
          ele.InstanceId != data.instanceId ||
          ele.SolutionPackageId != data.solutionPackageId
        ) {
          return ele;
        }
      });
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
  }

  startUpgrade() {    
    var paramreqdata = {
      "Id": this.podDetails.id,
      "iciVersion": this.majorversion+'.'+this.minorversion+'.'+this.patchversion+'.'+this.buildversion
    };
    this.multiTenatPodService
      .UpgradePOD(this.checkedItems,paramreqdata)
      .subscribe((resp) => {
        if (resp.status == 200) {
          this.notificationService.showNotification(
            this.mtPodEnum.multiTenantPods_upgrade_pod_notification,
            'success',
            this.mtPodEnum.multiTenantPods_upgrade_pod_notification_header
          );
        }
      });
  }

  closeDrawer() {
    this.closeD.emit('false');
    this.drawer.close();
  }
}
