<div class="idn-access-denied">
  <div class="wrapper">
    <mat-icon class="icon-main">lock</mat-icon>
    <h1>Access Denied!</h1>
    <p>URL you are looking is not allowed. If still you should have access to this Module Please Contact IDN Admin for Permission.</p>
    <button mat-stroked-button class="button-info" (click)="navigateHome()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span>Back to Home</span>
    </button>
  </div>
</div>
