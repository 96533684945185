import { Component, OnInit, Input, ViewChild,Output,EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivityNotificationService } from 'src/app/core/services/activity-notification/activity-notification.service';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ActivityNotification } from 'src/app/shared/enum/activityNotification';
import { AESEncryptDecryptService } from 'src/app/core/services/common/encryption/aesencrypt-decrypt.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { MultiTenantPodService } from 'src/app/core/services/multiTenantPod-management/multi-tenant-pod.service';
import { MultiTenantPods } from 'src/app/shared/enum/multi-tenant-pods';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

const DEPLOYMENT_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="16.224" height="18.192" viewBox="0 0 16.224 18.192">
  <path id="Subtraction_1" data-name="Subtraction 1" d="M15.628,19.132H9.878l.031-7.04,1.633,2.982h4.086v4.057Zm-6.582,0H3.331V15.074H7.555l1.521-2.967-.03,7.024ZM17.6,14.206H12.024L9.935,10.391h5.854L17.6,14.2Zm-10.548,0H1.429l-.051-.024,1.872-3.791H9.012L7.055,14.205Zm5.591-4.683H6.468a9.211,9.211,0,0,1-.206-2.979H7.9a1.667,1.667,0,0,0,3.262,0h1.688a9.072,9.072,0,0,1-.21,2.978ZM9.536,7a.889.889,0,1,1,.851-.888A.871.871,0,0,1,9.536,7ZM7.9,5.676H6.4A8.066,8.066,0,0,1,9.528.941a8.065,8.065,0,0,1,3.186,4.734H11.167a1.667,1.667,0,0,0-3.262,0Z" transform="translate(-1.379 -0.94)" fill="#6332a4"/>
</svg>

`;

const SEARCHING_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="82.387" height="81.179" viewBox="0 0 82.387 81.179">
  <g id="Group_625" data-name="Group 625" transform="translate(-1095.613 -323.703)">
    <path id="Path_460" data-name="Path 460" d="M-10304.674-24516.9c.218.654,7.847,9.7,7.847,9.7l5.231-5.342-10.244-8.174Z" transform="translate(11447.628 24890.385)" fill="#e9f4ff"/>
    <path id="Rectangle_160" data-name="Rectangle 160" d="M0,0H10.771a0,0,0,0,1,0,0V26.1A5.875,5.875,0,0,1,4.9,31.976h0a4.9,4.9,0,0,1-4.9-4.9V0A0,0,0,0,1,0,0Z" transform="matrix(0.695, -0.719, 0.719, 0.695, 1147.516, 382.67)" fill="#6332a4"/>
    <g id="Group_624" data-name="Group 624" transform="translate(1111.769 339.859)">
      <path id="Path_461" data-name="Path 461" d="M7.342.291l5.341-1.525L18.131.291c4.09,0,6.776,2.621,6.776,6.711V20.465H0V5.655C0,2.309,4,.291,7.342.291Z" transform="translate(0 16.559)" fill="#01e3bb"/>
      <circle id="Ellipse_29" data-name="Ellipse 29" cx="8.415" cy="8.415" r="8.415" transform="translate(4.039 0)" fill="#ffdbca"/>
    </g>
    <g id="Ellipse_28" data-name="Ellipse 28" transform="translate(1095.613 323.703)" fill="none" stroke="#646464" stroke-width="6">
      <circle cx="28.61" cy="28.61" r="28.61" stroke="none"/>
      <circle cx="28.61" cy="28.61" r="25.61" fill="none"/>
    </g>
  </g>
</svg>

`;

interface Option {
  value: string;
  viewValue: string;
}
interface Roles {
  value: string;
  viewValue: string;
}
interface Events {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-pageheader',
  templateUrl: './pageheader.component.html',
  styleUrls: ['./pageheader.component.scss']
})
export class Pageheader implements OnInit {
  @Output() toggleCertificationDrawer:EventEmitter<any> = new EventEmitter();
  @Input() pageTitle: string = '';
  @Input() deployment: boolean = false;
  @Input() createRole: boolean = false;
  @Input() unArchive: boolean = false;
  @Input() archivePage: boolean = false;
  @Input() addUser: boolean = false;
  @Input() auditLogfilter: boolean = false;
  @Input() notificationPage: boolean = false;
  @Input() createPartner: boolean = false;
  @Input() createCustomer: boolean = false;
  @Input() editPartner: boolean = false;
  @Input() editCustomer: boolean = false;
  @Input() editSku: boolean = false;
  @Input() editUser: boolean = false;
  @Input() auditLog: boolean = false;
  @Input() auditLogData: any;
  @Input() mapRole: boolean = false;
  @Input() clearNotification: boolean = false;
  @Input() clearFilter: boolean = false;
  @Input() applyFilter: boolean = false;
  @Input() archive: boolean = false;
  @Input() uploadSdk:boolean=false;
  @Input() ReferenceDocumentsExtension:boolean=false;
  @Input() createextension: boolean = false;
  @Input() editSDK: boolean = false;
  @Input() editSDKdata: any;
  @Input() editViewExtData: any;
  @Input() BackTopageList: string = '';
  @Input() createInstance:boolean = false;
  @Input() instanceTools:boolean = false;
  @Input() requestCertification:boolean = false;
  @Input() createWebApp:boolean = false;
  @Input() breadCrumbs: any;
  @Input() editRole: any;
  @Input() editExtension:boolean=false;
  @Input() editInstance:any=false;
  @Input() extTabSelection:any = 0;
  @Input() isApprover: Boolean = false;
  @Input() editWebApp:boolean=false;
  @Input() requestIciSkuDeployment:boolean=false;
  @Input() viewLogsCustomer:boolean=false;
  @Input() viewLogsUser:boolean = false;
  @Input() createWorkerTask:boolean=false;
  @Input() editWorkerTask:boolean=false;
  @Output() exportTypeEvent : EventEmitter<any> = new EventEmitter();
  @Output() createWorkerTaskEvent : EventEmitter<any> = new EventEmitter();
  isEditable: boolean = false;
  isFilterDrawer: boolean = false;
  isAuditDrawer: boolean = false;
  isSelectionDrawer: boolean = false;
  selected: boolean = true;
  maxDate :any=new Date();
  @Input() globalNotificationList: any;
  @Input() globalAuditList: any;
  newGlobalNotifList :any=[];
  newGlobalAuditList :any=[];
  clearFilterButton : boolean = true;
  @ViewChild('start') start: any;
  @ViewChild('end') end: any;
  @ViewChild('month') mont !:MatSelect;
  bulkActionselect:any='Bulk Action';
  isCreateRole: boolean = false;
  isCreateWorkbench: boolean = false;
  isEditWorkbench: boolean = false;
  isApproveInstance:boolean = false;
  getTargetICIVersion:any;
  public notificationEnum = ActivityNotification;
  public laActivityEnum = MultiTenantPods;
  userNotificationData: any = [];
  badgeCount: any = null;
  exportType: any;
  extensionEnum = ExtensionManagement;
 
  constructor(
    private router: Router,
    private notificationService : NotificationService,
    private activityNotifyService : ActivityNotificationService,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private permissionService: PermissionsService,
    private _AESEncryptDecryptService: AESEncryptDecryptService,
    private instanceService : InstanceService,
    private multitenatpodService: MultiTenantPodService,
    private changeDetector: ChangeDetectorRef,
    private customerService:CustomerService
  ) {
    iconRegistry.addSvgIconLiteral('deployment', sanitizer.bypassSecurityTrustHtml(DEPLOYMENT_ICON));
    iconRegistry.addSvgIconLiteral('searching', sanitizer.bypassSecurityTrustHtml(SEARCHING_ICON));
   }
   notificationFilter: FormGroup = new FormGroup({
    byMonths: new FormControl(''),
    start: new FormControl(''),
    end: new FormControl('')
  });
   auditFilter: FormGroup = new FormGroup({
    byMonths: new FormControl(''),
    start: new FormControl(''),
    end: new FormControl('')
  });

  ngOnInit(): void {
    this.pageTitle;
    this.auditLog;
    this.createRole;
    this.unArchive;
    this.editCustomer;
    this.createPartner;
    this.archivePage;
    this.addUser;
    this.deployment;
    this.createCustomer;
    this.notificationPage;
    this.auditLogfilter;
    this.editPartner;
    this.mapRole;
    this.editUser;
    this.clearNotification;
    this.clearFilter;
    this.applyFilter;
    this.archive;
    this.editSDK;
    this.editSDKdata;
    this.BackTopageList;
    this.breadCrumbs;
    this.editSku;
    this.createWebApp;
    this.requestIciSkuDeployment;
    this.isCreateRole = this.permissionService.getPermission('CreateRole');
    this.isCreateWorkbench = this.permissionService.getPermission('CreateSDK');
    this.isEditWorkbench = this.permissionService.getPermission('UpdateSDK');
    this.isApproveInstance = this.permissionService.getPermission("ApproveInstance");
    this.viewLogsCustomer;
    this.viewLogsUser;
  }
  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  openCertificationDrawer() {
    this.toggleCertificationDrawer.emit(true)
  }

  navigateActionForExportType(event:any){
    this.exportTypeEvent.emit(event);
  }

  navigateCreateWorkerTask(event:any){
    this.createWorkerTaskEvent.emit(event);
  }

  navigateItem(navLink: any): void {
    if(navLink == "developmentandutilities/extension-management/create") {

      let userCategory=this.permissionService.getUsercategoryId();  
  
      let partnerId =this.permissionService.getPartnerDetails().id;
        if(partnerId || userCategory == USER_CATEGORY_ID.Customer) {
          if(navLink != 'home'){
            navLink = 'home/'+ navLink;
          }
          if((this.permissionService.getPermission('UpgradeMultiTenantPOD') && !this.permissionService.getPermission('PreviewMultiTenantPODUpgrade'))){
            this.router.navigate(['home/upgrade/pod-upgrade'])
          }
          else{
            this.router.navigate([navLink]);
          }
        }
        else {
          this.notificationService.showNotification(this.extensionEnum.extension_notify_error_message_for_extension_pageheader_body, 'error', 'Unable to create Extension');
        }
      
    } else if(navLink == 'instanceoperations/instance-requests/history'){
      let json={
        path:"pageHeader",
        id:'',
        name:''
      }
      localStorage.setItem('tools-history', json.id)
      this.instanceService.sendData(JSON.stringify(json));
      this.router.navigate(['home/'+ navLink]);
    } else {
      if(navLink != 'home'){
        navLink = 'home/'+ navLink;
      } 
      if((this.permissionService.getPermission('UpgradeMultiTenantPOD') && !this.permissionService.getPermission('PreviewMultiTenantPODUpgrade'))){
        this.router.navigate(['home/upgrade/pod-upgrade'])
      }
      else{
        this.router.navigate([navLink]);
      }
    }
  }

  toauditLogList(navLink: any,d:any){
    localStorage.setItem('auditLogData',d)
    navLink = 'home/'+ navLink;
    this.router.navigate([navLink]);
  }

  navigateBulkAction(navLink:any){
    if(navLink == 'create'){
       this.bulkActionselect='Create';
       this.router.navigate(['home/administration/user-management/bulkaction-create-user']);
    }
    else if(navLink == 'viewlogs')   { 
      this.bulkActionselect='View Logs';      
       this.router.navigate(['home/administration/user-management/bulkaction-view-logs']);
    }    
  }

  navigateEditItem(navLink: any,): void {
    if(navLink == "edit-extension"){
      if(this.extTabSelection==1){
        if(localStorage.hasOwnProperty("fromViewPackage")){
          let pdata:any =localStorage.getItem("fromViewPackage");
          let packageData = JSON.parse(pdata);
          if(packageData.locked==true){
            this.notificationService.showNotification('You cannot edit Package as it is locked.', 'warning', 'Package is Locked');
          } else if(this.editViewExtData.locked == true){
            this.notificationService.showNotification('You cannot edit Package as extension is locked.', 'warning', 'Extension is Locked');
          } else{
            packageData["fromPackageList"] = true;
            localStorage.setItem("packageId", packageData.id);
            localStorage.setItem("extId", packageData.extensionId);
            localStorage.setItem("frmPackageToEditExt", "true");
            localStorage.removeItem("fromViewPackage");
            navLink = 'home/' + navLink;
          }
        }else if(!localStorage.hasOwnProperty("fromViewPackage") && this.editViewExtData.locked == true){
          this.notificationService.showNotification('You cannot edit extension as it is locked.', 'warning', 'Extension is Locked');
        }else{
          navLink = 'home/'+ navLink;
        }
      }
      else if(this.extTabSelection==0 && this.editViewExtData.locked == false){
        navLink = 'home/'+ navLink;
      }
      else{
        this.notificationService.showNotification('You cannot edit extension as it is locked.', 'warning', 'Extension is Locked');
      }
    } else if (navLink != 'home') {
      navLink = 'home/'+ navLink;
    }
    this.router.navigate([navLink]);
  }
  navigateItemsToListsPage(navLink: any,): void {
    navLink = 'home/'+ navLink;
    this.router.navigate([navLink]);
  }

  confirmArchive(){
    this.dialogService.confirmDialog({
      title: 'Confirm Archive',
      module: 'activity-notification',
      message: 'Are you sure you want to archive Last 3 Months Notifications?',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })
  }
  confirmArchiveBulk(){
    this.dialogService.confirmDialog({
      title: 'Confirm Un-archive',
      module: 'activity-notification',
      message: 'Are you sure you want to un-archive Selected Notifications?',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    })
  }
  options: Option[] = [
    {value: '', viewValue: 'CHOOSE DATE'},
    {value: '30-days', viewValue: 'Last 30 Days'},
    {value: '3-months', viewValue: 'Last 3 Months'},
    {value: '6-months', viewValue: 'Last 6 Months'},
  ];

  roles: Roles[] = [
    {value: 'portal-admin', viewValue: 'Portal Admin'},
    {value: 'Ops User', viewValue: 'Ops Ops User'},
    {value: 'sales-user', viewValue: 'Sales Ops User'},
    {value: 'partner-admin', viewValue: 'Partner Admin'},
    {value: 'partner-developer', viewValue: 'Partner Developer'},
  ];

  Events: Events[] = [
    {value: 'partner', viewValue: 'Partner Event'},
    {value: 'user', viewValue: 'User Event'},
    {value: 'customer', viewValue: 'Customer Event'},
    {value: 'workflow', viewValue: 'Workflow Event'},
    {value: 'system', viewValue: 'System Event'},
    {value: 'role', viewValue: 'Role Event'},
  ];

  toggleFilterDrawer(){
    this.isFilterDrawer = !this.isFilterDrawer;
    this.notificationFilter.patchValue({
      byMonths: "",
      start: "",
      end : ""
    })
    this.auditFilter.patchValue({
      byMonths: "",
      start: "",
      end : ""
    })
  }
  notificationFilterList():void{
    if(this.notificationFilter.value.byMonths != ""){
      const cd = new Date();
      this.clearFilterButton = false
      this.isFilterDrawer = !this.isFilterDrawer;
      switch(this.notificationFilter.value.byMonths){
        case "30-days" :
        let list3:any =[]
        let td3:any=cd.setDate(cd.getDate()-30)
        list3=this.globalNotificationList.filter((f:any) => new Date(f.eventDate) > td3 );
        list3.sort((a:any, b:any) => {
          return new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf(); // descending
        })
        this.newGlobalNotifList['filterNotificationList'] = list3
        this.activityNotifyService.emitNotification(this.newGlobalNotifList);
        break;
        case "3-months" :
        let list1:any =[]
        let td:any=cd.setDate(cd.getDate()-90)
        list1=this.globalNotificationList.filter((f:any) => new Date(f.eventDate) > td );
        list1.sort((a:any, b:any) => {
          return new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf(); // descending
        })
        this.newGlobalNotifList['filterNotificationList'] = list1
        this.activityNotifyService.emitNotification(this.newGlobalNotifList);
        break;
        case "6-months" :
        let list2:any =[]
        let td1:any=cd.setDate(cd.getDate()-180)
        list2=this.globalNotificationList.filter((f:any) => new Date(f.eventDate) > td1 );
        list2.sort((a:any, b:any) => {
          return new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf(); // descending
        })
        this.newGlobalNotifList['filterNotificationList'] = list2
        this.activityNotifyService.emitNotification(this.newGlobalNotifList);
        break;
      }
    }else if(this.notificationFilter.value.start != "" && this.notificationFilter.value.end != ""){
      this.clearFilterButton = false
        let betweenDates :any = []
      if(this.notificationFilter.value.start.getDate() == this.notificationFilter.value.end.getDate()){
         betweenDates = this.globalNotificationList.filter((f:any) => new Date(f.eventDate).getDate() == this.notificationFilter.value.start.getDate());
      }else{
        this.notificationFilter.value.end.setDate(this.notificationFilter.value.end.getDate() +1);
        betweenDates = this.globalNotificationList.filter((f:any) => new Date(f.eventDate) >= this.notificationFilter.value.start && new Date(f.eventDate) <= this.notificationFilter.value.end);
        }
      betweenDates.sort((a:any, b:any) => {
        return new Date(b.eventDate).valueOf() - new Date(a.eventDate).valueOf(); // descending
      })
       this.newGlobalNotifList['filterNotificationList'] = betweenDates
       this.activityNotifyService.emitNotification(this.newGlobalNotifList);
       this.isFilterDrawer = !this.isFilterDrawer;
    }

  }
  //remove auditfilterlist method : because of no use
  clearFIlteredList(){
    this.notificationFilter.patchValue({
      byMonths: "",
      start: "",
      end : ""
    })
    this.auditFilter.patchValue({
      byMonths: "",
      start: "",
      end : ""
    })
    this.clearFilterButton = true;
    this.newGlobalNotifList['filterNotificationList']=this.globalNotificationList;
    this.activityNotifyService.emitNotification(this.newGlobalNotifList);
    this.newGlobalAuditList['filterAuditList'] = this.globalAuditList
  }
  clearInputFields(e:string){
    switch(e){
        case 'byMonthsNotif':
          this.start.nativeElement.value = ""
          this.end.nativeElement.value = ""
          this.notificationFilter.patchValue({
            start: "" ,
            end: ""
          })
          break;
        case 'datePickerNotif':
          this.mont.value=null
          this.notificationFilter.patchValue({
            byMonths: ""
          })
          break;
        case 'byMonthsAudit':
          this.start.nativeElement.value = ""
          this.end.nativeElement.value = ""
          this.auditFilter.patchValue({
            start: "" ,
            end: ""
          })
          break;
        case 'datePickerAudit':
          this.mont.value=null
          this.auditFilter.patchValue({
            byMonths: ""
          })
          break;
    }
  }

  toggleSelectionDrawer(){
    this.isSelectionDrawer = !this.isSelectionDrawer;
  }
  confirmAdd(){
    this.selected = !this.selected;
  }

  adduserSelection(): void {
    const dialogRef = this.dialog.open(DialogSelect, {
      width: '400px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Add User Selected');
    });
  }

  customers: any = [
    { name: "Loren Ipsum Customer", email: "loren_ipsum@customer.com" },
    { name: "Loren Ipsum Customer", email: "loren_ipsum@customer.com" },
    { name: "Loren Ipsum Customer", email: "loren_ipsum@customer.com" },
    { name: "Loren Ipsum Customer", email: "loren_ipsum@customer.com" }
  ];

  navigateEditWorkerTask(navLink: any): void {
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/instanceoperations/instance-requests/' + navLink;
    }
    this.router.navigate([navLink]);
  }

}

// Add User Selection Dialog
@Component({
  selector: 'selection-dialog',
  templateUrl: './selection-dialog.html',
  styleUrls: ['./pageheader.component.scss']
})
export class DialogSelect {
  isSelectionDrawer: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogSelect>,
    private router: Router,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  gotoAdduser():void {
    this.router.navigate(['home/user-management/add']);
    this.dialogRef.close();
  }

  addIcertisUser():void {
    this.dialogRef.close();
    this.isSelectionDrawer = !this.isSelectionDrawer;
  }





}
