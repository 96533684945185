<mat-drawer-container class="intiate-certification-container colorred">
    <mat-drawer #drawer class="intiate-certification-drawer" position="end" mode="side">
      <div class="intiate-certification-header">
        <h2><mat-icon class="close-icon">verified_user</mat-icon>{{certificationEnum.certification_Initiate_helpMessage}}</h2>
        <button mat-icon-button (click)="closeDrawer()" aria-label="Close button">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
      <div class="intiate-certification-content">
        <div class="intiate-certification-info">
          <mat-icon class="close-icon">error</mat-icon>
          <p>{{certificationEnum.certification_Initiate_heading}}</p>
        </div>
        <div class="intiate-certification-form">
          <mat-form-field class="pss-block-form pss-input-upload mb-2">
            <mat-label>{{certificationEnum.certification_Initiate_Field_Label_approvalDocuments}}</mat-label>
            <mat-toolbar class="idn-upload-toolbar">
              <input matInput [(ngModel)]="fileAttr" class="input-files" [ngModelOptions]="{standalone: true}" readonly name="name" required/>
              <button mat-flat-button color="primary" class="idn-file-upload">{{certificationEnum.certification_Initiate_Field_Label_Browse_btn}}</button>
            </mat-toolbar>
            <label for="uploadFile" class="idn-off-focus-label">{{certificationEnum.certification_Initiate_Field_Label_fileInput}}</label>
            <input type="file" #fileInput id="uploadFile"  name="uploadFile" accept=".zip"  (change)="uploadFileEvt($event)" required />
            <mat-hint class="idn-mat-hint">
              <span class="material-icons">info</span>
              <span class="message">{{certificationEnum.certification_Initiate_Field_Label_fileSizeMesaage}}</span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="pss-block-form">
            <mat-label>{{certificationEnum.certification_Initiate_Field_Label_noteForApprover}}</mat-label>
            <textarea matInput [(ngModel)]="comments" required></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="intiate-certification-footer">
        <button mat-stroked-button class="submit-btn" color="primary" (click)="submitExceptionalApproval()" [disabled]="(comments == '') || (fileAttr == ('Choose File' || ''))">{{certificationEnum.certification_Initiate_Field_Label_Submit_btn}}</button>
        <button mat-stroked-button class="cancel-btn" (click)="closeDrawer()">{{certificationEnum.certification_Initiate_Field_Label_Cancel_btn}}</button>
      </div>
    </mat-drawer>
</mat-drawer-container>
