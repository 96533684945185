import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { enableDisableIDNFeatures } from 'src/app/shared/enum/disable-idn-feature';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  constructor(private http: HttpClient) {}
  public extSubject = new BehaviorSubject<any>('');
  private subject = new BehaviorSubject<any>("");
  public esignSubject = new BehaviorSubject<any>('');
  public getUpdatedApprovalCount = new BehaviorSubject<any>('');
   getUpdatedApprovalCountEvent$ = this.getUpdatedApprovalCount.asObservable()
  public getInstance(
    filterSearchValue: string,
    sortingColumn: string,
    pageNumber: number,
    pageSize: number,
    status: string
  ) {
    return this.http.get(this.baseApiUrl + 'api/Instance/GetInstanceRequests', {
      params: {
        filter: filterSearchValue,
        sortBy: sortingColumn,
        pageNumber: pageNumber,
        pageSize: pageSize,
        status: status,
      },
      headers: this.headers,
    });
  }

  public getReferenceData(inputParam:any) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetInstanceRequestReferenceData',
      { params: { referenceType: inputParam }, headers: this.headers }
    );
  }

  public postDataUrl(
    url: string,
    paraData?: any,
    paraBody?: any
  ): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, {
      headers: this.headers,
      params: paraData,
      observe: 'response',
    });
  }

  public putDataUrl(
    url: string,
    paraData?: any,
    paraBody?: any
  ): Observable<any> {
    return this.http.put(this.baseApiUrl + url, paraBody, {
      headers: this.headers,
      params: paraData,
      observe: 'response',
    });
  }

  public getInstanceByID(id: any) {
    return this.http.get(this.baseApiUrl + 'api/Instance/' + id, {
      headers: this.headers,
      observe: 'response',
    });
  }

  public checkDuplicateInstance(cname: any) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/CheckIfInstanceRequestNameExists',
      { params: { name: cname }, headers: this.headers }
    );
  }

  public getBlobUploadConnection(id: any) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetBlobUploadConnection',
      { params: { id: id }, headers: this.headers }
    );
  }

  public getBlobDownloadConnection(id: any, filename: any) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetBlobDownloadConnection',
      { params: { id: id, filename: filename }, headers: this.headers }
    );
  }

  public getBlobDownloadConnectionLog(id: any) {
    return this.http.get(
      this.baseApiUrl +
        'api/Instance/' +
        id +
        '/GetInstanceLogDownloadConnection',
      { headers: this.headers }
    );
  }

  public GetDeploymentLogDownloadConnection(id: any) {
    return this.http.get(
      this.baseApiUrl +
        'api/Deployment/' +
        id +
        '/GetDeploymentLogDownloadConnection',
      { headers: this.headers }
    );
  }
  public GetOrchestratedPackageLogDownloadConnection(id: any) {
    return this.http.get(
      this.baseApiUrl +
        'api/Deployment/' +
        id +
        '/GetOrchestrationPackage',
      { headers: this.headers }
    );
  }

  public submitForApproval(id: any): Observable<any> {
    return this.http.put(
      this.baseApiUrl + 'api/Instance/SubmitForApproval',
      {},
      { params: { id: id }, headers: this.headers, observe: 'response' }
    );
  }

  public approveInstance(data: any): Observable<any> {
    return this.http.put(
      this.baseApiUrl + 'api/Instance/CloudOpsApprove',
      data,
      { headers: this.headers, observe: 'response' }
    );
  }

  public rejectInstance(data: any): Observable<any> {
    return this.http.put(
      this.baseApiUrl + 'api/Instance/CloudOpsReject',
      data,
      { headers: this.headers, observe: 'response' }
    );
  }

  public getAllDeployements(
    filter: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number,
    status: string,
    startDate: string,
    endDate: string
  ) {
    return this.http.get(this.baseApiUrl + 'api/Deployment', {
      params: {
        filter: filter,
        sortBy: sortBy,
        pageNumber: pageNumber,
        pageSize: pageSize,
        status: status,
        deploymentDateFrom: startDate,
        deploymentDateTo: endDate,
      },
      headers: this.headers,
    });
  }

  public getDeploymentByID(id: any) {
    return this.http.get(this.baseApiUrl + 'api/Deployment/' + id, {
      headers: this.headers,
      observe: 'response',
    });
  }

  public getDeploymentReferenceData() {
    return this.http.get(
      this.baseApiUrl + 'api/Deployment/GetDeploymentRequestReferenceData',
      { headers: this.headers, observe: 'response' }
    );
  }

  public getSolutionPackageById(
    id: any,
    instanceId: any,
    multiTenantPodMajor: number,
    multiTenantPodMinor: number,
    multiTenantPodPatch: number
  ) {
    return this.http.get(
      this.baseApiUrl + 'api/Deployment/GetSolutionPackageByExtensionId',
      {
        params: {
          extensionId: id,
          instanceTypeId: instanceId,
          multiTenantPodMajor: multiTenantPodMajor,
          multiTenantPodMinor: multiTenantPodMinor,
          multiTenantPodPatch: multiTenantPodPatch,
        },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public requestForDeployment(data: any) {
    return this.http.post(
      this.baseApiUrl + 'api/Deployment/CreateDeploymentRequest',
      data,
      { headers: this.headers, observe: 'response' }
    );
  }

  public approveDeployment(data: any): Observable<any> {
    return this.http.put(
      this.baseApiUrl + 'api/Deployment/CloudOpsApprove',
      data,
      { headers: this.headers, observe: 'response' }
    );
  }

  public rejectDeployment(data: any): Observable<any> {
    return this.http.put(
      this.baseApiUrl + 'api/Deployment/CloudOpsReject',
      data,
      { headers: this.headers, observe: 'response' }
    );
  }

  public getExtensionName(
    customerId: any,
    extensionTypeId: any
  ): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/Deployment/GetExtensionsByExtensionTypeId',
      {
        params: { customerId: customerId, extensionTypeId: extensionTypeId },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public getSkuByCustomerID(
    customerId: number,
    multiTenantPodMajor: number,
    multiTenantPodMinor: number
  ) {
    return this.http.get(
      this.baseApiUrl + 'api/Deployment/GetSKUsbyCustomerIdAsync',
      {
        params: {
          customerId: customerId,
          multiTenantPodMajor: multiTenantPodMajor,
          multiTenantPodMinor: multiTenantPodMinor
        },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public GetInstanceTypeByCustomerId(customerId: any) {
    return this.http.get(
      this.baseApiUrl + 'api/instance/GetInstanceTypeByCustomerId',
      {
        params: { customerId: customerId },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public GetUsersByCustomerIdAndPartners(customerId: any,partners:any, instanceId:any=0) {
    return this.http.get(
      this.baseApiUrl + `api/instance/GetUsersByCustomerIdAndPartners?customerId=${customerId}&partnerIds=${partners}&instanceId=${instanceId}`,
      {
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public getIcmVersions(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Instance/ICIVersions', {
      headers: this.headers,
      observe: 'response',
    });
  }

  public getMultiTenantPod(
    regionId: number,
    subscriptionId: number
  ): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Instance/MultiTenantPODs', {
      params: { regionId: regionId, subscriptionId: subscriptionId },
      headers: this.headers,
      observe: 'response',
    });
  }

  //ICI tools changes
  public downloadApplicationLog(
    instanceID?: any,
    paraData?: any
  ): Observable<any> {
      return this.http.post(
        this.baseApiUrl + 'api/ICITool/GetICITenantApplicationLogs',
        paraData,
        {
          params: { instanceID: instanceID },
          headers: this.headers,
        }
      );  
  }

  public workerTaskLogs(instanceID?: any, paraData?: any): Observable<any> {
      return this.http.post(
        this.baseApiUrl + 'api/ICITool/GetICITenantWorkerTaskLogs',
        paraData,
        {
          params: { instanceId: instanceID, logStartDate: paraData.logStartDate },
          headers: this.headers,
        }
      );
    
   
  }

  public InstanceBackup(instanceID?: any, paraData?: any): Observable<any> {
    return this.http.post(
      this.baseApiUrl + `api/ICITool/InstanceBackup?instanceId=${instanceID}`,
      paraData,
      {
        headers: this.headers,
        observe: 'response'
      }
    ); 
  }

  public PerformRoleRestart(instanceID?: any, addInRoles?: any): Observable<any> {
      return this.http.post(
        this.baseApiUrl + 'api/ICITool/ICITenantRoleRestart?instanceId=' + instanceID, addInRoles,
        { headers: this.headers, observe: 'response' }
      );
  }
  public PerformTaskRestart(instanceID?: any): Observable<any> {
      return this.http.post(
        this.baseApiUrl +
          'api/ICITool/RestartTaskService?instanceId=' +
          instanceID,
        { headers: this.headers, observe: 'response' }
      );
 
  }
  public PerformRedisFlush(instanceID?: any): Observable<any> {
      return this.http.post(
        this.baseApiUrl + 'api/ICITool/FlushICITenantRedisCache?instanceId=' + instanceID,
        { headers: this.headers, observe: 'response' }
      );
   
  }

  public eSignProviderICIToolHistoryLog(instanceID: any, providerName:any): Observable<any> {
    return this.http.post(
      this.baseApiUrl + `api/ICITool/ESignProviderICIToolHistoryLog?instanceId=${instanceID}&providerName=${providerName}`,
      {
        headers: this.headers,
        observe: 'response' 
      }
    );
 
}

public gifTroubleShooterICIToolHistoryLog(paramData: any): Observable<any> {
  return this.http.post(
    this.baseApiUrl + `api/ICITool/GIFTroubleShooterICIToolHistoryLog`, paramData,
    {
      headers: this.headers,
      observe: 'response' 
    }
  );

}

public validateGIFDetails(paramData: any): Observable<any> {
  return this.http.post(
    this.baseApiUrl + `api/ICITool/ValidateGIFDetails`, paramData,
    {
      headers: this.headers,
      observe: 'response' 
    }
  );
}

public gifExportExcel(paramData: any): Observable<any> {
  return this.http.post(this.baseApiUrl + `api/ICITool/GIFTroubleShooterICIToolHistoryLog`, paramData, 
  { headers: this.headers, observe: 'response' });
}

  public downloadDatabaseLog(
    instanceID?: any,
    paraData?: any
  ): Observable<any> {
      return this.http.post(this.baseApiUrl + 'api/ICITool/GetICITenantDbLogs', paraData, {
        params: { instanceID: instanceID },
        headers: this.headers,
        responseType: 'arraybuffer',
      });
   
  }

  public ICIToolList(instanceId:any,pageNumber:any,pageSize:any,requestType?:any,status?:any) {
    return this.http.get(
      this.baseApiUrl + 'api/ICITool/List',
      {
        params: { instanceId:instanceId ,pageNumber:pageNumber,pageSize:pageSize,requestType:requestType,status:status },
        headers: this.headers,
        observe: 'response',
      }
    );
  }


  public GetCustomersByPartnerId(partnerId: any) {
    return this.http.get(
      this.baseApiUrl + 'api/instance/GetCustomersByPartnerId',
      {
        params: { partnerId: partnerId },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public getInstanceConfigUploadConnection(id: any) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetInstanceConfigUploadConnection',
      { params: { id: id }, headers: this.headers }
    );
  }

  public getInstanceConfigDownloadConnection(
    id: any,
    filename: any,
    isSampleConfig: any
  ) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetInstanceConfigDownloadConnection',
      {
        params: { id: id, filename: filename, isSampleConfig: isSampleConfig },
        headers: this.headers,
      }
    );
  }

  public getInstanceConfigFileContent(id: any, filename: any) {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetInstanceConfigFileContent',
      { params: { id: id, filename: filename }, headers: this.headers }
    );
  }

  public GetInstanceTags() {
    return this.http.get(
      this.baseApiUrl + 'api/Instance/GetInstanceTags',
      { headers: this.headers }
    );
  }

  public MergeTenantConfigkeys(paraData?: any) {
    return this.http.post(
      this.baseApiUrl + 'api/Orchestration/MergeTenantConfigkeys', paraData,
      { headers: this.headers, observe: 'response' }
    );
  }

  public UploadInstanceConfig(paraData?: any): Observable<any> {
    return this.http.post(
      this.baseApiUrl + 'api/Instance/UploadInstanceConfig',
      paraData,
      { headers: this.headers, observe: 'response' }
    );
  }

  public getInstanceICIUsers( customerId: any, instanceId:any, filter:any, sortBy?:any, pageNumber?:any, pageSize?:any, isProvisionStatus?:any, userCategoryFilter?:any, userTypeFilter?: any): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/ProvisionICIUser/GetInstanceICIUsers?customerId='+customerId+"&instanceId="+instanceId, { params:{
        filter:filter,
        sortBy:sortBy,
        pageNumber: pageNumber,
        pageSize: pageSize,
        isProvisionStatus: isProvisionStatus,
        userCategoryId: userCategoryFilter? Number(userCategoryFilter) : 0,
        userType: userTypeFilter? Number(userTypeFilter): '' },headers: this.headers, observe: 'response'}
    );
  }

  public provisionUserInICI(
    instanceId?: any,
    userId?: any
  ): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/ProvisionICIUser/ProvisionUserInICI?instanceId='+instanceId+'&userId='+userId, {
      headers: this.headers,
      observe: 'response',
    });
  }

  public makeCLMAdmin(
    instanceId: any,
    userId: any
  ): Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/ProvisionICIUser/makeCLMAdmin?instanceId='+instanceId+'&userId='+userId, {
      headers: this.headers,
      observe: 'response',
    });
  }
  public createTask(data?:any, instanceId?:any, partnerId?:any, customerId?:any):Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/IciTools/WorkerTaskEntriesInICI', data, { params: { instanceID: instanceId, partnerId:partnerId, customerId:customerId }, observe: 'response' });
  }

  public getWorkerTaskConfigurationListData(cname:any, pageNumber:any, pageSize:any, sortBy?:any, taskType?:any, status?:any): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/IciTools/GetWorkerTaskEntries', { params: {
        tenantCName: cname,
        pageNumber: pageNumber,
        pageSize: pageSize,
        sortBy:sortBy,
        status: status,
        taskType: taskType === 0? 'EventBased' : taskType === 1? 'Scheduled' : '',
      }, headers: this.headers, observe: 'response'}
    );
  }
  public getAllWorkerTask(cname:any): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/IciTools/GetAllWorkerTask', { params: {
        tenantCName: cname
      }, headers: this.headers, observe: 'response'}
    );
  }

  // get workertask information by id
  public getIciWorkerTaskConfigurationById(cname:any, id:any): Observable<any> {
    return this.http.get(
      this.baseApiUrl + `api/IciTools/GetWorkerTaskById`,{ params:{tenantCName :cname, taskId: id}, headers: this.headers, observe: 'response'}
    );
  }

  //updating the workerTask entry
  public updateTask(data?:any, instanceId?:any, partnerId?:any, customerId?:any):Observable<any> {
    return this.http.post(this.baseApiUrl + 'api/IciTools/WorkerTaskEntriesInICI', data, { params: { instanceID: instanceId, partnerId:partnerId, customerId:customerId }, observe: 'response' });
  }
  
   //Ativating/Enabling the workerTask entry
   public EnableTask(id:any):Observable<any> {
    return this.http.put(this.baseApiUrl + `api/IciTools/ActiveICIWorkerTaskById/${id}`, { headers: this.headers, observe: 'response' });
  }

  //Deativating/disabling the workerTask entry
  public DisableTask(id:any):Observable<any> {
    return this.http.put(this.baseApiUrl + `api/IciTools/DeactiveICIWorkerTaskById/${id}`, { headers: this.headers, observe: 'response' });
  }

  //Ativating/Deativating the workerTask entry
  public ActivateDeactivateWorkerTask(taskId:any, tenantCName:any , isActivateFlag:boolean):Observable<any> {
    return this.http.post(this.baseApiUrl + `api/IciTools/ActivateDeactivateWorkerTask?taskId=${taskId}&tenantCName=${tenantCName}&isActivateFlag=${isActivateFlag}`, { headers: this.headers, observe: 'response' });
  }
  
   //Save instance approval details as draft.
   public saveInstanceApprovalDetailsAsDraft(data:any):Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Instance/SaveInstanceApprovalDetailsAsDraft', data, { headers: this.headers, observe: 'response' });
  }
  
  sendData(data:any){
    this.subject.next(data);
  }

  receiveData():Observable<any>{
    return this.subject.asObservable();
  }
  
  //To get last run details of ICI tools
  public ICIToolRequestHistory( instanceID?: any): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/ICITool/ICIToolsHistory?instanceId='+instanceID, { headers: this.headers, observe: 'response'}
    );
  }

  //Approve deployment Api Call.
  public approveDeploymentRequest(data:any):Observable<any> {
    return this.http.post(this.baseApiUrl + `api/Deployment/ApproveDeploymentRequest`, data , { headers: this.headers, observe: 'response' });
  }

  //Reject deployment Api Call.
  public rejectDeploymentRequest(data:any):Observable<any> {
    return this.http.post(this.baseApiUrl + `api/Deployment/RejectDeploymentRequest`, data , { headers: this.headers, observe: 'response' });
  }

  public InstanceConfigHistoryById(instanceId:any,filter: any, sortBy:any, pageNumber:any,pageSize:any) {
    return this.http.get(
      this.baseApiUrl + 'api/ICITool/InstanceConfigHistoryById',
      {
        params: { instanceId:instanceId, filter:filter, sortBy:sortBy, pageNumber:pageNumber,pageSize:pageSize },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public getInstanceandProviderDetail(instanceName:any, version:any): Observable<any> {
    return this.http.get(
      this.baseApiUrl + 'api/ICITool/EsignProviderDetails', { params: {
        instanceCname: instanceName,
        iciVersion: version,
      }, headers: this.headers, observe: 'response'}
    );
  }

   //Reject deployment Api Call.
   public sendEnvelope(data:any):Observable<any> {
    return this.http.post(this.baseApiUrl + `api/ICITool/SendEnvelope`, data , { headers: this.headers, observe: 'response' });
  }

  public esignExportExcel(instanceId:any, providerName:any, iciVersion:any):Observable<any> {
    return this.http.post(this.baseApiUrl + `api/ICITool/ESignExportExcelICIToolHistoryLog?instanceId=${instanceId}&providerName=${providerName}&iciVersion=${iciVersion}`, 
    { headers: this.headers, observe: 'response' });
  }

  sendEsignData(data:any){
    this.esignSubject.next(data);
  }

  receiveEsignData():Observable<any>{
    return this.esignSubject.asObservable();
  }

  getUpdatedCount(count:any){
    this.getUpdatedApprovalCount.next(count);
  }

  getToolsAccessRequestApprovalPendingCount():Observable<any>{
    return this.http.get(
      this.baseApiUrl + 'api/ToolsFeatureAccessRequest/ToolsAccessRequestApprovalPendingCount', { headers: this.headers, observe: 'response'}
    );
  }

  getFeatureAccessRequestsList(searchText?:any, requestType?:any, status?:any, pageNumber?:any, pageSize?:any, sortBy?:any){
    return this.http.get(
      this.baseApiUrl + 'api/ToolsFeatureAccessRequest/GetFeatureAccessRequestsList', { params:{'filter':searchText? searchText : '','requestType':requestType? requestType:'','status':status?status:'', 'pageNumber':pageNumber?pageNumber:'','pageSize':pageSize?pageSize:'', 'sortBy':sortBy?sortBy:''}, headers: this.headers, observe: 'response'}
    );
  }

  viewFeatureAccessRequest(requestId:any){
    return this.http.get(
      this.baseApiUrl + `api/ToolsFeatureAccessRequest/viewFeatureAccessRequest?Id=${requestId}`, { headers: this.headers, observe: 'response'}
    );
  }

  ApproveRejectFeatureAccessRequest(paramData:any){
    return this.http.post(this.baseApiUrl + `api/ToolsFeatureAccessRequest/ApproveRejectFeatureAccessRequest`, paramData , { headers: this.headers, observe: 'response' });
  }

  getInstanceToolsFeaturesList(){
    return this.http.get(
      this.baseApiUrl + 'api/ToolsFeatureAccessRequest/InstanceToolsFeaturesList', { headers: this.headers, observe: 'response'}
    ); 
  }

  FeatureAccessRequest(paramData:any){
    return this.http.post(this.baseApiUrl + `api/ToolsFeatureAccessRequest/FeatureAccessRequest`, paramData , { headers: this.headers, observe: 'response' });
  }

  public getHangfireDashboard(id: any, url?:any) {
    if(url){
      return this.http.get(
        this.baseApiUrl + 'api/Hangfire/GetHangfireDashboard',
        { params: { instanceId: id , url:url}, headers: this.headers }
      );
    } else{
      return this.http.get(
        this.baseApiUrl + 'api/Hangfire/GetHangfireDashboard',
        { params: { instanceId: id }, headers: this.headers}
      );
    }

  }

  async getInstanceHealth(instanceName:any){
    return this.http.get(this.baseApiUrl + `api/Instance/InstanceHealth?cname=${instanceName}`, { headers: this.headers });
  }
}
