import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { PartnerService } from 'src/app/core/services/partner-management/partner.service';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PartnerMgmtStaticString } from 'src/app/shared/enum/partnerManagement';
@Component({
  selector: 'app-view-partner',
  templateUrl: './view-partner.component.html',
  styleUrls: ['./view-partner.component.scss']
})
export class ViewPartnerComponent implements OnInit {
  ViewData: any = { 'code': '', 'name': '', 'description': '', 'categoryId': '', 'active': '' };
  public PartnerMgmtEnum = PartnerMgmtStaticString;
  $subs!: Subscription;
  UserId: any;
  isTrueSet: any = true;
  IsActive: boolean = false;
  isUserAssociate: any = false;
  createPartner: any;
  updatePartner: any;
  dectivatePartner: any;
  activatePartnerPermission: any;

  constructor(
    private sharedata: ShareDataService,
    private router: Router,
    private dialogService: DialogService,
    private partnerService: PartnerService,
    private notificationService: NotificationService,
    private permssionService: PermissionsService
  ) { }

  ngOnDestroy(): void {
    this.$subs.unsubscribe();
  }

  ngOnInit(): void {
    this.createPartner = this.permssionService.getPermission('CreatePartner');
    this.updatePartner = this.permssionService.getPermission('UpdatePartner');
    this.dectivatePartner = this.permssionService.getPermission('DeactivatePartner');
    this.activatePartnerPermission = this.permssionService.getPermission('ActivatePartner');
    this.$subs = this.sharedata.receiveData().subscribe(data => {
      if (data) {
        let json = JSON.parse(data);
        if (json.id != null && json.id != undefined && json.id > 0) {
          this.ViewData = json;
          this.UserId = json.id;
          if (json.status) {
            this.IsActive = false;
          } else {
            this.IsActive = true;
          }
          this.UserId = json.id;
          localStorage.setItem('partner-user-id', this.UserId);
        }
      } else {
        this.UserId = localStorage.getItem('partner-user-id');
        this.partnerService.GetPartnerById(this.UserId).subscribe((res) => {
          if(res != null && res != undefined) {
            this.ViewData = res.body;
          }
        });
      }
    });
    if (this.ViewData.status) {
      this.IsActive = false;
    }
    else {
      this.IsActive = true;
    }

    localStorage.setItem("roleId", this.ViewData.id);
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/partner-management" },
    { label: "Partner Management", path: "administration/partner-management" },
    { label: "View", path: "administration/partner-management/view" },
  ];

  async deactivatePartner() {
    this.isUserAssociate = await this.checkPartnerAssociateWithCustomer(this.UserId);
    if (this.isUserAssociate.status == 200) {
        this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_linked_body, 'error', this.PartnerMgmtEnum.partner_notify_linked_heading);
        this.ngOnInit();
      }
      else {
        this.dialogService.confirmDialog({
          title: this.PartnerMgmtEnum.partner_dialog_deactivate_heading,
          module: 'partner',
          message: this.PartnerMgmtEnum.partner_dialog_deactivate_body,
          confirmText: this.PartnerMgmtEnum.partner_dialog_deactivate_primary_btn,
          cancelText: this.PartnerMgmtEnum.partner_dialog_cancel_btn
        }).subscribe(res => {
          if (res) {
            this.partnerService.deActivatePartner(this.UserId, false).subscribe((res: Response) => {
              let Result: any = res.body;
              if (res.status == 200) {
                this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_deactivated_body, 'success', this.PartnerMgmtEnum.partner_notify_deactivated_heading);
                this.router.navigate(["home/administration/partner-management"]);
              } else {
                this.notificationService.showNotification(Result.message, 'error', this.PartnerMgmtEnum.partner_notify_not_deactivated_heading);
                this.router.navigate(["home/administration/partner-management"]);
              }
              // this.GetPartners();
            })
          }
        })
      }
    }

  //To check User Associate With Role
  async checkPartnerAssociateWithCustomer(id: number): Promise<any> {
    if (id != null && id != undefined && id > 0) {
      return await lastValueFrom(this.partnerService.CheckPartnerAssociateWithCustomer(id));
    }
    else {
      this.router.navigate(["home/administration/partner-management"]);
    }
  }

  //To Activate Role Status
  activatePartner() {
    this.dialogService.confirmDialog({
      title: this.PartnerMgmtEnum.partner_dialog_activate_heading,
      module: 'partner',
      message: this.PartnerMgmtEnum.partner_dialog_activate_body,
      confirmText: this.PartnerMgmtEnum.partner_dialog_activate_primary_btn,
      cancelText: this.PartnerMgmtEnum.partner_dialog_cancel_btn
    }).subscribe((res: any) => {
      if (res) {
        this.partnerService.activatePartner(this.UserId, true).subscribe((res: Response) => {
          let Result: any = res.body;
          if (res.status == 200) {
            this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_activated_body, 'success', this.PartnerMgmtEnum.partner_notify_activated_heading);
            this.router.navigate(["home/administration/partner-management"]);
          } else {
            this.notificationService.showNotification(Result.message, 'warning', this.PartnerMgmtEnum.partner_notify_not_activated_heading);
            this.router.navigate(["home/administration/partner-management"]);
          }
          // this.GetPartners();
        })
      }
    })
  }

  onCancel() {
    this.router.navigate(["home/administration/partner-management"]);
  }
}
