export enum CertificationManagement {

    /*CERTIFICATION APPROVER DETAILS */
    certification_approver_heading = "Extension :",
    certification_approver_Field_packageName = "Package Name",
    certification_approver_Field_projectVersion = "Package Version",
    certification_approver_Field_customerName = "Customer Name",
    certification_approver_Field_projectType = "Project Type",
    certification_approver_Field_status = "Status",
    certification_approver_Field_requestorDetails = "Requester Details",
    certification_approver_Field_lastUpdateDate = "Last Update Date",
    certification_approver_Field_packageDescription = "Package Description",
    certification_approver_Field_packageFile = "Package File",
    certification_approver_Field_exceptionApproval = "Exceptional Approval",
    certification_approver_Field_requestorNote = "Requester Note",
    certification_approver_Field_CertificationCheckList_heading = "Certification Check List",
    certification_approver_Field_downloadFiles = "Download Files",
    certification_approver_Field_initiateReview_btn = "Initiate Review",
    certification_approver_Field_completeReview_btn = "Complete Review",
    certification_approver_Field_cancel_btn = "Cancel",
    certification_approver_Field_slaStatus = "SLA Status",
    certificationSlaStatus_expired = 'Expired',
    certificationSlaStatus_expiringSoon = 'Expiring Soon',
    certificationSlaStatus_onTrack = 'On Track',
    certificationSlaStatus_met ='Met',
    certificationSlaStatus_missed ='Missed',
    /*CERTIFICATION DETAILS */
    certification_Details_Field_packageName = "Package Name",
    certification_Details_Field_projectVersion = "Package Version",
    certification_Details_Field_customerName = "Customer Name",
    certification_Details_Field_projectType = "Project Type",
    certification_Details_Field_extensionType = "Extension Type",
    certification_Details_Field_status = "Status",
    certification_Details_Field_requestorDetails = "Requester Details",
    certification_Details_Field_lastUpdateDate = "Last Update Date",
    certification_Details_Field_packageDescription = "Package Description",
    certification_Details_Field_packageFile = "Package File",
    certification_Details_Field_exceptionApproval = "Exceptional Approval",
    certification_Details_Field_requestorNote = "Requester Note",
    certification_Details_Field_CertificationCheckList_heading = "Certification Check List",
    certification_Details_Field_downloadFiles = "Download Files",
    certification_Details_Field_initiateManual_btn = "Initiate Manual Certification",
    certification_Details_Field_completeReview_btn = "Complete Review",
    certification_Details_Field_cancel_btn = "Cancel",
    certification_Details_Field_passed = "Passed",
    certification_Details_Field_failed = "Failed",
    certification_Details_createdBy = "Created By",
    certification_Details_approverDetails = "Approver Details",
    certification_Details_Status = "Status",
    certification_Details_approverDetailssummary = "Approval details Summary",
    certification_details_veracodeResubmit_success_header = "Vulnerability scan initiated",
    certification_details_veracodeResubmit_success_body = "The vulnerability scan is now in progress.",
    certification_details_veracodeResubmit_error_header = "Vulnerability scan interrupted",
    certification_details_veracodeResubmit_error_body = "The vulnerability scan could not be started. Try again.",
    certification_details_veracodeResubmit_msg = "Scan Attempts Remaining: ",
    certification_details_veracodeResubmit_second_Attempt = ". Beyond this limit, a manual scan is required.",
    certification_details_veracodeDownload_heading = 'Vulnerability Report Downloaded',
    certification_details_veracodeDownload_body = 'Vulnerability Report has been downloaded',
    certification_details_veracodeDownload_failed_heading = 'Vulnerability Report Failed to download',
    certification_details_veracodeDownload_failed_body = 'Failed to download Vulnerability Report',
    /*CERTIFICATION APPROVER LIST */
    certification_approver_List_search_label = "Search by Package name or Requester name",
    certification_approver_List_search_partner_label = "Partner",
    certification_approver_List_search_extension_label = "Extension Type",
    certification_approver_List_Field_partnerName = "Partner Name",
    certification_approver_List_Field_extensionType = "Extension Type",
    certification_approver_List_Field_packageName = "Package Name",
    certification_approver_List_Field_projectVersion = "Package Version",
    certification_approver_List_Field_customerName = "Customer Name",
    certification_approver_List_Field_projectType = "Project Type",
    certification_approver_List_Field_status = "Status",
    certification_approver_List_Field_requestorDetails = "Requester Details",
    certification_approver_List_Field_lastUpdateDate = "Last Update Date",
    certification_approver_List_Field_packageDescription = "Package Description",
    certification_approver_List_Field_packageFile = "Package File",
    certification_approver_List_Field_exceptionApproval = "Exceptional Approval",
    certification_approver_List_Field_requestorNote = "Requester Note",
    certification_approver_List_Field_CertificationCheckList_heading = "Certification Check List",
    certification_approver_List_Field_downloadFiles = "Download Files",
    certification_approver_List_Field_initiateReview_btn = "Initiate Review",
    certification_approver_List_Field_completeReview_btn = "Complete Review",
    certification_approver_List_Field_cancel_btn = "Cancel",
    certification_approver_List_Field_withdraw_request = "Withdraw Request",
    certification_approver_List_Field_Resubmit_vulnerability_scan = "Resubmit for Vulnerability Scan",


    /*CERTIFICATION CHECKLIST LIST */
    certification_checkList_List_heading1 = "Detailed checklist submitted by the requester while initiating certification",
    certification_checkList_List_heading2 = "Certification Checklist",

    /* CERTIFICATION LIST */
    certification_List_Header_requestInstance_btn = "Request Certification",
    certification_List_Header_extensionName = "Extension Name",
    certification_List_Header_packageName = "Package Name",
    certification_List_Header_requestorName = "Requester Name",
    certification_List_Header_projectType = "Project Type",
    certification_List_Header_status = "Status",
    certification_List_Header_actions = "Actions",
    certification_List_searchField = "Search by Name or ....",
    certification_List_partnerField = "Partner",
    certification_List_extensionField = "Extension Type",
    certification_List_ActionLabel_dowloadFile_btn = "Download files",
    certification_List_Field_initiateManual_btn = "Initiate Manual Certification",
    certification_List_Field_ExceptionApproval = " (Exception Approval)",
    

    /* CERTIFICATION Download Files */
    certification_download_Header_helpMessage = "Following are the list of documents available for download. ",
    certification_download_FieldLabel_extenionName = "Files",
    certification_download_FieldLabel_packageName = "Select All",
    certification_download_FieldLabel_report = "Report",
    certification_download_FieldLabel_downloadFiles = "Download Files",

    /*CERTIFICATION REQUEST VIEW */
    certification_Request_View_Field_packageName = "Extension",
    certification_Request_View_Field_packageVersion = "Package Name",
    certification_Request_View_Field_projectType = "Package Version",
    certification_Request_View_Field_partnerName = "Partner Name",
    certification_Request_View_Field_status = "Status",
    certification_Request_View_Field_requestDate = "Requester Details",
    certification_Request_View_Field_lastUpdateDate = "Last Update Date",
    certification_Request_View_Field_packageDescription = "Package Description",
    certification_Request_View_Field_packageFile = "Package File",
    certification_Request_View_Field_exceptionApproval = "Exceptional Approval",
    certification_Request_View_Field_requestorNote = "Requester Note",
    certification_Request_View_Field_downloadFiles = "Download Files",
    certification_Request_View_Field_initiateReview_btn = "Initiate Review",
    certification_Request_View_Field_cancel_btn = "Cancel",
    certification_Request_View_Field_completeReview_btn = "Complete Review",

    /*CERTIFICATION UPDATE */
    certification_Update_helpMessage = "Please update the comments for all the questions in the checklist and the approval comments before you can approve or reject the certification request.",
    certification_Update_Field_packageName = "Package Name",
    certification_Update_Field_projectVersion = "Package Version",
    certification_Update_Field_partnerName = "Partner Name",
    certification_Update_Field_customerName = "Customer Name",
    certification_Update_Field_requesterDetails = "Requester Details",
    certification_Update_Field_exceptionApproval = "Exceptional Approval",
    certification_Update_Field_projectType = "Project Type",
    certification_Update_Field_packageDescription = "Package Description",
    certification_Update_Field_certificationCheckList = "Certification Checklist",
    certification_Update_Field_Comments = "Comments",
    certification_Update_Field_overallComment = "Overall Comments",
    certification_Update_Field_approverComment = "Approver Comments",
    certification_Update_Field_fileInput = "File Input",
    certification_Update_Field_approvalDocuments = "Approval Documents",
    certification_Update_helpMessage_p1 = "Approval details summary ",
    certification_Update_helpMessage_p2 = " note for approver",
    certification_Update_Field_approve_btn = "Approve",
    certification_Update_Field_browse_btn = "Browse",
    certification_Update_Field_reject_btn = "Reject",
    certification_Update_Field_cancel_btn = "Cancel",
    certification_Update_HintMessage = "Note: Adding comments for each question in the checklist is mandatory for completing the approval process.",
    certification_Update_HelpMessage_fileSize = "You can upload *.zip Email communication and max file limit is 5 MB.",
    certification_Update_confirm_heading = "Confirm Action",
    certification_Update_body = "Are you sure you want to {}?",
    certification_Update_primary_btn = 'Yes',
    certification_Update_secondary_btn = 'No',

    /*INITIATE CERTIFICATION */
    certification_Initiate_helpMessage = "Submit Exception Approval",
    certification_Initiate_heading = "To proceed with Manual Certification request please attach email communication ZIP to provide extra information to the Certification Approver",
    certification_Initiate_Field_Label_approvalDocuments = "Approval Documents",
    certification_Initiate_Field_Label_Browse_btn = "Browse",
    certification_Initiate_Field_Label_fileInput = "File Input",
    certification_Initiate_Field_Label_fileSizeMesaage = "File type should be *.ZIP. The zip file can contain the emails as the attachment of maximum 5 MB.",
    certification_Initiate_Field_Label_noteForApprover = "Note For Approver",
    certification_Initiate_Field_Label_Submit_btn = "Submit",
    certification_Initiate_Field_Label_Cancel_btn = "Cancel",

    /* NOTIFICATION MESSAGE AND HEADING */
    certification_notify_notvalid_msg = "Please submit all required fields.",
    certification_notify_certificationRequestFailed_heading = "Certification request failed",
    certification_notify_certificationRequestFailed_body_p1 = "Certification request for package",
    certification_notify_certificationRequestFailed_body_p2 = "failed",
    certification_notify_certificationRequest_heading = "Certification Request Submitted",
    certification_notify_certificationRequest_body_p1 = "The certification request for package ",
    certification_notify_certificationRequest_body_p2 = " was successfully submitted for approval.",

    certification_notify_packageDownload_heading = "Package Downloaded",
    certification_notify_packageDownload_body = "Package has been downloaded.",
    certification_notify_packageFailedDownload_heading = "Package Failed to Download",
    certification_notify_packageFailedDownload_body = "Failed to download Package.",

    certification_notify_manualCertificationFailed_heading = "Manual Certification Failed",
    certification_notify_manualCertificationFailed_body_p1 = "Manual Certification Requested for package ",
    certification_notify_manualCertificationFailed_body_p2 = " failed",

    certification_notify_manualCertificationRequested_heading = "Manual Certification Requested",
    certification_notify_manualCertificationRequested_body_p1 = "Manual Certification Requested for package ",
    certification_notify_manualCertificationRequested_body_p2 = " submitted for approval",

    certification_notify_certificationDownloadFailed_heading = "Certification Download Failed",
    certification_notify_certificationDownloadFailed_body = "Failed to download Certification File ",

    certification_notify_certificationDownloaded_heading = "Certification Downloaded",
    certification_notify_certificationDownloaded_body = "Certification file has been downloaded.",

    certification_notify_fileexceed_heading = "File size exceeded",
    certification_notify_fileexceed_body = "File size is more than 5 MB",

    certification_notify_exceptionApproval_heading = "Exception Approval Request success",
    certification_notify_requestSuccess_body = "Request Success",

    certification_notify_raiseRequest_heading = "Please raise the fresh request",
    certification_notify_raiseRequest_body = "Request failed but upload success",

    certification_notify_fileUploadFailed_heading = "File upload failed. Please upload the file again and submit the exception approval request",
    certification_notify_fileUploadFailed_body = "File Upload Failed",

    certification_notify_approvalRequestApproved_heading = "Certification Request Approved",
    certification_notify_approvalRequestApproved_body_p1 = "Certification request for the",
    certification_notify_approvalRequestApproved_body_p2 = "Customer Extension Package has been approved.",

    certification_notify_saveAsDraft_heading = "Certification Details Saved",
    certification_notify_saveAsDraft_body = "Details saved as draft.",
    certification_notify_failTo_saveAsDraft_heading = "Failed To Save",
    certification_notify_failTo_saveAsDraft_body = "Failed to save details as draft.",

    certification_notify_approvalRequestRejected_heading = "Certification Request Rejected",
    certification_notify_approvalRequestRejected_body_p1 = "Certification request for the",
    certification_notify_approvalRequestRejected_body_p2 = "Customer Extension Package has been rejected.",

    certification_notify_certificationInitiateFailed_heading = "Certification initiation failed",
    certification_notify_certificationInitiateFailed_body_p1 = "Approval process initiation has failed.",

    certification_notify_extensionNotAvailable_body = "Extensions are not available for selected extension type",
    certification_notify_extensionNotAvailable_heading = "Extension not available",

    certification_notify_packageNotAvailable_body = "Customer Extension Packages are not available for the selected extension",
    certification_notify_packageNotAvailable_heading = "Customer Extension Packages not available",
    /* No Result Found */
    certification_no_result_msg = "No Certification request found!",

    /*REQUEST CERTIFICATION */
    certification_notify_RequestcertificationFailed_body_p1="Certification Request for package ",
	
	certification_notify_RequestcertificationFailed_body_p2=" failed",
	
	certification_notify_RequestcertificationFailed_heading="Certification Request Failed",
	
	
	certification_notify_certificationRequestSubmitted_body_p1="Certification Request for package ",
	
	certification_notify_certificationRequestSubmitted_body_p2=" submitted for approval",
	certification_notify_certificationRequestSubmitted_heading="Certification Request Submitted",

    redirect_dialog_heading = "Confirm Re-direct",
    redirect_dialog_body = "The link will open in a new browser tab. Are you sure you want to continue?",
    redirect_dialog_primary_btn = 'Yes',
    redirect_dialog_secondary_btn = 'No',
    certification_enum_approve = 'approve',
    certification_enum_approved = 'Approved',
    certification_enum_reject = 'reject',
    certification_enum_rejected = 'Rejected',
    certification_enum_draft = 'draft',   
    certification_enum_drafted = 'Drafted',

    certification_notify_invalidFileType_heading = "Invalid file type",
    certification_notify_invalidFileType_body = "Please upload valid file type",
}