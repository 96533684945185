<mat-drawer-container class="certification-checklist-container colorred no-drawer-scroll">
    <mat-drawer #drawer class="certification-checklist-drawer" position="end" mode="side">
      <div class="drawer-main-container">
        <div class="certification-checklist-header">
          <h2><mat-icon class="close-icon">checklist_rtl</mat-icon>Certification Checklist</h2>
          <button mat-icon-button (click)="closeDrawer()" aria-label="Close Drawer">
            <mat-icon class="close-icon">close</mat-icon>
          </button>
        </div>
        <div class="certification-checklist-content">
          <div class="certification-checklist-info">
            <mat-icon class="close-icon">error</mat-icon>
            <p>{{certificationEnum.certification_checkList_List_heading1}}</p>
          </div>
          <div class="certification-checklist-form">
              <div class="certification-checklist-details">
                  <div class="certification-checklist">
                    <h3>{{certificationEnum.certification_checkList_List_heading2}}</h3>
                    <mat-accordion>
                      <mat-expansion-panel hideToggle *ngFor="let list of certificationsAnswersList">
                        <mat-expansion-panel-header class="idn-checklist-expansion-panel">
                          <mat-panel-title>
                              <div class="certification-question">
                                  <div><span>{{ list.checkListId }}</span></div>
                                  <p *ngFor="let item of checkList">
                                    <ng-container *ngIf="list.checkListId == item.id">
                                      {{ item.question }}
                                    </ng-container>
                                  </p>
                              </div>
                              <div class="certification-icons">
                                  <mat-icon class="close-icon">speaker_notes</mat-icon>
                                  <span [ngClass]="{'checklist-yes': list.answer == true, 'checklist-no': list.answer == false}" class="checklist-no" (click)="$event.stopPropagation();">{{ list.answer == true ? "YES" : "NO" }}</span>
                              </div>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="certification-question-body">
                          <div class="certification-comments">
                              <p><span>COMMENTS</span>
                                <quill-view-html [content]="list.comments"></quill-view-html> </p>
                          </div>
                        </div>
                    </mat-expansion-panel>
                    </mat-accordion>
                    <div *ngIf="certificationDetails">
                      <h3>Approver Comments</h3>
                      <quill-view-html [content]="certificationDetails.reviewerComment" theme="snow"></quill-view-html>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="certification-checklist-footer">
          <button mat-stroked-button (click)="closeDrawer()">Close</button>
        </div>
      </div>
    </mat-drawer>
</mat-drawer-container>
