import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-idn-list-drawer',
  templateUrl: './idn-list-drawer.component.html',
  styleUrls: ['./idn-list-drawer.component.scss']
})
export class IdnListDrawerComponent implements OnInit {
  @Input() data:any;
  @Output() closeEvent = new EventEmitter<any>();
  ngOnInit(): void {
  }


  toggledrawer(data:any){
    this.closeEvent.emit(data);
  }
}
