<app-pageheader [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div class="page-container">

    <div class="idn-view-wrapper">
      <div class="idn-view-header">
        <div class="data-left">
          <div class="idn-view-header-label">{{iciComponenetDeploymentEnum.componentDeployment_view_header}}</div>
          <div class="idn-view-header-data">
            <h3>{{ViewData.cName}}</h3>
          </div>
        </div>
      </div>
  
      <div class="idn-view-body pss-dashboard-form top-spacer">
        <mat-grid-list cols="3" class="idn-view-body" rowHeight="80px">
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_table_header_cname}}</div>
              <div class="idn-view-body-items-data">{{ViewData.cName}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_table_header_iciVersion}}</div>
              <div class="idn-view-body-items-data">{{ViewData.iciVersion}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_table_header_status}}</div>
              <div class="idn-view-body-items-data">{{ViewData.statusDisplayName}}</div>
            </div>
          </mat-grid-tile>  
        </mat-grid-list>
  
        <mat-grid-list cols="3" rowHeight="80px">
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_table_header_serviceNowTicketId}}</div>
              <div class="idn-view-body-items-data">{{ViewData.serviceNowTicketId}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_table_header_requestorDetails}}</div>
              <div class="idn-view-body-items-data">
                <span class="view-user-info">{{ViewData.requesterdDetail}}</span>
                <span class="view-timestamp">on {{ViewData.createdAt | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>  


        <div class="idn-view-package-wrapper mt-2">
          <div class="header" *ngIf="ViewData.iciDeploymentPackageDetails">
            Deployment Packages <span class="count">{{ViewData.iciDeploymentPackageDetails.length }}</span>
          </div>
          <div class="idn-view-package-container">
            <div *ngFor="let deployment of ViewData.iciDeploymentPackageDetails" class="idn-acc-list-card">
              <mat-grid-list cols="1" rowHeight="48px">
                <mat-grid-tile [colspan]="1">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_view_deploymentFor}}</div>
                    <div class="idn-view-body-items-data">{{iciComponenetDeploymentEnum.componentDeployment_view_skuDeployment}}</div>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="1" rowHeight="48px">
                <mat-grid-tile [colspan]="1">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_view_extensionType}}</div>
                    <div class="idn-view-body-items-data">{{deployment.extensionType}}</div>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="1" rowHeight="48px" *ngIf="deployment.skuName">
                <mat-grid-tile [colspan]="1">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_view_productName}}</div>
                    <div class="idn-view-body-items-data">{{deployment.skuName}}</div>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
              <mat-grid-list cols="1" rowHeight="48px">
                <mat-grid-tile [colspan]="1">
                  <div class="idn-view-body-items">
                    <div class="idn-view-body-items-label">{{iciComponenetDeploymentEnum.componentDeployment_view_packageName}}</div>                                   
                      <span class="pckgname">{{deployment.solutionPackage}}</span>
                  </div>
                </mat-grid-tile>
              </mat-grid-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="pss-form-button-wrapper">  
    <button mat-stroked-button (click)="cancel()">Cancel</button>
  </div>
