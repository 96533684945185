<app-pageheader [pageTitle]="'Instance Requests'" [breadCrumbs]="this.breadCrumbItems" [auditLog]="true"
  [auditLogData]="'deploymentrequest'"></app-pageheader>
<div class="page-container">
  <div class="idn-filter">
    <form class="idn-grid-filter" [formGroup]="deploymetDates">
      <mat-form-field class="idn-grid-filter-search">
        <mat-label>Search Deployment</mat-label>
        <input #name matInput (keydown.enter)="doFilter()" #search_input
          placeholder="Search by customer name, cName and Requestor Details..." #input>
        <span class="idn-custom-search" matSuffix>
          <mat-icon (click)="doFilter()">search</mat-icon>
          <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput()">
            <mat-icon>close</mat-icon>
          </button>
        </span>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select (selectionChange)="doFilter()" #deploymentStatus matNativeControl>
          <mat-option [value]="selectAll"> All</mat-option>
          <mat-option *ngFor="let stat of statusList" [value]="stat.id">
            {{stat.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>custom date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" [max]="maxDate">
          <input matStartDate formControlName="start" placeholder="Start date" value="startDate" #start>
          <input matEndDate formControlName="end" (dateChange)="doFilter()" placeholder="End date" value="endDate" #end>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </form>
    <button mat-stroked-button color="primary" (click)="clearFilter()">
      <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
      <span>Clear Filter</span>
    </button>
  </div>

  <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" class="idn-grid-position idn-grid-global"
      (matSortChange)="announceSortChange($event)" matSort #empTbSort="matSort"
      *ngIf="!noContent; else noContentTemplate">

      <ng-container matColumnDef="customerName" *ngIf="!isCustomerUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by customerName">
          Customer Name
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.customerName}}"
          matTooltipClass="my-tooltip" matTooltipPosition="above"> {{element.customerName}} </td>
      </ng-container>

      <ng-container matColumnDef="cnameToEnter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by cnameToEnter">
          cName
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.cnameToEnter}}"
          matTooltipClass="my-tooltip" matTooltipPosition="above">
          <div>{{element.cnameToEnter}}</div>
          <div><span class="idn-cell-badgage">{{element.instanceType}}</span></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="requiredBy">
        <th mat-header-cell *matHeaderCellDef>
          {{deploymentEnum.deployment_View_Field_requestorDetails}}
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-data">
          <div class="idn-cell-date">{{ element.requesterdDetail }}</div>
          <div class="requested-date"> on {{ element.createdAt | date:"dd MMMM, yyyy " }}{{ element.createdAt | date:"'at' HH:mm '(UTC)'" }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="deploymentType">
        <th mat-header-cell *matHeaderCellDef>
          Deployment Type
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-data" id="package-count">
          <div>Customer Extension Packages: {{element.customerExtensionPackageCount}}</div>
          <div>SKUs: {{element.skuCount}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="action-status">
              Status
              <div class="refresh-btn">
                <mat-icon (click)="refresh($event)" >refresh</mat-icon>
              </div>
            </div>
           
           </th>
        <td mat-cell *matCellDef="let element">
          <div class="pss-chips-status">
            <mat-icon class="idn-cell-status c-status-info" *ngIf="element.statusId == 1">bookmark</mat-icon>
            <mat-icon class="idn-cell-status c-status-warning" *ngIf="element.statusId == 2"> auto_mode
            </mat-icon>
            <mat-icon class="idn-cell-status c-status-warning" *ngIf="element.statusId == 3">warning
            </mat-icon>
            <mat-icon class="idn-cell-status c-status-success" *ngIf="element.statusId == 4">check_circle
            </mat-icon>
            <mat-icon class="idn-cell-status c-status-danger" *ngIf="element.statusId == 5">dangerous</mat-icon>
            <mat-icon class="idn-cell-status c-status-warning" *ngIf="element.statusId == 6">inventory_2</mat-icon>
            <mat-icon class="idn-cell-status c-status-danger" *ngIf="element.statusId == 7">inventory_2</mat-icon>
            <mat-icon class="idn-cell-status c-status-success" *ngIf="element.statusId == 8">inventory_2</mat-icon>
            <mat-icon class="idn-cell-status c-status-warning" *ngIf="element.statusId == 9">hourglass_empty</mat-icon>
            <span class="label"
              [ngClass]="[element.status === 'Failed' ? 'c-status-danger' : '',  element.status === 'Request Rejected' ? 'c-status-warning' : '']">{{element.status}}</span>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="idn-grid-th-p1 action-cell">
          Actions
        </th>

        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="View Deployment" matTooltip="View Deployment"
            (click)="navigateItem('deployment/view', element.id)">
            <mat-icon class="view-icon">remove_red_eye</mat-icon>
          </button>
          <button [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="Instance Deployment Actions"
            matTooltip="Instance Deployment Actions">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #gridMenu="matMenu" xPosition="before">
            <button mat-menu-item (click)="toggleTrackStatus(element.statusId, element.deploymentStatusChanges)">
              <mat-icon>touch_app</mat-icon> Track Status
            </button>
            <button mat-menu-item (click)="downloadDeploymentLog(element.id, element.instanceType)" *ngIf="element.statusId === 4 || element.statusId === 5">
              <mat-icon>download</mat-icon> {{deploymentEnum.deployment_List_Action_Label_downloadLogs}}
            </button>
            <button mat-menu-item (click)="approveReject(element.id, element.deploymentRequestedDate)" *ngIf="element.statusId === 9 && isRejectDeployment && isApproveDeployment">
              <mat-icon>rule</mat-icon> {{deploymentEnum.deployment_constant_approve_reject}}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [pageSize]="10" [pageIndex]="currentPage" class="mat-paginator-sticky"
    [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" (page)="pageChanged($event)" [length]="pageLength" showFirstLastButtons
    aria-label="Select page of periodic elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
  </mat-paginator>
</div>
<button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
  <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
</button>



<mat-drawer-container class="idn-drawer-internal" *ngIf="isTrackStatusDrawer">
  <mat-drawer #drawerInfo class="idn-drawer">
    <div class="header">
      <div class="heading">
        <mat-icon>touch_app</mat-icon>
        <h2>{{deploymentEnum.deployment_View_CustomDrawer_Header_screenHelpMessage}}</h2>
      </div>
      <button mat-icon-button (click)="closeTrackStatus()" aria-label="Close Button">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="body pt-1">
      <div class="page-container">
        <div class="idn-view-wrapper">
          <div class="idn-view-body">
            <div class="idn-info">
              <mat-icon>info</mat-icon>
              <h4>
                {{deploymentEnum.deployment_View_CustomDrawer_Title_screenHelpMessage}}
              </h4>
            </div>
            <div class="idn-track-status">

              <div class="steps" *ngFor="let tractStatus of deploymentStatusChanges;let arrayIndex = index">
                <span class={{StatusClasses[arrayIndex]}}>
                  <mat-icon>{{tractStatus.icon}}</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{tractStatus.statusDisplayName}}</h4>
                  <p *ngIf="tractStatus.statusId">
                    on {{tractStatus.timestamp.slice(0, 10) | date:'dd MMMM, YYYY'}} at {{tractStatus.timestamp |
                    date:'HH:mm:ss'}} (UTC)
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="pss-drawer-button-wrapper">
      <button mat-flat-button (click)="closeTrackStatus()">Close</button>
    </div>
  </mat-drawer>
</mat-drawer-container>

<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71"
      viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)"
            fill="#515165" />
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
          transform="translate(3187.267 7517.8)" fill="#7c7c97" />
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
          transform="translate(3192.911 7517.8)" fill="#515165" />
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
          transform="translate(3178.801 7551.663)" fill="#ababc5" />
        <path id="Union_2" data-name="Union 2"
          d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
          transform="translate(3178.801 7617.272)" fill="#7c7c97" />
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
          transform="translate(3192.911 7551.663)" fill="#7c7c97" />
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)"
            fill="#cfdae2" />
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
            transform="translate(8.466 8.466)" fill="#f6f2f1" />
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
          clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1"
            d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
            transform="translate(-14.11 0)" fill="#515165" />
        </g>
      </g>
    </svg>
    <h4>{{ deploymentEnum.deployment_no_result_msg }}</h4>
  </div>
</ng-template>
