import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { catchError, throwError } from 'rxjs';
import { IdnTermsService } from 'src/app/core/services/common/auth/idn-terms/idn-terms.service';
import { TermsAndConditionsService } from 'src/app/core/services/terms-and-conditions/terms-and-conditions.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { TermsConditions } from 'src/app/shared/enum/termsConditions';
import { LogoutString } from 'src/app/shared/enum/logoutString';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { IdnConfigurationService } from 'src/app/core/services/common/idn-configuration.service';
@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.scss']
})
export class TermsDialogComponent implements OnInit {
  public logoutEnum = LogoutString;
  content: SafeHtml;
  isChecked: any =  false;
  public termsString = TermsConditions;
  constructor(
    private idnTermsIdnTermsService: IdnTermsService,
    private sanitizer: DomSanitizer,
    private MatDialog: MatDialog,
    private msalService: MsalService,
    private dialogService: DialogService,
    private TermsAndConditionsService: TermsAndConditionsService,
    private permissionService: PermissionsService,
    private idnConfiguration:IdnConfigurationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationService: NotificationService
  ) {
    this.content = this.sanitizer.bypassSecurityTrustHtml(
      this.idnTermsIdnTermsService.getTermsContent()
    );
   }

  ngOnInit(): void {
  }

  closeTerms(){
    this.MatDialog.closeAll();
  }

  agree(){
      this.TermsAndConditionsService.accept().pipe(
        catchError(err => {
          this.notificationService.showNotification(this.termsString.terms_notify_failed_body, 'error', this.termsString.terms_notify_failed_heading);
          return throwError(err);
        })
      ).subscribe(async res => {
        this.notificationService.showNotification(this.termsString.terms_notify_success_body, 'success', this.termsString.terms_notify_success_heading);

       let configValues =await this.idnConfiguration.getConfigValues();        
       if(configValues){
          if(!(this.permissionService.getPermission('UpgradeMultiTenantPOD') && !this.permissionService.getPermission('PreviewMultiTenantPODUpgrade'))){
            this.router.navigate(['home/resources']);
          }else{
            this.router.navigate(['home/upgrade/pod-upgrade']);
          }
        }
        this.MatDialog.closeAll();
      });
  }

  cancelTerms(): void {
    this.dialogService.confirmDialog({
      title: this.logoutEnum.logout_dialog_heading,
      module: 'logout',
      message: this.logoutEnum.logout_dialog_body,
      confirmText: this.logoutEnum.logout_dialog_primary_btn,
      cancelText: this.logoutEnum.logout_dialog_secondary_btn
    }).subscribe(res => {
      if (res) {
        localStorage.removeItem("authenticatedUser");
        let ss = (this.msalService.instance.getActiveAccount())?.homeAccountId;
        let loginhint = (this.msalService.instance.getActiveAccount())?.idTokenClaims?.login_hint;
        const currentAccount = this.msalService.instance.getAccountByHomeId(ss!);
        if (currentAccount != null) {
          this.msalService.instance.logoutRedirect({ account: currentAccount, idTokenHint: loginhint, logoutHint: loginhint });
          this.MatDialog.closeAll();
          localStorage.clear();
        } else {
          this.msalService.instance.logoutRedirect()
        }

      }
    });

  }

}
