import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any =  environment.headers;

  constructor(private http: HttpClient) { }

  public accept(){
    return this.http.post<any>(`${this.baseApiUrl}api/TermsAndCondition/Accept`, { headers :this.headers});
  }

}
