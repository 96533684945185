<app-idn-search-box (searchText)="onSearchData($event)"></app-idn-search-box>
<div class="idn-checkbox-list-data">
  <mat-checkbox class="master-check" [checked]="allComplete" color="primary" (change)="setAll($event.checked)">
    All
  </mat-checkbox>
  <div class="subtask-check" *ngFor="let instance of instanceTypes">
    <mat-checkbox class="environment" color="primary" [(ngModel)]="instance.completed"
      (ngModelChange)="updateAllComplete($event, instance.id)" [value]="instance.id"> {{instance.name}}
    </mat-checkbox>
    <div class="idn-env-count" id="env-count-id" *ngIf="checkedInstanceVal.indexOf(instance.id) > -1">
      <button mat-icon-button color="primary" (click)="decreaseValue(instance.id)" aria-label="Decreement Button">
        <mat-icon>remove_circle</mat-icon>
      </button>
      <mat-form-field class="instance-input idn-no-mb">
        <ng-container *ngFor="let instanceData of instancelists">
          <input type="text" (change)="addValue($event, instance.id)" matInput class="alignTextCenter" required="" *ngIf="instanceData.instanceTypeId==instance.id"
            [value]="instanceData.instanceTypeId==instance.id?instanceData.numberOfInstasnces:''"
            (keyup)="validateNumber($event)" aria-label="Add input" (onkeypress)="allowOnlyNumbers($event)">
        </ng-container>

      </mat-form-field>
      <button mat-icon-button color="primary" (click)="increaseValue(instance.id)" aria-label="Increement Button">
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
  </div>
</div>