<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [instanceTools]="false"
    [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
    <app-card [ViewData]="instanceData"></app-card>
    <div class="table-header"><span>{{dbLogTableName}}</span></div>
    <div class="search-div">
        <mat-form-field class="search-input">
            <mat-label>Search</mat-label>
            <input type="text" (keydown.enter)="filterData()" #search_input matInput [(ngModel)]="searchTerm">
            <span class="idn-custom-search" matSuffix>
                <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event)">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="filterData()" >Search</button>
    </div>
    <app-idn-table class="db-table" [data]="tableDatabaseLogs" (onPageChanged)="pageChanged($event)"></app-idn-table>
</div>
<div class="pss-form-button-wrapper">
    <button mat-stroked-button (click)="Oncancel()">Cancel</button>
</div>