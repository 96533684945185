<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [instanceTools]="false"
    [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
    <app-card [ViewData]="instanceEsignData" *ngIf="instanceEsignData?.records?.length"></app-card>
    <div class="idn-stepper">
        <mat-stepper linear #stepper aria-busy="true">
            <mat-step state="step1" [stepControl]="firstStepControl" completed=false>
                <ng-template matStepLabel>
                    Configuration <mat-icon *ngIf="configError" class="error_found">report_problem</mat-icon> <mat-icon *ngIf="!configError" class="no_error">check_circle</mat-icon>
                <br>
                    <h6>
                        <mat-hint class="info">Monitor Esign Configuration
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="error" *ngIf="configError">
                    <mat-icon class="warning-icon">report_problem </mat-icon>
                   <span>{{instanceEnum.instance_esign_config_error_message}}</span>
                 </div>
                <div class="task-status">
                    <div class="heading"><span>Esign Task Status</span></div>
                    <mat-divider></mat-divider>
                    <app-idn-table class="infra-table" [data]="tableTrackStatus"></app-idn-table>
                </div>
                <div class="config-key-validation">
                    <div class="heading"><span>Config Key Validation</span></div>
                    <mat-divider></mat-divider>
                    <app-idn-table class="config-table" [data]="tableConfigKeyValidation" (onPageChanged)="pageChanged($event)"></app-idn-table>
                </div>
            </mat-step>
            <mat-step  state="step2" [stepControl]="secondStepControl" completed=false>
                <ng-template matStepLabel>Database Tables <mat-icon *ngIf="true" class="no_error">check_circle</mat-icon> <br>
                    <h6>
                        <mat-hint class="info">Track Esign Tasks
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="idn-view-package-expansion-wrapper mt-1">
                    <mat-accordion>
                      <mat-expansion-panel  expanded="true" class="idn-panel">
                        <mat-expansion-panel-header class="header">
                            <mat-panel-title>
                                Esign Processing Task Failure History (Last {{historyPeriod}} Days)
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-idn-table class="panel-table" [data]="tableFailedESign" (onPageChanged)="pageChanged($event)" (onSort)="announceSortChange($event)"></app-idn-table>
                      </mat-expansion-panel>
                      <mat-expansion-panel  class="idn-panel" >
                        <mat-expansion-panel-header class="header">
                            <mat-panel-title>
                                Callback Processing Failure History (Last {{historyPeriod}} Days)
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <app-idn-table class="sql-table" [data]="tableSQLFailed" (onPageChanged)="pageChanged($event)" (onSort)="announceSortChange($event)"></app-idn-table>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
            </mat-step>
            <mat-step state="step3" [stepControl]="thirdStepControl" completed=false>
                <ng-template matStepLabel>Envelope Validation <mat-icon *ngIf="true" class="no_error">check_circle</mat-icon><br>
                    <h6>
                        <mat-hint class="info">Send Envelope Validation
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="envelop">
                    <div class="heading"><span>Send Envelope Validation</span></div>
                    <mat-divider></mat-divider>
                    <div>
                        <div class="envelop_sign">
                            <div class="envelop-left"><Span>Send as Signature:</Span></div>
                            <mat-form-field class="select-input">
                                <mat-label>Please Select</mat-label>
                                <mat-select disableOptionCentering name="action" [(value)]="signatureKey"
                                    (selectionChange)="signatureKeyChange($event)">
                                    <mat-option *ngFor="let sign of signaturekeyData" [value]="sign.id">
                                        {{sign.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span><mat-icon matTooltip="{{instanceEnum.esign_email_info}}"  matTooltipClass="my-tooltip" matTooltipPosition="right" class="sign-info">info</mat-icon></span>
                        </div>
                        <div class="envelop_sign" *ngIf="signatureKey == 3">
                            <div class="envelop-left"><Span>User Email ID:</Span></div>
                            <div class="select-input">
                                <mat-form-field>
                                    <mat-label>Enter the user's email address</mat-label>
                                    <input matInput placeholder="example@.com" [(ngModel)]="userEmail" required />
                                    <mat-hint class="idn-info"><mat-icon>info</mat-icon> Document will be sent for Esign from this email
                                        ID.</mat-hint>
                                </mat-form-field>
                                <mat-error *ngIf="validateUserEmail(userEmail)">Invalid email Id format</mat-error>
                            </div>
                        </div>
                        <div class="envelop_sign">
                            <div class="envelop-left"><Span>Recipient Email ID:</Span></div>
                            <div class="select-input">
                                <mat-form-field>
                                    <mat-label>Enter the recipient's email address</mat-label>
                                    <input matInput placeholder="example@.com" [(ngModel)]="recipientEmail" required />
                                </mat-form-field>
                                <mat-error *ngIf="validateUserEmail(recipientEmail)">Invalid email Id format</mat-error>
                            </div>
                        </div>
                        <button mat-flat-button color="primary" class="envelop-button" (click)="sendEnvelope()" [disabled]="validateSendEnvelopeData()">
                            Send Envelope
                        </button>
                    </div>
                    <div class="idn-view-package-expansion-wrapper mt-1 envelop-panel" *ngIf="dataSourceValidationStatus.records.length">
                        <mat-accordion>
                            <mat-expansion-panel class="idn-panel">
                                <mat-expansion-panel-header class="header">
                                    <mat-panel-title>
                                        Validation Status
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <app-idn-table class="sql-table" [data]="validationStatusTable"></app-idn-table>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                </div>
            </mat-step>
            <mat-step state="step4" [stepControl]="fourthStepControl" completed=false>
                <ng-template matStepLabel>Export Details <br>
                    <h6>
                        <mat-hint class="info">Download Troubleshoot Report
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="export">
                    <div class="export-container">
                        <mat-icon svgIcon="export_icon"></mat-icon>
                        <button mat-flat-button color="primary" class="envelop-button" (click)="exportToExcel()" >
                            Export to Excel
                        </button>
                    </div>
                </div>
            </mat-step>
            <ng-template matStepperIcon="edit">
                <mat-icon *ngIf="mystepper?.selectedIndex == 0">settings_applications</mat-icon>
                <mat-icon *ngIf="mystepper?.selectedIndex == 1">storage</mat-icon>
                <mat-icon *ngIf="mystepper?.selectedIndex == 2">task</mat-icon>
                <mat-icon *ngIf="mystepper?.selectedIndex == 3">exit_to_app</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="done">
                <mat-icon>done</mat-icon>
            </ng-template>           
            
            <ng-template matStepperIcon="step1">
                <mat-icon>settings_applications</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="step2">
                <mat-icon>storage</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="step3">
                <mat-icon>task</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="step4">
                <mat-icon>exit_to_app</mat-icon>
            </ng-template>
              
        </mat-stepper>
        <div class="pss-form-button-wrapper">
            <button mat-stroked-button color="primary" (click)="onPrevious()" class="idn-edit-btn" *ngIf="mystepper?.selectedIndex != 0">
                {{instanceEnum.instance_initiate_deployment_previous_btn}}
            </button>
            <button mat-stroked-button color="primary" (click)="onNext()" class="idn-edit-btn" *ngIf="mystepper?.selectedIndex != 3">
                {{instanceEnum.instance_initiate_deployment_next_btn}}
            </button>
            <button mat-flat-button
                (click)="onCancel()">{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_cancel_btn}}</button>
        </div>
    </div>
    
</div>
