<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [instanceTools]="false"
    [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
    <app-card [ViewData]="instanceGIFData" *ngIf="instanceGIFData?.records?.length"></app-card>

    <div class="idn-stepper">
        <mat-stepper linear #stepper aria-busy="true">

            <mat-step state="step1" [stepControl]="firstStepControl" completed=false>
                <ng-template matStepLabel>
                    Validation Details <mat-icon class="error_found">report_problem</mat-icon>
                    <mat-icon *ngIf="configError" class="no_error">check_circle</mat-icon>
                    <br>
                    <h6>
                        <mat-hint class="info">Monitor GIF Configuration
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="error" *ngIf="configError">
                    <mat-icon class="warning-icon">report_problem </mat-icon>
                    <span>{{instanceEnum.instance_gif_config_error_message}}</span>
                </div>
                <div class="idn-info gif-info">
                    <mat-icon class="align-self">info</mat-icon>
                    <h5 class="align-content">
                        {{instanceEnum.instance_gif_view_info_message}}
                    </h5>
                </div>
                <div><br></div>
                <div class="idn-info">
                    <mat-icon class="content-center">info</mat-icon>
                    <h4>
                        {{instanceEnum.instance_gif_validation_info_message1}} <span class="orange-color"><b>orange.</b></span><br />
                        {{instanceEnum.instance_gif_validation_info_message2}} <span
                            class="red-color"><b>red.</b></span>
                    </h4>
                </div>
                <div class="task-status">
                    <div class="heading"><span>Infrastructure</span></div>
                    <mat-divider></mat-divider>
                    <app-idn-table class="infra-table" [data]="tableTrackStatus"></app-idn-table>
                </div>
                <div class="task-status">
                    <div class="heading"><span>Tenant Keys</span></div>
                    <mat-divider></mat-divider>
                    <app-idn-table class="infra-table" [data]="tableTenantKeyStatus"></app-idn-table>
                </div>               
            </mat-step>
            <mat-step state="step2" [stepControl]="secondStepControl" completed=false>
                <ng-template matStepLabel>Database Tables <mat-icon *ngIf="true"
                        class="no_error">check_circle</mat-icon> <br>
                    <h6>
                        <mat-hint class="info">Track Database Tables</mat-hint>
                    </h6>
                </ng-template>
                <div class="idn-info gif-info">
                    <mat-icon class="align-self">info</mat-icon>
                    <h5 class="align-content">
                        {{instanceEnum.instance_gif_view_info_message}}
                    </h5>
                </div>
                <div><br></div>
                <div class="idn-info">
                    <mat-icon class="content-center">info</mat-icon>
                    <h4>
                       {{instanceEnum.instance_gif_validation_info_message1}} <span class="orange-color"><b>orange.</b></span><br />
                       {{instanceEnum.instance_gif_validation_info_message2}} <span
                            class="red-color"><b>red.</b></span>
                    </h4>
                </div>
                <div>

                    <div class="task-status">
                        <div class="heading"><span>ClientAppCrossReferenceCT</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableClientAppCrossRefCT"
                            (onPageChanged)="pageChanged($event)"></app-idn-table>
                    </div>
                    <div class="task-status">
                        <div class="heading"><span>EntityMappingCT</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableEntityMappingCT"
                            (onPageChanged)="pageChanged($event)"></app-idn-table>
                    </div>
                    <div class="idn-info">
                        <mat-icon class="content-center">info</mat-icon>
                        <h4>
                           {{instanceEnum.instance_gif_config_info_message}}
                        </h4>
                    </div>
                    <div class="task-status">
                        <div class="heading"><span>CrossReferenceAttribute</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableCrossRefAttribute"
                            (onPageChanged)="pageChanged($event)"></app-idn-table>
                    </div>
                    <div class="idn-info">
                        <mat-icon class="content-center">info</mat-icon>
                        <h4>
                            {{instanceEnum.instance_gif_config_info_message}}
                        </h4>
                    </div>
                    <div class="task-status">
                        <div class="heading"><span>EntityMappingAttribute</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableEntityMappingAttribute"
                            (onPageChanged)="pageChanged($event)"></app-idn-table>
                    </div>
                    <div class="task-status">
                        <div class="heading"><span>ServerConnection</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableServerConnection"></app-idn-table>
                    </div>
                    <div class="task-status">
                        <div class="heading"><span>StagingTable</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableStaging"
                            (onPageChanged)="pageChanged($event)"></app-idn-table>
                    </div>
                    <div class="task-status">
                        <div class="heading"><span>ServiceBus</span></div>
                        <mat-divider></mat-divider>
                        <app-idn-table class="infra-table" [data]="tableServiceBus"></app-idn-table>
                    </div>
                </div>
            </mat-step>
            <mat-step state="step3" [stepControl]="thirdStepControl" completed=false>
                <ng-template matStepLabel>Export Details <br>
                    <h6>
                        <mat-hint class="info">Download Troubleshoot Report
                        </mat-hint>
                    </h6>
                </ng-template>
                <div class="export">
                    <div class="export-container">
                        <mat-icon svgIcon="export_icon"></mat-icon>
                        <button mat-flat-button color="primary" class="envelop-button" (click)="exportToExcel()">
                            Export to Excel
                        </button>
                    </div>
                </div>
            </mat-step>
            <ng-template matStepperIcon="edit">
                <mat-icon *ngIf="mystepper?.selectedIndex == 0">task</mat-icon>
                <mat-icon *ngIf="mystepper?.selectedIndex == 1">storage</mat-icon>
                <mat-icon *ngIf="mystepper?.selectedIndex == 2">exit_to_app</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="done">
                <mat-icon>done</mat-icon>
            </ng-template>

            <ng-template matStepperIcon="step1">
                <mat-icon>task</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="step2">
                <mat-icon>storage</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="step3">
                <mat-icon>exit_to_app</mat-icon>
            </ng-template>

        </mat-stepper>
        <div class="pss-form-button-wrapper">
            <button mat-stroked-button color="primary" (click)="onPrevious()" class="idn-edit-btn"
                *ngIf="mystepper?.selectedIndex != 0">
                {{instanceEnum.instance_initiate_deployment_previous_btn}}
            </button>
            <button mat-stroked-button color="primary" (click)="onNext()" class="idn-edit-btn"
                *ngIf="mystepper?.selectedIndex != 2">
                {{instanceEnum.instance_initiate_deployment_next_btn}}
            </button>
            <button mat-stroked-button
                (click)="onCancel()">{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_cancel_btn}}</button>
        </div>
    </div>

</div>