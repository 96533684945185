<app-pageheader [pageTitle]="'SDK Mangement/Solution_Revised'" [auditLog]="false"  [editSDK]="true" [editSDKdata]="ViewData"
    [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div class="page-container">
  <div class="idn-view-wrapper">

    <div class="idn-view-header">
      <div class="data-left">
        <div class="idn-view-header-label">{{ this.WbMgmtEnum.wb_header_view }} :</div>
        <div class="idn-view-header-data">
          <h3>{{ViewData.name}}</h3>
          <em><b>Created on: {{ ViewData.createdAt | date: "dd MMM, yyyy 'at' HH:mm:ss '(UTC)'" }} by {{ViewData.createdByDisplayName}}</b></em>
        </div>
      </div>
    </div>
    <div class="idn-view-body">
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_name }}</div>
        <div class="idn-view-body-items-data">{{ViewData.name}}</div>
      </div>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_description }}</div>
        <div class="idn-view-body-items-data">{{ViewData.description}}</div>
      </div>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_version }}</div>
        <div class="idn-view-body-items-data">{{ViewData.version}}</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_ici_version }}</div>
        <div class="idn-view-body-items-data">{{ViewData.iciVersions}}</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_release_notes }}</div>
        <div class="idn-view-body-items-data">{{ViewData.releaseNote}}</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_release_date }}</div>
        <div class="idn-view-body-items-data">{{ViewData.releaseDate | date: 'shortDate'}}</div>
        <div class="idn-view-body-items-data" *ngIf="!ViewData.releaseDate">NA</div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_eol_date }}</div>
        <div class="idn-view-body-items-data">{{ViewData.endOfLifeDate | date: 'shortDate'}}</div>
        <div class="idn-view-body-items-data" *ngIf="!ViewData.endOfLifeDate">NA</div>
      </div>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_file_uploaded }}</div>
        <div class="idn-view-body-items-data">
          <span [ngClass]="ViewData.isFileUploaded ? 'active': 'in-active'">{{ViewData.isFileUploaded ? 'Yes' :
            'No'}}</span>
        </div>
      </div>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_file }}</div>
        <div class="idn-view-body-items-data">
          <button mat-button class="idn-grid-link-button wkbench" [disabled]="ViewData.status != 1" (click)="downloadFile1(ViewData)">
            <span class="pckgname">{{ViewData.fileName}} </span>
            <mat-icon class="download-icon" *ngIf="ViewData.status == 1" >download
            </mat-icon>
          </button>
        </div>
      </div>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{ this.WbMgmtEnum.wb_label_status }}</div>
        <div class="idn-view-body-items-data">
          <span [ngClass]="ViewData.status ==1? 'active': 'in-active'">{{ViewData.status==1 ? 'Active' :
            'In-Active'}}</span>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="pss-form-button-wrapper">

  <button mat-stroked-button class="pss-btn-danger" (click)="DeactivateSDKs()" [disabled]= "ViewData.isFileUploaded ==false ? true : false" *ngIf="ViewData.status==1 && isDeactivateSDK">
    <mat-icon>block</mat-icon> <span>{{ this.WbMgmtEnum.wb_label_deactivate_btn }}</span>
  </button>
  <button mat-stroked-button class="pss-btn-success" (click)="ActivateSDKs()" [disabled]= "ViewData.isFileUploaded ==false ? true : false" *ngIf="ViewData.status==2 && isActivateSDK">
    <mat-icon>check</mat-icon> <span>{{ this.WbMgmtEnum.wb_label_activate_btn }}</span>
  </button>
  <button mat-stroked-button (click)="onCancel('idn-workbench-management')">{{ this.WbMgmtEnum.wb_label_cancel_btn }}</button>

</div>
<button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
  <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
</button>
