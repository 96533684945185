import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnyRecordWithTtl } from 'dns';
import { lastValueFrom } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { PartnerService } from 'src/app/core/services/partner-management/partner.service';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PartnerMgmtStaticString } from 'src/app/shared/enum/partnerManagement';

@Component({
  selector: 'app-list-partner',
  templateUrl: './list-partner.component.html',
  styleUrls: ['./list-partner.component.scss']
})
export class ListPartnerComponent implements OnInit {
  public PartnerMgmtEnum = PartnerMgmtStaticString;
  displayedColumns: string[] = ['partnerCode', 'name', 'description', 'region', 'user_limit', 'status', 'action'];
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;
  globalList: any;
  isUserAssociate: any = false;
  @ViewChild('search_input') inputName: any;
  createPartner: any;
  updatePartner: any;
  dectivatePartner: any;
  activatePartnerPermission: any;
  dataSource: any = [];
  searchFilterData: any = "";
  //listing
  filterVal: string = "";
  sortBy: string = "";
  pageNumber: number = 1;
  pageSize: number = 10;
  currentPage: number = 0;
  userRoleId: any = '';
  pageLength: number = 0;

  noContent: Boolean = false;
  constructor(
    private titleService: Title,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private router: Router,
    private partnerService: PartnerService,
    private sharedata: ShareDataService,
    private permssionService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Partner Management | IDN');
    this.createPartner = this.permssionService.getPermission('CreatePartner');
    this.updatePartner = this.permssionService.getPermission('UpdatePartner');
    this.dectivatePartner = this.permssionService.getPermission('DeactivatePartner');
    this.activatePartnerPermission = this.permssionService.getPermission('ActivatePartner');
    this.GetPartners();
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/partner-management" },
    { label: "Partner Management", path: "administration/partner-management" },
    { label: "List", path: "administration/partner-management" },
  ];

  GetPartners() {
    let filterValue: any = (localStorage.getItem("PartnerManagementFilter") == null) ? '' : localStorage.getItem("PartnerManagementFilter");
    if (filterValue != undefined && filterValue != null && filterValue != '') {
      this.filterVal = JSON.parse(filterValue).search;
      this.pageSize = JSON.parse(filterValue).pageSize;
      this.pageNumber = JSON.parse(filterValue).pageNumber;
      this.currentPage = JSON.parse(filterValue).currentPage;
    }
    this.partnerService.GetPartners(this.filterVal, this.sortBy, this.pageNumber, this.pageSize,).subscribe((resp: any) => {
      if (resp != null && resp.records.length > 0) {
        this.noContent = false;
        let reverse = resp.records;
        this.dataSource = new MatTableDataSource(resp.records);
        this.paginator.pageIndex = this.currentPage;  //for listing
        this.paginator.length = resp.matchingCount;  //for listing
        this.globalList = reverse;
      }
      else {
        this.noContent = true;
        this.dataSource = [];
      }
    });
  }

  async deactivatePartner(id: number) {
    this.isUserAssociate = await this.checkPartnerAssociateWithCustomer(id);
    if (this.isUserAssociate.status == 200) {
      this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_linked_body, 'error', this.PartnerMgmtEnum.partner_notify_linked_heading);
    }
    else {
      this.dialogService.confirmDialog({
        title: this.PartnerMgmtEnum.partner_dialog_deactivate_heading,
        module: 'partner',
        message: this.PartnerMgmtEnum.partner_dialog_deactivate_body,
        confirmText: this.PartnerMgmtEnum.partner_dialog_deactivate_primary_btn,
        cancelText: this.PartnerMgmtEnum.partner_dialog_cancel_btn
      }).subscribe(res => {
        if (res) {
          this.partnerService.deActivatePartner(id, false).subscribe((res: Response) => {
            let Result: any = res.body;
            if (res.status == 200) {
              this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_deactivated_body, 'success', this.PartnerMgmtEnum.partner_notify_deactivated_heading);
              this.router.navigate(["home/administration/partner-management"]);
            } else {
              this.notificationService.showNotification(Result.message, 'error', this.PartnerMgmtEnum.partner_notify_not_deactivated_heading);
              this.router.navigate(["home/administration/partner-management"]);
            }
            this.GetPartners();
          })
        }
      })
    }
  }

  //To Activate Role Status
  activatePartner(id: number) {
    this.dialogService.confirmDialog({
      title: this.PartnerMgmtEnum.partner_dialog_activate_heading,
      module: 'partner',
      message: this.PartnerMgmtEnum.partner_dialog_activate_body,
      confirmText: this.PartnerMgmtEnum.partner_dialog_activate_primary_btn,
      cancelText: this.PartnerMgmtEnum.partner_dialog_cancel_btn
    }).subscribe((res: any) => {
      if (res) {
        this.partnerService.activatePartner(id, true).subscribe((res: Response) => {
          let Result: any = res.body;
          if (res.status == 200) {
            this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_activated_body, 'success', this.PartnerMgmtEnum.partner_notify_activated_heading);
            this.router.navigate(["home/administration/partner-management"]);
          } else {
            this.notificationService.showNotification(Result.message, 'warning', this.PartnerMgmtEnum.partner_notify_not_activated_heading);
            this.router.navigate(["home/administration/partner-management"]);
          }
          this.GetPartners();
        })
      }
    })
  }



  //To check User Associate With Role
  async checkPartnerAssociateWithCustomer(id: number): Promise<any> {
    if (id != null && id != undefined && id > 0) {
      return await lastValueFrom(this.partnerService.CheckPartnerAssociateWithCustomer(id));
    }
    else {
      this.router.navigate(["home/administration/partner-management"]);
    }
  }

  navigateItem(navLink: any): void {
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/administration/' + navLink;
    }
    this.router.navigate([navLink]);
  }

  ngAfterViewInit() {
    this.inputName.nativeElement.value = this.filterVal;
  }
  ngOnDestroy(): void {
    const url1:string ='/home/administration/partner-management/view';
    const url2:string='/home/administration/partner-management/edit';
      if(window.location.pathname != url1 && window.location.pathname != url2){
        localStorage.removeItem('PartnerManagementFilter'); 
      } 
  }

  public doFilter = (event?: any, name?: any) => {
    this.inputName.nativeElement.value;
    let search = this.inputName.nativeElement.value;
    this.searchFilterData = search;
    let filterValues: any
    if (name == 'search') {
      filterValues = (event.target as HTMLInputElement).value;
    } else {
      filterValues = this.searchFilterData;
    }
    this.pageNumber = 1;
    this.currentPage = 0
    this.filterVal = this.inputName.nativeElement.value;
    this.GetPartners();
  }

  //To View Role
  ViewRole(navLink: any, element: any) {
    let Json = {
      'search': this.inputName.nativeElement.value,
      'pageSize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage': this.currentPage
    };
    localStorage.setItem("PartnerManagementFilter", JSON.stringify(Json));
    localStorage.setItem("partnerId", element.id);
    this.sharedata.sendData(JSON.stringify(element));
    navLink = 'home/administration/' + navLink;
    this.router.navigate([navLink]);
  }
  //To Edit Role
  edit(navLink: any, element: any) {

    let Json = {
      'search': this.inputName.nativeElement.value,
      'pageSize': this.pageSize,
      'pageNumber': this.pageNumber,
      'currentPage': this.currentPage
    };
    localStorage.setItem("PartnerManagementFilter", JSON.stringify(Json));
    localStorage.setItem("partnerId", element.id);
    navLink = 'home/administration/' + navLink;
    this.router.navigate([navLink]);
  }

  ClearInput() {
    if (this.inputName.nativeElement.value != '') {
      localStorage.removeItem("PartnerManagementFilter");
      this.inputName.nativeElement.value = '';
      this.filterVal = "";
      this.sortBy = "";
      this.GetPartners();
    }
  }

  clearFilter() {
      localStorage.removeItem("PartnerManagementFilter");
      this.inputName.nativeElement.value = '';
      this.filterVal = "";
      this.sortBy = "";
      this.pageNumber = 1;
      this.pageSize = 10;
      this.currentPage = 0;
      this.GetPartners();
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber=event.pageIndex+1;
    let filters = localStorage.getItem('PartnerManagementFilter');
    if (filters != null) {
      let Json = {
        'search': this.inputName.nativeElement.value,
        'pageSize': this.pageSize,
        'pageNumber': this.pageNumber,
        'currentPage': this.currentPage
      };
      localStorage.setItem('PartnerManagementFilter', JSON.stringify(Json))
    }
    this.GetPartners();
  }
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    }
    else {
      this.sortBy = sortState.active;
    }
    this.GetPartners();
  }

}
