import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';

@Component({
  selector: 'app-list-ici-workertask-configuration',
  templateUrl: './list-ici-workertask-configuration.component.html',
  styleUrls: ['./list-ici-workertask-configuration.component.scss'],
})
export class ListIciWorkertaskConfigurationComponent implements OnInit {
  breadCrumbItems: any;
  noContent: boolean = false;
  displayedColumns: string[] = [
    'taskId',
    'partnerName',
    'customerName',
    'cname',
    'type',
    'workerTaskName',
    'requestedBy',
    'status',
    'action',
  ];

  dataSource: any = [];
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator, { static: false })
  paginator!: MatPaginator;
  @ViewChild('empTbSort') empTbSort = new MatSort();
  currentPage = 0;
  pageLength: any = 0;
  pageNumber: any = 1;
  pageSize: any = 10;
  sortBy: string = '';
  ViewData: any;
  public instanceEnum = InstanceManagement;
  userCategory: any;
  isCustomerUser: any;

  constructor(
    private titleService: Title,
    private router: Router,
    private instanceService: InstanceService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle('ICI WorkerTask Configuration | IDN');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      {label: 'Development and Utilities', path: 'developmentandutilities/ici-workertask-configuration'},
      {label: 'ICI WorkerTask Configuration', path: 'developmentandutilities/ici-workertask-configuration'},
      { label: 'List', path: 'developmentandutilities/ici-workertask-configuration/list' },
    ];
    this.getWorkerTaskConfigurationListData();
    this.userCategory=this.permissionService.getUsercategoryId();
    if(this.userCategory ==  USER_CATEGORY_ID.Customer){
      this.isCustomerUser = true;
      this.displayedColumns = [
        'taskId',        
        'cname',
        'type',
        'workerTaskName',
        'requestedBy',
        'status',
        'action',
      ];
    }
    else{
      this.isCustomerUser = false;
    }
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
    this.getWorkerTaskConfigurationListData();
  }

  getWorkerTaskConfigurationListData() {
    try {
      this.instanceService
        .getWorkerTaskConfigurationListData(this.pageNumber, this.pageSize)
        .subscribe((resp: any) => {
          if (resp.body != undefined && resp.body != null) {
            this.ViewData = resp.body.records;
            this.noContent = false;
            this.paginator.length = resp.body.matchingCount;
          } else {
            this.noContent = true;
            this.ViewData = [];
          }
          this.dataSource = new MatTableDataSource<any>(this.ViewData);
          this.empTbSort.disableClear = true;
          this.paginator.pageIndex = this.currentPage;
        });
    } catch (error) {
      console.log(error);
    }
  }

  navigateItem(navLink: any, workerTaskId: any): void {
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/developmentandutilities/' + navLink;
    }
    localStorage.setItem('workerTaskId', workerTaskId);
    this.router.navigate([navLink]);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction == 'desc') {
      this.sortBy = sortState.active + '_' + sortState.direction;
    } else {
      this.sortBy = sortState.active;
    }
    this.getWorkerTaskConfigurationListData();
  }

  //navigate to view page
  navigateEdit(navLink: any, workerTaskId: any) {
    localStorage.setItem('workerTaskId', workerTaskId);
    if (navLink == 'home') {
      navLink = 'home';
    } else {
      navLink = 'home/developmentandutilities/' + navLink;
    }
    this.router.navigate([navLink]);
  }

  //disable the workertask entry
  disableEntry(id: any) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.workertask_redirect_dialog_heading,
        module: 'partner',
        message: this.instanceEnum.workertask_redirect_dialog_body,
        confirmText: this.instanceEnum.workertask_redirect_dialog_primary_btn,
        cancelText: this.instanceEnum.workertask_redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if(res){
          try {
            this.instanceService
              .DisableTask(id)
              .subscribe((resp: any) => {
                if(resp.isSuccess){
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_disable_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_disable_heading
                  );
                  this.getWorkerTaskConfigurationListData();
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_disable_failed_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_disable_failed_heading
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  }

  //enable the workertask entry
  enableEntry(id: any) {
    this.dialogService
      .confirmDialog({
        title: this.instanceEnum.workertask_redirect_dialog_heading_enable,
        module: 'partner',
        message: this.instanceEnum.workertask_redirect_dialog_body_enable,
        confirmText: this.instanceEnum.workertask_redirect_dialog_primary_btn,
        cancelText: this.instanceEnum.workertask_redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if(res){
          try {
            this.instanceService
              .EnableTask(id)
              .subscribe((resp: any) => {
                if(resp.isSuccess){
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_enable_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_enable_heading
                  );
                  this.getWorkerTaskConfigurationListData();
                } else {
                  this.notificationService.showNotification(
                    this.instanceEnum.workertask_notify_task_enable_failed_body,
                    'success',
                    this.instanceEnum.workertask_notify_task_enable_failed_heading
                  );
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
      });
  }
}
