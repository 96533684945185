import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateFilterComponent } from '../date-filter/date-filter.component';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { UserService } from 'src/app/core/services/user-management/user.service';
@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit{
  @Output() closeFilter = new EventEmitter<any>();
  @Output() applyFilter = new EventEmitter<any>();
  @Input() referenceModule :any;
  outputData:any = {};
  modifiedData:boolean = false;
  selectedDateFilter:any = '';
  receivedInputValue: string = '';
  dateFilter:any =[];
  disableField: boolean = false;
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  regions:any = [];
  dataSetRegion = {
    data: [],
    reference: ''
  }
  dataSetRequiredICIProducts = {
    data: [],
    reference: ''
  }
  userCategories:any;
  customerCreateDateFrom:any= '';
  customerCreateDateTo:any ='';
  hasGlobalDataAccess:boolean = false;
  partnerId :any;
  skusList:any = [];
  partnerAccess:any;
  tabsData:any = [];
  constructor(
    private customerService: CustomerService,
    private permissionService:PermissionsService,
  ) { }
  ngOnInit(): void {
    this.partnerAccess = this.permissionService.hasGlobalDataAccess();
   this.modifiedData = true;
    this.dateFilter =  ['Fixed Date (UTC)', 'Custom Date (UTC)'];
    this.partnerId = this.permissionService.getPartnerDetails().id;
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    if(this.referenceModule == 'customer-management'){
      this.outputData = {
        customerCreateDateFrom : '',
        customerCreateDateTo : '',
        regionId:[],
        skuId:[],
        customerInstanceTypes:[]
      };
      this.getSKU();
      this.getRegions();
      this.tabsData  =[
        {label:"DATE (UTC)", icon:'circle', show: false},
        {label:"ENVIRONMENT TYPE", icon:'circle', show: false},
        {label:"REGION", icon:'circle', show: false},
        {label:"REQUIRED ICI PRODUCTS", icon:'circle', show: false}
        ];
    }
    else if(this.referenceModule == 'user-management'){
      this.outputData = {
        customerCreateDateFrom : '',
        customerCreateDateTo : '',
        userCategory : ''
      };
      if(this.partnerAccess){
        this.tabsData  =[
          {label:"DATE (UTC)", icon:'circle', show: false},
          {label:"USER CATEGORY", icon:'circle', show: false}
          ];
      }
      else{
        this.tabsData  =[
          {label:"DATE (UTC)", icon:'circle', show: false},
          ];
      }
      
    }
    let customerData = localStorage.getItem('customerFilterData');
    let userData = localStorage.getItem('userFilterData');
    if(customerData){
      this.outputData = JSON.parse(customerData);
      if(this.referenceModule == 'customer-management'){
        this.tabsData[0].show = (this.outputData['customerCreateDateFrom'] != '' && this.outputData['customerCreateDateFrom'] != undefined );
        this.tabsData[1].show = (this.outputData['customerInstanceTypes'] != '' && this.outputData['customerInstanceTypes'] != undefined);
        this.tabsData[2].show = (this.outputData['regionId'] != '' && this.outputData['regionId'] != undefined );
        this.tabsData[3].show = (this.outputData['skuId'] != '' && this.outputData['skuId'] != undefined);
      }
    }
      if( userData){
        this.outputData = JSON.parse(userData);
        if(this.referenceModule == 'user-management'){
          this.tabsData[0].show = (this.outputData['customerCreateDateFrom'] != '' && this.outputData['customerCreateDateFrom'] != undefined );
          this.tabsData[1].show = (this.outputData['userCategory'] != '' && this.outputData['userCategory'] != undefined);
        }
      }
  }

  receivedInput(inputValue: any) {
    if(inputValue){
      this.outputData['dateType'] = inputValue?.dateType ;
      this.outputData['customerCreateDateFrom'] = inputValue?.customerCreateDateFrom;
      this.outputData['customerCreateDateTo'] = inputValue?.customerCreateDateTo;
      this.tabsData[0].show = (this.outputData['customerCreateDateFrom'] != '' );
    }
   
  }

  closeAdvanceFilter(){
    this.closeFilter.emit(false);
  }

  getRegions() {
    try {
      this.customerService.getRegions().subscribe((resp: any) => {
        if (resp != null) {
          this.regions = resp;
          this.dataSetRegion={
            data: resp,
            reference : 'regions' 
          }
        }
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  getSKU() {
    if(this.hasGlobalDataAccess){
      this.partnerId = 0;
    }
    try {
      this.customerService.getSKU(this.partnerId).subscribe((resp: any) => {
        if (resp != null) {
          this.skusList = resp;
          this.dataSetRequiredICIProducts={
           data: this.skusList,
           reference : 'requiredIciProducts' 
         }
        }
      });
    }
    catch (error) {
      console.log(error);
    }
  }
 
  GetAdvanceFilteredData(){
    this.applyFilter.emit(this.outputData);
    if(this.referenceModule == 'customer-management'){
      localStorage.setItem('customerFilterData', JSON.stringify(this.outputData));
    }
   else if(this.referenceModule == 'user-management'){
    localStorage.setItem ('userFilterData', JSON.stringify(this.outputData));
   }
   
  }
  
  getSelectedRegions(event:any){
    if(this.referenceModule == 'customer-management'){
      this.outputData['regionId']= event? event : [];
      this.tabsData[2].show = (this.outputData['regionId'] != '');
    }
    
  }
  
  getSelectedSkuId(event:any){
    if(this.referenceModule == 'customer-management'){
      this.outputData['skuId'] = event? event : [];
      this.tabsData[3].show = (this.outputData['skuId'] != '');
    }
   
  }

  getSelectedEnvironmentType(event:any){
    if(this.referenceModule == 'customer-management'){
      this.outputData['customerInstanceTypes'] = event ? event : [];
      this.tabsData[1].show = (this.outputData['customerInstanceTypes'] != '');
    }
    
  }

  getSelectedUserCategory(event:any){
    if(this.referenceModule == 'user-management'){
      this.outputData['userCategory'] = event? event : '';
      this.tabsData[1].show = (this.outputData['userCategory'] != '');
    }
  }

  resetAdvanceFilter(){
   this.outputData={
    customerCreateDateFrom : '',
    customerCreateDateTo : '',
    regionId:[],
    skuId:[],
    customerInstanceTypes:[]
  };
    localStorage.removeItem('customerFilterData');
    localStorage.removeItem('userFilterData');
    this.applyFilter.emit(this.outputData);
    this.tabsData  =[
      {label:"DATE (UTC)", icon:'circle', show: false},
      {label:"ENVIRONMENT TYPE", icon:'circle', show: false},
      {label:"REGION", icon:'circle', show: false},
      {label:"REQUIRED ICI PRODUCTS", icon:'circle', show: false}
      ];
  }

}
