import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { LayoutComponent } from './modules/dashboard/layout/layout.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { HomeComponent } from './pages/landing/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { PackagesDetailsComponent } from './modules/extension-management/view-extension/packages/packages-details/packages-details.component';
import { ResourcesComponent } from './modules/resources/resources.component';
import { AuditLogComponent } from './modules/audit-log/audit-log.component';
import { CertificationGuardService } from './core/Guards/certificationGuard/certification-guard.service';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { UserGuardService } from './core/Guards/userGuard/user-guard.service';
import { PartnerGuardService } from './core/Guards/partnerGuard/partner-guard.service';
import { RoleGuardService } from './core/Guards/roleGuard/role-guard.service';
import { ExtensionGuardService } from './core/Guards/extensionGuard/extension-guard.service';
import { InstanceGuardService } from './core/Guards/instanceGuard/instance-guard.service';
import { MtpodGuardService } from './core/Guards/mtpodGuard/mtpod-guard.service';
import { CustomerGuardService } from './core/Guards/customerGuard/customer-guard.service';
import { SkuGuardService } from './core/Guards/skuGuard/sku-guard.service';
import { WorkbenchGuardService } from './core/Guards/workbenchGuard/workbench-guard.service';
import { PageNotFoundComponent } from './pages/error/page-notfound/page-not-found/page-not-found.component';
import { DeploymentGuardService } from './core/Guards/deploymentGuard/deployment-guard.service';
import { IdnGuardService } from './core/Guards/idnGuard/idn-guard.service';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { TermsAndConditionComponent } from './pages/terms-and-condition/terms-and-condition.component';
import { AcceptTermsOfUseComponent } from './modules/accept-terms/accept-terms-of-use/accept-terms-of-use.component';
import { AuditGuardService } from './core/Guards/auditGuard/audit-guard.service';
import { WebAppModule } from './modules/web-app-management/web-app.module'
import { ICIAcademyCertificationErrorComponent } from './pages/ici-academy-certification-error/ici-academy-certification-error.component';
import { WebAppGuardService } from './core/Guards/webAppGuard/webApp-guard.service';
import { IciComponentDeploymentGuard } from './core/Guards/iciComponentDeployment/ici-component-deployment.guard';
import { PodUpgradeGuardService } from './core/Guards/podUpgrade/pod-upgrade.guard';
import { IciWorkerTaskConfigurationGuardService } from './core/Guards/ici-workertask-configuration/ici-workertask-configuration.guard.service';
import { ApprovalManagementComponent } from './modules/approval-management/approval-management.component';
import { ApprovalListComponent } from './modules/approval-management/approval-list/approval-list.component';
import { ViewApprovalComponent } from './modules/approval-management/view-approval/view-approval.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionComponent },
  { path: 'login',  component: LoginComponent },
  { path: 'forget-password',  component: ForgetPasswordComponent },
  { path: 'un-authorized', component: UnauthorizedComponent },
  { path: 'accept-terms-of-use', component: AcceptTermsOfUseComponent },{
    path: 'authorize-icicertification', component: ICIAcademyCertificationErrorComponent
  },
  {
    path: 'home',
    component: LayoutComponent,
    canActivate:[IdnGuardService],
    canActivateChild: [IdnGuardService],
    children: [
        {
          path: 'developmentandutilities/extension-management',
          loadChildren: () => import('./modules/extension-management/extension-management.module').then(m => m.ExtensionManagementModule),
          canActivate: [ExtensionGuardService]
        },
        {
          path: 'access-denied',
          component: AccessDeniedComponent
        },
        {
          path: 'developmentandutilities/certification-request',
          loadChildren: () => import('./modules/certifications-management/certifications.module').then(m => m.CertificationsModule),
          canActivate : [CertificationGuardService]
        },
        {
          path: 'instanceoperations/instance-requests',
          loadChildren: () => import('./modules/instance-management/instance-management.module').then(m => m.InstanceManagementModule),
          canActivate : [InstanceGuardService]
        },
        {
          path: 'instanceoperations/deployment',
          loadChildren: () => import('./modules/deployments/deployment.module').then(m => m.DeploymentModule),
          canActivate : [DeploymentGuardService]
        },
        {
          path: 'administration/partner-management',
          loadChildren: () => import('./modules/partner-management/partner-management.module').then(m => m.PartnerManagementModule),
          canActivate : [PartnerGuardService]
        },
        {
          path: 'administration/customer-management',
          loadChildren: () => import('./modules/customer-management/customer-management.module').then(m => m.CustomerManagementModule),
          canActivate : [CustomerGuardService]
        },
        {
          path: 'notification',
          loadChildren: () => import('./modules/activity-notification/activity-notification.module').then(m => m.ActivityNotificationModule)
        },
        {
          path: 'sku-management',
          loadChildren: () => import('./modules/sku-management/sku-management.module').then(m => m.SkuManagementModule),
          canActivate : [SkuGuardService]
        },
        {
          path: 'administration/user-management',
          loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule),
          canActivate : [UserGuardService]
        },
        {
          path: 'upgrade/pod-upgrade',
          loadChildren: () => import('./modules/pod-upgrade/pod-upgrade.module').then(m => m.PodUpgradeModule),
          canActivate : [PodUpgradeGuardService]
        },
        {
          path: 'audit-log',
          component: AuditLogComponent,
          canActivate : [AuditGuardService]
        },
        {
          path: 'resources',
          component: ResourcesComponent,
        },
        {
          path: 'dashboard',
          component: DashboardComponent,
        },
	      {
          path: 'administration/role-management',
          loadChildren: () => import('./modules/role-management/role-management.module').then(m => m.RoleManagementModule),
          canActivate : [RoleGuardService]
        },
	      {
          path: 'developmentandutilities/idn-workbench-management',
          loadChildren: () => import('./modules/sdk-management/sdk-management.module').then(m => m.SdkManagementModule),
          canActivate : [WorkbenchGuardService]
        },
        {
          path: 'upgrade/multi-tenantPod-management',
          loadChildren: () => import('./modules/multi-tenant-pods/multi-tenant-pods.module').then(m => m.MultiTenantPodsModule),
          canActivate : [MtpodGuardService]
        },
        {
          path: 'web-app-management',
          loadChildren: () => import('./modules/web-app-management/web-app.module').then(m => m.WebAppModule),
          canActivate : [WebAppGuardService]
        },
        {
          path: 'sku-management/ici-component-deployment',
          loadChildren: () => import('./modules/ici-component-deployment/ici-component-deployment.module').then(m => m.IciComponentDeploymentModule),
          canActivate : [IciComponentDeploymentGuard]
        },
        {
          path: 'package-details',
          component: PackagesDetailsComponent,
        },
        {
          path: 'developmentandutilities/ici-workertask-configuration',
          loadChildren: () => import('./modules/ici-workertask-configuration-management/ici-workertask-configuration.module').then(m => m.IciWorkertaskConfigurationModule),
          canActivate : [IciWorkerTaskConfigurationGuardService]
        },
        {
          path:'actionables',
          component: ApprovalManagementComponent
        },
        {
          path:'actionables/my-approvals',
          component:ApprovalListComponent
        },
        {
          path:'actionables/my-approvals/view-approvals',
          component:ViewApprovalComponent
        }
      ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: QuicklinkStrategy}),RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
