import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateInstanceGuard } from 'src/app/core/Guards/createinstanceGuard/create-instance.guard';
import { EditInstanceGuard } from 'src/app/core/Guards/editinstanceGuard/edit-instance.guard';
import { CreateInstanceComponent } from './create-instance/create-instance.component';
import { EditInstanceComponent } from './edit-instance/edit-instance.component';
import { InstanceListComponent } from './instance-list/instance-list.component';
import { ViewInstanceComponent } from './view-instance/view-instance.component';
import { ConfigDrawerComponent } from './config-drawer/config-drawer.component';
import { InitiateDeploymentComponent } from './initiate-deployment/initiate-deployment.component';
import { ProvisionUsersComponent } from './provision-users/provision-users.component';
import { InstanceToolsComponent } from './instance-tools/instance-tools/instance-tools.component';
import { InstancetoolsHistoryComponent } from './instance-tools/instancetools-history/instancetools-history.component';
import { IciWorkertaskConfigurationComponent } from './ici-workertask-configuration/ici-workertask-configuration.component';
import { InstanceApprovalComponent } from './instance-approval/instance-approval.component';
import { LandingPageComponent } from './instance-tools/landing-page/landing-page.component';
import { ViewEsignComponent } from './instance-tools/view-esign/view-esign.component';
import { ViewDblogsComponent } from './instance-tools/view-dblogs/view-dblogs.component';


const routes: Routes = [
  {
    path: '',
    component: InstanceListComponent
  },
  {
    path:'create',
    component: CreateInstanceComponent,
    canActivate:[CreateInstanceGuard]
  },
  {
    path:'view',
    component: ViewInstanceComponent
  },
  {
    path:'edit',
    component: EditInstanceComponent,
    canActivate:[EditInstanceGuard]
  },
  {
    path:'config-Key',
    component:ConfigDrawerComponent
  },
  {
    path:'initiate-deployment',
    component:InitiateDeploymentComponent
  },
  {
    path:'provision-users',
    component:ProvisionUsersComponent
  },
  {
    path:'instance-tools',
    component:InstanceToolsComponent
  },
  {
    path:'history',
    component:InstancetoolsHistoryComponent
  },
  {
    path:'ici-workertask-configuration',
    component:IciWorkertaskConfigurationComponent
  },
  {
    path:'instance-approval',
    component:InstanceApprovalComponent
  },
  {
    path:'instance-tools-landing',
    component:LandingPageComponent
  },
  {
    path:'history/view-esign',
    component:ViewEsignComponent
  },
  {
    path:'history/view-dblogs',
    component:ViewDblogsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InstanceManagementRoutingModule { }
