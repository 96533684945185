import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-ici-academy-certification-error',
  templateUrl: './ici-academy-certification-error.component.html',
  styleUrls: ['./ici-academy-certification-error.component.scss']
})
export class ICIAcademyCertificationErrorComponent implements OnInit {

  constructor(
    private router: Router,
    private titleService: Title,
    private msalService: MsalService,
  ) {
   }

  ngOnInit(): void {
    this.titleService.setTitle('ICI Certification | IDN');
  }
 
}
