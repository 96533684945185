import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebAppRoutingModule } from './web-app-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { DateFormatPipe } from 'src/app/core/Pipes/certification/date-format.pipe';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    WebAppRoutingModule,
    MaterialModule,
    PageHeaderModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class WebAppModule {}
