import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IciComponentDeploymentService {

  constructor(private http: HttpClient) {}
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;

  public getICIVersionsAsync(): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/ICIComponentDeployment/ICIVersions', {
      headers: this.headers,
      observe: 'response',
    });
  }

  public getPublicSKUsbyICIVersion(
    multiTenantPodMajor: number,
    multiTenantPodMinor: number,
    multiTenantPodPatch: number
  ) {
    return this.http.get(
      this.baseApiUrl + 'api/ICIComponentDeployment/PublicSKUs',
      {
        params: {
          multiTenantPodMajor: multiTenantPodMajor,
          multiTenantPodMinor: multiTenantPodMinor,
          multiTenantPodPatch: multiTenantPodPatch,
        },
        headers: this.headers,
        observe: 'response',
      }
    );
  }

  public getAllDeployementList(
    filter: string,
    sortBy: string,
    pageNumber: number,
    pageSize: number,
    status: string,
    startDate: string,
    endDate: string
  ) {
    return this.http.get(this.baseApiUrl + 'api/ICIComponentDeployment/List', {
      params: {
        filter: filter,
        sortBy: sortBy,
        pageNumber: pageNumber,
        pageSize: pageSize,
        status: status,
        deploymentDateFrom: startDate,
        deploymentDateTo: endDate,
      },
      headers: this.headers,
    });
  }

  public requestForDeployment(data: any) {
    return this.http.post(
      this.baseApiUrl + 'api/ICIComponentDeployment/Create',
      data,
      { headers: this.headers, observe: 'response' }
    );
  }

  public getDeploymentByID(id: any) {
    return this.http.get(this.baseApiUrl + 'api/ICIComponentDeployment/Details?id=' + id, {
      headers: this.headers,
      observe: 'response',
    });
  }
}
