export enum CustomerMgmtStaticString {
  /* HEADERS */
  customer_header_create = "Create Customer",
  customer_header_edit = "Edit Customer",
  customer_header_view = "Customer",
  customer_header_approval_dialog = "Submit for Approval",
  /* LABELS AND BUTTONS */
  customer_label_customer_name = "Customer Name",
  customer_label_partner_name = "Partner Name",
  customer_label_region = "Region",
  customer_label_first_name = "Contact First Name",
  customer_label_last_name = "Contact Last Name",
  customer_label_email = "Email Address",
  customer_label_contact_primary = "Contact Number Primary",
  customer_label_contact_secondary = "Contact Number Secondary",
  customer_label_required_ici_product = "Required ICI Products",
  customer_label_required_details = "Required Details",
  customer_label_documents = "Supporting Documents",
  customer_label_environment_details = "Environment Details",
  customer_label_user_limit = "User Limit",
  customer_userlimit_info_note = "Specifies the number of IDN Customer users that can be created in the system.",
  customer_label_sandbox = "Sandbox",
  customer_label_development = "Development",
  customer_label_uat = "UAT",
  customer_label_training = "Training",
  customer_label_pre_production = "Pre-Production",
  customer_label_pre_revenue = "Revenue",
  customer_label_comments = "Approve/ Reject Comments",
  customer_label_status = "Status",
  customer_label_search = "Search Customer",
  customer_label_clear_filter = "Clear Filter",
  customer_label_file_browse_btn = "Browse",
  customer_label_file_download_btn = "Download Files",
  customer_label_approve_relect_btn = "Approve/ Reject",
  customer_label_submit_approval_btn = "Submit for Approval",
  customer_label_file_browse_upload_btn = "Browse & Upload",
  customer_label_create_save_next_btn = "Next",
  customer_label_create_save_previous_btn = "Previous",
  customer_label_create_save_and_send_approval_btn = "Save & Send for Approval",
  customer_label_create_primary_btn = "Save",
  customer_label_create_save_approve_btn = "Save & Approve",
  customer_label_form_cancel = "Cancel",
  customer_label_edit_primary_btn = "Update",
  customer_label_activate_btn = "Activate",
  customer_label_deactivate_btn = "Deactivate",
  customer_label_approver_note = "Note to Approver",
  customer_label_no_ref_document_heading = "No Reference Documents",
  customer_label_no_ref_document_body = "we could not find any relevant document uploaded",
  customer_label_approve_btn = "Approve",
  customer_label_reject_btn = "Reject",
  customer_label_sfdc_customerId = "SFDC Customer ID",
  customer_label_netsuiteId = "NetSuite ID",
  customer_label_customer_status = "Status",
  customer_label_approval_status = "Approval Status",
  customer_label_alm_subscription = "ALM Subscription",
  /* FIELD ERROR MESSAGES */
  customer_error_customer_name = "Valid characters include A-z and 0-9.",
  customer_error_first_name = "Valid characters include A-z and 0-9.",
  customer_error_last_name = "Valid characters include A-z and 0-9.",
  customer_placeholder_email = "Please enter in the format test@example.com",
  customer_error_email = "Please enter in the format test@example.com",
  customer_error_contact = "Valid characters include 0-9,+ and -",
  customer_error_note_character = "You can enter A-Z,a-z,0-9 and special characters.",
  customer_error_approve = "You can enter A-Z,a-z,0-9,- and no special characters.",
  customer_error_sfdc_customerId = "Enter a valid SFDC Customer ID. It is alphanumeric and 18 characters long.",
  customer_error_user_limit = "User limit should be less than ",
  customer_error_netsuiteId = "Enter a valid NetSuite ID.",
  /* FIELD HINT MESSAGES */
  customer_hint_alm_subscription = "If turned on, the Application Lifecycle Management subscription is enabled, facilitating user account creation for customers. If turned off, only partner users can be created for this customer.",
  customer_hint_documents = "You can upload multiple files up to 50 MB each. Supported file types: PDF, DOC, DOCX, XLS, TXT.",
  customer_hint_documents_drawer = "Following are the list of documents available for download. ",
  customer_hint_approve = "You can add note to approver of maximum 200 characters.",
  customer_hint_note = "You can enter upto 200 characters.",
  /* DIALOG HEADING MESSAGE AND BUTTONS */
  customer_dialog_deactivate_heading = "Confirm Deactivate",
  customer_dialog_deactivate_body = "Are you sure you want to deactivate this customer?",
  customer_dialog_activate_heading = "Confirm Activate",
  customer_dialog_activate_body = "Are you sure you want to activate this customer?",
  /* NOTIFICATION MESSAGE AND HEADING */
  customer_notify_activated_heading = "Customer Activated",
  customer_notify_activated_body = "Customer has been activated Successfully",
  customer_notify_not_activated_heading = "Customer not Activated",
  customer_notify_not_activated_body = "Customer activation failed",
  customer_notify_deactivated_heading = "Customer DeActivated",
  customer_notify_deactivated_body = "Customer has been Deactivated Successfully",
  customer_notify_not_deactivated_heading = "Customer deactivation failed",
  customer_notify_not_deactivated_body = "The Customer could not be deactivated as it was already assigned to users.",
  customer_notify_not_deactivate_failed_heading = "Customer not Deactivated",
  customer_notify_not_deactivate_failed_body = "Customer deactivation failed",
  customer_notify_requested_heading = "Customer requested",
  customer_notify_requested_body = "Customer has been sent for approval.",
  customer_notify_required_comment_heading = "Invalid comment",
  customer_notify_required_comment_body = "Please enter required field.",
  customer_notify_request_approved_heading = "Customer request approved",
  customer_notify_request_approved_body = "Customer request is approved",
  customer_notify_request_rejected_heading = "Customer request rejected",
  customer_notify_request_rejected_body = "Customer request is rejected.",
  customer_notify_select_file_heading = "Files not selected",
  customer_notify_select_file_body = "Please select files to download.",
  customer_notify_file_downloaded_heading = "Documents downloaded",
  customer_notify_file_downloaded_body = "Customer reference documents has been downloaded.",
  customer_notify_file_not_downloaded_heading = "Download Failed",
  customer_notify_file_not_downloaded_body = "Failed to download Customer reference documents.",
  customer_notify_invalid_file_type_heading = "Invalid file type",
  customer_notify_invalid_file_type_body = "Please upload valid file type.",
  customer_notify_exceed_file_size_heading = "File size exceeded",
  customer_notify_exceed_file_size_body = "File size is more than 50 MB.",
  customer_notify_emptyFile_size_heading = "Empty File Selected",
  customer_notify_emptyFile_size_body = "Uploaded file is an empty file, please check and upload a valid file.",
  customer_notify_choose_file_heading = "No File choosen",
  customer_notify_choose_file_body = "Please choose file.",
  customer_notify_duplicate_file_heading = "Duplicate File",
  customer_notify_duplicate_file_body = "File Already Added.",
  customer_notify_required_field_heading = "Customer Not Created",
  customer_notify_required_field_body = "Please enter required field.",
  customer_notify_not_updated_heading = "Customer Not Updated",
  customer_notify_not_updated_body = "Please enter required field.",
  customer_notify_customer_approved_heading = "Customer approved",
  customer_notify_customer_approved_body = "Customer is created and approved successfully.",
  customer_notify_customer_requested_heading = "Customer requested",
  customer_notify_customer_requested_body = "Customer is submitted for approval successfully.",
  customer_notify_customer_created_draft_heading = "Customer created in draft",
  customer_notify_customer_created_draft_body = "Customer created in draft mode successfully.",
  customer_notify_file_upload_failed_heading = "Upload failed",
  customer_notify_file_upload_failed_body = "File upload failed.",
  customer_notify_not_created_heading = "Customer not created",
  customer_notify_customer_exist_heading = "Customer already exists",
  customer_notify_customer_exist_body = "Customer Name is already exists.",
  customer_notify_valid_comment_heading = "Not valid comments",
  customer_notify_valid_comment_body = "Please enter required field as per validations.",
  customer_notify_updated_heading = "Customer is Updated",
  customer_notify_updated_body = "Customer has been updated successfully.",  
  customer_notify_alm_subscription_body = "To turn on Application Lifecycle Management subscription for this customer, click ",
  /* No Result Found */
  customer_no_result_msg = "No Customer Found!",
  bulkaction_viewlogs_download_success_body="File downloaded successfully",
  bulkaction_viewlogs_download_head="File download",
  bulkaction_viewlogs_download_error_body="Error in downloading the file",
  customer_notify_clm_admin_exists_heading = "Customer Update Error",
  customer_notify_clm_admin_exists = "You cannot remove the following partners while users linked to them have CLM Admin privileges:",
  customer_notify_new_partner_associated = "After assigning a partner, grant them access to the required customer resources, and update their users to associate with the customer.",
  customer_notify_partner_unassociated = "Removing a partner will revoke its users’ access to the customer’s resources."
}
