import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeploymentRoutingModule } from './deployment-routing.module';
import { MaterialModule } from 'src/app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { DeploymentComponent } from './deployment/deployment.component';
import { ViewDeploymentComponent } from './view-deployment/view-deployment.component';


@NgModule({
  declarations: [
    DeploymentComponent,
    ViewDeploymentComponent
  ],
  imports: [
    DeploymentRoutingModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    PageHeaderModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
  ]
})
export class DeploymentModule { }
