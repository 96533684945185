import { Pipe, PipeTransform } from '@angular/core';
import { Role } from 'src/app/modules/role-management/role-list/role-list.component';

@Pipe({
  name: 'categoryById'
})
export class CategoryByIdPipe implements PipeTransform {

  // Custom Pipe to show category name
  transform(categoryId: number, categories: Role[]): string {
    const category = categories.find(category => category.id === categoryId);
    return category ? category.name : "";
  };

}
