import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IciComponentDeploymentService } from 'src/app/core/services/iciComponentDeployment/ici-component-deployment.service';
import { ICIComponentDeploymentManagement } from 'src/app/shared/enum/iciComponentDeployment';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-create-ici-deployment',
  templateUrl: './create-ici-deployment.component.html',
  styleUrls: ['./create-ici-deployment.component.scss'],
})
export class CreateIciDeploymentComponent implements OnInit {
  public iciComponenetDeploymentEnum = ICIComponentDeploymentManagement;
  breadCrumbItems: any;
  skuList: any = [];
  cnameSuffix = '.icertis.com';
  iciVersionList: any = [];
  multiTenantPodMajor: number = 0;
  multiTenantPodMinor: number = 0;
  multiTenantPodPatch: number = 0;
  multiTenantPodbuild: number = 0;

  constructor(
    private titleService: Title,
    private router: Router,
    private iciComponentDeploymentService: IciComponentDeploymentService,
    private notificationService: NotificationService
  ) {}

  frmIciCompDeploy: FormGroup = new FormGroup({
    cname: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-z0-9][a-z0-9\s\-]*$/),
    ]),
    iciVersion: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[1-9]{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?:)?$/),
    ]),
    skuId: new FormControl('', Validators.required),
    serviceNowId: new FormControl('', [
      Validators.pattern(/^[a-zA-Z0-9][a-z0-9\s]*$/),
    ]),
  });

  ngOnInit(): void {
    this.titleService.setTitle('ICI SKU Deployment | IDN');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'SKU Management', path: 'sku-management/ici-component-deployment' },
      { label: 'ICI SKU Deployment', path: 'sku-management/ici-component-deployment' },
      { label: 'Create', path: 'sku-management/ici-component-deployment/create' },
    ];
    this.getIciVersionList();
  }

  getIciVersionList() {
    try {
      this.iciComponentDeploymentService
        .getICIVersionsAsync()
        .subscribe((resp) => {
          if (resp) {
            let list: any = resp.body;
            let acceptVersion = '8.3.0.0';
            this.iciVersionList = list.filter((version: any) => {
              return version.localeCompare(acceptVersion) >= 0;
            });
            this.iciVersionList = this.iciVersionList?.sort((a:any, b:any) =>{ return b.localeCompare(a, undefined, { numeric: true, sensitivity: 'base' });});
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getPublicSKUsbyICIVersion() {
    let iciversion = this.frmIciCompDeploy.controls['iciVersion']?.value;
    [
      this.multiTenantPodMajor,
      this.multiTenantPodMinor,
      this.multiTenantPodPatch,
    ] = iciversion.split('.');
    try {
      this.iciComponentDeploymentService
        .getPublicSKUsbyICIVersion(
          this.multiTenantPodMajor,
          this.multiTenantPodMinor,
          this.multiTenantPodPatch
        )
        .subscribe((resp) => {
          this.skuList = resp.body;
        });
    } catch (error) {
      console.log(error);
    }
  }

  initiateComponentDeployment() {
    try{
      let selectedSku = this.frmIciCompDeploy.controls['skuId'].value;
      let selectedSkuData: any = [];
      selectedSkuData = this.skuList.map((sku: any) => {
        if (selectedSku.includes(sku.id)) {
          return {
            id: 0,
            ICIDeploymentRequestId: 0,
            extensionTypeId: sku.extensionTypeId,
            skuId: sku.id,
            solutionPackageId: sku.solutionPackageId,
            extensionId: sku.extensionId,
            packageSource: 1,
          };
        }
        return;
      });
      let requestData ={
        cName:this.frmIciCompDeploy.controls['cname'].value + this.cnameSuffix,
        iciVersion:this.frmIciCompDeploy.controls['iciVersion'].value,
        serviceNowTicketId:this.frmIciCompDeploy.controls['serviceNowId'].value,
        ICIDeploymentPackageDTOs:selectedSkuData.filter((value:any)=>value!=undefined)
      }
      this.iciComponentDeploymentService.requestForDeployment(requestData).subscribe((resp:any)=>{
        if (resp.status == 200) {
          this.notificationService.showNotification(
            this.iciComponenetDeploymentEnum.componentDeployment_notify_deploymentInitiated_body,
            'success',
            this.iciComponenetDeploymentEnum.componentDeployment_notify_deploymentInitiated_heading
          );
          this.frmIciCompDeploy.reset();
          this.router.navigate(['home/sku-management/ici-component-deployment']);
        } else {
          this.notificationService.showNotification(
            this.iciComponenetDeploymentEnum.componentDeployment_notify_deploymentNotInitiated_body,
            'error',
            this.iciComponenetDeploymentEnum.componentDeployment_notify_deploymentNotInitiated_heading
          );
          this.frmIciCompDeploy.reset();
        }
      })
    } catch(error){
      console.log(error)
    }
  }

  Cancel() {
    this.frmIciCompDeploy.reset();
    this.router.navigate(['home/sku-management/ici-component-deployment']);
  }
}
