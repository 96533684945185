import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class SkuDeploymentGuardService {

  constructor(
    private router: Router,
    private permissionService: PermissionsService
  ) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if( this.permissionService.hasGlobalDataAccess() ) {
      return true;
    }
    this.router.navigate(['home/access-denied']);
    return false;
  }
}
