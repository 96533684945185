export enum SharedComponent {
    /* HEADERS */
    deployment_request_note = "Note: This is a production instance and deployment will begin post CloudOps's approval.",
    deployment_approval_note = "Note: Choose the day that works best for the  deployment.",
    deployment_date_time_label = "Deployment Start Date & Time (UTC)",
    deployment_date_time_error = "Please select future date & time.",
    laActivity_info_message = "To upgrade all deployed packages in IDN for this pod, enter the target ICI version and click Confirm.",
    starter_faq_question1 = "Can I add existing Icertis instances to IDN?",
    starter_faq_question2 = "How do I provision ICI instances for new customer implementations?",
    starter_faq_question3 = "Can I onboard existing customer projects to IDN?",
    starter_faq_question4 = "Who should I contact for support with various IDN related tasks?",
    starter_faq_question5 = "How do I deploy technical customization to the Production instance?",
    starter_faq_answer1 = "Yes. Email the multitenant instance name to IDNsupport@icertis.com.",
    starter_faq_answer2 = "To request new ICI instances, raise a support ticket using the Icertis Support Portal.",
    starter_faq_answer3 = "Inflight projects are migrated to IDN after project completion. However, for projects in early stages of development, an exception can be made after assessment. To request onboarding of inflight projects, reach out to IDNsupport@icertis.com.",
    starter_faq_answer4 = "• For IDN access/ logistical issues – reach out to your CPS contact.\n • For IDN access or logistics related queries, reach out to your Icertis point of contact.\n • For IDN related issues, raise a support ticket from the Icertis Support Portal. To know more, see Raising support tickets for IDN.\n • For issues related to customer implementations, raise a general support ticket from the Icertis Support Portal.",
    starter_faq_answer5 = 'To deploy technical customization to the Production instance, follow the process documented in "Deploy to Production"',
    approval_popup_instruction = 'Enter the approval reason and click ',
    rejection_popup_instruction = 'Enter the reason for rejection and click ',
    approval_reason_label = 'Approval Reason',
    rejection_reason_label = 'Reason for rejection',
    approve_btn_label = 'Approve',
    reject_btn_label = 'Reject',
    approval_request_header = 'Approval Requests',
    request_type_label = 'Request Type',
    cards_header = 'To Do List',
    approver_req_heading = 'Approver Request',
    awaiting_approval_heading = 'Awaiting Approval',
    View_btn = 'View All',
    view_page_instance_heading = 'Instance',
    view_approval_request_heading = 'Approval Request',
    view_partnerName_label= 'Partner Name',
    view_customerName_label= 'Customer Name',
    cName_label= 'CName',
    requestedBy_label = 'Requested By',
    duration_label = 'Duration',
    status_label = 'Status',
    requester_comment_label = 'Requestor Comment',
    cancel_btn_label = 'Cancel'

}