export const USER_CATEGORY_ID: { [key: string]: number } = {
    Icertis: 1,
    Partner: 2,
    Customer: 3
  };

export const USER_ROLE_ID: { [key: string]: number } = {
    NonIDNUser: 9,    
  };
export const BEC_USER_LIMIT = 5000;

export const REQUEST_TYPE: { [key: string]: number } = {
  GIFTroubleShooter: 12,  
};
export const DATE_FORMAT_STRING = "dd MMMM, yyyy 'at' HH:mm '(UTC)'";