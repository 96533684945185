import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensiontagcountfilter'
})
export class ExtensiontagcountfilterPipe implements PipeTransform {

  transform(value: any[]) : any{
    if(value != null){
      if(value.length > 2){
        return value.slice(2);
        }else{
          return value;
        }
    }
   
  }

}
