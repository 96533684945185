export enum LogoutString {
    /* Logout Dialog strings */
    logout_dialog_heading = "Confirm Logout",
    logout_dialog_body = "Are you sure you want to logout?",
    logout_dialog_primary_btn = 'Logout',
    logout_dialog_secondary_btn = 'Cancel',
    /* Re-direct Dialog strings */
    redirect_dialog_heading = "Confirm Redirection",
    redirect_dialog_body = "The link will open in a new browser tab. Click <b>Yes</b> to confirm.",
    redirect_dialog_primary_btn = 'Yes',
    redirect_dialog_secondary_btn = 'No',
}
