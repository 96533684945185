import { T } from '@angular/cdk/keycodes';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { trim } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { PartnerService } from 'src/app/core/services/partner-management/partner.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PartnerMgmtStaticString } from 'src/app/shared/enum/partnerManagement';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { CustomerService } from 'src/app/core/services/customer-management/customer.service';
@Component({
  selector: 'app-edit-partner',
  templateUrl: './edit-partner.component.html',
  styleUrls: ['./edit-partner.component.scss']
})
export class EditPartnerComponent implements OnInit {
  public PartnerMgmtEnum = PartnerMgmtStaticString;
  public regex = InputPattern;
  EditData: any = {'name':""};
  isTrueSet: any = true;
  UserId: any;
  isUserAssociate: any = false;
  IsActive: boolean = false;
  editPartnerTimestamp: any = "";
  createPartner: any;
  updatePartnerPermission: any;
  dectivatePartner: any;
  activatePartnerPermission: any;
  myInputValuename:any;
  myInputValue:any;
  errorDescriptionField:any;
regexName:any = new RegExp(this.regex.namePattern);
regexDescription: any = new RegExp(this.regex.descriptionPattern);
errorDescription : any = this.regex.errorMessage;
firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;
errorDescriptionName:any;
deploymentRegions: any= [];
copilotUsedPrompt: any=0;
copilotPlan:any=[
  {
    name: "Plan-1",
    id:0,
    value: 500
  },
  {
    name: "Plan-2",
    id:1,
    value: 2000
  },
  {
    name: "Plan-3",
    id:2,
    value: 3000
  },
  {
    name: "Custom",
    id:3,
    value: 0
  },
]
  constructor(
    private titleService: Title,
    private partnerService: PartnerService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private router: Router,
    private permssionService: PermissionsService,
    private customerService: CustomerService,

  ) { }
  frmEditPartner: FormGroup = new FormGroup({
    id: new FormControl(0),
    partnerCode: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]),
    name: new FormControl('', [Validators.pattern(this.regexName)]),
    description: new FormControl('',[Validators.pattern(this.regexDescription)]),
    regionId: new FormControl(null),
    status: new FormControl(true),
    partnerUserQuota: new FormControl(50, Validators.required),
    copilotPromptQuota: new FormControl(500, [Validators.pattern(/^[0-9]+$/)]),
    copilotPlan: new FormControl(0),
  });

  ngOnInit(): void {
    this.createPartner = this.permssionService.getPermission('CreatePartner');
    this.updatePartnerPermission = this.permssionService.getPermission('UpdatePartner');
    this.dectivatePartner = this.permssionService.getPermission('DeactivatePartner');
    this.activatePartnerPermission = this.permssionService.getPermission('ActivatePartner');
    this.titleService.setTitle('Edit Partner | IDN');
    let id = localStorage.getItem("partnerId");
    this.getPartnerByID(id);
    this.getRegions();
  }

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Administration", path: "administration/partner-management" },
    { label: "Partner Management", path: "administration/partner-management" },
    { label: "Edit", path: "administration/partner-management/edit" },
  ];


  valueChangename(event: any) {
    if (event == undefined) {
      this.errorDescriptionName = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionName = this.regex.firstCharacterErrorMessage;
      return;
    }
    else {
      this.errorDescriptionName = this.regex.errorMessage;
    }
  }
  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescriptionField = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionField = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescriptionField = this.regex.errorMessage;
    }
  }
  getPartnerByID(id: any) {
    try {
      this.partnerService.GetPartnerById(id).subscribe((resp: any) => {
        let response;
        response = resp.body;
        if (response != undefined && response != null && response != '') {
          let json = response;
          this.EditData = json;
          this.UserId = json.id;
          this.isTrueSet = json.status;
          this.editPartnerTimestamp = json.timestamp;
          this.copilotUsedPrompt = json.copilotUsedPrompt;
          this.frmEditPartner.patchValue({
            partnerCode: json.partnerCode,
            name: json.name,
            description: json.description,
            status: json.status,
            id: json.id,
            regionId: json.regionId,
            partnerUserQuota: json.partnerUserQuota,
            copilotPlan: json.copilotPromptQuota == 500? 0: json.copilotPromptQuota== 2000? 1: json.copilotPromptQuota == 3000? 2:3,
            copilotPromptQuota : json.copilotPromptQuota
          })
          if(this.frmEditPartner.get('copilotPlan')?.value == 3){
            this.frmEditPartner.get('copilotPromptQuota')?.enable();
          } else {
            this.frmEditPartner.get('copilotPromptQuota')?.disable();
          }
          this.frmEditPartner.controls["partnerCode"].disable();


          // this.checkUserAssociateWithRole(this.frmRole.controls["Id"].value);
          if (this.frmEditPartner.value.status) {
            this.IsActive = false;
          }
          else {
            this.IsActive = true;
            this.frmEditPartner.controls["name"].disable();
            this.frmEditPartner.controls["description"].disable();
            this.frmEditPartner.controls["regionId"].disable();
          }

        }
      })
    }
    catch (error) {
      console.log(error)
    }
  }

  async deactivatePartner() {
    this.isUserAssociate = await this.checkPartnerAssociateWithCustomer(this.UserId);
    if (this.isUserAssociate.status == 200) {
      this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_linked_body, 'error', this.PartnerMgmtEnum.partner_notify_linked_heading);
      this.ngOnInit();
    }
    else {
      this.dialogService.confirmDialog({
        title: this.PartnerMgmtEnum.partner_dialog_deactivate_heading,
        module: 'partner',
        message: this.PartnerMgmtEnum.partner_dialog_deactivate_body,
        confirmText: this.PartnerMgmtEnum.partner_dialog_deactivate_primary_btn,
        cancelText: this.PartnerMgmtEnum.partner_dialog_cancel_btn
      }).subscribe(res => {
        if (res) {
          this.partnerService.deActivatePartner(this.UserId, false).subscribe((res: Response) => {
            let Result: any = res.body;
            if (res.status == 200) {
              this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_deactivated_body, 'success', this.PartnerMgmtEnum.partner_notify_deactivated_heading);
              this.router.navigate(["home/administration/partner-management"]);
            } else {
              this.notificationService.showNotification(Result.message, 'error', this.PartnerMgmtEnum.partner_notify_not_deactivated_heading);
              this.router.navigate(["home/administration/partner-management"]);
            }
            // this.GetPartners();
          },  (error: HttpErrorResponse) =>{
            let message = this.PartnerMgmtEnum.partner_notify_already_deactivated_body;
            this.notificationService.showNotification(message, 'warning', error.toString());
          })

        }
      })
    }
  }


  //To Activate Role Status
  activatePartner() {
    this.dialogService.confirmDialog({
      title: this.PartnerMgmtEnum.partner_dialog_activate_heading,
      module: 'partner',
      message: this.PartnerMgmtEnum.partner_dialog_activate_body,
      confirmText: this.PartnerMgmtEnum.partner_dialog_activate_primary_btn,
      cancelText: this.PartnerMgmtEnum.partner_dialog_cancel_btn
    }).subscribe((res: any) => {
      if (res) {
        this.partnerService.activatePartner(this.UserId, true).subscribe((res: Response) => {
          let Result: any = res.body;
          if (res.status == 200) {
            this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_activated_body, 'success', this.PartnerMgmtEnum.partner_notify_activated_heading);
            this.router.navigate(["home/administration/partner-management"]);
          } else {
            this.notificationService.showNotification(Result.message, 'warning', this.PartnerMgmtEnum.partner_notify_not_activated_heading);
            this.router.navigate(["home/administration/partner-management"]);
          }
          // this.GetPartners();
        },  (error: HttpErrorResponse) =>{
          let message = this.PartnerMgmtEnum.partner_notify_already_activated_body;
          this.notificationService.showNotification(message, 'warning', error.toString());
        })

      }
    })
  }

  //To check User Associate With Role
  async checkPartnerAssociateWithCustomer(id: number): Promise<any> {
    if (id != null && id != undefined && id > 0) {
      return await lastValueFrom(this.partnerService.CheckPartnerAssociateWithCustomer(id));
    }
    else {
      this.router.navigate(["home/administration/partner-management"]);
    }
  }

  //-- To Update Data--
  async updatePartner() {
    let jsonrole = this.frmEditPartner.getRawValue();
    jsonrole['timestamp'] = this.editPartnerTimestamp;
    jsonrole["copilotUsedPrompt"] = this.copilotUsedPrompt;
    let isexist = await this.checkIfPartnerExists(trim(jsonrole.name), trim(jsonrole.partnerCode),jsonrole.id)
    if (isexist.body != undefined && !isexist.body) {
      //jsonrole['timestamp'] =this.editRoleTimeStamp;
      this.partnerService.PutData("api/Partner/Update", {}, jsonrole).subscribe((result: any) => {
        if (result != null && result != undefined) {
          if (result.status == 200) {
            this.router.navigate(["home/administration/partner-management"]);
            this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_updated_body, 'success', this.PartnerMgmtEnum.partner_notify_updated_heading);
          }
          else {
            this.router.navigate(["home/administration/partner-management"]);
            this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_not_updated_body, 'error', this.PartnerMgmtEnum.partner_notify_not_updated_heading);
          }
        }
      })
    } else {
      this.notificationService.showNotification(this.PartnerMgmtEnum.partner_notify_exist_body, 'error', this.PartnerMgmtEnum.partner_notify_exist_heading);
      //this.frmRole.reset();
      //this.router.navigate(["home/role-management"]);
    }
  }

  onCancel() {
    this.router.navigate(["home/administration/partner-management"]);
  }

  async checkIfPartnerExists(name: any, partnerCode: any,id:any) {
    if (name != null && name != undefined) {
      return await lastValueFrom(this.partnerService.CheckPartnerName(name, partnerCode,id));
    }
    else {
      this.router.navigate(["home/administration/partner-management"]);
    }
  }

  getRegions() {
    try {
      this.customerService.getRegions().subscribe((resp: any) => {
        if (resp != null) {
          this.deploymentRegions = resp;
        }
      })
    }
    catch (error) {
      console.log(error);
    }
  }

  selectedCopilotPlan(){
    let selectedPlanId = this.frmEditPartner.get('copilotPlan')?.value;
    if(selectedPlanId !== 3){
      this.frmEditPartner.get('copilotPromptQuota')?.disable();
      let planValue = this.copilotPlan?.filter((data:any)=> data.id==selectedPlanId)[0].value;
      this.frmEditPartner.get('copilotPromptQuota')?.setValue(planValue);
    } else if(selectedPlanId == 3){
      this.frmEditPartner.get('copilotPromptQuota')?.enable();
      this.frmEditPartner.get('copilotPromptQuota')?.setValue(this.EditData?.copilotPromptQuota);
    }
  }
}
