<app-pageheader [breadCrumbs]="this.breadCrumbItems"></app-pageheader>

<div class="page-container">

  <div class="idn-view-wrapper">
    <div class="idn-view-header">
      <div class="data-left">
        <div class="idn-view-header-label">{{deploymentEnum.deployment_View_Heading}}</div>
        <div class="idn-view-header-data">
          <h3>{{ViewData.customerName}}</h3>
          <em><b>Created on: {{ViewData.createdAt | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'" }} by {{
              ViewData.requesterdDetail }}</b></em>
        </div>
      </div>
    </div>

    <div class="idn-view-body pss-dashboard-form top-spacer">
      <mat-grid-list cols="3" class="idn-view-body" rowHeight="80px">
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_partnerName}}</div>
            <div class="idn-view-body-items-data">{{ViewData.partnerDetail?.length ? ViewData.partnerDetail : 'NA'}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_customerName}}</div>
            <div class="idn-view-body-items-data">{{ViewData.customerName}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_type}}</div>
            <div class="idn-view-body-items-data">{{ViewData.instanceType}}</div>
          </div>
        </mat-grid-tile>

      </mat-grid-list>
      <mat-grid-list cols="3" rowHeight="80px">
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_cName}}</div>
            <div class="idn-view-body-items-data">{{ViewData.cnameToEnter}}</div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_region}}</div>
            <div class="idn-view-body-items-data">{{ViewData.regionName}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_status}}</div>
            <div class="idn-view-body-items-data">
              <div class="pss-chips-status">
                <mat-icon class="idn-cell-status c-status-info" *ngIf="ViewData.statusId == 1">bookmark</mat-icon>
                <mat-icon class="idn-cell-status c-status-warning" *ngIf="ViewData.statusId == 2"> auto_mode
                </mat-icon>
                <mat-icon class="idn-cell-status c-status-warning" *ngIf="ViewData.statusId == 3">warning
                </mat-icon>
                <mat-icon class="idn-cell-status c-status-success" *ngIf="ViewData.statusId == 4">check_circle
                </mat-icon>
                <mat-icon class="idn-cell-status c-status-danger" *ngIf="ViewData.statusId == 5">dangerous</mat-icon>
                <mat-icon class="idn-cell-status c-status-warning" *ngIf="ViewData.statusId == 6">inventory_2</mat-icon>
                <mat-icon class="idn-cell-status c-status-danger" *ngIf="ViewData.statusId == 7">inventory_2</mat-icon>
                <mat-icon class="idn-cell-status c-status-success" *ngIf="ViewData.statusId == 8">inventory_2</mat-icon>
                <mat-icon class="idn-cell-status c-status-warning"
                  *ngIf="ViewData.statusId == 9">hourglass_empty</mat-icon>
                <span class="label" [ngClass]="[ViewData.status === 'Failed' ? 'c-status-danger' : '',
                   ViewData.status === 'Request Rejected' ? 'c-status-warning' : '']">{{ViewData.status}}</span>
              </div>
            </div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list cols="3" rowHeight="80px">
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_View_Field_requestorDetails}}</div>
            <div class="idn-view-body-items-data">
              <span class="view-user-info">{{ViewData.requesterdDetail}}</span>
              <span class="view-timestamp">on {{ViewData.createdAt | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}</span>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" *ngIf="isProdInstance">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_view_deployment_requested_date}}</div>
            <div class="idn-view-body-items-data">
              <span class="view-user-info">{{ViewData.deploymentRequestedDate | date:"dd MMMM, yyyy 'at' HH:mm
                '(UTC)'"}}</span>

            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" *ngIf="ViewData.statusId === 4 || ViewData.statusId === 5">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_List_Action_Label_downloadLogs}}</div>
            <div class="idn-view-body-items-data">
              <div class="mt-1">
                <div class="rows">
                  <mat-chip-list class="idn-chips-download" aria-label="file selection">
                    <mat-chip (click)="downloadDeploymentLog(ViewData.id, ViewData.instanceType)">
                      Download
                      <mat-icon>download</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" >
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{deploymentEnum.deployment_view_deployment_orchestrated_package}}</div>
            <div class="idn-view-body-items-data">
              <div class="mt-1">
                <div class="rows">
                  <mat-chip-list class="idn-chips-download" aria-label="file selection">
                    <mat-chip (click)="downloadOrchestratedPackage(ViewData.id)">
                      {{deploymentEnum.deployment_view_deployment_orchestrated_package_download}}
                      <mat-icon>download</mat-icon>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>
            </div>
          </div>
        </mat-grid-tile>        
      </mat-grid-list>

      <div class="idn-view-package-wrapper mt-2" *ngIf="isProdInstance">
        <div class="header" >
          {{deploymentEnum.deployment_view_approval_details}}
        </div>
        <mat-grid-list cols="3" class="idn-view-body" rowHeight="80px">
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{deploymentEnum.deployment_view_reviewer_details}}</div>
              <div class="idn-view-body-items-data">
                <span class="view-user-info">{{ViewData.reviewerName}}</span>
                <span class="view-timestamp" *ngIf="ViewData.reviewedOn != null">on {{ViewData.reviewedOn | date:"dd
                  MMMM, yyyy
                  'at' HH:mm '(UTC)'"}}</span>
              </div>
            </div>


          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{deploymentEnum.deployment_view_deployment_approved_date}}</div>
              <div class="idn-view-body-items-data">{{ViewData.statusId==3 ? "NA" : ViewData.deploymentApprovedDate | date:"dd MMMM, yyyy 'at' HH:mm
                '(UTC)'"}}</div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile [colspan]="1">
            <div class="idn-view-body-items">
              <div class="idn-view-body-items-label">{{deploymentEnum.deployment_view_comments}}</div>
              <div class="idn-view-body-items-data">{{ViewData.reviewerComment}}</div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <div class="idn-view-package-expansion-wrapper mt-1">
        <mat-accordion>
          <mat-expansion-panel  expanded="true" class="idn-panel">
            <mat-expansion-panel-header class="header">
                <mat-panel-title>
                  Deployment Packages <span class="count">{{ViewData?.deploymentPackageDetails?.length }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="idn-table">
              <table mat-table [dataSource]="dataSource?.data?.slice(0, displayedRows['deployment'])" class="mat-elevation-z8">
    
                  <!-- Deployment For Column -->
                  <ng-container matColumnDef="DeploymentFor">
                      <th mat-header-cell *matHeaderCellDef>
                        {{deploymentEnum.deployment_View_Field_deployment_for}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.packageSource==1?"Customer Extension Package":"SKU
                        Deployment"}}
                      </td>
                  </ng-container>
    
                  <!-- ExtensionType Column -->
                  <ng-container matColumnDef="ExtensionType">
                      <th mat-header-cell *matHeaderCellDef>
                        {{deploymentEnum.deployment_View_Field_extensionType}}</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.extensionType}}
                      </td>
                  </ng-container>
    
                  <!-- ExtensionName Column -->
                  <ng-container matColumnDef="ExtensionName">
                      <th mat-header-cell *matHeaderCellDef>
                       Product / Extension Name</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.sku? element.sku : element.extension}}
                      </td>
                  </ng-container>
    
                  <!-- BinaryFile Column -->
                  <ng-container matColumnDef="BinaryFile">
                      <th mat-header-cell *matHeaderCellDef>
                          {{deploymentEnum.deployment_View_Field_binaryFile}}
                      </th>
                      <td mat-cell *matCellDef="let element">
                          <button mat-button class="idn-grid-link-button download_btn"
                            *ngIf="(!element.sku && !element.isPackageLocked && isInitiateDeployment && element.hasExtensionAccess) "
                            (click)="downloadSingleFile(element.solutionPackageId,[element.binaryFileName],['Package'])">
                            <div class="button-name">
                              <div class="idn-view-body-items-button-data" matTooltip="{{element.solutionPackage}}"
                                matTooltipPosition="below" matTooltipClass="my-tooltip">{{element.solutionPackage}}</div>
                              <mat-icon class="download-icon">download
                              </mat-icon>
                            </div>
                          </button>
                          <div class="idn-view-body-items-data"
                            *ngIf="element.sku || element.isPackageLocked || !isInitiateDeployment || !element.hasExtensionAccess"
                            matTooltip="{{element.solutionPackage}}" matTooltipPosition="below" matTooltipClass="my-tooltip">
                            {{element.solutionPackage}}</div>
                        
                      </td>
                  </ng-container>
    
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let rows;columns: displayedColumns;">
                    </tr>
                  
              </table>
              <div class="show-option" *ngIf="ViewData?.deploymentPackageDetails?.length > displayedRows['deployment'] || displayedRows['deployment'] > minRecords">
                <button mat-raised-button color="primary" class="pss-approve-btn" (click)="showMore('deployment')" *ngIf="ViewData?.deploymentPackageDetails?.length > displayedRows['deployment']">View More</button>
                <button mat-raised-button color="primary" class="pss-approve-btn" (click)="showLess('deployment')" *ngIf="displayedRows['deployment'] > minRecords">View Less</button>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel  class="idn-panel" *ngIf="configDataSource?.data?.length">
            <mat-expansion-panel-header class="header">
                <mat-panel-title>
                  Modified Config Keys <span class="count">{{configDataSource?.data?.length}}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="idn-table">
              <table mat-table [dataSource]="configDataSource?.data?.slice(0, displayedRows['configKey'])" class="mat-elevation-z8">
    
                  <!-- Name Column -->
                  <ng-container matColumnDef="Name">
                      <th mat-header-cell id="mat-column-name" *matHeaderCellDef>
                        Name</th>
                      <td mat-cell *matCellDef="let element" matTooltipClass="tooltip-content" matTooltip="{{element.configName}}" matTooltipPosition="above"
                      [matTooltipDisabled]="isDisabled(nameToolTip)" #nameToolTip>
                        {{element.configName}}
                      </td>
                  </ng-container>
    
                  <!-- value Column -->
                  <ng-container matColumnDef="Value">
                    <th mat-header-cell id="mat-column-value" *matHeaderCellDef>
                      Value</th>
                    <td mat-cell *matCellDef="let element" #valueToolTip>
                      <div class="view-data">
                        <div class="large-data" #content>
                          {{element.value}}
                        </div>
                        <button class="show-button" *ngIf="!isDisabled(content)" (click)="viewMoreData(element)">View more</button>
                      </div>
                    </td>
                  </ng-container>
    
                  <!-- value Type Column -->
                  <ng-container matColumnDef="ValueType">
                      <th mat-header-cell id="mat-column-valueType" *matHeaderCellDef>
                       Value Type</th>
                      <td mat-cell *matCellDef="let element">
                        {{element.valueType}}
                      </td>
                  </ng-container>
  
                  <!-- OverrideType Column -->
                  <ng-container matColumnDef="OverrideType">
                    <th mat-header-cell id="mat-column-overrideType" *matHeaderCellDef>
                      Override Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.overrideType}}
                    </td>
                </ng-container>
  
                <!-- IsEncrypted Column -->
                <ng-container matColumnDef="IsEncrypted">
                    <th mat-header-cell id="mat-column-isEncrypted" *matHeaderCellDef>
                      Is Encrypted</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.isEncrypted}}
                    </td>
                </ng-container>
    
                  <tr mat-header-row *matHeaderRowDef="configDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let rows;columns: configDisplayedColumns;">
                    </tr>
                  
              </table>
              <div class="show-option" *ngIf="configDataSource?.data?.length > displayedRows['configKey'] || displayedRows['configKey'] > minRecords">
                <button mat-raised-button color="primary" class="pss-approve-btn" (click)="showMore('configKey')" *ngIf="configDataSource?.data?.length > displayedRows['configKey']">View More</button>
                <button mat-raised-button color="primary" class="pss-approve-btn" (click)="showLess('configKey')" *ngIf="displayedRows['configKey'] > minRecords">View Less</button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      
    </div>
  </div>
  <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
    <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
  </button>
</div>

<div class="pss-form-button-wrapper">
  <button mat-stroked-button color="primary"
    (click)="toggleTrackStatus(ViewData.statusId, ViewData.deploymentStatusChanges)">
    <mat-icon>touch_app</mat-icon> <span>{{deploymentEnum.deployment_View_trackStatus_primary_btn}}</span>
  </button>
  <button mat-stroked-button color="primary" (click)="approveReject(ViewData.id, ViewData.deploymentRequestedDate)"
    *ngIf="ViewData.statusId === 9 && isRejectDeployment && isApproveDeployment">
    <mat-icon>rule</mat-icon> {{deploymentEnum.deployment_constant_approve_reject}}
  </button>
  <button mat-stroked-button (click)="cancel()">{{deploymentEnum.deployment_View_CustomDrawer_Cancel_btn}}</button>
</div>

<mat-drawer-container class="idn-drawer-internal" *ngIf="isTrackStatusDrawer">
  <mat-drawer #drawerInfo class="idn-drawer">
    <div class="header">
      <div class="heading">
        <mat-icon>touch_app</mat-icon>
        <h2>{{deploymentEnum.deployment_View_CustomDrawer_Header_screenHelpMessage}}</h2>
      </div>
      <button mat-icon-button aria-label="Close Button" (click)="closeTrackStatus()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="body pt-1">
      <div class="page-container">
        <div class="idn-view-wrapper">
          <div class="idn-view-body">
            <div class="idn-info">
              <mat-icon>info</mat-icon>
              <h4>
                {{deploymentEnum.deployment_View_CustomDrawer_Title_screenHelpMessage}}
              </h4>
            </div>
            <div class="idn-track-status">


              <div class="steps" *ngFor="let tractStatus of deploymentStatusChanges;let arrayIndex = index">
                <span class={{StatusClasses[arrayIndex]}}>
                  <mat-icon>{{tractStatus.icon}}</mat-icon>
                </span>
                <div class="steps-description">
                  <h4>{{tractStatus.statusDisplayName}}</h4>
                  <p *ngIf="tractStatus.statusId">
                    on {{tractStatus.timestamp.slice(0, 10) | date:'dd MMMM, YYYY'}} at {{tractStatus.timestamp |
                    date:'HH:mm:ss'}} (UTC)
                  </p>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>
      <div class="pss-drawer-button-wrapper">
        <button mat-flat-button (click)="closeTrackStatus()">Close</button>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>