<div class="app-notification-pre-header" @slide *ngIf="isPushnotification && LoginStatus && userNotificationAction">
  <div class="app-notification-content">
    <mat-icon class="material-icons-outlined">help_outline</mat-icon>
    <div class="message">
      {{ appNotification }}
    </div>
  </div>
  <button mat-icon-button class="" (click)="closeNotification()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<main [ngClass]="isPushnotification && LoginStatus && userNotificationAction ? 'app-main-wrapper' : ''">
  <router-outlet></router-outlet>
</main>

