<app-pageheader [pageTitle]="'Role Management / Create Role'" [auditLog]="false" [createRole]="false" [mapRole]="true" [applyFilter]="false" [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
  <div class="form-wrapper">
    <h2>{{pageHeading}}</h2>

    <form class="pss-dashboard-form" [formGroup]="frmRole">
      <mat-form-field class="pss-block-form">
        <mat-label>{{labelName}}</mat-label>
        <input type="text" matInput maxlength="100" placeholder="Enter Name of Role" formControlName="Name"  required>
        <mat-error *ngIf="frmRole.controls.Name.invalid">{{errorName}}</mat-error>
      </mat-form-field>

      <mat-form-field class="pss-block-form">
        <mat-label>{{labelDescription}}</mat-label>
        <textarea matInput maxlength="500" placeholder="Description of Role" formControlName="Description" required class="idn-min-textarea-height" (ngModelChange)="valueChange($event)"></textarea>
        <mat-error *ngIf="frmRole.controls.Description.invalid">{{errorDescription}}</mat-error>
      </mat-form-field>

      <mat-form-field class="pss-block-form">
        <mat-label>Role Category</mat-label>
        <mat-select formControlName="UserCategoryId" aria-hidden="true" required>          
          <mat-option *ngFor="let role of roleCategory" [value]="role.id">
            {{role.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</div>
<div class="pss-form-button-wrapper">
  <button mat-raised-button color="primary" [disabled]="!frmRole.valid" (click)="createRole()">{{actionSave}}</button>
  <button mat-stroked-button (click)="Cancel()">{{actionCancel}}</button>
</div>
