import { HttpHandler, HttpErrorResponse, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { throwError } from "rxjs";
import { catchError } from "rxjs";
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { HttpErrorMsgStaticString } from 'src/app/shared/enum/httpErrorMessages';
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: 'root'

})

export class HttpErrorInterceptorService implements HttpInterceptor {
  public HttpErrorMessage = HttpErrorMsgStaticString;
  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private msalService: MsalService,
  ) { }
  errorHeading: string ='';
  errorDescription: string = '';

  intercept(request: HttpRequest<any>, next: HttpHandler) {
      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            const errorMessage = this.setError(error);
            return throwError(errorMessage);
          })
        );
  }

  setError(error: HttpErrorResponse): string {
    let errorMessage = error.error;
    let errorUrl = error.url;
    if(error.error instanceof ErrorEvent){
      // Client Side Error
      errorMessage = error.error.message;
    } else {
      // Server Side Error
      if(error.status !== 0 ){
        let errorCode: number = error.status;
        switch ( errorCode ){
          case 400:
            if(error.error === 'User not be found or not active'){
              this.errorHeading = this.HttpErrorMessage.error_400_unauthorized_heading;
              this.errorDescription = this.HttpErrorMessage.error_400_unauthorized_body;
              this.router.navigate(['un-authorized']);
            } else if(error.error === 'User not be found in IDN'){
              this.errorHeading = this.HttpErrorMessage.error_400_general_heading;
              this.errorDescription = error.error;
              this.msalService.instance.logoutRedirect();
            } else if(error.error === 'Another deployment is running on the same instance'){
              this.errorHeading = this.HttpErrorMessage.error_400_initiate_deployment_heading;
              this.errorDescription = error.error;
            } else if(error.error === 'TargetICIVersion is not in correct format'){
              this.errorHeading = this.HttpErrorMessage.error_400_general_heading;
              this.errorDescription = this.HttpErrorMessage.error_400_initiate_pod_upgrade_body;
            } else {
              this.errorHeading = this.HttpErrorMessage.error_400_general_heading;
              this.errorDescription = error.error;
            }
            break;
          case 401:
            let errorObject=error.error;
            if(errorObject?.error === 'User have insufficient privileges to login to the IDN.'){
              this.errorHeading = this.HttpErrorMessage.error_401_general_heading;
              this.errorDescription = errorObject.error;
            } else {
              this.errorHeading = this.HttpErrorMessage.error_401_general_heading;
              this.errorDescription = this.HttpErrorMessage.error_401_general_body;
            }
            break;
          case 403:
            this.errorHeading = this.HttpErrorMessage.error_403_general_heading;
            this.errorDescription = this.HttpErrorMessage.error_403_general_body;
            break;
          case 404:
            this.errorHeading = this.HttpErrorMessage.error_404_general_heading;
            this.errorDescription = this.HttpErrorMessage.error_404_general_body;
            break;
          case 408:
            this.errorHeading = this.HttpErrorMessage.error_408_general_heading;
            this.errorDescription = this.HttpErrorMessage.error_408_general_body;
            break;
          case 409:
            this.errorHeading = this.HttpErrorMessage.error_409_general_heading;
            if(error.error){
              this.errorDescription = error.error;
            } else {
              this.errorDescription = this.HttpErrorMessage.error_409_general_body;
            }
            break;
          case 500:
            if(error.error === "We couldn't retrieve the requested data at the moment. Please try soon."){
              this.errorHeading = this.HttpErrorMessage.error_500_instance_health_error_heading;
              this.errorDescription = this.HttpErrorMessage.error_500_instance_health_error_body;
            } else {
              this.errorHeading = this.HttpErrorMessage.error_500_general_heading;
              this.errorDescription = this.HttpErrorMessage.error_500_general_body;
            }
            break;
          case 502:
            this.errorHeading = this.HttpErrorMessage.error_502_general_heading;
            this.errorDescription = this.HttpErrorMessage.error_502_general_body;
            break;
          case 503:
          case 504:
           this.handlerror(errorUrl,errorCode);
            break;
          default:
            this.errorHeading = this.HttpErrorMessage.error_default_general_heading;
            this.errorDescription = this.HttpErrorMessage.error_default_general_body;
        }
        if((errorCode==503 || errorCode==504) && (errorUrl?.includes("FlushRedisCache") || errorUrl?.includes("RestartRole"))){
          this.notificationService.showNotification(this.errorDescription, 'success', this.errorHeading);
        }
        else{
        this.notificationService.showNotification(this.errorDescription, 'warning', this.errorHeading);
        }
      }
    }
    return errorMessage;
  }

  handlerror(errorUrl:any, errorCode:number){
    if(errorUrl?.includes("FlushRedisCache") || errorUrl?.includes("RestartRole")){
      this.errorHeading = this.HttpErrorMessage.error_503_504_cacheflush_rolerestart_heading;
      this.errorDescription = this.HttpErrorMessage.error_503_504_cacheflush_rolerestart_body;        
    } else if(errorCode ==503){
      this.errorHeading = this.HttpErrorMessage.error_503_general_heading;
      this.errorDescription = this.HttpErrorMessage.error_503_general_body;
    }
    else if(errorCode ==504){
      this.errorHeading = this.HttpErrorMessage.error_504_general_heading;
      this.errorDescription = this.HttpErrorMessage.error_504_general_body;
    }   
  }
}
