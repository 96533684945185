export enum MultiTenantPods {
  multiTenantPods_Filter_Search_label_searchInstance = 'Search Multitenant Pod',
  multiTenantPods_Region = 'Region',
  multiTenantPods_Subscription = 'Subscription',
  multiTenantPods_PodName = 'POD Name',
  multiTenantPods_ici_Version = 'ICI Version',
  multiTenantPods_upgrade_Status = 'upgrade Status',
  multiTenantPods_Actions = 'Action',
  multiTenantPods_Actions_List_Action_Label_trigger_deployment_btn = 'Trigger Deployment',
  multiTenantPods_Actions_List_Action_Label_upgrade_pod_btn = 'Upgrade',
  multiTenantPods_Actions_List_Action_Label_trackStatus_btn = 'Track Status',
  multiTenantPods_Actions_List_Action_Label_download_btn = 'Download Documents',
  /* No Result Found */
  multiTenantPods_no_result_msg = 'No Instance Found!',

  /*View fields*/
  multiTenantPods_View_Header = 'MT POD for :',
  multiTenantPods_View_Field_region = 'Region',
  multiTenantPods_View_Field_extension = 'Tenant List',
  multiTenantPods_View_Field_subscription = 'Subscription',
  multiTenantPods_View_Field_podName = 'POD Name',
  multiTenantPods_View_Field_ExtensionIciVersion = 'Extension ICI Version',
  multiTenantPods_View_Field_PODIciVersion = 'ICI Version',
  multiTenantPods_View_Field_tenantName = 'Tenant Name',
  multiTenantPods_View_Field_extensionName = 'Extension Name',
  multiTenantPods_View_Field_version = 'Version',
  multiTenantPods_upgrade_pod = 'Upgrade',
  multiTenantPods_cancel = 'Cancel',
  multiTenantPods_upgrade_pod_notification_header = 'Deployment requested',
  multiTenantPods_upgrade_pod_notification = 'Deployment request for selected packages initiated successfully',
  multiTenantPods_upgrade_pod_downgrade_message = "Please select version greater than current version",
  /*upgrade*/
  multiTenantPods_upgrade_to_version = 'Upgrade to Version:',
  multiTenantPods_current_version = 'Current Version:',
  package_Create_Field_major = 'Major',
  package_Create_Field_minor = 'Minor',
  package_Create_Field_patch = 'Patch',
  package_Create_Field_build = 'Build',
  multiTenantPods_preview_upgrade = 'Preview Upgrade',
  multiTenantPods_start_upgrade = 'Start Upgrade',
  multiTenantPods_preview_header = 'Upgrade',
  multiTenantPods_preview_feild_instance = 'Tenant Name',
  multiTenantPods_preview_feild_extension = 'Extension',
  multiTenantPods_preview_feild_version = 'Version',
  multiTenantPods_preview_feild_iciVersion = 'ICI Version',
  multiTenantPods_preview_feild_newVersion = 'New Version',
  multiTenantPods_preview_feild_compatible = 'Compatible',
  multiTenantPods_preview_btn_start_upgrade = 'Start Upgrade',
  multiTenantPods_preview_no_content_message='No Extensions Found For Upgrade!',

  /*POD calendar*/

  pod_calendar_Download_btn="Download", 
  pod_calendar_template_downlaod_body="Template downloaded successfully",
  pod_calendar_template_downlaod_heading="Template Download",
  pod_calendar_template_downlaod_Error_body="Error in downloading template",
  pod_calendar_template_downlaod_Error_header="Download error",
  pod_calendar_template_incorrect_file_extension_header = 'File extension is not matching',
  pod_calendar_template_incorrect_file_extension_body ='Please upload the file with correct extension.',
  pod_calendar_template_notify_emptyFile_header = 'Uploaded file is an empty file',
  pod_calendar_template_notify_emptyFile_body = 'please check and upload a valid file.',
  pod_calendar_template_notify_fileSizeExceed_body = 'File size is more than 50 MB',
  pod_calendar_template_notify_fileSieExceed_header= 'File size exceeded',
  pod_calendar_confirm_upload_message = 'File uploaded successfully. Once you trigger the CloudOps notification, we will send an email informing them to perform the next action. Are you sure you want to proceed?',
  pod_calendar_confirm_upload_success_body = 'Upgrade calendar information approved successfully',
  pod_calendar_confirm_upload_success_header = 'Success',
  pod_calendar_file_save_progress_body = 'File upload is in Progress',
  pod_calendar_file_save_progress_header = 'Uploading File',
  pod_calendar_file_save_completed_body =  'File Upload was Successful',
  pod_calendar_file_save_completed_header = 'File Uploaded',
  pod_calendar_error_file_upload_body = 'Error occured while uploading the file.',
  pod_calendar_error_file_upload_header = 'Error Uploading File',
  pod_calendar_complete_pod_upgrade_body = 'You have successfully marked the POD Upgrade as completed',
  pod_calendar_complete_pod_upgrade_header = 'Success',
  pod_calendar_start_pod_upgrade_body =  'You have successfully marked the POD upgrade as started',
  pod_calendar_start_pod_upgrade_header = 'Success',
  pod_calendar_start_upgrade_confirm_message = 'You are notifying the customers that their ICI upgrade has started:',
  pod_calendar_cloudops_homePage_message = 'To get started select a POD and ICI version from the list above',
  pod_calendar_rm_homePage_message = 'Download Excel Template to fill it out, and then use the upload button to submit the information to get started.',
  pod_calendar_rm_file_uplaod_extension_msg = 'Upload the template in .xlsx or .xls format only.',
  pod_calendar_instruction_rm_point1 = 'This page facilitates the upload of the ICI POD upgrade schedules for major versions and maintenance packs.',
  pod_calendar_instruction_rm_point2 = 'The upgrade information can be uploaded via an XLS or XLSX file only. If you have accessed this page for the first time, please download the template onto your system.',
  pod_calendar_instruction_rm_point3 = 'The template would have a pre-defined set of columns and some sample information for your assistance.',
  pod_calendar_instruction_rm_point4 = 'Please do not modify the names of the columns in the template to avoid any discrepancies.',
  pod_calendar_instruction_rm_point5 = 'Kindly ensure to enter the right information and use the same date and time formats as mentioned in the sample data.',
  pod_calendar_instruction_rm_point6 = 'Once you have filled up the template with the required information, upload it from this screen. Once you confirm the upload, you will need to notify the CloudOps user to take over the next activity. In case you missed notifying the CloudOps, you would need to re-upload the file and trigger the CloudOps email notification.',
  pod_calendar_instruction_rm_point7 = 'Once you notify the CloudOps, you are all set! You may continue uploading the next set of upgrade information by performing the same steps. You need not download the template again. You can re-use the available file to modify the required data.',
  pod_calendar_instruction_cloudops_point1 = 'This page facilitates you to trigger email communications to the designated customer users to inform them that their instance upgrade has either started or completed.',
  pod_calendar_instruction_cloudops_point2 = 'These actions do not trigger the actual upgrade operations. This is performed manually and separately outside IDN.',
  pod_calendar_instruction_cloudops_point3 = 'To get started, select a POD and the relevant ICI version from the available list.',
  pod_calendar_instruction_cloudops_point4 = 'Based on the current status, the start or complete operations will be available. Based on the action taken, the relevant email communication will be sent to the customer users.',
  pod_calendar_notification_duplicate_records_heading = 'Duplicate Records Found',
  pod_calendar_notification_duplicate_records_body = 'Uploaded records already exist. Remove duplicates before proceeding.',
  // LA Activity
  la_activity_success_body = 'LA Activity Started Successfully',
  la_activity_success_header = 'LA Activity Started',
  la_activity_failed_header = 'LA Activity Failed',
  la_activity_input_label = 'Please Enter Target ICI Version',
  la_activity_validation_msg = 'Enter valid Target ICI version. It should be >= 8.5.0.0',
  la_activity_btn_label = 'Confirm',
  btn_label = 'Upgrade Customer Packages',

  multiTenantPods_view_field_packageName = 'Package Name',
  multiTenantPods_view_field_package_ici_version = 'Package ICI Version',
  multiTenantPods_view_field_package_status = 'Package Status',
  multiTenantPods_view_field_email_recipient = 'Email Recipient',
  multiTenantPods_view_field_email_recipient_tooltip_no_email_sent = 'Compatible package. No error email sent.',
}
