import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePartnerComponent } from './create-partner/create-partner.component';
import { EditPartnerComponent } from './edit-partner/edit-partner.component';
import { ListPartnerComponent } from './list-partner/list-partner.component';
import { ViewPartnerComponent } from './view-partner/view-partner.component';

const routes: Routes = [
  {
    path: '',
    component: ListPartnerComponent,
  },
  {
    path: 'create',
    component: CreatePartnerComponent,
  },
  {
    path: 'edit',
    component: EditPartnerComponent,
  },
  {
    path: 'view',
    component: ViewPartnerComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  declarations: [],
  exports: [RouterModule]
})
export class PartnerManagementRoutingModule { }
