import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ShareDataService } from 'src/app/core/services/sdk-mangement/share-data.service';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { EnvService } from 'src/app/core/services/env.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ActivityNotificationService } from 'src/app/core/services/activity-notification/activity-notification.service';
import { WorkbenchMgmtStaticString } from 'src/app/shared/enum/workbenchManagement';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { MatIconRegistry } from '@angular/material/icon';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`
@Component({
  selector: 'app-edit-sdk',
  templateUrl: './edit-sdk.component.html',
  styleUrls: ['./edit-sdk.component.scss']
})
export class EditSdkComponent implements OnInit {
  public WbMgmtEnum = WorkbenchMgmtStaticString;
  ViewData: any = { 'name': '', 'description': '', 'version': '', 'filename': '', 'releaseNote': '', 'releaseDate': '', 'endOfLifeDate': '', 'isActive': '' };
  UserId: any;
  minDateEolDate :any;
  minDate = new Date();
  isTrueSet: any = true;
  $subs!: Subscription;
  Status: number = 2;
  isActiveSDK :boolean = false;
  isuploadFile:boolean=false;
  isUserAssociate: boolean = false;
  dataSource: any
  filename = '';
  azUrl: string = "pathNotFound";
  filePath: any;
  file: any;
  showProgressBar = 'false';
  eventObject: any = {};
  icVersionList: any = [];
  newArray: any = [];
  selectedCheckbox: any = [];
  newICVersionList: any = [];
  SelectICIVersion: any;
  IcCheckedList = new FormControl();
  fileNames: any;
  files: any = "Choose File";
  fileAttr = "Choose File";
  isDisabled: any
  editSDKTimestamp:any="";
  editData: any;
  currentSDK: any;
  uniqueIdentifier: any;
  sas: any;
  errorDescriptionName: any;
  errorDescriptionField: any;
  myInputValuename: any;
  myInputValue: any;
  
  public regex = InputPattern;
  regexName: any = new RegExp(this.regex.namePattern);
  regexDescription: any = new RegExp(this.regex.descriptionPattern);
  errorDescription: any = this.regex.errorMessage;
  firstCharErrorMessage:any = this.regex.firstCharacterErrorMessage;
  fileSizeLimit = this.env.fileSizeLimit; //environment.fileSizeLimit;
  fileExtension = ['zip', 'rar', 'vsix'];
  breadCrumbItems: any = [
    { label: "Home", path: "resources"},
    { label: 'Development and Utilities', path: 'developmentandutilities/idn-workbench-management' },
    { label: 'IDN Workbench', path: 'developmentandutilities/idn-workbench-management' },
    { label: "Edit", path: "developmentandutilities/idn-workbench-management/edit"},
  ];
  isEditSDK: boolean = false;
  isViewSDK: boolean = false;
  isActivateSDK: boolean = false;
  isDeactivateSDK: boolean = false;
  hasGlobalDataAccess: boolean = false;
  refreshSDKList : any = {}
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  constructor(private blobService: AzureBlobStorageService,
    private notificationService: NotificationService,
    private sdkServices: SdkServiceService, private sharedata: ShareDataService,
    private dialogService: DialogService,
    private titleService: Title,
    private router: Router,
    private permssionService: PermissionsService,
    private activityNotifService: ActivityNotificationService,
    private env: EnvService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIconLiteral(
      'HELP_ICON',
      sanitizer.bypassSecurityTrustHtml(HELP_ICON)
    );
  }




  frmSDK: FormGroup = new FormGroup({
    Name: new FormControl('',[Validators.required,Validators.pattern(this.regexName)]),
    Description: new FormControl('', [Validators.required,Validators.maxLength(500),Validators.pattern(this.regexDescription)]),
    Version: new FormControl('', [Validators.required,Validators.pattern(/^\d{1,2}\.\d{1,2}(?:\.\d{1,2})?$/)]),
    file: new FormControl('', Validators.required),
    ReleaseNote: new FormControl('', [Validators.required,Validators.pattern(this.regexDescription)]),
    ReleaseDate: new FormControl('',),
    EOLDate: new FormControl('',),
    uniqueIdentifier: new FormControl(null),
    IClist: new FormControl('', Validators.required),
    Active: new FormControl(true),
  });


  ngOnInit(): void {
    this.titleService.setTitle('Edit Workbench | IDN');
    this.isEditSDK = this.permssionService.getPermission('UpdateSDK');
    if(!this.isEditSDK){
      this.router.navigate(['home/access-denied']);
      return;
    }
    this.isViewSDK = this.permssionService.getPermission('ViewSDK');
    this.isActivateSDK = this.permssionService.getPermission('ActivateSDK');
    this.isDeactivateSDK = this.permssionService.getPermission('DeactivateSDK');
    this.hasGlobalDataAccess = this.permssionService.hasGlobalDataAccess();
    if (this.sharedata.isView) {
      try {
        this.sharedata.receiveData().subscribe(data => {   
          if(data !=""){
            localStorage.setItem("editSdkDatas",data);
          }else{
            data = localStorage.getItem("editSdkDatas");
          }
            let newSDKId :any=JSON.parse(data);
            this.sdkServices.GetSDKById(newSDKId.id).subscribe(resp => { 
              data = resp;
          let rdate:any= data.releaseDate;
          this.minDateEolDate= new Date(rdate);
          this.minDateEolDate.setDate(this.minDateEolDate.getDate() + 1);
          this.selectedCheckbox = data.iciIds;
          this.fileNames = data.fileName;
          this.files = data.fileName;
          this.fileAttr = data.fileName;
          this.UserId = data.id;
          this.Status = data.status
          this.editData = data;
          this.minDateEolDate = data.releaseDate;
          this.editSDKTimestamp = data.timestamp;
          this.isuploadFile=data.isFileUploaded;

          if (data.status == 1) {
            document.getElementById('file')?.setAttribute('disabled', 'disabled');
            document.getElementById('fileLabel')?.setAttribute('style', 'color:#aaa');
            document.getElementById('checkboxSS')?.setAttribute('disabled', 'true');
            this.isDisabled = true;
            this.frmSDK.controls["Name"].disable();
            this.frmSDK.controls["Version"].disable();
            this.frmSDK.controls["ReleaseNote"].disable();
            this.frmSDK.controls["IClist"].disable();
            if(this.isuploadFile==true){
              this.frmSDK.controls["file"].disable();
            }

          }else if(data.status == 2 ){
            this.isActiveSDK = true;
            this.frmSDK.disable();
  }

          this.frmSDK.patchValue({
            Name: data.name,
            Description: data.description,
            Version: data.version,
            file: data.fileName,
            ReleaseNote: data.releaseNote,
            ReleaseDate: data.releaseDate,
            EOLDate: data.endOfLifeDate,
            IClist: data.iciIds,
          })
        });
        });
        this.getVersionList();       
        this.sharedata.isView.next(false);
      }
      catch (error) {
        console.log(error);
      }
    }

    else {
      this.sdkServices.onSDK<any>().subscribe(
        data => {
          this.selectedCheckbox = data.iciIds;
          this.fileNames = data.fileName;
          this.fileAttr = data.fileName;
          this.files = data.fileName;
          this.UserId = data.id;
          this.Status = data.status
          this.editData = data;
          if (data.status == 1) {
            document.getElementById('file')?.setAttribute('disabled', 'disabled');
            document.getElementById('fileLabel')?.setAttribute('style', 'color:#aaa');
            document.getElementById('checkboxSS')?.setAttribute('disabled', 'true');
            this.isDisabled = true;
            this.frmSDK.controls["Name"].disable();
            this.frmSDK.controls["Version"].disable();
            this.frmSDK.controls["ReleaseNote"].disable();
            this.frmSDK.controls["ReleaseDate"].disable();
            this.frmSDK.controls["EOLDate"].disable();
            this.frmSDK.controls["IClist"].disable();
            if(this.isuploadFile==true){
              this.frmSDK.controls["file"].disable();
            }

          }

          this.frmSDK.patchValue({
            Name: data.name,
            Description: data.description,
            Version: data.version,
            file: data.fileName,
            ReleaseNote: data.releaseNote,
            ReleaseDate: data.releaseDate,
            EOLDate: data.endOfLifeDate,
          })
        });
    }

    this.getVersionList();    
  }

  async onFileSelected(event: any) {
    this.file = event.target.files[0];
    this.files = event.target.files[0].name
    this.filename = this.file.name;
  }

  valueChange(event: any) {
    if (event == undefined) {
      this.errorDescriptionField = this.regex.descriptionErrorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionField = this.firstCharErrorMessage;
      return;
    }
    else {
      this.errorDescriptionField = this.regex.descriptionErrorMessage;
    }
  }

  valueChangename(event: any) {
    if (event == undefined) {
      this.errorDescriptionName = this.regex.errorMessage;
      return;
    }
    if (!this.regexName.test(event[0])) {
      this.errorDescriptionName = this.regex.firstCharacterErrorMessage;
      return;
    }
    else {
      this.errorDescriptionName = this.regex.errorMessage;
    }
  }
  


  uploadFileEvt(File: any) {
    if (File.target.files[0] != undefined && File.target.files[0] != null && File.target.files[0] != '') {
      var lastDot = File.target.files[0].name.lastIndexOf(".");
      const extension = File.target.files[0].name.substring(lastDot + 1).toLowerCase();
      const validExtension = this.fileExtension.filter((x) => x == extension).length > 0;
      // if (!validExtension) {
      //   this.notificationService.showNotification("Please upload valid file type", 'warning', 'Invalid file type');
      // } else {
      //   this.file = File.target.files[0];
      //   this.fileAttr = File.target.files[0].name;
      //   this.filename = File.target.files[0].name;
      // }
      if (!validExtension) {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_invalid_file_body, 'warning', this.WbMgmtEnum.wb_notify_invalid_file_heading);
        return
      }
      this.file = File.target.files[0];
      var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
      if (filesize > this.fileSizeLimit) {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_exceed_file_limit_body, 'error', this.WbMgmtEnum.wb_notify_exceed_file_limit_heading);
        this.file = null;
        this.fileAttr = 'Choose IDN Workbench File';
        return
      }
      if (File.target.files && File.target.files[0]) {
        this.fileAttr = '';
        this.fileAttr = File.target.files[0].name;
        this.filename = File.target.files[0].name;
        this.frmSDK.patchValue({ file: this.filename });
      }
    }
  }

  getVersionList() {
    this.sdkServices.GetICVersionList().subscribe((result: Response) => {
      this.icVersionList = result;
      this.icVersionList = this.icVersionList?.sort((a:any, b:any) =>{ return b.version.localeCompare(a.version, undefined, { numeric: true, sensitivity: 'base' });});
      for (let i = 0; i < this.icVersionList.length; i++) {
        if (this.selectedCheckbox.includes(this.icVersionList[i].id)) {
          this.icVersionList[i]["isChecked"] = true;
        } else {
          this.icVersionList[i]["isChecked"] = false;
        }
      }
    });
  }
  onUpdateSDK() {
    this.showProgressBar = 'true';
    let json;
    let releaseDatelocalISOTime=null;
    let EOLDatelocalISOTime=null;


    if (this.frmSDK.value.ReleaseDate != null && this.frmSDK.value.EOLDate!=null && this.frmSDK.value.ReleaseDate != "" && this.frmSDK.value.EOLDate != "") {
      let d = new Date(this.frmSDK.value.ReleaseDate);
      let d2 = new Date(this.frmSDK.value.EOLDate)

      let releaseDatetzoffset = d.getTimezoneOffset() * 60000;
      releaseDatelocalISOTime = new Date(Number(d) - releaseDatetzoffset).toISOString().slice(0, -1);

      let EOLDatetzoffset = d2.getTimezoneOffset() * 60000;
      EOLDatelocalISOTime = new Date(Number(d2) - EOLDatetzoffset).toISOString().slice(0, -1);
    }

    if (this.editData.status == 1) {
      json = {
        "id": this.editData.id,
        "name": this.editData.name,
        "description": this.frmSDK.value.Description,
        "version": this.editData.version,
        "fileUrl": this.editData.fileUrl,
        "fileName": this.filename=="" ?  this.editData.fileName : this.filename,
        "status": this.editData.status,
        "releaseNote": this.editData.releaseNote,
        "releaseDate": this.frmSDK.value.ReleaseDate == null ? null : releaseDatelocalISOTime,
        "endOfLifeDate": this.frmSDK.value.EOLDate == null ? null : EOLDatelocalISOTime,
        "isDownloaded": this.editData.isDownloaded,
        "iciIds": this.editData.iciIds,
      }

    } else {

      json = {
        "id": this.editData.id,
        "name": this.frmSDK.value.Name,
        "description": this.frmSDK.value.Description,
        "version": this.frmSDK.value.Version,
        "fileUrl": this.azUrl == "pathNotFound" ? this.editData.fileUrl : this.azUrl,
        "fileName": this.filename == "" ? this.editData.fileName : this.filename,
        "status": 2,
        "releaseNote": this.frmSDK.value.ReleaseNote,
        "releaseDate":this.frmSDK.value.ReleaseDate == null ? null : releaseDatelocalISOTime,
        "endOfLifeDate":this.frmSDK.value.EOLDate == null ? null : EOLDatelocalISOTime,
        "isDownloaded": true,
        "iciIds": this.newArray.length == 0 ? this.editData.iciIds.sort() : this.newArray.sort(),
      }
    }
    if (this.filename != "") {
      this.eventObject['showProgressBar'] = this.showProgressBar;
      this.eventObject['sdkName'] = this.frmSDK.value.Name == undefined || this.frmSDK.value.Name == "" ? this.editData.name : this.frmSDK.value.Name;
      this.eventObject['sdkDesc'] = this.frmSDK.value.Description;
      this.eventObject['sdkVers'] = this.frmSDK.value.Version == undefined || this.frmSDK.value.Version == "" ? this.editData.version : this.frmSDK.value.Version;
      this.sdkServices.emit<any>(this.eventObject);
    }

    json['timestamp'] =this.editSDKTimestamp;

    this.sdkServices.EditSDK(json).subscribe(async (result: Response) => {
       this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
       if(this.filename != ""){

        this.sdkServices.GetSDKById(this.editData.id).subscribe((res: any) => {
          this.currentSDK = res.body;
        })

          let resToken = await  this.setToken( this.editData.id);
          if (resToken != null && resToken != undefined) {
            this.sas = resToken.sasUrl;
            this.uniqueIdentifier = resToken.container;
            this.frmSDK.controls['uniqueIdentifier'].setValue(this.uniqueIdentifier);
          }

        this.UploadFileAsynchronously();
       }
      if (result.status == 200 && this.filename == "") {
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_wb_updated_body, 'success', this.WbMgmtEnum.wb_notify_wb_updated_heading);
      } else if(result.status != 200){
        this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_wb_not_updated_body, 'error', this.WbMgmtEnum.wb_notify_wb_not_updated_heading);
      }
    })
  }

     //To Set SAS Token
     async setToken(sdkId: any): Promise<any> {
      try {
        return this.sdkServices.getBlobUploadConnection(sdkId).toPromise();
      }
      catch (execption) {
        console.log(execption)
      }
    }

  getselectedICIversion(event: any) {
    this.newArray = this.selectedCheckbox;
    if (this.newArray.includes(event.id)) {
      for (var i = 0; i < this.newArray.length; i++) {
        if (this.newArray[i] == event.id) {
          this.newArray.splice(i, 1);
          break;
        }
      }
    } else {
      this.newArray.push(event.id);
    }
  }

  ngOnDestroy(): void {
    if(window.location.pathname != '/home/idn-workbench-management'){
      localStorage.removeItem('IDNManagementFilter');  
     }
  }

  UploadFileAsynchronously() {
    return new Promise(resolve => {
        this.blobService.uploadFiles(this.sas, this.uniqueIdentifier, this.file, this.file.name, (response: any) => {
          if (response._response != undefined) {
            this.azUrl = response._response.request.url;
            this.sdkServices.Activate(this.editData.id).subscribe((resp: Response) => {
                this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_file_uploaded_body, 'success', this.WbMgmtEnum.wb_notify_file_uploaded_heading);

              this.sdkServices.FileUploadSuccessful(this.editData.id).subscribe((resp: any) => {
                this.refreshSDKList['refreshSDK'] = true
                    this.sdkServices.emitRefreshSDK<any>(this.refreshSDKList);
              });

                this.activityNotifService.TriggerSDKNotification(this.editData.id,true);
            });
          } else {
            this.sdkServices.Deactivate(this.editData.id).subscribe((resp: Response) => {
                this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_file_not_uploaded_body, 'error', this.WbMgmtEnum.wb_notify_file_not_uploaded_heading);
            });
          }
          this.eventObject['showProgressBar'] = 'false';
          this.sdkServices.emit<any>(this.eventObject);
        })
    });
  }

  onCancel(val: any) {
    let navLink: any = 'home/developmentandutilities/' + val;
    this.router.navigate([navLink]);
  }

  DeactivateSDKs() {
    this.deactivateSDK(this.UserId);
  }

  ActivateSDKs() {
    this.activateSDK(this.UserId);
  }
  //To In-active SDK  Status
  deactivateSDK(id: number) {
    if (id != 0) {
      try {
            this.dialogService.confirmDialog({
              title: this.WbMgmtEnum.wb_dialog_deactivate_heading,
              module: 'sdk',
              message: this.WbMgmtEnum.wb_dialog_deactivate_body,
              confirmText: this.WbMgmtEnum.wb_label_deactivate_btn,
              cancelText: this.WbMgmtEnum.wb_label_cancel_btn,
            }).subscribe((res) => {
              if (res) {
                this.sdkServices.Deactivate(id).subscribe((resp: Response) => {
                  if (resp == null) {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_deactivated_body, 'success', this.WbMgmtEnum.wb_notify_deactivated_heading);
                    this.onCancel("idn-workbench-management");
                  } else {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_deactivated_body, 'warning', this.WbMgmtEnum.wb_notify_not_deactivated_heading);
                  }
                })
              }
            })

      }
      catch (error) {
        console.log(error)
      }
    }
  }

  //To Activate SDK Status
  activateSDK(id: number) {
    if (id != 0) {
      try {
            this.dialogService.confirmDialog({
              title: this.WbMgmtEnum.wb_dialog_activate_heading,
              module: 'sdk',
              message: this.WbMgmtEnum.wb_dialog_activate_body,
              confirmText: this.WbMgmtEnum.wb_label_activate_btn,
              cancelText: this.WbMgmtEnum.wb_label_cancel_btn,
            }).subscribe((res: any) => {
              if (res) {
                this.sdkServices.Activate(id).subscribe((resp: Response) => {
                  if (resp == null) {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_activated_body, 'success', this.WbMgmtEnum.wb_notify_activated_heading);
                    this.onCancel("idn-workbench-management");
                  } else {
                    this.notificationService.showNotification(this.WbMgmtEnum.wb_notify_not_activated_body, 'warning', this.WbMgmtEnum.wb_notify_not_activated_heading);

                  }
                })

              }
            })
      }
      catch (error) {
        console.log(error)
      }
    }
  }

  OnCancel() {
    this.frmSDK.reset();
    this.router.navigate(["home/developmentandutilities/idn-workbench-management"]);
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    try {
      let rdate:any= event.value;
      this.minDateEolDate= new Date(rdate);
      this.minDateEolDate.setDate(this.minDateEolDate.getDate() + 1);
    } catch (err) {
      console.log(err);
    }
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.WbMgmtEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.WbMgmtEnum.redirect_dialog_body,
        confirmText: this.WbMgmtEnum.redirect_dialog_primary_btn,
        cancelText: this.WbMgmtEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          window.open(
            'https://developer-help.icertis.com/docs/setting-up-idn-workbench/',
            '_blank'
          );
        }
      });
  }
}
