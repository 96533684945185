<div class="container">
  <div class="idn-terms-wrapper">
    <img class="brand-logo" src="../../../../assets/images/logo/Icertis_logo_dark.svg" alt="Icertis Logo">
    <h1>Warm welcome to Icertis Developer Network</h1>
    <h2><em>{{ username }}</em></h2>
    <p>Please read the IDN terms of use and provide your consent before you can access the IDN application.</p>
    <div class="idn-terms-form-wrapper">
      <mat-checkbox color="primary" class="idn-terns-link" (change)="openTerms($event); ($event.checked == true) ? (isChecked = true) : (isChecked = false)">
        Accept Terms of Use
      </mat-checkbox>
      <div class="form-btn-wrapper">
        <button mat-raised-button color="primary" (click)="agree()" [disabled]="!isChecked">
          <mat-icon class="material-icons-outlined">check_circle</mat-icon>Agree
        </button>
        <button mat-raised-button (click)="cancelTerms()">
          <mat-icon>highlight_off</mat-icon>Disagree
        </button>
      </div>
    </div>
  </div>
</div>
