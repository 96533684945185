
<div class="page-container">
  <div class="form-wrapper">
<div class="idn-view-wrapper" >
  <div class="idn-view-body">
    <mat-grid-list cols="3" rowHeight="80px">
      <mat-grid-tile [colspan]="1">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_packageName}}</div>
          <div class="idn-view-body-items-data">
            <div class="package">
              <div class="package-name" matTooltip="{{packageDetails.name}}" [matTooltipDisabled]="isDisabled(valueToolTip)" matTooltipClass="my-tooltip" matTooltipPosition="below" #valueToolTip>{{ packageDetails.name ==''? '..': packageDetails.name }}</div>
              <button mat-button class="idn-grid-link-button idn-cell-link-btn-align" [disabled]="packageDetails.isActive == false"  matTooltip="Linked packages" *ngIf="linkageAndQueueForUpgradeFeature"
        matTooltipClass="my-tooltip" matTooltipPosition="below" aria-label="link" >        
          <mat-icon *ngIf="packageDetails?.linkageId" (click)="linkedPackage(packageDetails)">link</mat-icon>
        </button>
        <mat-icon *ngIf="packageDetails?.linkageId && packageDetails?.queuedForUpgrade && linkageAndQueueForUpgradeFeature" class="icon-scale" svgIcon="queue_for_upgrade" matTooltip="Queued for deployment on upgrade" matTooltipClass="my-tooltip" matTooltipPosition="below"></mat-icon>
        <mat-icon class="material-symbols-outlined green" *ngIf="packageDetails.isProdDeployedPackage == true" matTooltip="Production deployed package" matTooltipClass="my-tooltip" matTooltipPosition="above">
          credit_score         
        </mat-icon>      
      </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_packageVersion}}</div>
          <div class="idn-view-body-items-data">{{ packageDetails.major }}.{{
              packageDetails.minor }}.{{ packageDetails.patch }}.{{ packageDetails.build }}</div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_iciVersion}}</div>
          <div class="idn-view-body-items-data">{{packageDetails.iciVersion ? packageDetails.iciVersion : '..' }}</div>
        </div>
      </mat-grid-tile>        
    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="80px">
      <mat-grid-tile [colspan]="1">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_CustomerName}}</div>
          <div class="idn-view-body-items-data">{{ packageDetails.customerName =='' ? '..': packageDetails.customerName}}</div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_status}}</div>
            <div class="idn-view-body-items-data" id="package-info" [ngClass]="{ 'upload-failed': packageDetails.status == packageStatus.uploadFailed, 'package-uploaded': packageDetails.status == packageStatus.packageUploaded, 'uncertified-package': packageDetails.status == packageStatus.buildSuccessful, 'certified-package': packageDetails.status == packageStatus.certifiedPackage, 'virus-scan-failed': packageDetails.status == packageStatus.virusScanFailed, 'virus-scan-in-progress': packageDetails.status == packageStatus.virusScanInProgress, 'upload-in-progress': packageDetails.status == packageStatus.uploadInProgress, 'build-failed': packageDetails.status == packageStatus.buildFailed, 'build-in-progress': packageDetails.status == packageStatus.buildInProgress, 'rejected': packageDetails.status == packageStatus.rejected, 'certification-in-progress': packageDetails.status == packageStatus.CertificationInProgress }">
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.uploadFailed" svgIcon="upload_failed"></mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.packageUploaded">check_circle</mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.buildSuccessful" svgIcon="build_success"></mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.certifiedPackage">verified_user</mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.virusScanFailed">flip</mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.virusScanInProgress">flip</mat-icon>
                <mat-icon class="mat-icon-style rotate-180" *ngIf="packageDetails.status == packageStatus.uploadInProgress">downloading</mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.CertificationInProgress" svgIcon="certify_progress"></mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.buildFailed" svgIcon="build_failed"></mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.buildInProgress" svgIcon="build_progress"></mat-icon>
                <mat-icon class="mat-icon-style" *ngIf="packageDetails.status == packageStatus.rejected">block</mat-icon>
                {{ packageDetails.status == "" ? '' : ((packageDetails.status ==
                    packageStatus.buildSuccessful) ? "Build Successful" : packageDetails.status &&
                    (packageDetails.status == packageStatus.certifiedPackage) ? "Certified" :
                    packageDetails.status)
                    }}
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_lastUpdateDate}}</div>
            <div class="idn-view-body-items-data" id="package-info"  [ngClass]="'last-updated-date'">{{packageDetails.modifiedAt =='' ? '..':packageDetails.modifiedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}</div>
          </div>
        </mat-grid-tile>

    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="80px">
      <mat-grid-tile [colspan]="1">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_partnerName}}</div>
          <div class="idn-view-body-items-data">{{packageDetails.partnerName ==null ? 'NA':packageDetails.partnerName }}</div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_createdBy}}</div>
            <div class="idn-view-body-items-data">
                <span class="view-user-info">{{packageDetails.createdByDisplayName ==null ? '..':packageDetails.createdByDisplayName }}</span>
                <span class="view-timestamp">on {{packageDetails.createdAt =='' ? '..':packageDetails.createdAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                </span>
              </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_certifiedRequestDate}}</div>
            <div class="idn-view-body-items-data">{{packageDetails.certificationRequestedOn == null ? '..' : packageDetails.certificationRequestedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}</div>
          </div>
        </mat-grid-tile>        
    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="80px">
        <mat-grid-tile>
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_packageDescription}}</div>
            <div class="idn-view-body-items-data">{{ packageDetails.description }}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="packageDetails.locked == true">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_PackageLockDescription}}</div>
            <div class="idn-view-body-items-data">
              <span class="view-user-info">{{packageDetails.modifiedByDisplayName}}</span>
              <span class="view-timestamp">on {{packageDetails.modifiedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" |
                dateFormat}}
              </span>
            </div>
          </div>
        </mat-grid-tile>
        
    </mat-grid-list>

    <mat-grid-list cols="3" rowHeight="80px" >
      <mat-grid-tile [colspan]="1" *ngIf="packageDetails.fileName!= null && packageDetails.fileName!='' && packageDetails.fileName!=undefined">
        <div class="idn-view-body-items">
          <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_packageFile}}</div>
          <div class="idn-view-body-items-data">
              <div
              *ngIf="packageDetails.fileName!='' && packageDetails.fileName!=undefined">
              <div class="rows">
                  <mat-chip-list class="idn-chips-download" aria-label="file selection"
                  [ngStyle]="{'pointer-events': extDetailsData.status == 2 || packageDetails.locked == true ? 'none' : '' }"
                  [disabled]="extDetailsData.status== 2 || packageDetails.locked || (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed'?false:true)">
                  <mat-chip (click)="(packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' )?downloadSingleFile(packageDetails.id,[packageDetails.fileName], ['Package']):''">
                      <span class="package-file" matTooltip="{{packageDetails.fileName}}" matTooltipClass="my-tooltip" matTooltipPosition="below">{{packageDetails.fileName}}</span>
                      <mat-icon>download</mat-icon>
                  </mat-chip>
              </mat-chip-list>
              </div>
          </div>
          </div>
        </div>
      </mat-grid-tile>
      <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items"  *ngIf="packageDetails.binaryFileName!=null && packageDetails.binaryFileName!='' && packageDetails.binaryFileName!=undefined">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_binaryFile}}</div>
            <div class="idn-view-body-items-data">
              <div>
                  <div class="rows">
                      <mat-chip-list class="idn-chips-download" aria-label="file selection"
                          [ngStyle]="{'pointer-events':extDetailsData.status == 2 || packageDetails.locked == true ? 'none' : '' }"
                          [disabled]="extDetailsData.status == 2 || packageDetails.locked || (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed'?false:true)">
                          <mat-chip
                              (click)="(packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' )?downloadSingleFile(packageDetails.id,[packageDetails.binaryFileName], ['Binary']):''">
                              <span class="package-file" matTooltip="{{packageDetails.binaryFileName}}" matTooltipClass="my-tooltip" matTooltipPosition="below">{{packageDetails.binaryFileName}}</span>
                              <mat-icon>download</mat-icon>
                          </mat-chip>
                      </mat-chip-list>
                  </div>
              </div>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items"  *ngIf="packageDetails.logFileName!=null && packageDetails.logFileName!='' && packageDetails.logFileName!=undefined">
            <div class="idn-view-body-items-label">{{extensionEnum.package_View_Field_logFile}}</div>
            <div class="idn-view-body-items-data">
              <div>
                  <div class="rows">
                      <mat-chip-list class="idn-chips-download" aria-label="file selection"
                          [ngStyle]="{'pointer-events':extDetailsData.status == 2 || packageDetails.locked == true ? 'none' : '' }"
                          [disabled]="extDetailsData.status == 2 || packageDetails.locked || (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed'?false:true)">
                          <mat-chip
                              (click)="(packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' )?downloadSingleFile(packageDetails.id,[packageDetails.logFileName], ['Log']):''">
                              <span class="package-file" matTooltip="{{packageDetails.logFileName}}" matTooltipClass="my-tooltip" matTooltipPosition="below">{{packageDetails.logFileName}}</span> 
                              <mat-icon>download</mat-icon>
                          </mat-chip>
                      </mat-chip-list>
                  </div>
              </div>
            </div>
          </div>
        </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
</div>
</div>
<div class="pss-form-button-wrapper package-details-footer">
  <div class="pss-form-button-wrapper package-details-footer">
    <button mat-stroked-button *ngIf="!linkageAndQueueForUpgradeFeature" class="idn-chips-download" [disabled]="packageDetails.locked || extDetailsData.status == 2 || (packageDetails.fileName!=''?false:true) || (packageDetails.fileName!=undefined?false:true) || (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' ?false:true) "
        (click)="(packageDetails.locked === false && extDetailsData.status == 1 && (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' ))?downloadSingleFile(packageDetails.id, [packageDetails.fileName, packageDetails.binaryFileName, packageDetails.logFileName], ['Package', 'Binary', 'Log']):''">
        <mat-icon [ngClass]="{'disable':packageDetails.locked == true || extDetailsData.status == 2 }">download
        </mat-icon><span>{{extensionEnum.package_View_dowloadSingleFiles_primary_btn}}</span>
    </button>
  <button mat-stroked-button color="primary" class="idn-edit-btn" *ngIf="linkageAndQueueForUpgradeFeature && packageDetails?.linkageId && !packageDetails?.queuedForUpgrade && isCreateSolutionPackage && (packageDetails.status == 'Certified package'
  || packageDetails.status == 'Certification in progress'
  || packageDetails.status == 'Build successful')"  [disabled]="packageDetails.locked || extDetailsData.status == 2"
    (click)="queueForUpgrade(packageDetails)">
    <mat-icon class="icon-scale mr-half" svgIcon="queue_for_upgrade"></mat-icon> Deploy on Instance Upgrade
  </button>
  <button mat-stroked-button class="idn-button-secondary" *ngIf="isInitiateAutomaticCertificationPermission && (packageDetails?.partnerId == loggedInUserPartnerId || partnerAccess)" (click)="((packageDetails.status == packageStatus.buildSuccessful) && (!packageDetails.locked) && (extDetailsData.status == 1)) ? submitSolutionCertification(packageDetails) : ''"
      [disabled]="packageDetails.status != packageStatus.buildSuccessful || packageDetails.locked || (extDetailsData.status == 2)">
      <mat-icon class="mr-half">local_police</mat-icon><span>{{extensionEnum.package_View_submitforSolutionCertification_btn}}</span>
  </button>
  <button [matMenuTriggerFor]="gridMenu" mat-stroked-button aria-label="More Actions" color="primary" class="idn-edit-btn" 
    matTooltip="More Actions" *ngIf="linkageAndQueueForUpgradeFeature" [disabled]="extDetailsData.status == 2" >
    <div class="idn-button-icon">
      <mat-icon class="mr-half">add</mat-icon>
      <span>{{extensionEnum.more_action_btn}}</span>
    </div>
  </button>
  <mat-menu #gridMenu="matMenu" xPosition="before" >
    <button mat-menu-item class="menu-item" [disabled]="packageDetails.locked || extDetailsData.status == 2 || (packageDetails.fileName!=''?false:true) || (packageDetails.fileName!=undefined?false:true) || (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' ?false:true) "
      (click)="(packageDetails.locked === false && extDetailsData.status == 1 && (packageDetails.status !=='Upload failed' && packageDetails.status !=='Package uploaded' && packageDetails.status !=='Virus scan in progress' && packageDetails.status !=='Virus scan failed' ))?downloadSingleFile(packageDetails.id, [packageDetails.fileName, packageDetails.binaryFileName, packageDetails.logFileName], ['Package', 'Binary', 'Log']):''">
      <mat-icon [ngClass]="{'disable':packageDetails.locked == true || extDetailsData.status == 2 }">download
      </mat-icon><span>{{extensionEnum.package_View_dowloadSingleFiles_primary_btn}}</span>
  </button>
  <button mat-menu-item class="menu-item" (click)="uploadLinkedPackage('extension-management/create',packageDetails)" *ngIf="(packageDetails?.fileName != '')  && isCreateSolutionPackage">
    <mat-icon>link</mat-icon> Upload New Version </button>   
  </mat-menu>
  <button mat-stroked-button (click)="onCancel()">{{extensionEnum.package_View_cancel_btn}}</button>
</div>
