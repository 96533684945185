<app-pageheader [pageTitle]="'Role Management / Role Permissions'" [auditLog]="false" [createRole]="false"
  [mapRole]="false" [applyFilter]="false" [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
  <div class="pss-grid-filter-wrapper">
    <form class="idn-grid-filter">
      <mat-form-field class="idn-grid-filter-search">
        <mat-label>Search for activity</mat-label>
        <input #search_input matInput (keydown.enter)="permissionFilter($event, 'name')" 
          placeholder="Example: View Roles">
        <span class="idn-custom-search" matSuffix>
          <mat-icon (click)="permissionFilter($event, 'name')">search</mat-icon>
          <button matSuffix type="button"  mat-icon-button aria-label="Clear" (click)="clearFilter()">
            <mat-icon>close</mat-icon>
          </button>
        </span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Modules</mat-label>
        <mat-select #select name="module" [formControl]="permissionsList"
          (selectionChange)="permissionFilter($event, 'module')" multiple matTooltip="{{filterModule}}">
          <div class="select-all">
            <mat-checkbox color="primary" #selectAllCheckBox [(ngModel)]="allSelected"
              [ngModelOptions]="{standalone: true}" (change)="toggleAllSelection()">All</mat-checkbox>
          </div>
          <mat-option *ngFor="let module of moduleList | sort:'name'" [value]="module.name">{{ module.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <div class="filter-end-wrap">
      <mat-checkbox (change)="$event ? selectRows($event) : null" [checked]="checkBoxCheck"
        [indeterminate]="checkBoxIndeterminate">Allow all Permissions</mat-checkbox>
    </div>
  </div>

  <div class="pss-bottom-sheet-scrollable">
    <div class="table-scroll role-list-table">
      <table mat-table [dataSource]="dataSource" style="width: 100%" matSort
        (matSortChange)="announceSortChange($event)">

        <!-- Module name Column -->
        <ng-container matColumnDef="moduleName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"> Module Name
          </th>
          <td mat-cell *matCellDef="let element"> {{element.moduleName}} </td>
        </ng-container>

        <!-- Activity Column -->
        <ng-container matColumnDef="activity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name"> Activity/Action
            Name </th>
          <td mat-cell *matCellDef="let element"> {{element.activity}} </td>
        </ng-container>

        <!-- Permission Column -->
        <ng-container matColumnDef="permissions">
          <th mat-header-cell *matHeaderCellDef class="alignPermission"> Permission </th>
          <td mat-cell *matCellDef="let element">
            <section class="example-section" class="alignPermission">
              <mat-slide-toggle (change)="onToggle($event, element.id)" [checked]="element.permissionAssigned" aria-label="PermissionBtn" >
              </mat-slide-toggle>
            </section>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="onPaginateChange($event)">
    </mat-paginator>
  </div>
  <div class="pss-form-button-wrapper">
    <button mat-raised-button color="primary" (click)="onUpdate()">{{actionSave}}</button>
    <button mat-stroked-button (click)="OnCancel()">{{actionCancel}}</button>
  </div>
</div>