<app-pageheader [pageTitle]="'Role Management'" [auditLog]="true" [auditLogData]="'role'" [createRole]="true" [mapRole]="true"
  [applyFilter]="false" [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
  <div class="idn-filter">
  <form class="idn-grid-filter">
    <mat-form-field class="idn-grid-filter-search">
      <mat-label>Search Role</mat-label>
      <input  (keydown.enter)="doFilter('search')" #name matInput placeholder="Search by name and description"  #input>
      <span class="idn-custom-search" matSuffix>
        <mat-icon  (click)="doFilter('search')">search</mat-icon>
       <button type="button" matSuffix mat-icon-button aria-label="Clear" (click)="ClearInput()">
        <mat-icon>close</mat-icon>
      </button>
      </span>
    </mat-form-field>
  </form>
  <button mat-stroked-button color="primary"  (click)="clearFilter()">
    <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
    <span>Clear Filter</span>
  </button>
  </div>
  <div class="table-scroll">
  <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position idn-grid-global" matSort  #empTbSort="matSort"
  *ngIf="!noContent; else noContentTemplate">

    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number">
         Name
      </th>
      <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"> <span matTooltip="{{element.name}}" matTooltipClass="idn-tooltip" >{{element.name}}</span></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
         Description
      </th>
      <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.description}}" matTooltipClass="idn-tooltip">{{element.description}}</span></td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef >
       Role Category
      </th>    
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.userCategoryName}}" matTooltipClass="idn-tooltip">{{element.userCategoryName}}</span></td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef >
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="pss-chips-status" *ngIf="element.active === true">
          <span class="marker marker__active"></span>
          <span class="label" tabindex="0" aria-label="Role Status">Active</span>
        </div>
        <div class="pss-chips-status" *ngIf="element.active === false">
          <span class="marker marker__in-active"></span>
          <span class="label" tabindex="0" aria-label="Role Status">In-Active</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="idn-grid-th-p1 action-cell">
        Actions
      </th>

      <td mat-cell *matCellDef="let element">
        <button  mat-icon-button aria-label="View" (click)="ViewRole('role-management/view',element)" matTooltip="View Role"  matTooltipClass="idn-tooltip">
        <mat-icon>remove_red_eye</mat-icon>
        </button>
        <button [matMenuTriggerFor]="gridMenu" mat-icon-button aria-label="Role Actions" matTooltip="Role Actions"  matTooltipClass="idn-tooltip" *ngIf="isRoleActivate || isRoleDeactivate || isRoleEdit || managePermissions">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <mat-menu #gridMenu="matMenu" xPosition="before">
          <button mat-menu-item [disabled]="element.active" (click)="ActivateRole(element.id)" *ngIf="isRoleActivate">
            <mat-icon>check</mat-icon> Activate
          </button>
          <button mat-menu-item [disabled]="!element.active" (click)="DeactivateRole(element.id)" *ngIf="isRoleDeactivate">
            <mat-icon>block</mat-icon> Deactivate
          </button>
          <button mat-menu-item (click)="edit('role-management/edit',element)" *ngIf="isRoleEdit">
            <mat-icon>mode</mat-icon> Edit
          </button>
          <button mat-menu-item (click)="viewPermissions('role-management/permissions',element)" *ngIf="managePermissions" aria-label="Manage Permission">
            <mat-icon>key</mat-icon> Manage Permissions
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
  <mat-paginator [pageIndex]="currentPage" [pageSize]="10" (page)="pageChanged($event)"
  [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
    aria-label="Select page of periodic elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
  </mat-paginator>

</div>
<ng-template #noContentTemplate>
  <div class="idn-no-result">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
        </clipPath>
      </defs>
      <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
        <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
        <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
        <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
        <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
        <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
        <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
          <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
          <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
        </g>
        <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
          <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
        </g>
      </g>
    </svg>
    <h4>{{ RoleMgmtEnum.role_no_result_msg }}</h4>
  </div>
</ng-template>
