<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [mapRole]="true" [applyFilter]="false"
    [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
    <div class="idn-acc-wrapper">
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">{{instanceEnum.workerTask_card_header_instance}}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">
    
                    <div class="idn-view-body-items" id="customer_block">
                        <div class="idn-view-body-items-label" >
                            {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_customerName}}
                        </div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.customerName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.customerName}}</div>
                    </div>
                    <div class="idn-view-body-items" id="cname_block">
                        <div class="idn-view-body-items-label" >
                            {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_cName}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.cname}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.cname}}</div>
                    </div>
    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_type}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.instanceType}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.instanceType}}</div>
                    </div>
    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_region}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.deploymentRegion}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ViewData.deploymentRegion}}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    
    </div>
    <div class="form-container">
        <div class="form-input">
            <h2>{{instanceEnum.workerTask_header_task}}</h2>
            <mat-form-field class="pss-block-form">
                <mat-label>{{instanceEnum.workerTask_header_task_label}}</mat-label>
                  <mat-select [formControl]="selectTask" disableOptionCentering required name="task" (selectionChange)="selectedTask($event.value)">
                    <mat-option *ngFor="let task of Tasks" [value]="task.id">
                      {{task.name}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
        </div>
        <div class="form-wrapper" *ngIf="selectedTaskId != null">
            <h2 *ngIf="selectedTaskId == eventTaskId">{{instanceEnum.workerTask_option_event_task_header}}</h2>
            <h2 *ngIf="selectedTaskId == scheduledTaskId">{{instanceEnum.workerTask_option_schedule_task_header}}</h2>
            <form class="pss-dashboard-form" [formGroup]="frmTask">
              <mat-form-field class="pss-block-form">
                <mat-label>ID</mat-label>
                <input type="text" matInput maxlength="30" placeholder="Enter the Task ID" formControlName="id"  required>
                <mat-error *ngIf="frmTask.controls.id.invalid">{{instanceEnum.workertask_message_valid_id}}</mat-error>
              </mat-form-field>
      
              <mat-form-field class="pss-block-form">
                <mat-label>{{instanceEnum.workertask_form_field_name}}</mat-label>
                <input type="text" matInput maxlength="100" placeholder="Enter the Task Name" formControlName="name" required>
                <mat-error *ngIf="frmTask.controls.name.invalid">{{instanceEnum.workertask_message_valid_name}}</mat-error>
            </mat-form-field>

              <div class="decrypt-name">
                <mat-slide-toggle [(ngModel)]="isDecrypted" [ngModelOptions]="{standalone: true}" color="primary"  (click)="$event.stopPropagation();">{{instanceEnum.workertask_form_field_use_decryption}}</mat-slide-toggle>
              </div>
              
              <mat-form-field class="pss-block-form">
                <mat-label>{{instanceEnum.workertask_form_field_type}}</mat-label>
                <input type="text" matInput maxlength="500" placeholder="Enter the Task Type (Example : Icertis.CLM.Tasks.Notification.SendMailTask, Icertis.CLM.Tasks)" formControlName="type" required>
              </mat-form-field>
      
               <mat-form-field class="pss-block-form">
                <mat-label>{{instanceEnum.workertask_form_field_description}}</mat-label>
                <textarea matInput maxlength="500" placeholder="Enter the Task Description" formControlName="description" class="idn-min-textarea-height"></textarea>
              </mat-form-field>

              <mat-form-field class="pss-block-form" *ngIf="selectedTaskId == scheduledTaskId">
                <mat-label>{{instanceEnum.workertask_form_field_cron_expression}}</mat-label>
                <input type="text" matInput maxlength="500" placeholder="Enter the CRON expression (Example 0 12 * * ? indicating 12 noon everyday)" [(ngModel)]="cronExpression" [ngModelOptions]="{standalone: true}" required>
              </mat-form-field>

              <div></div>
      
              <div class="table-configuration" *ngIf="selectedTaskId == eventTaskId">
                <span>{{instanceEnum.workertask_form_field_interest_configuration}}</span>
                <div class="table-scroll" >
                    <table #tableData mat-table *ngIf="dataSource" [dataSource]="dataSource" matSort style="width: 100%"
                        class="idn-grid-position">

                        <!-- key Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef aria-required="true">
                                {{instanceEnum.workertask_interest_configuration_field_name}}<sup class="sup-required">*</sup>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Enter the Interest Configuration" [(ngModel)]="interestConfiguration[i]['eventTaskInterestName']" [ngModelOptions]="{standalone: true}"
                                    required="true">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Topic Name">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_interest_configuration_field_topic_name}}<sup class="sup-required">*</sup>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Enter the Interest Configuration" [(ngModel)]="interestConfiguration[i]['eventTaskInterestTopicName']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Subscription Name">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_interest_configuration_field_subscription_name}}<sup class="sup-required">*</sup>
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Enter the Interest Configuration" [(ngModel)]="interestConfiguration[i]['eventTaskInterestSubscriptionName']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Parallel Workers">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_interest_configuration_field_parallel_workers}}
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <mat-slide-toggle [(ngModel)]="interestConfiguration[i]['eventTaskInterestParellelWorkers']" [ngModelOptions]="{standalone: true}" color="primary"  (click)="$event.stopPropagation();">{{instanceEnum.workertask_interest_configuration_field_parallel_workers}}</mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Filter">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_interest_configuration_field_filter}}
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Enter the Interest Configuration" [(ngModel)]="interestConfiguration[i]['eventTaskInterestFilter']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_configuration_field_action}}
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index; let last=last">
                                <mat-icon *ngIf="interestConfiguration.length>1" mat-raised-button color="primary"
                                    (click)="deleteRow(row)">cancel</mat-icon>
                                <mat-icon  *ngIf="last" 
                                    mat-raised-button color="primary" (click)="addRow(row);">add_box</mat-icon>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
                    </table>
                </div>
            </div>

            <div class="table-configuration">
                <span>{{instanceEnum.workertask_form_field_setting_configuration}}</span>
                <div class="table-scroll" >
                    <table #tableData mat-table *ngIf="dataSource1" [dataSource]="dataSource1" matSort style="width: 100%"
                        class="idn-grid-position">

                        <!-- key Column -->
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_setting_configuration_field_name}}
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <input type="text" matInput maxlength="100" placeholder="Enter the Interest Configuration" [(ngModel)]="settingConfiguration[i]['taskSettingName']" [ngModelOptions]="{standalone: true}"
                                    required>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="isEncrypted">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_setting_configuration_field_is_encrypted}}
                            </th>
                            <td mat-cell *matCellDef="let element;let i=index">
                                <mat-slide-toggle [(ngModel)]="settingConfiguration[i]['taskSettingIsEncrypted']" [ngModelOptions]="{standalone: true}" color="primary"  (click)="$event.stopPropagation();">Is Encrypted </mat-slide-toggle>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>
                                {{instanceEnum.workertask_configuration_field_action}}
                            </th>
                            <td mat-cell *matCellDef="let row; let i = index; let last=last">
                                <mat-icon *ngIf="settingConfiguration.length>1" mat-raised-button color="primary"
                                    (click)="deleteSettingConfigurationRow(row)">cancel</mat-icon>
                                <mat-icon  *ngIf="last" 
                                    mat-raised-button color="primary" (click)="addSettingConfigurationRow(row);">add_box</mat-icon>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true">
                        </tr>
                        <tr mat-row *matRowDef="let row; columns:displayedColumns1;"></tr>
                    </table>
                </div>
            </div>
      
            </form>
          </div>
    </div>
</div>
<div class="pss-form-button-wrapper">
    <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="validateForm()">{{instanceEnum.workertask_button_submit}}</button>


    <button mat-stroked-button (click)="onCancel()">{{instanceEnum.workertask_button_cancel}}</button>
</div>