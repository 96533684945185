<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div class="bottomSheetHeader">
    <h2 mat-dialog-title>Terms of Use</h2>
    <button mat-icon-button [mat-dialog-close]="false" class="idn-terms-close" aria-label="Close Button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="terms-content-wrap">
      <div class="idn-terms-content" [innerHtml]="content"></div>
      <div class="idn-terms-acceptance" *ngIf="data.acceptedAt">
        <p class="terms-label">Terms of use accepted on :</p>
        <p class="terms-timestamp">{{ data.acceptedAt | date:"d MMMM, y" }} at {{ data.acceptedAt | date:"h:mm '(UTC)'" }}</p>
      </div>
    </div>
    <div class="form-btn-wrapper" *ngIf="data.action">
      <button mat-raised-button color="primary" (click)="agree()">
        <mat-icon class="material-icons-outlined">check_circle</mat-icon>Agree
      </button>
      <button mat-raised-button (click)="cancelTerms()">
        <mat-icon>highlight_off</mat-icon>Disagree
      </button>
    </div>
  </mat-dialog-content>

</div>
