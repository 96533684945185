import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable()
export class CreateInstanceGuard  {

  constructor(
    private router: Router,
    private _router: Router,
    private permssionService: PermissionsService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): boolean {

    if (this.permssionService.getPermission('AddInstance')) {
      return true;
    }

    this.router.navigate(['home/access-denied']);

    return false;
  }

}
