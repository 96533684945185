import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { EnvService } from 'src/app/core/services/env.service';
import { env } from 'process';
import { Subscription } from 'rxjs';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';

@Component({
  selector: 'app-intiate-certification',
  templateUrl: './intiate-certification.component.html',
  styleUrls: ['./intiate-certification.component.scss']
})

export class IntiateCertificationComponent implements OnInit {

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;
  @Input() certDetailsData: any;
  fileArray: any[] = [];
  fileNameArray:any[]=[];
  isFileUploaded: boolean = false;
  eventObject: any = {};
  file: any;
  fileSizeLimit = this.env.fileSizeLimitCertifications;
  sas: any;
  uniqueIdentifier: any;
  azUrl: any;
  comments: string = "";
  fileAttr = "Choose File";
  certificationEnum=CertificationManagement;
  constructor(private certificationsService: CertificationsService, 
    private blobService: AzureBlobStorageService, 
    private notificationService: NotificationService,
    private env: EnvService) { }
    drawerSubscription!: Subscription;
    intiateCertificationActive: Boolean = false;

  ngOnInit(): void {
    let self = this;
    this.certificationsService.setDrawer(this.drawer);
    setTimeout(function () {
      self.certificationsService.open();
    });
    this.drawerSubscription = this.certificationsService.intiateCertificationToggleSubject.subscribe(data => {
      if(data) {
        this.intiateCertificationActive = false;
      }  
    });
  }

  closeDrawer() {
    this.certificationsService.closeIntiateCertification(true);
  }

  uploadFileEvt(File: any) {
    if (File.target.files && File.target.files[0]) {
      this.file = File.target.files[0];
      if (this.file.type == "application/x-zip-compressed") {
        this.fileArray.push({ 'fileContent': this.file, 'fileName': this.file.name, 'isUploaded': false })
        var filesize = Number(((this.file.size / 1024) / 1024).toFixed(4));
        if (filesize > this.fileSizeLimit) {
          this.notificationService.showNotification(this.certificationEnum.certification_notify_fileexceed_body, 'error', this.certificationEnum.certification_notify_fileexceed_heading);
          return
        }
        this.fileAttr = '';
        this.fileAttr = File.target.files[0].name;
      }
      else {
        this.fileAttr = 'Choose File';
      }
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  uploadFileAsynchronously() {
    this.setTokenForIdentifier();
    return new Promise(resolve => {
      try {
        setTimeout(() => {
          let counter = 0;
          this.fileArray.forEach((x: any) => {
            this.blobService.uploadFiles(this.sas, this.uniqueIdentifier, x.fileContent, x.fileName, (response: any) => {
              counter += 1;
              if (response._response != undefined) {
                this.azUrl = response._response.request.url;
                x["azUrl"] = this.azUrl;
                x.isUploaded = true;
                resolve("success")
              }
            else {
                resolve('Fail')
              }
              // if (counter == this.fileArray.length) {
              //   this.eventObject['showProgressBar'] = 'false';
              //   this.certificationsService.emit<any>(this.eventObject);
              // }
            })
          })
        }, 2000);
      }
      catch (execption) {
        console.log(execption);
      }
    });
  }

  async setTokenForIdentifier() {
    try {
      await this.certificationsService.getUploadTokenForIdentifier(this.certDetailsData.id).subscribe((res: any) => {
        if (res) {
          this.sas = res.body.sasUrl;
          this.uniqueIdentifier = res.body.container;
        }
      })
    }
    catch (execption) {
      console.log(execption)
    }
  }

  async submitExceptionalApproval() {
    let isExceptionalCertificationRequested = false;
    let isFileUploaded = false;
    this.certificationsService.closeIntiateCertification(true);
    if(this.fileArray.length > 0) {
      this.uploadFileAsynchronously().then(async (result: any) => {
        if (result == "success") {
            isFileUploaded = true;
            this.fileArray.forEach((x: any) => {
              this.fileNameArray.push(x.fileName);
            });
            let JSON = {
              "id": this.certDetailsData.id,
              "commentByRequestor": this.comments,
              "Files":  this.fileNameArray
            }
            this.certificationsService.submitForxceptionCertification(JSON).subscribe(res => {
              if (res.status == 200) {
                isExceptionalCertificationRequested = true;
                this.notificationService.showNotification(this.certificationEnum.certification_notify_requestSuccess_body, 'success', this.certificationEnum.certification_notify_exceptionApproval_heading);
                this.certificationsService.certificationList("redirectToCertificationListFromDetails");
                this.intiateCertificationActive = false;
            }else{
              this.notificationService.showNotification(this.certificationEnum.certification_notify_raiseRequest_body, 'error', this.certificationEnum.certification_notify_raiseRequest_heading);
            }});
        }
        else {
          this.notificationService.showNotification(this.certificationEnum.certification_notify_fileUploadFailed_body, 'error', this.certificationEnum.certification_notify_fileUploadFailed_heading)
        }
      });
    }
  }

  ngOnDestroy() {
    this.certificationsService.closeIntiateCertification(false);
  }

}
