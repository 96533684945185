
<app-pageheader [pageTitle]="'ICI Component deployment Requests'" [auditLog]="true" [auditLogData]="'ICIDeploymentRequest'" [requestIciSkuDeployment]="true"
  [breadCrumbs]="this.breadCrumbItems"></app-pageheader>


  <div class="page-container">
    <div class="table-scroll">
    <table mat-table [dataSource]="dataSource" matSort class="idn-grid-position idn-grid-global"
      (matSortChange)="announceSortChange($event)"  matSort  #empTbSort="matSort"
      *ngIf="!noContent; else noContentTemplate">
  
      <!-- cname Column -->
      <ng-container matColumnDef="cnameToEnter">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by cnametoenter">
          {{iciComponenetDeploymentEnum.componentDeployment_table_header_cname}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"> <span matTooltip="{{element.cName}}" matTooltipClass="idn-tooltip" >{{element.cName}}</span></td>
      </ng-container>
  
      <!-- ici version -->
      <ng-container matColumnDef="iciVersion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by iciVersion">
          {{iciComponenetDeploymentEnum.componentDeployment_table_header_iciVersion}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.iciVersion}}" matTooltipClass="idn-tooltip">{{element.iciVersion}}</span></td>
      </ng-container>

      <!-- Service now ticket ID -->
      <ng-container matColumnDef="servicenowticketid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by servicenowticketid">
          {{iciComponenetDeploymentEnum.componentDeployment_table_header_serviceNowTicketId}}
        </th>
        <td tabindex="0" mat-cell *matCellDef="let element" class="overflow-data"><span matTooltip="{{element.serviceNowTicketId}}" matTooltipClass="idn-tooltip">{{element.serviceNowTicketId}}</span></td>
      </ng-container>

      <!-- Requestor Details -->
      <ng-container matColumnDef="requestedBy">
        <th mat-header-cell *matHeaderCellDef>
          {{iciComponenetDeploymentEnum.componentDeployment_table_header_requestorDetails}}
        </th>
        <td mat-cell *matCellDef="let element" class="overflow-data">
          <div class="idn-cell-date">{{ element.requesterdDetail }}</div>
          <div class="requested-date"> on {{ element.createdAt | date:"dd MMMM, yyyy " }}{{ element.createdAt | date:"'at' HH:mm '(UTC)'" }}</div>
        </td>
      </ng-container>
  
      <!-- Symbol Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">
          {{iciComponenetDeploymentEnum.componentDeployment_table_header_status}}
        </th>
        <td mat-cell *matCellDef="let element">
            <span matTooltip="{{element.statusDisplayName}}" matTooltipClass="idn-tooltip" class="label" tabindex="0" aria-label="Status">{{element.statusDisplayName}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          {{iciComponenetDeploymentEnum.componentDeployment_table_header_actions}}
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="View sku Deployment" matTooltip="View sku Deployment"
            (click)="navigateItem('ici-component-deployment/view', element.id)">
            <mat-icon>remove_red_eye</mat-icon>
          </button>         
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="10" class="mat-paginator-sticky"
  (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
  aria-label="Select page of icicomponentdeploymentrequest elements" [ngClass]="{'hide-pagination': noContent ? true : false}">
</mat-paginator>
  
  </div>
  <ng-template #noContentTemplate>
    <div class="idn-no-result">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
          </clipPath>
        </defs>
        <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
          <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
          <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
          <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
          <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
          <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
          <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
            <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
            <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
          </g>
          <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
            <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
          </g>
        </g>
      </svg>
      <h4>No Data found</h4>
    </div>
  </ng-template>