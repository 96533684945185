import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExtensionService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  public _subject = new BehaviorSubject<any>('');
  public extSubject = new BehaviorSubject<any>('');
  public extDetailsData = new BehaviorSubject<any>('');
  public packageDetailsData = new BehaviorSubject<any>('');

  constructor(
    private http: HttpClient
  ) { }

  public postDataUrl(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }

  public putDataUrl(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.put(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }

  public getExtensions(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number, extensionType: string, customer: string) {
    return this.http.get(this.baseApiUrl + 'api/Extension/List', { params: { 'filter': filterSearchValue, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize, 'extensionType': extensionType, 'customer': customer }, headers: this.headers })
  }

  public getExtensionTypes(userCategory:any, customerId?:any, partnerId:any = null) {
    if(customerId && partnerId){
      return this.http.get(this.baseApiUrl + `api/Extension/ExtensionTypes?customerId=${customerId}&partnerId=${partnerId}&userCategoryId=${userCategory}`)
    }
    else if(customerId && !partnerId){
      return this.http.get(this.baseApiUrl + `api/Extension/ExtensionTypes?customerId=${customerId}&userCategoryId=${userCategory}`)
    }
    else{
      return this.http.get(this.baseApiUrl + 'api/Extension/ExtensionTypes?userCategoryId='+ userCategory)
    }
  }

  public checkForDuplicateExtension(extName: any, customerId: any) {
    return this.http.get(this.baseApiUrl + 'api/Extension/CheckIfExtensionNameExists', { params: { 'name': extName, 'customerId': customerId }, observe: 'response' })
  }

  public getExtensionById(id: number): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Extension/' + id)
  }

  public getCustomers(partnerId: any) {
    return this.http.get(this.baseApiUrl + 'api/Extension/GetCustomerData')
  }

  emitExt<T>(data: T) {
    this._subject.next(data);
  }

  onExt<T>(): Observable<T> {
    return this._subject.asObservable();
  }

  emit<T>(data: T) {
    this.extSubject.next(data);
  }

  on<T>(): Observable<T> {
    return this.extSubject.asObservable();
  }
  emitExtDetails<T>(data: T) {
    this.extDetailsData.next(data);
  }

  onExtDetails<T>(): Observable<T> {
    return this.extDetailsData.asObservable();
  }
  emitPackageDetails<T>(data: T) {
    this.packageDetailsData.next(data);
  }

  onPackageDetails<T>(): Observable<T> {
    return this.packageDetailsData.asObservable();
  }

  public getExtensionPackageList(id: any, sortingColumn: string, pageNumber: number, pageSize: number) {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/GetSolutionPackagesByExtensionId', { params: { 'extensionId': id, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize }, headers: this.headers });
  }

  public getSolutionPackagesForLinkage(extensionId: any, packageId?: any) {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/SolutionPackagesForLinkage', { params: { 'extensionId': extensionId, 'packageId': packageId? packageId :'' }, headers: this.headers });
  }

  public getSolutionPackageHierarchyById(id: any) {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/SolutionPackageHierarchyById', { params: { 'id': id }, headers: this.headers });
  }

  public getExtensionPackageById(id: any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/' + id);
  }

  public GetSolutionPackagesForCertificationRequest(extnid: any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/GetSolutionPackagesForCertificationRequest', {params:{'extensionId': extnid }});
  }

  public isLocked(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Extension/Lock/' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
      .pipe(map(data => {
        return data;
      }))
  }
  public queueForUpgrade(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SolutionPackage/QueuedForUpgrade?id=' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
  }

  public isUnlocked(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Extension/Activate/' + id, { observe: 'response', headers: this.headers, responseType: 'text' }).pipe(map(data => {
      return data;
    }))
  }

  public isPackageUnlocked(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SolutionPackage/UnLock/' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
  }

  public isPackageLocked(id: number): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/SolutionPackage/Lock/' + id, { observe: 'response', headers: this.headers, responseType: 'text' })
  }

  public getProjectType() {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/GetProjectTypes')
  }

  public getBlobUploadConnectionForExtension(id: any) {
    return this.http.get(this.baseApiUrl + 'api/Extension/GetBlobUploadConnection', { params: { 'id': id }, headers: this.headers })
  }

  public getBlobDownloadConnectionForExtension(id: any, filename: any) {
    return this.http.get(this.baseApiUrl + 'api/Extension/GetBlobDownloadConnection', { params: { 'id': id, 'filename': filename }, headers: this.headers })
  }
  public getBlobUploadConnectionForSolution(id: any) {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/GetBlobUploadConnection', { params: { 'id': id }, headers: this.headers })
  }

  public getBlobDownloadConnectionForSolution(id: any, filename: any) {
    return this.http.get(this.baseApiUrl + 'api/SolutionPackage/GetBlobDownloadConnection', { params: { 'id': id, 'filename': filename }, headers: this.headers })
  }

  public CheckRLExtensionExistsForPartner(partnerId:any) {
    return this.http.get(this.baseApiUrl + 'api/Extension/CheckRLExtensionExistsForPartner',{ params:{"partnerId":partnerId}, headers: this.headers, responseType: 'text' })
  }

  public getPartnersByCustomerId(customerId:any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Customer/PartnersByCustomerId', { params: { 'customerId': customerId }, headers: this.headers })
  }
}
