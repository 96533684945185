import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;
  constructor(
    private http: HttpClient
  ) { }

  public GetPartners(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number,) {
    return this.http.get(this.baseApiUrl + 'api/Partner/List', { params: {'filter': filterSearchValue, 'sortBy': sortingColumn,'pageNumber': pageNumber, 'pageSize': pageSize} })
  }
  public PostData(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.post(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }
  public PutData(url: string, paraData?: any, paraBody?: any): Observable<any> {
    return this.http.put(this.baseApiUrl + url, paraBody, { params: paraData, observe: 'response' });
  }
  public activatePartner(id: number, status: boolean): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Partner/Activate/' + id, status, { observe: 'response' })
  }
  public deActivatePartner(id: number, status: boolean): Observable<any> {
    return this.http.put(this.baseApiUrl + 'api/Partner/DeActivate/' + id, status, { observe: 'response' })
  }
  public GetPartnerById(id: number): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Partner/' + id, { observe: 'response' })
  }
  public CheckPartnerName(partnerName: any, partnerCode: any, id: any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Partner/CheckIfPartnerNameExists', { params: { 'Id': id, 'name': partnerName, 'partnercode': partnerCode }, observe: 'response', headers: this.headers })
  }
  public CheckPartnerAssociateWithCustomer(id: any): Observable<any> {
    return this.http.get(this.baseApiUrl + 'api/Partner/CheckIfPartnerExistsInCustomer', { params: { 'id': id }, observe: 'response', headers: this.headers })
  }
}
