<div class="footer">
  <div class="container">
    <div class="wrapper">
      <div class="branding">
        <ul>
          <li>
            <img src="../../../../assets/images/logo/Icertis_logo.svg" alt="Icertis Logo">
          </li>
          <li>
            <a href="javascript:void(0)" (click)="navigateTerms()">{{ 'HOME.MENU.TERMS' | translate }}</a>
          </li>
          <li>
            <a href="javascript:void(0)" (click)="navigateTo('privacy-statement')">{{ 'HOME.MENU.PRIVACY' | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="copyrights">
        <p>&copy; {{ 'HOME.HOMEPAGE.COPYRIGHT_PART_ONE' | translate }} {{currentYear}} <a href="javascript:void(0)" (click)="navigateTo('home')">{{ 'HOME.HOMEPAGE.BRAND' | translate }}</a>. {{ 'HOME.HOMEPAGE.COPYRIGHT_PART_TWO' | translate }}.</p>
      </div>
    </div>
  </div>
</div>
<button class="pss-button-primary pss-button-primary__rounded scroll-top" aria-label="Scroll to the Top of Tage" (click)="scrollToTop()" [class.show] = "sticky"><mat-icon>arrow_right_alt</mat-icon></button>
