<div class="pss-login">
  <div class="pss-login-content">
    <div class="wrapper">
      <img src="../../../assets/images/Polar bear Illustration.svg" alt="Icertis Logo" />
      <div class="idn-certification-errormsg">Oops-a-daisy!</div>
      <div class="idn-certifcation-errdesc">It seems you have not completed <br> the IDN Certification
        course.
      </div>

    </div>
  </div>
  <div class="pss-login-form">
    <div class="form-wrapper">
      <div class="idn-certification-steps">
        <strong>Step 1</strong> : Get Certified - <span><a href="https://icertis.litmos.com?C=9553870">IDN Course Link</a></span>
        <strong>Step 2 </strong>: Access IDN and start development
      </div>
    </div>
  </div>
</div>