import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IciComponentDeploymentService } from 'src/app/core/services/iciComponentDeployment/ici-component-deployment.service';
import { ICIComponentDeploymentManagement } from 'src/app/shared/enum/iciComponentDeployment';
import { NotificationService } from 'src/app/shared/notification/notification.service';

@Component({
  selector: 'app-view-ici-deployment',
  templateUrl: './view-ici-deployment.component.html',
  styleUrls: ['./view-ici-deployment.component.scss']
})
export class ViewIciDeploymentComponent implements OnInit {
  breadCrumbItems:any;
  ViewData:any=[];
  iciSkuDeploymentId: any;
  public iciComponenetDeploymentEnum = ICIComponentDeploymentManagement;

  constructor(private router: Router,
    private titleService: Title,
    private iciComponentDeploymentService: IciComponentDeploymentService,
    private notificationService: NotificationService,) { }

  ngOnInit(): void {
    this.titleService.setTitle('ICI SKU Deployment | IDN');
    this.iciSkuDeploymentId = localStorage.getItem('skudeploymentId');
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'SKU Management', path: 'sku-management/ici-component-deployment' },
      { label: 'ICI SKU Deployment', path: 'sku-management/ici-component-deployment' },
      { label: 'View', path: 'sku-management/ici-component-deployment/view' },
    ];
    this.getDeploymentById()
  }

  getDeploymentById() {
    try {
      this.iciComponentDeploymentService.getDeploymentByID(this.iciSkuDeploymentId).subscribe(
        (resp: any) => {
          let res = resp.body;
          if (res != undefined && res != null) {
            this.ViewData = res;
          } else {
            this.notificationService.showNotification(
              this.iciComponenetDeploymentEnum.componentDeployment_notify_infoNotAvailable_body,
              'warning',
              this.iciComponenetDeploymentEnum.componentDeployment_notify_infoNotAvailable_heading
            );
            this.router.navigate(['home/sku-management/ici-component-deployment']);
          }
        },
        (error: HttpErrorResponse) => {
          let message: any = error;
          this.notificationService.showNotification(
            message,
            'warning',
            this.iciComponenetDeploymentEnum.componentDeployment_notify_infoNotAvailable_heading
          );
          this.router.navigate(['home/sku-management/ici-component-deployment']);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  cancel(){
    this.router.navigate(['home/sku-management/ici-component-deployment']);
  }

}
