import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { RoleService } from 'src/app/core/services/role-management/role.service';
import * as _ from 'lodash';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { FormControl } from '@angular/forms';
import { ShareDataService } from 'src/app/core/services/role-management/share-data.service';
import { Observable, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { RoleMgmtStaticString } from 'src/app/shared/enum/roleManagement';

@Component({
  selector: 'app-map-role',
  templateUrl: './map-role.component.html',
  styleUrls: ['./map-role.component.scss']
})
export class MapRoleComponent implements OnInit {
  public RoleMgmtEnum = RoleMgmtStaticString;
  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;
  filter: string = '';
  displayedColumns: string[] = ['moduleName', 'activity', 'permissions'];
  dataSource: any;
  moduleList: any = [];
  modulevalue = '';
  permissions: any = [];
  ModuleSelect = "ALL";
  checkAll: boolean = false;
  permissionsList = new FormControl();
  $subs!: Subscription;
  pageIndex = 0;
  pageSize = 10;
  pageLength: any;
  CurrentPermissions: any = [];
  permissionId: any;
  RoleName: any;
  breadCrumbItems: any;
  checkedPages: any = [];
  checkedBox: any;
  filterModule: any;
  globalListFilteredData: any;
  filteredData: any;
  allSelected = false;
  @ViewChild('select') select!: MatSelect;
  @ViewChild('selectAllCheckBox') selectAllCheckBox!: MatCheckbox;
  @ViewChild('input') input!: ElementRef;
  checkingForPermissions: any;
  checkBoxCheck = false;
  checkBoxIndeterminate = false;
  testData: any = [];
  onUpdateData: any;
  rolePermissionLists: any;
  actionSave: string ='';
  actionCancel: string ='';
  @ViewChild('search_input') inputName: any;

  sub: Observable<string> | undefined;
  constructor(
    private roleService: RoleService,
    private sharedata: ShareDataService,
    private notificationService: NotificationService,
    private router: Router,
    private msalService: MsalService,
    private route: ActivatedRoute,
    private _liveAnnouncer: LiveAnnouncer
  ) { }


  ngOnInit(): void {
    let params: any = localStorage.getItem('permissions');
    let data = JSON.parse(params);
    let id = data?.id;
    this.RoleName = data?.roleName;
    this.permissionId = id;
    this.GetPermissions(id);
    this.GetPermissionModules();
    this.breadCrumbItems = [
      { label: "Home", path: "resources" },
      { label: "Administration", path: "administration/role-management" },
      { label: "Role Management", path: "administration/role-management" },
      { label: "Permissions", path: "administration/role-management" },
      { label: this.RoleName, path: 'administration/role-management' }
    ];
    this.actionSave = this.RoleMgmtEnum.role_permission_primary_btn;
    this.actionCancel = this.RoleMgmtEnum.role_cancel_btn;
  }

  //Clear the filter input box
  ClearInput() {
    this.modulevalue = '';
    this.input.nativeElement.value = '';
    if (this.filterModule) {
      if (this.filterModule.length > 0) {
        this.filteredData = this.permissions;
        // this.changeFilter(this.filterModule);
      } else {
        this.TableBinding(this.permissions);
      }
    } else {
      this.TableBinding(this.permissions);
    }
  }

  //Table binding
  TableBinding(PermissionLists: any) {
    this.dataSource = new MatTableDataSource(PermissionLists);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  //filter for table ----- Logic changed by Anuj 19/05/2022
  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    if (filterValue.length > 0) {
      let filtered = this.globalListFilteredData.filter((item: any) => {
        return item.activity.toLowerCase().includes(filterValue.toLowerCase());
      })
      this.TableBinding(filtered);
      this.filteredData = filtered;
    } else {
      this.TableBinding(this.globalListFilteredData);
      this.ClearInput();
    }
    this.checkboxCheck();
  }

  changeFilter(value: any) {
    let filteredData: any = [];
    if (value.length > 0) {
      value.forEach((moduleName: any) => {
        let filteredRowData = this.filteredData.filter((item: any) => {
          return item.moduleName.toLowerCase() == moduleName.toLowerCase();
        })
        filteredData.push(filteredRowData)
      });
      let filteredPermissionList = [].concat.apply([], filteredData);
      this.checkedBox = false;
      this.globalListFilteredData = filteredPermissionList;
      this.TableBinding(filteredPermissionList);
    }
    else {
      this.globalListFilteredData = this.filteredData;
      this.TableBinding(this.filteredData);
    }
  }

  //Filters for module ----- Logic changed by Anuj 19/05/2022
  changeModule(event: any) {
    this.filterModule = event.value;
    const moduleList: any = [];
    this.moduleList.forEach((item: any) => moduleList.push(item.name));
    if (event.value.length == moduleList.length) {
      this.selectAllCheckBox.checked = true;
    } else {
      this.selectAllCheckBox.checked = false;
    }
    this.changeFilter(this.filterModule);
    this.checkboxCheck();
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }

  //Get list of permissions based on role id
  GetPermissions(permissionId: number) {
    this.roleService.GetPermissionForRole(permissionId).subscribe((resp: any) => {
      if (resp.length > 0) {
        this.TableBinding(resp);
        this.permissions = resp;
        this.filteredData = resp;
        this.globalListFilteredData = resp;
        this.onUpdateData = resp;
        this.rolePermissionLists = resp;
        this.paginator._intl.itemsPerPageLabel = 'Records per page';
        this.checkboxCheck();
        this.permissionDataLists = resp;
      }
    })
  }

  //Get distinct permissions modules
  GetPermissionModules() {
    this.roleService.GetPermissionModule().subscribe((resp: any) => {
      if (resp.length > 0) {
        this.moduleList = resp;
      }
    })
  }

  selectRows(event: any) {
    let endIndex: number;
    if (event.checked == true) {
      this.checkBoxCheck = true;
      if (this.dataSource.data.length > (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize) {
        endIndex = (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
      } else {
        endIndex = this.dataSource.data.length;
      }
      for (let index = (this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize); index < endIndex; index++) {
        this.dataSource.data[index].permissionAssigned = true;
      }
    } else {
      this.checkBoxCheck = false;
      if (this.dataSource.data.length > (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize) {
        endIndex = (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
      } else {
        endIndex = this.dataSource.data.length;
      }
      for (let index = (this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize); index < endIndex; index++) {
        this.dataSource.data[index].permissionAssigned = false;
      }
    }
  }

  onToggle(event: any, id: number) {
    if (!event.checked) {
      this.checkedBox = false;
    }
    this.dataSource.data.forEach((element: any) => {
      if (id == element.id) {
        element.permissionAssigned = event.checked;
      }
    });
    this.checkboxCheck();
  }

  checkboxCheck() {
    let endIndex: number;
    const testData = [];
    if (this.dataSource.data.length > (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize) {
      endIndex = (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
    } else {
      endIndex = this.dataSource.data.length;
    }
    for (let index = (this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize); index < endIndex; index++) {
      testData.push(this.dataSource.data[index]);
      this.checkBoxCheck = testData.every((item: any) => item.permissionAssigned == true);
      if (testData.every((item: any) => item.permissionAssigned == false)) {
        this.checkBoxIndeterminate = false;
      } else {
        this.checkBoxIndeterminate = testData.some((item: any) => item.permissionAssigned == false);
      }
    }
  }

  //Get to know the pagination details
  onPaginateChange(event: any) {
    this.checkboxCheck();
  }

  //update permissions
  onUpdate() {
    let checkedPermissions: any = [];
    this.onUpdateData.forEach((row: any) => {
      if (row.permissionAssigned) {
        checkedPermissions.push(row.id)
      }
    });
    let dataToUpdate = {
      "roleId": this.permissionId,
      "permissionIdList": checkedPermissions
    }

    this.roleService.UpdatePermissions(dataToUpdate).subscribe((result: any) => {
      if (result != null && result != undefined) {
        if (result.status == 200 || result.status == 201) {
          this.notificationService.showNotification(this.RoleMgmtEnum.role_notify_permission_success_body, 'success', this.RoleMgmtEnum.role_notify_permission_success_heading);
        }
      }
    })
    this.checkboxCheck();
  }

  //-- Re-setting the permissions --
  OnCancel() {
    this.router.navigate(["home/administration/role-management"]);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  // Filter
  SearchInputval: any;
  moduleVal: any;
  partnerNameVal: any;
  permissionDataLists: any;

  @ViewChild('searchInput') searchInput!: ElementRef;

  checkModule(module: any, value: any) {
    let available;
    if (value.length == 0) {
      available = true;
    } else {
      available = value.includes(module)
    }
    return available;
  }

  filteredTableData() {
    let filteredCount
    let Permissionscount = 0;
    let filteredList = this.permissionDataLists.filter((item: any) => {
      return ((this.SearchInputval == (undefined && '')) ? true : item.activity.toLowerCase().includes(this.SearchInputval.toLowerCase())) &&
        ((this.moduleVal == (undefined && '')) ? true : this.checkModule(item.moduleName, this.moduleVal));
    });

    if (this.pageSize >= filteredList.length) {
      filteredCount = filteredList.length
    }
    else {
      filteredCount = this.pageSize
    }
    for (let permission = 0; permission < filteredCount; permission++) {
      if (!filteredList[permission].permissionAssigned) {
        this.checkBoxCheck = false;
      }
      else {
        Permissionscount++;
      }
    }
    if (Permissionscount == this.pageSize) {
      this.checkBoxCheck = true;
    }
    this.dataSource = new MatTableDataSource(filteredList);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  permissionFilter(event: any, type: any) {
    if (type == 'name') {
      this.SearchInputval =  this.inputName.nativeElement.value
    } else if (type == 'module') {
      this.moduleVal = event.value;
    }
    this.filteredTableData();
  }

  clearFilter() {
    this.inputName.nativeElement.value = '';
    this.SearchInputval = '';
    this.GetPermissions(this.permissionId);
  }
  // Filter

}
