import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  constructor(
    private router: Router,
    private titleService: Title,
    private msalService: MsalService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Un-authorized | IDN');
  }

  navigateHome(): void {
    let ss = (this.msalService.instance.getActiveAccount())?.homeAccountId;
    let loginhint = (this.msalService.instance.getActiveAccount())?.idTokenClaims?.login_hint;
    const currentAccount = this.msalService.instance.getAccountByHomeId(ss!);
    if (currentAccount != null) {
      this.msalService.instance.logoutRedirect({ account: currentAccount, idTokenHint: loginhint, logoutHint: loginhint });
      localStorage.clear();
      }
      else {
        this.router.navigate(['']);
      }
  }
}
