<div class="page-container">
  <div class="idn-view-wrapper p-0">
    <div class="idn-view-body">
      <mat-grid-list cols="4" rowHeight="60px">
        <mat-grid-tile [colspan]="1" >
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.extension_View_Field_type}}</div>
            <div class="idn-view-body-items-data">{{extDetailsData.extensionType}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" >
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.extension_View_Field_name}}</div>
            <div class="idn-view-body-items-data overflow-data" matTooltip="{{extDetailsData.name}}">{{extDetailsData.name}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1" >
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{extensionEnum.extension_View_Field_customerName}}</div>
            <div class="idn-view-body-items-data overflow-data" matTooltip="{{extDetailsData.customerName}}">{{extDetailsData.customerName}}</div>
          </div>
        </mat-grid-tile>

        <mat-grid-tile [colspan]="1">
          <div class="idn-view-body-items">
            <div class="idn-view-body-items-label">{{ extensionEnum.extension_View_Field_partnerName }}</div>
            <div class="idn-view-body-items-data dp-flex"><div class="overflow-data" matTooltip="{{extDetailsData.partners?.length? extDetailsData.partners[0]?.partnerName: ''}}">{{extDetailsData.partners?.length? extDetailsData.partners[0]?.partnerName: 'NA'}}</div>
              <a *ngIf="extDetailsData.partners?.length > 0"  class="idn-grid-rec-count"
                href="javascript:void (0)" (click)="closeListDrawer(extDetailsData)">{{0>extDetailsData.partners.length - 1
                ? '' : extDetailsData.partners.length-1 >0 ? '+'+(extDetailsData.partners.length-1) : ''}}</a>
            </div>
          </div>
        </mat-grid-tile> 
      </mat-grid-list>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{extensionEnum.extension_View_Field_tag}}</div>
        <div class="idn-view-body-items-data">
          <div class="rows idn-view-mt-1">
            <div *ngFor="let tag of extDetailsData.tags">
              <p class="mat-body tag">{{tag}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{extensionEnum.extension_View_Field_extensionDescription}}</div>
        <div class="idn-view-body-items-data">{{extDetailsData.description}}</div>
      </div>

      <div *ngIf="extDetailsData.status == 2" class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{extensionEnum.extension_View_lockDescription}}</div>
        <div class="idn-view-body-items-data">{{extDetailsData.modifiedByDisplayName}}
          <span class="view-timestamp">on {{extDetailsData.modifiedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" |
            dateFormat}}</span>
        </div>
      </div>

      <div class="idn-view-body-items">
        <div class="idn-view-body-items-label">{{extensionEnum.extension_View_Field_referenceDocument}}</div>
        <div class="idn-view-body-items-data">
          <div class="idn-view-mt-1">
            <div class="idn-norecord-comp" *ngIf="extDetailsData.files == null || extDetailsData.files == undefined">
              <mat-icon svgIcon="no_document_icon"></mat-icon>
              <div>
                <h4>{{extensionEnum.extension_view_hint_message_noRefDocument_label}}</h4>
                <p>{{extensionEnum.extension_view_hint_message_noRefDocument_para}}</p>
              </div>
            </div>
            <mat-chip-list class="idn-chips-download"
              [ngStyle]="{'pointer-events':extDetailsData.status == 2 ? 'none' : '' }"
              [disabled]="extDetailsData.status == 2" aria-label="File Download" *ngFor="let file of extDetailsData.files">
              <mat-chip (click)="extDetailsData.status == 1 ? downloadSingleFile(extDetailsData.id,file): ''">{{file}}
                <mat-icon>download</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
<app-idn-list-drawer *ngIf="listPartner" [data]="partnerData" (closeEvent)="closeListDrawer($event)"></app-idn-list-drawer>
<div class="pss-form-button-wrapper">
  <button mat-stroked-button color="primary" class="idn-button-secondary"
    [disabled]="extDetailsData.files == null || extDetailsData.files == undefined || extDetailsData.status == 2"
    (click)="(extDetailsData.files != null && extDetailsData.files != undefined && extDetailsData.status == 1) ? downloadAllFiles(extDetailsData) : ''">
    <mat-icon>download</mat-icon> <span class="paddingRight10"> {{extensionEnum.extension_Create_dowloadAllFiles_primary_btn}}</span>
  </button>
  <button mat-stroked-button (click)="onCancel('extension-management')">{{extensionEnum.extension_Create_cancel_btn}}</button>
</div>