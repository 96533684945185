import { NgModule  } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CertificationsRoutingModule } from './certifications-routing.module';
import { CertificationsListingComponent } from './certifications-listing/certifications-listing.component';
import { IntiateCertificationComponent } from './intiate-certification/intiate-certification.component';
import { CertificationDetailsComponent } from './certification-details/certification-details.component';
import { CertificationManagementComponent } from './certification-management.component';
import { CertificationApproverListComponent } from './certification-approver-list/certification-approver-list.component';
import { CertificationApproverDetailsComponent } from './certification-approver-details/certification-approver-details.component';
import { DownloadFilesComponent } from './download-files/download-files.component';
import { UpdateCertificationComponent } from './update-certification/update-certification.component';
import { CertificationChecklistComponent } from './certification-checklist/certification-checklist.component';
import { PageHeaderModule } from '../pageheader/pageheader.module';
//Material Module
import { MaterialModule } from 'src/app/material.module';
import { DateFormatPipe } from 'src/app/core/Pipes/certification/date-format.pipe';
import { CertificationRequestComponent } from './certification-request/certification-request.component';
import { QuillModule } from 'ngx-quill'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';

@NgModule({
  declarations: [
    CertificationsListingComponent,
    IntiateCertificationComponent,
    CertificationDetailsComponent,
    CertificationManagementComponent,
    CertificationApproverListComponent,
    CertificationApproverDetailsComponent,
    DownloadFilesComponent,
    UpdateCertificationComponent,
    CertificationChecklistComponent,
    CertificationRequestComponent,
    DateFormatPipe
  ],
  imports: [
    CommonModule,
    CertificationsRoutingModule,
    MaterialModule,
    FormsModule,
    PageHeaderModule,
    ReactiveFormsModule,
    QuillModule,
    NgxMatSelectSearchModule,
    MatSelectModule
  ],
  exports: [
    CertificationsListingComponent,
    IntiateCertificationComponent,
    CertificationDetailsComponent,
    CertificationManagementComponent,
    CertificationApproverListComponent,
    CertificationRequestComponent,
    DateFormatPipe
  ]
})
export class CertificationsModule { }
