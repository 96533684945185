<mat-drawer-container class="idn-drawer-internal">
    <mat-drawer #drawerConfigView class="idn-drawer" mode="over">
        <div class="header">
            <div class="heading">
                <mat-icon>vpn_key</mat-icon>
                <h2>{{instanceEnum.instance_config_drawer_heading}}</h2>
            </div>
            <button id="closeBtn" mat-button="closebtn" (click)="closeDrawer()" aria-label="close Button">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="body">
            <div class="page-container">
                <div class="idn-view-wrapper">
                    <div class="idn-view-body">
                      <div class="idn-info">
                        <mat-icon>info</mat-icon>
                        <h4>
                          {{instanceEnum.instance_config_drawer_screen_message}}
                        </h4>
                      </div>
                      <div class="form-wrapper">
                            <div class="edit-config-field">
                                <mat-label>{{instanceEnum.instance_edit_config_name}}</mat-label>
                                <mat-form-field class="pss-block-form" *ngIf="editableFeilds?.includes('name')">
                                        <input matInput placeholder="Name" [value]="compareValue.Name"
                                        [(ngModel)]="compareValue.Name">
                                  </mat-form-field>
                                  <span *ngIf="!editableFeilds?.includes('name')">{{compareValue.Name}}</span>
                            </div>
                            <div class="edit-config-field">
                                <mat-label>{{instanceEnum.instance_edit_config_value}}</mat-label>
                                <mat-form-field class="pss-block-form" *ngIf="compareValue.ValueType == 'System.Boolean'">
                                    <mat-select placeholder="Value" matNativeControl [(ngModel)]="compareValue.Value" (ngModelChange)="validateBoolean($event)" >
                                      <mat-option [value]="true">True</mat-option>
                                      <mat-option [value]="false">False</mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                  <div class="input-field" *ngIf="compareValue.ValueType == 'System.Int32' || compareValue.ValueType == 'System.Int64' ">
                                    <mat-form-field class="pss-block-form" >
                                      <input matInput placeholder="Value" [value]="compareValue.Value" (keypress)="checkIntType($event)"
                                      [(ngModel)]="compareValue.Value" (input)="compareValue.ValueType == 'System.Int32'? validateInt32Type($event): validateInt64Type($event)">
                                  </mat-form-field>
                                  <mat-error *ngIf="invalidData">{{validateError}}</mat-error>
                                  </div>
                                  <div class="input-field" *ngIf="compareValue.ValueType == 'System.String' && !jsonInput ">
                                    <mat-form-field class="pss-block-form" >
                                      <input matInput placeholder="Value" [value]="compareValue.Value"
                                      [(ngModel)]="compareValue.Value" (input)="validateStringType($event)">
                                  </mat-form-field>
                                  <mat-error *ngIf="invalidData">{{validateError}}</mat-error>
                                </div>
                                <div class="input-field-json" *ngIf="jsonInput && !(compareValue.ValueType == 'System.Int32' || compareValue.ValueType == 'System.Int64' || compareValue.ValueType == 'System.Boolean')" >
                                  <mat-form-field class="pss-block-form" >
                                    <textarea matInput placeholder="Value" [value]="compareValue.Value" width="50"
                                    [(ngModel)]="compareValue.Value" (input)="validateJsonType($event)">
                                  </textarea>
                                </mat-form-field>
                                <mat-error *ngIf="invalidData">{{validateError}}</mat-error>
                                </div>
                            </div>
                            <div class="edit-config-field">
                              <mat-label>{{instanceEnum.instance_edit_config_valueType}}</mat-label>
                              <mat-form-field class="pss-block-form" *ngIf="editableFeilds?.includes('valueType')">
                                      <input matInput placeholder="ValueType" [value]="compareValue.ValueType"
                                      [(ngModel)]="compareValue.ValueType">
                                </mat-form-field>
                                <span *ngIf="!editableFeilds?.includes('valueType')">{{compareValue.ValueType}}</span>
                          </div>
                          <div class="edit-config-field">
                            <mat-label>{{instanceEnum.instance_edit_config_OverrideType}}</mat-label>
                            <mat-form-field class="pss-block-form" *ngIf="editableFeilds?.includes('overrideType')">
                                    <input matInput placeholder="OverrideType" [value]="compareValue.OverrideType"
                                    [(ngModel)]="compareValue.OverrideType">
                              </mat-form-field>
                              <span *ngIf="!editableFeilds?.includes('overrideType')">{{compareValue.OverrideType}}</span>
                        </div>
                        <div class="edit-config-field">
                          <mat-label>{{instanceEnum.instance_edit_config_DefaultValue}}</mat-label>
                          <mat-form-field class="pss-block-form" *ngIf="editableFeilds?.includes('defaultValue')">
                                  <input matInput placeholder="DefaultValue" [value]="compareValue.DefaultValue"
                                  [(ngModel)]="compareValue.DefaultValue">
                            </mat-form-field>
                            <span *ngIf="!editableFeilds?.includes('defaultValue')">{{compareValue.DefaultValue?compareValue.DefaultValue: "-"}}</span>
                      </div>
                      <div class="edit-config-field">
                        <mat-label>{{instanceEnum.instance_edit_config_IsEncrypted}}</mat-label>
                        <mat-form-field class="pss-block-form" *ngIf="editableFeilds?.includes('isEncrypted')">
                                <input matInput placeholder="IsEncrypted" [value]="compareValue.IsEncrypted"
                                [(ngModel)]="compareValue.IsEncrypted">
                          </mat-form-field>
                          <span *ngIf="!editableFeilds?.includes('IsEncrypted')">{{compareValue.IsEncrypted}}</span>
                    </div>
                      </div>
                        
                    </div>
                  </div>
            </div>
        </div>
        <div class="pss-drawer-button-wrapper">
          <button mat-raised-button color="primary" (click)="save(compareValue)" [disabled]="invalidData || !this.compareValue.isModified">
              <span>Save</span>
          </button>
          <button mat-stroked-button (click)="closeDrawer()">Cancel</button>
      </div>
    </mat-drawer>
</mat-drawer-container>