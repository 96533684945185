import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerManagementRoutingModule } from './partner-management-routing.module';
import { ListPartnerComponent } from './list-partner/list-partner.component';
import { CreatePartnerComponent } from './create-partner/create-partner.component';
import { EditPartnerComponent } from './edit-partner/edit-partner.component';
import { MaterialModule } from 'src/app/material.module';
import { PageHeaderModule } from '../pageheader/pageheader.module';
import { ViewPartnerComponent } from './view-partner/view-partner.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule, MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    ListPartnerComponent,
    CreatePartnerComponent,
    EditPartnerComponent,
    ViewPartnerComponent
  ],
  imports: [
    CommonModule,
    PartnerManagementRoutingModule,
    MaterialModule,
    PageHeaderModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    ListPartnerComponent,
    CreatePartnerComponent,
    EditPartnerComponent,
    ViewPartnerComponent
  ]
})
export class PartnerManagementModule { }
