import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SkuManagementService {

  baseApiUrl: string = environment.appGatewayUrl;
  headers: any = environment.headers;

  constructor(private http: HttpClient) { }

  public initiateReleaseVersion(payload: any) {
    return this.http.post<any>(`${this.baseApiUrl}api/SKU`, payload, { headers: this.headers });
  }

  public getSKUList(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number, productFamily: string, SKUStatus: string) {
    return this.http.get<any>(`${this.baseApiUrl}api/SKU/List`, { params: { 'filter': filterSearchValue, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize, 'productFamily': productFamily, 'status': SKUStatus }, headers: this.headers });
  }

  public getSKUApprovedList(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number, productFamily: string, partner: string) {
    return this.http.get<any>(`${this.baseApiUrl}api/SKU/ApprovedList`, { params: { 'filter': filterSearchValue, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize, 'productFamily': productFamily, 'partner': partner }, headers: this.headers });
  }

  public GetPartners() {
    return this.http.get<any>(`${this.baseApiUrl}api/SKU/GetPartners`)
  }

  public viewSKU(id: any) {
    return this.http.get<any>(`${this.baseApiUrl}api/sku/${id}`)
  }

  public approve(payload: any) {
    return this.http.put<any>(`${this.baseApiUrl}api/SKU/SKUApprove`, payload);
  }

  public reject(payload: any) {
    return this.http.put<any>(`${this.baseApiUrl}api/SKU/SKUReject`, payload);
  }

  public update(payload: any) {
    return this.http.put<any>(`${this.baseApiUrl}api/sku/${payload.id}`, payload);
  }

  public getExtensionTypes() {
    return this.http.get(`${this.baseApiUrl}api/Extension/ExtensionTypes`);
  }

  public getSKUCustomer(filterSearchValue: string, sortingColumn: string, pageNumber: number, pageSize: number, instanceType: string, deployedDateFrom: string, deployedDateTo: string) {
    return this.http.get(`${this.baseApiUrl}api/SKU/SKUDeployments`, { params: { 'filter': filterSearchValue, 'sortBy': sortingColumn, 'pageNumber': pageNumber, 'pageSize': pageSize, 'instanceType': instanceType, 'deployedDateFrom': deployedDateFrom, 'deployedDateTo': deployedDateTo }, headers: this.headers });
  }

  public GetInstanceTypes() {
    return this.http.get(`${this.baseApiUrl}api/sku/GetInstanceTypes`);
  }

}
