import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { catchError, Subscription, throwError } from 'rxjs';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { MatSelect } from '@angular/material/select';

enum certificationListStatus {
  systemCheckSuccess = 'System Check Success',
  certified = 'Certified',
  systemCheckFailed = 'System Check Failed',
  reviewRequested = 'Review Requested',
  rejected = 'Rejected',
  reviewInProgress = 'Review In-Progress',
  certificationRequested = 'Certification Requested'
}

@Component({
  selector: 'app-certifications-listing',
  templateUrl: './certifications-listing.component.html',
  styleUrls: ['./certifications-listing.component.scss']
})

export class CertificationsListingComponent implements OnInit {
  displayedColumns: string[] = ['packageName', 'partnerName', 'status', 'action'];

  dataSource: any;
  @Input() extDetailsData: any;
  @Output() certificationDetails: EventEmitter<any> = new EventEmitter();
  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('status') status !: MatSelect;
  drawerSubscription!: Subscription;
  certificationListRefershSubscription!: Subscription;
  intiateCertificationActive: boolean = false;
  downloadFilesActive: boolean = false;
  certDetails: any;
  noCertification: Boolean = false;
  isExceptionalApproval: any;
  isInitiateManualCertification: any;
  public extensionEnum = ExtensionManagement;
  // Make a variable reference to certificationListStatus Enum
  certificationStatus = certificationListStatus;

  //Filter
  sortBy: any = '';
  pageNumber = 1;
  pageSize = 10;
  currentPage = 0;
  pageLength: any = 0;
  filterValue: any = '';

  CertificationStatus: any = [
    { name: "Certification Requested", id: 1 },
    { name: "System Check Failed", id: 2 },
    { name: "System Check Success", id: 3 },
    { name: "Review Requested", id: 4 },
    { name: "Review In-Progress", id: 5 },
    { name: "Certified", id: 6 },
    { name: "Rejected", id: 7 },
  ]
  certificationStatusId: any = '';
  selectAll: any = "selectAll";

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  certificationEnum = CertificationManagement;
  loggedinUserEmail: string | null | undefined;
  isInitiateAutomaticCertificationPermission: boolean | undefined;
  hasGlobalDataAccess: any;
  loggedInUserPartnerId: any;

  constructor(
    private certificationsService: CertificationsService,
    private notificationService: NotificationService,
    private permissionService: PermissionsService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loggedinUserEmail = localStorage.getItem("email");
    this.isExceptionalApproval = this.permissionService.getPermission("InitiateManualCertification-ExceptionApproval");
    this.isInitiateManualCertification = this.permissionService.getPermission("InitiateManualCertification");
    this.isInitiateAutomaticCertificationPermission = this.permissionService.getPermission("InitiateAutomaticCertification");
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.loggedInUserPartnerId = this.permissionService.getPartnerDetails().id;
    this.CertificationStatus = this.CertificationStatus?.sort((a: any, b: any) => { return a.name.localeCompare(b.name); });
    this.drawerSubscription = this.certificationsService.intiateCertificationToggleSubject.subscribe(data => {
      if (data) {
        this.intiateCertificationActive = false;
        this.downloadFilesActive = false;
      }
      this.getCertificationList();
    });
    this.certificationListRefershSubscription = this.certificationsService._certificationList.subscribe(res => {
      if (res == "redirectToCertificationList" || res == "redirectToCertificationListFromDetails") {
        this.getCertificationList();
      }
    })
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.value = this.filterValue;
    this.status.value = this.certificationStatusId;
    this.cd.detectChanges();
  }

  getCertificationList() {
    let filterValue: any = localStorage.getItem("FilterRecordExtensionCertification");
    if (filterValue != undefined && filterValue != null && filterValue != '') {
      this.filterValue = JSON.parse(filterValue).filter
      this.certificationStatusId = JSON.parse(filterValue).certificationPackageStatus != undefined && JSON.parse(filterValue).certificationPackageStatus != this.selectAll ? JSON.parse(filterValue).certificationPackageStatus : "";
    }
    this.certificationsService.getCertificationList(this.extDetailsData, this.sortBy, this.pageNumber, this.pageSize, this.filterValue, this.certificationStatusId).pipe(
      catchError(err => {
        if (err != 200) {
          this.noCertification = true;
        }
        return throwError(err);
      })
    ).subscribe(data => {
      if (data != undefined && data != null && data != "") {
        this.noCertification = false;
        this.dataSource = new MatTableDataSource(data.records);
        this.paginator.pageIndex = this.currentPage;
        this.paginator.length = data.matchingCount;
      } else {
        this.noCertification = true;
      }
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.pageNumber = event.pageIndex + 1;
    this.getCertificationList();
  }

  // ** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.sortBy = sortState.active + (sortState.direction == "asc" ? '' : '_' + sortState.direction);
    this.getCertificationList();
  }

  viewCertificationDetails(element: any) {
    let Json = {
      filter: this.searchInput.nativeElement.value,
      certificationPackageStatus: this.status?.value != this.selectAll ?  this.status?.value : ''
    };
    localStorage.setItem("FilterRecordExtensionCertification", JSON.stringify(Json))
    this.certificationsService.emitCertificationDetails(element);
    this.certificationDetails.emit(true);
  }

  // Initiate Manual Certification
  initiateManualCertification(element: any) {
    this.certDetails = element;
    if (element.status == "System Check Success") {
      this.certificationsService.initiateManualCertification(element).pipe(
        catchError(err => {
          if (err.status != 200) {
            this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationFailed_body_p1 + element.packageName + this.certificationEnum.certification_notify_manualCertificationFailed_body_p2, 'error', this.certificationEnum.certification_notify_manualCertificationFailed_heading);
          }
          return throwError(err);
        })
      ).subscribe(response => {
        this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationRequested_body_p1 + element.packageName + this.certificationEnum.certification_notify_manualCertificationRequested_body_p2, 'success', this.certificationEnum.certification_notify_manualCertificationRequested_heading);
        this.getCertificationList();
      })
    } else if (element.status == "System Check Failed") {
      this.intiateCertificationActive = true;
    }
  }

  // Download Files
  downloadFiles(element: any) {
    this.certificationsService.emitCertificationDetails(element);
    if (!this.downloadFilesActive) {
      this.downloadFilesActive = true;
    }
  }

  ngOnDestroy() {
    if (this.drawerSubscription) {
      this.drawerSubscription.unsubscribe();
    }
    if (this.certificationListRefershSubscription) {
      this.certificationListRefershSubscription.unsubscribe();
    }
    const url1: string = '/home/developmentandutilities/extension-management/view';
    const url2: string = '/home/developmentandutilities/extension-management/edit';
    if (window.location.pathname != url1 && window.location.pathname != url2) {
      localStorage.removeItem('FilterRecordExtensionCertification');
    }
  }

  refresh(event?: any) {
    try {
      event?.preventDefault();
      event?.stopPropagation();
      this.getCertificationList();
    }
    catch (error) {
      console.log(error);
    }
  }

  withdrawRequest(element: any) {
    try {
      this.dialogService
        .confirmDialogComments({
          title: "Confirm Request Withdrawal",
          module: 'withdraw-certification',
          message: ' To withdraw your certification request for package <b>' + element.packageName + '</b>, click <b>Confirm</b>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
          if (res) {

            let JSON = {
              id: element.id,
              requesterNote: res,
            };

            this.certificationsService.WithdrawManualCertification(JSON).subscribe((resp: any) => {
              this.notificationService.showNotification(
                'Your certification request is withdrawn. Request status is changed to System Check Success.',
                'success',
                'Success'
              );
              this.refresh();
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  }


  filter() {
    let filters = localStorage.getItem('FilterRecordExtensionCertification');
    if (filters != null) {
      let Json = {
        filter: this.searchInput.nativeElement.value,
        certificationPackageStatus: this.status?.value != this.selectAll ?  this.status?.value : ''
      };
      localStorage.setItem("FilterRecordExtensionCertification", JSON.stringify(Json))
    } else {
      this.filterValue = this.searchInput.nativeElement.value,
      this.certificationStatusId = this.status?.value != this.selectAll ?  this.status?.value : '';
    }
    this.pageNumber = 1;
    this.currentPage = 0;
    this.getCertificationList();
  }

  clearFilter() {
    localStorage.removeItem("FilterRecordExtensionCertification")
    this.filterValue = '';
    this.searchInput.nativeElement.value = this.filterValue;
    this.certificationStatusId = '';
    this.status.value = this.certificationStatusId;
    this.getCertificationList();
  }

  ClearInput() {
    this.searchInput.nativeElement.value = '';
    this.filter();
  }

}
