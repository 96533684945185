<div class="idn-access-denied">
  <div class="wrapper">
    <mat-icon class="icon-main">person</mat-icon>
    <h1>Un authorized!</h1>
    <p>The user which you are trying to Login is either in-Active or not available on IDN, try again again with a valid user account.</p>
    <button mat-stroked-button class="button-info" (click)="navigateHome()">
      <mat-icon>keyboard_backspace</mat-icon>
      <span>Go Back</span>
    </button>
  </div>
</div>
