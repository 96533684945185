export enum WebAppManagement {
  webApp_header_create = "Create WebApp",
  

  webApp_label_app_name = 'App Name',
  webApp_label_package = 'Package',
  webApp_label_url = 'URL',
  webApp_label_app_settings = 'App Settings',

  webApp_label_app_key="KEY",
  webApp_label_app_value="Value",
  webApp_label_create_add_btn="Add",

  webApp_error_app_name = 'Invalid App Name',
  webApp_error_app_settings = "WebApp settings can not be blank",

  webApp_label_file_browse_btn = 'Browse',
  webApp_Create_Field_referenceDocument = 'Supporting Documents',
  webApp_notify_invalid_file_type_heading = 'Invalid file type',
  webApp_notify_invalid_file_type_body = 'Please upload valid file type.',
  webApp_notify_exceed_file_size_heading = 'File size exceeded',
  webApp_notify_exceed_file_size_body = 'File size is more than 50 MB.',
  webApp_notify_choose_file_heading = "No File choosen",
  webApp_notify_choose_file_body = "Please choose file.",
  webApp_notify_duplicate_file_heading = "Duplicate File",
  webApp_notify_duplicate_file_body = "File Already Added.",
  webApp_notify_submit_successful= "Submit Successful",
  webApp_notify_submit_unsuccessful= "Submit Unsuccessful",

  /* FIELD HINT MESSAGES */
  webApp_hint_documents = 'Upload multiple files of 50 MB each. Supported file type is zip.',
  webApp_notify_fileSizeExceed_heading = "File size exceeded",
  webApp_notify_fileSizeExceed_body = "File size is more than 50 MB",
}
