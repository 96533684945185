import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';
import { from } from 'rxjs';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionsService } from '../../services/common/permissions.service';

@Injectable({
  providedIn: 'root'

})
export class ValidateUserInterceptor implements HttpInterceptor {
  baseApiUrl: string = environment.appGatewayUrl;
  headers: any =  environment.headers;

  constructor(
    private permissionService: PermissionsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return from(this.handle(request, next))
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    let isActive;
    let isTermsAccepted;
    //Check if reqsuest is POST or PUT and also Check if it is authenticatedUser or not
    if ((request.method === 'POST' || request.method === 'PUT') && localStorage.getItem("authenticatedUser") != null && localStorage.getItem("authenticatedUser") != undefined) {
      isActive = this.permissionService.isActiveUser();
      isTermsAccepted = this.permissionService.isTermsAccepted();
    }

    //Check if user is active or not, if not then redirect to unauthorized page otherwise pass the request.
    if (isActive != undefined && isActive) {
      return await lastValueFrom(next.handle(request))
    } else if (isActive != undefined) {
      return await lastValueFrom(next.handle(request))
    } else {
      return await lastValueFrom(next.handle(request))
    }

  }
}

