import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { TermsAndConditionsService } from 'src/app/core/services/terms-and-conditions/terms-and-conditions.service';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { catchError, throwError } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { TermsConditions } from 'src/app/shared/enum/termsConditions';
import { LogoutString } from 'src/app/shared/enum/logoutString';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TermsDialogComponent } from '../terms/terms-dialog.component';
import { IdnConfigurationService } from 'src/app/core/services/common/idn-configuration.service';
@Component({
  selector: 'app-accept-terms-of-use',
  templateUrl: './accept-terms-of-use.component.html',
  styleUrls: ['./accept-terms-of-use.component.scss']
})

export class AcceptTermsOfUseComponent implements OnInit {
  public logoutEnum = LogoutString;
  username: any = ''
  isChecked: any =  false;
  public termsString = TermsConditions;
  constructor(
    private titleService: Title,
    private msalService: MsalService,
    private dialogService: DialogService,
    private TermsAndConditionsService: TermsAndConditionsService,
    private router: Router,
    private permissionService: PermissionsService,
    private notificationService: NotificationService,
    private matDialog: MatDialog,
    private idnConfiguration:IdnConfigurationService,
  ) { }

  ngOnInit(): void {
    this.username = localStorage.getItem('username');
    this.titleService.setTitle('Accept terms of use | IDN');
    if (localStorage.getItem("authenticatedUser") != null && localStorage.getItem("authenticatedUser") != undefined) {
      this.permissionService.getPermissionListForUser().subscribe(async res => {
        if (res.body != undefined || res.body != null) {
            let authuserpermissions = res.body;
            if(authuserpermissions.user.isTermsAccepted) {
              let configValues =await this.idnConfiguration.getConfigValues();        
              if(configValues){
                 if(!(this.permissionService.getPermission('UpgradeMultiTenantPOD') && !this.permissionService.getPermission('PreviewMultiTenantPODUpgrade'))){
                   this.router.navigate(['home/resources']);
                 }else{
                   this.router.navigate(['home/upgrade/pod-upgrade']);
                 }
               }
            }
        }
      })
    }
  }

  openTerms(event: any){
    if(event.checked) {
      this.matDialog.open(TermsDialogComponent, {
        data: { acceptedAt: false, action: true },
        disableClose: true
      }).afterClosed();
    }
  }

  agree(){
    this.TermsAndConditionsService.accept().pipe(
      catchError(err => {
        this.notificationService.showNotification(this.termsString.terms_notify_failed_body, 'error', this.termsString.terms_notify_failed_heading);

        return throwError(err);
      })
    ).subscribe(res => {
      this.notificationService.showNotification(this.termsString.terms_notify_success_body, 'success', this.termsString.terms_notify_success_heading);
      if(!(this.permissionService.getPermission('UpgradeMultiTenantPOD') && !this.permissionService.getPermission('PreviewMultiTenantPODUpgrade'))){
        this.router.navigate(['home/resources']);
      }else{
        this.router.navigate(['home/upgrade/pod-upgrade']);
      }
    });
  }

  cancelTerms(): void {
    this.dialogService.confirmDialog({
      title: this.logoutEnum.logout_dialog_heading,
      module: 'logout',
      message: this.logoutEnum.logout_dialog_body,
      confirmText: this.logoutEnum.logout_dialog_primary_btn,
      cancelText: this.logoutEnum.logout_dialog_secondary_btn
    }).subscribe(res => {
      if (res) {
        localStorage.removeItem("authenticatedUser");
        let ss = (this.msalService.instance.getActiveAccount())?.homeAccountId;
        let loginhint = (this.msalService.instance.getActiveAccount())?.idTokenClaims?.login_hint;
        const currentAccount = this.msalService.instance.getAccountByHomeId(ss!);
        if (currentAccount != null) {
          this.msalService.instance.logoutRedirect({ account: currentAccount, idTokenHint: loginhint, logoutHint: loginhint });
          localStorage.clear();
        } else {
          this.msalService.instance.logoutRedirect()
        }
      }
    });

  }

}
