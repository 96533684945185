import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { Subscription } from 'rxjs';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';

@Component({
  selector: 'app-certification-checklist',
  templateUrl: './certification-checklist.component.html',
  styleUrls: ['./certification-checklist.component.scss']
})
export class CertificationChecklistComponent implements OnInit {

  @ViewChild('drawer', { static: true }) public drawer!: MatDrawer;

  fileAttr = "Choose File";
  panelOpenState = false;
  checked = false;
  toggle = "NO";
  certificationsAnswersList: any;
  checkList: any;
  certificationDetailsSubscription!: Subscription;
  certificationCheckListSubscription!: Subscription;
  certificationId : any;
  certificationDetails: any;
  certificationEnum=CertificationManagement;
  constructor(private certificationsService: CertificationsService) { }

  ngOnInit(): void {
    let self = this;
    this.certificationsService.setDrawer(this.drawer);
    this.certificationDetailsSubscription = this.certificationsService.onCertificationDetails<any>().subscribe(
      data => {
        if (data != null && data != "") {
          this.certificationId = data.id
        } else {
          let certificationDetails = localStorage.getItem('certificationId');
          this.certificationId = certificationDetails
        }
        this.certificationsService.getCertificationDetails(this.certificationId).subscribe(response => {
          this.certificationsAnswersList = response.checkListItemAnswersList;
          this.certificationDetails = response;
          self.certificationsService.open();
        })
      }
    );
    this.certificationCheckListSubscription = this.certificationsService.certificationChecklist().subscribe(response => {
      this.checkList = response;
    });
	}

  closeDrawer() {
    this.certificationsService.closeIntiateCertification(true);
  }

  downloadAllFlies() {
    this.certificationsService.closeIntiateCertification(true);
  }

  ngOnDestroy(){
    this.certificationsService.closeIntiateCertification(false);
    if(this.certificationDetailsSubscription) {
      this.certificationDetailsSubscription.unsubscribe();
    }
    if(this.certificationCheckListSubscription){
      this.certificationCheckListSubscription.unsubscribe();
    }
  }

}
