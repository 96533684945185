<app-pageheader [pageTitle]="'IDN Workbench / Upload'" [auditLog]="false" [createRole]="false" [mapRole]="true" [breadCrumbs]="this.breadCrumbItems"
  [applyFilter]="false" [clearFilter]="false" [archive]="false"></app-pageheader>
<div class="page-container">
  <div class="form-wrapper">
    <h2>{{ this.WbMgmtEnum.wb_header_create }}</h2>
    <!-- <div class="pss-info-widget">
      <mat-icon class="icon">info</mat-icon>
      <p>To create SDK make SDK File URL handy and provide basic details below</p>
    </div> -->

    <form class="pss-dashboard-form" [formGroup]="frmSDK" >

      <div class="pss-left-panel">
        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_name }}</mat-label>
          <input type="text" matInput maxlength="100" placeholder="Enter Name of IDN Workbench" formControlName="Name" [(ngModel)]="myInputValuename" (ngModelChange)="valueChangename($event)"  required>
          <mat-error *ngIf="frmSDK.controls.Name.invalid">{{ errorDescriptionName }}</mat-error>
        </mat-form-field>
        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_description }}</mat-label>
          <textarea class="idn-min-textarea-height" maxlength="500" width="50" matInput placeholder="Description of IDN Workbench" [(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)"
            formControlName="Description" required></textarea>
            <mat-error *ngIf="frmSDK.controls.Description.invalid">{{ errorDescriptionField }}</mat-error>
        </mat-form-field>
        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_version }}</mat-label>
          <input type="text" length="11" width="50" maxlength="15" matInput placeholder="IDN Workbench Version" formControlName="Version"
            required>
            <mat-error>
              <span  *ngIf="frmSDK.controls.Version.invalid">{{ this.WbMgmtEnum.wb_error_version }}</span>
            </mat-error>

        </mat-form-field>

        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_file }}</mat-label>
          <mat-toolbar class="idn-upload-toolbar">
            <input matInput [(ngModel)]="fileAttr" class="input-files" [ngModelOptions]="{standalone: true}" readonly name="name" required/>
            <button mat-flat-button color="primary" class="idn-file-upload" (click)="file.click()">Browse</button>
          </mat-toolbar>
          <label for="uploadFile" class="idn-off-focus-label">File Input</label>
          <input type="file" #fileInput id="uploadFile"  name="uploadFile" accept=".zip,.rar,.vsix" (change)="uploadFileEvt($event)" onclick="this.value = null"/>
          <mat-hint class="idn-mat-hint">
            <span class="material-icons">info</span>
            <span class="message">{{ this.WbMgmtEnum.wb_hint_file }}</span>
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="pss-right-panel">

        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_ici_version }}</mat-label>
          <mat-select formControlName="IClist" disableOptionCentering multiple required>
              <mat-option *ngFor="let IClist of icVersionList ; let i =index" [value]="IClist.id">
                <!-- <mat-checkbox color="primary" > -->
                  {{IClist.version}}
                <!-- </mat-checkbox> -->
              </mat-option>
          </mat-select>
          <mat-error>
            <span  *ngIf="frmSDK.controls.Version.invalid">
              {{ this.WbMgmtEnum.wb_error_ici_version }}
              </span>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_release_notes }}</mat-label>
          <textarea class="idn-min-textarea-height" matInput formControlName="ReleaseNote" maxlength="500" width="50" placeholder="Release Notes" [(ngModel)]="myInputValueReleaseNotes" (ngModelChange)="valueChangeReleaseNotes($event)" required></textarea>
            <mat-error *ngIf="frmSDK.controls.ReleaseNote.invalid">{{ errorDescriptionReleaseNote }}</mat-error>
        </mat-form-field>

        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_release_date }}</mat-label>
          <input matInput [matDatepicker]="picker" (dateInput)="addEvent('input', $event)"  formControlName="ReleaseDate"
          readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field class="pss-block-form">
          <mat-label>{{ this.WbMgmtEnum.wb_label_eol_date }}</mat-label>
          <input matInput  [matDatepicker]="pickerEol"  [min]="minDateEolDate"  formControlName="EOLDate" readonly>
          <mat-datepicker-toggle matSuffix [for]="pickerEol"></mat-datepicker-toggle>
          <mat-datepicker #pickerEol></mat-datepicker>
          <mat-error *ngIf="frmSDK.controls.EOLDate.invalid">{{ this.WbMgmtEnum.wb_error_endOfLife_date }}</mat-error>
        </mat-form-field>
      </div>
      <div class="pss-form-button-wrapper">
        <button mat-raised-button color="primary" [disabled]="!frmSDK.valid && (!frmSDK.controls.ReleaseDate.invalid || !frmSDK.controls.EOLDate.invalid)"  type="submit" (click)="onSaveSDk()">{{ this.WbMgmtEnum.wb_label_save_btn }}</button>
        <button mat-stroked-button (click)="onCancel('idn-workbench-management')">{{ this.WbMgmtEnum.wb_label_cancel_btn }}</button>
      </div>
    </form>
  </div>
</div>
