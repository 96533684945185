import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { UserService } from 'src/app/core/services/user-management/user.service';

@Component({
  selector: 'app-idn-dropdown',
  templateUrl: './idn-dropdown.component.html',
  styleUrls: ['./idn-dropdown.component.scss']
})
export class IdnDropdownComponent implements OnInit{
  userCategories:any = [];
  selectedValue:any;
  @ViewChild ('customer') selectData !: MatSelect;
  @Output() selectedData = new EventEmitter<any>();
  @Input() moduleName :any;
  partnerAccess:any;
  storeData:any;
  idnCertifiedData: any = [];
  data: any = {};
  customerUser: boolean = false;
  userCategory:any = {
    icertis: 1,
    partner: 2,
    customer: 3
  }
  constructor(
    private userService: UserService,
    private permissionService: PermissionsService,
  ){}
ngOnInit(): void {
  this.partnerAccess = this.permissionService.hasGlobalDataAccess();
  this.customerUser = (this.permissionService.getUsercategoryId() == this.userCategory.customer);
  if(this.moduleName == 'user-management'){
    let data:any = localStorage.getItem('userFilterData');
    this.storeData = JSON.parse(data)
    if(this.storeData){
      this.selectedValue = this.storeData?.idnCertified;
    }
  }
  if(!this.customerUser){
    this.idnCertifiedData = [{id: 0, name: 'Yes', value: true}, {id: 1, name: 'No', value: false}];
  }
}

onSelectionChange(event:any){
  this.selectedValue = event.value;
  this.selectedData.emit (this.selectedValue)
}
}
