<app-pageheader [breadCrumbs]="this.breadCrumbItems" [auditLog]="false" [createRole]="false" [mapRole]="false"
        [applyFilter]="false" [clearFilter]="false" [archive]="false" ></app-pageheader>
<div>
    <div class="table-header">{{this.sharedEnum.approval_request_header}}</div>
    <div class="search">
        <app-idn-search-box (searchText)="onSearchData($event)"></app-idn-search-box>
        <mat-form-field>
            <mat-label>{{this.sharedEnum.request_type_label}}</mat-label>
            <mat-select #requesttype (selectionChange)="doFilter()">
              <mat-option [value]= "selectAll"> All</mat-option>
              <mat-option *ngFor="let reqstatus of requestStatus" [value]="reqstatus.id">
                {{reqstatus.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="select-status">
            <mat-label>Status</mat-label>
            <mat-select #requeststatus (selectionChange)="doFilter()">
              <mat-option [value]= "selectAll"> All</mat-option>
              <mat-option *ngFor="let status of statusList" [value]="status.id">
                {{status.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
    </div>
<div class="idn-table">
    <app-idn-table  class="logs-table" [data]="viewApprovalListData"  (viewButton)="ViewRequestDetails($event)" (onPageChanged)="pageChanged($event)" (onSort)="announceSortChange($event)"></app-idn-table>
</div>
   
</div>