export const environment = {
  production: false,
  headers: { },
  clientId: 'c95b6896-e99d-4938-badd-4832b97f8fce',
  tenantId: 'd393bd23-4700-41f9-bee8-490b984bf844',
  audienceId: 'adfa6d7d-dc7c-49ea-802b-552e43d00340',
  redirectUri: 'https://developer-dev.icertis.com/login',
  postLogoutRedirectUri: 'https://developer-dev.icertis.com/',
  appGatewayUrl: 'https://developer-dev.icertis.com/',
  azureAccount: 'idnfinalfilestoragedev',
  azUploadAccount: '',
 };
